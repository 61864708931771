angular.module('meanApp')
  .controller('step3-controller', function ($scope, $rootScope, $state, $stateParams, $filter, DataM, $window, Auth, empowerDataService, $timeout, Idle, DataHelper, $document, DataMSPortal) {
    $scope.isAdmin = false;
    Auth.isAdmin().then(isAdmin => ($scope.isAdmin = isAdmin));

    $scope.isThirdStep = true;
    $scope.empowerData = empowerDataService;
    $scope.isSpendingShown = false;
    $scope.isBillsShown = true;

    $scope.otherExpenses = [];
    $scope.taxDeductible = [];
    $scope.savedText = "Save";
    if ($state.current && $state.current.name.toLowerCase() == "msspending") {
      $scope.isSpendingShown = true;
      $scope.isBillsShown = false;
    }
    $scope.stepsState = {
      back: 'step2',
      next: 'ms-landing',
    }
    $scope.loadingMessage = "Loading...";
    $scope.showClientSpinner = false;
    $scope.showErrorMessage = false;
    $scope.error = {};

    $scope.expenses = [];
    $scope.defaultExpensesBills = empowerDataService.defaultExpensesBills.slice();
    $scope.defaultExpensesSpending = empowerDataService.defaultExpensesSpending.slice();


    $scope.loanTypes = ['Home Loan - Basic Variable', 'Home Loan - Standard Variable', 'Home Loan - Fixed Rate',
      'Home Loan - Line of Credit (Personal Use)', 'Investment Loan - Basic Variable', 'Investment Loan - Standard Variable',
      'Investment Loan - Fixed Rate', 'Investment Loan - Line of Credit (Investment Use)', 'Credit Card', 'Car Loan', 'Personal Loan', 'ATO or Centrelink Debt',
      'HECS/HELP Debt', 'Hire Purchase', 'Lease', 'Overdraft', 'Store Card', 'Commercial Bill', 'Charge Card', 'Other'
    ];



    $scope.primaryPurposes = ['Buy Real Estate', 'Refinance Real Estate', 'Debt Consolidation', 'General Spending', 'Other Purchases/Items'];

    $scope.incomes = [{}];
    $scope.loans = [{}];
    $scope.addIncome = function () {
      $scope.incomes.push({});
    }


    $scope.deleteIncome = function (income) {
      $scope.incomes.splice($scope.incomes.indexOf(income), 1);
    }

    $scope.addLoan = function () {
      $scope.loans.push({});
    }

    $scope.addOtherExpense = function () {
      var otherExpense = empowerDataService.newOtherExpense();
      $scope.otherExpenses.push(otherExpense);
    }
    $scope.addNewCustomTaxDeductBill = function () {
      var bill = empowerDataService.newTaxDeductibleBill();
      $scope.taxDeductible.push(bill);
    };
    $scope.deleteCustomTaxDeductBill = function (bill) {
      $scope.taxDeductible.splice($scope.taxDeductible.indexOf(bill), 1);
     
    };
    $scope.deleteLoan = function (loan) {
      $scope.loans.splice($scope.loans.indexOf(loan), 1);
    }

    $scope.deleteOtherExpense = function (expense) {
      $scope.otherExpenses.splice($scope.otherExpenses.indexOf(expense), 1);
    }

    $scope.swithExpense = function (expenseType) {
      if (expenseType == 'Spending') {
        $scope.isBillsShown = false;
        $scope.isSpendingShown = true;
      }
      if (expenseType == 'Bills') {
        $scope.isBillsShown = true;
        $scope.isSpendingShown = false;
      }
    }

    $scope.processAmount = DataHelper.processExpenseMonthly;
    $scope.owners = [];
    // if admin add uid to get request
    var requestPayload = {};

    Auth.isAdmin().then(isAdmin => {
      if (isAdmin) {
        requestPayload.uid = $stateParams.clientId;
      }
    
      DataMSPortal.getStep3(requestPayload).then(function(data) {
        if (data.expenses && data.expenses.expenses) {
          mapRetrieveExpenseData(
            data.expenses.expenses,
            $scope.defaultExpensesBills
          );
    
          mapRetrieveExpenseData(
            data.expenses.expenses,
            $scope.defaultExpensesSpending
          );
        }
        if (data.personalInfo) {
          $scope.personalInfo = data.personalInfo;
          if (data.personalInfo.client1.fName != null) {
            var owner1 = {
              name:
                data.personalInfo.client1.fName +
                " " +
                data.personalInfo.client1.lName,
              id: data.personalInfo.client1._id
            };
            $scope.owners.unshift(owner1);
          }
          if (data.personalInfo.client2) {
            if (data.personalInfo.client2.fName) {
              var owner2 = {
                name:
                  data.personalInfo.client2.fName +
                  " " +
                  data.personalInfo.client2.lName,
                id: data.personalInfo.client2._id
              };
    
              var joint = {
                name: "Joint",
                id: ""
              };
    
              $scope.owners.unshift(owner2);
              $scope.owners.push(joint);
            }
          }
        }
        // check selected tax deductible owner
        for (var $i = 0; $i < $scope.taxDeductible.length; $i++) {
          var taxDeductibleExpense = $scope.taxDeductible[$i];
    
          // check if joint type
          if (taxDeductibleExpense.ownership) {
            if (taxDeductibleExpense.ownership.ownershipType == "Joint") {
              taxDeductibleExpense.owner = $scope.owners[$scope.owners.length - 1];
            }
    
            // determine owner
            else if (taxDeductibleExpense.ownership.ownershipType == "Sole") {
              for (var $k = 0; $k < $scope.owners.length; $k++) {
                var ownerListOption = $scope.owners[$k];
    
                if (
                  taxDeductibleExpense.ownership.owners[0].owner ==
                  ownerListOption.id
                ) {
                  taxDeductibleExpense.owner = ownerListOption;
                }
              }
            }
          }
        }
      });
    });

    function mapRetrieveExpenseData(expensesData, expenseArrayHolder) {

      for (var i = 0; i < expensesData.length; i++) {
        var expenseData = expensesData[i];
        if (!expenseData.frequency || expenseData.frequency.length < 1) {
          expenseData.frequency = "Monthly";
        }
        if(expenseData.taxDeductible == true){
          if($scope.taxDeductible.indexOf(expenseData) == -1){
            $scope.taxDeductible.push(expenseData);
            $scope.numbersOnly(expenseData, 'basic');
          }
        }
        else if (expenseData.taxDeductible != true &&expenseData.customExpense == true && $scope.otherExpenses.indexOf(expenseData) == -1) {
          $scope.otherExpenses.push(expenseData);
          $scope.numbersOnly(expenseData, 'basic');
          $scope.numbersOnly(expenseData, 'discretionary');
          $scope.numbersOnly(expenseData, 'amount');
        } else {
          for (var j = 0; j < expenseArrayHolder.length; j++) {
            var holderExpense = expenseArrayHolder[j];
            if (expenseData.desc === holderExpense.desc) {
              expenseArrayHolder[j] = expenseData;

              $scope.numbersOnly(expenseArrayHolder[j], 'basic');
              $scope.numbersOnly(expenseArrayHolder[j], 'discretionary');
              $scope.numbersOnly(expenseArrayHolder[j], 'amount');

              break;
            }
          }
        }
      }
    }

    $scope.save = function () {
      $scope.showClientSpinner = true;

      var expenses = [];
      expenses = expenses.concat($scope.defaultExpensesBills);
      expenses = expenses.concat($scope.defaultExpensesSpending);
      expenses = expenses.concat($scope.otherExpenses);
      expenses = expenses.concat($scope.taxDeductible);

      var formData = {
        expenses: {
          expenses: expenses
        },
      }

      DataMSPortal.saveStep3(formData).then(function (data) {
        $scope.showClientSpinner = false;

        // Should go to moneysmarts
        // Remove all saved data from sessionStorage
        sessionStorage.clear();
        $scope.savedText = "Saved";
        if ($state.current.name == "step3") {
          $state.go('whatsnext');
        }

      }).catch(function (err) {
        console.log('Save error', err);
        $scope.showClientSpinner = false;
        $scope.showErrorMessage = true;
        $scope.error.title = "Save Error";
        $scope.error.message = "There seems to be a problem with saving your data. Please try to refresh the page and try again. If error still occurs Please contact an Adviser from Empower Wealth.";

      });
    }

    $scope.numbersOnly = function(object, propertyToChange) {
      var transformedText  = '';
      
      var text = "";
      if(object[propertyToChange+"Str"] &&  object[propertyToChange+"Str"].length>0){
        text = object[propertyToChange+"Str"];
      } else if(typeof object[propertyToChange] == "number"){
        text = object[propertyToChange].toString();
      } else {
      }
      if(text) {
        var countDots = (text.match('.') || []).length;
        let textArr = text.split('.');
        countDots = textArr.length;
        if(countDots > 2) {
         textArr = textArr.slice(0,textArr.length-1);
          text = textArr.join('.');
        }
        transformedText = text.replace(/[^0-9.]/g, '');
        if(propertyToChange != "interestRate"){
          transformedText = "$"+transformedText;
        } else {
          transformedText = transformedText+"%";
        }
      }

      let valueStr = transformedText.replace('$', '');
      valueStr = valueStr.replace('%', '');

      object[propertyToChange + "Str"] = transformedText;
      if (isNaN(parseFloat(valueStr)) == false) {
        object[propertyToChange] = parseFloat(valueStr);

      } else {
        object[propertyToChange] = null;
      }

      

    }

    $scope.assignOwnership = function (taxDeductibleExpense, owner) {

      var p = $scope.taxDeductible[$scope.taxDeductible.indexOf(taxDeductibleExpense)];


      if (owner.name == "Joint") {

        if ($scope.owners.length > 2) {
          p.ownership = {
            ownershipType: 'Joint',
            owners: [{
                percentage: 50,
                name: $scope.personalInfo.client1.fName + ' ' + $scope.personalInfo.client1.lName,
                owner: $scope.personalInfo.client1._id
              },
              {
                percentage: 50,
                name: $scope.personalInfo.client2.fName + ' ' + $scope.personalInfo.client2.lName,
                owner: $scope.personalInfo.client2._id
              }
            ],
          };
        }
      } else if (owner.name == "Tenants in Common") {
        p.ownership = {
          ownershipType: 'Joint',
          owners: [{
            percentage: 0,
            name: $scope.personalInfo.client1.fName + ' ' + $scope.personalInfo.client1.lName,
            owner: $scope.personalInfo.client1._id
          }]
        };
        if ($scope.owners.length > 2) {

          var owner2 = {
            percentage: 0,
            name: $scope.personalInfo.client2.fName + ' ' + $scope.personalInfo.client2.lName,
            owner: $scope.personalInfo.client2._id
          }
          p.ownership.owners.push(owner2);
        }
      } else {
        p.ownership = {
          ownershipType: 'Sole',
          owners: [{
            percentage: 100,
            name: owner.name,
            owner: owner.id
          }],
        };
      }
    }

  });
