"use strict";

angular.module("meanApp").config(function($stateProvider) {
  $stateProvider.state("pwp-workbench", {
    url: "/pwpworkbench/:clientId",
    params: {
      clientId: {
        value: null,
        squash: false
      }
    },
    templateUrl: "app/pwpWorkbench/pwp_workbench.html",
    controller: "pwp-workbench-controller",
    authenticate: "admin"
  });
});
