export const LendingSurveyTabName = 'lending-surveys';

export const tabNamesToHandle = [LendingSurveyTabName];

/**
 * @param {string} tabName
 *
 * @param {HTMLDivElement} node
 *
 * @param {string} prevTabName
 *
 * @param {{
 *  userId: string,
 *  apiBaseUrl: string,
 * }} props
 *
 * @returns {boolean} `true` if `tabName` is handled
 */
export default function handleTabName(tabName, node, prevTabName, props) {
  const isHandleTab = tabNamesToHandle.includes(tabName)

  if (tabNamesToHandle.includes(prevTabName)) {
    ReactDOM.unmountComponentAtNode(node);
  }

  if (!isHandleTab) {
    return false;
  }

  switch (tabName) {
    case LendingSurveyTabName:

      ReactDOM.render(
        React.createElement(SettlementForm.LendingSurveysPage, props),
        node
      );

      return true;

    default:
      // pass
  }

  return false;
}