function RouterDecoratorConfig($rootScope, $state, Auth) {
  'ngInject';

  // Redirect to login if route requires auth and the user is not logged in, or doesn't have required role
  $rootScope.$on('$stateChangeStart', function(event, next) {
    if (!next.authenticate) {
      return;
    }

    if (typeof next.authenticate === 'string') {
      Auth.hasRole(next.authenticate, _.noop)
        .then(has => {
          if (has) {
            return;
          }

          event.preventDefault();

          return Auth.isLoggedIn(_.noop)
            .then(is => {
              // maybe to change to redirect to main dashboad page if user logged in...
              $state.go('login', {redirect: next.name});
            });
        });
    } else {
      Auth.isLoggedIn(_.noop)
        .then(is => {
          if (is) {
            return;
          }

          event.preventDefault();
          $state.go('login', {redirect: next.name});
        });
    }
  });
}

export default RouterDecoratorConfig;
