/**
 * Created by Konstantino on 29/9/16.
 */
'use strict';

angular.module('meanApp')
  .config(function ($stateProvider) {
    $stateProvider
      .state('mygoals', {
        url: '/my-goals/:clientId',
        params: {
          clientId: {
            value: null,
            squash: false
          }
        },
        templateUrl: 'app/myGoals/my-goals.html',
        controller: 'factfind-0-controller',
        authenticate: ['user','admin']
      });
  });
