export const incomeTaxScales = [
  {
    min: 0,
    max: 18_200,
    tax: 0,
    excessRate: 0,
  },
  {
    min: 18_201,
    max: 45_000,
    tax: 0,
    excessRate: 19 / 100,
  },
  {
    min: 45_001,
    max: 120_000,
    tax: 5092,
    excessRate: 32.5 / 100,
  },
  {
    min: 120_001,
    max: 180_000,
    tax: 29_467,
    excessRate: 37 / 100,
  },
  {
    min: 180_001,
    max: 0,
    tax: 51_667,
    excessRate: 45 / 100,
  },
];

export const lowIncomeTaxOffsetScales = [
  {
    min: 0,
    max: 37_500,
    tax: 700,
    excessRate: 0,
  },
  {
    min: 37_501,
    max: 45_000,
    tax: 700,
    excessRate: -5 / 100,
  },
  {
    min: 45_001,
    max: 66_667,
    tax: 325,
    excessRate: -1.5 / 100,
  },
  {
    min: 66_668,
    max: 0,
    tax: 0,
    excessRate: 0,
  },
];

export const medicalLevyScales = [
  {
    min: 0,
    max: 23_226,
    tax: 0,
    excessRate: 0,
  },
  {
    min: 23_227,
    max: 29_033,
    tax: 0,
    excessRate: 10 / 100,
  },
  {
    min: 29_034,
    max: 0,
    tax: 581,
    excessRate: 2 / 100,
  },
];
