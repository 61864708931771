angular.module('meanApp')

  .controller('personal-info-updated-controller', function ($scope, $rootScope, $state, $stateParams, DataM, $window, Auth, empowerDataService, $location, $timeout, Idle, DataHelper, $uibModal) {
    'use strict';
    $scope.isAdmin = false;
    Auth.isAdmin().then(isAdmin => ($scope.isAdmin = isAdmin));

    $scope.locked = false;
    $scope.personalInfoData = {};
    $scope.reqData = {};
    $scope.showChangesAlert = false;
    const $clientId = $stateParams.clientId;
    const formData = {};
    if ($clientId && $clientId.length > 0) {
      formData.uid = $clientId;
    }
    const savePersonalInfo = DataM.savePersonalInfoWithFP;
    const getIsPartnerActivated = DataM.getPartnerActivated;
    const createPartner = DataM.createPartner;
    $scope.mbWarningEnabled = false;
    $scope.newLoc = "";
    $scope.isSaving = false;
    $scope.toStateName = "";
    $scope.showBottomButtons = false
    $scope.didNextSave = false

    $scope.$on("$stateChangeStart", function (event, toState, toParams, fromState, fromParams) {
      if (_.isEqual(toState.name, 'login')) {

      } else if (toState.url.indexOf("personal-info") == -1 && !$scope.isSaving && !$scope.didNextSave) {
        $scope.isSaving = true;
        $scope.toStateName = toState.name;
        event.preventDefault();
        if ($scope.showBottomButtons) {
          $scope.showChangesAlert = true;
        } else {
          $state.go(toState.name, {
            clientId: $clientId
          });
          return;
        }
      }
    });

    $rootScope.changeRouteModalReact = function(isToSave, isToDiscard) {
      showReactApp($scope.mbWarningEnabled, isToSave, isToDiscard);
      $scope.closeChangesAlert()
    }

    $scope.closeChangesAlert = function (doCancel) {
      $scope.showChangesAlert = false;
      $scope.isSaving  = false;
      if (doCancel){
        $scope.toStateName = "";
      }
    }

    function getMBWarning() {
      DataM.getMBWarningEnabled(formData).then(function (data) {
        $scope.mbWarningEnabled = data.mbWarningEnabled
        showReactApp($scope.mbWarningEnabled, false, false);
      }).catch(function (err) {
        console.log(err);
      });
    }
    
    function showReactApp(mbWarningEnabled, isToSave, isToDiscard) {
        if (PersonalInfo.default) {
          let isFactFind = $state.current.name==='fact-find1'? true:false;
        
          var propsApi = {
            savePersonalInfo:savePersonalInfo,
            getIsPartnerActivated:getIsPartnerActivated,
            createPartner:createPartner,
          }
          let personalInfoWrapper = document.getElementById("react-wrapper-personal");
          if ($scope.$$destroyed) return;

          ReactDOM.unmountComponentAtNode(personalInfoWrapper);
          ReactDOM.render(
            React.createElement(PersonalInfo.default, {
              clientData : $scope.personalInfoData,
              savePersonalInfo : propsApi.savePersonalInfo,
              getIsPartnerActivated : propsApi.getIsPartnerActivated,
              createPartner :propsApi.createPartner,
              isFactFind : isFactFind,
              MBChecker: mbWarningEnabled,
              reqData: $scope.reqData,
              isToSave,
              isToDiscard,
              showModal: function (show) {
                $scope.showBottomButtons = show;
              },
              showNotifAlert: function (isSaved) {
                var alertMessage = ""
                if(isSaved) {
                  alertMessage = "Successfully saved changes!"
                } else {
                  alertMessage = "Successfully discarded changes!"
                }
                if($scope.toStateName !== "" && !$scope.showBottomButtons) {
                  $state.go($scope.toStateName, {
                    clientId: $clientId
                  });
                } else {
                  $rootScope.setAlertValues(true, true, alertMessage, true)
                }
              },
              updateState: function () {
                $rootScope.$broadcast("resetNotif", null);
              },
            }
          ), personalInfoWrapper);

          $scope.$on('$destroy', function (){
            ReactDOM.unmountComponentAtNode(personalInfoWrapper);
          });
        }
    }

    $scope.$on("reqData", function (evt, reqData) {
      $scope.reqData = reqData;
      DataM.getPersonalInfoWithFP().then(function (data) {
        $scope.personalInfoData = data;
        console.log("Personal Info Data Received:",data);
        getMBWarning();
      })
    });
  });
