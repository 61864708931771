angular
  .module("meanApp")
  .controller("2faRegisterCtrl", function(
    $scope,
    Auth,
    $location,
    $window,
    $state
  ) {
    "use strict";
    $scope.isAdmin = false;
    Auth.isAdmin().then(isAdmin => ($scope.isAdmin = isAdmin));

    $scope.showSpinner = false;
    $scope.errors = {};
    $scope.phoneNumber = "";
    $scope.shortPhoneNumber = "";
    $scope.countryCode = "";
  	$scope.isValid;
  	$scope.showAlertModal = false;
    $scope.type = Auth.getCurrentUser().type;
  
    if(Auth.getCurrentUser().authenticator !== 'sms' && Auth.getCurrentUser().role === 'user'){
      Auth.logout();
      $state.go('login');
    }

    $scope.confirmRegister = function(form) {
      if (form.$valid && $scope.phoneNumber != "") {
        $scope.countryCode = $scope.phoneNumber.replace(
          form.tel.$viewValue,
          ""
        );
        $scope.shortPhoneNumber = form.tel.$viewValue;
        $scope.showAlertModal = true;
      } else {
        $scope.errors.msg = "Please enter a valid phone number.";
      }
    };

    $scope.closeModal = function() {
      $scope.showAlertModal = false;
    };

    $scope.register = function() {
      $scope.showAlertModal = false;
      $scope.showSpinner = true;
      $scope.errors = {};

      var countryCode = $scope.countryCode.substring(1);
      var mobileNumber = $scope.shortPhoneNumber.replace(/\s+/g, "");

      Auth.register({
        countryCode: countryCode,
        mobileNumber: mobileNumber
      })
        .then(user => {
          $scope.showSpinner = false;
          $state.go("2fa-verifycode");
        })
        .catch(function(err) {
          console.log(err);
          $scope.errors.msg =
            "Error registring to two factor authentication. Please verify the phone number you have entered.";
          $scope.showSpinner = false;
        });
    };
  })

  .controller("2faVerificationCtrl", function(
    $scope,
    Auth,
    $location,
    $window,
    $state
  ) {
    "use strict";

    $scope.showSpinner = false;
    $scope.verificationCode = null;
    $scope.errors = {};
    $scope.type = Auth.getCurrentUser().type;
    $scope.redirect = $state.params.redirect;

    if(Auth.getCurrentUser().authenticator !== 'sms' && Auth.getCurrentUser().role === 'user'){
      Auth.logout();
      $state.go('login');
    }
   
    $scope.resendVerifyCode = function () {
      
      $scope.showSpinner = true;

      Auth.getCurrentUser(null).then(user => {
        Auth.resendVerificationCode({
          email: user.email
        })
          .then(res => {
            $scope.showSpinner = false;
          })
          .catch(function(err) {
            $scope.errors.msg = "resend";
            $scope.showSpinner = false;
          });
      });
    };

    $scope.verifyCode = function(form) {
      $scope.showSpinner = true;
      if (form.$valid) {
        $scope.errors = {};
        Auth.verifyCode({
          verificationCode: $scope.verificationCode
        })
          .then(user => {
            $scope.showSpinner = false;

            if (!user.passwordReset) {
              $state.go("resetPassword");
            } else if (
              (!user.TCaccepted || !user.updateTCaccepted) &&
              user.passwordReset &&
              user.role == "user"
            ) {
              $state.go("terms");
            } else {
              Auth.isAdmin().then(isAdmin => {
                if (isAdmin) {
                  $state.go("dashboard-search");
                } else {
                  if(!$scope.redirect) {
                    $state.go("dashboard");
                  } else {
                    $state.go($scope.redirect);
                  }
                }
              });
            }
          })
          .catch(function(err) {
            $scope.errors.msg = "Please enter a valid authentication code";
            $scope.showSpinner = false;
          });
      }
    };
  });
