'use strict';

angular.module('meanApp')
  .config(function ($stateProvider) {
    $stateProvider
      .state('settings', {
        url: '/settings/:clientId',
        params: {
          clientId: {
            value: null,
            squash: false
          }
        },
        templateUrl: 'app/settings/settings.html',
        controller: 'settings-controller',
        authenticate:'admin'
      });
      
  });
