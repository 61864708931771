angular
  .module("meanApp")
  .controller("knowlegeCenter-controller", function (
    $scope,
    $stateParams,
    Auth
  ) {
    "use strict";
    $scope.isAdmin = false;
    Auth.isAdmin().then((isAdmin) => ($scope.isAdmin = isAdmin));

    let knowledgeCenterWrapper = document.getElementById("react-wrapper-knowledgeCenter");
    $scope.$on("knowledgeCenter-react", function (evt, reqData) {
      ReactDOM.unmountComponentAtNode(knowledgeCenterWrapper);
      ReactDOM.render(
        React.createElement(EmpowerWealthBlog.default, {
          id: $stateParams.clientId,
          isAdmin: $scope.isAdmin,
        }),
        knowledgeCenterWrapper
      );
      $scope.$on("$destroy", () => {
        ReactDOM.unmountComponentAtNode(knowledgeCenterWrapper);
      });
    });
  });
