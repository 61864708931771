angular
  .module("meanApp")
  .controller("futurePlansBeta-controller", function($scope, $stateParams,Auth) {
    "use strict";

    const reactContainerNode = document.getElementById("react-wrapper-future-plans");
    Auth.isAdmin().then(isAdmin => ($scope.isAdmin = isAdmin));
    ReactDOM.unmountComponentAtNode(reactContainerNode);
    ReactDOM.render(
      React.createElement(FuturePlans.default, {
        id: $stateParams.clientId,
        url: process.env.API_ENDPOINT
      }),
      reactContainerNode
    );

    $scope.$on("$destroy", () => {
      ReactDOM.unmountComponentAtNode(reactContainerNode);
    });
  });
