export default {
    getHouseholdComposition: function (personalInfo) {

        let adult1 = _.get(personalInfo, 'client1', false) ? 1 : 0;
        let adult2 = _.get(personalInfo, 'client2', false) ? 1 : 0;
        let dependants = _.get(personalInfo, 'dependants', []);

        // children
        let femaleKeywords = ['daughter', 'granddaughter'];
        let maleKeywords = ['son', 'grandson'];

        let femaleChildren = 0;
        let maleChildren = 0;
        let other = 0
        for (var i = 0; i < dependants.length; i++) {
            let dependantRelation = _.get(dependants[i], 'relationship', '').toLowerCase();
            if (femaleKeywords.includes(dependantRelation)) {
                femaleChildren += 1;
            }
            else if (maleKeywords.includes(dependantRelation)) {
                maleChildren += 1;
            } else {
                other += 1;
            }
        }
        console.log("adult1, adult2, maleChildren, femaleChildren, other");
        let compArray = [adult1, adult2, maleChildren, femaleChildren, other];
        var csv = compArray.join(",");
        return csv;

    },
    jobDescription: function (income) {

        // primary and partner
        let primaryIncome = _.get(income, 'client1PersonalIncome.currentEmployment', []);
        let partnerIncome = _.get(income, 'client2PersonalIncome.currentEmployment', []);

        let primaryCSV = [];
        let partnerCSV = [];
        for(var i =0; i<primaryIncome.length;i++){
            let csv = this.getIncomeDescription(primaryIncome[i]);
            primaryCSV.push(csv);
        }
        for(var i =0; i<partnerIncome.length;i++){
            let csv = this.getIncomeDescription(partnerIncome[i]);
            primaryCSV.push(csv);
        }
        return [primaryCSV,partnerCSV];

    }, getIncomeDescription(employment) {

        // employment type 1
        // employment basis 2
        // income amount
        // nature of work
        // nature of business
        // business name
        // abn

        let type = _.get(employment, 'employmentType', '');
        let basis = '';
        let amount = 0;
        let natureOfWork = '';
        let jobPosition = '';
        let natureOfBusiness = '';
        let detailedBasis = '';
        let businessName = '';
        let abn = '';

        // type, basis, amount, natureOfWork, jobPosition, natureOfBusiness, detailedBasis, businessName, abn
        if (type == 'PAYG') {
            // income amount
            amount = _.get(employment, 'job.annualSalary', 0);
            basis = _.get(employment, 'job.basis', '');
            natureOfWork = _.get(employment, 'job.nature', '');
            jobPosition = _.get(employment, 'job.positionTitle', '');
        } else {
            amount = _.get(employment, 'business.earnings[0].salary', 0);
            basis = _.get(employment, 'business.basis', '');
            natureOfWork = _.get(employment, 'business.natureOfWork', '');
            natureOfBusiness = _.get(employment, 'business.natureOfBusiness', '');
            detailedBasis = _.get(employment, 'business.detailedBasis', '');
            businessName = _.get(employment, 'business.businessName', '');
            abn = _.get(employment, 'business.abn', '');
        }

        let incomeDescArray = [type, basis, amount, natureOfWork, jobPosition, natureOfBusiness, detailedBasis, businessName, abn];
        let csv = incomeDescArray.join(",");
        return csv;
    }
}
