angular.module("meanApp").directive("barChart", function($window) {
  return {
    restrict: "EA",
    template: "",
    link: function(scope, elem, attrs) {
      var d3 = $window.d3;
      var unformattedData = scope[attrs.data];
      var data = formatData(unformattedData);

      d3.functor = function functor(v) {
        return typeof v === "function"
          ? v
          : function() {
              return v;
            };
      };
      var margin = { top: 40, right: 20, bottom: 100, left: 40 },
        width = 585 - margin.left - margin.right,
        height = 455 - margin.top - margin.bottom;

      var x = d3.scaleBand().rangeRound([0, width], 0.1);

      var y = d3.scaleLinear().range([height, 0]);

      var xAxis = d3.axisBottom().scale(x);

      var yAxis = d3.axisLeft().scale(y);

      var tip = d3
        .tip()
        .attr("class", "d3-tip")
        .offset([-10, 0])
        .html(function(d) {
          return (
            "<strong>" +
            d.name +
            ":</strong> <span style='color:red'>" +
            d.value +
            "</span>"
          );
        });


      function drawBar(data) {
        var svg = d3
          .select("#barChart")
          .append("svg")
          .attr("id", "barID")
          .attr("width", width + margin.left + margin.right)
          .attr("height", height + margin.top + margin.bottom)

          .append("g")
          .attr(
            "transform",
            "translate(" + margin.left + "," + margin.top + ")"
          );

        svg.call(tip);

        console.log("insight date :", data);

        // The following code was contained in the callback function.
        x.domain(
          data.map(function(d) {
            return d.name;
          })
        );
        y.domain([
          0,
          d3.max(data, function(d) {
            return d.value;
          })
        ]);

        var bars = svg.selectAll(".bar").data(data)

        svg
          .append("g")
          .attr("class", "x axis")
          .attr("transform", "translate(0," + height + ")")
          .call(xAxis)
          .selectAll("text")
          .style("text-anchor", "end")
          .attr("dx", "-.8em")
          .attr("dy", ".15em")
          .attr("transform", "rotate(-65)");

        svg
          .append("g")
          .attr("class", "y axis")
          .call(yAxis)
          .append("text")
          .attr("transform", "rotate(-90)")
          .attr("y", 6)
          .attr("dy", ".71em")
          .style("text-anchor", "end");

       bars
          .enter()
          .append("rect")
          .attr("class", "bar")
          .attr("x", function(d) {
            return x(d.name);
          })
          .attr("width", x.bandwidth())
          .attr("y", height)
          .attr("height", 0)
          .merge(svg.selectAll('.bar').data(data))
          .transition()
          .duration(1000)
          .attr("y", function(d) {
            return y(d.value);
          })
          .attr("height", function(d) {
            return height-y(d.value);
          })
          .on("mouseover", tip.show)
          .on("mouseout", tip.hide);
      }

      drawBar(data);

      function formatData(data) {
        let formattedData = [];
        let keys = Object.keys(data);
        for (var i = 0; i < keys.length; i++) {
          let key = keys[i];
          let name = key;
          let value = data[key];
          formattedData.push({ name: name, value: value });
        }
        return formattedData;
      }

      scope.$watch(
        attrs.data,
        function(newValue) {
          let formattedNewValue = formatData(newValue);
          if (newValue) {
            var svg = d3.select("#barID").remove();
            svg.selectAll(".bar").remove();
            drawBar(formattedNewValue);
          }
        },
        true
      );
    }
  };
});
