angular.module('meanApp')

.controller('forgotPassword-controller', function($scope, Auth, $state) {
  'use strict';
  $scope.email = "";
  $scope.isReset = false;
  $scope.errors = {};
  $scope.showSpinner = false;

  $scope.resetPassword = function(form) {
    $scope.submitted = true;
    if(form.$valid) {
      $scope.showSpinner = true;
      $scope.errors = {};
      Auth.forgotPassword($scope.email)
      .then(function() {
        $scope.showSpinner = false;
        $scope.isReset = true;
      })
      .catch( function(err) {
        $scope.showSpinner = false;
        if(err.status === 403){
         $scope.errors.msg = "This email doesn't exist";
        }
        else {
          $scope.errors.msg = 'Error resetting your password';
        }
      });
    }
  };

  $scope.back = function(){
      $state.go('login');
  }
});
