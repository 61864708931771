'use strict';

angular.module('meanApp')
  .config(function ($stateProvider) {
    $stateProvider
      .state('terms', {
        url: '/terms',
        controller: 'terms-controller',
        templateProvider:  function ($stateParams,$location,appHostnames){
             return '<div ng-if="isTPCUser === true" ng-include="\'app/terms/tpc-terms.html\'"></div>' +
              '<div ng-if="isTPCUser === false" ng-include="\'app/terms/terms.html\'"></div>';
          
        },
        authenticate: ['user']
      });
  });
