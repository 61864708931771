
angular.module('meanApp')
  .controller('2faQRRegisterCtrl', function ($scope, Auth, $state) {
    'use strict';

    $scope.size = "150";
    $scope.version = "5";
    $scope.level = "M";
    $scope.showSpinner = false;
    $scope.errors = {};
    $scope.phoneNumber ="";
  	$scope.isValid;
    $scope.showAlertModal = false;
    $scope.isAlertConfirmShown = false;
    $scope.showCopyModal = false;
    $scope.type = Auth.getCurrentUser().type;
    getSecret();
    if(Auth.getCurrentUser().authenticator !== 'google' && Auth.getCurrentUser().role === 'user'){
      Auth.logout();
      $state.go('login');
    }
    // DataM.getMe().then(function (userData) {

    //   var email  = userData.email;
    //   getSecret(email);

    // }).catch(function (err) {
    //   console.log('Retrieve current user error');
    // });

    $scope.copySecret = function()
    {
      var copyText = document.getElementById("secretKey");
      copyText.select();
      document.execCommand("copy");
      $scope.showCopyModal = true;
    };

    $scope.confirmRegister = function(form) {
      if($scope.data != "") {
      	$scope.showAlertModal = true;
      }
      else
      {
      	 $scope.errors.msg = 'Key should not be empty.';
      }
    };

    $scope.showConfirmModal = function(){
      if($scope.data != "") {
        $scope.showAlertModal = false;
        $scope.isAlertConfirmShown= true;
      }
      else
      {
      	 $scope.errors.msg = 'Key should not be empty.';
      }

    };
    $scope.closeModal = function(){
      $scope.showAlertModal = false;
      $scope.isAlertConfirmShown = false;
      $scope.showCopyModal = false;
    }

    $scope.registerSecret = function(){
    	$scope.showSpinner = true;
        $scope.errors = {};

        Auth.register2faqr()
        .then(user =>  {
          $scope.showSpinner = false;
          $state.go('2fa-qr-verify');
        })
        .catch( function(err) {
        	console.log(err);
           $scope.errors.msg = 'Error registring to two factor authentication';
           $scope.showSpinner = false;
        });
    }
    function getSecret()
    {
      return new Promise(function(resolve,reject)
      {
        Auth.getQRcode().then(res =>{

        if(res){
          var secret = res.secret;
          $scope.qrData = secret.otpauth_url;
          $scope.data = secret.base32;
        }
        }).catch(function(error){
          console.log(error);
        });
      });
    }
  })

  .controller('2faQRVerifyCtrl', function ($scope, Auth, $state) {
    'use strict';

    $scope.showSpinner = false;
    $scope.verificationCode = null;
    $scope.errors = {};
    $scope.type = Auth.getCurrentUser().type;
    $scope.redirect = $state.params.redirect;

    if(Auth.getCurrentUser().authenticator !== 'google' && Auth.getCurrentUser().role === 'user'){
      Auth.logout();
      $state.go('login');
    }
    $scope.verifyCode2fa = function(form) {
      $scope.showSpinner = true;
      if(form.$valid) {
        $scope.errors = {};
        Auth.verifyCode2faqr({
          verificationCode: $scope.verificationCode
        })
        .then(user =>  {
            $scope.showSpinner = false;

            if(!user.passwordReset){
              $state.go('resetPassword');
            } else if((!user.TCaccepted || !user.updateTCaccepted) && user.passwordReset && user.role == 'user'){
              $state.go('terms');
            } else {
              Auth.isAdmin().then(isAdmin => {
                if (isAdmin) {
                  $state.go("dashboard-search");
                } else {
                  if(!$scope.redirect) {
                    $state.go("dashboard");
                  } else {
                    $state.go($scope.redirect);
                  }
                }
              });
            }
        })
        .catch( function(err) {
           $scope.errors.msg = 'Please enter a valid authentication code';
           $scope.showSpinner = false;
        });
      }
    };

  });
