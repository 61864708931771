angular
  .module("meanApp")
  .controller("moneysmarts-controller", function (
    $scope,
    $stateParams,
    DataM,
    DataMSPortal,
    Auth,
    $rootScope
  ) {
    "use strict";
    $scope.isAdmin = false;
    Auth.isAdmin().then(isAdmin => ($scope.isAdmin = isAdmin));
    const moneyFitPromise = DataMSPortal.getMoneyFit;

    $scope.$on("moneysmarts-calculation", function (evt, reqData) {
      let moneySmartsNode = document.getElementById("react-wrapper-moneysmarts");
      ReactDOM.render(
        React.createElement(MoneySMARTS.default, { id: $stateParams.clientId, dashboardValues: reqData, 
          isAdmin: $scope.isAdmin, moneyFitPromise, tabValue: $stateParams.tabValue }),
        document.getElementById("react-wrapper-moneysmarts")
      );
      $scope.$on("$destroy", () => {
        ReactDOM.unmountComponentAtNode(moneySmartsNode);
      });
    });
  });