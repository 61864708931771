angular.module('meanApp')
.config(function ($stateProvider) {
  'use strict';
  $stateProvider
    .state('client-files', {
      url: '/client-files/:clientId',
      params: {
        clientId: {
          value: null,
          squash: false
        }
      },
      templateUrl: 'app/clientFiles/client-files.html',
      controller: 'client-files-controller',
      authenticate: ['user','admin']
    });
});
