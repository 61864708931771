angular.module('meanApp')
  .controller('basic-expenses-controller', function ($scope, $state, $stateParams, DataM, Auth, empowerDataService, $timeout, Idle) {
    'use strict';
    $scope.isAdmin = false;
    Auth.isAdmin().then(isAdmin => ($scope.isAdmin = isAdmin));

    $scope.owners = [];
    $scope.isBasicExpensesHidden = false;
    $scope.clientId = $stateParams.clientId;
    $scope.state = $state.current;
    $scope.params = $stateParams;
    $scope.forms = {};
    $scope.savedText = "Save";
    $scope.owners = [];
    /*Empower Data Service - For common datasets*/
    $scope.empowerData = empowerDataService;
    $scope.showClientSpinner = true;
    $scope.loadingMessage = "Retrieving Client Information...";
    $scope.showRetrieveError = false;
    $scope.allExpenses = [];
    $scope.expenses = []; // reference to existing expenses from other tabs
    $scope.defaultBills = [{
        tier1: 'Home Costs',
        tier2: 'Rent Payments',
        tier3: 'Rent payments',
        tier4: 'Regular Payments',
        desc: 'Rent',
        amount: null,
        frequency: '',
        owner: 'Joint'
      }, {
        tier1: 'Home Costs',
        tier2: 'Council Rates',
        tier3: 'Local government rates',
        tier4: 'Bills',
        desc: 'Local Government Rates',
        amount: null,
        frequency: '',
        owner: 'Joint'
      }, {
        tier1: 'Home Costs',
        tier2: 'Council Rates',
        tier3: 'Water and sewerage rates',
        tier4: 'Bills',
        desc: 'Water and Sewerage Rates and Charges',
        amount: null,
        frequency: '',
        owner: 'Joint'
      }, {
        tier1: 'Home Costs',
        tier2: 'Home and Contents Insurance',
        tier3: '',
        tier4: 'Bills',
        desc: 'House and Contents Insurance',
        amount: null,
        frequency: '',
        owner: 'Joint'
      }, {
        tier1: 'Home Power & Fuel',
        tier2: 'Utilities',
        tier3: 'Electricity',
        tier4: 'Bills',
        desc: 'Electricity',
        amount: null,
        frequency: '',
        owner: 'Joint'
      }, {
        tier1: 'Home Power & Fuel',
        tier2: 'Utilities',
        tier3: 'Mains gas',
        tier4: 'Bills',
        desc: 'Gas',
        amount: null,
        frequency: '',
        owner: 'Joint'
      }, {
        tier1: 'Home Services & Operations',
        tier2: 'Communications',
        tier3: '',
        tier4: 'Bills',
        desc: 'Telephone - Fixed and Mobile',
        amount: null,
        frequency: '',
        owner: 'Joint'
      }, {
        tier1: 'Home Services & Operations',
        tier2: 'Communications',
        tier3: 'Internet charges',
        tier4: 'Bills',
        desc: 'Internet',
        amount: null,
        frequency: '',
        owner: 'Joint'
      }, {
        tier1: 'Lifestyle (Recreational)',
        tier2: 'Audio & Visual',
        tier3: '',
        tier4: 'Living and Lifestyle',
        desc: 'Pay Television',
        amount: null,
        frequency: '',
        owner: 'Joint'
      }, {
        tier1: 'Personal Health Insurances',
        tier2: 'Accident & Health Insurance',
        tier3: '',
        tier4: 'Bills',
        desc: 'Health Insurance',
        amount: null,
        frequency: '',
        owner: 'Joint'
      }, {
        tier1: 'Personal / Professional',
        tier2: 'Personal Protection Insurances',
        tier3: 'Life Insurance',
        tier4: 'Bills',
        desc: 'Life Insurance',
        amount: null,
        frequency: '',
        owner: 'Joint'
      }, {
        tier1: 'Transport',
        tier2: 'Car License & Registration',
        tier3: 'Compulsory registration',
        tier4: 'Bills',
        desc: 'Car Registration',
        amount: null,
        frequency: '',
        owner: 'Joint'
      }, {
        tier1: 'Transport',
        tier2: 'Car Insurance',
        tier3: '',
        tier4: 'Bills',
        desc: 'Car Insurance',
        amount: null,
        frequency: '',
        owner: 'Joint'
      }, {
        tier1: 'Transport',
        tier2: 'Motoring Organisation Fees',
        tier3: 'Subscription to motor organisations',
        tier4: 'Bills',
        desc: 'Motoring Organisations',
        amount: null,
        frequency: '',
        owner: 'Joint'
      }, {
        tier1: 'Education',
        tier2: '',
        tier3: '',
        tier4: 'Bills',
        desc: 'Education - School Fees',
        amount: null,
        frequency: '',
        owner: 'Joint'
      }, {
        tier1: 'Education',
        tier2: 'Pre School Care Services',
        tier3: '',
        tier4: 'Bills',
        desc: 'Child Care Fees',
        amount: null,
        frequency: '',
        owner: 'Joint'
      }, {
        tier1: 'Bank Charges',
        tier2: 'Banking Fees',
        tier3: '',
        tier4: 'Regular Payment',
        desc: 'Bank Fees',
        amount: null,
        frequency: '',
        owner: 'Joint'
      },

    ];

    $scope.defaultSpendings = [{
        tier4: 'Living Lifestyle',
        desc: 'Groceries and Non-alcoholic Beverages',
        amount: 0,
        frequency: ''
      },
      {
        tier4: 'Living Lifestyle',
        desc: 'Clothing, Footwear, Dry cleaning, Repairs',
        amount: 0,
        frequency: ''
      },
      {
        tier4: 'Bills',
        desc: 'Education - Out-of-pocket expenses',
        amount: 0,
        frequency: ''
      },
      {
        tier4: 'Bills',
        desc: 'Medical and Health Care expenses',
        amount: 0,
        frequency: ''
      },
      {
        tier4: 'Living Lifestyle',
        desc: 'Personal Care',
        amount: 0,
        frequency: ''
      },
      {
        tier4: 'Living Lifestyle',
        desc: 'Pets and Animals',
        amount: 0,
        frequency: ''
      },
      {
        tier4: 'Provisioning',
        desc: 'Household Appliances and Tools',
        amount: 0,
        frequency: ''
      },
      {
        tier4: 'Provisioning',
        desc: 'Furniture',
        amount: 0,
        frequency: ''
      },
      {
        tier4: 'Living Lifestyle',
        desc: 'Household Furnishings',
        amount: 0,
        frequency: ''
      },
      {
        tier4: 'Household',
        desc: 'House and Garden maintenance',
        amount: 0,
        frequency: ''
      },
      {
        tier4: 'Bills',
        desc: 'Fuel',
        amount: 0,
        frequency: ''
      },
      {
        tier4: 'Bills',
        desc: 'Car maintenance',
        amount: 0,
        frequency: ''
      },
      {
        tier4: 'Bills',
        desc: 'Parking and Tolls',
        amount: 0,
        frequency: ''
      }, {
        tier4: 'Bills',
        desc: 'Fares',
        amount: 0,
        frequency: ''
      },
      {
        tier4: 'Living Lifestyle',
        desc: 'Presents and Gifts',
        amount: 0,
        frequency: ''
      },
      {
        tier4: 'Living Lifestyle',
        desc: 'Donations',
        amount: 0,
        frequency: ''
      },
      {
        tier4: 'Provisioning',
        desc: 'Holidays',
        amount: 0,
        frequency: ''
      },
      {
        tier4: 'Living Lifestyle',
        desc: 'Dining out / Takeaway food',
        amount: 0,
        frequency: ''
      },
      {
        tier4: 'Living Lifestyle',
        desc: 'Entertainment',
        amount: 0,
        frequency: ''
      },
      {
        tier4: 'Living Lifestyle',
        desc: 'Sport, recreation and hobbies',
        amount: 0,
        frequency: ''
      },
      {
        tier4: 'Living Lifestyle',
        desc: 'Books, Magazines and Newspapers',
        amount: 0,
        frequency: ''
      },
      {
        tier4: 'Living Lifestyle',
        desc: 'Video / DVD purchase and hire',
        amount: 0,
        frequency: ''
      },
      {
        tier4: 'Living Lifestyle',
        desc: 'Alcohol and Tobacco',
        amount: 0,
        frequency: ''
      },
      {
        tier4: 'Living Lifestyle',
        desc: 'Gambling and Lotto',
        amount: 0,
        frequency: ''
      },

    ];

    $scope.defaultExpenses = $scope.defaultBills.concat($scope.defaultSpendings);

    // expense types
    $scope.bills = [];
    $scope.spendings = [];
    $scope.customBills = [];
    $scope.taxDeductible = [];
    $scope.customSpendings = [];
    $scope.basicExpenses = {};
    var hiddenDefaultSpendings = ["Presents and Gifts", "Donations", "Holidays", "Dining out / Takeaway food", "Entertainment",
      "Sport, recreation and hobbies", "Books, Magazines and Newspapers", "Video / DVD purchase and hire", "Alcohol and Tobacco", "Gambling and Lotto"
    ];
    // add custom spending
    $scope.addNewCustomSpending = function () {
      var spend = {
        tier4: 'Spending',
        customExpense: true
      };
      $scope.customSpendings.push(spend);
    };

    // if basic expenses is empty map from the current expenses
    $scope.addNewCustomBill = function () {
      var spend = {
        tier4: 'Spending',
        customExpense: true
      };
      $scope.customSpendings.push(spend);
    };

    $scope.addNewCustomTaxDeductBill = function () {
      var bill = {
        tier4: 'Bills',
        taxDeductible: true
      };
      $scope.taxDeductible.push(bill);
    };

    $scope.addNewCustomBill = function () {
      var bill = {
        tier4: 'Bills',
        customExpense: true
      };
      $scope.customBills.push(bill);
    };


    $scope.deleteCustomBill = function (bill) {
      $scope.customBills.splice($scope.customBills.indexOf(bill), 1);
    };

    $scope.deleteCustomTaxDeductibleBill = function (bill) {
      $scope.taxDeductible.splice($scope.taxDeductible.indexOf(bill), 1);
    };

    $scope.deleteDefaultBill = function (bill) {
      $scope.bills.splice($scope.bills.indexOf(bill), 1);
    };

    $scope.deleteCustomSpending = function (spending) {
      $scope.customSpendings.splice($scope.customSpendings.indexOf(spending), 1);
    };

    $scope.deleteDefaultSpending = function (spending) {
      $scope.spendings.splice($scope.spendings.indexOf(spending), 1);
    };

    $scope.addDefaultBill = function (billDesc) {

      for (var i = 0; i < $scope.allExpenses.length; i++) {
        var expense = $scope.allExpenses[i];
        if (expense.desc == billDesc) {
          $scope.bills.push(expense);
          return;
        }
      }

      for (var i = 0; i < $scope.defaultBills.length; i++) {
        var defaultBill = $scope.defaultBills[i];
        if (defaultBill.desc == billDesc) {
          $scope.bills.push(defaultBill);
          return
        }
      }
    }

    $scope.addDefaultSpending = function (billDesc) {

      for (var i = 0; i < $scope.allExpenses.length; i++) {
        var expense = $scope.allExpenses[i];
        if (expense.desc == billDesc) {
          $scope.spendings.push(expense);
          return;
        }
      }

      for (var i = 0; i < $scope.defaultSpendings.length; i++) {
        var defaultSpending = $scope.defaultSpendings[i];
        if (defaultSpending.desc == billDesc) {
          $scope.spendings.push(defaultSpending);
          return
        }
      }
    }

    $scope.filterAvailableDefaultBill = function (defaultBill) {
      return function (defaultBill) {

        if (defaultBill) {
          for (var i = 0; i < $scope.bills.length; i++) {
            var bill = $scope.bills[i];

            if (bill.desc == defaultBill.desc) {
              return false
            }
          }
        }

        return true;
      };



      return true;
    };


    $scope.filterAvailableDefaultSpending = function (defaultSpending) {
      return function (defaultSpending) {

        if (defaultSpending) {
          for (var i = 0; i < $scope.spendings.length; i++) {
            var spending = $scope.spendings[i];

            if (spending.desc == defaultSpending.desc) {
              return false
            }
          }
        }

        return true;
      };



      return true;
    };

    DataM.getBillPayments().then(function (data) {
      $scope.showClientSpinner = false;

      $scope.personalInfo = data.personalInfo;
      if (data.personalInfo.client1.fName != null) {
        var owner1 = {
          name: data.personalInfo.client1.fName + " " + data.personalInfo.client1.lName,
          id: data.personalInfo.client1._id
        };
        $scope.owners.unshift(owner1);
      }
      if (data.personalInfo.client2) {
        if (data.personalInfo.client2.fName) {

          var owner2 = {
            name: data.personalInfo.client2.fName + " " + data.personalInfo.client2.lName,
            id: data.personalInfo.client2._id
          };

          var joint = {
            name: "Joint",
            id: ''
          };

          $scope.owners.unshift(owner2);
          $scope.owners.push(joint);

        }
      }

      if (data.expenses) {

        if (data.expenses.expenses) {
          $scope.expenses = data.expenses.expenses;
          $scope.allExpenses = JSON.parse(JSON.stringify(data.expenses.expenses));

        }
        // check if user has existing basic expenses
        if (haveExistingBasicExpenses(data.expenses)) {
          $scope.basicExpenses = data.expenses.basicExpenses;
          $scope.bills = data.expenses.basicExpenses.bills;
          $scope.spendings = data.expenses.basicExpenses.spendings;
          $scope.customSpendings = data.expenses.basicExpenses.customSpendings;
          $scope.isBasicExpensesHidden =  data.expenses.basicExpenses.isHidden;
          
          // differentiate tax deductible bills
          if (data.expenses.basicExpenses.customBills) {
            for (var i = 0; i < data.expenses.basicExpenses.customBills.length; i++) {
              var customBill = data.expenses.basicExpenses.customBills[i];

              if (customBill.taxDeductible == true) {
                $scope.taxDeductible.push(customBill);
              } else {

                $scope.customBills.push(customBill);
              }
            }
          }

        } else { // else map expenses to basic expenses

          for (var i = 0; i < $scope.defaultExpenses.length; i++) {
            var expense = getExpense($scope.defaultExpenses[i]);

            // do not include payTV
            if (isDefaultBill(expense) && expense.desc != "Pay Television") {
              $scope.bills.push(expense);
            } else if (isDefaultSpending(expense) && !isSpendingDefaultHidden(expense)) {
              $scope.spendings.push(expense);
            }
          }

          for (var i = 0; i < $scope.allExpenses.length; i++) {
            var expense = $scope.allExpenses[i];
            if (isCustomBill(expense)) {
              $scope.customBills.push(expense);
            } else if (expense.taxDeductible === true) {
              $scope.taxDeductible.push(expense);
            } else if (isCustomSpending(expense)) {
              $scope.customSpendings.push(expense);
            }
          }
        }
        // check selected tax deductible owner
        for (var $i = 0; $i < $scope.taxDeductible.length; $i++) {

          var taxDeductibleExpense = $scope.taxDeductible[$i];

          // check if joint type
          if (taxDeductibleExpense.ownership) {
            if (taxDeductibleExpense.ownership.ownershipType == 'Joint') {
              taxDeductibleExpense.owner = $scope.owners[$scope.owners.length - 1];
            }

            // determine owner
            else if (taxDeductibleExpense.ownership.ownershipType == 'Sole') {

              for (var $k = 0; $k < $scope.owners.length; $k++) {
                var ownerListOption = $scope.owners[$k];

                if (taxDeductibleExpense.ownership.owners[0].owner == ownerListOption.id) {

                  taxDeductibleExpense.owner = ownerListOption;
                }
              }
            }
          }
        }
      }
    })

    function isDefaultBill(expense) {

      for (var i = 0; i < $scope.defaultBills.length; i++) {
        var defaultBill = $scope.defaultBills[i];
        if (expense.desc && defaultBill.desc == expense.desc) {
          return true
        }
      }
      return false;

    }

    function isDefaultSpending(expense) {

      for (var i = 0; i < $scope.defaultSpendings.length; i++) {

        var defaultSpending = $scope.defaultSpendings[i];

        if (expense.desc && defaultSpending.desc == expense.desc) {
          return true
        }
      }
      return false;
    }

    function haveExistingBasicExpenses(expenses) {
      return expenses.basicExpenses && expenses.basicExpenses.bills.length > 0 && expenses.basicExpenses.spendings.length &&
        expenses.basicExpenses.customBills.length && expenses.basicExpenses.customSpendings.length;
    }

    function isCustomBill(expense) {
      return expense.tier4 === 'Bills' && expense.taxDeductible === false && expense.customExpense === true;
    }

    function isCustomSpending(expense) {
      return expense.tier4 == 'Spending' && expense.customExpense == true;
    }

    function isSpendingDefaultHidden(expense) {

      return hiddenDefaultSpendings.indexOf(expense.desc) > -1;
    }

    // get from Bills and Spengings tab version of the expense
    // with their values from the other tabs
    function getExpense(defaultExpense) {

      for (var i = 0; i < $scope.allExpenses.length; i++) {
        var expense = $scope.allExpenses[i];

        if (defaultExpense.desc == expense.desc) {
          return expense;
        }
      }
      return defaultExpense;
    }

    $scope.dashboardSave = function() {
      Auth.isAdmin().then(isAdmin => {
        if (!isAdmin) {
          if ($scope.forms.factfindform.$valid) {
            $scope.save("basic-expenses");
    
            $scope.savedText = "Saved";
          }
          for (var $i = 0; $i < $scope.forms.factfindform.$$controls.length; $i++) {
            var element = $scope.forms.factfindform.$$controls[$i];
            if (!element.$valid) {
              $scope.elementToFocus = element.$$element;
              $scope.showAlertValidationModal();
              $scope.isSaving = false;
              break;
            }
          }
        } else {
          $scope.save("basic-expenses");
    
          $scope.savedText = "Saved";
        }
      });
    };
    
    $scope.setisBasicExpensesHidden = function (){

      $scope.isBasicExpensesHidden = !$scope.isBasicExpensesHidden;
    }

    $scope.save = function (location) {

      $scope.showClientSpinner = true;
      $scope.loadingMessage = "Saving Client Information...";
      $scope.basicExpenses.bills = $scope.bills;
      $scope.basicExpenses.spendings = $scope.spendings;
      $scope.basicExpenses.customBills = $scope.customBills.concat($scope.taxDeductible);
      $scope.basicExpenses.customSpendings = $scope.customSpendings;
      $scope.basicExpenses.isHidden = $scope.isBasicExpensesHidden;
      
      var formData = {
        'expenses': {
          'expenses': $scope.expenses,
          'basicExpenses': $scope.basicExpenses
        }
      };

      //SAVE TO DB
      DataM.saveExpenses(formData).then(function (data) {


        if (data._message === 'Client validation failed') {
          $scope.showClientSpinner = false;

          var errorMessage = '';
          $scope.showClientSpinner = false;
          for (var prop in data.errors) {
            if (data.errors[prop].message) {
              errorMessage = prop + ': ' + data.errors[prop].message;
              break;
            }
          }
          $scope.didNextSave = false;
          alert('Error validating form data : \n' + errorMessage);
        } else {
          $state.go(location, {
            clientId: $scope.clientId
          });
          $scope.showClientSpinner = false;
        }
      }).catch(function (err) {
        console.log('Save error');
        $scope.didNextSave = false;
        $scope.showClientSpinner = false;
      });




    };

    $scope.assignOwnership = function (taxDeductibleExpense, owner) {
      
      var p = $scope.taxDeductible[$scope.taxDeductible.indexOf(taxDeductibleExpense)];


      if (owner.name == "Joint") {

        if ($scope.owners.length > 2) {
          p.ownership = {
            ownershipType: 'Joint',
            owners: [{
                percentage: 50,
                name: $scope.personalInfo.client1.fName + ' ' + $scope.personalInfo.client1.lName,
                owner: $scope.personalInfo.client1._id
              },
              {
                percentage: 50,
                name: $scope.personalInfo.client2.fName + ' ' + $scope.personalInfo.client2.lName,
                owner: $scope.personalInfo.client2._id
              }
            ],
          };
        }
      } else if (owner.name == "Tenants in Common") {
        p.ownership = {
          ownershipType: 'Joint',
          owners: [{
            percentage: 0,
            name: $scope.personalInfo.client1.fName + ' ' + $scope.personalInfo.client1.lName,
            owner: $scope.personalInfo.client1._id
          }]
        };
        if ($scope.owners.length > 2) {

          var owner2 = {
            percentage: 0,
            name: $scope.personalInfo.client2.fName + ' ' + $scope.personalInfo.client2.lName,
            owner: $scope.personalInfo.client2._id
          }
          p.ownership.owners.push(owner2);
        }
      } else {
        p.ownership = {
          ownershipType: 'Sole',
          owners: [{
            percentage: 100,
            name: owner.name,
            owner: owner.id
          }],
        };
      }
    }
  })
