angular.module('meanApp')

.controller('client-changes-controller', function($scope, DataM, Auth, $state, $stateParams,$uibModal) {
  'use strict';

    var $clientId = $stateParams.clientId;
    $scope.state = $state.current;
    $scope.params = $stateParams;
    $scope.common= {};
    $scope.client2Id = '';
    $scope.client1Id = '';
    $scope.client2FullName = '';
    $scope.showSpinner = false;
    $scope.searchText = '';
    $scope.changerEmails = [];
    Auth.isAdmin().then(isAdmin => ($scope.isAdmin = isAdmin));
    $scope.factFindPages = [
        {name:'All', value:''}, 
        {name:'Client Survey', value:'Client Survey'}, 
        {name:'Personal Info', value:'Personal Info'}, 
        {name:'Income', value:'Income'}, 
        {name:'Properties', value:'Properties'}, 
        {name:'Non-Property Assets', value:'Non-Property Assets'}, 
        {name:'Loans/Borrowings', value:'Loans/Borrowings'},
        {name:'Bill Payments', value:'Bill Payments'}, 
        {name:'Expenses', value:'Expenses'}, 
        {name:'Future Plans - Household', value:'Future Plans - Household'},
        {name:'Future Plans - Income and Expenses', value:'Future Plans - Income and Expenses'},
        {name:'Future Plans - Investments', value:'Portfolio Planning'}, 
        {name:'JSON Upload', value:'JSON Upload'},
        {name:'Proposed Lending', value:'Proposed Lending'},
        {name:'Proposed Property', value:'Proposed Property'},
        {name:'Mongo Script', value:'Mongo Script'},
        {name:'Restored History', value:'Restored History'},
     ];
     $scope.restoreData = {};
     $scope.showActionModal = function (history) {
        $scope.restoreData.selectedHistory = history;
        var modalInstance = $uibModal.open({
          animation: $scope.animationsEnabled,
          templateUrl: 'app/clientChanges/modals/action-modal.html',
          controller: 'HistoryActionController',
          resolve: {
            restoreData: function () {
              return $scope.restoreData;
            },
            difference: function(){
                return difference;
            }
          },
          windowClass:'history-action-modal'
        });
    }
    if ($clientId !== null) {

      DataM.getClientChanges().then(function (data) {

        for (var index = 0; index < data.clientHistory.length; index++) {
          var history = data.clientHistory[index];
          history.index = index;
          history.unfilteredTo = {};
          history.restoreData = {};
          if (data.clientHistory[index].from) {
            history.restoreData = JSON.parse(JSON.stringify(data.clientHistory[index].from));
          }
          if (data.clientHistory[index].to) {
            history.unfilteredTo = JSON.parse(JSON.stringify(data.clientHistory[index].to));
          }
          var propertyId = '';
          if (history.pageChanged == 'Proposed Lending') {
            propertyId = history.from.loanStructure.propertyId
          }
          if(history.from && history.to){
            
            history.fullTo = history.to;
            history.fullFrom = history.from;
            var testfrom = difference(history.from, history.to);//history.from//
            var testto = difference(history.to, history.from);//history.to//

            history.to = testto;
            history.from = testfrom;
          }

          if (history.pageChanged == 'Proposed Lending') {
            history.to.loanStructure.propertyId = propertyId;
            history.from.loanStructure.propertyId = propertyId;
          }

        }
        var clientHistory = data.clientHistory
        DataM.getSummary().then(function (data) {
            $scope.clients = data.clientSummary.personalInfo;
            $scope.restoreData.current = data.clientSummary;
            /*Set full names for ownership*/
            $scope.client1FullName = $scope.clients.client1.fName + " " + $scope.clients.client1.lName;
            $scope.client1Id = $scope.clients.client1._id;
            $scope.common.client1Id = $scope.client1Id;
            $scope.common.client1FullName = $scope.client1FullName;
            if ($scope.client1FullName == " ") {
              $scope.client1FullName = "You"
            }

            if ($scope.clients.client2) {
              $scope.client2FullName = $scope.clients.client2.fName + " " + $scope.clients.client2.lName;

              $scope.common.client2Id = $scope.client2Id;
              $scope.common.client2FullName = $scope.client2FullName;
            }
            if ($scope.client2FullName == " ") {
              $scope.client2FullName = "Your Partner"
            }
            if ($scope.clients.client2) {
              $scope.client2Id = $scope.clients.client2._id;
            }

            $scope.clientHistory = clientHistory;
            var filteredHistoryWithUniqChangers = _.uniq($scope.clientHistory, 'changedBy');

            // counter for async loop
            var getUserIDCount = 0;
            for (var i in filteredHistoryWithUniqChangers) {

                if(_.isNil(filteredHistoryWithUniqChangers[i].changedBy) !== true){
                    DataM.getUserWithId(filteredHistoryWithUniqChangers[i].changedBy).then(function (data) {
                        var userObject = {
                        id: data.id,
                        email: data.email
                        };

                        $scope.changerEmails.push(userObject);
                        getUserIDCount++;
                        if (getUserIDCount == filteredHistoryWithUniqChangers.length) {

                        // add emails to history items
                        addEmailChangers();

                        }
                    }).catch(function (err) {
                        getUserIDCount++;
                        console.log('Retrieve error',err);
                    });
                }
            }

            filterItemChanged();

          }

        ).catch(function (err) {
          console.log('Retrieve error',err);
        });
      }).catch(function (err) {
        console.log('Retrieve error',err);
      });

    }

    var addEmailChangers = function(){

        for (var index = 0; index < $scope.clientHistory.length; index++) {
            for (var index2 = 0; index2 < $scope.changerEmails.length; index2++) {
           
                 if($scope.changerEmails[index2].id == $scope.clientHistory[index].changedBy){ 
                     
                    $scope.clientHistory[index].changerEmail = $scope.changerEmails[index2].email;
                    
                    break;
                 }
            }    
             
            
            
        }
       
    }
    
    $scope.computeSurveyScore = function(quiz){
        var total = 0;
        for ( var key in quiz) {
            if(key != '_id') {
                total += quiz[key];
            }
            
        }
        return total;
    }

    $scope.filterExpense = function(expense) {

        if(expense.tier1) {
            if(expense.tier1 == ' '){
                return true;
            }
        } else {
            if(!expense.ownership) {
                return true;
            }
        }
        return false;
    }
    // used for checking if no difference from toObject and fromObject
    var removeIds = function(objectWithId) {
        if(typeof objectWithId == 'object'){
            for (var key in objectWithId) {
                if(key == '_id'){
                    delete objectWithId[key];
                }
                else {
                    if(typeof objectWithId[key] == 'object') {
                    removeIds(objectWithId[key]);   
                }
                }
            }
        }
    }
 var updateNullProperties = function(objectWithNullProp) {
    for (var key in objectWithNullProp) {
        if(objectWithNullProp[key]== null || objectWithNullProp[key] == ''){
            objectWithNullProp[key] = ' ';
        } else if(typeof objectWithNullProp[key] == 'object') {
            updateNullProperties(objectWithNullProp[key]);
        }   
    }
 }

 var removeSameProperties = function(fromObject, toObject) {

        // check if is object
        if(typeof fromObject == 'object' && typeof toObject == 'object'){



        if(_.isArray(fromObject) && _.isArray(toObject)){
        
            for (var index in toObject) {
                if(toObject[index]._id){
                    var objectToCompare = _.find(fromObject, {_id: toObject[index]._id});
                    if(_.isEqual(objectToCompare,toObject[index])) {
                        var indexOfToObj = _.indexOf(toObject, toObject[index]);
                        var indexOfFromObj = _.indexOf(fromObject, objectToCompare);
                        toObject[key].splice(indexOfToObj, 1);
                        fromObject[key].splice(indexOfFromObj, 1);
                    }
                }
            }
            for (var index in fromObject) {
                if(fromObject[index]._id){
                    var objectToCompare = _.find(toObject, {_id: fromObject[index]._id});

                    if(_.isEqual(objectToCompare,fromObject[index])) {

                        var indexOfToObj = _.indexOf(toObject, objectToCompare );
                        var indexOfFromObj = _.indexOf(fromObject,fromObject[index] );
                        toObject[key].splice(indexOfToObj, 1);
                        fromObject[key].splice(indexOfFromObj, 1);
                    }
                }
            }
        }
        else {
          for (var key in toObject) {
            if(fromObject[key]){
                if(angular.equals(toObject[key], fromObject[key]) && key != '_id') {
                    delete fromObject[key];
                    delete toObject[key];
                }
                 else {
                    
                    if(typeof toObject[key] == 'object' && _.isArray(toObject[key]) != true) {
                      try{
                      removeSameProperties(fromObject[key], toObject[key]);   
                      } catch(error) {
                          console.log(error);
                      }
                    } else if(_.isArray(toObject[key])) {
                        
                        for(var i= 0; i<toObject[key].length; i++) {
                            
                            if(_.isArray(toObject[key])) {
                                for(var i2= 0; i2<fromObject[key].length; i2++) {

                                    if(angular.equals(toObject[key][i], fromObject[key][i2]) && key != '_id') {
                                            delete fromObject[key][i];
                                            delete toObject[key][i2];
                                        } else {
                                        removeSameProperties(fromObject[key][i2], toObject[key][i]);  
                                    }
                                }
                            }
                        }
                    }
                    
                }
            } else {
              if(fromObject.hasOwnProperty(key) && key != '_id') {
                if(toObject.hasOwnProperty(key)) {
                  if(angular.equals(toObject[key], fromObject[key])) {
                    delete fromObject[key];
                    delete toObject[key];
                 }
                }
              }
            }
          }
        }
        }

          
        
    }
    /**
     * Deep diff between two object, using lodash
     * @param  {Object} object Object compared
     * @param  {Object} base   Object to compare with
     * @return {Object}        Return a new object who represent the diff
     */
   var difference = function (object, base) {
        function changes(object, base) {
            return _.transform(object, function(result, value, key) {
                if (!_.isEqual(value, base[key])) {
                    result[key] = (_.isObject(value) && _.isObject(base[key])) ? changes(value, base[key]) : value;
                }
            });
        }
        return changes(object, base);
    }

    var formatArrays = function(objectWithArray){
        for(var key in objectWithArray) {
            
            if( angular.isArray(objectWithArray[key])) {
                 var arrayInObject = objectWithArray[key];
                 arrayInObject = arrayInObject.reverse();
            } else {
                for(var key2 in objectWithArray[key]) {
                    if( angular.isArray( objectWithArray[key][key2])) {
                       
                        var arrayInObject = objectWithArray[key][key2];
                        arrayInObject = arrayInObject.reverse();
                    }
                }
            }
        }
    }
    
    $scope.getKeys = function(obj){ return Object.keys(obj) } 

    $scope.formatDate = function(isoDateString){
        if(isoDateString) {
            var date = new Date(isoDateString);
            var formattedDate = date.getMonth() + "/" + date.getDate() +"/" +date.getFullYear(); 
            return formattedDate;
        }
    }
    
    $scope.getName = function(clientId) {
        if(clientId === $scope.client1Id) {
            return $scope.client1FullName;
        }
        if(clientId === $scope.client2Id) {
            return $scope.client2FullName;
        }
        for(var i = 0; i < $scope.clients.dependants.length; i++){
            if(clientId === $scope.clients.dependants[i]._id) {
                return $scope.clients.dependants[i].name;
            }
        }
        return '';
    }
    $scope.formatDateTime = function(dateTime) {
        var date = new Date(dateTime);
        console.log(date.toLocaleDateString("en-AU"));
       
    }
    $scope.itemChanges = [];
    $scope.currentIndex = 0;
    var filterItemChanged = function(){
        
        for(var i =0; i < $scope.clientHistory.length; i++) {
            var history = $scope.clientHistory[i];
            var itemChanges = [];
            $scope.currentIndex = i;
            $scope.itemChanges[i] = [];
            history.itemChanged = JSON.parse(JSON.stringify(history.to));

            
                getChangedItem(history.unfilteredTo, history.to);
                for(var i2 =0; i2 <$scope.itemChanges[i].length; i2++){
 
                    replaceObjectWithId($scope.itemChanges[i][i2], history.itemChanged);

                }
            
        }
        
    }
    var getChangedItem = function(fromObject, toObject) {

        if(typeof fromObject == 'object' && typeof toObject == 'object' && fromObject && toObject) {
        if(fromObject.hasOwnProperty('_id')) {
                if(toObject.hasOwnProperty('_id')) {
                    
                    if(toObject._id === fromObject._id){
                        var newObject = JSON.parse(JSON.stringify(fromObject));
                       
                        $scope.itemChanges[$scope.currentIndex].push(newObject);
                       
                        
                    } else {
                        for (var key in fromObject) {
                            
                            for(var key2 in toObject)
                            {
                                
                                getChangedItem(fromObject[key], toObject[key2]);   
                            }
                        }
                    }
                } else {
                     
                        for (var key in fromObject) {
                            
                            for(var key2 in toObject)
                            {
                                getChangedItem(fromObject[key], toObject[key2]);   
                            }
                        }
                }
            } else {
                for (var key in fromObject) {
                            for(var key2 in toObject)
                            {
                                getChangedItem(fromObject[key], toObject[key2]);   
                            }
                        }
            }
        }
    }
    var modify = function (obj, newObj) {



        Object.keys(obj).forEach(function(key) {
            delete obj[key];
        });

        Object.keys(newObj).forEach(function(key) {
            obj[key] = newObj[key];
        });
    
    }
    var replaceObjectWithId = function(objectToReplace, mainObject){
        
        if(mainObject.hasOwnProperty('_id')) {
            if(objectToReplace.hasOwnProperty('_id')) {
                if(mainObject._id == objectToReplace._id) {
                    
                    mainObject = _.clone(objectToReplace);
                    
                   
                } else {
                    for(var key in mainObject){
                        if(mainObject[key]){
                            if(mainObject[key]._id == objectToReplace._id) {
                                
                                mainObject[key] = _.clone(objectToReplace);
                                
                            
                            }
                            else if(typeof mainObject[key] =='object')
                            {
                                replaceObjectWithId(_.clone(objectToReplace),mainObject[key]);
                            }
                        } else {
                            
                            delete mainObject[key];
                        }
                    } 
                }
            }
        } else {
            
            for(var key in mainObject){
                if(mainObject[key]){
                    if(typeof mainObject[key] == 'object')
                    {
                         if(mainObject[key].hasOwnProperty('_id')) {
                            if(objectToReplace.hasOwnProperty('_id')) {
                                if(mainObject[key]._id == objectToReplace._id) {
                                    
                                    mainObject[key] = _.clone(objectToReplace);
                                    
                                    //modify(mainObject,objectToReplace);
                                
                                } else {

                                    replaceObjectWithId(_.clone(objectToReplace),mainObject[key]);
                                }
                            }
                         }
                    }
                } else {
                     
                    delete mainObject[key];
                }
            }

        }

    }
    // $scope.restoreState = function(restoreItem, pageChanged){
        
    //     $scope.showSpinner = true;
    //     if(pageChanged==='Client Survey') {
    //         DataM.saveClientSurvey(restoreItem).then(function (data) {
    //         console.log('Client Survey Page 0 save data:', data);
            
    //             $state.reload();
    //             $scope.showSpinner = false;
            
    //         }).catch(function (err) {
    //             console.log(err);
    //             $scope.showSpinner = false;
    //         });
    //     } 
    //     else if (pageChanged === 'Personal Info'){
    //         DataM.savePersonalInfo(restoreItem).then(function (data) {
    
    //             $scope.showSpinner = false;
    //             $state.reload();
            
    //         }).catch(function (err) {
    //             console.log(err);
    //             $scope.showSpinner = false;
    //         });
            
    //     }
    //     else if (pageChanged === 'Income'){

    //         console.log('Client Survey Page 2 save data:', restoreItem);
    //         DataM.saveIncome(restoreItem).then(function (data) {
    //         console.log('Client Survey Page 3 save data:', data);

    //             $scope.showSpinner = false;
    //             $state.reload();
            
    //         }).catch(function (err) {
    //             console.log(err);
    //             $scope.showSpinner = false;
    //         });
    //     }
    //     else if (pageChanged === 'Properties'){

    //         DataM.saveProperties(restoreItem).then(function (data) {
    //         console.log('saveProperties:', data);

    //             $scope.showSpinner = false;
    //             $state.reload();
            
    //         }).catch(function (err) {
    //             console.log(err);
    //             $scope.showSpinner = false;
    //         });

    //     }
    //     else if (pageChanged === 'Non-Property Assets'){
    //         DataM.saveOtherAssets(restoreItem).then(function (data) {
    //             $scope.showSpinner = false;
    //             $state.reload();
            
    //         }).catch(function (err) {
    //             console.log(err);
    //             $scope.showSpinner = false;
    //         });
    //     }
    //     else if (pageChanged === 'Loans/Borrowings'){
    //         DataM.savePropertyLoans(restoreItem).then(function (data) {
    //             $scope.showSpinner = false;
    //             $state.reload();
            
    //         }).catch(function (err) {
    //             console.log(err);
    //             $scope.showSpinner = false;
    //         });
    //     }
    //     else if (pageChanged === 'Bill Payments'){
    //         DataM.saveBillPayments(restoreItem).then(function (data) {
    //             $scope.showSpinner = false;
    //             $state.reload();
            
    //         }).catch(function (err) {
    //             console.log(err);
    //             $scope.showSpinner = false;
    //         });
    //     }
    //     else if (pageChanged === 'Expenses'){
    //         DataM.saveExpenses(restoreItem).then(function (data) {
    //             $scope.showSpinner = false;
    //             $state.reload();
            
    //         }).catch(function (err) {
    //             console.log(err);
    //             $scope.showSpinner = false;
    //         });
    //     }
    //     else if (pageChanged === 'Future Plans - Household'){
    //         DataM.saveFutureHouseholdPlans(restoreItem).then(function (data) {
    //             $scope.showSpinner = false;
    //             $state.reload();
            
    //         }).catch(function (err) {
    //             console.log(err);
    //             $scope.showSpinner = false;
    //         });
    //     }
    //     else if (pageChanged === 'Future Plans - Income and Expenses'){
    //         DataM.saveFutureIncomeChanges(restoreItem).then(function (data) {
    //             $scope.showSpinner = false;
    //             $state.reload();
            
    //         }).catch(function (err) {
    //             console.log(err);
    //             $scope.showSpinner = false;
    //         });
    //     }
    // }

})
.directive('nestedItem', ['$compile', function($compile){
    return {
        restrict: 'E',
        link: function(scope, element){
            if (scope.item.children){
                var html = $compile('<ul><li nested-item  ng-repeat="(key, val) in item">{{key} : {{val}}</li></ul>')(scope);
                element.append(html);
            }
        },
        templateUrl: 'client-changes.html'
    };
}])
.directive('addressItem', ['$compile', function($compile){
   
    return {
        restrict: 'E',
        scope:{
            address:"=address"
        },
        templateUrl: 'app/clientChanges/common/address-item.html'
        };
    }])

.directive('personalInfoItem', ['$compile', function($compile){

return {
    restrict: 'E',
    scope:{
        personalInfo:"=personalInfo",
        formatDate:"=formatDate"
    },
    templateUrl: 'app/clientChanges/personalInfo/personal-info-item1.html'
    };
}])

.directive('propertyItem', ['$compile', function($compile){

return {
    restrict: 'E',
    scope:{
        property:"=property",
        formatDate:"=formatDate",
        index:"=index",
        getName:"=getName"
    },
    templateUrl: 'app/clientChanges/assets/property-item.html'
    };
}])
.directive('assetItem', function(){

return {
    restrict: 'E',
    scope:{
        asset:"=asset",
        formatDate:"=formatDate",
        index:"=index",
        getName:"=getName"
    },
    templateUrl: 
    function(element, attrs) {
        switch(attrs.assetType){
            case 'Bank Account':
                return 'app/clientChanges/assets/bank-account-item.html';
            case 'Investment':
                return 'app/clientChanges/assets/investment-item.html';
            case 'Life Insurance':
                return 'app/clientChanges/assets/life-insurance-item.html';
            case 'Super Fund':
                return 'app/clientChanges/assets/superfund-item.html';
            case 'Other Asset':
                return 'app/clientChanges/assets/other-asset-item.html';
            case 'Vehicle':
                return 'app/clientChanges/assets/vehicle-item.html';
        }
    }
   
    };
})

.directive('incomeItem', function(){

return {
    restrict: 'E',
    scope:{
        income:"=income",
        common: "=common"
    },
    templateUrl: 'app/clientChanges/incomes/income-item.html'
    }
}) 

.directive('employmentItem', function(){

return {
    restrict: 'E',
    scope:{
        employment:"=employment",
        other:"=otherIncome",
        deduction:"=deduction",
        creditHistory:"=creditHistory",
        formatDate:"=formatDate",
        thirdParty:"=thirdParty",
        index:"=index",
        getName:"=getName"
    },
    templateUrl: 
    function(element, attrs) {
        switch(attrs.employmentItemType){
            case 'Employment':
                return 'app/clientChanges/incomes/employment-item.html';
            case 'Deduction':
                return 'app/clientChanges/incomes/deduction-item.html';
            case 'Other Income':
                return 'app/clientChanges/incomes/other-income-item.html';
            case 'Credit History':
                return 'app/clientChanges/incomes/credit-history-item.html';
            case 'Third Party':
                return 'app/clientChanges/incomes/third-party-item.html';
        }
    }
   
}})    

.directive('employmentSubItem', function(){

return {
    restrict: 'E',
    scope:{
        job:"=job",
        business:"=business",
        formatDate:"=formatDate",
    },
    templateUrl: 
    function(element, attrs) {
        switch(attrs.employmentSubItemType){
            case 'Job':
                return 'app/clientChanges/incomes/job-item.html';
            case 'Business':
                return 'app/clientChanges/incomes/business-item.html';
        }
    }
   
}})    
.directive('ownershipItem', function(){

return {
    restrict: 'E',
    scope:{
        ownership:"=ownership",
        getName:"=getName",
        formatDate:"=formatDate",
    },
    templateUrl: 'app/clientChanges/common/ownership-item.html'
   }
}) 

.directive('loanItem', function(){

return {
    restrict: 'E',
    scope:{
        borrowing:"=borrowing",
        getName:"=getName",
        formatDate:"=formatDate",
        index:"=index"
    },
    templateUrl: 'app/clientChanges/borrowings/loan-item.html'
   }
})
.directive('expenseDetailItem', function(){

return {
    restrict: 'E',
    scope:{
        expense:"=expense",
        getName:"=getName",
        formatDate:"=formatDate",
        index:"=index"
    },
    templateUrl: 'app/clientChanges/expenses/expense-detail-item.html'
   }
}) 
.directive('futurePlansItem', function(){

return {
    restrict: 'E',
    scope:{
        futurePlans:"=futurePlans",
        getName:"=getName",
        formatDate:"=formatDate",
        type:"=type",
        common:"=common"
    },
    templateUrl: 'app/clientChanges/futurePlans/future-plans-item.html'
   }
}) 
.directive('existingFamilyDepItem', function(){

return {
    restrict: 'E',
    scope:{
        existingFamilyDep:"=existingFamilyDep",
        getName:"=getName",
        formatDate:"=formatDate",
    },
    templateUrl: 'app/clientChanges/futurePlans/existingfamilydep-item.html'
   }
}) 
.directive('familyPlansItem', function(){

return {
    restrict: 'E',
    scope:{
        familyPlans:"=familyPlans",
        getName:"=getName",
        formatDate:"=formatDate",
    },
    templateUrl: 'app/clientChanges/futurePlans/family-plans-item.html'
   }
})
.directive('clientSurveyItem', function(){

return {
    restrict: 'E',
    scope:{
        clientSurvey:"=clientSurvey",
        computeSurveyScore:'=computeSurveyScore'
    },
    templateUrl: 'app/clientChanges/client-survey/client-survey-item.html'
   }
})    
.directive('portfolioPlanningItem', function(){

    return {
        restrict: 'E',
        scope:{
            portfolioPlanning:"=portfolioPlanning",
            getName:"=getName",
            formatDate:"=formatDate",
            type:"=type",
            common:"=common"
        },
        templateUrl: 'app/clientChanges/portfolioPlanning/portfolio-planning-item.html'
       }
}) 

.directive('plannedInvestmentPropertyItem', function(){

    return {
        restrict: 'E',
        scope:{
            plannedInvestmentProperty:"=plannedInvestmentProperty",
            getName:"=getName",
            formatDate:"=formatDate",
            type:"=type",
            common:"=common",
            index:"=index",
        },
        templateUrl: 'app/clientChanges/portfolioPlanning/planned-investment-property.html'
       }
})    

.directive('plannedInvestmentOtherItem', function(){

    return {
        restrict: 'E',
        scope:{
            plannedInvestment:"=plannedInvestment",
            getName:"=getName",
            formatDate:"=formatDate",
            type:"=type",
            common:"=common",
            index:"=index",
        },
        templateUrl: 'app/clientChanges/portfolioPlanning/planned-investment.html'
       }
})    

.directive('plannedInvestmentRenovationItem', function(){

    return {
        restrict: 'E',
        scope:{
            plannedInvestmentRenovation:"=plannedInvestmentRenovation",
            getName:"=getName",
            formatDate:"=formatDate",
            type:"=type",
            common:"=common",
            index:"=index",
        },
        templateUrl: 'app/clientChanges/portfolioPlanning/planned-investment-renovation.html'
       }
})   
.directive('plannedCashTransferItem', function(){

    return {
        restrict: 'E',
        scope:{
            plannedCashTransfer:"=plannedCashTransfer",
            getName:"=getName",
            formatDate:"=formatDate",
            type:"=type",
            common:"=common",
            index:"=index",
        },
        templateUrl: 'app/clientChanges/portfolioPlanning/planned-cash-transfer.html'
       }
})

.directive('plannedLoanItem', function(){

    return {
        restrict: 'E',
        scope:{
            plannedLoan:"=plannedLoan",
            getName:"=getName",
            formatDate:"=formatDate",
            type:"=type",
            common:"=common",
            index:"=index",
        },
        templateUrl: 'app/clientChanges/portfolioPlanning/planned-loan.html'
       }
})

.directive('loanStructureItem', function(){
    return {
        restrict: 'E',
        scope:{
            loanStructure:"=loanStructure",
            type:"=type",
            common:"=common",
        },
        templateUrl: 'app/clientChanges/loanStructure/proposed-lending.html'
       }
})
.directive('proposedPropertyItem', function(){
    return {
        restrict: 'E',
        scope:{
            proposedProperty:"=proposedProperty",
            type:"=type",
            common:"=common",
        },
        templateUrl: 'app/clientChanges/proposedProperty/proposed-property.html'
       }
})

angular.module('meanApp').controller('HistoryActionController', function ($scope, $uibModalInstance,restoreData, difference, DataM) {

    $scope.showSpinner = false;
    $scope.transferType = null;
    $scope.currentShown = false;
    $scope.historyType = "from";
    $scope.restoreData = restoreData;
    $scope.historyData = $scope.restoreData.selectedHistory.fullFrom;
    $scope.selectedHistoryType = $scope.restoreData.selectedHistory.pageChanged;
    $scope.historyMergedData = {};
    $scope.showType = 'diff';
    $scope.result = null;
    $scope.diffHistory = {
        current: { 
            to: difference($scope.restoreData.current, $scope.restoreData.selectedHistory.fullTo),
            from: difference($scope.restoreData.current, $scope.restoreData.selectedHistory.fullFrom)
        },
        history:{
            to: difference($scope.restoreData.selectedHistory.fullTo,$scope.restoreData.current),
            from: difference($scope.restoreData.selectedHistory.fullFrom,$scope.restoreData.current)
        },
            
        };
    console.log(' $scope.restoreData ',$scope.restoreData );
    console.log(' $scope.diffHistory ',$scope.diffHistory );
    $scope.showHideHistoryCurrent = function(){
        $scope.currentShown =  !$scope.currentShown;
        $scope.restoreResult = false;
        $scope.onChangeSelectedHistoryType();
    }
    $scope.showHideRestoreResult = function(){

        $scope.restoreResult =  !$scope.restoreResult;
        $scope.currentShown = false;
      
       
        $scope.getSampleOutput($scope.historyType);
        
    }

    $scope.getSampleOutput = function(historyType){

        let selectedHistory =  $scope.restoreData.selectedHistory.fullTo;
        $scope.showSpinner = true;
        if (historyType == 'from') {
            selectedHistory = $scope.restoreData.selectedHistory.fullFrom;
        }
        let payload = {
            history: selectedHistory,
            
        }
        $scope.sampleHistoryOutput = {};
        DataM.getSampleHistoryMerge(payload).then(function(data){
            if ($scope.showType == 'diff') {
                $scope.sampleHistoryOutput = difference(data.sampleOutput,filterCurrent($scope.restoreData.current, data.sampleOutput));
            }   else {
                $scope.sampleHistoryOutput = data.sampleOutput;
            }
            $scope.historyMergedData = data.sampleOutput;
            $scope.showSpinner = false;
        });
        
    }

    $scope.onChangeSelectedHistoryType = function () {
        if ($scope.showType == 'full') {
            if ($scope.historyType == 'to') {
                $scope.historyData = $scope.restoreData.selectedHistory.fullTo;
                $scope.current = $scope.restoreData.current;
            } else {
                $scope.historyData = $scope.restoreData.selectedHistory.fullFrom;
                $scope.current = $scope.restoreData.current;
            }
        } else {
            if ($scope.historyType == 'to') {
                $scope.historyData =  $scope.diffHistory.history.to;
                $scope.current = $scope.diffHistory.current.to;
            } else {
                $scope.historyData = $scope.diffHistory.history.from;
                $scope.current = $scope.diffHistory.current.from;
            }
        }
        $scope.getSampleOutput($scope.historyType);
    }
    $scope.close = function () {
      $uibModalInstance.dismiss('cancel');
    };
    $scope.checkExists = function(item){
            return !_.isNil(item);
    }
    $scope.toggleShowType = function(showType){
      
        $scope.showType  = showType;
        $scope.onChangeSelectedHistoryType();
    }

    function filterCurrent(current, sampleOutput){
        let sampleOutputKeys = Object.keys(sampleOutput);
        let currentKeys = Object.keys(current);
        let pCurrent = current;
        for(var i=0; i< currentKeys.length;i++){
            if(sampleOutputKeys.includes(currentKeys[i]) == false){
                delete pCurrent[currentKeys[i]];
            }
        }
        return pCurrent;
    }

    $scope.restore = function(){
        let payload = {
            history:  $scope.historyMergedData,
            pageChanged: $scope.selectedHistoryType
        }
        $scope.showSpinner = true;
        DataM.restoreClientWithHistory(payload).then(function(data){
            // Done merge 
            // TODO Result or error
            $scope.showSpinner = false;
            $scope.result = data;
        });
    }
    $scope.showSpinner = true;
    DataM.getSummary().then(function (data) {
        $scope.showSpinner = false;
        $scope.restoreData.current = data.clientSummary;
    });
  });