function UserService($resource) {
  'ngInject';

  return $resource('/api/users/:id/:controller', {
    id: '@_id'
  }, {
    changePassword: {
      method: 'PUT',
      params: {
        controller: 'password'
      }
    },
    get: {
      method: 'GET',
      params: {
        id: 'me'
      }
    },
    forgotPassword: {
      method: 'PUT',
      params: {
        id: 'forgotPassword'
      }
    },
    acceptTC: {
      method: 'PUT',
      params: {
        controller: 'acceptTC'
      }
    }
  });
}

export default UserService;
