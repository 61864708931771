angular.module('meanApp')
  .controller('short-fact-find-controller', function ($scope, $rootScope, $state, $stateParams, $filter, DataM, $window, Auth, empowerDataService, $timeout, Idle, DataHelper,$document) {
    DataM.getSummary().then(function (data) {
      const client = data.clientSummary;
      if(client.surveyComplete) {
        $state.go('dashboard')
      }
    });
    
    $scope.isAdmin = false;
    Auth.isAdmin().then(isAdmin => ($scope.isAdmin = isAdmin));

    $scope.empowerData = empowerDataService;
    $scope.showRequiredField = false;

    // Goals
    $scope.topicsToDiscuss = {};
    $scope.timeAllocated = {
      other: []
    };
    // Personal Info
    $scope.dependants = [];
    $scope.personalInfo = {};
    $scope.client1 = {
      lName:""
    };
    $scope.client2 = {
      lName:""
    };
    $scope.hasPartner = false;
    $scope.hasDependants = false;
    var ownership1 = {
      ownershipType : "Sole",
      owners:[ 
          {
              "owner":$scope.client1._id,
              "percentage" : 100
          }
      ]
    };

    $scope.annualRentalIncome = {
      client1GrossRentalIncome: null,
      client2GrossRentalIncome: null
    };

    $scope.numberOfDependants = "0";
    $scope.setUpDependants = function(numString) {
      $scope.dependants.splice(0, $scope.dependants.length)
      for(var i =0; i<numString; i++){
        $scope.dependants.push({});
      }
    }
    $scope.isBrowserNotSupported=false;

    $scope.ageChanged = function(client){
      client.ageEnteredOn = new Date();
    }

    var getBrowserUsed = function() {
      var userAgent = $window.navigator.userAgent;
      var browsers = {chrome: /chrome/i, safari: /safari/i, firefox: /firefox/i, ie: /internet explorer/i};

      for(var key in browsers) {
          if (browsers[key].test(userAgent)) {

            return key;
          }
      };
    }

    if(getBrowserUsed() !== "chrome"){
      $timeout(function() {  $scope.isBrowserNotSupported=true;}, 3000);
    }
    // Income
    $scope.client1MainEmployment = {};
    $scope.client2MainEmployment = {};
    $scope.otherIncome1 = [];
    $scope.otherIncome2 = [];
    $scope.clientNotes = "";
    // Expenses
    $scope.annualBills = {
      basic: null,
      discretionary: null,
      amount: null,
      frequency:'Annually'
    };

    $scope.annualSpendings = {
      basic: null,
      discretionary: null,
      amount: null,
      frequency:'Annually'
    };


    // Loans
    $scope.hasHomeLoan = false;
    $scope.hasInvestmentLoan = false;
    $scope.hasCCLoan = false;
    $scope.hasPersonalLoan = false;

    $scope.homeLoan = {
      type: "Home Loan - Basic Variable",
      borrower:ownership1,
      repaymentFreq:"Yearly"
    }
    $scope.investmentLoan = {
      type: "Investment Loan - Basic Variable",
      borrower:ownership1,
      repaymentFreq:"Yearly"
    }
    $scope.personalLoan = {
      type: "Personal Loan",
      borrower:ownership1,
      repaymentFreq:"Yearly"
    }
    $scope.creditCard = {
      type: "Credit Card",
      borrower:ownership1
    }

    // Assets
    $scope.properties = [];
    $scope.hasHome = false;
    $scope.ownHome = {
      purpose: "Own Home",
      ownership:ownership1
    };
    $scope.investmentProperty = {
      purpose: "Investment",
      ownership:ownership1
    };
    // client1
    $scope.superFund1 = {
      ownership:ownership1
    }
    // client2
    $scope.superFund2 = {
      isClient2Owner:true
    }

    $scope.investment = {
      type: "Other",
      ownership:ownership1
    }

    $scope.bankAccount = {
      type: "Other",
      ownership:ownership1
    }

    $scope.otherAsset = {
      desc: "Total Other Assets (Cars, Jewelry, etc)",
      ownership:ownership1
    }
    $scope.addOtherIncome = function (otherIncome) {
      var income = {};
      otherIncome.push(income);
    }

    $scope.removeOtherIncome = function (income, otherIncome) {
      otherIncome.splice(otherIncome.indexOf(income), 1);
    }

    $scope.addNewDependancy = function () {
      var dependant = {};
      $scope.dependants.push(dependant);
    }

    $scope.removeDependant = function (dependant) {
      $scope.dependants.splice($scope.dependants.indexOf(dependant), 1);
    }


    $scope.computeAmount = function (expense) {

      if (typeof expense.basic != "undefined" && typeof expense.discretionary != "undefined") {
        expense.amount = expense.discretionary + expense.basic;
        expense.totalString = $filter('number')(expense.amount, 2);
      }

    }

    function formatClientData(formData) {
      $scope.properties = [];
      formData.clientNotes = $scope.clientNotes;
      formData.clientSurvey = {
        timeAllocated: $scope.timeAllocated,
        topicsToDiscuss: $scope.topicsToDiscuss
      };
      formData.personalInfo = {};
      formData.personalInfo.client1 = $scope.client1;
      formData.personalInfo.dependants = $scope.dependants;
      formData.expenses = {
        annualBills: $scope.annualBills,
        annualSpendings: $scope.annualSpendings
      };

      formData.income = {};
      formData.income.client1PersonalIncome = {};
      formData.income.client1PersonalIncome.currentEmployment = [];
      formData.income.client1PersonalIncome.other = [];
      formatIncome(formData,$scope.otherIncome1,true);
      //formatRentalIncome(formData, true);
      formData.income.client1PersonalIncome.currentEmployment.unshift($scope.client1MainEmployment);
      if ($scope.hasPartner) {
        formData.personalInfo.client2 = $scope.client2;
        formData.income.client2PersonalIncome = {};
        formData.income.client2PersonalIncome.currentEmployment = [];
        formData.income.client2PersonalIncome.other = [];
        formatIncome(formData,$scope.otherIncome2,false);
        //formatRentalIncome(formData, false);

        formData.income.client2PersonalIncome.currentEmployment.unshift($scope.client2MainEmployment);
      }

      var borrowing = [];
      if ($scope.hasCCLoan) {
        borrowing.push($scope.creditCard);
      }
      if ($scope.hasHomeLoan) {
        borrowing.push($scope.homeLoan);
      }
      if ($scope.hasInvestmentLoan) {
        borrowing.push($scope.investmentLoan);
      }
      if ($scope.hasPersonalLoan) {
        borrowing.push($scope.personalLoan);
      }
      formData.borrowings = {
        borrowing: borrowing
      };

      formData.assets = {
        bankAccounts: [],
        investments: [],
        otherAssets:[],
        superFunds:[],
        properties:[],
        annualRentalIncome: $scope.annualRentalIncome
      };

      if($scope.hasHome){
        $scope.properties.unshift($scope.ownHome);
      }
      if($scope.bankAccount.balance > 0) {
        formData.assets.bankAccounts.push($scope.bankAccount);
      }
      if($scope.investmentProperty.currentValue > 0) {
        $scope.properties.push($scope.investmentProperty);
      }
      if($scope.superFund1.value > 0) {
        formData.assets.superFunds.push($scope.superFund1);
      } 
      if($scope.superFund2.value > 0) {
        formData.assets.superFunds.push($scope.superFund2);
      }
      if($scope.investment.currentValue > 0){
        formData.assets.investments.push($scope.investment);
      }
      if($scope.otherAsset.value > 0) {
        formData.assets.otherAssets.push($scope.otherAsset);
      }
      formData.assets.properties = $scope.properties;

    }

    /**
     * Process the rental income to the client data
     * @param formData reference to client data to submit
     * @param otherIncome reference to the income array that contains rental income
     * @param isClient1 flag to check where to assign the rental income
     * 
     */
    function formatRentalIncome(formData, isClient1) {
      var grossRental = 0;
      var otherIncome = [];

      if(isClient1){
        otherIncome = $scope.otherIncome1;
      } else {
        otherIncome = $scope.otherIncome2; 
      }
      if(otherIncome.length > 0) {
        // check for Gross Rental Income
        for(var i = 0; i < otherIncome.length; i++) {
          var other = otherIncome[i];
          if(other.type === "Gross Rental Income") {
            if(other.salary){
              grossRental += other.salary;
            }
          }
        }
        if(isClient1){
          $scope.annualRentalIncome.client1GrossRentalIncome = grossRental;
        } else {
          $scope.annualRentalIncome.client2GrossRentalIncome = grossRental; 
        }
      }
    }

    function formatIncome(formData, otherIncomeArray, isClient1) {

      for (var i = 0; i < otherIncomeArray.length; i++) {
        var other = otherIncomeArray[i];
        
        var selfEmp = {
          employmentType: other.type,
          business: {
            basis: "Sole trader",
            earnings: [{
              salary: other.salary
            }]
          }
        }

        var otherIncome = {
          desc:"Other income",
          amount: other.salary
        }

        if(isClient1){
          if($scope.client1MainEmployment.type === "PAYG") {
            if(other.type === "Overtime") {
              $scope.client1MainEmployment.job.overtime = other.salary;
            } else if(other.type === "Bonus") {
              $scope.client1MainEmployment.job.bonus = other.salary;
            }
          }
          if (other.type === "Self employed") {
            formData.income.client1PersonalIncome.currentEmployment.push(selfEmp);
          }
          if(other.type === "Other") {
            formData.income.client1PersonalIncome.other.push(otherIncome);
          }
        } else {
          if($scope.client2MainEmployment.type === "PAYG") {
            if(other.type === "Overtime") {
              $scope.client2MainEmployment.job.overtime = other.salary;
            } else if(other.type === "Bonus") {
              $scope.client2MainEmployment.job.bonus = other.salary;
            }
          }
          if (other.type === "Self employed") {
            formData.income.client2PersonalIncome.currentEmployment.push(selfEmp);
          }
          if(other.type === "Other") {
            formData.income.client2PersonalIncome.other.push(otherIncome);
          }
        }
      }
    }

    $scope.formatMainIncome = function(employment) {

      employment.employmentType = employment.type;
      employment.frequency = "Yearly";
      if (employment.type === "PAYG") {
        if (employment.frequency && !_.isNaN(employment.baseSalary)) {
       
          employment.job = {
            basis:"Full time",
            annualSalary: getAnnual(employment.baseSalary,employment.frequency)
          }
          employment.business = null;
        }
      } else if(employment.type ==="Business" || employment.type ==="Self employed"){
          employment.business = {
              basis: "Sole trader",
              earnings: [{
                salary: getAnnual(employment.baseSalary,employment.frequency)
              }]
          }
          employment.job = null;
      } else {
        employment.job = null;
        employment.business = null;
      }
    }

    function getAnnual(amount, frequency) {
      var annual = 0;
      if (amount > 0) {
        switch (frequency) {
          case "Weekly":
            annual = (amount * 52);
            break;
          case "Fortnightly":
            annual = (amount * 26);
            break;
          case "Monthly":
            annual = amount * 12;
            break;
          case "Every 2 months":
            annual = amount * 6;
            break;
          case "Every 3 months":
            annual = amount * 4;
            break;
          case "Every 6 months":
            annual = amount * 2;
            break;
          case "Yearly":
            annual = amount;
            break;
        }
      }
      return annual;
    }

    $scope.save = function () {

     $scope.showClientSpinner = true;

      // Format clientSchema here
      if(formIsValid() === false) {
        return false;
      }
      var formData = {
        hasPartner: $scope.hasPartner,
      };
      formatClientData(formData);

      const client1GrossRentalIncome = formData.assets.annualRentalIncome.client1GrossRentalIncome;
      const isNullUndefinedRentalIncomeClient1 = _.isNil(client1GrossRentalIncome);

      if(client1GrossRentalIncome != 0 && !isNullUndefinedRentalIncomeClient1) {
        formData.assets.properties.push({
            "address": {
                "country": "Australia",
            },
            "purpose": "Investment",
            "ownership": {
                "ownershipType": "Sole",
                "owners": [{
                    "owner": formData.personalInfo.client1._id,
                    "percentage": 100
                }]
            },
            "grossRentalIncome": formData.assets.annualRentalIncome.client1GrossRentalIncome
        })
      }

      const client2GrossRentalIncome = formData.assets.annualRentalIncome.client2GrossRentalIncome;
      const isNullUndefinedRentalIncomeClient2 = _.isNil(client2GrossRentalIncome);

      if(client2GrossRentalIncome != 0 && !isNullUndefinedRentalIncomeClient2) {
        formData.assets.properties.push({
            "address": {
                "country": "Australia",
            },
            "purpose": "Investment",
            "ownership": {
                "ownershipType": "Sole",
                "owners": [{
                    "owner": formData.personalInfo.client2._id,
                    "percentage": 100
                }]
            },
            "grossRentalIncome": formData.assets.annualRentalIncome.client2GrossRentalIncome
        })
      }
      
      //SAVE TO DB
      
      DataM.saveShortFactFind(formData).then(function (data) {
        
        $scope.showClientSpinner = false;
        $state.go('dashboard');
        
      }).catch(function (err) {
        console.log('Save error',err);
        $scope.didNextSave = false;
        $scope.showClientSpinner = false;
        $scope.showSaveError = true;
      });
    
    }


    DataM.getPersonalInfo().then(function (data) {

      $scope.showClientSpinner = false;

      $scope.householdClients = [];
      
      if (data.personalInfo.client1) {
        $scope.client1 = data.personalInfo.client1;
        ownership1.owners[0].owner = data.personalInfo.client1._id;
      }
      if (data.personalInfo.client2) {
        $scope.client2 = data.personalInfo.client2;
        $scope.hasPartner = true;
      }

      $scope.dependants = data.personalInfo.dependants;

    }).catch(function (err) {
      console.log('Get personal info error', err);
      $scope.showRetrieveError = true;
    });

    $scope.hasIncomeType = function(type, otherIncomes){
        var hasIncome = false;
        for(var i=0; i< otherIncomes.length > 0; i++){
          if(otherIncomes[i].type === type){
            hasIncome = true;
          }
        }
        return hasIncome;
    }

    $scope.closeModal = function () {
      $scope.showRetrieveError = false;
      $scope.showSaveError = false;
      
    }

    function formIsValid(){
      
      if (!$scope.form.$valid) {
        $scope.showClientSpinner = false;
        $scope.showRequiredField = true;
        return false;
      } else {
        return true;
      }
    }
    $scope.closePopup = function(){
     
      if (!$scope.form.$valid &&  $scope.showRequiredField == true) {
        $scope.showRequiredField = false;
        if (!$scope.form.$valid) {
          if(angular.element($document[0].querySelector('input.ng-invalid'))[0]) {
            angular.element($document[0].querySelector('input.ng-invalid'))[0].focus();
          } else if(angular.element($document[0].querySelector('select.ng-invalid'))[0]){
            angular.element($document[0].querySelector('select.ng-invalid'))[0].focus();
          }
          
        }
      } 
    }

    $scope.addNewFutureGoal = function () {
      var goal = {};
      if (!$scope.timeAllocated.other) {
        $scope.timeAllocated.other = [];
      }
      $scope.timeAllocated.other.push(goal);
    };

    $scope.deleteFutureGoal = function (goal) {
      $scope.timeAllocated.other.splice($scope.timeAllocated.other.indexOf(goal), 1);
    };
  });
