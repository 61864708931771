'use strict';
angular.module('meanApp')
  .config(function ($stateProvider) {
    $stateProvider
      .state('proposedLendingMBAC', {
        url: '/proposedlending/:clientId',
        params: {
          clientId: {
            value: null,
            squash: false
          }
        },
        templateUrl: 'app/proposedLendingMBAC/proposed-lending.html',
        controller: 'proposed-lending-controller-2',
        authenticate:'admin'
      })
      .state('proposedLendingWithPropertyPureMBAC', {
        url: '/proposedlending/pure/:propertyId/:clientId',
        params: {
          propertyId: {
                value: null,
                squash: false
              },
              clientId: {
                value: null,
                squash: false
              },
              pure: true
          },
        templateUrl: 'app/proposedLendingMBAC/proposed-lending.html',
        controller: 'proposed-lending-controller-2',
        authenticate: 'admin'
      })
      .state('proposedLendingWithPropertyMBAC', {
        url: '/proposedlending/:propertyId/:clientId',
        params: {
          propertyId: {
                value: null,
                squash: false
              },
              clientId: {
                value: null,
                squash: false
              }
          },
        templateUrl: 'app/proposedLendingMBAC/proposed-lending.html',
        controller: 'proposed-lending-controller-2',
        authenticate: 'admin'
      });
      
  });
