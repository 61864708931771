export default {
    getTotalBills: function (expenses, annualBills) {

        var totalBills = 0;

        for (var i = 0; i < expenses.length; i++) {

            // Filter expenses that are bills
            var expense = expenses[i];
            if (expense.tier1) {
                totalBills += this.getAnnualAmountExpenses(expense.amount, expense.frequency);
            } else if ((expense.customExpense == true && expense.tier4 != 'Spending') || (expense.ownership && expense.ownership.ownershipType && expense.tier4 != 'Spending')) {
                totalBills += this.getAnnualAmountExpenses(expense.amount, expense.frequency);
            }
        }

        // Check if has annual bills data
        // if there is use it as total bills if the current bills == 0
        if(totalBills === 0 ) {
            totalBills = _.get(annualBills,'amount',0);
        }
        return totalBills;
    },
    getTotalSpendings: function (expenses, annualSpendings) {

        var totalSpending = 0;
        for (var i = 0; i < expenses.length; i++) {

            // Filter expenses that are bills
            var expense = expenses[i];

            if ((expense.tier1 == null) && (expense.ownership == null)) {

                if (expense.tier4 != undefined && expense.tier4 != null) {
                    if (expense.customExpense == true) {
                        if (expense.tier4 == 'Spending') {
                            totalSpending += this.getAnnualAmountExpenses(expense.amount, expense.frequency);
                        }
                    } else {
                        totalSpending += this.getAnnualAmountExpenses(expense.amount, expense.frequency);
                    }

                }
            }
        }

        if(totalSpending === 0 ) {
            totalSpending = _.get(annualSpendings,'amount',0);
        }
        return totalSpending;
    },
    getTotalTaxDeductibleBills: function (expenses, clientId) {
        var totalTaxDeductibleBills = 0;
        for (var i = 0; i < expenses.length; i++) {

            // Filter expenses that are bills
            var expense = expenses[i];

            if (expense.taxDeductible === true) {

                if (clientId) {
                    if (expense.ownership && expense.ownership.owners) {
                        for (var ownerIndex = 0; ownerIndex < expense.ownership.owners.length; ownerIndex++) {
                            var owner = expense.ownership.owners[ownerIndex];
                            if (owner.owner == clientId) {
                                totalTaxDeductibleBills += (this.getAnnualAmountTaxDeductible(expense.amount, expense.frequency) * (owner.percentage / 100));
                            }
                        }
                    }
                } else {
                    totalTaxDeductibleBills += this.getAnnualAmountTaxDeductible(expense.amount, expense.frequency);
                }

            }
        }
        return totalTaxDeductibleBills;
    },
    getInvestmentPropertyCost: function (properties, clientId) {

        var investmentPropertyCost = 0;
        for (var propertyIndex = 0; propertyIndex < properties.length; propertyIndex++) {

            var property = properties[propertyIndex];

            if (property.purpose == 'Investment') {
                if (clientId) {
                    // Check ownership
                    if (property.ownership && property.ownership.owners) {
                        for (var ownerIndex = 0; ownerIndex < property.ownership.owners.length; ownerIndex++) {
                            var owner = property.ownership.owners[ownerIndex];
                            if (owner.owner == clientId) {
                                investmentPropertyCost += (this.computePropertyHoldingCost(property) * (owner.percentage / 100));
                            }
                        }
                    }
                } else {
                    investmentPropertyCost += this.computePropertyHoldingCost(property);
                }
            }
        }
        return investmentPropertyCost;
    },
    getInvestmentPropertyDeduction: function (properties, clientId) {

        var investmentPropertyCost = 0;
        for (var propertyIndex = 0; propertyIndex < properties.length && properties[propertyIndex].ownership; propertyIndex++) {

            var property = properties[propertyIndex];

            if (property.purpose == 'Investment') {
                if (clientId) {
                    // Check ownership
                    if (property.ownership && property.ownership.owners) {
                        for (var ownerIndex = 0; ownerIndex < property.ownership.owners.length; ownerIndex++) {
                            var owner = property.ownership.owners[ownerIndex];
                            if (owner.owner == clientId) {
                                investmentPropertyCost += (this.computePropertyHoldingCost(property) * (owner.percentage / 100));
                            }
                        }
                    }
                } else {
                    investmentPropertyCost += this.computePropertyHoldingCost(property);
                }
            }
        }
        return investmentPropertyCost;
    },
    computePropertyHoldingCost: function (property) {
        var totalCost = 0;

        // calculate property cost
        if (property.bodyCorpCosts) {
            totalCost += property.bodyCorpCosts;
        }
        if (property.insuranceCosts) {
            totalCost += property.insuranceCosts;
        }
        if (property.landTaxCosts) {
            totalCost += property.landTaxCosts;
        }
        if (property.maintenanceCost) {
            totalCost += property.maintenanceCost;
        }
        if (property.managementFees) {
            totalCost += property.managementFees;
        }
        if (property.waterRates) {
            totalCost += property.waterRates;
        }
        if (property.councilRates) {
            totalCost += property.councilRates
        }

        return totalCost
    },
    getTotalExpenditure: function(clientSchema) {
        var expenses =  _.get(clientSchema,'expenses.expenses',[]);
        var annualBills =  _.get(clientSchema,'expenses.annualBills',{});
        var annualSpendings =  _.get(clientSchema,'expenses.annualSpendings',{});
        var loans =  _.get(clientSchema,'borrowings.borrowing',[]);;
        var properties =  _.get(clientSchema,'assets.properties',[]);;
        var superFunds = _.get(clientSchema,'assets.superFunds',[]);

        var totalBills = this.getTotalBills(expenses,annualBills);
        var totalSpendings = this.getTotalSpendings(expenses,annualSpendings);
        var totalRepayments = this.getLoanRepayments(loans);
        var investmentPropertyCosts = this.getInvestmentPropertyCost(properties);
        var afterTaxContrib = this.getTotalSuperAnnuationAfterTaxContribution(superFunds);


        var totalExpenditures = totalBills+totalSpendings+totalRepayments+investmentPropertyCosts +afterTaxContrib;
        return totalExpenditures;
    },
    getExpensesValues: function(expensesData, clientId) {
        var expenses =  _.get(expensesData,'expenses',[]);
        var annualBills =  _.get(expensesData,'annualBills',{});
        var annualSpendings =  _.get(expensesData,'annualSpendings',{});

        var totalBills = this.getTotalBills(expenses,annualBills);
        var totalSpendings = this.getTotalSpendings(expenses,annualSpendings);
        var totalTaxDeductibleBills = this.getTotalTaxDeductibleBills(expenses,clientId);

        var expenseItems = {
            bills: totalBills,
            spendings: totalSpendings,
            taxDeductibles: totalTaxDeductibleBills
        }
        return expenseItems;
    },
    getClientExpensesValues: function(clientSchema) {
        var expenses =  _.get(clientSchema,'expenses',{});
        var taxDBillExpenses =  _.get(clientSchema,'expenses.expenses',[]);
        var client1Id = clientSchema.personalInfo.client1 ? clientSchema.personalInfo.client1._id : '';
        var hasClient2 = clientSchema.personalInfo.client2 ? true : false;

        var client1TaxDedBills = this.getTotalTaxDeductibleBills(taxDBillExpenses, client1Id);
        var expensesValues = this.getExpensesValues(expenses);
        expensesValues.client1TaxDeductibleBills = client1TaxDedBills;

        if(hasClient2) {
            var client2Id = clientSchema.personalInfo.client2._id;
            var client2TaxDedBills = this.getTotalTaxDeductibleBills(taxDBillExpenses, client2Id);

            expensesValues.client2TaxDeductibleBills = client2TaxDedBills;
        }
        return expensesValues;
    },
    getTotalSuperAnnuationAfterTaxContribution: function(superFunds){
        var totalAfterTaxContrib = 0;
        for (var i = 0; i < superFunds.length; i++) {
            var superFund = superFunds[i];
            if(superFund.personalContributionsBool == true) {
                var contrib1 = _.get(superFund,'personalContributionsClient1', 0);
                var contrib2 = _.get(superFund,'personalContributionsClient2',0);

                totalAfterTaxContrib += contrib1 + contrib2;

            }

        }

        return totalAfterTaxContrib;
    },
    formUniqueArrayByValue: function(array, uniqueBy) {
        return _.uniqBy(array, uniqueBy);
    },
    stringifyJSON: function(array, excludeKeys, str) {
        return JSON.stringify(array, function( key, value ) {
            if(excludeKeys.indexOf(key) > -1) {
                return undefined;
            }
            return value;
        });
    },
    holdingCostsTotal: function(property, isCustomIncluded) {
        let holdingCost = 0;
        holdingCost += property.maintenanceCost ? property.maintenanceCost : 0;
        holdingCost += property.waterRates ? property.waterRates : 0;
        holdingCost += property.insuranceCosts ? property.insuranceCosts : 0;
        holdingCost += property.councilRates ? property.councilRates : 0;
        holdingCost += property.bodyCorpCosts ? property.bodyCorpCosts : 0;
        holdingCost += property.landTaxCosts ? property.landTaxCosts : 0;
        holdingCost += property.managementFees ? property.managementFees : 0;
        if (isCustomIncluded && property.hasOwnProperty('customHoldingCosts')) {
          let customCost = 0;
          for (let x = 0; x < property.customHoldingCosts.length; x++) {
            const propertyHolding = property.customHoldingCosts[x];
            customCost += propertyHolding.value ? propertyHolding.value : 0;
          }
          holdingCost += customCost;
        }
        return holdingCost;
    }
}
