import angular from 'angular';

export const appConfig = [
  'appConfig',
  {
    "userRoles": [
      "guest",
      "user",
      "admin",
      "fp_admin",
      "pwp_admin",
      "mb_admin",
      "super_admin"
    ]
  }
];

export const appHostnames =  [
  'appHostnames',
  {
    "tpcHosts": [
      "127.0.0.1",
      "temptpc.dev.wealthpathlabs.com",
      "tpc.dev.wealthpathlabs.com",
      "tpc.test.wealthpathlabs.com",
      "tpc.moneysmarts.com.au"
    ],
    "ewHosts": [
      "localhost",
      "tempclientportal.dev.wealthpathlabs.com",
      "clientportal.dev.wealthpathlabs.com",
      "clientportal.test.wealthpathlabs.com",
      "ew.moneysmarts.com.au"
    ],
  }
]



export default angular
  .module("meanApp.constants", [])
  .constant(...appConfig)
  .constant(...appHostnames)
  .name
