
export default {

    /** START OF DASHBOARD TILES */
    getTotalHouseholdIncome: function (clientSchema) {

        var totalHouseholdIncome = 0;

        var personalIncomeClient1 = _.get(clientSchema, 'income.client1PersonalIncome', {});
        var assets = clientSchema.assets;
        var client1Id = _.get(clientSchema, 'personalInfo.client1._id', '');
        var otherIncomes = _.get(personalIncomeClient1, 'other', []);
        var client1GrossIncome = this.getGrossIncome(personalIncomeClient1, assets, client1Id, true);
        var client1NonTaxable = this.getGrossNontaxableIncome(otherIncomes);

        totalHouseholdIncome += client1GrossIncome + client1NonTaxable;

        if (clientSchema.personalInfo.client2 && clientSchema.income.client2PersonalIncome) {
            var personalIncomeClient2 = _.get(clientSchema, 'income.client2PersonalIncome', {});
            var client2Id = _.get(clientSchema, 'personalInfo.client2._id', '');
            var otherIncomes2 = _.get(personalIncomeClient2, 'other', []);
            var client2GrossIncome = this.getGrossIncome(personalIncomeClient2, assets, client2Id, false);
            var client2NonTaxable = this.getGrossNontaxableIncome(otherIncomes2);

            totalHouseholdIncome += client2GrossIncome + client2NonTaxable;

        }
        return totalHouseholdIncome;
    },

    getAnnualTaxPayable: function (clientSchema) {
        let totalTaxPayable = 0;
        var personalIncomeClient1 = _.get(clientSchema, 'income.client1PersonalIncome', {});
        var assets = clientSchema.assets;
        var expenses = _.get(clientSchema, 'expenses.expenses', []);
        var client1Id = _.get(clientSchema, 'personalInfo.client1._id', '');
        var loans = _.get(clientSchema, 'borrowings.borrowing', []);

        var taxableIncome1 = this.getTaxableIncome(personalIncomeClient1, expenses, assets, loans, client1Id, true);

        var taxPayable1 = this.computeEstimatedTaxPaid(taxableIncome1);
        if(taxPayable1 <= 0) {
            taxPayable1 = 0;
        }
        totalTaxPayable += taxPayable1;

        var client2 = _.get(clientSchema, 'personalInfo.client2',null);

        if (client2) {

            var personalIncomeClient2 = _.get(clientSchema, 'income.client2PersonalIncome', {});
            var client2Id = _.get(clientSchema, 'personalInfo.client2._id', '');

            var taxableIncome2 = this.getTaxableIncome(personalIncomeClient2, expenses, assets, loans, client2Id, false);

            var taxPayable2 = this.computeEstimatedTaxPaid(taxableIncome2);

            if(taxableIncome2 <= 0) {
                taxPayable2 = 0;
            }
            totalTaxPayable += taxPayable2;
        }
        return totalTaxPayable;
    },

    getTotalCashSavings: function (bankAccounts) {
        var totalCashSavings = this.getTotalBankAccountsValue(bankAccounts);
        return totalCashSavings;
    },

    getAvailableEquity: function (properties, loans) {
        var totalPropertiesValue = this.getTotalPropertiesValue(properties);
        var totalPropertyLiability = this.getTotalPropertyLiability(loans);
        var equityAvaible = totalPropertiesValue - totalPropertyLiability;
        return equityAvaible;
    },

    getSurplusCashFlow: function (clientSchema) {
        var totalHouseholdIncome = this.getTotalHouseholdIncome(clientSchema);
        var totalExpenditure = this.getTotalExpenditure(clientSchema);
        var taxPayable = this.getAnnualTaxPayable(clientSchema);
        var surplus = totalHouseholdIncome - totalExpenditure - taxPayable;

        return surplus;
    },

    getTotalNetIncome: function (clientSchema) {
        var totalHouseholdIncome = this.getTotalHouseholdIncome(clientSchema);
        var taxPayable = this.getAnnualTaxPayable(clientSchema);

        var totalNetIncome = totalHouseholdIncome - taxPayable;
        return totalNetIncome;
    },
    getDashboardTilesCalculations: function(clientSchema){
        var totalNetIncome = this.getTotalNetIncome(clientSchema);
        var surplusCashFlow = this.getSurplusCashFlow(clientSchema);
        var totalHouseholdIncome = this.getTotalHouseholdIncome(clientSchema);
        var totalExpenditure = this.getTotalExpenditure(clientSchema);

        var dashboardCalculations = {
            totalNetIncome: totalNetIncome,
            surplusCashFlow: surplusCashFlow,
            moneyIn: totalHouseholdIncome,
            moneyOut: totalExpenditure
        }
        return dashboardCalculations;
    },
    getMoneysmartsDashboardCalculations: function(clientSchema){
        var totalNetIncome = this.getTotalNetIncome(clientSchema);
        var taxPayable = this.getAnnualTaxPayable(clientSchema);
        var totalExpenditure = this.getTotalExpenditure(clientSchema);
        var surplusCashFlow = this.getSurplusCashFlow(clientSchema);
        let superFunds = _.get(clientSchema,'assets.superFunds',[]);
        var afterTaxContrib = this.getTotalSuperAnnuationAfterTaxContribution(superFunds);

        var dashboardCalculations = {
            moneyIn: totalNetIncome,
            moneyOut: totalExpenditure,
            surplus: surplusCashFlow,
            afterTaxContrib: afterTaxContrib
        }

        return dashboardCalculations;
    },

    //** END OF DASHBOARD TILES */
}
