'use strict';
angular.module('meanApp')
  .config(function ($stateProvider) {
    $stateProvider
      .state('whatsnext', {
        url: '/whatsnext',
        params: {},
        templateUrl: 'app/tpcPortal/whatsnext/whatsnext.html',
        controller: 'whatsnext-controller',
        authenticate: 'user'
      });
  });
