
angular.module('meanApp')
  .config(function ($stateProvider) {
    'use strict';
    $stateProvider
      .state('future-household', {
        url: '/future-household/:clientId',
        params: {
          clientId: {
            value: null,
            squash: false
          }
        },
        templateUrl: 'app/futurePlans/future-household.html',
        controller: 'factfind-8-controller',
        authenticate: ['user','admin']
      });
  })

  .config(function ($stateProvider) {
    'use strict';
    $stateProvider
      .state('future-income-expenses', {
        url: '/future-income-expenses/:clientId',
        params: {
          clientId: {
            value: null,
            squash: false
          }
        },
        templateUrl: 'app/futurePlans/future-income-expenses.html',
        controller: 'factfind-9-controller',
        authenticate: ['user','admin']
      });
  })
  .config(function ($stateProvider) {
    'use strict';
    $stateProvider
      .state('portfolio-planning', {
        url: '/portfolio-planning/:clientId',
        params: {
          clientId: {
            value: null,
            squash: false
          }
        },
        templateUrl: 'app/futurePlans/portfolio-planning.html',
        controller: 'factfind-10-controller',
        authenticate: ['user','admin']
      });
  })
  
  ;

  