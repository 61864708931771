"use strict";

angular.module("meanApp").config(function ($stateProvider) {
  $stateProvider.state("moneysmartsChanges", {
    url: "/moneysmartsChanges/:clientId",
    params: {
      clientId: {
        value: null,
        squash: false,
      },
    },
    templateUrl: "app/moneySmartsChanges/moneysmartsChanges.html",
    controller: "moneysmartsChanges-controller",
    authenticate: ["admin"],
  });
});
