angular
  .module("meanApp")
  .controller(
    "futurePlansChanges-controller",
    function ($scope, $stateParams, Auth, $q) {
      "use strict";
      $scope.isAdmin = false;
      Auth.isAdmin().then((isAdmin) => ($scope.isAdmin = isAdmin));

      let futurePlansWrapper = document.getElementById("react-wrapper-futurePlansChanges");
      $scope.$on("futurePlansChanges", function (evt, reqData) {
        ReactDOM.unmountComponentAtNode(futurePlansWrapper);
        ReactDOM.render(
          React.createElement(ModalUnansweredFields.FuturePlansChanges, {
            category: $stateParams.category, clientId: $stateParams.clientId, futurePlansProps: reqData
          }),
          futurePlansWrapper
        );

        $scope.$on("$destroy", () => {
          ReactDOM.unmountComponentAtNode(futurePlansWrapper);
        });
      });
    }
  );
