'use strict';

angular.module('meanApp')
  .config(function ($stateProvider) {
    $stateProvider
      .state('resetPassword', {
        url: '/reset',
        templateUrl: 'app/reset/reset.html',
        controller: 'reset-controller',
        authenticate: ['user','admin']
      });
  });
