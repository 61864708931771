export const TypeLendingSurvey = "lending-survey";

export const ReactElementIdLendingSurvey = "react-lending-survey";

export type Notification = {
  id: string;
  type: typeof TypeLendingSurvey;
  onRefreshSurveys?: () => void;
};

export function openLendingSurveyModal(
  id: string,
  refreshLendingSurveys?: () => void
) {
  if (!process.env.API_ENDPOINT) return;

  const node = document.getElementById(ReactElementIdLendingSurvey);

  if (!node) return;

  const unmount = () => {
    setTimeout(() => {
      ReactDOM.unmountComponentAtNode(node);
    }, 1000 / 3);
  };

  const props: LendingSurveyProps = {
    id,
    apiBaseUrl: process.env.API_ENDPOINT,
    onClose: unmount,
    onSave() {
      if (refreshLendingSurveys) refreshLendingSurveys();
      unmount();
    },
  };

  ReactDOM.render(
    React.createElement(SettlementForm.LendingSurvey, props),
    document.getElementById(ReactElementIdLendingSurvey)
  );
}

export function handleOnNotificationClick(notification: Notification) {
  const {id, type, onRefreshSurveys} = notification;

  if (type === TypeLendingSurvey) openLendingSurveyModal(id, onRefreshSurveys);
}
