
angular.module('meanApp')
    .config(function ($stateProvider) {
        'use strict';
        $stateProvider
            .state('lendingForm', {
                url: '/lending-form/:clientId',
                params: {
                    clientId: {
                        value: null,
                        squash: false
                    }
                },
                templateUrl: 'app/lendingForm/lending-form-all.html',
                controller: 'lending-form-controller',
                authenticate: ['admin']
            });
    })


    .config(function ($stateProvider) {
        'use strict';
        $stateProvider
            .state('lendingFormUpdate', {
                url: '/lending-form/:formId/:clientId',
                params: {
                    clientId: {
                        value: null,
                        squash: false
                    },
                    formId: {
                        value: null,
                        squash: false
                    }
                },
                templateUrl: 'app/lendingForm/lending-form-all.html',
                controller: 'lending-form-controller',
                authenticate: ['admin']
            });
    })

    .config(function ($stateProvider) {
        'use strict';
        $stateProvider
            .state('internalSubmitReview', {
                url: '/lending-form/review/:formId/:clientId',
                params: {
                    clientId: {
                        value: null,
                        squash: false
                    },
                    formId: {
                        value: null,
                        squash: false
                    }
                },
                templateUrl: 'app/lendingForm/lending-form-review.html',
                controller: 'lending-form-controller',
                authenticate: ['admin']
            });
    })

