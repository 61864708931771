import angular from 'angular';
import ngCookies from 'angular-cookies';
import ngResource from 'angular-resource';
import ngSanitize from 'angular-sanitize';
import uiRouter from '@uirouter/angularjs';
import uiBootstrap from 'angular-ui-bootstrap';
import ngAnimate from 'angular-animate';
import ngFileUpload from 'ng-file-upload';
import ngIdle from 'ng-idle';
import duScroll from 'angular-scroll';
import 'ng-autocomplete';
import 'oclazyload';
import 'jquery-ui-dist/jquery-ui';
import 'jquery-ui-dist/jquery-ui.css';
import 'intl-tel-input/build/js/intlTelInput';
import 'assets/js/intl-tel-utils';
import 'intl-tel-input/build/css/intlTelInput.css';
import 'ng-intl-tel-input/dist/ng-intl-tel-input';
import 'spin.js/spin.js';
import 'spin.js/spin.css';
import 'angular-spinner/dist/angular-spinner';
import 'angular-file-saver/dist/angular-file-saver.bundle';
import 'chart.js/dist/Chart.bundle';
import 'chart.js/dist/Chart.css'
import 'angular-chart.js/dist/angular-chart';
import 'angular-qrcode/angular-qrcode';

const requires = [
  ngCookies,
  ngResource,
  ngSanitize,
  uiRouter,
  uiBootstrap,
  ngAnimate,
  ngFileUpload,
  ngIdle,
  duScroll,
  // intlTelInputUtils,
  "chart.js",
  "ngIntlTelInput",
  "angularSpinner",
  "ngFileSaver",
  'monospaced.qrcode',
  'oc.lazyLoad',
  'ngAutocomplete'
];

export default angular.module('meanApp.package', requires).name;
