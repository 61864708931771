'use strict';

angular.module('meanApp')
  .config(function ($stateProvider) {
    $stateProvider
      .state('fact-find0', {
        url: '/factfind0',
        templateUrl: 'app/factFind/fact-find-0.html',
        controller: 'factfind-0-controller',
        authenticate: 'user'
      })
      .state('fact-find2', {
        url: '/factfind2',
        templateUrl: 'app/factFind/fact-find-2.html',
        controller: 'factfind-2-controller',
        authenticate: 'user'
      })
      .state('fact-find3', {
        url: '/factfind3',
        templateUrl: 'app/factFind/fact-find-3.html',
        controller: 'factfind-3-controller',
        authenticate: 'user'
      })
      .state('fact-find4', {
        url: '/factfind4',
        templateUrl: 'app/factFind/fact-find-4.html',
        controller: 'factfind-4-controller',
        authenticate: 'user'
      })
      .state('fact-find5', {
        url: '/factfind5',
        templateUrl: 'app/factFind/fact-find-5.html',
        controller: 'factfind-5-controller',
        authenticate: 'user'
      })
      .state('fact-find6', {
        url: '/factfind6',
        templateUrl: 'app/factFind/fact-find-6.html',
        controller: 'factfind-6-controller',
        authenticate: 'user'
      })
      .state('fact-find7', {
        url: '/factfind7',
        templateUrl: 'app/factFind/fact-find-7.html',
        controller: 'factfind-7-controller',
        authenticate: 'user'
      })
      .state('fact-find8', {
        url: '/factfind8',
        templateUrl: 'app/factFind/fact-find-8.html',
        controller: 'factfind-8-controller',
        authenticate: 'user'
      })
      .state('fact-find9', {
        url: '/factfind9',
        templateUrl: 'app/factFind/fact-find-9.html',
        controller: 'factfind-9-controller',
        authenticate: 'user'
      })
      // .state('fact-find10', {
      //   url: '/factfind10',
      //   templateUrl: 'app/factFind/fact-find-10.html',
      //   controller: 'factfind-10-controller',
      //   authenticate: 'user'
      // })
      .state('client-summary', {
        url: '/summary',
        templateUrl: 'app/factFind/client-summary.html',
        controller: 'client-summary-controller',
        authenticate: 'user'
      });
      
  });
