export default {
    getTotalBankAccountsValue: function (bankAccounts, clientId) {
        var currentValue = 0;

        for (var i1 = 0; i1 < bankAccounts.length; i1++) {

            var bankAccount = bankAccounts[i1];
            currentValue += this.getBalance(bankAccount, clientId);
        }
        return currentValue;
    },
    getTotalPropertiesValue: function (properties, clientId) {
        var currentValue = 0;

        for (var i1 = 0; i1 < properties.length; i1++) {

            var property = properties[i1];
            currentValue += this.getCurrentValue(property, clientId);

        }
        return currentValue;
    },
    getTotalInvestmentPropertiesValue: function (properties, clientId) {
        var currentValue = 0;

        for (var i1 = 0; i1 < properties.length; i1++) {

            var property = properties[i1];
            if(property.purpose == "Business Use" || property.purpose == "Investment Property" || property.purpose == 'Investment') {
                currentValue += this.getCurrentValue(property, clientId);
            }

        }
        return currentValue;
    },
    getTotalNonInvestmentPropertiesValue: function (properties, clientId) {
        var currentValue = 0;

        for (var i1 = 0; i1 < properties.length; i1++) {

            var property = properties[i1];
            if(property.purpose == "Personal Use" || property.purpose == "Own Home") {
                currentValue += this.getCurrentValue(property, clientId);
            }

        }
        return currentValue;
    },
    getTotalHouseholdContentsValue: function (properties, clientId) {
        var currentValue = 0;

        for (var i1 = 0; i1 < properties.length; i1++) {

            var property = properties[i1];
            currentValue += this.getContentsValue(property, currentValue);
        }
        return currentValue;
    },
    getTotalVehiclesValue: function (vehicles, clientId) {
        var totalValue = 0;

        for (var i1 = 0; i1 < vehicles.length; i1++) {

            var vehicle = vehicles[i1];
            totalValue += this.getValue(vehicle, clientId);

        }
        return totalValue;
    },
    getTotalInvestmentsValue: function (investments, clientId) {
        var totalCurrentValue = 0;

        for (var i1 = 0; i1 < investments.length; i1++) {

            var investment = investments[i1];
            totalCurrentValue += this.getCurrentValue(investment, clientId);

        }
        return totalCurrentValue;
    },
    getTotalSuperFundsValue: function (superFunds, clientId) {
        var totalValue = 0;

        for (var i1 = 0; i1 < superFunds.length; i1++) {

            var superFund = superFunds[i1];
            totalValue += this.getValue(superFund, clientId);

        }
        return totalValue;
    },
    getTotalLifeInsuranceValue: function (lifeInsurances, clientId) {
        var totalValue = 0;

        for (var i1 = 0; i1 < lifeInsurances.length; i1++) {

            var lifeInsurance = lifeInsurances[i1];
            totalValue += this.getValue(lifeInsurance, clientId);

        }
        return totalValue;
    },
    getTotalOtherAssetsValue: function (otherAssets, clientId) {
        var totalValue = 0;

        for (var i1 = 0; i1 < otherAssets.length; i1++) {

            var otherAsset = otherAssets[i1];
            totalValue += this.getValue(otherAsset, clientId);

        }
        return totalValue;
    },
    getSuperAnnuationPersonalContribution: function (superFunds, clientId, isMainClient = true) {
        var personalContribution = 0;


        for (var i = 0; i < superFunds.length; i++) {
            var superFund = superFunds[i];
            if (superFund.ownership && superFund.ownership.owners && superFund.personalContributionsBool === true) {
                if (clientId) {
                    for (var i2 = 0; i2 < superFund.ownership.owners.length; i2++) {
                        var owner = superFund.ownership.owners[i2];
                        if (owner.owner == clientId) {
                            if (superFund.ownership.ownershipType == 'Sole') {
                                personalContribution += superFund.personalContributionsClient1;
                            } else {
                                if (isMainClient) {
                                    personalContribution += superFund.personalContributionsClient1;
                                } else {
                                    personalContribution += superFund.personalContributionsClient2;
                                }
                            }
                        }
                    }
                } else {
                    personalContribution += superFund.personalContributionsClient1 + superFund.personalContributionsClient2;
                }
            }



        }
        return personalContribution;
    },
    getTotalAssetsValue: function (assets, clientId) {
        var bankAccountsValue = assets.bankAccounts ? this.getTotalBankAccountsValue(assets.bankAccounts, clientId) : 0;
        var propertiesValue = assets.bankAccounts ? this.getTotalPropertiesValue(assets.properties, clientId) : 0;
        var propertyContentsValue = assets.bankAccounts ? this.getTotalHouseholdContentsValue(assets.properties, clientId) : 0;
        var investmentsValue = assets.bankAccounts ? this.getTotalInvestmentsValue(assets.investments, clientId) : 0;
        var vehiclesValue = assets.bankAccounts ? this.getTotalVehiclesValue(assets.vehicles, clientId) : 0;
        var superFundsValue = assets.bankAccounts ? this.getTotalSuperFundsValue(assets.superFunds, clientId) : 0;
        var otherAssetsValue = assets.bankAccounts ? this.getTotalOtherAssetsValue(assets.otherAssets, clientId) : 0;

        var totalAssets = 0;

        totalAssets = bankAccountsValue + propertiesValue + propertyContentsValue + investmentsValue + vehiclesValue + superFundsValue + otherAssetsValue;

        return totalAssets;
    },
    getAssetValues: function (assets, clientId) {
        var assetValues = {};
        var bankAccountsValue = assets.bankAccounts ? this.getTotalBankAccountsValue(assets.bankAccounts, clientId) : 0;
        var propertiesValue = assets.properties ? this.getTotalPropertiesValue(assets.properties, clientId) : 0;
        var propertyContentsValue = assets.properties ? this.getTotalHouseholdContentsValue(assets.properties, clientId) : 0;
        var investmentsValue = assets.investments ? this.getTotalInvestmentsValue(assets.investments, clientId) : 0;
        var vehiclesValue = assets.vehicles ? this.getTotalVehiclesValue(assets.vehicles, clientId) : 0;
        var superFundsValue = assets.superFunds ? this.getTotalSuperFundsValue(assets.superFunds, clientId) : 0;
        var otherAssetsValue = assets.otherAssets ? this.getTotalOtherAssetsValue(assets.otherAssets, clientId) : 0;
        var superFundPersonalContribution = assets.superFunds ? this.getSuperAnnuationPersonalContribution(assets.superFunds, clientId) : 0;
        var totalAssets = 0;

        totalAssets = this.getTotalAssetsValue(assets, clientId);

        assetValues.bankAccounts = bankAccountsValue;
        assetValues.properties = propertiesValue;
        assetValues.propertyContents = propertyContentsValue;
        assetValues.investments = investmentsValue;
        assetValues.vehicles = vehiclesValue;
        assetValues.superFunds = superFundsValue;
        assetValues.otherAssets = otherAssetsValue;
        assetValues.totalAssets = totalAssets;
        assetValues.superFundPersonalContribution = superFundPersonalContribution;

        return assetValues;
    },
    getTotalInvestmentAssets: function(assets, clientId){
        if(!_.isNil(assets)) {
            var bankAccountsValue = assets.bankAccounts ? this.getTotalBankAccountsValue(assets.bankAccounts, clientId) : 0;
            var propertiesValue = assets.properties ? this.getTotalInvestmentPropertiesValue(assets.properties, clientId) : 0;
            var investmentsValue = assets.investments ? this.getTotalInvestmentsValue(assets.investments, clientId) : 0;
            var superFundsValue = assets.superFunds ? this.getTotalSuperFundsValue(assets.superFunds, clientId) : 0;
        } else {
            var bankAccountsValue = 0;
            var propertiesValue = 0;
            var investmentsValue = 0;
            var superFundsValue = 0;
        }

        var totalInvestmentAssetsValue= bankAccountsValue+propertiesValue+investmentsValue+superFundsValue;
        return totalInvestmentAssetsValue;
    },
    getTotalNonInvestmentAssets: function(assets, clientId){
        if(!_.isNil(assets)) {
            var propertiesValue = assets.properties ? this.getTotalNonInvestmentPropertiesValue(assets.properties, clientId) : 0;
        } else {
            var propertiesValue = 0;
        }
        return propertiesValue;
    },
    getClientsAssetsValues: function(clientSchema) {
        var assets = clientSchema.assets ? clientSchema.assets : {};
        var client1Id = clientSchema.personalInfo.client1._id;

        var hasClient2 = clientSchema.personalInfo.client2;

        var client1Assets = this.getAssetValues(assets, client1Id);

        var clientsAssets = {
            client1: client1Assets
        }

        if(hasClient2) {
            var client2Id = clientSchema.personalInfo.client2._id;
            var client2Assets = this.getAssetValues(assets, client2Id);
            clientsAssets.client2 = client2Assets;

            var bothAssets = this.getAssetValues(assets);
            clientsAssets.both = bothAssets;
        }

        return clientsAssets;
    }
}
