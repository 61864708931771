"use strict";

angular.module("meanApp").config(function($stateProvider,) {
  $stateProvider.state("futureplansbeta", {
    url: "/futureplansbeta/:clientId",
    params: {
      clientId: {
        value: null,
        squash: false
      }
    },
    templateUrl: "app/futurePlansBeta/futurePlansBeta.html",
    controller: "futurePlansBeta-controller",
    authenticate: ["user", "admin"]
  });
});
