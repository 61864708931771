require("core-js/stable");
require("regenerator-runtime/runtime");
/**********************************************************************************************
 * Global Declaration
 *
 * @description a solution to call global modules inside the app while we try to migrate them
 **********************************************************************************************/
window.$ = window.jQuery = require('jquery');
window.angular = require('angular');
window.moment = require('moment');
window._ = require('lodash');
window.qrcode = require('qrcode-generator');

const isProduction = process.env.NODE_ENV === "production";

if(isProduction) {
  // Disable all console logs on production
  window.console.log = () => {};
}

/**********************************************************************************************
 * App Module Declaration
 *
b * @description application module that is needed on boot mainly angular module related
 **********************************************************************************************/
const requireAll = requireContext => requireContext.keys().forEach(requireContext);

require('angular-validation-match/dist/angular-validation-match.min.js');
require('./base-app')
requireAll(require.context('./app', true, /\.js$/));
requireAll(require.context('./components', true, /\.js$/));
