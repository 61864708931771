'use strict';

angular.module('meanApp')
  .config(function ($stateProvider) {
    $stateProvider
      .state('login', {
        url: '/?redirect',
        templateProvider:  function ($stateParams,$location,appHostnames){
          let hostname = $location.$$host;

          if(appHostnames.tpcHosts.indexOf(hostname) >-1){
             return  '<div ng-include="\'app/login/tpc-login.html\'"></div>';
          } else {
             return  '<div ng-include="\'app/login/login.html\'"></div>';
          }
          
        },
        controller: 'LoginCtrl'
      });
  });