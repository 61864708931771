
angular.module('meanApp')
    .config(function ($stateProvider) {
        'use strict';
        $stateProvider
            .state('lendingFormMBAC', {
                url: '/lending-form/:clientId',
                params: {
                    clientId: {
                        value: null,
                        squash: false
                    }
                },
                templateUrl: 'app/lendingFormMBAC/lending-form-all.html',
                controller: 'lending-form-controller',
                authenticate: ['admin'],
                
            })
            .state('lendingFormPureMBAC', {
                url: '/lending-form/pure/:clientId',
                params: {
                    clientId: {
                        value: null,
                        squash: false
                    },
                    pure : true
                },
                templateUrl: 'app/lendingFormMBAC/lending-form-all.html',
                controller: 'lending-form-controller',
                authenticate: ['admin'],
                
            });

            
    })


    .config(function ($stateProvider) {
        'use strict';
        $stateProvider
            .state('lendingFormUpdateMBAC', {
                url: '/lending-form/:formId/:clientId',
                params: {
                    clientId: {
                        value: null,
                        squash: false
                    },
                    formId: {
                        value: null,
                        squash: false
                    }
                },
                templateUrl: 'app/lendingFormMBAC/lending-form-all.html',
                controller: 'lending-form-controller',
                authenticate: ['admin']
            });
    })

    .config(function ($stateProvider) {
        'use strict';
        $stateProvider
            .state('internalSubmitReviewMBAC', {
                url: '/lending-form/review/:formId/:clientId',
                params: {
                    clientId: {
                        value: null,
                        squash: false
                    },
                    formId: {
                        value: null,
                        squash: false
                    }
                },
                templateUrl: 'app/lendingFormMBAC/lending-form-review.html',
                controller: 'lending-form-controller',
                authenticate: ['admin']
            });
    })