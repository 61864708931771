angular.module('meanApp').controller('ResponseCtrl', function ($scope, $uibModalInstance, result) {

    $scope.result = result;
    $scope.errorMessage = _.get(result,'error.data.error','Unable to save/submit lending form.');
    $scope.close = function () {
        $uibModalInstance.close();
    };
});


angular.module('meanApp').controller('ReferenceIdListCtrl', function ($scope, $uibModalInstance, $state, clientId, DataM, currentReferenceId) {

    $scope.referenceIdList = [];
    $scope.currentReferenceId = currentReferenceId;
    DataM.getFSFormRefList().then(function(data){
        if(data.referenceIdList && data.referenceIdList.length > 0){
            $scope.referenceIdList  = data.referenceIdList;
        }
    })
    $scope.selectedFormData = {};
    $scope.load = function () {
        $state.go('lendingFormUpdate', {
            formId: $scope.selectedFormData.id,
            clientId: clientId
        });
        $uibModalInstance.close();
    }
    $scope.close = function () {
        $uibModalInstance.close();
    };
});

angular.module('meanApp').controller('LendingFormHistoryListCtrl', function ($scope, $uibModalInstance, $state, referenceId, clientId, formId, DataM) {

    $scope.selectedFormData = {};
    if (formId) {
        $scope.selectedFormData.id = formId;
    }
    $scope.historyList = [];
    let payload = {
        referenceId: referenceId
    }
    DataM.getFSFormHistoryList(payload).then(function (data) {
        $scope.historyList = data.historyList;
    });

    $scope.load = function () {
        $state.go('lendingFormUpdate', {
            formId: $scope.selectedFormData.id,
            clientId: clientId
        });
        $scope.close();
    }
    $scope.close = function () {
        $uibModalInstance.close();
    };
});

angular.module('meanApp').controller('EditReferenceIdCtrl', function ($scope, $uibModalInstance, currentReferenceId, DataM) {

    $scope.currentReferenceId = currentReferenceId;
    $scope.newReferenceId = '';
    $scope.updateFailed = false;

    $scope.update= function() {
        let payload = {
            oldReferenceId: $scope.currentReferenceId,
            newReferenceId: $scope.newReferenceId
        }
        DataM.updateFormReferenceId(payload).then(function (data) {
            $uibModalInstance.close({newReferenceId:$scope.newReferenceId});
        }).catch(function(err){
            $scope.updateFailed = true;
            console.log(err);
        })
        $uibModalInstance.close();
    }
    $scope.close = function () {
        $uibModalInstance.close();
    };
    $scope.didChange = function(){
        $scope.updateFailed = false;
    }
});

angular.module('meanApp').controller('AddFormCtrl', function ($scope, $uibModalInstance, DataM, clientId, $state) {

    $scope.referenceId = "Lending Form "+ new Date().toLocaleDateString("en-AU");
    $scope.addFailed = false;
    $scope.add = function() {
    let payload = {
        uid:clientId,
        referenceId: $scope.referenceId
    }
        DataM.addFSForm(payload).then(function (data) {
            $state.go('lendingFormUpdate', {
                formId: data._id,
                clientId: clientId
            });
            $uibModalInstance.close();
        }).catch(function (error) {
            console.log(error);
            $scope.addFailed = true;
        });
       
    }
    $scope.close = function () {
        $uibModalInstance.close();
    };
    $scope.didChange = function(){
        $scope.addFailed = false;
    }
});

angular.module('meanApp').controller('DuplicateFormCtrl', function ($scope, $uibModalInstance, DataM, clientId, $state, formData) {

    $scope.referenceId = "Lending Form "+ new Date().toLocaleDateString("en-AU");
    $scope.addFailed = false;
    $scope.add = function() {
        let payload = {
            formData: formData,
            uid:clientId,
            referenceId: $scope.referenceId
        }
        DataM.addFSForm(payload).then(function (data) {
            DataM.saveFSForm(payload).then(() => {
                $state.go('lendingFormUpdate', {
                    formId: data._id,
                    clientId: clientId,
                });
                $uibModalInstance.close();
            })
        }).catch(function (error) {
            console.log(error);
            $scope.addFailed = true;
        });
       
    }
    $scope.close = function () {
        $uibModalInstance.close();
    };
    $scope.didChange = function(){
        $scope.addFailed = false;
    }
});