angular.module('meanApp')
  .factory('MSPortal', function ($resource) {
    return $resource('/api/msportal/:controller', {
      id: '@_id'
    },{
      getPersonalInfo: {
        method: 'GET',
        params: {
          controller: 'personalInfo'
        },
      },
      savePersonalInfo: {
        method: 'PUT',
        params: {
          controller: 'personalInfo'
        },
      },
      getStep2: {
        method: 'GET',
        params: {
          controller: 'step2'
        },
      },
      saveStep2: {
        method: 'PUT',
        params: {
          controller: 'step2'
        },
      },
      getStep3: {
        method: 'GET',
        params: {
          controller: 'step3'
        },
      },
      saveStep3: {
        method: 'PUT',
        params: {
          controller: 'step3'
        },
      },
      getSurplus: {
        method: 'GET',
        params: {
          controller: 'getSurplus'
        },
      },
      getNetWorth: {
        method: 'GET',
        params: {
          controller: 'getNetWorth'
        },
      },
      getIncomes: {
        method: 'GET',
        params: {
          controller: 'getIncomes'
        },
      },
      setShowReactour: {
        method: 'PUT',
        params: {
          controller: 'showTour'
        },
      },
      getExpenses: {
        method: 'GET',
        params: {
          controller: 'getExpenses'
        },
      },
      getTopicsDiscuss: {
        method: 'GET',
        params: {
          controller: 'getTopicsDiscuss'
        },
      },
      postTransferToEW: {
        method: 'POST',
        params: {
          controller: 'transferToEW'
        },
      },

      postTransferMSDataToEW: {
        method: 'POST',
        params: {
          controller: 'transferMSDataToEW'
        },
      },
      getMoneyFit: {
        method: 'POST',
        params: {
          controller: 'getHist'
        },
        isArray: true
      },
    });
  });