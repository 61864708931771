'use strict';

angular.module('meanApp')
  .config(function ($stateProvider) {
    $stateProvider
      .state('2fa-register', {
        url: '/2fa-register',
        templateUrl: 'app/2fa/2fa-register.html',
        controller: '2faRegisterCtrl',
        authenticate: ['user','admin']
      })

      .state('2fa-verifycode', {
        url: '/2fa-verifycode',
        params: {
          redirect: undefined
        },
        templateUrl: 'app/2fa/2fa-verifycode.html',
        controller: '2faVerificationCtrl',
        authenticate: ['user','admin']
      });
  });