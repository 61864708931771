"use strict";

angular.module("meanApp").config(function($stateProvider,) {
  $stateProvider.state("documentcollection", {
    url: "/documentcollection/:clientId",
    params: {
      clientId: {
        value: null,
        squash: false
      }
    },
    templateUrl: "app/documentCollection/documentCollection.html",
    controller: "documentCollection-controller",
    authenticate: ["user", "admin"]
    
  })
})