function ClientService($resource) {
  "ngInject";

  return $resource(
    "/api/client/:controller",
    {
      id: "@_id",
    },
    {
      summary: {
        method: "GET",
        params: {
          controller: "summary",
        },
      },
    }
  );
}

export default ClientService;
