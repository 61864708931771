"use strict";

angular.module("meanApp").config(function($stateProvider,) {
  $stateProvider.state("settlements", {
    url: "/settlements/:propertyId/:loanIndex/:clientId",
    params: {
      clientId: {
        value: null,
        squash: false
      },
      propertyId: {
        value: null,
        squash: false
      },
      loanIndex: {
        value: null,
        squash: false
      }
    },
    templateUrl: "app/settlements/settlements.html",
    controller: "settlements-controller",
    authenticate: ["user", "admin"]
  });
});
