'use strict';

angular.module('meanApp')
  .config(function ($stateProvider) {
    $stateProvider
      .state('proposedLending', {
        url: '/proposedlending/:clientId',
        params: {
          clientId: {
            value: null,
            squash: false
          }
        },
        templateUrl: 'app/proposedLending/proposed-lending.html',
        controller: 'proposed-lending-controller',
        authenticate:'admin'
      })
      
      .state('proposedLendingWithProperty', {
        url: '/proposedlending/:propertyId/:clientId',
        params: {
          propertyId: {
                value: null,
                squash: false
              },
              clientId: {
                value: null,
                squash: false
              },
          },
        templateUrl: 'app/proposedLending/proposed-lending.html',
        controller: 'proposed-lending-controller',
        authenticate: 'admin'
      });
      
  });
