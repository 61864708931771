'use strict';

angular.module('meanApp')
  .config(function ($stateProvider) {
    $stateProvider
      .state('personalinfo', {
        url: '/personal-info/:clientId',
        params: {
          clientId: {
            value: null,
            squash: false
          }
        },
        templateUrl: 'app/personalInfoUpdated/personal-info1.html',
        controller: 'personal-info-updated-controller',
        authenticate: ['user', 'admin']
      })
      .state('fact-find1', {
        url: '/factfind1',
        params: {
          clientId: {
            value: null,
            squash: false
          }
        },
        templateUrl: 'app/factFind/fact-find-1v.html',
        controller: 'personal-info-updated-controller',
        authenticate: 'user'
      })
      .state('personalinfotpc', {
                url: '/personal-infotpc/:clientId',
                params: {
                  clientId: {
                    value: null,
                    squash: false
                  }
                },
                templateUrl: 'app/personalInfo/personal-info.html',
                controller: 'factfind-1-controller',
                authenticate: ['user', 'admin']
              });
  });
