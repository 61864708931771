export default {
    getLoanRepayments: function (loans, clientId) {
        var totalLoanRepayments = 0;

        for (var loanIndex = 0; loanIndex < loans.length; loanIndex++) {
            var loan = loans[loanIndex];
            var frequency = loan.repaymentFreq ? loan.repaymentFreq : 'Yearly';
            if (clientId) {
                // Check ownership
                if (loan.borrower && loan.borrower.owners) {
                    for (var ownerIndex = 0; ownerIndex < loan.borrower.owners.length; ownerIndex++) {
                        var owner = loan.borrower.owners[ownerIndex];
                        if (owner.owner == clientId) {

                            totalLoanRepayments += (this.getAnnualLoanRepayments(loan.repayment, frequency) * (owner.percentage / 100));
                        }
                    }
                }
            } else {
                totalLoanRepayments += this.getAnnualLoanRepayments(loan.repayment, frequency);
            }
        }
        return totalLoanRepayments;
    },
    getTotalLoanOutstanding: function (loans, clientId) {
        var totalOutstanding = 0;

        for (var loanIndex = 0; loanIndex < loans.length; loanIndex++) {
            var loan = loans[loanIndex];
            totalOutstanding += this.getOutstanding(loan, clientId);
        }
        return totalOutstanding;
    },
    getTotalCreditCardOutstanding: function (loans, clientId) {
        var totalOutstanding = 0;

        for (var loanIndex = 0; loanIndex < loans.length; loanIndex++) {
            var loan = loans[loanIndex];
            if (loan.type == 'Credit Card') {
                totalOutstanding += this.getOutstanding(loan, clientId);
            }
        }
        return totalOutstanding;
    },
    getTotalPropertyLiability: function (loans, clientId) {
        var totalOutstanding = 0;

        for (var i1 = 0; i1 < loans.length; i1++) {

            var loan = loans[i1];
            if (loan.properties.length > 0) {
                totalOutstanding += this.getOutstanding(loan, clientId);
            }
        }
        return totalOutstanding
    },
    getLoanValues: function (loans, clientId) {
        var propertyLiabilities = this.getTotalPropertyLiability(loans, clientId);
        var creditCardOutstanding = this.getTotalCreditCardOutstanding(loans, clientId);
        var totalOutstanding = this.getTotalLoanOutstanding(loans, clientId);
        var repayments = this.getLoanRepayments(loans, clientId);

        var loanItems = {
            repayments: repayments,
            propertyLiabilities: propertyLiabilities,
            creditCardLiabilities: creditCardOutstanding,
            totalLiabilities: totalOutstanding
        };
        return loanItems;
    },
    getClientLoansValues: function (clientSchema) {
        var loans = clientSchema.borrowings.borrowing.filter((_borrowing) => !_borrowing.isClosed  );
        var client1Id = clientSchema.personalInfo.client1._id;
        var hasClient2 = clientSchema.personalInfo.client2;

        var client1Loans = this.getLoanValues(loans, client1Id);

        var clientsLoans = {
            client1: client1Loans
        }

        if (hasClient2) {
            var client2Id = clientSchema.personalInfo.client2._id;
            var client2Loans = this.getLoanValues(loans, client2Id);
            clientsLoans.client2 = client2Loans;

            var bothAssets = this.getLoanValues(loans);
            clientsLoans.both = bothAssets;
        }

        return clientsLoans;
    },
    computeInvestmentLoanPropertyDeduction: function (loan) {
        var propertyDeduction = 0;
        if (loan) {
            if (loan.interestRate) {

                if (loan.outstanding) {
                    return ((loan.interestRate / 100) * loan.outstanding)
                }
            }
        }

        return propertyDeduction;
    },

    getLoanPropertyDeduction: function (loans, clientId) {
        var totalLoanRepayments = 0;

        for (var loanIndex = 0; loanIndex < loans.length; loanIndex++) {
            var loan = loans[loanIndex];
            //$scope.allLoans[$i].type.indexOf('Investment Loan') != -1 && $scope.allLoans[$i].primaryPurpose && $scope.allLoans[$i].primaryPurpose.indexOf('Real Estate') != -1
            const type = _.get(loan, 'type', '') ?? "";
            const purpose = _.get(loan, 'primaryPurpose', '') ?? "";
            if (purpose.indexOf('Real Estate') != -1 && type.indexOf('Investment Loan') != -1) {
                if (clientId) {
                    // Check ownership
                    if (loan.borrower && loan.borrower.owners) {
                        for (var ownerIndex = 0; ownerIndex < loan.borrower.owners.length; ownerIndex++) {
                            var owner = loan.borrower.owners[ownerIndex];
                            if (owner.owner == clientId) {

                                totalLoanRepayments += (this.computeInvestmentLoanPropertyDeduction(loan) * (owner.percentage / 100));
                            }
                        }
                    }
                } else {
                    totalLoanRepayments += this.computeInvestmentLoanPropertyDeduction(loan);
                }
            }
        }

        return totalLoanRepayments;
    }
}
