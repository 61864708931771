angular
  .module("meanApp")
  .controller("moneysmartsChanges-controller", function (
    $scope,
    $stateParams,
    Auth,
  ) {
    $scope.isAdmin = false;
    Auth.isAdmin().then(isAdmin => ($scope.isAdmin = isAdmin));
    $scope.$on("moneysmarts-changes", function () {
      let moneySmartsWrapper = document.getElementById("react-moneysmarts-changes");
      ReactDOM.render(
        React.createElement(MoneyStretchChanges.default, {
          id: $stateParams.clientId,
          isAdmin: $scope.isAdmin,
        }),
        moneySmartsWrapper
      );
      $scope.$on("$destroy", () => {
        ReactDOM.unmountComponentAtNode(moneySmartsWrapper);
      });
    });
  });
