angular
  .module("meanApp")
  .controller("pwp-workbench-controller", function($scope, $stateParams) {
    "use strict";

    const reactContainerNode = document.getElementById("react-wrapper-pwp-workbench");
    ReactDOM.render(
      React.createElement(PWPWorkbench.default, { uid: $stateParams.clientId }),
      reactContainerNode
    );
    $scope.$on("$destroy", () => {
      ReactDOM.unmountComponentAtNode(reactContainerNode);
    });
  });
