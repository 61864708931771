'use strict';

angular.module('meanApp')
  .config(function ($stateProvider) {
    $stateProvider
      .state('forgotPassword', {
        url: '/forgotPassword',
        templateUrl: 'app/forgotPassword/forgotPassword.html',
        controller: 'forgotPassword-controller'
      });
  });
