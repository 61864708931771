
angular.module('meanApp')
  .config(function ($stateProvider) {
    'use strict';
    $stateProvider
      .state('borrowings', {
        url: '/borrowings/:clientId',
        params: {
          clientId: {
            value: null,
            squash: false
          }
        },
        templateUrl: 'app/borrowings/borrowings.html',
        controller: 'factfind-5-controller',
        authenticate: ['user','admin']
      });
  });
