angular.module('meanApp')

.controller('terms-controller', function($scope, Auth, User, $state) {
  'use strict';
  $scope.user = {};
  $scope.errors = {};
  $scope.tcagree = {val: false};
  $scope.showSpinner = false;

  Auth.getIsTPCUser().then(function (isTPCUser){
    $scope.isTPCUser = isTPCUser;
  });

  $scope.acceptTC = function(form) {
    $scope.submitted = true;
    if(form.$valid) {
      $scope.showSpinner = true;
      $scope.errors = {};

      Auth.acceptTC($scope.tcagree.val)
      .then(function() {
        // Logged in, redirect to dashboard
        $scope.showSpinner = false;
        $state.go('dashboard');
      })
      .catch( function(err) {
        $scope.showSpinner = false;
        console.log(err);
        $scope.errors.msg = 'Error accepting T&C';
      });
    }
  };
});
