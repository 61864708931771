'use strict';

angular.module('meanApp')
  .factory('DataMSPortal', function DataMSPortal(MSPortal) {

    return {
      getPersonalInfo: function (userData,callback) {
        var cb = callback || angular.noop;
        return MSPortal.getPersonalInfo(userData,
          function (data) {
            return cb(data);
          },
          function (err) {
            return cb(err);
          }.bind(this)
        ).$promise;
      },
      savePersonalInfo: function(csData, callback) {
        var cb = callback || angular.noop;
        return MSPortal.savePersonalInfo(csData,
          function(csData) {
            return cb(csData);
          },
          function(err) {
            return cb(err);
          }.bind(this)
        ).$promise;
      },
      getStep2: function (userData,callback) {
        var cb = callback || angular.noop;
        return MSPortal.getStep2(userData,
          function (data) {
            return cb(data);
          },
          function (err) {
            return cb(err);
          }.bind(this)
        ).$promise;
      },
      saveStep2: function(csData, callback) {
        var cb = callback || angular.noop;
        return MSPortal.saveStep2(csData,
          function(csData) {
            return cb(csData);
          },
          function(err) {
            return cb(err);
          }.bind(this)
        ).$promise;
      },
      getStep3: function (userData, callback) {
        var cb = callback || angular.noop;
        return MSPortal.getStep3(userData,
          function (data) {
            return cb(data);
          },
          function (err) {
            return cb(err);
          }.bind(this)
        ).$promise;
      },
      saveStep3: function(csData, callback) {
        var cb = callback || angular.noop;
        return MSPortal.saveStep3(csData,
          function(csData) {
            return cb(csData);
          },
          function(err) {
            return cb(err);
          }.bind(this)
        ).$promise;
      },
      getSurplus: function(userData, callback) {
        var cb = callback || angular.noop;
        return MSPortal.getSurplus(userData,
          function(csData) {
            return cb(csData);
          },
          function(err) {
            return cb(err);
          }.bind(this)
        ).$promise;
      },
      setShowReactour: function(userData, callback) {
        var cb = callback || angular.noop;
        return MSPortal.setShowReactour(userData,
          function(csData) {
            return cb(csData);
          },
          function(err) {
            return cb(err);
          }.bind(this)
        ).$promise;
      },
      getNetWorth: function(userData, callback) {
        var cb = callback || angular.noop;
        return MSPortal.getNetWorth(userData,
          function(csData) {
            return cb(csData);
          },
          function(err) {
            return cb(err);
          }.bind(this)
        ).$promise;
      },
      getIncomes: function(userData, callback) {
        var cb = callback || angular.noop;
        return MSPortal.getIncomes(userData,
          function(csData) {
            return cb(csData);
          },
          function(err) {
            return cb(err);
          }.bind(this)
        ).$promise;
      },
      getExpenses: function(userData, callback) {
        var cb = callback || angular.noop;
        return MSPortal.getExpenses(userData,
          function(csData) {
            return cb(csData);
          },
          function(err) {
            return cb(err);
          }.bind(this)
        ).$promise;
      },
      getTopicsDiscuss: function(userData, callback) {
        var cb = callback || angular.noop;
        return MSPortal.getTopicsDiscuss(userData,
          function(csData) {
            return cb(csData);
          },
          function(err) {
            return cb(err);
          }.bind(this)
        ).$promise;
      },
      transferClientData: function(csData, callback) {
        var cb = callback || angular.noop;
        return MSPortal.postTransferToEW(csData,
          function(csData) {
            return cb(csData);
          },
          function(err) {
            return cb(err);
          }.bind(this)
        ).$promise;
      },
      transferMSData: function(csData, callback) {
        var cb = callback || angular.noop;
        return MSPortal.postTransferMSDataToEW(csData,
          function(csData) {
            return cb(csData);
          },
          function(err) {
            return cb(err);
          }.bind(this)
        ).$promise;
      },
      getMoneyFit: function(userData, callback) {
        var cb = callback || angular.noop;
        return MSPortal.getMoneyFit(userData,
          function(csData) {
            return cb(csData);
          },
          function(err) {
            return cb(err);
          }.bind(this)
        ).$promise;
      },
    };
  });
