'use strict';

angular.module('meanApp')
  .factory('ClientData', function ($resource) {
    return $resource('/api/client/:id/:controller', {
      id: '@_id'
    }, {
      getClientSurvey: {
        method: 'GET',
        params: {
          id: 'clientSurvey'
        }
      },

      saveClientSurvey: {
        method: 'PUT',
        params: {
          id: 'clientSurvey'
        }
      },

      getPersonalInfo: {
        method: 'GET',
        params: {
          id: 'personalInfo'
        }
      },

      savePersonalInfo: {
        method: 'PUT',
        params: {
          id: 'personalInfo'
        }
      },
      saveClientAge: {
        method: 'PUT',
        params: {
          id: 'updateAge'
        }
      },
      getPersonalInfoWithFP: {
        method: 'GET',
        params: {
          id: 'personalInfoWithFP'
        }
      },

      savePersonalInfoWithFP: {
        method: 'PUT',
        params: {
          id: 'personalInfoWithFP'
        }
      },

      getIncome: {
        method: 'GET',
        params: {
          id: 'income'
        }
      },

      saveIncome: {
        method: 'PUT',
        params: {
          id: 'income'
        }
      },

      getProperties: {
        method: 'GET',
        params: {
          id: 'properties'
        }
      },

      saveProperties: {
        method: 'PUT',
        params: {
          id: 'properties'
        }
      },

      getOtherAssets: {
        method: 'GET',
        params: {
          id: 'otherAssets'
        }
      },

      saveOtherAssets: {
        method: 'PUT',
        params: {
          id: 'otherAssets'
        }
      },

      getPropertyLoans: {
        method: 'GET',
        params: {
          id: 'borrowings'
        }
      },

      savePropertyLoans: {
        method: 'PUT',
        params: {
          id: 'borrowings'
        }
      },

      getBillPayments: {
        method: 'GET',
        params: {
          id: 'billPayments'
        }
      },

      saveBillPayments: {
        method: 'PUT',
        params: {
          id: 'billPayments'
        }
      },

      getExpenses: {
        method: 'GET',
        params: {
          id: 'expenses'
        }
      },

      saveExpenses: {
        method: 'PUT',
        params: {
          id: 'expenses'
        }
      },

      getFutureHouseholdPlans: {
        method: 'GET',
        params: {
          id: 'futureHouseholdPlans'
        }
      },

      saveFutureHouseholdPlans: {
        method: 'PUT',
        params: {
          id: 'futureHouseholdPlans'
        }
      },

      getFutureIncomeChanges: {
        method: 'GET',
        params: {
          id: 'futureIncomeChanges'
        }
      },

      saveFutureIncomeChanges: {
        method: 'PUT',
        params: {
          id: 'futureIncomeChanges'
        }
      },

      getPortfolioPlanning: {
        method: 'GET',
        params: {
          id: 'portfolioPlanning'
        }
      },

      savePortfolioPlanning: {
        method: 'PUT',
        params: {
          id: 'portfolioPlanning'
        }
      },

      getSummary: {
        method: 'GET',
        params: {
          id: 'summary'
        }
      },

      getClientDoc: {
        method: 'GET',
        params: {
          controller: 'clientDoc'
        }
      },
      getClientSummary: {
        method: 'GET',
        params: {
          controller: 'clientSummary'
        }
      },
      getMSSumarry: {
        method: 'GET',
        params: {
          id: 'getMSSumarry'
        }
      },
      clientMSSummary: {
        method: 'GET',
        params: {
          controller: 'clientMSSummary'
        }
      },

      saveSummary: {
        method: 'PUT',
        params: {
          id: 'summary'
        }
      },

      getDocumentCollectionSetting: {
        method: 'GET',
        params: {
          id: 'dcEnable'
        }
      },
      saveDocumentCollectionSetting: {
        method: 'PUT',
        params: {
          id: 'dcEnable'
        }
      },
      getMBWLendingFlexSetting: {
        method: 'GET',
        params: {
          id: 'mbwlendingflexenable'
        }
      },
      saveMBWLendingFlexSetting: {
        method: 'PUT',
        params: {
          id: 'mbwlendingflexenable'
        }
      },
      getMoneySmartsSetting: {
        method: 'GET',
        params: {
          id: 'msEnable'
        }
      },
      saveMoneySmartsSetting: {
        method: 'PUT',
        params: {
          id: 'msEnable'
        }
      },
      getPropertyPortfolioSetting: {
        method: 'GET',
        params: {
          id: 'propertyReportEnable'
        }
      },
      savePropertyPortfolioSetting: {
        method: 'PUT',
        params: {
          id: 'propertyReportEnable'
        }
      },
      getMaternityVersionSetting: {
        method: 'GET',
        params: {
          id: 'oldMaternityEnable'
        }
      },
      saveMaternityVersionSetting: {
        method: 'PUT',
        params: {
          id: 'oldMaternityEnable'
        }
      },
      getSuitabilitySetting: {
        method: 'GET',
        params: {
          id: 'suitabilityEnable'
        }
      },
      saveSuitabilitySetting: {
        method: 'PUT',
        params: {
          id: 'suitabilityEnable'
        }
      },
      getMBWarningEnabled: {
        method: 'GET',
        params: {
          id: 'mbWarningEnable'
        }
      },
      saveMBWarningEnabled: {
        method: 'PUT',
        params: {
          id: 'mbWarningEnable'
        }
      },
      findByEmail: {
        method: 'GET',
        params: {
          id: 'findByEmail',
        }
      },
      getClientChanges: {
        method: 'GET',
        params: {
          id: 'clientChanges'
        }
      },
      getAllClients: {
        method: 'GET',
        params: {
          id: 'allClients'
        },
        isArray: true
      },
      searchClients: {
        method: 'GET',
        params: {
          id: 'searchClients',
          searchTerm: '@searchTerm'
        },
        isArray: true
      },
      saveShortFF: {
        method: 'PUT',
        params: {
          id: 'shortFactFind'
        }
      },

      getProvisioning: {
        method: 'GET',
        params: {
          controller: 'provisioning'
        },
      },
      putProvisioning: {
        method: 'PUT',
        params: {
          controller: 'provisioning'
        },

      },

      getMonthlyBalance: {
        method: 'GET',
        params: {
          controller: 'monthlyBalance'
        },
      },
      putMonthlyBalance: {
        method: 'PUT',
        params: {
          controller: 'monthlyBalance'
        },
      },

      getSampleHistoryMerge: {
        method: 'POST',
        params: {
          controller: 'sampleHistoryMerge'
        },

      },
      restoreClientWithHistory: {
        method: 'POST',
        params: {
          controller: 'restoreClientWithHistory'
        },

      },

    });
  });

angular.module('meanApp')
  .factory('UserData', function ($resource) {
    return $resource('/api/users/:id/:controller', {
      id: '@_id'
    }, {
      getMe: {
        method: 'GET',
        params: {
          id: 'me'
        }
      },

      isBetaUser: {
        method: 'GET',
        params: {
          id: 'isBetaUser'
        }
      },

      setViewing: {
        method: 'PUT',
        params: {
          id: 'setViewing'
        }
      },
      createPartner: {
        method: 'POST',
        params: {
          id: 'createPartner'
        }
      },
      partnerActivated: {
        method: 'GET',
        params: {
          id: 'partnerActivated'
        }
      },

    });
  });
angular.module('meanApp')
  .factory('AdminSettings', function ($resource) {
    return $resource('/api/settings/:controller', {
      id: '@_id'
    }, {
      savePreference: {
        method: 'PUT',
        params: {},
      },
      getPreference: {
        method: 'GET',
        params: {}
      },
      getList: {
        method: 'GET',
        params: {
          controller: "list"
        },
        isArray: true
      }
    })
  })
angular.module('meanApp')
  .factory('FormStack', function ($resource) {
    return $resource('/api/formstack/:controller', {
      id: '@_id'
    }, {
      submit: {
        method: 'POST',
        params: {
          controller: "submit"
        }
      },
      submitInternal: {
        method: 'POST',
        params: {
          controller: "submitInternal"
        }
      },
      save: {
        method: 'POST',
        params: {
          controller: "save"
        }
      },
      getForm: {
        method: 'GET',
        params: {
          controller: "getFormData"
        }
      },
      getFormWithId: {
        method: 'GET',
        params: {
          controller: "getFormWithId"
        }
      },
      getFormRefList: {
        method: 'GET',
        params: {
          controller: "getFormRefList"
        }
      },
      getFormHistoryList: {
        method: 'GET',
        params: {
          controller: "getFormHistoryList"
        }
      },
      updateFormReferenceId: {
        method: 'POST',
        params: {
          controller: "updateReferenceId"
        }
      },
      addForm: {
        method: 'POST',
        params: {
          controller: "add"
        }
      },
      getFormFields: {
        method: 'GET',
        params: {
          controller: "getFields"
        }
      },
    })
  })
angular.module('meanApp')
  .factory('ClientFiles', function ($resource) {

    return $resource('/api/aws/listUpload', {

    }, {
      getList: {
        method: 'GET',
        params: {},
      }
    });
  });

angular.module('meanApp')
  .factory('ClientFile', function ($resource) {

    return $resource('/api/aws/:controller', {

    }, {
      deleteFile: {
        method: 'POST',
        params: {
          controller: 'deleteFile'
        },
      },

      downloadFile: {
        method: 'GET',
        params: {
          controller: 'checkFileExists'
        },
      }
    });
  });



angular.module('meanApp')
  .factory('UserList', function ($resource) {
    return $resource('/api/users', {
      id: '@_id'
    }, {
      getList: {
        method: 'GET',
        params: {
          id: '/'
        },
        isArray: true
      }
    });
  });

angular.module('meanApp')
  .factory('UserWithId', function ($resource) {
    return $resource('/api/users/:controller/:id/', {
      id: '@id',
      controller: '@controller'
    }, {
      getEmail: {
        method: 'GET',
        params: {
          controller: 'getEmail'
        }
      },
      getType: {
        method: 'GET',
        params: {
          controller: 'type'
        }
      },
      saveType: {
        method: 'PUT',
        params: {
          controller: 'type',
          id: '@uid'
        }
      },
      getAuthenticator: {
        method: 'GET',
        params: {
          controller: 'authenticator'
        }
      },
      saveAuthenticator: {
        method: 'PUT',
        params: {
          controller: 'authenticator',
          id: '@uid'
        }
      },
      getIsFuturePlansEnabled: {
        method: 'GET',
        params: {
          controller: 'isFuturePlansEnabled'
        }
      },
      setIsFuturePlansEnabled: {
        method: 'POST',
        params: {
          controller: 'isFuturePlansEnabled'
        }
      },
    });
  });

angular.module('meanApp')
  .factory('FindByEmail', function ($resource) {
    return $resource('/api/client', {
      id: '@_id'
    }, {
      findByEmail: {
        method: 'GET',
        params: {
          id: 'show'
        },
        isArray: true
      }
    });
  });

angular.module('meanApp')
  .factory('PushFlex', function ($resource) {
    return $resource('/api/flex/:controller', {
      id: '@_id'
    }, {
      pushFlex: {
        method: 'POST',
        params: {
          id: '/'
        }
      },
      getFlexLog: {
        method: 'GET',
        params: {
          controller: 'log'
        }
      },
      getFlexMissingFields: {
        method: 'GET',
        params: {
          controller: 'missing'
        }
      },
      getFlexSummary: {
        method: 'GET',
        params: {
          controller: 'getFlexSummary'
        }
      }
    });
  });
angular.module('meanApp')
  .factory('UpdateEmail', function ($resource) {
    return $resource('/api/users/userEmail', {
      id: '@_id'
    }, {
      updateEmail: {
        method: 'POST',
        params: {

        }
      }
    });
  });

angular.module('meanApp')
  .factory('UserPermission', function ($resource) {
    return $resource('/api/permissions/check', {

    }, {
      check: {
        method: 'GET',
        params: {}
      }
    });
  });

angular.module('meanApp')
  .factory('SuitabilityData', function ($resource) {
    return $resource('/api/suitability/:controller', {
      id: '@_id'
    }, {
      getSuitability: {
        method: 'GET',
        params: {
          controller: ''
        }
      },
      getSuitabilityList: {
        method: 'GET',
        params: {
          controller: 'previousList'
        },
        isArray: true
      },
      saveSuitability: {
        method: 'PUT',
        params: {
          controller: ''
        }
      },
      getSuitabilityReferenceIdList: {
        method: 'GET',
        params: {
          controller: 'referenceIdList'
        },
        isArray: true
      },
      lockSuitability: {
        method: 'PUT',
        params: {
          controller: 'lockReferenceId'
        }
      },
      updateReferenceId: {
        method: 'PUT',
        params: {
          controller: 'referenceId'
        }
      }
    });
  });
angular.module('meanApp')
  .factory('LoanStructure', function ($resource) {
    return $resource('/api/loanstructures/:controller', {

    }, {
      get: {
        method: 'GET',
        params: {

        }
      },
      save: {
        method: 'POST',
        params: {

        }
      },
      getList: {
        method: 'GET',
        params: {
          controller: 'previousList'
        },
        isArray: true
      },
      getPropertyList: {
        method: 'GET',
        params: {
          controller: 'propertyList'
        },
      },
      createProperty: {
        method: 'PUT',
        params: {
          controller: 'proposedProperty'
        }
      },
      updateProperty: {
        method: 'POST',
        params: {
          controller: 'proposedProperty'
        }
      },
      deleteProperty: {
        method: 'DELETE',
        params: {
          controller: 'removeProperty'
        }
      },
      getProperty: {
        method: 'GET',
        params: {
          controller: 'proposedProperty'
        }
      },
      delete: {
        method: 'DELETE',
        params: {
          controller: 'snapshot/delete'
        }
      },
    });
  });
angular.module('meanApp')
  .factory('LoanStructureSnapshot', function ($resource) {
    return $resource('/api/loanstructures/snapshot/:controller', {

    }, {
      rename: {
        method: 'POST',
        params: {
          controller: 'rename'
        }
      },
      delete: {
        method: 'POST',
        params: {
          controller: 'delete'
        }
      },
    });
  });


angular.module('meanApp')
  .factory('ResetAuthenticator', function ($resource) {
    return $resource('/api/users/resetAuthenticator', {
      id: '@_id'
    }, {
      resetUser2FA: {
        method: 'POST',
        params: {

        }
      }
    });
  });

angular.module('meanApp')
  .factory('DataHelper', function ($filter) {

    return {
      getOwnerList: function (data, withTenants, withJoints, withOther) {
        var ownerList = [];
        // prepare ownership options
        if (data.personalInfo.client2) {
          if (data.personalInfo.client2.fName) {

            var lname2 = data.personalInfo.client2.lName;
            if (!lname2 || lname2 === null) {
              lname2 = "";
            }
            var owner2 = {
              name: data.personalInfo.client2.fName + " " + lname2,
              id: data.personalInfo.client2._id
            };

            ownerList.unshift(owner2);

            if (withJoints) {
              var joint = {
                name: "Joint",
                id: ''
              };

              ownerList.push(joint);
            }
          }
        }

        if (withTenants) {
          var tenants = {
            name: "Tenants in Common",
            id: ''
          };

          ownerList.push(tenants);
        }
        if (withOther) {
          var other = {
            name: "Other",
            id: ''
          };
          ownerList.push(other);
        }
        if (data.personalInfo.client1) {
          var lname1 = data.personalInfo.client1.lName;
          if (!lname1 || lname1 === null) {
            lname1 = "";
          }
          var owner1 = {
            name: data.personalInfo.client1.fName + " " + lname1,
            id: data.personalInfo.client1._id
          };
          ownerList.unshift(owner1);
        }
        return ownerList;
      },

      // Set owner
      formatOwnership: function (otherAsset, personalInfo, ownershipList) {
        try {
          for (var i = 0; i < otherAsset.length; i++) {
            var asset = otherAsset[i];
            asset.ownerList = ownershipList;



            if (asset.ownership) {
              // set ownership percentage
              if (personalInfo.client1) {
                if (asset.ownership.owners[0]) {
                  if (asset.ownership.owners[0].percentage)
                    asset.percentage1 = asset.ownership.owners[0].percentage ? asset.ownership.owners[0].percentage : 0;
                }
              } else if (personalInfo.client2) {
                if (asset.ownership.owners[1]) {
                  if (asset.ownership.owners[1].percentage)
                    asset.percentage2 = asset.ownership.owners[1].percentage ? asset.owners[1].percentage : 0;
                }
              }

              // determine owner
              if (asset.ownership.ownershipType) {

                var ownershipType = asset.ownership.ownershipType;

                for (var k = 0; k < ownershipList.length; k++) {
                  var ownerListOption = ownershipList[k];

                  if (ownershipType == "Sole" || ownershipType == "Other") {
                    i
                    if (asset.ownership.owners && _.isArray(asset.ownership.owners)) {
                      if (asset.ownership.owners.length > 0) {
                        if (asset.ownership.owners[0].owner == ownerListOption.id && ownershipType == 'Sole') {
                          asset.percentage1 = asset.ownership.owners[0].percentage;
                          asset.owner = ownerListOption;
                          if (asset.ownership.ownershipDesc) {
                            asset.ownerOther = asset.ownership.ownershipDesc;
                          }
                        } else {

                          if (asset.ownership.owners) {
                            if (asset.ownership.owners[0].percentage) {

                              asset.percentage1 = asset.ownership.owners[0].percentage;
                            }
                          }

                          if (asset.ownership.ownershipDesc) {
                            asset.ownerOther = asset.ownership.ownershipDesc;
                          }
                          if (ownershipType == 'Other') {

                            asset.owner = ownerListOption;
                          }
                        }
                      } else {
                        if (ownershipType == 'Other') {
                          asset.owner = ownerListOption;
                        }
                        if (asset.ownership.ownershipDesc) {
                          asset.ownerOther = asset.ownership.ownershipDesc;
                        }
                      }
                    } else {
                      if (ownershipType == 'Other') {
                        asset.owner = ownerListOption;
                      }
                      if (asset.ownership.ownershipDesc) {
                        asset.ownerOther = asset.ownership.ownershipDesc;
                      }
                    }

                  } else if (ownershipType == "Tenants in Common" && ownerListOption.name == ownershipType) {
                    asset.owner = ownershipList[ownershipList.length - 2];
                    asset.percentage1 = asset.ownership.owners[0].percentage;
                    if (personalInfo.client2 && asset.ownership.owners[1]) {
                      asset.percentage2 = assest.ownership.owners[1].percentage;
                    }
                  } else if (ownershipType == "Joint" && ownerListOption.name == ownershipType) {
                    asset.owner = ownerListOption;
                    asset.percentage1 = asset.ownership.owners[0].percentage;
                    asset.percentage2 = asset.ownership.owners[1].percentage;
                  }

                }
              }
            } else if (asset.owner) {
              // set ownership percentage
              if (asset.owner.owners) {
                if (personalInfo.client1) {
                  if (asset.owner.owners[0]) {
                    if (asset.owner.owners[0].percentage)
                      asset.percentage1 = asset.owner.owners[0].percentage ? asset.owner.owners[0].percentage : 0;
                  }
                } else if (personalInfo.client2) {
                  if (asset.owner.owners[1]) {
                    if (asset.owner.owners[1].percentage)
                      asset.percentage2 = asset.owner.owners[1].percentage ? asset.owners[1].percentage : 0;
                  }
                }
              }

              // determine owner
              if (asset.owner.ownershipType) {

                var ownershipType = asset.owner.ownershipType;

                for (var k = 0; k < ownershipList.length; k++) {
                  var ownerListOption = ownershipList[k];

                  if (ownershipType == "Sole" || ownershipType == "Other") {
                    if (asset.owner.owners && _.isArray(asset.owner.owners)) {
                      if (asset.owner.owners[0].owner == ownerListOption.id && ownershipType == 'Sole') {
                        asset.percentage1 = asset.owner.owners[0].percentage;
                        asset.selectedOwner = ownerListOption;

                        if (asset.owner.ownershipDesc) {
                          asset.ownerOther = asset.owner.ownershipDesc;
                        }
                      } else {
                        if (asset.owner.owners) {
                          if (asset.owner.owners[0].percentage) {

                            asset.percentage1 = asset.owner.owners[0].percentage;
                          }
                        }

                        if (asset.owner.ownershipDesc) {
                          asset.ownerOther = asset.owner.ownershipDesc;
                        }
                        if (ownershipType == 'Other') {
                          asset.selectedOwner = ownerListOption;
                        }
                      }
                    } else {
                      if (ownershipType == 'Other') {
                        asset.owner = ownerListOption;
                      }
                      if (asset.ownership.ownershipDesc) {
                        asset.ownerOther = asset.ownership.ownershipDesc;
                      }
                    }

                  } else if (ownershipType == "Tenants in Common" && ownerListOption.name == ownershipType) {
                    asset.selectedOwner = ownershipList[ownershipList.length - 2];
                    asset.percentage1 = asset.owner.owners[0].percentage;
                    if (personalInfo.client2 && asset.owner.owners[1]) {
                      asset.percentage2 = assest.owner.owners[1].percentage;
                    }
                  } else if (ownershipType == "Joint" && ownerListOption.name == ownershipType) {
                    asset.selectedOwner = ownerListOption;

                    if (asset.owner.owners[0].percentage) {
                      asset.percentage1 = asset.owner.owners[0].percentage;
                    } else {
                      asset.percentage1 = 50;
                    }
                    if (asset.owner.owners[1].percentage) {
                      asset.percentage2 = asset.owner.owners[1].percentage;
                    } else {
                      asset.percentage2 = 50;
                    }
                  }

                }
              }
            }
          }

          return otherAsset;
        } catch (error) {

          var owner1 = ownershipList[0];
          for (var i = 0; i < otherAsset.length; i++) {
            var asset = otherAsset[i];
            asset.ownerList = ownershipList;
            asset.owner = owner1;
            asset.selectedOwner = owner1;
            asset.percentage1 = 100;

          }
          return otherAsset;
        }
      },
      // Set borrower
      formatBorrower: function (borrowings, personalInfo, ownershipList) {

        for (var i = 0; i < borrowings.length; i++) {
          var borrowing = borrowings[i];
          if (borrowing.borrower) {

            // set ownership percentage
            if (personalInfo.client1) {
              if (borrowing.borrower.owners[0]) {
                if (borrowing.borrower.owners[0].percentage)
                  borrowing.percentage1 = borrowing.borrower.owners[0].percentage ? borrowing.borrower.owners[0].percentage : 0;
              }
            } else if (personalInfo.client2) {
              if (borrowing.borrower.owners[1]) {
                if (borrowing.borrower.owners[1].percentage)
                  borrowing.percentage2 = borrowing.borrower.owners[1].percentage ? borrowing.borrower[1].percentage : 0;
              }
            }

            // determine owner
            if (borrowing.borrower.ownershipType) {

              var ownershipType = borrowing.borrower.ownershipType;

              for (var k = 0; k < ownershipList.length; k++) {
                var ownerListOption = ownershipList[k];


                if (ownershipType == "Sole" || ownershipType == "Other") {

                  if (ownershipType == "Sole" && borrowing.borrower.owners.length > 0) {
                    if (borrowing.borrower.owners[0].owner == ownerListOption.id) {
                      borrowing.percentage1 = borrowing.borrower.owners[0].percentage;
                      borrowing.borrowers = ownerListOption;

                      if (borrowing.borrower.ownershipDesc) {
                        borrowing.ownerOther = borrowing.borrower.ownershipDesc;
                      }
                    }
                  } else {
                    if (borrowing.borrower.owners.length > 0) {
                      if (borrowing.borrower.owners[0].percentage) {

                        borrowing.percentage1 = borrowing.borrower.owners[0].percentage;
                      }
                    }

                    if (borrowing.borrower.ownershipDesc) {
                      borrowing.ownerOther = borrowing.borrower.ownershipDesc;
                    }
                    if (ownershipType == "Other") {
                      borrowing.borrowers = ownerListOption;
                    }
                  }

                } else if (ownershipType == "Tenants in Common" && ownerListOption.name == ownershipType) {
                  borrowing.borrowers = ownershipList[ownershipList.length - 2];
                  borrowing.percentage1 = borrowing.borrower.owners[0].percentage;
                  if (personalInfo.client2 && borrowing.borrower.owners[1]) {
                    borrowing.percentage2 = borrowing.borrower.owners[1].percentage;
                  }
                } else if (ownershipType == "Joint" && ownerListOption.name == ownershipType) {
                  borrowing.borrowers = ownerListOption;
                  borrowing.percentage1 = borrowing.borrower.owners[0].percentage;
                  borrowing.percentage2 = borrowing.borrower.owners[1].percentage;

                }

              }
            }
          }
        }
        return borrowings;
      },
      maskClientData: function (dataObject) {
        String.prototype.randomize = function (isNum) {
          var a = this.split(""),
            n = a.length;
          var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

          for (var i = n - 1; i > 0; i--) {
            a[i] = possible.charAt(Math.floor(Math.random() * possible.length));;
          }
          return a.join("");
        }

        String.prototype.randomizeNum = function (isNum) {
          var a = this.split(""),
            n = a.length;
          var possible = "0123456789";
          for (var i = n - 1; i > 0; i--) {
            a[i] = possible.charAt(Math.floor(Math.random() * possible.length));;
          }
          return a.join("");
        }
        // to mask fields 
        var shuffleFields = ['fName', 'mName', 'lName', 'maidenName', 'preferred', 'email',
          'hPhone', 'mPhone', 'wPhone', 'name', 'desc', 'businessName', 'title', 'abn',
          'natureOfWork', 'organisation', 'contactNo', 'contactEmail', 'addlInfo',
          'last4', 'details', 'description', 'number', 'street', 'suburb', 'employer', 'postcode',
          'positionTitle', 'postCode', 'country', 'state', 'provider', 'otherProvider', 'institution',
          'legacyId', 'description', 'other', 'findUs', 'ownershipDesc', 'managingAgentContactNo', 'managingAgentContactName',
          'managingAgent'];

        iterate(dataObject, '');

        function iterate(obj, stack) {

          for (var property in obj) {
            if (obj.hasOwnProperty(property)) {

              if (typeof obj[property] == "object" && obj[property] != null && property !== "basicExpenses") {
                iterate(obj[property], stack + '.' + property);
              } else if (obj[property] != null) {
                if (shuffleFields.indexOf(property) != -1) {

                  if (stack.indexOf('.expenses') !== -1 && property === 'desc') {
                    if (obj.hasOwnProperty('taxDeductible') && obj.taxDeductible == true || obj.hasOwnProperty('customExpense') && obj.customExpense == true) {
                      obj[property] = obj[property].randomize();
                    }
                  }
                  else if (property === 'institution' || property === 'provider' || property === 'otherProvider') {
                    obj[property] = 'Kany';
                  } else if (property === 'email') {
                    obj[property] = obj[property].randomize() + "@empowerwealth.com.au";
                  } else if (stack.includes('personalInfo.client1') || stack.includes('personalInfo.client2')) {
                    if (property !== 'title') {
                      obj[property] = obj[property].randomize();
                    }
                  } else if (property === "last4" || property === "managingAgentContactNo") {
                    obj[property] = obj[property].randomizeNum();
                  } else {

                    obj[property] = obj[property].randomize();
                  }
                }
              }
            }
          }
        }


      },
      isObjectInvalid(object) {


        if (object === null) {
          return true;
        }

        if (typeof object === "undefined") {
          return true;
        }

        if (typeof object === "string" && object.length < 1) {
          return true;
        }

        if (typeof object === "object" && object.hasOwnProperty("ownershipType") && object.ownershipType.length > 0) {
          return false;

        }

        return false;

      },
      processExpenseMonthly(expense) {

        var annual = 0;
        var annualDisc = 0;
        var annualBasic = 0;
        // if(!expense.basic && !expense.discretionary && expense.amount){
        //   expense.basic = expense.amount;
        // }
        if (expense.basic || expense.basic === 0) {
          if (expense.discretionary) {
            expense.amount = expense.basic + expense.discretionary;
          } else {
            expense.amount = expense.basic;
          }

          expense.totalString = $filter('number')(expense.amount, 2);
        } else if (expense.discretionary || expense.discretionary === 0) {
          expense.amount = expense.discretionary;
          expense.totalString = $filter('number')(expense.amount, 2);
        }

        else if (!expense.discretionary && !expense.basic) {
          expense.totalString = "";
        }
        if (typeof expense.amount === "number" && expense.frequency) {

          switch (expense.frequency) {
            case "Weekly":
              annual = (expense.amount * 52);
              annualDisc = (expense.discretionary * 52);
              annualBasic = (expense.basic * 52);
              break;
            case "Fortnightly":
              annual = (expense.amount * 26);
              annualDisc = (expense.discretionary * 26);
              annualBasic = (expense.basic * 26);
              break;
            case "Monthly":
              annual = expense.amount * 12;
              annualBasic = expense.basic * 12;
              annualDisc = expense.discretionary * 12;
              break;
            case "Every 2 months":
              annual = expense.amount * 6;
              annualBasic = expense.basic * 6;
              annualDisc = expense.discretionary * 6;
              break;
            case "Every 3 months":
              annual = expense.amount * 4;
              annualBasic = expense.basic * 4;
              annualDisc = expense.discretionary * 4;
              break;
            case "Every 6 months":
              annual = expense.amount * 2;
              annualBasic = expense.basic * 2;
              annualDisc = expense.discretionary * 2;
              break;
            case "Yearly":
              annual = expense.amount;
              annualBasic = expense.basic;
              annualDisc = expense.discretionary;
              break;
          }

          expense.monthly = $filter('number')(annual / 12, 2);


        }

        if ((!expense.discretionary && !expense.basic) || (expense.discretionary === null && expense.basic === null)) {
          expense.monthly = "";
          expense.amount = null;
          expense.totalString = "";
        }
        expense.amountStr = expense.totalString;
      },
      formatMongoError(mongoError) {
        console.log(mongoError);
        var page = null;

        var personalInfoKeys = ["personalInfo.client1", "personalInfo.client2", "personalInfo.dependants"];
        var incomeKeys = ["income.client1PersonalIncome", "client2PersonalIncome", "thirdParties"];
        var futurePlanKeys = ["futurePlans.existingFamilyDep", "futurePlans.familyPlans", "futurePlans.homeImprovement", "futurePlans.movingHome", "futurePlans.financialIndependence", "futurePlans.carChangeover", "futurePlans.plannedExpense", "futurePlans.incomeChanges"];
        var borrowingsKeys = ["borrowings.borrowing"];
        var otherAssetsKeys = ["assets.bankAccounts", "assets.vehicles", "assets.lifeInsurance", "assets.superFunds", "assets.investments", "assets.otherAssets",];
        var propertiesKeys = ["assets.properties", "assets.rentalContentValue"];
        var expensesKeys = ["expenses.expenses"];

        var pages = [{
          keys: personalInfoKeys,
          page: "Personal Information"
        },
        {
          keys: incomeKeys,
          page: "Income"
        },
        {
          keys: futurePlanKeys,
          page: "Future Plans"
        },
        {
          keys: borrowingsKeys,
          page: "Borrowings"
        },
        {
          keys: otherAssetsKeys,
          page: "Other Assets"
        },
        {
          keys: propertiesKeys,
          page: "Properties"
        },
        {
          keys: expensesKeys,
          page: "Expenses"
        }
        ];

        for (var i = 0; i < pages.length; i++) {
          var keyArray = pages[i].keys;
          var found = false;
          for (var j = 0; j < keyArray.length; j++) {
            var key = keyArray[j];
            if (mongoError.message && mongoError.message.indexOf(key) !== -1) {
              found = true;
              page = pages[i].page;
            }

          }
          if (found == true) {
            break;
          }

        }
        var errMessage = "";
        for (var prop in mongoError.errors) {
          if (mongoError.errors[prop].message) {
            errMessage = prop + ': ' + mongoError.errors[prop].message;

            break;
          }
        }
        if (page !== null) {
          var errorMessage = "Error saving client data \nPlease review data in '" + page + "' page. \n" + errMessage;
          return errorMessage;
        } else {
          return "Error saving client data \n" + errMessage;
        }

      },
      getFlexExpenseType(expenseItem) {
        var otherPayments = ['Groceries and Non-alcoholic Beverages', 'Clothing, Footwear, Dry cleaning, Repairs', 'Education - Out-of-pocket expenses', 'Medical and Health Care expenses', 'Personal Care', 'Entertainment', 'Sport, recreation and hobbies',
          'Gambling and Lotto'
        ];

        var livingExpense = ['Rent', 'House and Contents Insurance', 'Telephone - Fixed and Mobile', 'Internet',
          'Pay Television', 'Health Insurance', 'Life Insurance', 'Car Insurance', 'Education - School Fees', 'Child Care Fees'
        ];

        var transportExpenses = ['Car Registration', 'Motoring Organisations', 'Fares', 'Fuel', 'Parking and Tolls', 'Car maintenance'];

        var utitilitiesOOExpenses = ['Electricity', 'Gas', 'Local Government Rates', 'Water and Sewerage Rates and Charges'];

        var otherExpenses = ['Accountant / Tax agent fees', 'Alcohol and Tobacco', 'Books, Magazines and Newspapers', 'Dining out / Takeaway food', 'Donations', 'Furniture',
          'Holidays', 'House and Garden maintenance', 'Household Appliances and Tools', 'Household Furnishings', 'Pets and Animals', 'Presents and Gifts', 'Video / DVD purchase and hire', 'Bank Fees'
        ];
        var expenseTypeOther = 'Other Living Expenses';
        var expenseTypeLiving = 'Living Expenses';

        if (expenseItem.desc.indexOf('Education') != -1) {
          return 'Education';
        }
        if (expenseItem.desc.indexOf('Insurance') != -1 || expenseItem.desc.indexOf('insurance') != -1) {
          return 'Insurance';
        }
        if (expenseItem.customExpense == true || otherPayments.indexOf(expenseItem.desc) != -1) {


          if (expenseItem.desc.indexOf('Entertainment') != -1 || expenseItem.desc.indexOf('recreation') != -1) {
            return 'Recreation & Entertainment';
          } else if (expenseItem.desc.indexOf('Clothing') != -1 || (expenseItem.desc === 'Personal Care')) {
            return 'Clothing & Personal Care';
          } else if (expenseItem.desc.indexOf('Groceries') != -1) {
            return 'Groceries';
          } else if (expenseItem.desc.indexOf('Medical') != -1) {
            return 'Medical & Health(ex Insurance)';
          }
          return expenseTypeOther;
        } else if (transportExpenses.indexOf(expenseItem.desc) != -1) {
          return 'Transport';
        } else if (utitilitiesOOExpenses.indexOf(expenseItem.desc) != -1) {
          return 'Utilities & Rates - OO';
        } else if (otherExpenses.indexOf(expenseItem.desc) != -1) {
          return 'Other Living Expenses';
        } else if (livingExpense.indexOf(expenseItem.desc) != -1) {

          if (expenseItem.desc === 'Rent') {
            return 'Rental Expense';
          } else if (expenseItem.desc === 'Pay Television') {
            return 'Telephone & Internet';
          } else if (expenseItem.desc == 'Internet' || expenseItem.desc.indexOf('Telephone') != -1) {
            return 'Telephone & Internet';
          } else if (expenseItem.desc.indexOf('Child') != -1) {
            return 'Childcare';
          }

          return expenseTypeLiving;
        }

        return expenseTypeOther;
      },
      mapFlexExpense(expenseItem) {
        var expense = {};
        if (expenseItem.desc) {
          expense.flexType = this.getFlexExpenseType(expenseItem);
        } else {
          expense.flexType = 'Other Payments';
        }
        if (expenseItem.frequency && expenseItem.basic) {
          expense.frequency = expenseItem.frequency;
          expense.amount = expenseItem.basic;
          expense.monthlyAmount = this.getMonthly(expenseItem.basic, expenseItem.frequency);
        }
        if (expenseItem.desc) {
          expense.desc = expenseItem.desc;
        }
        expense.summaryId = expenseItem._id
        return expense;
      },
      // deprecated by DataM.getFlexSummary
      getMappedFlexExpenses(expenses, expenseFreq = 'Monthly') {

        // Map expenses
        var mappedExpenses = [];
        var mappedTypes = [];
        for (var i = 0; i < expenses.length; i++) {
          var expenseItem = this.mapFlexExpense(expenses[i]);
          mappedExpenses.push(expenseItem);
          if (mappedTypes.indexOf(expenseItem.flexType) == -1) {
            mappedTypes.push(expenseItem.flexType);
          }
        }
        var mappedFlexExpenses = [];
        for (var i = 0; i < mappedTypes.length; i++) {
          var flexExpenseItem = {
            flexType: mappedTypes[i],
            items: [],
            monthlyAmount: 0
          }
          for (var j = 0; j < mappedExpenses.length; j++) {
            var mappedExpense = mappedExpenses[j];
            if (flexExpenseItem.flexType == mappedExpense.flexType) {
              flexExpenseItem.items.push(mappedExpense);
              if (typeof mappedExpense.monthlyAmount == 'number') {
                flexExpenseItem.monthlyAmount += mappedExpense.monthlyAmount;
              }
            }
          }
          mappedFlexExpenses.push(flexExpenseItem);
        }

        return mappedFlexExpenses;
      },
      // deprecated by DataM.getFlexSummary
      mapFlexPropertyExpenses(properties, otherPaymentsFlexExpense) {
        let flexType = 'Other Payments';
        let propertyCosts =
          [
            { desc: 'Owners/Body Corp Costs' },
            { desc: 'House and contents Insurance Costs' },
            { desc: 'Land Tax Costs' },
            { desc: 'Estimated Maintenance Costs' },
            { desc: 'Management Fees' },
            { desc: 'Water Rates/Usage' },
            { desc: 'Council Rates' },
          ];

        for (var i = 0; i < propertyCosts.length; i++) {
          propertyCosts[i].flexType = flexType;
          propertyCosts[i].amount = 0;
          propertyCosts[i].monthlyAmount = 0;
          propertyCosts[i].frequency = 'Yearly'
          if (!otherPaymentsFlexExpense.items || otherPaymentsFlexExpense.items == undefined) {
            otherPaymentsFlexExpense.items = [];
          }
          otherPaymentsFlexExpense.items.push(propertyCosts[i]);
        }

        for (var i = 0; i < properties.length; i++) {
          var property = properties[i];
          if (property.purpose === 'Investment') {
            if (property.bodyCorpCosts && typeof property.bodyCorpCosts == 'number') {
              propertyCosts[0].amount += property.bodyCorpCosts;
              propertyCosts[0].monthlyAmount = this.getMonthly(propertyCosts[0].amount, propertyCosts[0].frequency);

            }
            if (property.insuranceCosts && typeof property.insuranceCosts == 'number') {
              propertyCosts[1].amount += property.insuranceCosts;
              propertyCosts[1].monthlyAmount = this.getMonthly(propertyCosts[1].amount, propertyCosts[1].frequency);

            }
            if (property.landTaxCosts && typeof property.landTaxCosts == 'number') {
              propertyCosts[2].amount += property.landTaxCosts;
              propertyCosts[2].monthlyAmount = this.getMonthly(propertyCosts[2].amount, propertyCosts[2].frequency);

            }
            if (property.maintenanceCost && typeof property.maintenanceCost == 'number') {
              propertyCosts[3].amount += property.maintenanceCost;
              propertyCosts[3].monthlyAmount = this.getMonthly(propertyCosts[3].amount, propertyCosts[3].frequency);

            }
            if (property.managementFees && typeof property.managementFees == 'number') {
              propertyCosts[4].amount += property.managementFees;
              propertyCosts[4].monthlyAmount = this.getMonthly(propertyCosts[4].amount, propertyCosts[4].frequency);

            }
            if (property.waterRates && typeof property.waterRates == 'number') {
              propertyCosts[5].amount += property.waterRates;
              propertyCosts[5].monthlyAmount = this.getMonthly(propertyCosts[5].amount, propertyCosts[5].frequency);

            }
            if (property.councilRates && typeof property.councilRates == 'number') {
              propertyCosts[6].amount += property.councilRates;
              propertyCosts[6].monthlyAmount = this.getMonthly(propertyCosts[6].amount, propertyCosts[6].frequency);

            }
            propertyCosts[0].summaryId = property._id
            propertyCosts[1].summaryId = property._id
            propertyCosts[2].summaryId = property._id
            propertyCosts[3].summaryId = property._id
            propertyCosts[4].summaryId = property._id
            propertyCosts[5].summaryId = property._id
            propertyCosts[6].summaryId = property._id
          }

          // propertyCosts[i].summaryId = property._id
        }
        for (var i = 0; i < propertyCosts.length; i++) {
          otherPaymentsFlexExpense.monthlyAmount += propertyCosts[i].monthlyAmount;
        }
        return otherPaymentsFlexExpense;

      },
      getMonthly(amount, frequency) {
        var annual = 0;
        if (amount > 0) {
          switch (frequency) {
            case "Weekly":
              annual = (amount * 52);
              break;
            case "Fortnightly":
              annual = (amount * 26);
              break;
            case "Monthly":
              annual = amount * 12;
              break;
            case "Every 2 months":
              annual = amount * 6;
              break;
            case "Every 3 months":
              annual = amount * 4;
              break;
            case "Every 6 months":
              annual = amount * 2;
              break;
            case "Yearly":
              annual = amount;
              break;
          }
        }
        var monthly = (annual / 12);

        return monthly;
      },
      getYearly(amount, frequency) {
        var annual = 0;
        if (amount > 0) {
          switch (frequency) {
            case "Weekly":
              annual = (amount * 52);
              break;
            case "Fortnightly":
              annual = (amount * 26);
              break;
            case "Monthly":
              annual = amount * 12;
              break;
            case "Every 2 months":
              annual = amount * 6;
              break;
            case "Every 3 months":
              annual = amount * 4;
              break;
            case "Every 6 months":
              annual = amount * 2;
              break;
            case "Yearly":
              annual = amount;
              break;
          }
        }
        return annual;
      },
      getFlexMonthlyTotal(flexExpenses) {
        var totalMonthly = 0;
        for (var i = 0; i < flexExpenses.length; i++) {
          totalMonthly += flexExpenses[i].monthlyAmount;
        }
        return totalMonthly;
      },
      arrangeFlexExpenseOrder(flexExpenses) {
        var defaultOrder = ['Utilities & Rates - OO',
          'Utilities & Rates - INV',
          'Telephone & Internet',
          'Groceries',
          'Recreation & Entertainment',
          'Clothing & Personal Care',
          'Medical & Health(ex Insurance)',
          'Transport',
          'Education',
          'Childcare',
          'Insurance',
          'Other Living Expenses'];

        let newFlexOrder = [];


        for (var i = 0; i < flexExpenses.length; i++) {
          var flexItem = flexExpenses[i];

          if (defaultOrder.includes(flexItem.flexType)) {
            let newIndex = defaultOrder.indexOf(flexItem.flexType);
            newFlexOrder[newIndex] = flexItem;
          }
        }

        // Ensure that there is no skipped index
        let reIndexedFlexExpenses = [];
        newFlexOrder.forEach(function (item) {
          reIndexedFlexExpenses.push(item);
        })

        return reIndexedFlexExpenses;
      }

    }


  });


// Industry
angular.module('meanApp')
  .factory('IndustryDivision', function ($resource) {
    return $resource('/api/industry/industryDivisions', {

    }, {
      getIndustryDivisions: {
        method: 'GET',
        params: {

        }
      }
    });
  });

angular.module('meanApp')
  .factory('IndustryClass', function ($resource) {
    return $resource('/api/industry/industryClasses', {

    }, {
      getIndustryClasses: {
        method: 'GET',
        params: {

        }
      }
    });
  });

angular.module('meanApp')
  .factory('OptiMessageStatus', function ($resource) {
    return $resource('/api/opti-message-status/:controller', {
    }, {
      getCurrentUserStatus: {
        method: 'GET',
        params: {
          controller: "get-current-user-status"
        }
      },
      updateAction: {
        method: 'PUT',
        params: {
          controller: "update-action"
        }
      }
    });
  });
