angular.module('meanApp')
  .controller('landing-controller', function ($scope, $state, $stateParams, Auth, DataMSPortal, DataM) {
    DataM.getSummary().then(function (data) {
      const client = data.clientSummary;
      if(client.surveyComplete) {
        $state.go('dashboard')
      }
    });

    $scope.isAdmin = false;
    Auth.isAdmin().then(isAdmin => ($scope.isAdmin = isAdmin));

    $scope.loadingMessage = "Loading...";
    $scope.showClientSpinner = false;
    $scope.names = {};
    $scope.personalInfo = {hasPartner:false};
    $scope.properties = {};
    $scope.showErrorMessage = false;
    $scope.error = {};
    $scope.address = {};

    $scope.savedText = "Save";

    // if admin add uid to get request
    var requestPayload = {};

    Auth.isAdmin().then(isAdmin => {
      if (isAdmin) {
        requestPayload.uid = $stateParams.clientId;
      }
      DataMSPortal.getPersonalInfo(requestPayload).then(function(data) {
        if (data.personalInfo) {
          $scope.names.firstName = getName(data.personalInfo.client1, "first");
          $scope.names.lastName = "";
          $scope.names.partnerFirstName = getName(
            data.personalInfo.client2,
            "first"
          );
          $scope.names.partnerLastName = "";
          $scope.personalInfo.client1 = data.personalInfo.client1;
          $scope.address = data.personalInfo.client1.address;
          if (data.personalInfo.client2) {
            $scope.personalInfo.hasPartner = true;
            $scope.personalInfo.client2 = data.personalInfo.client2;
          }
        }
        if (data.properties) {
          $scope.properties = data.properties;
        }
      });
    });
    
    $scope.saveName = function (stay) {
      formatName();
      
      $scope.personalInfo.client1.address = $scope.address;

      if($scope.personalInfo.hasPartner != true){
        $scope.personalInfo.client2=null;
        delete $scope.personalInfo.client2;
      }
      var formData = {
        personalInfo: $scope.personalInfo
      };
      formData.uid = $stateParams.clientId;
      console.log("formData :",formData);


      $scope.showClientSpinner = true;
      DataMSPortal.savePersonalInfo(formData).then(function (data) {
      
      $scope.showClientSpinner = false;
      if (stay==null){
        $state.go('step1')}

      $scope.savedText = "Saved";

      }).catch(function (err) {
        console.log('Save error', err);
        $scope.showClientSpinner = false;
        $scope.showErrorMessage = true;
        $scope.error.title = "Save Error";
        $scope.error.message = "There seems to be a problem with saving your data. Please try to refresh the page and try again. If error still occurs Please contact an Adviser from Empower Wealth.";

      });
    }
    // Get Name of client
    function getName(client, type) {
      if (client) {
        if (type.toLowerCase() == 'first') {
          return client.fName;
        } else if (type.toLowerCase() == 'last') {
          return client.lName;
        }
      }
      return "";
    }

    function formatName() {
      $scope.personalInfo.client1.fName = $scope.names.firstName;
      $scope.personalInfo.client1.lName = $scope.names.lastName;
   
      if($scope.personalInfo.hasPartner){
        if(!$scope.personalInfo.client2){
          $scope.personalInfo.client2 = {};
        }
        $scope.personalInfo.client2.fName = $scope.names.partnerFirstName;
        $scope.personalInfo.client2.lName = $scope.names.partnerLastName;
        
      } else {
        $scope.personalInfo.client2 = {}; 
      }

    }

    $scope.closeError = function () {
      $scope.showErrorMessage = false;
    }
  });
