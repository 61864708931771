function AppInterceptor() {
  return {
    request(config) {
      const apiRequest = config.url.indexOf('api') !== -1 || config.url.indexOf('auth') !== -1;
      if(apiRequest) {
        config.url = `${process.env.API_ENDPOINT}${config.url}`
      }
      return config;
    },
  };
}

function BaseAppConfig(
  $stateProvider,
  $urlRouterProvider,
  $locationProvider,
  $httpProvider,
  $sceDelegateProvider,
  ngIntlTelInputProvider
) {
  'ngInject';

  $urlRouterProvider.otherwise(function ($injector, $location) {
    let $state = $injector.get('$state');
    $state.go('login', {redirect: $location.url().substr(1)});
  });

  $locationProvider.html5Mode(true);

  ngIntlTelInputProvider.set({
    initialCountry: "au",
    separateDialCode: true,
    preferredCountries: ["au", "nz"]
  });

  $httpProvider.interceptors.push(AppInterceptor);
}

export default BaseAppConfig;
