import {
  incomeTaxScales,
  lowIncomeTaxOffsetScales,
  medicalLevyScales,
} from "./taxCalculationRates";

export const computeEstTaxPaid = (totalTaxableIncome) => {
  const taxableIncome = Math.floor(totalTaxableIncome);
  let incomeTax = 0;
  let lowIncomeTaxOffset = 0;
  let medicalLevy = 0;

  // generic tax calculator function
  const calculate = (scales, previousIndex, currentIndex) => {
    return (
      (taxableIncome - scales[previousIndex].max) *
        scales[currentIndex].excessRate +
      scales[currentIndex].tax
    );
  };

  // Income Tax
  if (taxableIncome > incomeTaxScales[3].max)
    incomeTax = calculate(incomeTaxScales, 3, 4);
  else if (taxableIncome > incomeTaxScales[2].max)
    incomeTax = calculate(incomeTaxScales, 2, 3);
  else if (taxableIncome > incomeTaxScales[1].max)
    incomeTax = calculate(incomeTaxScales, 1, 2);
  else if (taxableIncome > incomeTaxScales[0].max)
    incomeTax = calculate(incomeTaxScales, 0, 1);
  else incomeTax = calculate(incomeTaxScales, 0, 0);

  // Low Income Tax Offset
  if (taxableIncome > lowIncomeTaxOffsetScales[2].max)
    lowIncomeTaxOffset = calculate(lowIncomeTaxOffsetScales, 2, 3);
  else if (taxableIncome > lowIncomeTaxOffsetScales[1].max)
    lowIncomeTaxOffset = calculate(lowIncomeTaxOffsetScales, 1, 2);
  else if (taxableIncome > lowIncomeTaxOffsetScales[0].max)
    lowIncomeTaxOffset = calculate(lowIncomeTaxOffsetScales, 0, 1);
  else lowIncomeTaxOffset = calculate(lowIncomeTaxOffsetScales, 0, 0);

  // Medical Levy
  if (taxableIncome > medicalLevyScales[1].max)
    medicalLevy = calculate(medicalLevyScales, 1, 2);
  else if (taxableIncome > medicalLevyScales[0].max)
    medicalLevy = calculate(medicalLevyScales, 0, 1);
  else medicalLevy = calculate(medicalLevyScales, 0, 0);

  // Calculates estimated tax paid
  const estimatedTaxPaid = incomeTax - lowIncomeTaxOffset + medicalLevy;

  if (estimatedTaxPaid < 0) return 0;

  return Math.round(estimatedTaxPaid);
};
