"use strict";

angular.module("meanApp").config(function ($stateProvider) {
  $stateProvider.state("futurePlansChanges", {
    url: "/futurePlansChanges/:clientId",
    params: {
      category: {
        value: null
      },
      clientId: {
        value: null
      }
    },
    templateUrl: "app/futurePlansChanges/futurePlansChanges.html",
    controller: "futurePlansChanges-controller",
    authenticate: ["admin"],
  });
});
