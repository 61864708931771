// 'use strict';

// angular.module('meanApp')
//   .config(function ($stateProvider) {
//     $stateProvider
//       .state('personalinfo', {
//         url: '/personal-info/:clientId',
//         params: {
//           clientId: {
//             value: null,
//             squash: false
//           }
//         },
//         templateUrl: 'app/personalInfo/personal-info.html',
//         controller: 'factfind-1-controller',
//         authenticate: ['user', 'admin']
//       })
//   });
