'use strict';

angular.module('meanApp')
  .config(function ($stateProvider) {
    $stateProvider
      .state('dashboard', {
        url: '/dashboard/:clientId',
        params: {
          clientId: {
            value: null,
            squash: false
          }
        },
        templateUrl: 'app/dashboard/dashboard.html',
        controller: 'dashboard-controller',
        authenticate: ['user','admin']
      })

     /* .state('dashboard-advisor', {
        url: '/dashboard-advisor',
        templateUrl: 'app/dashboard/dashboard.html',
        controller: 'dashboard-advisor-controller',
        authenticate: 'admin'
      })*/

      .state('dashboard-search', {
        url: '/dashboard-search',
        templateUrl: 'app/dashboard/dashboard-search.html',
        controller: 'dashboard-search-controller',
        authenticate: 'admin'
      });
  });
