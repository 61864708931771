angular.module('meanApp')
  .controller('step2-controller', function ($scope, $rootScope, $state, $stateParams, $filter, DataM, $window, Auth, empowerDataService, $timeout, Idle, DataHelper, $document, DataMSPortal,_) {
    $scope.isAdmin = false;
    Auth.isAdmin().then(isAdmin => ($scope.isAdmin = isAdmin));

    $scope.loadingMessage = "Loading...";
    $scope.isSecondStep = true;
    $scope.empowerData = empowerDataService;
    $scope.isSpendingShown = false;
    $scope.isBillsShown = true;

    $scope.savedText = "Save";
    $scope.stepsState = {
      back: 'step1',
      next: 'step3',
    }
    $scope.otherExpenses = [];
    $scope.defaultExpensesBills = empowerDataService.defaultExpensesBills.slice();
    $scope.defaultExpensesSpending = empowerDataService.defaultExpensesSpending.slice();
    $scope.borrowerList = [];
    $scope.incomeOwnerList = [];
    $scope.loanTypes = ['Home Loan - Basic Variable', 'Home Loan - Standard Variable', 'Home Loan - Fixed Rate',
      'Home Loan - Line of Credit (Personal Use)', 'Investment Loan - Basic Variable', 'Investment Loan - Standard Variable',
      'Investment Loan - Fixed Rate', 'Investment Loan - Line of Credit (Investment Use)', 'Credit Card', 'Car Loan', 'Personal Loan', 'ATO or Centrelink Debt',
      'HECS/HELP Debt', 'Hire Purchase', 'Lease', 'Overdraft', 'Store Card', 'Commercial Bill', 'Charge Card', 'Other'
    ];


    $scope.primaryPurposes = ['Buy Real Estate', 'Refinance Real Estate', 'Debt Consolidation', 'General Spending', 'Other Purchases/Items'];

    $scope.client1Name = "Client";
    $scope.client2Name = "Partner";
    $scope.personalInfo = {};
    $scope.hasPartner = false;


    $scope.incomes = [{}];
    $scope.loans = [];
    $scope.addIncome = function () {
      $scope.incomes.push({
        type:"PAYG",
        owner:$scope.personalInfo.client1._id
      });
    }
    $scope.properties = [];
    $scope.investments = [];
    $scope.owners = [];


    $scope.showClientSpinner = false;
    $scope.showErrorMessage = false;
    $scope.error = {};

    $scope.deleteIncome = function (income) {
      $scope.incomes.splice($scope.incomes.indexOf(income), 1);
    }

    $scope.addLoan = function () {
      var loan = {borrowers:$scope.borrowerList[0]};
      $scope.loans.push(loan);
      $scope.assignBorrower(loan, loan.borrowers);
    }

    $scope.addOtherExpense = function () {
      var otherExpense = empowerDataService.newOtherExpense();
      $scope.otherExpenses.push(otherExpense);
    }
    $scope.deleteLoan = function (loan) {
      $scope.loans.splice($scope.loans.indexOf(loan), 1);
    }

    $scope.deleteOtherExpense = function (expense) {
      $scope.otherExpenses.splice($scope.otherExpenses.indexOf(expense), 1);
    }

    $scope.swithExpense = function (expenseType) {
      if (expenseType == 'Spending') {
        $scope.isBillsShown = false;
        $scope.isSpendingShown = true;
      }
      if (expenseType == 'Bills') {
        $scope.isBillsShown = true;
        $scope.isSpendingShown = false;
      }
    }

    /**
     * Format Form data to server accepted data
     * Client portal format
     */
    $scope.formattedIncomeData = {
      client1PersonalIncome: {
        currentEmployment: [],
        other: []
      },
      client2PersonalIncome: {
        currentEmployment: [],
        other: []
      }
    };
    $scope.formatIncome = function () {

      $scope.properties = [];
      $scope.investments = [];
      for (var i = 0; i < $scope.incomes.length; i++) {
        var income = $scope.incomes[i];
        if (income.type == "Rental Income") {
          mapRentalIncome(income, $scope.properties);
        } else if(income.type == "Investment Income"){
          mapInvestmentIncome(income, $scope.investments);
        }else {
          if ($scope.personalInfo.client1._id == income.owner) {
            mapIncome($scope.formattedIncomeData.client1PersonalIncome, income);
          }
          if ($scope.hasPartner) {
            if ($scope.personalInfo.client2._id == income.owner) {
              mapIncome($scope.formattedIncomeData.client2PersonalIncome, income);
            }
          }
        }
      }
    }

    function mapIncome(employmentArray, income) {
      var employment = {};
      if (income.type === "PAYG") {
        var job = {
          basis: "Full time",
          annualSalary: income.amount,
          nature: income.description
        };
        employment = {
          employmentType: "PAYG",
          job: job
        };
      } else if (income.type === "Self Employed") {
        var business = {
          basis: "Sole trader",
          natureOfWork: income.description,
          earnings: [{
            salary: income.amount
          }]
        };
        employment = {
          employmentType: "Self employed",
          business: business
        };
      } else if (income.type === "Business") {
        var business = {
          basis: "Sole trader",
          investmentType:"Business",
          natureOfWork: income.description,
          earnings: [{
            salary: income.amount
          }]
        };
        employment = {
          employmentType: "Business",
          business: business
        };
      } else if (income.type === "Government Income") {
        var otherIncome = {
          type: "Other",
          description: income.description,
          amount: income.amount,
          isTaxFree: income.isTaxFree,
        };

        employmentArray.other.push(otherIncome);
      }
      if (employment.employmentType) {
        employmentArray.currentEmployment.push(employment);
      }
    }


    function mapRentalIncome(income, propertyArray) {
      var property = {
        purpose: "Investment",
        grossRentalIncome: income.amount,
        ownership: {
          ownershipType: "Sole",
          owners: [{
            owner: income.owner,
            percentage: 100
          }]
        },
        addlInfo: income.description,
      };
      propertyArray.push(property);
    }
    function mapInvestmentIncome(income, investmentArray) {
      var investment = {
        yearlyIncome: income.amount,
        investmentType:"Business",
        ownership: {
          ownershipType: "Sole",
          owners: [{
            owner: income.owner,
            percentage: 100
          }]
        },
        addlInfo: income.description,
      };
      investmentArray.push(investment);
    }
    $scope.assignBorrower = function (loan, owner) {
      var loanObject = $scope.loans[$scope.loans.indexOf(loan)];

      if (owner.name == "Joint") {

        if ($scope.personalInfo.client2) {
          loanObject.borrower = {
            ownershipType: 'Joint',
            ownershipDesc: '',
            owners: [{
                percentage: loanObject.percentage1,
                name: $scope.personalInfo.client1.fName,
                owner: $scope.personalInfo.client1._id
              },
              {
                percentage: loanObject.percentage2,
                name: $scope.personalInfo.client2.fName,
                owner: $scope.personalInfo.client2._id
              }
            ],
          };
        }
      } else if (owner.name == "Tenants in Common") {
        loanObject.borrower = {
          ownershipType: 'Tenants in Common',
          ownershipDesc: '',
          owners: [{
            percentage: loanObject.percentage1,
            name: $scope.personalInfo.client1.fName,
            owner: $scope.personalInfo.client1._id
          }]
        };
        if ($scope.personalInfo.client2) {

          var owner2 = {
            percentage: loanObject.percentage2,
            name: $scope.personalInfo.client2.fName,
            owner: $scope.personalInfo.client2._id,
          }
          loanObject.borrower.owners.push(owner2);
        }
      } else if (owner.name == "Other") {
        loanObject.borrower = {
          ownershipType: 'Other',
          ownershipDesc: loanObject.ownerOther,
          owners: [{
            percentage: loanObject.percentage1,
            name: $scope.personalInfo.client1.fName,
            owner: $scope.personalInfo.client1._id
          }]
        };
      } else {
        loanObject.otherBorrowerPercentage = null;
        loanObject.otherBorrowerAddlInfo = null;
        loanObject.borrower = {
          ownershipType: 'Sole',
          ownershipDesc: '',
          owners: [{
            percentage: 100,
            name: owner.name,
            owner: owner.id
          }],
        };
      }
    };
    // if admin add uid to get request
    var requestPayload = {};

    Auth.isAdmin().then(isAdmin => {
      if(isAdmin) {
        requestPayload.uid = $stateParams.clientId;
      }
  
      DataMSPortal.getStep2(requestPayload).then(function (data) {
       
        if (data.personalInfo) {
          $scope.client1Name = getName(data.personalInfo.client1, 'first');
          $scope.client2Name = getName(data.personalInfo.client2, 'first');
  
          $scope.borrowerList = DataHelper.getOwnerList(data, false, true, true);
          $scope.incomeOwnerList = getIncomeOwnerList(data.personalInfo);
  
          $scope.personalInfo = data.personalInfo;
  
          if (data.personalInfo.client2) {
            $scope.hasPartner = true;
          }
  
          if (data.expenses && data.expenses.expenses) {
            mapRetrieveExpenseData(data.expenses.expenses, $scope.defaultExpensesBills);
  
            mapRetrieveExpenseData(data.expenses.expenses, $scope.defaultExpensesSpending);
          }
          if (data.income) {
            setScopeIncomes(data);
  
            mapIncomeData(data.income.client1PersonalIncome, $scope.incomes, '1');
            mapIncomeData(data.income.client2PersonalIncome, $scope.incomes, '2');
          }
  
          if (data.assets && data.assets.properties) {
            if(data.assets.properties.length>0 && $scope.incomes.length==0){
              $scope.incomes = [];
            }
            mapRetrievePropertyData(data.assets.properties);
            mapRetrieveInvestmentData(data.assets.investments);
          }
  
          if (data.borrowings && data.borrowings.borrowing) {
            $scope.loans = DataHelper.formatBorrower(data.borrowings.borrowing.filter((_borrowing) => !_borrowing.isClosed  ), data.personalInfo, $scope.borrowerList);
  
            // $scope.loans = data.borrowings.borrowing;
          }
          for(var i =0; i< $scope.incomes.length; i++){
              var income = $scope.incomes[i];
              $scope.numbersOnly(income,'amount');
          }
          for(var i =0; i< $scope.loans.length; i++){
            var loan = $scope.loans[i];
            $scope.numbersOnly(loan,'outstanding');
            $scope.numbersOnly(loan,'repayment');
            $scope.numbersOnly(loan,'interestRate');
        }
  
        }
      });
    });



    function getName(client, type) {
      if (client) {
        if (type.toLowerCase() == 'first') {
          return client.fName;
        } else if (type.toLowerCase() == 'last') {
          return client.lName;
        }
      }
      return "";
    }

    function setScopeIncomes(data) {

      let hasIncome = false;
      
      if (data.income.client1PersonalIncome && hasAtLeastOneIncome(data.income.client1PersonalIncome)) {
        hasIncome = true;
      }
      if (data.income.client2PersonalIncome && hasAtLeastOneIncome(data.income.client2PersonalIncome)) {
        hasIncome = true;
      }

      if (hasIncome == true) {
        $scope.incomes = [];
      }
    }

    function getIncomeOwnerList(personalInfo) {
      var incomeOwnerList = [];

      if (personalInfo.client1) {
        var owner1 = {
          owner: personalInfo.client1._id,
          ownerName: personalInfo.client1.fName
        };
        incomeOwnerList.push(owner1);
      }
      if (personalInfo.client2) {
        var owner2 = {
          owner: personalInfo.client2._id,
          ownerName: personalInfo.client2.fName
        };
        incomeOwnerList.push(owner2);
      }

      return incomeOwnerList;
    }
    /**
     * Save function
     * send mapped form data to server
     */
    $scope.save = function (stay) {
      $scope.formatIncome();
      $scope.showClientSpinner = true;
      var expenses = [];
      expenses = expenses.concat($scope.defaultExpensesBills);
      expenses = expenses.concat($scope.defaultExpensesSpending);
      expenses = expenses.concat($scope.otherExpenses);

      var step2Data = {
        income: $scope.formattedIncomeData,
        expenses: {
          expenses: expenses
        },
        assets: {
          properties: $scope.properties,
          investments:$scope.investments
        },
        borrowings: {
          borrowing: $scope.loans
        }
      };

      saveStep2(step2Data);
      $state.go('step3')
    }

    $scope.saveBorrowings = function () {
      $scope.showClientSpinner = true;
      
      $scope.formatIncome();
      var step2Data = {
        borrowings: {
          borrowing: $scope.loans
        }
      };

      saveStep2(step2Data);
      $scope.savedText = "Saved";
    }

    $scope.saveExpenses = function () {
      $scope.showClientSpinner = true;
      var expenses = [];
      expenses = expenses.concat($scope.defaultExpensesBills);
      expenses = expenses.concat($scope.defaultExpensesSpending);
      expenses = expenses.concat($scope.otherExpenses);

      var step2Data = {
        expenses: {
          expenses: expenses
        }
      };

      saveStep2(step2Data);
      $scope.savedText = "Saved";
    }

    $scope.saveIncome = function () {
      $scope.formattedIncomeData = {
        client1PersonalIncome: {
          currentEmployment: [],
          other: []
        },
        client2PersonalIncome: {
          currentEmployment: [],
          other: []
        }
      };
      $scope.formatIncome();
      $scope.showClientSpinner = true;

      var step2Data = {
        income: $scope.formattedIncomeData,
        assets: {
          properties: $scope.properties,
          investments:$scope.investments
        }
      };

      saveStep2(step2Data);
      $scope.savedText = "Saved";
    }


    function saveStep2(step2Data) {

      DataMSPortal.saveStep2(step2Data).then(function (data) {

          $scope.showClientSpinner = false;
        }

      ).catch(function (err) {
        console.log('Save error', err);
        $scope.showClientSpinner = false;
        $scope.showErrorMessage = true;
        $scope.error.title = "Save Error";
        $scope.error.message = "There seems to be a problem with saving your data. Please try to refresh the page and try again. If error still occurs Please contact an Adviser from Empower Wealth.";
      });
    }



    $scope.closeError = function () {
      $scope.showErrorMessage = false;
    }

    /**
     * ============ MAP Data from Server to show on Forms
     */
    function mapIncomeData(personalIncomeData, incomeArrayHolder, clientNum) {

      if (personalIncomeData) {
        if (personalIncomeData.currentEmployment) {
          for (var i = 0; i < personalIncomeData.currentEmployment.length; i++) {

            var employmentIncome = personalIncomeData.currentEmployment[i];
            var mappedIncomeData = {};
            setOwner(clientNum, mappedIncomeData);

            switch (employmentIncome.employmentType) {
              case "PAYG":
                mappedIncomeData.type = "PAYG";
                mappedIncomeData.amount = _.get(employmentIncome,'job.annualSalary',0);
                mappedIncomeData.description = employmentIncome.job.nature;
                break;
              case "Business":
                mappedIncomeData.type = "Business";
                mappedIncomeData.amount = _.get(employmentIncome,'business.earnings[0].salary',0);
                mappedIncomeData.description = employmentIncome.business.natureOfWork;
                break;
              case "Self employed":
                mappedIncomeData.type = "Self Employed";
                mappedIncomeData.amount = _.get(employmentIncome,'business.earnings[0].salary',0);
                mappedIncomeData.description = employmentIncome.business.natureOfWork;
                break;
            }

            if (mappedIncomeData.type) {
              $scope.incomes.push(mappedIncomeData);
            }
          }
        }
        if (personalIncomeData.other) {
          for (var i = 0; i < personalIncomeData.other.length; i++) {

            var otherIncome = personalIncomeData.other[i];
            var mappedOtherIncome = {};
            mappedOtherIncome.type = "Government Income";
            mappedOtherIncome.amount = otherIncome.amount;
            mappedOtherIncome.description = otherIncome.description;
            mappedOtherIncome.isTaxFree = otherIncome.isTaxFree;

            setOwner(clientNum, mappedOtherIncome);
            $scope.incomes.push(mappedOtherIncome);

          }
        }
      }

    }

    function hasAtLeastOneIncome(dataIncome) {
     
      if (dataIncome.currentEmployment && dataIncome.currentEmployment.length > 0) {
        return true;
      }
      if (dataIncome.other && dataIncome.other.length > 0) {
        return true;
      }
      return false;

    }

    function setOwner(clientNum, object) {
      if (clientNum == '1') {
        object.owner = $scope.personalInfo.client1._id;
      } else if (clientNum == '2') {
        if ($scope.personalInfo.client2 && $scope.personalInfo.client2._id) {
          object.owner = $scope.personalInfo.client2._id;
        } else {
          object.owner = $scope.personalInfo.client1._id;
        }
      }
    }

    function mapRetrieveExpenseData(expensesData, expenseArrayHolder) {

      for (var i = 0; i < expensesData.length; i++) {
        var expenseData = expensesData[i];
        
        if(!expenseData.frequency || expenseData.frequency.length < 1) {
          expenseData.frequency = "Monthly";
        }

        if (expenseData.customExpense == true && $scope.otherExpenses.indexOf(expenseData) == -1) {
          $scope.otherExpenses.push(expenseData);
        } else {
          for (var j = 0; j < expenseArrayHolder.length; j++) {
            var holderExpense = expenseArrayHolder[j];
            if (expenseData.desc === holderExpense.desc) {

              expenseArrayHolder[j] = expenseData;

              break;
            }
          }
        }
      }
    }

    function mapRetrievePropertyData(propertiesData) {
      if (propertiesData) {
        for (var i = 0; i < propertiesData.length; i++) {

          var property = propertiesData[i];
          var mappedRentalIncome = {};
          mappedRentalIncome.type = "Rental Income";
          mappedRentalIncome.amount = property.grossRentalIncome;
          mappedRentalIncome.description = property.addlInfo;
          if (property.ownership && property.ownership.owners && property.ownership.owners[0]) {
            mappedRentalIncome.owner = property.ownership.owners[0].owner;
          }
          $scope.incomes.push(mappedRentalIncome);

        }
      }
    }

    function mapRetrieveInvestmentData(investmentData){
      if (investmentData) {
        for (var i = 0; i < investmentData.length; i++) {

          var investment = investmentData[i];
          var mappedInvestmentIncome = {};
          mappedInvestmentIncome.type = "Investment Income";
          mappedInvestmentIncome.amount = investment.yearlyIncome;
          mappedInvestmentIncome.description = investment.addlInfo;
          if (investment.ownership && investment.ownership.owners && investment.ownership.owners[0]) {
            mappedInvestmentIncome.owner = investment.ownership.owners[0].owner;
          }
          $scope.incomes.push(mappedInvestmentIncome);

        }
      }
    }
    
    $scope.numbersOnly = function(object, propertyToChange) {
      var transformedText  = '';
      
      var text = "";
      if(object[propertyToChange+"Str"] &&  object[propertyToChange+"Str"].length>0){
        text = object[propertyToChange+"Str"];
      } else if(typeof object[propertyToChange] == "number"){
        text = object[propertyToChange].toString();
      } else {
      }
      if(text) {
        var countDots = (text.match('.') || []).length;
        let textArr = text.split('.');
        countDots = textArr.length;
        if(countDots > 2) {
         textArr = textArr.slice(0,textArr.length-1);
          text = textArr.join('.');
        }
        transformedText = text.replace(/[^0-9.]/g, '');
        if(propertyToChange != "interestRate"){
          transformedText = "$"+transformedText;
        } else {
          transformedText = transformedText+"%";
        }
      }
      let valueStr= transformedText.replace('$','');
      valueStr = valueStr.replace('%','');
      object[propertyToChange+"Str"] = transformedText;
      object[propertyToChange] = parseFloat(valueStr);

    }

  });
