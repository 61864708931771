"use strict";

angular.module("meanApp").config(function($stateProvider) {
  $stateProvider.state("online-simulator", {
    url: "/online-simulator/:clientId",
    params: {
      clientId: {
        value: null,
        squash: false
      }
    },
    templateUrl: "app/onlineSimulator/online-simulator.html",
    controller: "online-simulator-controller",
    authenticate: "admin"
  });
});
