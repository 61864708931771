angular.module('meanApp')
  .controller('step1-controller', function ($scope, $rootScope, $state, $stateParams, $filter, DataM, $window, Auth, empowerDataService, $timeout, Idle, DataHelper, $document) {
    $scope.isAdmin = false;
    Auth.isAdmin().then(isAdmin => ($scope.isAdmin = isAdmin));

    $scope.loadingMessage = "Loading...";
    $scope.isFirstStep = true;
    $scope.empowerData = empowerDataService;
    $scope.timeAllocated = {};

    $scope.showClientSpinner = false;
    $scope.showErrorMessage = false;
    $scope.error = {};

    $scope.savedText = "Save";
    $scope.clientId = $stateParams.clientId;
    $scope.stepsState = {
      back: 'step1',
      next: 'step2',
    }
    $scope.defaultExpenses = [{
        desc: 'Rent'
      }, {
        desc: 'Local Government Rates'

      }, {
        desc: 'Water and Sewerage Rates and Charges'
      }, {
        desc: 'House and Contents Insurance'
      }, {
        desc: 'Electricity'
      }, {
        desc: 'Gas'
      }, {
        desc: 'Telephone - Fixed and Mobile'
      }, {
        desc: 'Internet'
      }, {
        desc: 'Pay Television'
      }, {
        desc: 'Health Insurance'
      }, {
        desc: 'Life Insurance'
      }, {
        desc: 'Car Registration'
      }, {
        desc: 'Car Insurance'
      }, {
        desc: 'Motoring Organisations'
      }, {
        desc: 'Education - School Fees'
      }, {
        desc: 'Child Care Fees'
      }, {
        desc: 'Bank Fees'
      },
      {
        desc: "Groceries and Non-alcoholic Beverages",
      },
      {
        desc: "Clothing, Footwear, Dry cleaning, Repairs",
      },
      {
        desc: "Education - Out-of-pocket expenses",
      },
      {
        desc: "Medical and Health Care expenses",
      },
      {
        desc: "Personal Care",
      },
      {
        desc: "Pets and Animals",
      },
      {
        desc: "Household Appliances and Tools",
      },
      {
        desc: "Furniture",
      },
      {
        desc: "Household Furnishings",
      },
      {
        desc: "House and Garden maintenance",
      },
      {
        desc: "Fuel",
      },
      {
        desc: "Car maintenance",
      },
      {
        desc: "Parking and Tolls",
      },
      {
        desc: "Fares",
      },
      {
        desc: "Presents and Gifts",
      },
      {
        desc: "Donations",
      },
      {
        desc: "Holidays",
      },
      {
        desc: "Dining out / Takeaway food",
      },
      {
        desc: "Entertainment",
      },
      {
        desc: "Sport, recreation and hobbies",
      },
      {
        desc: "Books, Magazines and Newspapers",
      },
      {
        desc: "Video / DVD purchase and hire",
      },
      {
        desc: "Alcohol and Tobacco",

      },
      {
        desc: "Gambling and Lotto",
      }
    ];

    $scope.incomes = [{}];

    $scope.addIncome = function () {
      $scope.incomes.push({});
    }

    $scope.infoReq = {
      bankStatements: null,
      creditCardStatements: null,
    };
    $scope.incomeTips = {};
    $scope.expensesTips = {};

    if (sessionStorage.getItem('incomeTips')) {
      $scope.incomeTips = JSON.parse(sessionStorage.getItem('incomeTips'));
    }
    if (sessionStorage.getItem('expenseTips')) {
      $scope.expensesTips = JSON.parse(sessionStorage.getItem('incomeTips'));
    }
    if (sessionStorage.getItem('infoReq')) {
      $scope.infoReq = JSON.parse(sessionStorage.getItem('infoReq'));
    }
    if (sessionStorage.getItem('defaultExpenses')) {
      $scope.defaultExpenses = JSON.parse(sessionStorage.getItem('defaultExpenses'));
    }
    if (sessionStorage.getItem('timeAllocated')) {
      $scope.timeAllocated = JSON.parse(sessionStorage.getItem('timeAllocated'));
    }

    $scope.next = function () {
      $scope.showClientSpinner = true;
      sessionStorage.setItem('incomeTips', JSON.stringify($scope.incomeTips));
      sessionStorage.setItem('expenseTips', JSON.stringify($scope.expensesTips));
      sessionStorage.setItem('infoReq', JSON.stringify($scope.infoReq));
      sessionStorage.setItem('defaultExpenses', JSON.stringify($scope.defaultExpenses));
      sessionStorage.setItem('timeAllocated', JSON.stringify($scope.timeAllocated));
      $scope.save();
    }

    // if admin add uid to get request
    var requestPayload = {};

   

    DataM.getClientSurvey(function (data) {
     
      if (data.clientSurvey && data.clientSurvey.hasOwnProperty('timeAllocated')) {
        $scope.timeAllocated = data.clientSurvey.timeAllocated;
      }

    });


    $scope.save = function (stay) {
      var formData = {
        'clientSurvey': {
          'topicsToDiscuss': {},
          'timeAllocated': $scope.timeAllocated,
          'quiz': {},
          'selfAssessment': {},
          'whatMoneyMeans': {},
          'findUs': '',
          'referrer': ''
        }
      };
      //SAVE TO DB
      DataM.saveClientSurvey(formData).then(function (data) {
        
        $scope.showClientSpinner = false;
        if (stay==null){
          $state.go('step2')}

          $scope.savedText = "Saved";  

      }).catch(function (err) {
        console.log('Save error', err);
        $scope.showClientSpinner = false;
        $scope.showErrorMessage = true;
        $scope.error.title = "Save Error";
        $scope.error.message = "There seems to be a problem with saving your data. Please try to refresh the page and try again. If error still occurs Please contact an Adviser from Empower Wealth.";

      });
      ;
    }

    $scope.closeError = function () {
      $scope.showErrorMessage = false;
    }
  });
