import commonService from "./empowerWealthHelper/commonService";
import empowerWealthService from "./empowerWealthHelper/empowerWealthService";
import expensesService from "./empowerWealthHelper/expensesService";
import assetService from "./empowerWealthHelper/assetService";
import incomeService from "./empowerWealthHelper/incomeService";
import loansService from "./empowerWealthHelper/loansService";

angular.module('meanApp')
    .factory('EmpowerWealthHelper', function () {

        return Object.assign({},
          commonService,
          expensesService,
          assetService,
          incomeService,
          loansService,
          empowerWealthService
        );
});


