"use strict";

angular.module("meanApp").config(function ($stateProvider) {
  $stateProvider.state("knowledgeCenter", {
    url: "/knowledge-center/:clientId",
    params: {
      clientId: {
        value: null,
        squash: false,
      },
    },
    templateUrl: "app/knowledgeCenter/knowledgeCenter.html",
    controller: "knowlegeCenter-controller",
    authenticate: ['user','admin']
  });
});
