

angular
  .module("meanApp")
  .controller("settlements-controller", function($scope, $stateParams, Auth) {
    $scope.userType = -1;
    Auth.isAdmin().then(isAdmin => ($scope.isAdmin = isAdmin));

    function RenderReactSF(params) {
      let wrapper = document.getElementById("react-wrapper-settlements");
      ReactDOM.unmountComponentAtNode(wrapper);
      ReactDOM.render(
        React.createElement(SettlementForm.default, params),
        wrapper
      );
      $scope.$on("$destroy", () => {
        ReactDOM.unmountComponentAtNode(wrapper);
      });
    }    

    let params = {
      id: $stateParams.clientId,
      propertyId: $stateParams.propertyId,
      loanIndex: $stateParams.loanIndex,
      baseUrl: window.location.origin,
      onSubmit: function(msg) {
        if (msg.save) {
          $scope.reloadPage();
          $scope.reloadPropertyLoans();
        }
      },
      onClose: function() {
        params.windowOpen = false;
        RenderReactSF(params);
      }
    };

    RenderReactSF(params);
  });
