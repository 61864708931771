import angular from 'angular';
import ngCookies from 'angular-cookies';
import uiRouter from '@uirouter/angularjs';
import utilModule from 'modules/util';
import constantsModule from 'modules/constants';
import AuthService from "./auth.service";
import UserService from "./user.service";
import ClientService from "./client.service";
import RouterDecoratorConfig from "./router-decorator.config";
import AuthConfig from "./auth.config";

const requires = [constantsModule, utilModule, ngCookies, uiRouter];

export default angular
  .module('meanApp.auth', requires)
  .config(AuthConfig)
  .factory('Auth', AuthService)
  .factory('User', UserService)
  .factory('Client', ClientService)
  .run(RouterDecoratorConfig)
  .name;

