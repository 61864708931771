'use strict';

angular.module('meanApp')
  .config(function ($stateProvider) {
    $stateProvider
      .state('2fa-qr-register', {
        url: '/2faAuth/register',
        templateUrl: 'app/2faAuthenticator/register.html',
        controller: '2faQRRegisterCtrl',
        authenticate: ['user','admin']
      })
      .state('2fa-qr-verify', {
        url: '/2faAuth/verify',
        params: {
          redirect: undefined
        },
        templateUrl: 'app/2faAuthenticator/verify.html',
        controller: '2faQRVerifyCtrl',
        authenticate: ['user','admin']
      });
  });