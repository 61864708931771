angular.module('meanApp')
  .config(function ($stateProvider) {
    'use strict';
    $stateProvider
      .state('bills', {
        url: '/bills/:clientId',
        params: {
          clientId: {
            value: null,
            squash: false
          }
        },
        templateUrl: 'app/expenses/bills.html',
        controller: 'factfind-6-controller',
        authenticate: ['user','admin']
      });
  })

  .config(function ($stateProvider) {
    'use strict';
    $stateProvider
      .state('spending', {
        url: '/spending/:clientId',
        params: {
          clientId: {
            value: null,
            squash: false
          }
        },
        templateUrl: 'app/expenses/spending.html',
        controller: 'factfind-7-controller',
        authenticate: ['user','admin']
      });
  })

  .config(function ($stateProvider) {
    'use strict';
    $stateProvider
      .state('basic-expenses', {
        url: '/basic-expenses/:clientId',
        params: {
          clientId: {
            value: null,
            squash: false
          }
        },
        templateUrl: 'app/expenses/basic-expenses.html',
        controller: 'basic-expenses-controller',
        authenticate: ['user','admin']
      });
  });
