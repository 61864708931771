angular.module('meanApp')
.config(function ($stateProvider) {
  'use strict';
  $stateProvider
    .state('clientsuitability', {
      url: '/suitability/:suitabilityId/:clientId',
      params: {
        suitabilityId: {
          value: null,
          squash: false
        },
        referenceId: {
            value: null,
            squash: false
          },
          clientId: {
            value: null,
            squash: false
          },
      },
      templateUrl: 'app/suitability/suitability.html',
      controller: 'suitability-controller',
      authenticate: 'admin'
    })
    .state('lockedsuitability', {
        url: '/suitability?sid=:suitabilityId',
        params: {
              suitabilityId: {
                value: null,
                squash: false
              },
          },
        templateUrl: 'app/suitability/suitability.html',
        controller: 'suitability-controller',
        authenticate: 'user'
      })
      .state('suitability', {
        url: '/suitability',
        params: {
              suitabilityId: {
                value: null,
                squash: false
              },
          },
        templateUrl: 'app/suitability/suitability.html',
        controller: 'suitability-controller',
        authenticate: 'user'
      })
});
