import authModule from 'modules/auth';
import utilModule from 'modules/util';
import packageModule from 'modules/package';
import constantsModule from 'modules/constants';
import services from 'services';
import BaseAppConfig from "./base-app.config";

const requires = [
  authModule,
  constantsModule,
  authModule,
  utilModule,
  packageModule,
  services
];

export default angular
  .module("meanApp", requires)
  .config(BaseAppConfig)
  .constant("_", window._);
