'use strict';

angular.module('meanApp')
    .factory('MBChecker', function DataMSPortal(MSPortal) {

        return {
            isPersonalInfoLacking: function (personalInfo) {
                let lacking = false;
                let adult1 = _.get(personalInfo, 'client1', null);
                let adult2 = _.get(personalInfo, 'client2', null);

                if (this.isInvalid(adult1) == false) {
                    if (this.isInvalid(adult1.fName)) {
                        return true;
                    }
                    if (this.isInvalid(adult1.lName)) {
                        return true;
                    }
                    if (this.isInvalid(adult1.dob)) {
                        return true;
                    }
                    if (this.isInvalid(adult1.email)) {
                        return true;
                    }
                    if (this.isInvalid(adult1.mPhone)) {
                        return true;
                    }
                    if (this.isInvalid(adult1.residency)) {
                        return true;
                    }
                    if (this.isInvalid(adult1.marital)) {
                        return true;
                    }
                    if (this.isInvalid(adult1.startDate)) {
                        return true;
                    }
                    if (this.isInvalid(adult1.tenure)) {
                        return true;
                    }
                }
                if (this.isInvalid(adult2) == false) {
                    if (this.isInvalid(adult2.fName)) {
                        return true;
                    }
                    if (this.isInvalid(adult2.lName)) {
                        return true;
                    }
                    if (this.isInvalid(adult2.dob)) {
                        return true;
                    }
                    if (this.isInvalid(adult2.email)) {
                        return true;
                    }
                    if (this.isInvalid(adult2.mPhone)) {
                        return true;
                    }
                }
                return lacking;
            },
            isIncomeLacking: function (income) {
                let lacking = false;
                let currentIncomes1 = _.get(income, 'client1PersonalIncome.currentEmployment', []);
                let currentIncomes2 = _.get(income, 'client2PersonalIncome.currentEmployment', []);

                let personalIncome1 = _.get(income, 'client1PersonalIncome', null);
                let personalIncome2 = _.get(income, 'client2PersonalIncome', null);

                if (this.isCurrentEmploymentLacking(currentIncomes1)) {
                    return true;
                }
                if (this.isCurrentEmploymentLacking(currentIncomes2)) {
                    return true;
                }
                if (this.isCreditHistoryLacking(personalIncome1)) {
                    return true;
                }
                if (this.isCreditHistoryLacking(personalIncome2)) {
                    return true;
                }
                return lacking;
            },
            isAddressLacking: function (address) {
                if (this.isInvalid(address)) {
                    return true;
                }
                if (this.isInvalid(address) == false) {
                    if (this.isInvalid(address.country)) {
                        return true;
                    }
                    if (this.isInvalid(address.street)) {
                        return true;
                    }
                    if (this.isInvalid(address.streetType)) {
                        return true;
                    }
                    if (this.isInvalid(address.suburb)) {
                        return true;
                    }
                    if (this.isInvalid(address.state)) {
                        return true;
                    }
                    if (this.isInvalid(address.postcode)) {
                        return true;
                    }
                }
                return false;
            },
            isCurrentEmploymentLacking: function (incomes) {
                for (var i = 0; i < incomes.length; i++) {
                    let income = incomes[i];
                    if (this.isInvalid(income.employmentType)) {
                        return true;
                    }
                    if (this.isInvalid(income.startDate)) {
                        return true;
                    }
                    if (income.employmentType != 'Unemployed' && income.employmentType != "Retired") {
                        if (this.isInvalid(income.job) == false) {
                            if (this.isInvalid(income.job.employer)) {
                                return true;
                            }
                            if (this.isInvalid(income.job.basis)) {
                                return true;
                            }
                            if (this.isInvalid(income.job.employer) == false) {
                                if (this.isInvalid(income.job.employer.employer)) {
                                    return true;
                                }
                                if (this.isInvalid(income.job.employer.contactNo)) {
                                    return true;
                                }
                                if (this.isInvalid(income.job.employer.address)) {
                                    return true;
                                }
                                if (this.isInvalid(income.job.employer.address) == false) {
                                    if (this.isAddressLacking(income.job.employer.address)) {
                                        return true;
                                    }
                                }
                            }
                            if (this.isInvalid(income.job.positionTitle)) {
                                return true;
                            }
                            if (this.isInvalid(income.job.nature)) {
                                return true;
                            }
                            if (this.isInvalid(income.job.probation)) {
                                return true;
                            }
                            if (this.isInvalid(income.job.annualSalary)) {
                                return true;
                            }
                        }
                        else if (this.isInvalid(income.business) == false) {
                            if (this.isInvalid(income.business.basis)) {
                                return true;
                            }
                        }
                    }
                }
                return false;
            },
            isCreditHistoryLacking: function (personalIncome) {
                let hasCreditHistory = _.get(personalIncome, 'hasCreditHistory', null);
                if (hasCreditHistory == true) {
                    let creditHistory = _.get(personalIncome, 'creditHistory[0]', null);
                    if (creditHistory == null) {
                        return true;
                    }
                    if (this.isInvalid(creditHistory.issue)) {
                        return true;
                    }
                    if (this.isInvalid(creditHistory.details)) {
                        return true;
                    }
                    if (this.isInvalid(creditHistory.resolutionDate)) {
                        return true;
                    }
                }
                return false;
            },
            isPropertyLacking: function (properties) {
                for (var i = 0; i < properties.length; i++) {
                    let property = properties[i];
                    if (this.isInvalid(property.purpose)) {
                        return true;
                    }
                    if (this.isInvalid(property.ownership)) {
                        return true;
                    }
                    if (this.isInvalid(property.datePurchased)) {
                        return true;
                    }
                    if (this.isInvalid(property.purchasePrice)) {
                        return true;
                    }
                    if (this.isInvalid(property.currentValue)) {
                        return true;
                    }
                    if (this.isInvalid(property.address) == false) {
                        if (this.isAddressLacking(property.address)) {
                            return true;
                        }
                    }
                    if (this.isInvalid(property.purpose) != true && (property.purpose == "Investment" || property.purpose == "Business Use")) {
                        if (this.isInvalid(property.grossRentalIncome)) {
                            return true;
                        }
                    }
                }
                return false;
            },
            isBankAccountLacking: function (bankAccounts) {
                for (var i = 0; i < bankAccounts.length; i++) {
                    let bankAccount = bankAccounts[i];
                    if (this.isInvalid(bankAccount.ownership)) {
                        return true;
                    }
                    if (this.isInvalid(bankAccount.accountType)) {
                        return true;
                    }
                    if (this.isInvalid(bankAccount.balance)) {
                        return true;
                    }
                    if (this.isInvalid(bankAccount.institution)) {
                        return true;
                    }
                    if (this.isInvalid(bankAccount.accountType) == false && bankAccount.accountType != "Offset") {
                        if (this.isInvalid(bankAccount.interestRate)) {
                            return true;
                        }
                    }
                    if (this.isInvalid(bankAccount.accountType) == false && bankAccount.accountType == "Offset") {
                        if (this.isInvalid(bankAccount.last4)) {
                            return true;
                        }
                    }
                }
                return false;
            },
            isVehicleLacking: function (vehicles) {
                for (var i = 0; i < vehicles.length; i++) {
                    let vehicle = vehicles[i];
                    if (this.isInvalid(vehicle.ownership)) {
                        return true;
                    }
                    if (this.isInvalid(vehicle.vehicleType)) {
                        return true;
                    }
                    if (this.isInvalid(vehicle.manufacturer)) {
                        return true;
                    }
                    if (this.isInvalid(vehicle.year)) {
                        return true;
                    }
                }
                return false;

            },
            isInsuranceLacking: function (insurances) {
                for (var i = 0; i < insurances.length; i++) {
                    let insurance = insurances[i];
                    if (this.isInvalid(insurance.ownership)) {
                        return true;
                    }
                    if (this.isInvalid(insurance.value)) {
                        return true;
                    }
                }
                return false;
            },
            isInvestmentLacking: function (investments) {
                for (var i = 0; i < investments.length; i++) {
                    let investment = investments[i];
                    if (this.isInvalid(investment.ownership)) {
                        return true;
                    }
                    if (this.isInvalid(investment.currentValue)) {
                        return true;
                    }
                    if (this.isInvalid(investment.startDate)) {
                        return true;
                    }
                    if(investment.hasContribution== true){
                        if (this.isInvalid(investment.contributionStartDate)) {
                            return true;
                        }
                        if (this.isInvalid(investment.contributionEndDate)) {
                            return true;
                        }
                    }
                }
                return false;
            },
            isSuperfundLacking: function (superfunds) {
                for (var i = 0; i < superfunds.length; i++) {
                    let superfund = superfunds[i];
                    if (this.isInvalid(superfund.ownership)) {
                        return true;
                    }
                    if (this.isInvalid(superfund.value)) {
                        return true;
                    }
                }
                return false;
            },
            isOtherAssetLacking: function (otherAssets) {
                for (var i = 0; i < otherAssets.length; i++) {
                    let otherAsset = otherAssets[i];
                    if (this.isInvalid(otherAsset.ownership)) {
                        return true;
                    }
                    if (this.isInvalid(otherAsset.value)) {
                        return true;
                    }
                }
                return false;
            },
            isNonPropertyAssetsLacking: function (assets) {
                let bankAccounts = _.get(assets, 'bankAccounts', []);
                let vehicles = _.get(assets, 'vehicles', []);
                let insurances = _.get(assets, 'lifeInsurance', []);
                let investments = _.get(assets, 'investments', []);
                let superFunds = _.get(assets, 'superFunds', []);
                let otherAssets = _.get(assets, 'otherAssets', []);
                if (this.isBankAccountLacking(bankAccounts)) {
                    return true;
                }
                if (this.isVehicleLacking(vehicles)) {
                    return true;
                }
                if (this.isInvestmentLacking(investments)) {
                    return true;
                }
                if (this.isInsuranceLacking(insurances)) {
                    return true;
                }
                if (this.isSuperfundLacking(superFunds)) {
                    return true;
                }
                if (this.isOtherAssetLacking(otherAssets)) {
                    return true;
                }

                return false;
            },
            isLoansLacking: function (loans) {
                for (var i = 0; i < loans.length; i++) {
                    let loan = loans[i];
                    if (this.isInvalid(loan.type)) {
                        return true;
                    }
                    if (this.isInvalid(loan.primaryPurpose)) {
                        return true;
                    }
                    if (this.isInvalid(loan.provider)) {
                        return true;
                    }
                    if (this.isInvalid(loan.borrower)) {
                        return true;
                    }
                    if (this.isInvalid(loan.type) == false && !loan.type.includes('Store Card') && !loan.type.includes('ATO') && !loan.type.includes('HECS')
                        && !loan.type.includes('Overdraft') && !loan.type.includes('Credit Card')) {
                        if (this.isInvalid(loan.startDate)) {
                            return true;
                        }
                    }
                    if (this.isInvalid(loan.type) == false &&
                        (loan.type.includes('Fixed') == false && loan.type.includes('Credit Card') == false &&
                            loan.type.includes('ATO') == false && loan.type.includes('HECS') == false && loan.type.includes('Overdraft') == false
                            && loan.type.includes('Store Card') == false && loan.type.includes('Charge Card') == false)) {
                        if (this.isInvalid(loan.interestRate)) {
                            return true;
                        }
                    }
                    if (this.isInvalid(loan.type) == false && loan.type.includes('Fixed')) {
                        if (this.isInvalid(loan.fixedTermSpecificDate)) {
                            return true;
                        }
                        if (this.isInvalid(loan.fixedRateEndDate)) {
                            return true;
                        }
                    }
                    if (this.isInvalid(loan.type) == false && loan.type.includes('Fixed') == false && (loan.type.includes('Home Loan') || loan.type.includes('Investment Loan'))) {
                        if (this.isInvalid(loan.term)) {
                            return true;
                        }
                    }
                    if (this.isInvalid(loan.type) == false && (loan.type.includes('Home Loan') || loan.type.includes('Investment Loan'))) {
                        if (this.isInvalid(loan.repaymentType)) {
                            return true;
                        }
                    }
                    if (this.isInvalid(loan.limit)) {
                        return true;
                    }
                    if (this.isInvalid(loan.outstanding)) {
                        return true;
                    }
                    if (this.isInvalid(loan.repayment)) {
                        return true;
                    }
                    if (this.isInvalid(loan.repaymentFreq)) {
                        return true;
                    }

                }
                return false;
            },
            isInvalid: function(object){
                if (_.isNil(object)) {
                    return true;
                }
                if(typeof object === "string" && object.length < 1){
                    return true;
                }
                if (typeof object === "object" && object.hasOwnProperty("ownershipType") && object.ownershipType.length < 1) {
                    return true;
                }
                return false;
            }
        }
    })