export default class AssetLoaderService {
  constructor($ocLazyLoad) {
    'ngInject';

    this.$ocLazyLoad = $ocLazyLoad;
  }

  init(path) {
    this.$ocLazyLoad.load(`${path}bundle.css`);
    return this.$ocLazyLoad.load(`${path}bundle.js`);
  }
}
