'use strict';

import { handleOnNotificationClick } from "../../modules/util/notifications";

angular.module('meanApp')
  .controller('topnav-controller', function ($scope, Auth, $state, $stateParams, $window, $timeout, DataM, Idle, $uibModal, DataHelper, MBChecker,appHostnames,$location,$rootScope, empowerDataService, EmpowerWealthHelper) {
    $scope.user = {};
    $scope.showInactiveDialog = false;
    $scope.isBrowserNotSupported = false;
    $scope.isAdmin = false;
    $scope.isBetaUser = false;
    $scope.isLoadingBetaUser = true;

    Auth.isAdmin().then(isAdmin => ($scope.isAdmin = isAdmin));
    DataM.isBetaUser().then((res) => {
      $scope.isLoadingBetaUser = false;
      $scope.isBetaUser = res.isBetaUser;
    });

    const initializer = () => {
      // Dashboard variables START

      $scope.firstLogin = false;
      $scope.clientSchema = {};
      $scope.isShortFactFindEnabled = false;
      $scope.showDashboardError = false;
      $scope.dataErrorMessage = "";

      // /*Dashboard Tiles Variables*/
      $scope.householdIncome = 0;
      $scope.taxPayable = 0;
      $scope.surplusCashflow = 0;
      $scope.totalCash = 0;
      $scope.availableEquity = 0;
      $scope.investmentNestegg = 0;

      $scope.totalIncomeVal = 0;
      $scope.totalExpenses = 0;
      $scope.totalAssets = 0;
      $scope.empowerData = empowerDataService;

    $scope.firstLogin = false;
    $scope.clientSchema = {};
    $scope.isShortFactFindEnabled = false;
    $scope.showDashboardError = false;
    $scope.dataErrorMessage = "";

    // /*Dashboard Tiles Variables*/
    $scope.householdIncome = 0;
    $scope.taxPayable = 0;
    $scope.surplusCashflow = 0;
    $scope.totalCash = 0;
    $scope.availableEquity = 0;
    $scope.investmentNestegg = 0;

    $scope.totalIncomeVal = 0;
    $scope.totalExpenses = 0;
    $scope.totalAssets = 0;
    $scope.empowerData = empowerDataService;

    // Hold client 1 and 2 ids
    $scope.client1Id = '';
    $scope.client2Id = '';

    // Gross annual rental income
    $scope.annualRentalIncome = {
      client1GrossRentalIncome:0,
      client2GrossRentalIncome:0
    }
      /*Property Portfolio*/
    //Need an array of properties.
    $scope.loansPropertyList = [];
    $scope.nonLoanPropertyList = [];
    //Need an array of Loans.
    $scope.totalPropertiesValue = 0;

    /*Property Loans*/
    $scope.loansTotalLimit = 0;
    $scope.loansTotalBalance = 0;
    $scope.loansTotalRepayment = 0;
    $scope.loansBalance = 0;
    $scope.currentLvr = 0;
    $scope.avlEquityAtEighty = 0;
    $scope.repayments = 0;

    /*Summary of Income*/
    /*[0] - Client 1, [1] - Client 2*/
    $scope.grossPaygIncome = [0,0];
    $scope.superannuationSalarySacrifice = [0,0];
    $scope.grossBusinessIncome = [0,0];
    $scope.grossSoleTraderIncome = [0,0];
    $scope.grossOtherIncome = [0,0];
    $scope.grossRentalIncome = [0,0];
    $scope.grossInvestmentIncome = [0,0];
    $scope.preTaxDeductions = [0,0];
    $scope.totalIncome = [0,0];

    $scope.investmentPropertyDeductions = [0,0];
    $scope.otherTaxDeductions = [0,0];
    $scope.taxableIncome = [0,0];
    $scope.estimatedTaxPaid = [0,0];
    $scope.nonTaxableIncome = [0,0];
    $scope.totalNetIncome = [0,0];

    /*Summary of Expenditure*/
    /*[0] - Monthly, [1] - Yearly*/
    $scope.totalBillPayments = [0,0];
    $scope.totalSpending = [0,0];
    $scope.totalInvestmentPropertyCosts = [0,0];
    $scope.totalOtherPropertyCosts = [0,0];
    $scope.totalInvestmentAssetCosts = [0,0];
    $scope.totalLoanPayments = [0,0];
    $scope.totalContributionsOtherInvestments = [0,0];
    $scope.afterTaxSuperContributions = [0,0];

    $scope.totalExpenditure = [0,0];


    /*[0] - Monthly, [1] - Yearly*/
    $scope.incomeOverall = [0,0];
    $scope.expenditureOverall = [0,0];
    $scope.surplus = [$scope.incomeOverall[0] - $scope.expenditureOverall[0], $scope.incomeOverall[1] - $scope.expenditureOverall[1]];

    /*Summary of Assets and Liabilities*/
    $scope.totalInvestmentAssets = 0;
    $scope.totalNonInvestmentAssets = 0;
    $scope.totalLiabilities = 0;
    $scope.totalPropertyLiabilities = 0;
    $scope.totalOutstandingCreditCardDebit = 0;

      /*Summary of Assets and Liabilities*/
      $scope.totalInvestmentAssets = 0;
      $scope.totalNonInvestmentAssets = 0;
      $scope.totalLiabilities = 0;
      $scope.totalPropertyLiabilities = 0;
      $scope.totalOutstandingCreditCardDebit = 0;

      // Dashboard variables END
    }
    const $clientId = $stateParams.clientId;
    $scope.state = $state.current;
    $scope.params = $stateParams;
    initializer();
    

    const reactContainerNode = document.getElementById(
      "pwpQuicknotes"
    )
    if(!_.isNil(reactContainerNode)) {
      ReactDOM.render(React.createElement(PWPWorkbench.QuickNotes, { uid: $stateParams.clientId }), reactContainerNode );
    }

    $scope.logout = function () {
      Auth.logout();
      $state.go('login');
    };

    $scope.isMBCheckerWarning = false;
    $scope.clientSchema = {};

    let hostname = $location.$$host;
    $scope.isLoadingUserType = true;

    Auth.getIsTPCUser().then(function (isTPCUser){
      $scope.isTPCUser = isTPCUser;
      $rootScope.isTPCUser = isTPCUser;
      $scope.isLoadingUserType = false;
      $scope.initDashboard();
    });

    var getBrowserUsed = function () {
      var userAgent = $window.navigator.userAgent;
      var browsers = { chrome: /chrome/i, safari: /safari/i, firefox: /firefox/i, ie: /internet explorer/i };

      for (var key in browsers) {
        if (browsers[key].test(userAgent)) {

          return key;
        }
      };
    }


    $scope.closeMBWarning = function () {
      $scope.isMBCheckerWarning = false;
    }
    $scope.closeGoogleWarning = function () {
      $scope.isBrowserNotSupported = false;
    }
    DataM.getMe().then(function (data) {

      $scope.user = data;
      // check if admin
      if(data.role === "user" || data.role === "guest"){
        // go to dashboard if future plans beta is enabled
        if(data.isFuturePlansEnabled === true){
          if($state.current.name === "future-household" || $state.current.name === "future-income-expenses"){
            $state.go('dashboard');
          }
        }
      }

    }).catch(function (err) { console.log('Retrieve error'); });

    if (getBrowserUsed() !== "chrome") {
      $timeout(function () { $scope.isBrowserNotSupported = true; }, 3000);

    }
    $scope.mbPagesLack = {
      personalInfo: false,
      income: false,
      properties: false,
      otherAssets: false,
      loans: false,
    };
    var formData = {};
    if ($clientId && $clientId.length > 0) {
      formData.uid = $clientId;
    }
    $scope.$on('onSaved', function(event, args) {

      // do what you want to do
      console.log("did broadcast");
      if ($state.current.name != 'dashboard-search') {
        getMBWarning();
      }
    });

    if ($state.current.name != 'dashboard-search') {
      getMBWarning();
    }

    $scope.$on("resetNotif", function (evt, args) {
      getMBWarning();
    });

    function getMBWarning() {
      DataM.getMBWarningEnabled(formData).then(function (data) {
        getClientSummary(data.mbWarningEnabled);

      }).catch(function (err) {
        console.log(err);
      });
    }

    $scope.calcInitialEmploymentHistoryRequirement = function (employmentData, client) {
      var totalDays = 0;
      var oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds

      switch (client) {
        case 1:
          var clientEmployment = employmentData
          break;
        case 2:
          var clientEmployment = employmentData
          break;
      }
      for (var i = 0; i < clientEmployment.length; i++) {
        totalDays = 0;
        var employment = clientEmployment[i];
        var startDate = moment(employment.startDate).format('DD/MM/YYYY');
        var firstDate = moment.utc(startDate, ["DD/MM/YYYY"]);
        var todayDate = moment.utc();

        var diffDays = Math.round(Math.abs((firstDate.valueOf() - todayDate.valueOf()) / (oneDay)));

        totalDays += diffDays;

        /**
         * Check if one of the employment is less than 3 years
         * If all are not less than 3 years show the previous employment
         */
        if ((totalDays / 365) < 3) {
          switch (client) {
            case 1:
              return false
            case 2:
              return false
          }
        } else if ((totalDays / 365) >= 3) {
          switch (client) {
            case 1:
              return true
            case 2:
              return true
          }
        } else if (!isNaN(totalDays)) {
          switch (client) {
            case 1:
              return true
            case 2:
              return true
          }
        }
      }

    };

    $scope.calcPreviousEmploymentHistory = function (employmentData, previousEmploymentData, client) {
      var totalDays = 0;
      var oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds



      switch (client) {
        case 1:
          var clientEmployment = employmentData;
          var clientPreviousEmployment = previousEmploymentData;
          break;
        case 2:
          var clientEmployment = employmentData;
          var clientPreviousEmployment = previousEmploymentData;
          break;
      }
      for (var i = 0; i < clientEmployment.length; i++) {
        var employment = clientEmployment[i];
        var startDate = moment(employment.startDate).format('DD/MM/YYYY');
        var firstDate = moment.utc(startDate, ["DD/MM/YYYY"])
        var todayDate = moment.utc();
        var diffDays = Math.round(Math.abs((firstDate.valueOf() - todayDate.valueOf()) / (oneDay)));
        totalDays += diffDays;
      }
      for (var i = 0; i < clientPreviousEmployment.length; i++) {
        var employment = clientPreviousEmployment[i];
        var startDate = moment(employment.startDate).format('DD/MM/YYYY');
        var firstDate = moment.utc(startDate, ["DD/MM/YYYY"]);
        var endDate = moment(employment.endDate).format('DD/MM/YYYY');
        var todayDate = moment.utc(endDate, ["DD/MM/YYYY"]);
        var diffDays = Math.round(Math.abs((firstDate.valueOf() - todayDate.valueOf()) / (oneDay)));
        if(todayDate.valueOf() < moment.utc()) {
          totalDays += diffDays;
        }
      }


      if ((totalDays / 365) < 3) {
        switch (client) {
          case 1:
            return false
          case 2:
            return false
        }
      } else if (!isNaN(totalDays)) {
        switch (client) {
          case 1:
            return true
          case 2:
            return true
        }
      }
    };

    $rootScope.setAlertValues = function(showSavingDiscardAlert, isSavingDiscardSuccess, alertMessage, isDiscard) {
      $scope.showSavingDiscardAlert = showSavingDiscardAlert
      $scope.isSavingDiscardSuccess = isSavingDiscardSuccess
      $scope.alertSavingDiscardMessage = alertMessage
      getMBWarning()
    }

    function RenderReactModal(params) {
        let reactWrapper = document.getElementById("react-wrapper-field-validation");
        ReactDOM.unmountComponentAtNode(reactWrapper);
        ReactDOM.render(
          React.createElement(ModalUnansweredFields.default, params),
          reactWrapper
        );

        $scope.$on('$destroy', function (){
          ReactDOM.unmountComponentAtNode(reactWrapper);
        });
      }

      $scope.openReactModal = function(clientId, pagesLacking, clientSummary, mbWarningEnabled) {
        // Insert isWorkExperienceThreeYears on clientSummary for AJV
        if(typeof clientSummary !== "undefined" && clientSummary !== null) {
            const currentEmployment = !_.isNil(clientSummary.income.client1PersonalIncome.currentEmployment) ? clientSummary.income.client1PersonalIncome.currentEmployment : []
            const previousCurrentEmployment = !_.isNil(clientSummary.income.client1PersonalIncome.pastEmployment) ? clientSummary.income.client1PersonalIncome.pastEmployment : []
            if($scope.calcInitialEmploymentHistoryRequirement(currentEmployment, 1)) {
              clientSummary.income.client1PersonalIncome.isWorkExperienceThreeYears = $scope.calcInitialEmploymentHistoryRequirement(currentEmployment, 1)
            } else {
              clientSummary.income.client1PersonalIncome.isWorkExperienceThreeYears = $scope.calcPreviousEmploymentHistory(currentEmployment, previousCurrentEmployment, 1)
            }
          if(!_.isNil(clientSummary.income.client2PersonalIncome)) {
            const currentEmployment = !_.isNil(clientSummary.income.client2PersonalIncome.currentEmployment) ? clientSummary.income.client2PersonalIncome.currentEmployment : []
            const previousCurrentEmployment = !_.isNil(clientSummary.income.client2PersonalIncome.pastEmployment) ? clientSummary.income.client2PersonalIncome.pastEmployment : []
            if($scope.calcInitialEmploymentHistoryRequirement(currentEmployment, 2)) {
              clientSummary.income.client2PersonalIncome.isWorkExperienceThreeYears = $scope.calcInitialEmploymentHistoryRequirement(currentEmployment, 2)
            } else {
              clientSummary.income.client2PersonalIncome.isWorkExperienceThreeYears = $scope.calcPreviousEmploymentHistory(currentEmployment, previousCurrentEmployment, 2)
            }
          }
        }
        // Insert isPrimary to superFunds for client1 or client2 condition
        const client1Id = clientSummary.personalInfo.client1._id
        const client2Id = !_.isNil(clientSummary.personalInfo.client2) ? clientSummary.personalInfo.client2._id : ''
        let newSuperfunds = [];
        (clientSummary.assets.superFunds || []).map((sf) => {
          if(!_.isNil(sf.ownership) && sf.ownership.owners.length === 1) {
            if(`${sf.ownership.owners[0].owner}` === `${client1Id}`) {
              sf.isPrimary = true
            } else if(`${sf.ownership.owners[0].owner}` === `${client2Id}`) {
              sf.isPrimary = false
            }
            newSuperfunds.push(sf)
          }
        })
        clientSummary.assets.superFunds = newSuperfunds;

        const modalUnansweredFieldsProps = {
          userId: $scope.user._id,
          clientId,
          pagesLacking,
          MBChecker: mbWarningEnabled,
          clientSummary,
          isNotificationDialogOpen: $scope.user.isNotificationDialogOpen,
          baseUrl: window.location.origin,
          apiBaseUrl: process.env.API_ENDPOINT,
          isAdmin: $scope.isAdmin,
          isTPCUser: $scope.isTPCUser,
          showSavingDiscardAlert: $scope.showSavingDiscardAlert,
          isSavingDiscardSuccess: $scope.isSavingDiscardSuccess,
          alertSavingDiscardMessage: $scope.alertSavingDiscardMessage,
          apiUrl: process.env.API_ENDPOINT,
          navigatePage: function(loc) {
            $state.go(loc, {
              clientId: clientId
            });
          },
          setReqData: function(reqData) {
            reqData["MBChecker"] = mbWarningEnabled;
            $rootScope.$broadcast("reqData", reqData);
            $rootScope.$broadcast("clientSummaryReqData", clientSummary);
          },
          onNotificationClick: handleOnNotificationClick,
        };

        RenderReactModal(modalUnansweredFieldsProps);
      };

      function getClientSummary(mbWarningEnabled) {
        if ($state.current.name == "dashboard-search") return;
        let onClientSummary = function (data){
          $scope.clientSchema = data.clientSummary;
          if (!$clientId){
            $rootScope.loggedUserId = $scope.clientSchema.uid;
          }
          let personalInfo = _.get($scope.clientSchema, "personalInfo", {});
          let income = _.get($scope.clientSchema, "income", {});
          let properties = _.get(
            $scope.clientSchema,
            "assets.properties",
            []
          );
          let assets = _.get($scope.clientSchema, "assets", {});
          let futurePlans = _.get($scope.clientSchema, "futurePlans", {});
          let loans = _.get($scope.clientSchema, "borrowings.borrowing", []);
          $scope.mbPagesLack.personalInfo = MBChecker.isPersonalInfoLacking(
            personalInfo
          );
          $scope.mbPagesLack.income = MBChecker.isIncomeLacking(income);
          $scope.mbPagesLack.properties = MBChecker.isPropertyLacking(
            properties
          );
          $scope.mbPagesLack.otherAssets = MBChecker.isNonPropertyAssetsLacking(
            assets
          );
          $scope.mbPagesLack.loans = MBChecker.isLoansLacking(loans);
          var keys = _.keys($scope.mbPagesLack);
          const modalData = {
            assets,
            futurePlans,
            income,
            loans,
            personalInfo,
            properties,
          };
          $scope.openReactModal($clientId, $scope.mbPagesLack, modalData, mbWarningEnabled);
        };

        if ($clientId){
          DataM.getClientSummary({ id: $clientId }).then(onClientSummary).catch(function(err) {});;
        } else {
          DataM.getSummary().then(onClientSummary).catch(function(err) {});;
        }
      }

    $scope.openMBDialog = function () {
      var modalInstance = $uibModal.open({
        animation: $scope.animationsEnabled,
        templateUrl: 'app/topnav/mb-warning.html',
        controller: 'MBCheckerModalController',
        resolve: {
          result: function () {
            return { pages: $scope.mbPagesLack, clientId: $clientId };
          }
        }
      });
      modalInstance.result.then(function (result) {
        if (result != 'cancel') {
          // activate
          $state.go(result, {
            clientId: $clientId
          });
        }
      }, function () {
        // if cancelled
      });
    }

    $scope.openPWPWorkbench = function(){
      let url = window.location.origin+'/pwpworkbench/'+$clientId;
      window.open(url, 'newWin',"width="+1200+",height="+900);
    }

    // Dasboard calculation START

    $scope.listLoanProperty = function(loan) {
      var loanProperties = [];

      if(loan.properties){
        for (var index = 0; index < loan.properties.length; index++) {
          var propertyId = loan.properties[index];

          $scope.properties.forEach(function(property) {

            if(property._id === propertyId) {
              loanProperties.push(property);
            }
          }, this);

        }

        if(loanProperties.length > 0) {
          var contains = false;

          $scope.loansPropertyList.forEach(function(propertyList) {

            if(_.isEqual(_.map(_.sortBy(propertyList, '_id'), _.values), _.map(_.sortBy(loanProperties, '_id'), _.values))) {
              contains = true;
            }
          }, this);

          if(contains === false) {
            $scope.loansPropertyList.push(loanProperties);
            $rootScope.loansPropertyList = $scope.loansPropertyList;
          }
        }
      }

    }
     $scope.getFilteredPropertyLoansLength = function (propertyID) {
      var filteredPropertyLoansLength = 0;
      for (var index = 0; index < $scope.loans.length; index++) {
        var loan = $scope.loans[index];

        if (loan.securedAgainst) {
          //if(_.isEqual(loan.securedAgainst.sort(), propertyListIds.sort())) {
          if (loan.securedAgainst.includes(propertyID)) {
            filteredPropertyLoansLength ++;
          }
        }
      }

      return filteredPropertyLoansLength;

     }





    $scope.calculateAllInvestmentAssets = function()
    {
      /*Calculate Total Super Annuation*/
      for (var $i = 0; $i <$scope.allSuper.length; $i++) {
        $scope.totalInvestmentAssets += $scope.allSuper[$i].value;

        if($scope.allSuper[$i].personalContributionsBool == true){
          $scope.afterTaxSuperContributions[1] += $scope.allSuper[$i].personalContributionsClient1 + $scope.allSuper[$i].personalContributionsClient2;
        }
        /*Super Salary Sacrifice & Post Tax Contributions*/
        if($scope.allSuper[$i].ownership && $scope.allSuper[$i].ownership.owners.length == 1 && $scope.allSuper[$i].salarySacrificeClient1 != null)
        {
          if($scope.allSuper[$i].ownership.ownershipType == 'Sole') {
            switch ($scope.allSuper[$i].ownership.owners[0].owner) {
              case $scope.client1Id:
                $scope.superannuationSalarySacrifice[0] += $scope.allSuper[$i].salarySacrificeClient1;
                break;

              case $scope.client2Id:
                $scope.superannuationSalarySacrifice[1] += $scope.allSuper[$i].salarySacrificeClient1;
                break;
            }
          }
        }
        else
        {
            if($scope.allSuper[$i].salarySacrificeClient1 != null)
            {
              $scope.superannuationSalarySacrifice[0] += $scope.allSuper[$i].salarySacrificeClient1;
            }

            if($scope.allSuper[$i].salarySacrificeClient2  != null)
            {
              $scope.superannuationSalarySacrifice[1] += $scope.allSuper[$i].salarySacrificeClient2;
            }

        }


      }

      $rootScope.totalInvestmentAssets = $scope.totalInvestmentAssets;
      $rootScope.superannuationSalarySacrifice = $scope.superannuationSalarySacrifice;
      $rootScope.afterTaxSuperContributions = $scope.afterTaxSuperContributions;

      $scope.superannuationSalarySacrifice[0] *= -1;
      $scope.superannuationSalarySacrifice[1] *= -1;

      $scope.afterTaxSuperContributions[0] = $scope.afterTaxSuperContributions[1]/12;

      /*Calculate Total Bank Accounts*/
      for($i=0;$i<$scope.bankAccounts.length;$i++) {
        $scope.totalInvestmentAssets += $scope.bankAccounts[$i].balance;
      }

      /*Calculate Total Investments Value*/
      for($i=0;$i<$scope.investments.length;$i++) {
        $scope.totalInvestmentAssets += $scope.investments[$i].currentValue;
      }

      const otherInvestmentContrib = $scope.investments.map((res) => {
        const initContribAmount = res.hasContribution && res.contributionAmount ? res.contributionAmount : 0;
        return getMonthlyValue(initContribAmount, res.contributionFrequency);
      }).filter((res) => !_.isNil(res)).reduce((total, amount) => total + amount, 0)

      $scope.totalContributionsOtherInvestments[0] = otherInvestmentContrib;
      $scope.totalContributionsOtherInvestments[1] = otherInvestmentContrib*12;

      /*Calculate Total Investment Property Value*/
      for (var $i = 0; $i <$scope.properties.length; $i++)
      {
        switch($scope.properties[$i].purpose)
        {
          case "Business Use":
            $scope.totalInvestmentAssets += $scope.properties[$i].currentValue;
            break;

          case "Investment Property":
            $scope.totalInvestmentAssets += $scope.properties[$i].currentValue;
            break;

          case "Investment":
            $scope.totalInvestmentAssets += $scope.properties[$i].currentValue;
            break;
        }
      }

    }


     $scope.calculatePropertyInvestmentCost = function()
    {

      for (var i = 0; i <$scope.properties.length; i++)
      {
        var property = $scope.properties[i];
        if(property.purpose === 'Investment'){
          // calculate property cost
          if(property.bodyCorpCosts) {
            $scope.totalInvestmentPropertyCosts[1] += property.bodyCorpCosts;
          }
          if(property.insuranceCosts) {
            $scope.totalInvestmentPropertyCosts[1] += property.insuranceCosts;
          }
          if(property.landTaxCosts) {
            $scope.totalInvestmentPropertyCosts[1] += property.landTaxCosts;
          }
          if(property.maintenanceCost) {
            $scope.totalInvestmentPropertyCosts[1] += property.maintenanceCost;
          }
          if(property.managementFees) {
            $scope.totalInvestmentPropertyCosts[1] += property.managementFees;
          }
          if(property.waterRates) {
            $scope.totalInvestmentPropertyCosts[1] += property.waterRates;
          }
          if(property.councilRates) {
            $scope.totalInvestmentPropertyCosts[1] += property.councilRates
          } 
          // added custom
          if(!_.isNil(property.customHoldingCosts) && property.customHoldingCosts.length > 0) {
            for (var j = 0;j <property.customHoldingCosts.length; j++) {
              var custom = property.customHoldingCosts[j];
              if(!_.isNil(custom.value)) {
                $scope.totalInvestmentPropertyCosts[1] += custom.value
              }
            }
          }

        } else if (property.purpose === 'Personal Use') {
          if(property.bodyCorpCosts) {
            $scope.totalOtherPropertyCosts[1] += property.bodyCorpCosts;
          }
          if(property.insuranceCosts) {
            $scope.totalOtherPropertyCosts[1] += property.insuranceCosts;
          }
          if(property.landTaxCosts) {
            $scope.totalOtherPropertyCosts[1] += property.landTaxCosts;
          }
          if(property.maintenanceCost) {
            $scope.totalOtherPropertyCosts[1] += property.maintenanceCost;
          } 
          if(property.managementFees) {
            $scope.totalOtherPropertyCosts[1] += property.managementFees;
          } 
          if(property.waterRates) {
            $scope.totalOtherPropertyCosts[1] += property.waterRates;
          }
          if(property.councilRates) {
            $scope.totalOtherPropertyCosts[1] += property.councilRates
          } 
          // added custom
          if(!_.isNil(property.customHoldingCosts) && property.customHoldingCosts.length > 0) {
            for (var j = 0;j <property.customHoldingCosts.length; j++) {
              var custom = property.customHoldingCosts[j];
              if(!_.isNil(custom.value)) {
                $scope.totalOtherPropertyCosts[1] += custom.value
              }
            }
          }
        }
      }
      $rootScope.totalInvestmentPropertyCosts = $scope.totalInvestmentPropertyCosts;
      $scope.totalInvestmentPropertyCosts[0] = Math.round($scope.totalInvestmentPropertyCosts[1]/12);
      $rootScope.totalOtherPropertyCosts = $scope.totalOtherPropertyCosts;
      $scope.totalOtherPropertyCosts[0] = Math.round($scope.totalOtherPropertyCosts[1]/12);
    }

    $scope.calculateInvestmentAssetCost = function() {
      // calculate investment costs
      $scope.allInvestments?.forEach(investment => {
        if (
          investment &&
          (investment.defaultHoldingCosts ||
            investment.customHoldingCosts)
        ) {
          var costs = 0;
          investment.defaultHoldingCosts.forEach(
            (defaultHoldingCost) => {
              costs += defaultHoldingCost?.value;
            }
          );

          investment.customHoldingCosts.forEach(
            (customHoldingCost) => {
              costs += customHoldingCost?.value;
            }
          );

          $scope.totalInvestmentAssetCosts[0] += Math.round(costs / 12);
          $scope.totalInvestmentAssetCosts[1] += costs;
        }
      })

      $rootScope.totalInvestmentAssetCosts = $scope.totalInvestmentAssetCosts;
    }

    $scope.calculateAllNonInvestmentAssets = function()
    {

      for (var i = 0; i <$scope.properties.length; i++)
      {
        var property = $scope.properties[i];
        switch(property.purpose)
        {
          case "Personal Use":
            $scope.totalNonInvestmentAssets += $scope.properties[i].currentValue;
            break;

          case "Own Home":
            $scope.totalNonInvestmentAssets += $scope.properties[i].currentValue;
            break;
        }
      }

      $rootScope.totalNonInvestmentAssets = $scope.totalNonInvestmentAssets[0];
    }

    $scope.calculateAllExpenses = function()
    {
      /*All Expenses*/
      var bills = 0;
      var expenses = 0;

      if($scope.clientSchema.expenses && $scope.clientSchema.expenses.expenses){
        $scope.allExpenses = $scope.clientSchema.expenses.expenses;
      } else {
        $scope.allExpenses = [];
      }
      /*Iterate through all expenses sorting into tier 4 category*/
      for (var i = 0; i <$scope.allExpenses.length; i++)
      {

        //Bills
        if($scope.allExpenses[i].tier1 || ($scope.allExpenses[i].customExpense == true && $scope.allExpenses[i].tier4 == 'Bills') || ($scope.allExpenses[i].taxDeductible == true && $scope.allExpenses[i].tier4 == 'Bills') || ($scope.allExpenses[i].ownership && $scope.allExpenses[i].ownership.ownershipType   && $scope.allExpenses[i].tier4 == 'Bills'))
        {

           {
              switch($scope.allExpenses[i].frequency)
              {
                case "Weekly":
                  bills += $scope.allExpenses[i].amount*52;
                  break;
                case "Fortnightly":
                  bills += $scope.allExpenses[i].amount*26;
                  break;
                case "Monthly":
                  bills += $scope.allExpenses[i].amount*12;
                  break;
                case "Every 2 months":
                  bills += $scope.allExpenses[i].amount*6;
                  break;
                case "Every 3 months":
                  bills += $scope.allExpenses[i].amount*4;
                  break;
                case "Every 6 months":
                  bills += $scope.allExpenses[i].amount*2;
                  break;
                case "Yearly":
                  bills += $scope.allExpenses[i].amount;
                  break;
              }
           }


            /*Calculate All Tax Deductable Expenses*/
            if($scope.allExpenses[i].taxDeductible == true)
            {
              var ownerIndex=0;
              if(!_.isNil($scope.allExpenses[i].ownership)) {
                if($scope.allExpenses[i].ownership.ownershipType == 'Sole' || ($scope.allExpenses[i].ownership.ownershipType == 'Joint' && $scope.client2FullName == "Your Partner" )){
                  //Determine Owner of Expense
                  switch($scope.allExpenses[i].ownership.owners[0].owner)
                  {
                    case $scope.client1Id:
                      ownerIndex=0;
                      break;
                    case $scope.client2Id:
                      ownerIndex=1;
                      break;
                    default:
                    ownerIndex =0;
                    break;
                  }
                  if($scope.client2FullName == "Your Partner") {
                    ownerIndex = 0;
                  }
                  switch($scope.allExpenses[i].frequency)
                  {
                    case "Weekly":
                      $scope.otherTaxDeductions[ownerIndex] += $scope.allExpenses[i].amount*4;
                      break;
                    case "Fortnightly":
                      $scope.otherTaxDeductions[ownerIndex]  += ($scope.allExpenses[i].amount*2);
                      break;
                    case "Monthly":
                      $scope.otherTaxDeductions[ownerIndex]  += $scope.allExpenses[i].amount;
                      break;
                    case "Every 2 months":
                      $scope.otherTaxDeductions[ownerIndex]  += ($scope.allExpenses[i].amount/2);
                      break;
                    case "Every 3 months":
                      $scope.otherTaxDeductions[ownerIndex]  += ($scope.allExpenses[i].amount/3);
                      break;
                    case "Every 6 months":
                      $scope.otherTaxDeductions[ownerIndex]  += ($scope.allExpenses[i].amount/6);
                      break;
                    case "Yearly":
                      $scope.otherTaxDeductions[ownerIndex]  += ($scope.allExpenses[i].amount/12);
                      break;
                  }

                }
                else if($scope.allExpenses[i].ownership.ownershipType == 'Joint') {
                  switch($scope.allExpenses[i].frequency)
                  {
                    case "Weekly":
                      $scope.otherTaxDeductions[0] += $scope.allExpenses[i].amount*4*0.5;
                      $scope.otherTaxDeductions[1] += $scope.allExpenses[i].amount*4*0.5;
                      break;
                    case "Fortnightly":
                      $scope.otherTaxDeductions[0]  += ($scope.allExpenses[i].amount*2)*0.5;
                      $scope.otherTaxDeductions[1]  += ($scope.allExpenses[i].amount*2)*0.5;
                      break;
                    case "Monthly":
                      $scope.otherTaxDeductions[0]  += $scope.allExpenses[i].amount*0.5;
                      $scope.otherTaxDeductions[1]  += $scope.allExpenses[i].amount*0.5;
                      break;
                    case "Every 2 months":
                      $scope.otherTaxDeductions[0]  += ($scope.allExpenses[i].amount/2)*0.5;
                      $scope.otherTaxDeductions[1]  += ($scope.allExpenses[i].amount/2)*0.5;
                      break;
                    case "Every 3 months":
                      $scope.otherTaxDeductions[0]  += ($scope.allExpenses[i].amount/3)*0.5;
                      $scope.otherTaxDeductions[1]  += ($scope.allExpenses[i].amount/3)*0.5;
                      break;
                    case "Every 6 months":
                      $scope.otherTaxDeductions[0]  += ($scope.allExpenses[i].amount/6)*0.5;
                      $scope.otherTaxDeductions[1]  += ($scope.allExpenses[i].amount/6)*0.5;
                      break;
                    case "Yearly":
                      $scope.otherTaxDeductions[0]  += ($scope.allExpenses[i].amount/12)*0.5;
                      $scope.otherTaxDeductions[1]  += ($scope.allExpenses[i].amount/12)*0.5;
                      break;
                  }
                }
              }
            }
        }

        //Expenses
        else
        {

          switch($scope.allExpenses[i].frequency)
          {
            case "Weekly":
              expenses += $scope.allExpenses[i].amount*52;
              break;
            case "Fortnightly":
              expenses += $scope.allExpenses[i].amount*26;
              break;
            case "Monthly":
              expenses += $scope.allExpenses[i].amount*12;
              break;
            case "Every 2 months":
              expenses += $scope.allExpenses[i].amount*6;
              break;
            case "Every 3 months":
              expenses += $scope.allExpenses[i].amount*4;
              break;
            case "Every 6 months":
              expenses += $scope.allExpenses[i].amount*2;
              break;
            case "Yearly":
              expenses += $scope.allExpenses[i].amount;
              break;
          }

        }

      }

      $rootScope.otherTaxDeductions = $scope.otherTaxDeductions;

      $scope.totalBillPayments[0] = Math.round(bills /12);
      $scope.totalBillPayments[1] = bills;

      $rootScope.totalBillPayments = $scope.totalBillPayments;

      $scope.totalSpending[0] =  Math.round(expenses /12);
      $scope.totalSpending[1] = expenses;

      $rootScope.totalSpending = $scope.totalSpending;

      $scope.otherTaxDeductions[0] *= 12;
      $scope.otherTaxDeductions[1] *= 12;


    };

    $scope.getPreTaxDeductions = function (allIncome) {

      for (var i = 0; i < allIncome.length; i++) {

        if(allIncome[i].employmentType == "PAYG"){
          let job = allIncome[i].job;

          if (job.withPreTax == true) {
            for (var j = 0; j < job.preTaxes.length; j++) {
              if (job.preTaxes[j]) {
                if(allIncome[i].owner==$scope.client1FullName){
                  $scope.preTaxDeductions[0] += DataHelper.getYearly(job.preTaxes[j].taxValue, job.preTaxes[j].taxFreq);
                } else if(allIncome[i].owner==$scope.client2FullName){
                  $scope.preTaxDeductions[1] += DataHelper.getYearly(job.preTaxes[j].taxValue, job.preTaxes[j].taxFreq);
                }
              }
            }
          }
        }
      }

      $rootScope.preTaxDeductionsClient1 = $scope.preTaxDeductions[0];
      $rootScope.preTaxDeductionsClient2 = $scope.preTaxDeductions[1];

      $rootScope.preTaxDeductions = $scope.preTaxDeductions;
    }

    $scope.calculateIncome = function()
    {
      var allIncome = [0,0];
      $scope.otherIncome = [];

      if($scope.clientSchema.income) {

        // Client 1
        if(!_.isNil($scope.clientSchema.personalInfo.client1) && $scope.clientSchema.income.client1PersonalIncome){
          allIncome = $scope.clientSchema.income.client1PersonalIncome.currentEmployment;
          $scope.otherIncome = $scope.clientSchema.income.client1PersonalIncome.other;

          for(var i=0; i < allIncome.length; i++) {
            var income = allIncome[i];
            income.owner = $scope.client1FullName;
          }

          for(var i=0; i < $scope.otherIncome.length; i++) {
            var income = $scope.otherIncome[i];
            income.owner = $scope.client1FullName;
          }

        }
        // Client 2
        if(!_.isNil($scope.clientSchema.personalInfo.client2) && $scope.clientSchema.income.client2PersonalIncome){

          for(var i=0;i<$scope.clientSchema.income.client2PersonalIncome.currentEmployment.length; i++) {
            var income = $scope.clientSchema.income.client2PersonalIncome.currentEmployment[i];
            income.owner = $scope.client2FullName;
          }

          for(var i=0;i<$scope.clientSchema.income.client2PersonalIncome.other.length; i++) {
            var income = $scope.clientSchema.income.client2PersonalIncome.other[i];
            income.owner = $scope.client2FullName;
          }

          allIncome = allIncome.concat($scope.clientSchema.income.client2PersonalIncome.currentEmployment);

          $scope.otherIncome = $scope.otherIncome.concat($scope.clientSchema.income.client2PersonalIncome.other);
        }
      }

      $scope.allBusinessIncome = [];
      $scope.allSoleTraderIncome = [];

      if($scope.clientSchema.assets){
        $scope.allInvestments = $scope.clientSchema.assets.investments;
      }else {
        $scope.allInvestments = [];
      }

      /*Total Value of PAYG Income*/
      for (var $i = 0; $i <allIncome.length; $i++)
      {

        switch(allIncome[$i].employmentType)
        {
          case "PAYG":
            switch(allIncome[$i].owner)
            {
              case $scope.client1FullName:
                $scope.grossPaygIncome[0] += allIncome[$i].job.annualSalary || 0;
                $scope.grossOtherIncome[0] += allIncome[$i].job.overtime || 0;
                $scope.grossOtherIncome[0] += allIncome[$i].job.commission || 0;
                $scope.grossOtherIncome[0] += allIncome[$i].job.bonus || 0;
                break;

              case $scope.client2FullName:
                $scope.grossPaygIncome[1] += allIncome[$i].job.annualSalary || 0;
                $scope.grossOtherIncome[1] += allIncome[$i].job.overtime || 0;
                $scope.grossOtherIncome[1] += allIncome[$i].job.commission || 0;
                $scope.grossOtherIncome[1] += allIncome[$i].job.bonus || 0;
                break;
            }
            default :
             if(allIncome[$i].business){
              if(allIncome[$i].business.basis != "Sole trader"){
                $scope.allBusinessIncome.push(allIncome[$i]);
              } else {
                $scope.allSoleTraderIncome.push(allIncome[$i]);
              }
             }
            break;
        }
      }

      $rootScope.grossPaygIncome = $scope.grossPaygIncome;

      /*Total Value of Business Income */
      for (var $i = 0; $i <$scope.allBusinessIncome.length; $i++)
      {

        switch($scope.allBusinessIncome[$i].owner)
        {
          case $scope.client1FullName:
            if($scope.allBusinessIncome[$i].business.earnings.length > 0){
              $scope.grossBusinessIncome[0] += $scope.allBusinessIncome[$i].business.earnings[0].salary;
              $scope.grossBusinessIncome[0] += $scope.allBusinessIncome[$i].business.earnings[0].profit;
            }
            break;

          case $scope.client2FullName:
            if($scope.allBusinessIncome[$i].business.earnings.length > 0){
              $scope.grossBusinessIncome[1] += $scope.allBusinessIncome[$i].business.earnings[0].salary;
              $scope.grossBusinessIncome[1] += $scope.allBusinessIncome[$i].business.earnings[0].profit;

            }
            break;
        }
      }

      $rootScope.grossBusinessIncome = $scope.grossBusinessIncome;


      /*Total Value of Sole Trader Income*/
      for (var $i = 0; $i <$scope.allSoleTraderIncome.length && $scope.allSoleTraderIncome[$i].business.earnings.length > 0; $i++)
      {
        switch($scope.allSoleTraderIncome[$i].owner)
        {
          case $scope.client1FullName:
            $scope.grossSoleTraderIncome[0] += $scope.allSoleTraderIncome[$i].business.earnings[0].salary;

            break;

          case $scope.client2FullName:
            $scope.grossSoleTraderIncome[1] += $scope.allSoleTraderIncome[$i].business.earnings[0].salary;

            break;
        }

      }

      $rootScope.grossSoleTraderIncome = $scope.grossSoleTraderIncome;

      /*Calculating Other Income Total Value*/
      for (var $i = 0; $i <$scope.otherIncome.length; $i++)
      {
        if($scope.otherIncome[$i].isTaxFree==false)
        {
          switch($scope.otherIncome[$i].owner) {
            case $scope.client1FullName:
              $scope.grossOtherIncome[0] += $scope.otherIncome[$i].amount;
              break;

            case $scope.client2FullName:
              $scope.grossOtherIncome[1] += $scope.otherIncome[$i].amount;
              break;
          }

        } else {
          switch($scope.otherIncome[$i].owner) {
            case $scope.client1FullName:
              $scope.nonTaxableIncome[0] += $scope.otherIncome[$i].amount;
              break;

            case $scope.client2FullName:
              $scope.nonTaxableIncome[1] += $scope.otherIncome[$i].amount;
              break;
          }
        }




      }

      $rootScope.nonTaxableIncome = $scope.nonTaxableIncome;

      $rootScope.grossOtherIncome = $scope.grossOtherIncome;

      /*Calculating Rental Income Total Value*/
      for (var $i = 0; $i <$scope.properties.length; $i++) {
        if ($scope.properties[$i].purpose == "Investment Property" ||$scope.properties[$i].purpose == "Investment" || $scope.properties[$i].purpose == "Business Use")
        {
          if(typeof $scope.properties[$i].ownership !== 'undefined' && $scope.properties[$i].ownership !== null) {
            if($scope.properties[$i].ownership.ownershipType == 'Sole') {

                if ($scope.properties[$i].ownership.owners.length == 1) {

                switch ($scope.properties[$i].ownership.owners[0].owner) {
                    case $scope.client1Id:

                    $scope.grossRentalIncome[0] += $scope.properties[$i].grossRentalIncome;

                    break;

                    case $scope.client2Id:

                    $scope.grossRentalIncome[1] += $scope.properties[$i].grossRentalIncome;
                    break;
                }
                }
            }
            else if($scope.properties[$i].ownership.ownershipType == 'Joint' || $scope.properties[$i].ownership.owners.length > 1) {

                var percentage1 = $scope.properties[$i].ownership.owners[0].percentage  / 100;
                $scope.grossRentalIncome[0] += ($scope.properties[$i].grossRentalIncome * percentage1);
                if($scope.properties[$i].ownership.owners[1] && $scope.properties[$i].ownership.owners[1].percentage) {
                var percentage2 = $scope.properties[$i].ownership.owners[1].percentage  / 100;
                $scope.grossRentalIncome[1] += ($scope.properties[$i].grossRentalIncome * percentage2);
                }
            }
          } else {
              // If there is no ownership, this will be added to client 1 by default
              $scope.grossRentalIncome[0] += $scope.properties[$i].grossRentalIncome;
          }
        }
      }

      $rootScope.grossRentalIncome = $scope.grossRentalIncome;

      /*Calculating Investments Total Value*/
      for (var $i = 0; $i <$scope.allInvestments.length; $i++)
      {

        if($scope.allInvestments[$i].yearlyIncome && $scope.allInvestments[$i].yearlyIncome > 0 && $scope.allInvestments[$i].ownership.ownershipType == 'Sole') {

          switch($scope.allInvestments[$i].ownership.owners[0].owner) {
            case $scope.client1Id:
              $scope.grossInvestmentIncome[0] += ($scope.allInvestments[$i].yearlyIncome * ($scope.allInvestments[$i].ownership.owners[0].percentage/100));
              break;

            case $scope.client2Id:
              $scope.grossInvestmentIncome[1] += ($scope.allInvestments[$i].yearlyIncome * ($scope.allInvestments[$i].ownership.owners[0].percentage/100));
              break;
          }
        }
        if($scope.allInvestments[$i].yearlyIncome && $scope.allInvestments[$i].yearlyIncome > 0 && $scope.allInvestments[$i].ownership.ownershipType == 'Joint') {

          if($scope.allInvestments[$i].ownership.owners.length > 1) {

            $scope.grossInvestmentIncome[0] += ($scope.allInvestments[$i].yearlyIncome * ($scope.allInvestments[$i].ownership.owners[0].percentage / 100));
            if($scope.allInvestments[$i].ownership.owners[1] && $scope.allInvestments[$i].ownership.owners[1].percentage) {
              $scope.grossInvestmentIncome[1] += ($scope.allInvestments[$i].yearlyIncome * ($scope.allInvestments[$i].ownership.owners[1].percentage / 100));
            }

          }

        }
      }
      $scope.getPreTaxDeductions(allIncome);
      $rootScope.grossInvestmentIncome = $scope.grossInvestmentIncome;
    }


    $scope.calculateAllLiabilities = function()
    {
      var loanRepayments=0;

        if($scope.clientSchema.borrowings){
          $scope.allLoans =  $scope.clientSchema.borrowings.borrowing.filter((_borrowing) => !_borrowing.isClosed  );
        }else{
          //pop up here
          // $scope.dataErrorMessage = "Borrowings : Lacking borrowings property from the Client summary.";
          //$scope.showDashboardError = true;
          $scope.allLoans = [];
        }

        for (var i = 0; i <$scope.allLoans.length; i++)
        {

          $scope.totalLiabilities += $scope.allLoans[i].outstanding;

          if($scope.allLoans[i].properties){
            if($scope.allLoans[i].properties.length > 0){
              $scope.totalPropertyLiabilities += $scope.allLoans[i].outstanding;
            }
          }

          switch($scope.allLoans[i].type)
          {
            case "Credit Card":
                  $scope.totalOutstandingCreditCardDebit += $scope.allLoans[i].outstanding;
                  break;
          }


          /*Calculate Repayments*/
          if($scope.allLoans[i].repayment) {
            switch($scope.allLoans[i].repaymentFreq){
              case "Weekly":
                loanRepayments += parseInt(($scope.allLoans[i].repayment*52)/12,10);
                break;
              case "Fortnightly":
                loanRepayments += parseInt(($scope.allLoans[i].repayment *26)/12,10);
                break;
              case "Monthly":
                loanRepayments += parseInt($scope.allLoans[i].repayment,10);
                break;
              case "Bi-Monthly":
                loanRepayments += parseInt($scope.allLoans[i].repayment,10)/2;
                break;
              case "Quarterly":
                loanRepayments += parseInt($scope.allLoans[i].repayment,10)/4;
                break;
              default:
                loanRepayments += parseInt($scope.allLoans[i].repayment,10)/12;
                break;
            }
          }
        }

        $rootScope.totalLoanPayments = $scope.totalLoanPayments;
        $rootScope.totalLiabilities = $scope.totalLiabilities;
        $rootScope.totalPropertyLiabilities = $scope.totalPropertyLiabilities;
        $rootScope.totalOutstandingCreditCardDebit = $scope.totalOutstandingCreditCardDebit;

        $scope.totalLoanPayments[0] = loanRepayments;
        $scope.totalLoanPayments[1] = loanRepayments*12;


      }

    $scope.totalPropertyValue = function() {

      for(var i=0;i<$scope.properties.length;i++) {
        $scope.totalPropertiesValue += $scope.properties[i].currentValue;
        $rootScope.totalPropertiesValue = $scope.totalPropertiesValue[0];
      }
    };
    var getMonthlyValue = function(amount, frequency) {   
      var monthlyValue = 0;
        switch(frequency){
          case "Weekly":
            monthlyValue = parseInt((amount*52)/12,10);
            break;
          case "Fortnightly":
            monthlyValue = parseInt((amount*26)/12,10);
            break;
          case "Monthly":
            monthlyValue= parseInt(amount,10);
            break;
          case "Every 2 months":
            monthlyValue = parseInt(amount,10)/2;
            break;
          case "Every 3 months":
            monthlyValue = parseInt(amount,10)/3;
            break;
          case "Every 6 months":
            monthlyValue = parseInt(amount,10)/6;
            break;
          case "Bi-Monthly":
            monthlyValue = parseInt(amount,10)/2;
            break;
          case "Quarterly":
            monthlyValue = parseInt(amount,10)/4;
            break;
          case "Yearly":
            monthlyValue = parseInt(amount,10)/12;
            break;
        }
      return monthlyValue;
    }
    $scope.calculatePortfolioValue = function() {

      $scope.propertyLVR = [];
      $scope.propertyAVL80 = [];
      $scope.propertyCurrentIO = [];
      $scope.propertyTotalRepayment = [];
      $scope.propertyTotalBalance = [];
      $scope.totalCurrentIO = 0;

      var totalLoanOutstanding = 0;
      var totalRepayments = 0;

      for(var index in $scope.loans) {
        $scope.listLoanProperty($scope.loans[index]);

        if($scope.loans[index].repayment && $scope.loans[index].repaymentFreq) {
          $scope.loans[index].monthlyRepayment = getMonthlyValue($scope.loans[index].repayment,$scope.loans[index].repaymentFreq);
        } else {
          $scope.loans[index].monthlyRepayment = $scope.loans[index].repayment;
        }
      }

      // get properties without loan
      for(var i in $scope.properties){
        let hasLoan = false;

        for(var j in $scope.loansPropertyList){
          for(var k in $scope.loansPropertyList[j]){
            if($scope.properties[i]._id == $scope.loansPropertyList[j][k]._id) {
              hasLoan = true;
              break;
            }
          }
        }
        if(!hasLoan) {
          $scope.nonLoanPropertyList.push($scope.properties[i]);
          $rootScope.nonLoanPropertyList = $scope.nonLoanPropertyList;
        }
      }

      for(var index in $scope.properties) {
        computePropertyListLoanValues(index, $scope.properties[index]);
      }
      for(var index in $scope.loans) {
        if($scope.loans[index].type){
          if($scope.loans[index].type.indexOf('Investment Loan') != -1 || $scope.loans[index].type.indexOf('Home Loan') != -1) {

            totalLoanOutstanding += $scope.loans[index].outstanding;
            $scope.loansTotalLimit += $scope.loans[index].limit;
            $rootScope.loansTotalLimit = $scope.loansTotalLimit;

            if($scope.loans[index].repayment && $scope.loans[index].repaymentFreq) {
              totalRepayments += getMonthlyValue($scope.loans[index].repayment, $scope.loans[index].repaymentFreq);
            }

          }
        }
      }

      $scope.loansBalance = totalLoanOutstanding;
      $rootScope.loansBalance = $scope.loansBalance;
      $scope.loansTotalBalance = totalLoanOutstanding;
      $rootScope.loansTotalBalance = $scope.loansTotalBalance;
      $scope.loansTotalRepayment = totalRepayments;
      $rootScope.loansTotalRepayment = $scope.loansTotalRepayment;
      $scope.avlEquityAtEighty = ($scope.totalPropertiesValue*0.8)-totalLoanOutstanding;
      $rootScope.avlEquityAtEighty = $scope.avlEquityAtEighty;
      $scope.currentLvr = Math.round((totalLoanOutstanding / $scope.totalPropertiesValue)*100);
      $rootScope.currentLvr = $scope.currentLvr;
    };


    var computePropertyListLoanValues = function(index, property){
      var propertyListValue = property.currentValue;;
      var loanOutstanding = 0;
      var loanRepayment = 0;
      let loanTotalIO = 0;
      // loop all loans
      $scope.loans.forEach(function(loan) {
        if(loan.securedAgainst) {
          // check if loan is related to property list
          if(loan.securedAgainst.includes(property._id)) {
            loanOutstanding += loan.outstanding;
            loanRepayment += loan.repayment;
            loanTotalIO += (loan.outstanding * (loan.interestRate / 100)) /12;
          }
        }
      }, this);
      // compute property's lvr, io and avl 90
      $scope.propertyLVR[index] = (loanOutstanding / propertyListValue)*100;
      $scope.propertyCurrentIO[index] = Math.round(loanTotalIO);
      $scope.totalCurrentIO += Math.round(loanTotalIO);
      $scope.propertyAVL80[index] = (propertyListValue*0.8)-loanOutstanding;
      $scope.propertyTotalRepayment[index] = loanRepayment;
      $scope.propertyTotalBalance[index] = loanOutstanding;
    }

    var computerPropertyTotalDeduction = function(property) {
      var totalDeduction = 0;

      // calculate property cost
      if(property.purpose == 'Investment'){
        if(property.bodyCorpCosts) {
          totalDeduction += property.bodyCorpCosts;
        }
        if(property.insuranceCosts) {
          totalDeduction += property.insuranceCosts;
        }
        if(property.landTaxCosts) {
          totalDeduction += property.landTaxCosts;
        }
        if(property.maintenanceCost) {
          totalDeduction += property.maintenanceCost;
        }
        if(property.managementFees) {
          totalDeduction += property.managementFees;
        }
        if(property.waterRates) {
          totalDeduction += property.waterRates;
        }
        if(property.councilRates) {
          totalDeduction += property.councilRates
        }
      }
      return totalDeduction
    }

    $scope.computePropertyDeduction = function() {

      /*Calculating Property Deduction Value*/
      for (var $i = 0; $i <$scope.properties.length && $scope.properties[$i].ownership; $i++) {

        if($scope.properties[$i].ownership.ownershipType == 'Sole') {

          if ($scope.properties[$i].ownership.owners.length == 1) {

            switch ($scope.properties[$i].ownership.owners[0].owner) {
              case $scope.client1Id:
                $scope.investmentPropertyDeductions[0] += (computerPropertyTotalDeduction($scope.properties[$i]) * ($scope.properties[$i].ownership.owners[0].percentage  / 100));
                break;

              case $scope.client2Id:
                $scope.investmentPropertyDeductions[1] += (computerPropertyTotalDeduction($scope.properties[$i]) * ($scope.properties[$i].ownership.owners[0].percentage / 100));
                break;
            }
          }
        }
        else if($scope.properties[$i].ownership.ownershipType == 'Joint') {

          $scope.investmentPropertyDeductions[0] += (computerPropertyTotalDeduction($scope.properties[$i]) * ($scope.properties[$i].ownership.owners[0].percentage / 100));
          if($scope.properties[$i].ownership.owners[1] && $scope.properties[$i].ownership.owners[1].percentage){
            $scope.investmentPropertyDeductions[1] += (computerPropertyTotalDeduction($scope.properties[$i]) * ($scope.properties[$i].ownership.owners[1].percentage / 100));
          }
        }
        else {

          if ($scope.properties[$i].ownership.owners.length == 1) {
            if($scope.properties[$i].ownership.owners[0].percentage) {
              switch ($scope.properties[$i].ownership.owners[0].owner) {
                case $scope.client1Id:
                  $scope.investmentPropertyDeductions[0] += (computerPropertyTotalDeduction($scope.properties[$i]) * ($scope.properties[$i].ownership.owners[0].percentage  / 100));
                  break;
                case $scope.client2Id:
                  $scope.investmentPropertyDeductions[1] += (computerPropertyTotalDeduction($scope.properties[$i]) * ($scope.properties[$i].ownership.owners[0].percentage / 100));
                  break;
              }
            }
          } else if($scope.properties[$i].ownership.owners.length == 2) {
            if($scope.properties[$i].ownership.owners[0].percentage) {
              switch ($scope.properties[$i].ownership.owners[0].owner) {
                case $scope.client1Id:
                  $scope.investmentPropertyDeductions[0] += (computerPropertyTotalDeduction($scope.properties[$i]) * ($scope.properties[$i].ownership.owners[0].percentage  / 100));
                  break;
                case $scope.client2Id:
                  $scope.investmentPropertyDeductions[1] += (computerPropertyTotalDeduction($scope.properties[$i]) * ($scope.properties[$i].ownership.owners[0].percentage / 100));
                  break;
              }
            }
            if($scope.properties[$i].ownership.owners[1].percentage) {
              switch ($scope.properties[$i].ownership.owners[1].owner) {
                case $scope.client1Id:
                  $scope.investmentPropertyDeductions[0] += (computerPropertyTotalDeduction($scope.properties[$i]) * ($scope.properties[$i].ownership.owners[1].percentage  / 100));
                  break;
                case $scope.client2Id:
                  $scope.investmentPropertyDeductions[1] += (computerPropertyTotalDeduction($scope.properties[$i]) * ($scope.properties[$i].ownership.owners[1].percentage / 100));
                  break;
              }
            }
          }
        }
      }

      $rootScope.investmentPropertyDeductions = $scope.investmentPropertyDeductions;

      if(hasLoanPropertyDeduction()) {
        $scope.computeLoanPropertyDeduction();
      }
    }

    var hasLoanPropertyDeduction = function() {

      return $scope.grossInvestmentIncome[0] > 0  || $scope.grossInvestmentIncome[1] > 0 || $scope.grossRentalIncome[0] > 0 ||$scope.grossRentalIncome[1] > 0;
    }
    $scope.computeLoanPropertyDeduction = function() {

      /*Calculating Property Deduction Value*/
      for (var $i = 0; $i <$scope.allLoans.length; $i++) {

        if($scope.allLoans[$i].type) {


          if(($scope.allLoans[$i].type.indexOf('Investment Loan') != -1 && $scope.allLoans[$i].primaryPurpose && $scope.allLoans[$i].primaryPurpose.indexOf('Real Estate') != -1) ){
          //|| ($scope.allLoans[$i].detailedPurpose&& $scope.allLoans[$i].detailedPurpose.indexOf('Investment use') != -1)) {
            if($scope.allLoans[$i].borrower){
            if($scope.allLoans[$i].borrower.ownershipType == 'Sole') {
              if ($scope.allLoans[$i].borrower.owners.length == 1) {


                switch ($scope.allLoans[$i].borrower.owners[0].owner) {
                  case $scope.client1Id:
                    $scope.investmentPropertyDeductions[0] += (computerLoanPropertyDeduction($scope.allLoans[$i]) * ($scope.allLoans[$i].borrower.owners[0].percentage  / 100));
                    break;

                  case $scope.client2Id:
                    $scope.investmentPropertyDeductions[1] += (computerLoanPropertyDeduction($scope.allLoans[$i]) * ($scope.allLoans[$i].borrower.owners[0].percentage / 100));
                    break;
                }
              }
            }
            else if($scope.allLoans[$i].borrower.ownershipType == 'Joint') {

              $scope.investmentPropertyDeductions[0] += (computerLoanPropertyDeduction($scope.allLoans[$i]) * ($scope.allLoans[$i].borrower.owners[0].percentage / 100));

              $scope.investmentPropertyDeductions[1] += (computerLoanPropertyDeduction($scope.allLoans[$i]) * ($scope.allLoans[$i].borrower.owners[1].percentage / 100));
            }
            else {

              if ($scope.allLoans[$i].borrower.owners.length == 1) {
                if($scope.allLoans[$i].borrower.owners[0].percentage) {
                  switch ($scope.allLoans[$i].borrower.owners[0].owner) {
                    case $scope.client1Id:
                      $scope.investmentPropertyDeductions[0] += (computerLoanPropertyDeduction($scope.allLoans[$i]) * ($scope.allLoans[$i].borrower.owners[0].percentage  / 100));
                      break;
                    case $scope.client2Id:
                      $scope.investmentPropertyDeductions[1] += (computerLoanPropertyDeduction($scope.allLoans[$i]) * ($scope.allLoans[$i].borrower.owners[0].percentage / 100));
                      break;
                  }
                }
              } else if($scope.allLoans[$i].borrower.owners.length == 2) {
                if($scope.allLoans[$i].borrower.owners[0].percentage) {
                  switch ($scope.allLoans[$i].borrower.owners[0].owner) {
                    case $scope.client1Id:
                      $scope.investmentPropertyDeductions[0] += (computerLoanPropertyDeduction($scope.allLoans[$i]) * ($scope.allLoans[$i].borrower.owners[0].percentage  / 100));
                      break;
                    case $scope.client2Id:
                      $scope.investmentPropertyDeductions[1] += (computerLoanPropertyDeduction($scope.allLoans[$i]) * ($scope.allLoans[$i].borrower.owners[0].percentage / 100));
                      break;
                  }
                }
                if($scope.allLoans[$i].borrower.owners[1].percentage) {
                  switch ($scope.allLoans[$i].borrower.owners[1].owner) {
                    case $scope.client1Id:
                      $scope.investmentPropertyDeductions[0] += (computerLoanPropertyDeduction($scope.allLoans[$i]) * ($scope.allLoans[$i].borrower.owners[1].percentage  / 100));
                      break;
                    case $scope.client2Id:
                      $scope.investmentPropertyDeductions[1] += (computerLoanPropertyDeduction($scope.allLoans[$i]) * ($scope.allLoans[$i].borrower.owners[1].percentage / 100));
                      break;
                  }
                }
              }
            }
            }
          }

        }
      }
    }

    var computerLoanPropertyDeduction = function(loan) {

      if(loan) {
          if(loan.interestRate) {

              if(loan.outstanding) {
                  return ((loan.interestRate/100 )* loan.outstanding)
              }
          }
      }
      return 0;
    }

    
    $scope.calculateAllAssets = function()
    {
      $scope.totalAssets = 0;
      $scope.propertyValue = 0;
      $scope.vehiclesValue = 0;
      $scope.investmentsValue = 0;
      $scope.bankAccountsValue = 0;
      $scope.superFundsValue = 0;
      $scope.otherAssetsValue = 0;
      $scope.lifeInsuranceValue = 0;
      $scope.householdContentsValue =0;

      if($scope.clientSchema.assets){
        var properties = $scope.clientSchema.assets.properties;
        var vehicles = $scope.clientSchema.assets.vehicles;
        var investments = $scope.clientSchema.assets.investments;
        var bankAccounts = $scope.clientSchema.assets.bankAccounts;
        var superFunds = $scope.clientSchema.assets.superFunds;
        var otherAssets = $scope.clientSchema.assets.otherAssets;
        var lifeInsurance = $scope.clientSchema.assets.lifeInsurance;

        if(properties){
          for(var $i=0;$i<properties.length;$i++) {
            $scope.propertyValue += properties[$i].currentValue;
            $scope.householdContentsValue += properties[$i].contentsValue;
          }
        }

        if(vehicles){
          for($i=0;$i<vehicles.length;$i++) {
            $scope.vehiclesValue += vehicles[$i].value;
          }
        }

        if(investments){
          for($i=0;$i<investments.length;$i++) {
            $scope.investmentsValue += investments[$i].currentValue;
          }
        }

        if(bankAccounts){
          for($i=0;$i<bankAccounts.length;$i++) {
            $scope.bankAccountsValue += bankAccounts[$i].balance;
          }
        }

        if(superFunds){
          for($i=0;$i<superFunds.length;$i++) {
            $scope.superFundsValue += superFunds[$i].value;
          }
        }

        if(lifeInsurance){
          for($i=0;$i<lifeInsurance.length;$i++) {
            $scope.lifeInsuranceValue += lifeInsurance[$i].value;
          }
        }

        if(otherAssets){
          for($i=0;$i<otherAssets.length;$i++) {
            $scope.otherAssetsValue += otherAssets[$i].value;
          }
        }

      }

      //Data For Assets Chart (Chart 3) on dashboard
      $scope.data3 = [$scope.propertyValue,$scope.investmentsValue,$scope.superFundsValue,$scope.vehiclesValue,$scope.bankAccountsValue,$scope.otherAssetsValue,$scope.lifeInsuranceValue, $scope.householdContentsValue];
      $scope.labels3 = ["Property","Investments","SuperFunds","Vehicles","Bank Accounts","Other Assets","Life Insurance","Household Contents"];
      $scope.colors3 = ["#FC580C","#FC6F18","#FC8624","#FD9C31","#FDB33D","#FDBB41","#FDCA49","#FDCA49"];

      $scope.totalAssets = $scope.propertyValue+$scope.investmentsValue+$scope.superFundsValue+$scope.vehiclesValue+$scope.bankAccountsValue+$scope.otherAssetsValue+ $scope.householdContentsValue;
      $rootScope.totalAssets = $scope.totalAssets;


    }

    $scope.initCalculation = function(){
      $scope.assets = $scope.clientSchema.assets;

      if($scope.assets){
          $scope.properties = $scope.clientSchema.assets.properties;
          $scope.allSuper = $scope.assets.superFunds;
          $scope.bankAccounts = $scope.assets.bankAccounts;
          $scope.investments = $scope.assets.investments;
      }else{
          $scope.properties = [];
          $scope.allSuper = [];
          $scope.bankAccounts = [];
          $scope.investments = [];
      }
      $scope.clients = $scope.clientSchema.personalInfo;


       /*Set full names for ownership*/
      if($scope.clients){
        if($scope.clients.client1){
          $scope.client1FullName = $scope.clients.client1.fName+" "+$scope.clients.client1.lName;
          $scope.client1Id = $scope.clients.client1._id;
          $rootScope.client1Id = $scope.client1Id;
        }

        if($scope.client1FullName == " "){$scope.client1FullName = "You"}

        if($scope.clients.client2){
          $scope.client2FullName = $scope.clients.client2.fName+" "+$scope.clients.client2.lName;
          if($scope.client2FullName == " "){$scope.client2FullName = "Your Partner"}
           $scope.client2Id = $scope.clients.client2._id;
           $rootScope.client2Id = $scope.client2Id;
        }

      }

      $scope.totalPropertyValue();
      $scope.calculatePortfolioValue();
      $scope.calculateIncome();
      $scope.calculateAllInvestmentAssets();
      $scope.calculateAllExpenses();
      $scope.calculateAllLiabilities();
      $scope.calculateAllNonInvestmentAssets();
      $scope.calculatePropertyInvestmentCost();
      $scope.computePropertyDeduction();
      $scope.calculateAllAssets();



      $rootScope.superSalarySacrificeClient1 = $scope.superannuationSalarySacrifice[0];
      $rootScope.superSalarySacrificeClient2 = $scope.superannuationSalarySacrifice[1];

      if($scope.grossRentalIncome[0] === 0 && $scope.grossRentalIncome[1] === 0){
        if($scope.clientSchema.assets && $scope.clientSchema.assets.annualRentalIncome) {
          $scope.annualRentalIncome = $scope.clientSchema.assets.annualRentalIncome;
          $scope.grossRentalIncome[0] =  $scope.annualRentalIncome.client1GrossRentalIncome;
          $scope.grossRentalIncome[1] =  $scope.annualRentalIncome.client2GrossRentalIncome;
        }
      }

      if($scope.totalBillPayments[1] === 0 ){
        if($scope.clientSchema.expenses && $scope.clientSchema.expenses.annualBills) {
          $scope.totalBillPayments[1] = $scope.clientSchema.expenses.annualBills.amount;
          $scope.totalBillPayments[0] = Math.round( $scope.totalBillPayments[1] /12);
        }
      }
      if($scope.totalSpending[1] === 0 ){
        if($scope.clientSchema.expenses && $scope.clientSchema.expenses.annualSpendings) {
          $scope.totalSpending[1] = $scope.clientSchema.expenses.annualSpendings.amount;
          $scope.totalSpending[0] = Math.round( $scope.totalSpending[1] /12);
        }
      }
      /*Total Income Summation*/
      $scope.totalIncome[0] = $scope.grossPaygIncome[0] + $scope.superannuationSalarySacrifice[0] + $scope.grossBusinessIncome[0] +
        $scope.grossSoleTraderIncome[0] + $scope.grossOtherIncome[0] + $scope.grossRentalIncome[0] + $scope.grossInvestmentIncome[0] - $scope.preTaxDeductions[0];
      $scope.totalIncome[1] = $scope.grossPaygIncome[1] + $scope.superannuationSalarySacrifice[1] + $scope.grossBusinessIncome[1] +
        $scope.grossSoleTraderIncome[1] + $scope.grossOtherIncome[1] + $scope.grossRentalIncome[1] + $scope.grossInvestmentIncome[1] - $scope.preTaxDeductions[1];

      $rootScope.totalIncome = $scope.totalIncome;

      $rootScope.totalGrossIncomeClient1 = $scope.totalIncome[0];
      $rootScope.totalGrossIncomeClient2 = $scope.totalIncome[1];

      $rootScope.grossRentalIncomeClient1 = $scope.grossRentalIncome[0];
      $rootScope.grossRentalIncomeClient2 = $scope.grossRentalIncome[1];

      $scope.totalIncomeVal = Math.round(($scope.totalIncome[0] + $scope.totalIncome[1] + $scope.nonTaxableIncome[0] + $scope.nonTaxableIncome[1])/12);
      $rootScope.totalIncomeVal = $scope.totalIncomeVal;

      $scope.data = [Math.round(($scope.grossPaygIncome[0]+$scope.superannuationSalarySacrifice[0]+$scope.grossPaygIncome[1]+$scope.superannuationSalarySacrifice[1])/12),
      Math.round(($scope.grossOtherIncome[0] + $scope.grossOtherIncome[1])/12),
      Math.round(($scope.grossBusinessIncome[0] + $scope.grossBusinessIncome[1])/12),
      Math.round(($scope.grossSoleTraderIncome[0]+$scope.grossSoleTraderIncome[1])/12),
      Math.round(($scope.grossRentalIncome[0]+$scope.grossRentalIncome[1])/12),
      Math.round(($scope.grossInvestmentIncome[0]+$scope.grossInvestmentIncome[1])/12) ];
      $scope.labels = ["Gross PAYG Income","Gross Other Income","Gross Business Income","Gross Self Employed Income",
          "Gross Rental Income", "Gross Investment Income"];
      $scope.colors = ["#A3D12D","#8CC230", "#75B334","#5DA437","#46953B", "#2F863E"];

      /*Total Expenses Summation*/
      $scope.totalExpenditure[0] =  $scope.totalBillPayments[0] + $scope.totalSpending[0] + $scope.totalInvestmentPropertyCosts[0] + $scope.totalOtherPropertyCosts[0] +
        $scope.totalLoanPayments[0] + $scope.totalContributionsOtherInvestments[0] + $scope.afterTaxSuperContributions[0];
      $scope.totalExpenditure[1] =  $scope.totalBillPayments[1] + $scope.totalSpending[1] + $scope.totalInvestmentPropertyCosts[1] + $scope.totalOtherPropertyCosts[1] +
        $scope.totalLoanPayments[1] + $scope.totalContributionsOtherInvestments[1] + $scope.afterTaxSuperContributions[1];
      $scope.totalExpenses = $scope.totalExpenditure[0];
      $rootScope.totalExpenses = $scope.totalExpenses;

      $rootScope.totalExpenditure = $scope.totalExpenditure;

      /*Expenses Chart*/
      $scope.data2 = [$scope.totalBillPayments[0],$scope.totalSpending[0],$scope.totalInvestmentPropertyCosts[0],$scope.totalOtherPropertyCosts[0],
      $scope.totalLoanPayments[0],$scope.afterTaxSuperContributions[0]];
      $scope.labels2 = ["Bills","Spending","Investment Property Costs","Other Property Costs","Loan Payments","After Tax Super Contributions"];
      $scope.colors2 = ["#f22525","#E02323","#CD2121","#b51b1b","#b21e1e","#b21e1f"];
  
      /* Taxable Income*/
      $scope.taxableIncome[0] = $scope.totalIncome[0] - $scope.otherTaxDeductions[0] - $scope.investmentPropertyDeductions[0];
      $scope.taxableIncome[1] = $scope.totalIncome[1] - $scope.otherTaxDeductions[1] - $scope.investmentPropertyDeductions[1];

      $rootScope.taxableIncome = $scope.taxableIncome;

      $rootScope.taxableIncomeClient1 = $scope.taxableIncome[0];
      $rootScope.taxableIncomeClient2 = $scope.taxableIncome[1];

      /* Estimated Tax Paid */
      $scope.estimatedTaxPaid[0] = EmpowerWealthHelper.computeEstimatedTaxPaid($scope.taxableIncome[0]);
      if($scope.taxableIncome[1] && $scope.taxableIncome[1] > 0) {
        $scope.estimatedTaxPaid[1] = EmpowerWealthHelper.computeEstimatedTaxPaid($scope.taxableIncome[1]);
      }

      $rootScope.estimatedTaxPaid = $scope.estimatedTaxPaid;
      /* Total Net */
      $scope.totalNetIncome[0] = $scope.totalIncome[0] - $scope.estimatedTaxPaid[0] + $scope.nonTaxableIncome[0];
      $scope.totalNetIncome[1] = $scope.totalIncome[1] - $scope.estimatedTaxPaid[1] + $scope.nonTaxableIncome[1];

      $rootScope.totalNetIncome = $scope.totalNetIncome;

      $rootScope.totalNetIncome = $scope.totalNetIncome[0] + $scope.totalNetIncome[1];
      $rootScope.totalNetIncomeClient1 = $scope.totalNetIncome[0];
      $rootScope.totalNetIncomeClient2 = $scope.totalNetIncome[1];

      $rootScope.estimatedTaxPaid = $scope.estimatedTaxPaid[0] + $scope.estimatedTaxPaid[1];
      $rootScope.estimatedTaxPaidClient1 = $scope.estimatedTaxPaid[0];
      $rootScope.estimatedTaxPaidClient2 = $scope.estimatedTaxPaid[1];

      /*Income overall*/
      $scope.incomeOverall[1] =  $scope.totalNetIncome[0] +  $scope.totalNetIncome[1];
      $scope.incomeOverall[0] =   parseInt(($scope.totalNetIncome[0] +  $scope.totalNetIncome[1]) / 12);

      $rootScope.incomeOverall = $scope.incomeOverall;

      /* Expenditure overall */
      $scope.expenditureOverall[1] = $scope.totalExpenditure[1] ;
      $scope.expenditureOverall[0] =  parseInt($scope.expenditureOverall[1] / 12);
      $scope.surplus = [$scope.incomeOverall[0] - $scope.expenditureOverall[0], $scope.incomeOverall[1] - $scope.expenditureOverall[1]];

      $rootScope.expenditureOverall = $scope.expenditureOverall;
      $rootScope.surplus = $scope.surplus;

      //dashboard tiles
      $scope.householdIncome = $scope.totalIncome[0] + $scope.totalIncome[1] + $scope.nonTaxableIncome[0]+ $scope.nonTaxableIncome[1];
      $rootScope.householdIncome = $scope.householdIncome;
      $scope.taxPayable = $scope.estimatedTaxPaid[0] + $scope.estimatedTaxPaid[1];
      $rootScope.taxPayable = $scope.taxPayable;
      $scope.surplusCashflow = $scope.surplus[1];
      $rootScope.surplusCashflow = $scope.surplusCashflow;
      $scope.totalCash = $scope.bankAccountsValue;
      $rootScope.totalCash = $scope.totalCash;
      $scope.availableEquity = $scope.totalPropertiesValue-$scope.totalPropertyLiabilities;
      $rootScope.availableEquity = $scope.availableEquity;
      //$scope.investmentNestegg = $scope.superFundsValue;

      $rootScope.$broadcast("dashboard-calculation", {
        householdIncome: $scope.householdIncome,
        taxPayable: $scope.taxPayable,
        surplusCashflow: $scope.surplusCashflow,
        totalCash: $scope.totalCash,
        availableEquity: $scope.availableEquity,
        totalIncomeVal: $scope.totalIncomeVal,
        totalExpenses: $scope.totalExpenses,
        totalAssets: $scope.totalAssets,
        empowerData: empowerDataService,
        client1Id: $scope.client1Id,
        client2Id: $scope.client2Id,
        annualRentalIncome: $scope.annualRentalIncome,
        loansPropertyList: $scope.loansPropertyList,
        nonLoanPropertyList: $scope.nonLoanPropertyList,
        totalPropertiesValue: $scope.totalPropertiesValue,
        loansTotalLimit: $scope.loansTotalLimit,
        loansTotalBalance: $scope.loansTotalBalance,
        loansTotalRepayment: $scope.loansTotalRepayment,
        loansBalance: $scope.loansBalance,
        currentLvr: $scope.currentLvr,
        avlEquityAtEighty: $scope.avlEquityAtEighty,
        repayments: $scope.repayments,
        grossPaygIncome: $scope.grossPaygIncome,
        superannuationSalarySacrifice: $scope.superannuationSalarySacrifice,
        grossBusinessIncome: $scope.grossBusinessIncome,
        grossSoleTraderIncome: $scope.grossSoleTraderIncome,
        grossOtherIncome: $scope.grossOtherIncome,
        grossRentalIncome: $scope.grossRentalIncome,
        grossInvestmentIncome: $scope.grossInvestmentIncome,
        preTaxDeductions: $scope.preTaxDeductions,
        totalIncome: $scope.totalIncome,
        investmentPropertyDeductions: $scope.investmentPropertyDeductions,
        otherTaxDeductions: $scope.otherTaxDeductions,
        taxableIncome: $scope.taxableIncome,
        estimatedTaxPaid: $scope.estimatedTaxPaid,
        nonTaxableIncome: $scope.nonTaxableIncome,
        totalNetIncome: $scope.totalNetIncome,
        totalBillPayments: $scope.totalBillPayments,
        totalSpending: $scope.totalSpending,
        totalInvestmentPropertyCosts: $scope.totalInvestmentPropertyCosts,
        totalInvestmentAssetCosts: $scope.totalInvestmentAssetCosts,
        totalOtherPropertyCosts: $scope.totalOtherPropertyCosts,
        totalLoanPayments: $scope.totalLoanPayments,
        totalContributionsOtherInvestments: $scope.totalContributionsOtherInvestments,
        afterTaxSuperContributions: $scope.afterTaxSuperContributions,
        totalExpenditure: $scope.totalExpenditure,
        incomeOverall: $scope.incomeOverall,
        expenditureOverall: $scope.expenditureOverall,
        surplus: $scope.surplus,
        totalInvestmentAssets: $scope.totalInvestmentAssets,
        totalNonInvestmentAssets: $scope.totalNonInvestmentAssets,
        totalLiabilities: $scope.totalLiabilities,
        totalPropertyLiabilities: $scope.totalPropertyLiabilities,
        totalOutstandingCreditCardDebit: $scope.totalOutstandingCreditCardDebit,
        clients: $scope.clients,
        client1FullName: $scope.client1FullName,
        client1Id: $scope.client1Id,
        client2FullName: $scope.client2FullName,
        client2Id: $scope.client2Id,
        clientSchema: $scope.clientSchema,
        legacyId: $scope.legacyId,
        properties: $scope.properties,
        data3: $scope.data3,
        labels3: $scope.labels3,
        colors3: $scope.colors3,
        data: $scope.data,
        labels: $scope.labels,
        colors: $scope.colors,
        data2: $scope.data2,
        labels2: $scope.labels2,
        colors2: $scope.colors2,
        loans: $scope.loans,
        propertyLVR: $scope.propertyLVR,
        propertyAVL80: $scope.propertyAVL80,
        propertyCurrentIO: $scope.propertyCurrentIO,
        propertyTotalRepayment: $scope.propertyTotalRepayment,
        propertyTotalBalance: $scope.propertyTotalBalance,
        totalCurrentIO: $scope.totalCurrentIO
      })

      $scope.loansTotalOutstanding = _.isNil($scope.clientSchema.borrowings) ? [] 
        : $scope.clientSchema.borrowings.borrowing.filter((_borrowing) => !_borrowing.isClosed);

      const houseHoldNetWorth =
        EmpowerWealthHelper.getTotalInvestmentAssets($scope.assets) +
        EmpowerWealthHelper.getTotalNonInvestmentAssets($scope.assets) -
        EmpowerWealthHelper.getTotalLoanOutstanding($scope.loansTotalOutstanding);
      
      $rootScope.$broadcast("moneysmarts-calculation", {
        initDashboard: $scope.initDashboard,
        totalNetIncome: $scope.totalNetIncome[0] + $scope.totalNetIncome[1],
        totalNetIncomeClient1: $scope.totalNetIncomeClient1,
        totalNetIncomeClient2: $scope.totalNetIncomeClient2,
        estimatedTaxPaid: $scope.estimatedTaxPaid[0] + $scope.estimatedTaxPaid[1],
        estimatedTaxPaidClient1: $scope.estimatedTaxPaidClient1,
        estimatedTaxPaidClient2: $scope.estimatedTaxPaidClient2,
        superSalarySacrificeClient1: $scope.superSalarySacrificeClient1,
        superSalarySacrificeClient2: $scope.superSalarySacrificeClient2,
        taxableIncomeClient1: $scope.taxableIncomeClient1,
        taxableIncomeClient2: $scope.taxableIncomeClient2,
        totalGrossIncomeClient1: $scope.totalGrossIncomeClient1,
        totalGrossIncomeClient2: $scope.totalGrossIncomeClient2,
        preTaxDeductionsClient1: $scope.preTaxDeductionsClient1,
        preTaxDeductionsClient2: $scope.preTaxDeductionsClient2,
        grossRentalIncomeClient1: $scope.grossRentalIncomeClient1,
        grossRentalIncomeClient2: $scope.grossRentalIncomeClient2,
        totalExpenditure: $scope.totalExpenditure[1],
        totalHouseHoldExpenseAnnual: $scope.totalSpending[1] + $scope.totalBillPayments[1],
        houseHoldNetWorth: houseHoldNetWorth,
        totalLoanPayments: $scope.totalLoanPayments,
        totalInvestmentPropertyCosts: $scope.totalInvestmentPropertyCosts,
        totalInvestmentAssetCosts: $scope.totalInvestmentAssetCosts,
        totalContributionsOtherInvestments: $scope.totalContributionsOtherInvestments,
        afterTaxSuperContributions: $scope.afterTaxSuperContributions,
        totalOtherPropertyCosts: $scope.totalOtherPropertyCosts
      });
      $rootScope.$broadcast("futurePlansChanges", {
        primaryClientName: $scope.client1FullName,
        secondaryClientName: $scope.client2FullName,
        clientLastName: $scope.clients.client1.lName
      })

      $rootScope.$broadcast("knowledgeCenter-react")

      $rootScope.$broadcast("moneysmarts-changes")
    }

    $scope.initDashboard = function(clientSchema = null){
      // reset init scope variables when there is a passed parameter (comes from moneysmarts react app pagination)
      if(clientSchema) {
        initializer();
      }
      if ($clientId) {
          DataM.getClientSummary({ id: $clientId })

            .then(function(data) {

              $scope.showSpinner = false;
              
              // if there's a passed parameter in the initDashboard function, used it instead of the api return, because that's a request from the moneysmarts react app (pagination on rollover).
              $scope.clientSchema = clientSchema || data.clientSummary;
              
              let incomeValues = EmpowerWealthHelper.getClientsIncomeValues($scope.clientSchema);
              if(data.clientSummary.legacyId){
                $scope.legacyId = data.clientSummary.legacyId;
              }
              if(data.clientSummary.borrowings){
                $scope.loans = data.clientSummary.borrowings.borrowing.filter((_borrowing) => !_borrowing.isClosed  );
              } else {
                $scope.loans = [];
              }

              $scope.initCalculation();
            }).catch(function(err) {
              console.log(err);
            });
      } else {
        DataM.getSummary().then( function(data) {

          // if there's a passed parameter in the initDashboard function, used it instead of the api return, because that's a request from the moneysmarts react app (pagination on rollover).
          $scope.clientSchema = clientSchema || data.clientSummary;

          // check income and survey
          if(data.clientSummary.surveyComplete) {
            $scope.isShortFactFindEnabled = false;
          } else {
            var hasDoneClientSurvey = typeof data.clientSummary.clientSurvey !== "undefined";
            var hasDoneIncome = typeof data.clientSummary.income !== "undefined";
            if($scope.isTPCUser === true){
              $state.go('landing');
              return;
            }
            if(hasDoneClientSurvey && hasDoneIncome) {
              $scope.isShortFactFindEnabled = false;
            }
              DataM.getSetting({name:"ShowShortFactFind"}).then(function (settingsData) {
                if (settingsData.isEnabled === true) {
                  $scope.isShortFactFindEnabled = true;
                    $state.go('lite-fact-find');
                }
                else if(!hasDoneClientSurvey && settingsData.isEnabled === false){

                  $scope.isShortFactFindEnabled = false;
                  $state.go('fact-find0');
                }
              }).catch(function(err){
                console.log('error in retrieving settings')
                $scope.showSpinner = false;
              });

          }

          if(data.clientSummary.borrowings){
            $scope.loans = data.clientSummary.borrowings.borrowing.filter((_borrowing) => !_borrowing.isClosed  );
          } else {
            $scope.loans = [];
          }
          if(data.clientSummary.legacyId){
            $scope.legacyId = data.clientSummary.legacyId;

            $scope.initCalculation();

          }
          else{
            if(data.clientSummary.borrowings){
              $scope.loans = data.clientSummary.borrowings.borrowing.filter((_borrowing) => !_borrowing.isClosed  );
            } else {
              $scope.loans = [];
            }

            if($scope.clientSchema.surveyComplete){
              $scope.initCalculation();
            }
          }

        }).catch( function(err) {});
      }
    }

    /*
    // Old Logic - no longer uses
    $scope.isTPCUser = false;
    DataM.getMe().then(function(data){
      if(data.role === 'user' && data.type === 'tpc'){
        $scope.isTPCUser = true;
      }
      return data.type;

    }).then(function(type){
      $scope.initDashboard();
    });
    */

    // Dasboard calculation END

  })
  
angular.module('meanApp').controller('MBCheckerModalController', function ($scope, $uibModalInstance, result, $state) {

  $scope.pagesLacking = result.pages;

  $scope.activate = function () {
    $uibModalInstance.close();
  };

  $scope.close = function (location) {
  $uibModalInstance.close
  };
});
