'use strict';
angular.module('meanApp')
  .config(function ($stateProvider) {
    $stateProvider
      .state('landing', {
        url: '/landing/:clientId',
        params: {},
        templateUrl: 'app/tpcPortal/landing/landing.html',
        controller: 'landing-controller',
        authenticate: ['user','admin']
      });
  });
