angular
  .module("meanApp")
  .directive("borrowingsloan", function () {
    return {
      restrict: "E",
      templateUrl: "app/borrowings/borrowings-loan.html",
    };
  })
  .controller(
    "factfind-0-controller",
    function (
      $scope,
      $state,
      $stateParams,
      $timeout,
      $location,
      DataM,
      Auth,
      $interval,
      empowerDataService,
      $rootScope
    ) {
      "use strict";
      $scope.isAdmin = false;
      Auth.isAdmin().then((isAdmin) => ($scope.isAdmin = isAdmin));

      $scope.moneyManagement = {};
      $scope.topicsToDiscuss = {};
      $scope.timeAllocated = {};
      $scope.quiz = {};
      $scope.whatMoneyMeans = {};
      $scope.selfAssessment = {};
      $scope.isReviewed = false;
      $scope.score = 0;
      $scope.addlAdvisorInfo = "";
      $scope.firstPage = true;
      $scope.forms = {};
      $scope.empowerData = empowerDataService;
      $scope.findUs = {
        type: "select",
        name: "Find Us",
        value: "Please select",
        values: [
          "Advertisement",
          "Ask (Search Engine)",
          "Australian Broker TV",
          "Australian Property Investor Magazine (Online)",
          "Australian Property Investor Magazine (Print)",
          "Bryce Holdaway's Website",
          "Building Signage",
          "CPA Australia - Adelaide",
          "CPA Australia - Brisbane",
          "CPA Australia - Canberra",
          "CPA Australia - Melbourne",
          "CPA Congress Melbourne 2014",
          "CPA Congress Brisbane 2014",
          "CPA Congress Melbourne 2015",
          "CPA Congress Sydney 2015",
          "CPA Regional Roadshow 2015",
          "Direct Mail",
          "Domain.com.au",
          "Door to Door",
          "Empower Wealth's Seminar/Edu Event",
          "Empower Wealth's VIP Cocktail Party",
          "Event - AOT",
          "Event - External - Seminar/Edu Event",
          "Event - Luxury Network",
          "Event - Simone Bulleen",
          "Event - Telstra Award",
          "Event / Exhibition",
          "Facebook",
          "Google",
          "Google Pay Per Click",
          "Google+",
          "Home Buyer Show - Brisbane",
          "Home Buyer Show - Melbourne",
          "Home Buyer Show - Perth",
          "Home Buyer Show - Sydney",
          "LinkedIn",
          "Location Location Location Australia Show",
          "Media / Press Coverage",
          "Melbourne Property TV",
          "Money Magazine (Print)",
          "Professional recommendation - NAB",
          "Property Buyer Expo Sydney - 2015",
          "PropertyChat.com.au",
          "Qantas Inflight Radio",
          "Real Estate Talk Podcast",
          "RealEstate.com.au",
          "Referral by Family/Friends",
          "Sky News - Switzer",
          "Sky News - Your Money Your Call",
          "Smart Property Investment  Magazine (Online)",
          "Smart Property Investment  Magazine (Print)",
          "The Armchair Guide to Property Investing book",
          "The Property Couch Podcast",
          "Twitter",
          "Vitality Show 2014 - Sydney",
          "Webinar",
          "Webinar - Beginners Guide to Property Investing",
          "Webinar - First Home Buyer",
          "Yahoo",
          "Yellow Pages",
          "Yellow Pages (Search Engine)",
          "Your Investment Property Forum - Melbourne",
          "Your Investment Property Magazine",
          "Your Investment Property Magazine (Online)",
          "Your Investment Property Magazine (Print)",
          "Your Property Manager Event - 2015",
          "YouTube",
          "Other",
        ],
        other: "",
        referrer: "",
      };

      const whatMoneyMeansDefault = {
        takeCareOfYourself: false,
        improvedLifestyle: false,
        timeWithFamily: false,
        volunteerWork: false,
        finerThingsInLife: false,
        betterEducationForKids: false,
        notWanting: false,
        chooseWhatIWantToDo: false,
        startOwnBusiness: false,
        providingForFamily: false,
        moreBigKidsToys: false,
        workLifeBalance: false,
        lifestyleChoices: false,
        timeForHobbies: false,
        realisingDreams: false,
        inspiringOthers: false,
        selfConfidence: false,
        purposefulLife: false,
        accomplishment: false,
        styleAndGrace: false,
        personalTime: false,
        peaceOfMind: false,
        betterHealth: false,
        actualisation: false,
        moreJewellery: false,
        holidayHome: false,
        compassion: false,
        betterHome: false,
        recognition: false,
        quietTimes: false,
        helpSociety: false,
        studyAgain: false,
        selfWorth: false,
        relaxation: false,
        abundance: false,
        generosity: false,
        happiness: false,
        workLess: false,
        fulfillment: false,
        influence: false,
        betterCar: false,
        adventure: false,
        freedom: false,
        stability: false,
        optimism: false,
        success: false,
        results: false,
        security: false,
        control: false,
        comfort: false,
        travel: false,
        growth: false,
        pride: false,
        power: false,
      };

      const topicsToDiscussDefault = {
        others: null,
        smsf: false,
        superReview: false,
        buyersAgencyServices: false,
        insuranceLearning: false,
        investorLearning: false,
        mortgageReview: false,
        brokingServices: false,
        general: false,
        buildPortfolio: false,
        reviewPortfolio: false,
        nextInvestment: false,
        firstInvestment: false,
        firstHome: false,
      };

      $scope.showClientSpinner = true;
      $scope.loadingMessage = "Retrieving Client Information...";
      $scope.showRetrieveError = false;
      $scope.clientId = $stateParams.clientId;
      $scope.state = $state.current;
      $scope.params = $stateParams;

      $scope.isPersonalInfoCompleted = false;
      $scope.isIncomeCompleted = false;
      $scope.isPropertyCompleted = false;
      $scope.isNonPropertyCompleted = false;
      $scope.isBorrowingCompleted = false;
      $scope.isBillPaymentsCompleted = false;
      $scope.isSpendingCompleted = false;
      $scope.isFPlanHouseholdCompleted = false;
      $scope.isFPlanIncomeCompleted = false;
      $scope.didNextSave = false;
      $scope.clientNotes = "";

      $scope.showBottomButtons = false;
      let toCompare = null;
      $scope.initialSurvey = null;
      $scope.showChangesAlert = false;
      $scope.toStateName = "";
      $scope.isSaving = false;

      $scope.closeChangesAlert = function () {
        $scope.newLocation = "";
        $scope.showChangesAlert = false;
        $scope.isSaving = false;
      };

      $scope.dashboardDiscard = async function () {
        $scope.showClientSpinner = true;
        $scope.loadingMessage = "Discarding Client Changes...";
        if ($scope.showChangesAlert) {
          $scope.showChangesAlert = false;
        }

        var promise = new Promise(function (resolve, reject) {
          $timeout(function () {
            $scope.setInputValues(true);
            resolve("done");
          }, 1);
        });

        let result = await promise;
        if (result === "done") {
          $scope.showClientSpinner = false;
          if ($scope.isSaving && $scope.toStateName !== "") {
            $state.go($scope.toStateName, {
              clientId: $scope.clientId,
            });
          } else {
            $scope.showBottomButtons = false;
            $rootScope.setAlertValues(
              true,
              true,
              "Successfully discarded changes!",
              true
            );
          }
        }
      };

      $scope.calculateScore = function () {
        var total = 0;
        for (var index in $scope.quiz) {
          if ($scope.quiz[index] !== "" && index !== "_id") {
            const value =
              typeof index == "undefined" || !$scope.quiz[index]
                ? 0
                : $scope.quiz[index];
            total += parseInt(value, 10);
          }
        }
        $scope.score = isNaN(total) || !total ? 0 : total;
      };

      $scope.addNewFutureGoal = function () {
        var goal = {};
        if (!$scope.timeAllocated.other) {
          $scope.timeAllocated.other = [];
        }
        $scope.timeAllocated.other.push(goal);
      };

      $scope.deleteFutureGoal = function (goal) {
        $scope.timeAllocated.other.splice(
          $scope.timeAllocated.other.indexOf(goal),
          1
        );
      };

      $scope.goToLocation = function (location) {
        var proceed = $scope.empowerData.goToLocation(location, $scope);

        if (proceed) {
          $state.go(location, {
            clientId: $scope.clientId,
          });
        }
      };

      $scope.assignValues = function (data) {
        $scope.isPersonalInfoCompleted = data.isPersonalInfoCompleted;
        $scope.isIncomeCompleted = data.isIncomeCompleted;
        $scope.isPropertyCompleted = data.isPropertyCompleted;
        $scope.isNonPropertyCompleted = data.isNonPropertyCompleted;
        $scope.isBorrowingCompleted = data.isBorrowingCompleted;
        $scope.isBillPaymentsCompleted = data.isBillPaymentsCompleted;
        $scope.isSpendingCompleted = data.isSpendingCompleted;
        $scope.isFPlanHouseholdCompleted = data.isFPlanHouseholdCompleted;
        $scope.isFPlanIncomeCompleted = data.isFPlanIncomeCompleted;
        $scope.clientNotes = data.clientNotes;
        if (data.clientSurvey) {
          if (data.clientSurvey.hasOwnProperty("topicsToDiscuss")) {
            $scope.topicsToDiscuss = data.clientSurvey.topicsToDiscuss;
            if ($scope.topicsToDiscuss.others !== null) {
              $scope.topicsToDiscuss.other = true;
            }
          }
          if (data.clientSurvey.hasOwnProperty("timeAllocated")) {
            $scope.timeAllocated = data.clientSurvey.timeAllocated;
          }
          if (data.clientSurvey.hasOwnProperty("quiz")) {
            $scope.quiz = data.clientSurvey.quiz;
          }
          if (data.clientSurvey.hasOwnProperty("selfAssessment")) {
            $scope.selfAssessment = data.clientSurvey.selfAssessment;
          }
          if (data.clientSurvey.hasOwnProperty("whatMoneyMeans")) {
            $scope.whatMoneyMeans = data.clientSurvey.whatMoneyMeans;
          }
          if (data.clientSurvey.hasOwnProperty("findUs")) {
            $scope.findUs.value = data.clientSurvey.findUs;
          }

          if (data.clientSurvey.hasOwnProperty("isReviewed")) {
            $scope.isReviewed = data.clientSurvey.isReviewed;
          }
          if (data.clientSurvey.hasOwnProperty("addlAdvisorInfo")) {
            $scope.addlAdvisorInfo = data.clientSurvey.addlAdvisorInfo;
          }
          if ($scope.findUs.value !== "Please select") {
            if ($scope.findUs.values.indexOf($scope.findUs.value) === -1) {
              $scope.findUs.other = $scope.findUs.value;
              $scope.findUs.value = "Other";
            }
            if ($scope.findUs.value === "Referral by Family/Friends") {
              $scope.findUs.referrer = data.clientSurvey.referrer;
            }
          }
          $scope.calculateScore();
        }

        $scope.changeBottomButtonState();
      };

      $scope.setInputValues = function (isDiscard) {
        $scope.moneyManagement = {};
        $scope.topicsToDiscuss = {};
        $scope.timeAllocated = {};
        $scope.quiz = {};
        $scope.whatMoneyMeans = {};
        $scope.selfAssessment = {};

        if (!isDiscard) {
          DataM.getClientSurvey()
            .then(function (data) {
              if (_.isNil(data.clientSurvey)) {
                // if the user is newly created, this varible is undefined
                // in the database, so we need to add an empty data for it
                // so that it will not cause any inconsistency on comapring
                // initial scope variable to toCompare
                data.clientSurvey = {
                  referrer: null,
                  findUs: "Please select",
                  whatMoneyMeans: whatMoneyMeansDefault,
                  selfAssessment: {},
                  quiz: {},
                  timeAllocated: {
                    other: [],
                  },
                  topicsToDiscuss: topicsToDiscussDefault,
                };
              } else {
                if (_.isNil(data.clientSurvey.referrer)) {
                  data.clientSurvey.referrer = null;
                }
                if (_.isNil(data.clientSurvey.findUs)) {
                  data.clientSurvey.findUs = "Please select";
                }
                if (_.isNil(data.clientSurvey.whatMoneyMeans)) {
                  data.clientSurvey.whatMoneyMeans = whatMoneyMeansDefault;
                }
                if (_.isNil(data.clientSurvey.selfAssessment)) {
                  data.clientSurvey.selfAssessment = {};
                }
                if (_.isNil(data.clientSurvey.quiz)) {
                  data.clientSurvey.quiz = {};
                }
                if (_.isNil(data.clientSurvey.timeAllocated)) {
                  data.clientSurvey.timeAllocated = {
                    other: [],
                  };
                }
                if (_.isNil(data.clientSurvey.topicsToDiscuss)) {
                  data.clientSurvey.topicsToDiscuss = topicsToDiscussDefault;
                }
              }
              toCompare = data;
              var promise = new Promise(function (resolve, reject) {
                $scope.assignValues(data);
                resolve("done");
              });

              promise.then(function (data) {
                if (data === "done") {
                  $scope.showClientSpinner = false;
                }
              });
            })
            .catch(function (err) {
              console.log("Retrieve error", err);
              $scope.showRetrieveError = true;
            });
        } else {
          var data = JSON.parse($scope.initialSurvey);
          toCompare = data;
          $scope.assignValues(data);
        }
      };

      $scope.setInputValues(false);
      $scope.changeBottomButtonState = function () {
        if ($scope.initialSurvey !== null && toCompare !== null) {
          var newToCompare = JSON.stringify(toCompare, function (key, value) {
            if (key === "$$hashKey") {
              return undefined;
            }
            if (key === "$promise") {
              return undefined;
            }
            if (key === "$resolved") {
              return undefined;
            }
            return value;
          });
          if ($scope.initialSurvey !== newToCompare) {
            $scope.showBottomButtons = true;
          } else {
            $scope.showBottomButtons = false;
          }
        } else if ($scope.initialSurvey === null && toCompare !== null) {
          $scope.initialSurvey = JSON.stringify(
            toCompare,
            function (key, value) {
              if (key === "$$hashKey") {
                return undefined;
              }
              if (key === "$promise") {
                return undefined;
              }
              if (key === "$resolved") {
                return undefined;
              }
              return value;
            }
          );
        }
      };

      $scope.$watch(
        "[findUs, moneyManagement, topicsToDiscuss, timeAllocated, quiz, whatMoneyMeans, selfAssessment]",
        function () {
          $scope.changeBottomButtonState();
        },
        true
      );

      $scope.save = function (location) {
        if ($scope.showChangesAlert) {
          $scope.showChangesAlert = false;
        }
        $scope.showClientSpinner = true;
        $scope.loadingMessage = "Saving Client Information...";

        var findUsVal = $scope.findUs.value;
        if ($scope.findUs.value === "Other") {
          findUsVal = $scope.findUs.other;
        }
        var ref = null;
        if ($scope.findUs.value === "Referral by Family/Friends") {
          ref = $scope.findUs.referrer;
        }
        if (!$scope.topicsToDiscuss.other) {
          $scope.topicsToDiscuss.others = null;
        }
        // create variable in same format as clientSchema
        var formData = {
          clientSurvey: {
            topicsToDiscuss: $scope.topicsToDiscuss,
            timeAllocated: $scope.timeAllocated,
            quiz: $scope.quiz,
            selfAssessment: $scope.selfAssessment,
            whatMoneyMeans: $scope.whatMoneyMeans,
            findUs: findUsVal,
            referrer: ref,
          },
        };
        //SAVE TO DB
        DataM.saveClientSurvey(formData)
          .then(function (data) {
            if (data._message === "Client validation failed") {
              var errorMessage = "";
              const formattedMessage = DataHelper.formatMongoError(data);
              $scope.showClientSpinner = false;

              $scope.didNextSave = false;
              alert(formattedMessage);
            } else {
              if ($location.$$url.includes("my-goals")) {
                if ($scope.isSaving && $scope.toStateName !== "") {
                  $state.go($scope.toStateName, {
                    clientId: $scope.clientId,
                  });
                } else {
                  $rootScope.setAlertValues(
                    true,
                    true,
                    "Successfully saved changes!",
                    true
                  );
                  $scope.initialSurvey = null;
                  $scope.initialSurvey = JSON.stringify(
                    toCompare,
                    function (key, value) {
                      if (key === "$$hashKey") {
                        return undefined;
                      }
                      if (key === "$promise") {
                        return undefined;
                      }
                      if (key === "$resolved") {
                        return undefined;
                      }
                      return value;
                    }
                  );
                  $scope.changeBottomButtonState();
                }
              } else {
                $state.go(location, {
                  clientId: $scope.clientId,
                });
              }

              $scope.showClientSpinner = false;
              $rootScope.$broadcast("onSuitabilitySettingSaved");
            }
          })
          .catch(function (err) {
            console.log("test err", err);
            $rootScope.setAlertValues(
              true,
              false,
              "There is a problem accessing the server.",
              true
            );
            alert(
              "There is a problem accessing the server. Please make sure your internet connection is working. If it is working and this problem persist, please report this problem to Empower Wealth team."
            );
            $scope.showClientSpinner = false;
          });
      };

      $scope.onChangeDiscoverUs = function (value) {
        toCompare.clientSurvey.findUs = value;
        $scope.changeBottomButtonState();
      };

      $scope.next = function () {
        $scope.save("fact-find2");
      };

      $scope.quit = function () {
        $scope.save("dashboard");
      };

      $scope.savedText = "Save";
      $scope.dashboardSave = function () {
        var location = "mygoals";
        if ($scope.isSaving && $scope.toStateName !== "") {
          location = $scope.toStateName;
        }
        $scope.save(location);
      };

      $scope.showAlertValidationModal = function () {
        $scope.alertMessage = $scope.empowerData.saveValidationMessage;
        $scope.showAlertModal = true;
      };
      $scope.closeModal = function () {
        $scope.showAlertModal = false;

        if ($scope.elementToFocus) {
          $timeout($scope.elementToFocus.focus(), 1500);
        }
      };
      $scope.showConfirmSaveModal = function (page) {
        if (!$scope.didNextSave) {
          // $scope.toShowConfirmSaveModal = true;
          $scope.nextPageLocation = page;
        }
      };
      $scope.goNextAndNotSaveModal = function () {
        //$scope.toShowConfirmSaveModal = false;
        $state.go($scope.nextPageLocation, {
          clientId: $scope.clientId,
        });
      };
      $scope.goNextAndSaveModal = function () {
        //$scope.toShowConfirmSaveModal = false;
        $scope.didNextSave = true;
        $scope.save($scope.nextPageLocation);
      };
      $scope.closeConfirmModal = function () {
        //$scope.toShowConfirmSaveModal = false;
        $scope.savedText = "Save";
        $scope.isSaving = false;
      };

      $scope.$on(
        "$stateChangeStart",
        function (event, toState, toParams, fromState, fromParams) {
          if (_.isEqual(toState.name, "login")) {
          } else if (
            toState.url.indexOf("mygoals") == -1 &&
            !$scope.isSaving &&
            !$scope.didNextSave
          ) {
            $scope.isSaving = true;
            $scope.toStateName = toState.name;
            event.preventDefault();
            if ($scope.showBottomButtons) {
              $scope.showChangesAlert = true;
            } else {
              $state.go(toState.name, {
                clientId: $scope.clientId,
              });
              return;
            }
          }
        }
      );
    }
  )

  .controller(
    "factfind-1-controller",
    function (
      $scope,
      $rootScope,
      $state,
      $location,
      $stateParams,
      DataM,
      $window,
      Auth,
      empowerDataService,
      $timeout,
      DataHelper,
      $uibModal
    ) {
      $scope.isAdmin = false;
      Auth.isAdmin().then((isAdmin) => ($scope.isAdmin = isAdmin));

      /*Empower Data Service - For common datasets*/
      $scope.empowerData = empowerDataService;
      $scope.dependants = [];
      $scope.householdClients = [];
      $scope.isReviewed = false;
      $scope.addlAdvisorInfo = "";
      $scope.isNumber = angular.isNumber;
      $scope.needsMaiden = [false, false];
      $scope.tenureStatuses = [
        "Own Home - Mortgage",
        "Own Home - No Mortgage",
        "Renting",
        "With Parents",
        "Other",
      ];
      $scope.properties = [];
      $scope.forms = {};
      $scope.clientId = $stateParams.clientId;
      $scope.state = $state.current;
      $scope.params = $stateParams;
      $scope.showClientSpinner = true;
      $scope.loadingMessage = "Retrieving Client Information...";
      $scope.showRetrieveError = false;
      $scope.showRemoveClientModal = false;

      $scope.threeYearsHistoryBoolClient1 = true;
      $scope.threeYearsHistoryBoolClient2 = true;
      // TODO: I don't know how we want this to behave
      $scope.isUserAdmin = $scope.isAdmin;

      $scope.thirdParties = {
        "Property Investment Advisor": {
          active: false,
          id: 0,
        },
        "Mortgage Broker": {
          active: false,
          id: 1,
        },
        "Buyers Agent": {
          active: false,
          id: 2,
        },
        "Financial Planner": {
          active: false,
          id: 3,
        },
        Accountant: {
          active: false,
          id: 4,
        },
        "Solicitor/Conveyancer": {
          active: false,
          id: 5,
        },
      };

      /*Test Variable for locking feature.*/
      $scope.locked = false;

      /*Check if lending needs is turned on*/
      $scope.suitabilityEnabled = false;

      // used to check if partner has associated loans, properties and other assets
      $scope.partnerAssociatedItems = {
        hasProperties: false,
        hasLoans: false,
        hasBankAccounts: false,
        hasVehicles: false,
        hasInvestments: false,
        hasLifeInsurance: false,
        hasOtherAssets: false,
        hasSuperFunds: false,
      };

      // Partner activate login
      $scope.isPartnerAccountActivated = false;
      $scope.activatedPartnerDetails = {};

      $scope.showBottomButtons = false;
      let toCompare = null;
      $scope.initialPersonalInfoTPC = null;
      $scope.showChangesAlert = false;
      $scope.toStateName = "";
      $scope.isSaving = false;

      $scope.closeChangesAlert = function () {
        $scope.newLocation = "";
        $scope.showChangesAlert = false;
        $scope.isSaving = false;
      };

      $scope.dashboardDiscard = async function () {
        $scope.showClientSpinner = true;
        $scope.loadingMessage = "Discarding Client Changes...";
        if ($scope.showChangesAlert) {
          $scope.showChangesAlert = false;
        }

        var promise = new Promise(function (resolve, reject) {
          $timeout(function () {
            $scope.setInputValues(true);
            resolve("done");
          }, 1);
        });

        let result = await promise;
        if (result === "done") {
          $scope.showClientSpinner = false;
          if ($scope.isSaving && $scope.toStateName !== "") {
            $state.go($scope.toStateName, {
              clientId: $scope.clientId,
            });
          } else {
            $scope.showBottomButtons = false;
            $rootScope.setAlertValues(
              true,
              true,
              "Successfully discarded changes!",
              true
            );
          }
        }
      };

      // remove phone number space
      $scope.removePhoneNumberSpace = function (client, phoneType, value) {
        if (value !== "") {
          client[phoneType] = $scope.empowerData.removeSpace(client[phoneType]);
        }
      };
      $scope.isInvalidForFlex = function (object) {
        return DataHelper.isObjectInvalid(object) && $scope.suitabilityEnabled;
      };

      // Check if partner's account is activated
      DataM.getPartnerActivated()
        .then(function (data) {
          $scope.isPartnerAccountActivated = _.get(data, "isActivated", false);
        })
        .catch(function (err) {
          console.log("Retrieve current user error");
        });

      function getSuitabilitySetting() {
        DataM.getSuitabilitySetting({ uid: $stateParams.clientId })
          .then(function (settingData) {
            $scope.suitabilityEnabled = false;
            if (settingData.suitabilityEnabled === true) {
              $scope.suitabilityEnabled = true;
            }
          })
          .catch(function (err) {
            console.log(err);
          });
      }
      $scope.addressHistory = function (client) {
        client.totalAddressYears = client.yearsAtAddress;

        if (client.totalAddressYears >= 3) {
          client.previousAddress = [];
        } else {
          for (var $i = 0; $i < client.previousAddress.length; $i++) {
            if (client.previousAddress[$i].hasOwnProperty("yearsAtAddress")) {
              client.totalAddressYears +=
                client.previousAddress[$i].yearsAtAddress;
            }
          }

          if (client.totalAddressYears < 3) {
            if (client.previousAddress.length > 0) {
              if (
                client.previousAddress[
                  client.previousAddress.length - 1
                ].hasOwnProperty("yearsAtAddress")
              ) {
                if (
                  client.previousAddress[client.previousAddress.length - 1]
                    .yearsAtAddress !== null
                ) {
                  client.previousAddress.push({
                    address: {
                      country: "Australia",
                    },
                  });
                }
              }
            } else {
              client.previousAddress.push({
                address: {
                  country: "Australia",
                },
              });
            }
          } else {
            if (client.previousAddress.length > 0) {
              if (
                !client.previousAddress[
                  client.previousAddress.length - 1
                ].hasOwnProperty("yearsAtAddress")
              ) {
                client.previousAddress.pop();
              }
            }
          }
        }
      };

      $scope.convertInt = function (number) {
        return angular.isNumber(number);
      };

      $scope.redirectPage = function () {
        $window.location.href = "#/fact-find-2.html";
      };

      $scope.addNewCurrentAddress = function (client) {
        var pushToPreviousAddress = {
          address: client.address,
          startDate: client.startDate,
          startDatePicker: client.startDatePicker,
          tenure: client.tenure,
          isTravel: client.previousAddress.isTravel || false,
        };
        client.previousAddress.unshift(pushToPreviousAddress);

        client.address = {
          country: "Australia",
        };
        client.tenure = null;
        client.startDate = null;
        client.startDatePicker = null;
        client.yearsAtAddress = null;
      };

      $scope.addNewPreviousAddress = function (client) {
        var pushToPreviousAddress = {
          address: {
            country: "Australia",
          },
          startDate: null,
          startDatePicker: null,
          tenure: null,
          isTravel: client.previousAddress.isTravel || false,
        };

        client.previousAddress.push(pushToPreviousAddress);
      };

      $scope.addNewHouseholdClient = function () {
        const defaultYear = [1980, 0, 1, 0, 0, 0];
        var birthdate = moment.utc(defaultYear);
        var dobDatePicker =
          empowerDataService.generatedLocalDateString(birthdate);

        var client = {
          previousAddress: [],
          dobDatePicker: dobDatePicker,
        };
        $scope.householdClients.push(client);
        toCompare.personalInfo.client2 = client;
      };

      $scope.removePreviousAddress = function (client, previous) {
        client.previousAddress.splice(
          client.previousAddress.indexOf(previous),
          1
        );
      };

      $scope.calcPreviousAddressHistory = function (client, clientIndex) {
        var totalDays = 0;
        var oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds

        totalDays = 0;
        var firstDate = moment.utc(client.startDatePicker, ["DD/MM/YYYY"]);
        var todayDate = moment.utc();
        var diffDays = Math.round(
          Math.abs((firstDate.valueOf() - todayDate.valueOf()) / oneDay)
        );
        totalDays += diffDays;

        /**
         * Check if one of the employment is less than 3 years
         * If all are not less than 3 years show the previous employment
         */
        if (totalDays / 365 < 3) {
          switch (clientIndex) {
            case 1:
              $scope.threeYearsHistoryBoolClient1 = false;
              if (
                !client.previousAddress ||
                client.previousAddress.length == 0
              ) {
                client.previousAddress = [
                  {
                    address: {
                      country: "Australia",
                    },
                    isTravel: false,
                  },
                ];
              }
              break;
            case 2:
              $scope.threeYearsHistoryBoolClient2 = false;
              if (
                !client.previousAddress ||
                client.previousAddress.length == 0
              ) {
                client.previousAddress = [
                  {
                    address: {
                      country: "Australia",
                    },
                    isTravel: false,
                  },
                ];
              }
              break;
          }
        } else if (totalDays / 365 >= 3) {
          switch (clientIndex) {
            case 1:
              $scope.threeYearsHistoryBoolClient1 = true;
              if (
                client.previousAddress &&
                client.previousAddress.length == 1 &&
                !client.previousAddress[0].startDate
              ) {
                client.previousAddress = [];
              }
              break;
            case 2:
              $scope.threeYearsHistoryBoolClient2 = true;
              if (
                client.previousAddress &&
                client.previousAddress.length == 1 &&
                !client.previousAddress[0].startDate
              ) {
                client.previousAddress = [];
              }
              break;
          }
        } else if (!isNaN(totalDays)) {
          switch (clientIndex) {
            case 1:
              $scope.threeYearsHistoryBoolClient1 = true;
              break;
            case 2:
              $scope.threeYearsHistoryBoolClient2 = true;
              break;
          }
        }
      };
      // $scope.deleteHouseholdClient = function (client) {
      //   $scope.householdClients.splice(1, 1);
      //   $scope.secondHousehold = {};
      //   $scope.sameHouseBool = 'True';
      // };
      $scope.isMBAdmin = false;
      // check if user is admin
      DataM.getMe()
        .then(function (userData) {
          if (userData.role !== "user" && userData.role !== "guest") {
            $scope.isUserAdmin = true;
            if (
              userData.role === "super_admin" ||
              userData.role === "mb_admin"
            ) {
              $scope.isMBAdmin = true;
            }
            $scope.isAdmin = true;
          } else {
            $scope.isUserAdmin = false;
            $scope.isAdmin = false;
          }
        })
        .catch(function (err) {
          console.log("Retrieve current user error");
        });

      $scope.showRemovePartnerModal = function () {
        $scope.showClientSpinner = true;
        DataM.getSummary()
          .then(function (data) {
            $scope.showClientSpinner = false;
            setPartnerAssociatedItems(
              data.clientSummary,
              $scope.householdClients[1]._id
            );
            $scope.isConfirmRemovePartnerShown = true;
          })
          .catch((err) => {
            $scope.showClientSpinner = false;
          });
      };
      $scope.deleteHouseholdClient = function () {
        $scope.isConfirmRemovePartnerShown = false;
        $scope.householdClients.splice(1, 1);
        $scope.secondHousehold = {};
        delete toCompare.personalInfo.client2;
      };

      // Return True if has assets and loans
      // False otherwise
      var setPartnerAssociatedItems = function (data, clientId) {
        var clientData = data;
        if (clientData.personalInfo.client2) {
          var client2Id = clientData.personalInfo.client2._id;
        } else {
          return false;
        }
        // check in Assets
        if (clientData.assets) {
          var assets = clientData.assets;
          for (var key in assets) {
            if (assets.hasOwnProperty(key)) {
              if (assets[key]) {
                if (assets[key].constructor === Array) {
                  for (var i = 0; i < assets[key].length; i++) {
                    var asset = assets[key][i];

                    if (asset.ownership && asset.ownership.owners) {
                      for (var j = 0; j < asset.ownership.owners.length; j++) {
                        var ownerDetail = asset.ownership.owners[j];
                        if (ownerDetail.owner === client2Id) {
                          switch (key) {
                            case "properties":
                              $scope.partnerAssociatedItems.hasProperties = true;
                              break;
                            case "bankAccounts":
                              $scope.partnerAssociatedItems.hasBankAccounts = true;
                              break;
                            case "vehicles":
                              $scope.partnerAssociatedItems.hasVehicles = true;
                              break;
                            case "lifeInsurance":
                              $scope.partnerAssociatedItems.hasLifeInsurance = true;
                              break;
                            case "superFunds":
                              $scope.partnerAssociatedItems.hasSuperFunds = true;
                              break;
                            case "investments":
                              $scope.partnerAssociatedItems.hasInvestments = true;
                              break;
                            case "otherAssets":
                              $scope.partnerAssociatedItems.hasOtherAssets = true;
                              break;
                            default:
                              break;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }

        // check in Borrowings
        if (clientData.borrowings) {
          var borrowings = clientData.borrowings;
          for (var key in borrowings) {
            if (borrowings.hasOwnProperty(key)) {
              if (borrowings[key]) {
                if (borrowings[key].constructor === Array) {
                  for (var i = 0; i < borrowings[key].length; i++) {
                    var borrowing = borrowings[key][i];

                    if (borrowing.ownership && borrowing.ownership.owners) {
                      for (var j = 0; j < asset.ownership.owners.length; j++) {
                        var ownerDetail = asset.ownership.owners[j];
                        if (ownerDetail.owner === client2Id) {
                          $scope.partnerAssociatedItems.hasLoans = true;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        return false;
      };

      $scope.addNewDependancy = function () {
        const defaultYear = [1980, 0, 1, 0, 0, 0];
        var birthdate = moment.utc(defaultYear);
        var dobDatePicker =
          empowerDataService.generatedLocalDateString(birthdate);

        var dependant = {
          dobDatePicker: dobDatePicker,
        };
        $scope.dependants.push(dependant);
      };

      $scope.deleteDependancy = function (dependant) {
        $scope.dependants.splice($scope.dependants.indexOf(dependant), 1);
      };

      $scope.cohabiting = function (householdClients) {
        householdClients[1].cohabiting = householdClients[0].cohabiting;
        if (householdClients[0].cohabiting === "true") {
          householdClients[1].address = householdClients[0].address;
          householdClients[1].yearsAtAddress =
            householdClients[0].yearsAtAddress;
        } else {
          delete householdClients[1].address;
          delete householdClients[1].yearsAtAddress;
          delete householdClients[1].startDatePicker;

          if (householdClients[1].previousAddress.length === 0) {
            householdClients[1].previousAddress = [];
            if (typeof $scope.householdClients[1].address === "undefined") {
              $scope.householdClients[1].address = {};
              $scope.householdClients[1].address.country = "Australia";
            }
          }
        }
      };

      $scope.ageChanged = function (client) {
        client.ageEnteredOn = moment.utc();
      };

      $scope.setDOB = function (x) {
        x.dob = moment.utc(x.dobDatePicker, ["DD/MM/YYYY"]);
      };

      $scope.setDate = function (currentAddress, type) {
        if (type === "Start") {
          currentAddress.startDate = moment.utc(
            currentAddress.startDatePicker,
            ["DD/MM/YYYY"]
          );
          currentAddress.yearsAtAddress = Math.abs(
            currentAddress.startDate.year() - moment.utc().year()
          );
        }
        if (type === "End") {
          currentAddress.endDate = moment.utc(currentAddress.endDatePicker, [
            "DD/MM/YYYY",
          ]);
        }
      };

      $scope.emptyClient = function (x) {
        return Object.keys(x).every(function (y) {
          return (
            x[y] === "" || x[y] === null || x[y] === false || x[y].length === 0
          );
        });
      };

      $scope.marriageCheck = function (x) {
        if (x.hasOwnProperty("marital")) {
          if (x.marital === "Married" || x.marital === "Defacto") {
            if ($scope.householdClients.length < 2) {
              $scope.addNewHouseholdClient();
            }
          }
        }
      };
      $scope.goToLocation = function (location) {
        var proceed = $scope.empowerData.goToLocation(location, $scope);

        if (proceed) {
          $state.go(location);
        }
      };

      var removePartnerData = function (clientSchema) {
        var client1ID = clientSchema.personalInfo.client1._id;

        if ($scope.householdClients.length < 2) {
          // remove client 2 income
          if (clientSchema.income) {
            if (clientSchema.income.client2PersonalIncome) {
              clientSchema.income.client2PersonalIncome = null;
            }
          }

          // re assign ownership
          if (clientSchema.assets) {
            var assets = clientSchema.assets;
            for (var key in assets) {
              if (assets.hasOwnProperty(key)) {
                if (assets[key]) {
                  if (assets[key].constructor === Array) {
                    for (var i = 0; i < assets[key].length; i++) {
                      var asset = assets[key][i];

                      if (asset.ownership) {
                        if (asset.ownership.ownershipType == "Joint") {
                          asset.ownership.ownershipType = "Sole";
                          asset.ownership.owners = [
                            {
                              owner: client1ID,
                              percentage: 100,
                            },
                          ];
                        } else {
                          if (asset.ownership.owners) {
                            if (asset.ownership.owners.length > 0) {
                              asset.ownership.owners[0].owner = client1ID;
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          if (clientSchema.borrowings) {
            var borrowings = clientSchema.borrowings;
            for (var key in borrowings) {
              if (borrowings.hasOwnProperty(key)) {
                if (borrowings[key]) {
                  if (borrowings[key].constructor === Array) {
                    for (var i = 0; i < borrowings[key].length; i++) {
                      var borrowing = borrowings[key][i];

                      if (borrowing.borrower) {
                        if (borrowing.borrower.ownershipType == "Joint") {
                          borrowing.borrower.ownershipType = "Sole";
                          borrowing.borrower.owners = [
                            {
                              owner: client1ID,
                              percentage: 100,
                            },
                          ];
                        } else {
                          if (borrowing.borrower.owners) {
                            if (borrowing.borrower.owners.length > 0) {
                              borrowing.borrower.owners[0].owner = client1ID;
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          if (clientSchema.expenses) {
            var expenses = clientSchema.expenses;
            for (var key in expenses) {
              if (expenses.hasOwnProperty(key)) {
                if (expenses[key]) {
                  if (expenses[key].constructor === Array) {
                    for (var i = 0; i < expenses[key].length; i++) {
                      var expense = expenses[key][i];

                      if (expense.ownership) {
                        if (expense.ownership.ownershipType == "Joint") {
                          expense.ownership.ownershipType = "Sole";
                          expense.ownership.owners = [
                            {
                              owner: client1ID,
                              percentage: 100,
                            },
                          ];
                        } else {
                          if (expense.ownership.owners) {
                            if (expense.ownership.owners.length > 0) {
                              expense.ownership.owners[0].owner = client1ID;
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }

        return clientSchema;
      };

      $scope.assignValues = function (data) {
        $scope.isPersonalInfoCompleted = data.isPersonalInfoCompleted;
        $scope.isIncomeCompleted = data.isIncomeCompleted;
        $scope.isPropertyCompleted = data.isPropertyCompleted;
        $scope.isNonPropertyCompleted = data.isNonPropertyCompleted;
        $scope.isBorrowingCompleted = data.isBorrowingCompleted;
        $scope.isBillPaymentsCompleted = data.isBillPaymentsCompleted;
        $scope.isSpendingCompleted = data.isSpendingCompleted;
        $scope.isFPlanHouseholdCompleted = data.isFPlanHouseholdCompleted;
        $scope.isFPlanIncomeCompleted = data.isFPlanIncomeCompleted;

        getSuitabilitySetting();
        if (
          window.APP_UPDATED_PERSONAL_INFO_PAGE &&
          $state.current.name == "personalinfo"
        ) {
          window.APP_UPDATED_PERSONAL_INFO_PAGE(data);
        } else {
          console.error("Failed to find window.APP_UPDATED_PERSONAL_INFO_PAGE");
        }
        if (data.legacyId) {
          $scope.legacyId = data.legacyId;
        }
        if (data.personalInfo.client1) {
          $scope.householdClients[0] = data.personalInfo.client1;
          if (typeof $scope.householdClients[0].address === "undefined") {
            $scope.householdClients[0].address = {};
            $scope.householdClients[0].address.country = "Australia";
          } else {
            if (!$scope.householdClients[0].address.country) {
              $scope.householdClients[0].address.country = "Australia";
            }
          }
        }
        if (data.personalInfo.client2) {
          $scope.householdClients[1] = data.personalInfo.client2;

          if (typeof $scope.householdClients[1].address === "undefined") {
            $scope.householdClients[1].address = {};
            $scope.householdClients[1].address.country = "Australia";
          }
        }

        $scope.dependants = data.personalInfo.dependants;
        //$scope.isReviewed = data.personalInfo.isReviewed;
        // $scope.addlAdvisorInfo = data.personalInfo.addlAdvisorInfo;

        for (var $i = 0; $i < $scope.householdClients.length; $i++) {
          if (!$scope.householdClients[$i].hasOwnProperty("cohabiting")) {
            $scope.householdClients[$i].cohabiting = "";
          }

          if ($scope.householdClients[$i].hasOwnProperty("startDate")) {
            $scope.householdClients[$i].startDatePicker =
              empowerDataService.generatedLocalDateString(
                $scope.householdClients[$i].startDate
              );
            $scope.householdClients[$i].startDate =
              empowerDataService.generateUTCDate(
                $scope.householdClients[$i].startDate
              );
          }
          if ($scope.householdClients[$i].hasOwnProperty("previousAddress")) {
            for (
              var $j = 0;
              $j < $scope.householdClients[$i].previousAddress.length;
              $j++
            ) {
              $scope.householdClients[$i].previousAddress[$j].startDate =
                empowerDataService.generateUTCDate(
                  $scope.householdClients[$i].previousAddress[$j].startDate
                );
              $scope.householdClients[$i].previousAddress[$j].startDatePicker =
                empowerDataService.generatedLocalDateString(
                  $scope.householdClients[$i].previousAddress[$j].startDate
                );

              $scope.householdClients[$i].previousAddress[$j].endDate =
                empowerDataService.generateUTCDate(
                  $scope.householdClients[$i].previousAddress[$j].endDate
                );
              $scope.householdClients[$i].previousAddress[$j].endDatePicker =
                empowerDataService.generatedLocalDateString(
                  $scope.householdClients[$i].previousAddress[$j].endDate
                );
              $scope.householdClients[$i].previousAddress[$j].isTravel =
                $scope.householdClients[$i].previousAddress[$j].isTravel ||
                false;
            }
          }
        }

        for (var $i = 0; $i < $scope.dependants.length; $i++) {
          if ($scope.dependants[$i].hasOwnProperty("dob")) {
            const defaultYear = [1980, 0, 1, 0, 0, 0];
            var birthdate = $scope.dependants[$i].dob
              ? $scope.dependants[$i].dob
              : moment.utc(defaultYear);

            $scope.dependants[$i].dobDatePicker =
              empowerDataService.generatedLocalDateString(birthdate);
            $scope.dependants[$i].dob =
              empowerDataService.generateUTCDate(birthdate);
          }
        }
        if (data.personalInfo.thirdParties) {
          for (var x in data.personalInfo.thirdParties) {
            // O: Property Investment Advisor
            // 1: Mortgage Broker
            // 2: Buyers Agent
            // 3: Financial Planner
            // 4: Accountant
            // 5: Solicitor/Conveyancer
            if (data.personalInfo.thirdParties[x].id === 0) {
              $scope.thirdParties["Property Investment Advisor"] =
                data.personalInfo.thirdParties[x];
              $scope.thirdParties["Property Investment Advisor"].active = true;
            } else if (data.personalInfo.thirdParties[x].id === 1) {
              $scope.thirdParties["Mortgage Broker"] =
                data.personalInfo.thirdParties[x];
              $scope.thirdParties["Mortgage Broker"].active = true;
            } else if (data.personalInfo.thirdParties[x].id === 2) {
              $scope.thirdParties["Buyers Agent"] =
                data.personalInfo.thirdParties[x];
              $scope.thirdParties["Buyers Agent"].active = true;
            } else if (data.personalInfo.thirdParties[x].id === 3) {
              $scope.thirdParties["Financial Planner"] =
                data.personalInfo.thirdParties[x];
              $scope.thirdParties["Financial Planner"].active = true;
            } else if (data.personalInfo.thirdParties[x].id === 4) {
              $scope.thirdParties["Accountant"] =
                data.personalInfo.thirdParties[x];
              $scope.thirdParties["Accountant"].active = true;
            } else if (data.personalInfo.thirdParties[x].id === 5) {
              $scope.thirdParties["Solicitor/Conveyancer"] =
                data.personalInfo.thirdParties[x];
              $scope.thirdParties["Solicitor/Conveyancer"].active = true;
            }
          }
        }
        $scope.changeBottomButtonState();
      };

      $scope.setInputValues = function (isDiscard) {
        $scope.householdClients = [];
        $scope.dependants = [];
        $scope.properties = [];

        if (!isDiscard) {
          DataM.getPersonalInfo()
            .then(function (data) {
              if (_.isNil(data.personalInfo)) {
                data.personalInfo = {
                  client1: {
                    fName: null,
                    email: null,
                    mPhone: null,
                    flexId: null,
                    address: {
                      postcode: null,
                      state: null,
                      country: null,
                    },
                  },
                  client2: {
                    fName: null,
                    email: null,
                    mPhone: null,
                  },
                  dependants: [],
                  thirdParties: [],
                };
              }
              if (_.isNil(data.properties)) {
                data.properties = [];
              }
              toCompare = data;
              var promise = new Promise(function (resolve, reject) {
                $scope.assignValues(data);
                resolve("done");
              });

              promise.then(function (data) {
                if (data === "done") {
                  $scope.showClientSpinner = false;
                }
              });
            })
            .catch(function (err) {
              console.log("Retrieve error", err);
              $scope.showRetrieveError = true;
            });
        } else {
          var data = JSON.parse($scope.initialPersonalInfoTPC);
          toCompare = data;
          $scope.assignValues(data);
        }
      };

      $scope.setInputValues(false);
      $scope.changeBottomButtonState = function () {
        if ($scope.initialPersonalInfoTPC !== null && toCompare !== null) {
          var newToCompare = JSON.stringify(toCompare, function (key, value) {
            if (key === "$$hashKey") {
              return undefined;
            }
            if (key === "$promise") {
              return undefined;
            }
            if (key === "$resolved") {
              return undefined;
            }
            return value;
          });
          if ($scope.initialPersonalInfoTPC !== newToCompare) {
            $scope.showBottomButtons = true;
          } else {
            $scope.showBottomButtons = false;
          }
        } else if (
          $scope.initialPersonalInfoTPC === null &&
          toCompare !== null
        ) {
          $scope.initialPersonalInfoTPC = JSON.stringify(
            toCompare,
            function (key, value) {
              if (key === "$$hashKey") {
                return undefined;
              }
              if (key === "$promise") {
                return undefined;
              }
              if (key === "$resolved") {
                return undefined;
              }
              return value;
            }
          );
        }
      };

      $scope.$watch(
        "[householdClients, dependants]",
        function () {
          $scope.changeBottomButtonState();
        },
        true
      );

      $scope.save = function (location) {
        if ($scope.showChangesAlert) {
          $scope.showChangesAlert = false;
        }
        $scope.showClientSpinner = true;
        $scope.loadingMessage = "Saving Client Information...";

        if (
          $scope.householdClients[0].tenure === $scope.tenureStatuses[0] ||
          $scope.householdClients[0].tenure === $scope.tenureStatuses[1]
        ) {
          var $prop = {
            purpose: "Own Home",
          };
          $prop.address = $scope.householdClients[0].address;
          $prop.ownership = {
            ownershipType: "Sole",
          };
          var owner = {
            owner: $scope.householdClients[0]._id,
            percentage: 100,
          };
          $prop.ownership.owners = [];
          $prop.ownership.owners.push(owner);
          // if there is already a property with the same address, don't add this home to the array
          if ($scope.properties != null) {
            var found = false;
            for (var $i = 0; $i < $scope.properties.length; $i++) {
              if (
                empowerDataService.generateAddressFull($prop.address) ===
                empowerDataService.generateAddressFull(
                  $scope.properties[$i].address
                )
              ) {
                found = true;
              }
            }
            if (found === false) {
              $scope.properties.unshift($prop);
            }
          } else {
            $scope.properties = [];
            $scope.properties.unshift($prop);
          }
        }
        if ($scope.householdClients.length > 1) {
          if (
            !$scope.householdClients[1].cohabiting &&
            ($scope.householdClients[1].tenure === $scope.tenureStatuses[0] ||
              $scope.householdClients[1].tenure === $scope.tenureStatuses[1])
          ) {
            var $prop2 = {
              purpose: "Own Home",
            };

            $prop2.address = $scope.householdClients[1].address;
            $prop2.ownership = { ownershipType: "Sole" };
            $prop2.ownership.owners = [];

            // if there is already a property with the same address, don't add this home to the array
            if ($scope.properties != null) {
              var found = false;
              for (var $i = 0; $i < $scope.properties.length; $i++) {
                if (
                  empowerDataService.generateAddressFull($prop2.address) ===
                  empowerDataService.generateAddressFull(
                    $scope.properties[$i].address
                  )
                ) {
                  found = true;
                }
              }
              if (found === false) {
                $scope.properties.unshift($prop2);
              }
            } else {
              $scope.properties = [];
              $scope.properties.unshift($prop2);
            }
          }
        }

        var professionalAdvisory = [];

        // Deal with third Parties:
        for (var x in $scope.thirdParties) {
          if ($scope.thirdParties[x].active === true) {
            professionalAdvisory.push($scope.thirdParties[x]);
          }
        }

        var formData = {};
        // create variable in same format as clientSchema
        if ($scope.householdClients.length == 2) {
          formData = {
            personalInfo: {
              client1: $scope.householdClients[0],
              client2: $scope.householdClients[1],
              dependants: $scope.dependants,
              thirdParties: professionalAdvisory,
              // 'isReviewed': $scope.isReviewed,
              // 'addlAdvisorInfo': $scope.addlAdvisorInfo
            },
            properties: $scope.properties,
          };
        } else {
          formData = {
            personalInfo: {
              client1: $scope.householdClients[0],
              dependants: $scope.dependants,
              thirdParties: professionalAdvisory,
              // 'isReviewed': $scope.isReviewed,
              // 'addlAdvisorInfo': $scope.addlAdvisorInfo
            },
            properties: $scope.properties,
          };
        }

        //SAVE TO DB
        DataM.savePersonalInfo(formData)
          .then(function (data) {
            if (data._message === "Client validation failed") {
              var errorMessage = "";
              const formattedMessage = DataHelper.formatMongoError(data);
              $scope.showClientSpinner = false;

              $scope.didNextSave = false;
              alert(formattedMessage);
            } else {
              if ($scope.householdClients.length < 2) {
                DataM.getSummary()
                  .then(function (data) {
                    var clientSchema = removePartnerData(data.clientSummary);
                    var formData = {
                      clientSchema: clientSchema,
                    };
                    //SAVE TO DB
                    DataM.saveSummary(formData)
                      .then(function (data) {})
                      .catch(function (err) {
                        console.log("Save error");
                        $scope.didNextSave = false;
                        $scope.showClientSpinner = false;
                      });
                  })
                  .catch(function (err) {
                    console.log("Save error");
                    $scope.didNextSave = false;
                    $scope.showClientSpinner = false;
                  });
              }
              if ($location.$$url.includes("personal-infotpc")) {
                if ($scope.isSaving && $scope.toStateName !== "") {
                  $state.go($scope.toStateName, {
                    clientId: $scope.clientId,
                  });
                } else {
                  $rootScope.setAlertValues(
                    true,
                    true,
                    "Successfully saved changes!",
                    true
                  );
                  $scope.initialPersonalInfoTPC = null;
                  $scope.initialPersonalInfoTPC = JSON.stringify(
                    toCompare,
                    function (key, value) {
                      if (key === "$$hashKey") {
                        return undefined;
                      }
                      if (key === "$promise") {
                        return undefined;
                      }
                      if (key === "$resolved") {
                        return undefined;
                      }
                      return value;
                    }
                  );
                  $scope.changeBottomButtonState();
                }
              } else {
                $state.go(location, {
                  clientId: $scope.clientId,
                });
              }

              $scope.showClientSpinner = false;
            }
            $rootScope.$broadcast("onSaved");
          })
          .catch(function (err) {
            console.log("test err", err);
            $rootScope.setAlertValues(
              true,
              false,
              "There is a problem accessing the server.",
              true
            );
            alert(
              "There is a problem accessing the server. Please make sure your internet connection is working. If it is working and this problem persist, please report this problem to Empower Wealth team."
            );
            $scope.showClientSpinner = false;
          });
      };

      $scope.$on(
        "$stateChangeStart",
        function (event, toState, toParams, fromState, fromParams) {
          if (_.isEqual(toState.name, "login")) {
          } else if (
            toState.url.indexOf("personal-infotpc") == -1 &&
            !$scope.isSaving &&
            !$scope.didNextSave
          ) {
            $scope.isSaving = true;
            $scope.toStateName = toState.name;
            event.preventDefault();
            if ($scope.showBottomButtons) {
              $scope.showChangesAlert = true;
            } else {
              $state.go(toState.name, {
                clientId: $scope.clientId,
              });
              return;
            }
          }
        }
      );

      $scope.next = function () {
        // check if form is valid
        if (
          $scope.forms.factfindform.$valid &&
          $scope.forms.factfindform.$dirty
        ) {
          $scope.showConfirmSaveModal("fact-find2");
        } else {
          if (!$scope.forms.factfindform.$dirty) {
            $state.go("fact-find2", {
              clientId: $scope.clientId,
            });
            return;
          }
        }
      };

      $scope.back = function () {
        // check if form is valid
        if ($scope.forms.factfindform.$valid) {
          $scope.showConfirmSaveModal("fact-find0");
        } else {
        }
      };
      $scope.quit = function () {
        $state.go("dashboard");
      };

      $scope.savedText = "Save";
      $scope.dashboardSave = function () {
        $scope.save("personalinfotpc");
      };

      $scope.showAlertValidationModal = function () {
        $scope.alertMessage = $scope.empowerData.saveValidationMessage;
        $scope.showAlertModal = true;
      };
      $scope.closeModal = function () {
        $scope.showAlertModal = false;
        $scope.showRemoveClientModal = false;
        $scope.isConfirmRemovePartnerShown = false;
        if ($scope.elementToFocus) {
          $timeout($scope.elementToFocus.focus(), 1500);
        }
      };
      $scope.showConfirmSaveModal = function (page) {
        if (!$scope.didNextSave) {
          //$scope.toShowConfirmSaveModal = true;
          $scope.nextPageLocation = page;
        }
      };
      $scope.goNextAndNotSaveModal = function () {
        //$scope.toShowConfirmSaveModal = false;
        $state.go($scope.nextPageLocation, {
          clientId: $scope.clientId,
        });
      };
      $scope.goNextAndSaveModal = function () {
        //$scope.toShowConfirmSaveModal = false;
        $scope.didNextSave = true;
        $scope.save($scope.nextPageLocation);
      };
      $scope.closeConfirmModal = function () {
        //$scope.toShowConfirmSaveModal = false;
        $scope.isConfirmRemovePartnerShown = false;
        $scope.savedText = "Save";
        $scope.isSaving = false;
      };

      $scope.openCreatePartnerResult = function () {
        var modalInstance = $uibModal.open({
          animation: $scope.animationsEnabled,
          templateUrl: "app/settings/activate_partner_result.html",
          controller: "ModalInstanceCtrl",
          resolve: {
            result: function () {
              return $scope.activatedPartnerDetails;
            },
          },
        });
      };
      $scope.openCreatePartner = function () {
        var modalInstance = $uibModal.open({
          animation: $scope.animationsEnabled,
          templateUrl: "app/settings/activate_partner.html",
          controller: "ModalInstanceCtrl",
          resolve: {
            result: function () {
              return {};
            },
          },
        });

        modalInstance.result.then(
          function (result) {
            if (result.activate == true) {
              // activate
              $scope.activatePartner();
            }
          },
          function () {
            // if cancelled
          }
        );
      };

      $scope.activatePartner = function () {
        $scope.showSpinner = true;
        let uid = $scope.clientId;
        let partnerEmail = $scope.householdClients[1].email;

        if (partnerEmail) {
          let payload = {
            email: partnerEmail,
          };
          if (uid) {
            payload.partnerUID = uid;
          }
          DataM.createPartner(payload).then(function (data) {
            $scope.showSpinner = false;
            $scope.activatedPartnerDetails = data;
            $scope.activatedPartnerDetails.errorMessage = null;
            if (data.success == false) {
              let errorMessage = _.get(
                data,
                "message",
                "Unable to create partner account."
              );
              $scope.activatedPartnerDetails.errorMessage = errorMessage;
            } else {
              $scope.isPartnerAccountActivated = true;
            }
            // open result modal
            $scope.openCreatePartnerResult();
          });

          $scope.showSpinner = false;
        } else {
          $scope.showSpinner = false;
        }
      };
    }
  )

  .controller(
    "factfind-2-controller",
    function (
      $scope,
      $rootScope,
      $location,
      $state,
      $stateParams,
      DataM,
      Auth,
      empowerDataService,
      EmpowerWealthHelper,
      $interval,
      $timeout,
      DataHelper,
      $document,
      $window
    ) {
      "use strict";
      $scope.isAdmin = false;
      Auth.isAdmin().then((isAdmin) => ($scope.isAdmin = isAdmin));
      $scope.showNotificationIncome = false;
      $scope.client1Employment = [];
      $scope.client2Employment = [];
      $scope.reqDataCR = {};
      $scope.reqDataMB = {};
      $scope.reqDataLoaded = false;
      $scope.MBChecker = false;
      $scope.showBottomButtons = false;
      let toCompare = null;
      $scope.initialIncome = null;
      $scope.showChangesAlert = false;
      $scope.toStateName = "";

      $scope.isEmployerAddressEditMode = false;

      $scope.isUserNewlyCreated = false;

      // Industry Scopes
      $scope.industryDivisions = [];
      $scope.industryClasses = [];
      $scope.searchResults = [];
      $scope.industryClassesComplete = [];
      $scope.industryDivisionCode = "";
      $scope.isManualIndustry = false;
      $scope.isIndustryValid = false;
      $scope.showDropdown = false;

      const getIndustryDivisions = () => {
        DataM.getIndustryDivisions()
          .then(function (data) {
            console.log(data, "data");
            $scope.industryDivisions = data.divisions;
          })
          .catch(function (err) {
            console.log(err);
          });
      };
      getIndustryDivisions();

      const getIndustryClasses = () => {
        DataM.getIndustryClasses()
          .then(function (data) {
            $scope.industryClasses = data.classes;
            $scope.industryClassesComplete = data.classes;
            $scope.searchResults = data.classes;
          })
          .catch(function (err) {
            console.log(err);
          });
      };
      getIndustryClasses();

      // Function to set to manual picking of industry
      $scope.switchToManualIndustry = (isManual) => {
        $scope.isManualIndustry = isManual;
        $scope.industryClasses = $scope.industryClassesComplete;
      };

      // Listen to industryFormatted change
      $scope.onIndustryFormattedChange = (index, type) => {
        if (type === "current") {
          $scope.runSearch(
            $scope.client1Employment[index].job.employer.industry.industryClass
          );
        } else if (type === "current-client2") {
          $scope.runSearch(
            $scope.client2Employment[index].job.employer.industry.industryClass
          );
        } else if (type === "past-client2") {
          $scope.runSearch(
            $scope.client2PreviousEmployment[index].job.employer.industry
              .industryClass
          );
        } else {
          $scope.runSearch(
            $scope.client1PreviousEmployment[index].job.employer.industry
              .industryClass
          );
        }
      };

      // Check if industry class is valid
      const checkValidIndustry = () => {
        $scope.isManualIndustry = false;
        $scope.client1Employment.forEach((item, index) => {
          if (item?.job?.employer?.industry?.industryClass) {
            const title =
              item.job.employer.industry.industryClass?.split(" - ")[1];
            if (title) {
              const filteredClass = $scope.industryClassesComplete.filter(
                (item) => item.title === title.trim()
              );
              if (title) {
                if (!filteredClass.length) {
                  $scope.client1Employment[
                    index
                  ].job.employer.industry.industryClass = "";
                  $scope.client1Employment[
                    index
                  ].job.employer.industry.industryDivision = "";
                }
              } else {
                $scope.client1Employment[
                  index
                ].job.employer.industry.industryClass = "";
                $scope.client1Employment[
                  index
                ].job.employer.industry.industryDivision = "";
              }
            } else {
              $scope.client1Employment[
                index
              ].job.employer.industry.industryClass = "";
              $scope.client1Employment[
                index
              ].job.employer.industry.industryDivision = "";
            }
          }
        });
        $scope.client2Employment.forEach((item, index) => {
          if (item?.job?.employer?.industry?.industryClass) {
            const title =
              item.job.employer.industry.industryClass?.split(" - ")[1];
            if (title) {
              const filteredClass = $scope.industryClassesComplete.filter(
                (item) => item.title === title.trim()
              );
              if (title) {
                if (!filteredClass.length) {
                  $scope.client2Employment[
                    index
                  ].job.employer.industry.industryClass = "";
                  $scope.client2Employment[
                    index
                  ].job.employer.industry.industryDivision = "";
                }
              } else {
                $scope.client2Employment[
                  index
                ].job.employer.industry.industryClass = "";
                $scope.client2Employment[
                  index
                ].job.employer.industry.industryDivision = "";
              }
            } else {
              $scope.client2Employment[
                index
              ].job.employer.industry.industryClass = "";
              $scope.client2Employment[
                index
              ].job.employer.industry.industryDivision = "";
            }
          }
        });
        $scope.client1PreviousEmployment.forEach((item, index) => {
          if (item?.job?.employer?.industry?.industryClass) {
            const title =
              item.job.employer.industry.industryClass?.split(" - ")[1];
            if (title) {
              const filteredClass = $scope.industryClassesComplete.filter(
                (item) => item.title === title.trim()
              );
              if (title) {
                if (!filteredClass.length) {
                  $scope.client1PreviousEmployment[
                    index
                  ].job.employer.industry.industryClass = "";
                  $scope.client1PreviousEmployment[
                    index
                  ].job.employer.industry.industryDivision = "";
                }
              } else {
                $scope.client1PreviousEmployment[
                  index
                ].job.employer.industry.industryClass = "";
                $scope.client1PreviousEmployment[
                  index
                ].job.employer.industry.industryDivision = "";
              }
            } else {
              $scope.client1PreviousEmployment[
                index
              ].job.employer.industry.industryClass = "";
              $scope.client1PreviousEmployment[
                index
              ].job.employer.industry.industryDivision = "";
            }
          }
        });
        $scope.client2PreviousEmployment.forEach((item, index) => {
          if (item?.job?.employer?.industry?.industryClass) {
            const title =
              item.job.employer.industry.industryClass?.split(" - ")[1];
            if (title) {
              const filteredClass = $scope.industryClassesComplete.filter(
                (item) => item.title === title.trim()
              );
              if (title) {
                if (!filteredClass.length) {
                  $scope.client2PreviousEmployment[
                    index
                  ].job.employer.industry.industryClass = "";
                  $scope.client2PreviousEmployment[
                    index
                  ].job.employer.industry.industryDivision = "";
                }
              } else {
                $scope.client2PreviousEmployment[
                  index
                ].job.employer.industry.industryClass = "";
                $scope.client2PreviousEmployment[
                  index
                ].job.employer.industry.industryDivision = "";
              }
            } else {
              $scope.client2PreviousEmployment[
                index
              ].job.employer.industry.industryClass = "";
              $scope.client2PreviousEmployment[
                index
              ].job.employer.industry.industryDivision = "";
            }
          }
        });
      };

      // Listen to division changes
      $scope.onDivisionChange = (index, type) => {
        let code = "";
        if (type === "current") {
          code =
            $scope?.client1Employment[
              index
            ]?.job?.employer?.industry?.industryDivision?.split(" - ")[0];
          $scope.client1Employment[index].job.employer.industry.industryClass =
            "";
        } else if (type === "current-client2") {
          code =
            $scope.client2Employment[
              index
            ]?.job?.employer?.industry?.industryDivision?.split(" - ")[0];
          $scope.client2Employment[index].job.employer.industry.industryClass =
            "";
        } else if (type === "past-client2") {
          code =
            $scope?.client2PreviousEmployment[
              index
            ]?.job?.employer?.industry?.industryDivision?.split(" - ")[0];
          $scope.client2PreviousEmployment[
            index
          ].job.employer.industry.industryClass = "";
        } else {
          code =
            $scope?.client1PreviousEmployment[
              index
            ]?.job?.employer?.industry?.industryDivision?.split(" - ")[0];
          $scope.client1PreviousEmployment[
            index
          ].job.employer.industry.industryClass = "";
        }
        $scope.industryDivisionCode = code?.trim();
      };

      // Listen to class changes
      $scope.onClassChange = (index) => {
        if (
          $scope.client1Employment[index]?.job?.employer?.industry
            ?.industryDivision
        ) {
          const code =
            $scope.client1Employment[
              index
            ].job.employer.industry.industryDivision.split(" - ")[0];
          const filterClasses = $scope.industryClassesComplete.filter(
            (item) => item.divisionType === code.trim()
          );
          if (filterClasses.length) {
            $scope.isIndustryValid = true;
          } else {
            $scope.isIndustryValid = false;
          }
        } else {
          $scope.isIndustryValid = false;
        }
      };

      // onfocus dropdown
      $scope.onFocusIndustryClass = (index, type) => {
        let code = "";
        if (type === "current") {
          code =
            $scope.client1Employment[
              index
            ].job.employer.industry.industryDivision.split(" - ")[0];
        } else if (type === "current-client2") {
          code =
            $scope.client2Employment[
              index
            ]?.job?.employer?.industry?.industryDivision?.split(" - ")[0];
        } else if (type === "past-client2") {
          code =
            $scope.client2PreviousEmployment[
              index
            ]?.job.employer?.industry?.industryDivision.split(" - ")[0];
        } else {
          code =
            $scope.client1PreviousEmployment[
              index
            ]?.job?.employer.industry?.industryDivision.split(" - ")[0];
        }
        $scope.industryDivisionCode = code.trim();
      };

      // Check if valid
      $scope.checkValidInput = (index, type) => {
        let title = "";
        if (type === "current") {
          if (
            $scope?.client1Employment[index]?.job?.employer?.industry
              ?.industryClass
          ) {
            title =
              $scope.client1Employment[
                index
              ].job.employer.industry.industryClass.split(" - ")[1];
          } else {
            return "emptyIndustry";
          }
        } else if (type === "current-client2") {
          if (
            $scope?.client2Employment[index]?.job?.employer?.industry
              ?.industryClass
          ) {
            title =
              $scope.client2Employment[
                index
              ].job.employer.industry.industryClass.split(" - ")[1];
          } else {
            return "emptyIndustry";
          }
        } else if (type === "past-client2") {
          if (
            $scope?.client2PreviousEmployment[index]?.job?.employer?.industry
              ?.industryClass
          ) {
            title =
              $scope.client1PreviousEmployment[
                index
              ].job.employer.industry.industryClass.split(" - ")[1];
          } else {
            return "emptyIndustry";
          }
        } else {
          if (
            $scope?.client1PreviousEmployment[index]?.job?.employer?.industry
              ?.industryClass
          ) {
            title =
              $scope.client1PreviousEmployment[
                index
              ].job.employer.industry.industryClass.split(" - ")[1];
          } else {
            return "emptyIndustry";
          }
        }

        if (title) {
          const filteredClass = $scope.industryClassesComplete.filter(
            (item) => item.title === title.trim()
          );
          if (title) {
            if (!filteredClass.length) {
              return "isNotIndustryValid";
            } else {
              return "isIndustryValid";
            }
          } else {
            return "isNotIndustryValid";
          }
        } else {
          return "isNotIndustryValid";
        }
      };

      $scope.toggleDropdown = (isShow) => {
        $scope.showDropdown = isShow;
      };

      $scope.runSearch = (text) => {
        if (text) {
          text = text.toLowerCase().split(" ");
          $scope.searchResults = $scope.industryClassesComplete.filter(
            function (item) {
              return text.every(function (el) {
                return item.title.toLowerCase().indexOf(el) > -1;
              });
            }
          );
        }
      };

      $scope.onPickIndustry = (industry, index) => {
        $scope.client1Employment[
          index
        ].job.employer.industry.industryClass = `${industry.code} - ${industry.title}`;
        if (industry.title) {
          const filteredClass = $scope.industryClassesComplete.filter(
            (item) => item.title === industry.title.trim()
          );
          if (filteredClass.length) {
            $scope.isIndustryValid = true;
            $scope.showDropdown = false;
            const filteredDivision = $scope.industryDivisions.filter(
              (item) => item.code === filteredClass[0].divisionType
            );
            if (filteredDivision.length) {
              $scope.client1Employment[
                index
              ].job.employer.industry.industryDivision = `${filteredDivision[0].code} - ${filteredDivision[0].label}`;
            } else {
              $scope.client1Employment[
                index
              ].job.employer.industry.industryDivision = "";
            }
          } else {
            $scope.isIndustryValid = false;
            $scope.client1Employment[
              index
            ].job.employer.industry.industryDivision = "";
            $scope.client1Employment[
              index
            ].job.employer.industry.industryClass = "";
          }
        }
      };

      $scope.onPickIndustryClient2 = (industry, index) => {
        $scope.client2Employment[
          index
        ].job.employer.industry.industryClass = `${industry.code} - ${industry.title}`;
        if (industry.title) {
          const filteredClass = $scope.industryClassesComplete.filter(
            (item) => item.title === industry.title.trim()
          );
          if (filteredClass.length) {
            $scope.isIndustryValid = true;
            $scope.showDropdown = false;
            const filteredDivision = $scope.industryDivisions.filter(
              (item) => item.code === filteredClass[0].divisionType
            );
            if (filteredDivision.length) {
              $scope.client2Employment[
                index
              ].job.employer.industry.industryDivision = `${filteredDivision[0].code} - ${filteredDivision[0].label}`;
            } else {
              $scope.client2Employment[
                index
              ].job.employer.industry.industryDivision = "";
            }
          } else {
            $scope.isIndustryValid = false;
            $scope.client2Employment[
              index
            ].job.employer.industry.industryDivision = "";
            $scope.client2Employment[
              index
            ].job.employer.industry.industryClass = "";
          }
        }
      };

      $scope.onPickIndustryPreviousClient2 = (industry, index) => {
        $scope.client2PreviousEmployment[
          index
        ].job.employer.industry.industryClass = `${industry.code} - ${industry.title}`;
        if (industry.title) {
          const filteredClass = $scope.industryClassesComplete.filter(
            (item) => item.title === industry.title.trim()
          );
          if (filteredClass.length) {
            $scope.isIndustryValid = true;
            $scope.showDropdown = false;
            const filteredDivision = $scope.industryDivisions.filter(
              (item) => item.code === filteredClass[0].divisionType
            );
            if (filteredDivision.length) {
              $scope.client2PreviousEmployment[
                index
              ].job.employer.industry.industryDivision = `${filteredDivision[0].code} - ${filteredDivision[0].label}`;
            } else {
              $scope.client2PreviousEmployment[
                index
              ].job.employer.industry.industryDivision = "";
            }
          } else {
            $scope.isIndustryValid = false;
            $scope.client2PreviousEmployment[
              index
            ].job.employer.industry.industryDivision = "";
            $scope.client2PreviousEmployment[
              index
            ].job.employer.industry.industryClass = "";
          }
        }
      };

      $scope.onPickIndustryPrevious = (industry, index) => {
        $scope.client1PreviousEmployment[
          index
        ].job.employer.industry.industryClass = `${industry.code} - ${industry.title}`;
        if (industry.title) {
          const filteredClass = $scope.industryClassesComplete.filter(
            (item) => item.title === industry.title.trim()
          );
          if (filteredClass.length) {
            $scope.isIndustryValid = true;
            $scope.showDropdown = false;
            const filteredDivision = $scope.industryDivisions.filter(
              (item) => item.code === filteredClass[0].divisionType
            );
            if (filteredDivision.length) {
              $scope.client1PreviousEmployment[
                index
              ].job.employer.industry.industryDivision = `${filteredDivision[0].code} - ${filteredDivision[0].label}`;
            } else {
              $scope.client1PreviousEmployment[
                index
              ].job.employer.industry.industryDivision = "";
            }
          } else {
            $scope.isIndustryValid = false;
            $scope.client1PreviousEmployment[
              index
            ].job.employer.industry.industryDivision = "";
            $scope.client1PreviousEmployment[
              index
            ].job.employer.industry.industryClass = "";
          }
        }
      };

      // linking address field functions to services
      $scope.addressOnClickHandler = (id, scope) => {
        switch (scope.addressType) {
          case "income":
          case "incomeSelfEmployed":
            EmpowerWealthHelper.addressFieldOnClickHandler(
              $scope.client1Employment,
              id,
              scope.addressType
            );
            break;
          case "client1PreviousEmployment":
            EmpowerWealthHelper.addressFieldOnClickHandler(
              $scope.client1PreviousEmployment,
              id,
              scope.addressType
            );
            break;
          case "incomeSecondary":
          case "client2Employment":
            EmpowerWealthHelper.addressFieldOnClickHandler(
              $scope.client2Employment,
              id,
              scope.addressType
            );
            break;
          case "client2PreviousEmployment":
            EmpowerWealthHelper.addressFieldOnClickHandler(
              $scope.client2PreviousEmployment,
              id,
              scope.addressType
            );
            break;
        }
      };
      $scope.switchToManual = (id, value, scope) => {
        switch (scope.addressType) {
          case "income":
          case "incomeSelfEmployed":
            EmpowerWealthHelper.addressSwitchToManual(
              $scope.client1Employment,
              id,
              value,
              scope.addressType
            );
            break;
          case "client1PreviousEmployment":
            EmpowerWealthHelper.addressSwitchToManual(
              $scope.client1PreviousEmployment,
              id,
              value,
              scope.addressType
            );
            break;
          case "incomeSecondary":
          case "client2Employment":
            EmpowerWealthHelper.addressSwitchToManual(
              $scope.client2Employment,
              id,
              value,
              scope.addressType
            );
            break;
          case "client2PreviousEmployment":
            EmpowerWealthHelper.addressSwitchToManual(
              $scope.client2PreviousEmployment,
              id,
              value,
              scope.addressType
            );
            break;
        }
      };
      $scope.updateAddressField = (address) => {
        EmpowerWealthHelper.updateAddressField(address);
      };

      $scope.$on("reqData", function (evt, reqData) {
        $scope.reqDataCR = reqData.cr;
        $scope.reqDataMB = reqData.mb;
        $scope.MBChecker = reqData.MBChecker;
        $scope.reqDataLoaded = true;
      });

      $scope.closeChangesAlert = function () {
        $scope.newLocation = "";
        $scope.showChangesAlert = false;
        $scope.isSaving = false;
      };

      $scope.dashboardDiscard = async function () {
        $scope.industryClasses = $scope.industryClassesComplete;
        $scope.isManualIndustry = false;
        $scope.showClientSpinner = true;
        $scope.loadingMessage = "Discarding Client Changes...";
        if ($scope.showChangesAlert) {
          $scope.showChangesAlert = false;
        }

        var promise = new Promise(function (resolve, reject) {
          $timeout(function () {
            $scope.setInputValues(true);
            resolve("done");
          }, 1);
        });

        let result = await promise;
        if (result === "done") {
          $scope.showClientSpinner = false;
          if ($scope.isSaving && $scope.toStateName !== "") {
            $state.go($scope.toStateName, {
              clientId: $scope.clientId,
            });
          } else {
            $scope.showBottomButtons = false;
            $rootScope.setAlertValues(
              true,
              true,
              "Successfully discarded changes!",
              true
            );
          }
        }
      };

      $scope.showClientSpinner = true;
      $scope.loadingMessage = "Retrieving Client Information...";
      $scope.showRetrieveError = false;

      /*Empower Data Service - For common datasets*/
      $scope.empowerData = empowerDataService;

      /*In the scenario where current employment is less than 3 years.*/
      $scope.client1PreviousEmployment = [];
      $scope.client2PreviousEmployment = [];

      $scope.client1CreditHistory = [];
      $scope.client2CreditHistory = [];

      $scope.hasCreditHistory = {
        client1: false,
        client2: false,
      };

      $scope.threeYearsHistoryBoolClient1 = true;
      $scope.sufficientPreviousBoolClient1 = false;

      $scope.threeYearsHistoryBoolClient2 = true;
      $scope.sufficientPreviousBoolClient2 = false;

      $scope.job = [];
      $scope.taxFree = [];
      $scope.other = [];

      $scope.isPersonalInfoCompleted = false;
      $scope.isIncomeCompleted = false;
      $scope.isPropertyCompleted = false;
      $scope.isNonPropertyCompleted = false;
      $scope.isBorrowingCompleted = false;
      $scope.isBillPaymentsCompleted = false;
      $scope.isSpendingCompleted = false;
      $scope.isFPlanHouseholdCompleted = false;
      $scope.isFPlanIncomeCompleted = false;
      $scope.didNextSave = false;

      $scope.forms = {};

      // remove phone number space
      $scope.removePhoneNumberSpace = function (employer, phoneType, value) {
        if (value !== "") {
          employer[phoneType] = $scope.empowerData.removeSpace(
            employer[phoneType]
          );
        }
      };

      $scope.updateToNull = function (val, index, client, isCurrent) {
        if (client === 1) {
          if (isCurrent) {
            if (val === "") {
              $scope.client1Employment[index].job.employer.address.state = null;
            }
          } else {
            if (val === "") {
              $scope.client1PreviousEmployment[
                index
              ].job.employer.address.state = null;
            }
          }
        } else if (client === 2) {
          if (isCurrent) {
            if (val === "") {
              $scope.client2Employment[index].job.employer.address.state = null;
            }
          } else {
            if (val === "") {
              $scope.client2PreviousEmployment[
                index
              ].job.employer.address.state = null;
            }
          }
        }
      };

      /*Check if lending needs is turned on*/
      $scope.suitabilityEnabled = false;

      $scope.isInvalidForFlex = (object) => {
        return DataHelper.isObjectInvalid(object) && $scope.suitabilityEnabled;
      };

      $scope.isInvalidForFlexCheckAll = (address) => {
        const addressProps = ["street", "streetType", "suburb", "postcode"];
        if (address) {
          return addressProps.some((addressProp) =>
            $scope.isInvalidForFlex(address[addressProp])
          );
        } else {
          return false;
        }
      };

      $scope.isQualifiedForMB = function (object, value) {
        if ($scope.reqDataLoaded && $scope.MBChecker) {
          let className = "";
          if (object !== null && typeof object !== "undefined") {
            if (
              $scope.reqDataMB.income.indexOf(object) > -1 &&
              (value === "" ||
                typeof value === "undefined" ||
                value === null ||
                value !== value ||
                (typeof value.length !== "undefined" && value.length === 0) ||
                (typeof value._isValid !== "undefined" && !value._isValid))
            ) {
              className = "highlight-blue";
            }
          }
          return className;
        }
      };

      function getSuitabilitySetting() {
        DataM.getSuitabilitySetting({ uid: $stateParams.clientId })
          .then(function (settingData) {
            $scope.suitabilityEnabled = false;
            if (settingData.suitabilityEnabled === true) {
              $scope.suitabilityEnabled = true;
            }
          })
          .catch(function (err) {
            console.log(err);
          });
      }
      getSuitabilitySetting();

      $scope.thirdParties = {
        "Property Investment Advisor": {
          active: false,
          id: 0,
        },
        "Mortgage Broker": {
          active: false,
          id: 1,
        },
        "Buyers Agent": {
          active: false,
          id: 2,
        },
        "Financial Planner": {
          active: false,
          id: 3,
        },
        Accountant: {
          active: false,
          id: 4,
        },
        "Solicitor/Conveyancer": {
          active: false,
          id: 5,
        },
      };

      $scope.personalInfo = {};
      /****ONLY NEED THESE ARRAYS FOR OTHER INCOME****/
      $scope.otherClient1 = [];
      $scope.otherClient2 = [];
      $scope.FYStrings = [];

      $scope.clientId = $stateParams.clientId;
      $scope.state = $state.current;
      $scope.params = $stateParams;

      $scope.twoClients = false;

      $scope.savedText = "Save";

      $scope.showAlertValidationModal = function () {
        $scope.alertMessage = $scope.empowerData.saveValidationMessage;
        $scope.showAlertModal = true;
      };
      $scope.closeModal = function () {
        $scope.showAlertModal = false;

        if ($scope.elementToFocus) {
          $timeout($scope.elementToFocus.focus(), 1500);
        }
      };
      $scope.showConfirmSaveModal = function (page) {
        if (!$scope.didNextSave) {
          //$scope.toShowConfirmSaveModal = true;
          $scope.nextPageLocation = page;
        }
      };
      $scope.goNextAndNotSaveModal = function () {
        //$scope.toShowConfirmSaveModal = false;
        $state.go($scope.nextPageLocation, {
          clientId: $scope.clientId,
        });
      };
      $scope.goNextAndSaveModal = function () {
        //$scope.toShowConfirmSaveModal = false;
        $scope.didNextSave = true;
        $scope.save($scope.nextPageLocation);
      };
      $scope.closeConfirmModal = function () {
        //$scope.toShowConfirmSaveModal = false;
        $scope.savedText = "Save";
        $scope.isSaving = false;
      };

      $scope.dashboardSave = function () {
        // Check industrty if valid upon save
        checkValidIndustry();

        var location = "income";

        const setInitialAddressState = function () {
          $scope.client1Employment.filter(Boolean).map((i) => {
            if (i.employmentType === "Self employed") {
              if (i.business)
                EmpowerWealthHelper.initAddressField(
                  i.business.registeredAddress
                );
            } else {
              if (i.job && i.job.employer)
                EmpowerWealthHelper.initAddressField(i.job.employer.address);
            }
            return i;
          });
          $scope.client2Employment.filter(Boolean).map((i) => {
            if (i.employmentType === "Self employed") {
              if (i.business)
                EmpowerWealthHelper.initAddressField(
                  i.business.registeredAddress
                );
            } else {
              if (i.job && i.job.employer)
                EmpowerWealthHelper.initAddressField(i.job.employer.address);
            }
            return i;
          });
          $scope.client1PreviousEmployment.filter(Boolean).map((i) => {
            if (i.employmentType === "Self employed") {
              if (i.business)
                EmpowerWealthHelper.initAddressField(
                  i.business.registeredAddress
                );
            } else {
              if (i.job && i.job.employer)
                EmpowerWealthHelper.initAddressField(i.job.employer.address);
            }
            return i;
          });
          $scope.client2PreviousEmployment.filter(Boolean).map((i) => {
            if (i.employmentType === "Self employed") {
              if (i.business)
                EmpowerWealthHelper.initAddressField(
                  i.business.registeredAddress
                );
            } else {
              if (i.job && i.job.employer)
                EmpowerWealthHelper.initAddressField(i.job.employer.address);
            }
            return i;
          });
        };

        if ($scope.isSaving && $scope.toStateName !== "") {
          location = $scope.toStateName;
        }
        setInitialAddressState();
        $scope.save(location);
      };

      $scope.$on(
        "$stateChangeStart",
        function (event, toState, toParams, fromState, fromParams) {
          if (_.isEqual(toState.name, "login")) {
          } else if (
            toState.url.indexOf("income") == -1 &&
            !$scope.isSaving &&
            !$scope.didNextSave
          ) {
            $scope.isSaving = true;
            $scope.toStateName = toState.name;
            event.preventDefault();
            if ($scope.showBottomButtons) {
              $scope.showChangesAlert = true;
            } else {
              $state.go(toState.name, {
                clientId: $scope.clientId,
              });
              return;
            }
          }
        }
      );

      $scope.calcPastFinancialYear = function (currentFY) {
        if (currentFY) {
          var separate = currentFY.split("/");
          var firstYear = parseInt(separate[0], 10);
          var secondYear = parseInt(separate[1], 10);
          firstYear--;
          secondYear--;
          return firstYear + "/" + secondYear;
        } else {
          return "";
        }
      };

      $scope.calcInitialEmploymentHistoryRequirement = function (client) {
        var totalDays = 0;
        var oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds

        switch (client) {
          case 1:
            var clientEmployment = $scope.client1Employment;
            break;
          case 2:
            var clientEmployment = $scope.client2Employment;
            break;
        }
        for (var i = 0; i < clientEmployment.length; i++) {
          totalDays = 0;
          var employment = clientEmployment[i];
          var firstDate = moment.utc(employment.startDatePicker, [
            "DD/MM/YYYY",
          ]);
          var todayDate = moment.utc();

          var diffDays = Math.round(
            Math.abs((firstDate.valueOf() - todayDate.valueOf()) / oneDay)
          );

          totalDays += diffDays;

          /**
           * Check if one of the employment is less than 3 years
           * If all are not less than 3 years show the previous employment
           */
          if (totalDays / 365 < 3) {
            switch (client) {
              case 1:
                $scope.threeYearsHistoryBoolClient1 = false;
                break;
              case 2:
                $scope.threeYearsHistoryBoolClient2 = false;
                break;
            }
          } else if (totalDays / 365 >= 3) {
            switch (client) {
              case 1:
                $scope.threeYearsHistoryBoolClient1 = true;
                return;
              case 2:
                $scope.threeYearsHistoryBoolClient2 = true;
                return;
            }
          } else if (!isNaN(totalDays)) {
            switch (client) {
              case 1:
                $scope.threeYearsHistoryBoolClient1 = true;
                $scope.client1PreviousEmployment = [];
                break;
              case 2:
                $scope.threeYearsHistoryBoolClient2 = true;
                $scope.client2PreviousEmployment = [];
                break;
            }
          }
        }
      };
      $scope.moveToPreviousEmployment = function (client, currentEmployment) {
        switch (client) {
          case 1:
            var clientEmployment = $scope.client1Employment;
            var clientPreviousEmployment = $scope.client1PreviousEmployment;
            break;
          case 2:
            var clientEmployment = $scope.client2Employment;
            var clientPreviousEmployment = $scope.client2PreviousEmployment;

            break;
        }

        // move specific employment to previous employment
        clientPreviousEmployment.push(currentEmployment);
        if (client === 1) {
          toCompare.income.client1PersonalIncome.pastEmployment.push(
            currentEmployment
          );
        } else if (client === 2) {
          toCompare.income.client1PersonalIncome.pastEmployment.push(
            currentEmployment
          );
        }

        // remove from current employment
        clientEmployment.splice(clientEmployment.indexOf(currentEmployment), 1);
        if (client === 1) {
          toCompare.income.client1PersonalIncome.currentEmployment.splice(
            toCompare.income.client1PersonalIncome.currentEmployment.indexOf(
              currentEmployment
            ),
            1
          );
        } else if (client === 2) {
          toCompare.income.client2PersonalIncome.currentEmployment.splice(
            toCompare.income.client2PersonalIncome.currentEmployment.indexOf(
              currentEmployment
            ),
            1
          );
        }
      };

      /*Calculate all history together, both current and previous employment
       * to determine if a total of three years history has been provided.*/
      $scope.calcPreviousEmploymentHistory = function (client) {
        var totalDays = 0;
        var oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds

        switch (client) {
          case 1:
            var clientEmployment = $scope.client1Employment;
            var clientPreviousEmployment = $scope.client1PreviousEmployment;
            break;
          case 2:
            var clientEmployment = $scope.client2Employment;
            var clientPreviousEmployment = $scope.client2PreviousEmployment;
            break;
        }
        for (var i = 0; i < clientEmployment.length; i++) {
          var employment = clientEmployment[i];
          var firstDate = moment.utc(employment.startDatePicker, [
            "DD/MM/YYYY",
          ]);
          var todayDate = moment.utc();
          var diffDays = Math.round(
            Math.abs((firstDate.valueOf() - todayDate.valueOf()) / oneDay)
          );
          totalDays += diffDays;
        }
        for (var i = 0; i < clientPreviousEmployment.length; i++) {
          var employment = clientPreviousEmployment[i];
          var firstDate = moment.utc(employment.startDatePicker, [
            "DD/MM/YYYY",
          ]);
          var todayDate = moment.utc(employment.endDatePicker, ["DD/MM/YYYY"]);
          var diffDays = Math.round(
            Math.abs((firstDate.valueOf() - todayDate.valueOf()) / oneDay)
          );
          totalDays += diffDays;
        }

        if (totalDays / 365 < 3) {
          switch (client) {
            case 1:
              $scope.sufficientPreviousBoolClient1 = false;
              break;
            case 2:
              $scope.sufficientPreviousBoolClient2 = false;
              break;
          }
        } else if (!isNaN(totalDays)) {
          switch (client) {
            case 1:
              $scope.sufficientPreviousBoolClient1 = true;
              break;
            case 2:
              $scope.sufficientPreviousBoolClient2 = true;
              break;
          }
        }
      };

      /*Function to sort array of employments into individual arrays (CLIENT 1)*/
      $scope.sortEmploymentOut = function () {
        for (var index in $scope.client1Employment) {
          var employment = $scope.client1Employment[index];

          if (
            employment.type === "Full Time" ||
            employment.type === "Part Time" ||
            employment.type === "Casual"
          ) {
            $scope.job.push(employment);
          } else if (employment.type === "Sole Trader") {
            $scope.soleTrader.push(employment);
          } else if (
            employment.type === "Self Employed" ||
            employment.type === "Business"
          ) {
            $scope.business.push(employment);
          }
        }
        if ($scope.client1Employment.length === 0) {
          $scope.client1Employment.push({});
        }
        if ($scope.client2Employment.length === 0) {
          $scope.client2Employment.push({});
        }
      };

      // $scope.sortEmploymentOut();

      $scope.sortEmploymentIn = function () {
        for (var index in $scope.job) {
          var employment = $scope.job[index];
          $scope.client1Employment.push(employment);
        }
        for (index in $scope.soleTrader) {
          var employment = $scope.soleTrader[index];
          $scope.client1Employment.push(employment);
        }
        for (index in $scope.business) {
          var employment = $scope.business[index];
          $scope.client1Employment.push(employment);
        }
      };

      $scope.addNewEmployment = function (client) {
        var employment = {};
        $scope.showClientSpinner = true;
        switch (client) {
          case 1:
            $scope.client1Employment.push(employment);
            toCompare.income.client1PersonalIncome.currentEmployment.push(
              employment
            );
            var elementId =
              "client1Employment" + ($scope.client1Employment.length - 1);

            waitForElement(elementId, scrollDownToItem);
            break;
          case 2:
            $scope.client2Employment.push(employment);
            toCompare.income.client2PersonalIncome.currentEmployment.push(
              employment
            );
            var elementId =
              "client2Employment" + ($scope.client2Employment.length - 1);
            waitForElement(elementId, scrollDownToItem);
            break;
        }
      };
      function waitForElement(elementId, callBack) {
        window.setTimeout(function () {
          var element = document.getElementById(elementId);
          if (element) {
            $timeout(callBack(elementId), 200);
          } else {
            waitForElement(elementId, callBack);
          }
        }, 500);
      }

      function scrollDownToItem(elementId) {
        $scope.showClientSpinner = false;
        var element = angular.element(document.getElementById(elementId));
        $timeout($document.scrollToElement(element, 100, 800), 200);
      }
      $scope.deleteEmployment = function (client, employment) {
        switch (client) {
          case 1:
            $scope.client1Employment.splice(
              $scope.client1Employment.indexOf(employment),
              1
            );
            toCompare.income.client1PersonalIncome.currentEmployment.splice(
              toCompare.income.client1PersonalIncome.currentEmployment.indexOf(
                employment
              ),
              1
            );
            break;
          case 2:
            $scope.client2Employment.splice(
              $scope.client2Employment.indexOf(employment),
              1
            );
            toCompare.income.client2PersonalIncome.currentEmployment.splice(
              toCompare.income.client2PersonalIncome.currentEmployment.indexOf(
                employment
              ),
              1
            );
            break;
        }
      };

      $scope.addPreviousEmployment = function (client) {
        var employment = {};
        $scope.showClientSpinner = true;
        switch (client) {
          case 1:
            $scope.client1PreviousEmployment.push(employment);
            toCompare.income.client1PersonalIncome.pastEmployment.push(
              employment
            );
            var elementId =
              "client1PreviousEmployment" +
              ($scope.client1PreviousEmployment.length - 1);

            waitForElement(elementId, scrollDownToItem);
            break;
          case 2:
            $scope.client2PreviousEmployment.push(employment);
            toCompare.income.client2PersonalIncome.pastEmployment.push(
              employment
            );
            var elementId =
              "client2PreviousEmployment" +
              ($scope.client2PreviousEmployment.length - 1);

            waitForElement(elementId, scrollDownToItem);
            break;
        }
      };

      $scope.addPreTaxDeduction = function (employment) {
        const addTax = {
          taxName: "",
          taxValue: null,
          taxFreq: "Yearly",
        };
        if (!employment.job.hasOwnProperty("preTaxes")) {
          employment.job.preTaxes = [];
          employment.job.preTaxes.push(addTax);
        } else {
          employment.job.preTaxes.push(addTax);
        }
      };

      $scope.goToOtherPage = function (page) {
        $state.go(page, {
          clientId: $scope.clientId,
        });
      };

      $scope.deletePreviousEmployment = function (client, employment) {
        switch (client) {
          case 1:
            $scope.client1PreviousEmployment.splice(
              $scope.client1PreviousEmployment.indexOf(employment),
              1
            );
            toCompare.income.client1PersonalIncome.pastEmployment.splice(
              toCompare.income.client1PersonalIncome.pastEmployment.indexOf(
                employment
              ),
              1
            );
            $scope.calcPreviousEmploymentHistory(client);
            break;
          case 2:
            $scope.client2PreviousEmployment.splice(
              $scope.client2PreviousEmployment.indexOf(employment),
              1
            );
            toCompare.income.client2PersonalIncome.pastEmployment.splice(
              toCompare.income.client2PersonalIncome.pastEmployment.indexOf(
                employment
              ),
              1
            );
            $scope.calcPreviousEmploymentHistory(client);
            break;
        }
      };

      $scope.addNewTaxFree = function () {
        var taxFree = {};
        $scope.taxFree.unshift(taxFree);
      };

      $scope.deleteTaxEmployment = function (taxFree) {
        $scope.taxFree.splice($scope.taxFree.indexOf(taxFree), 1);
      };

      /****FUNCTIONS ADD AND DELETE OTHER TYPES OF INCOME***/
      $scope.addNewOther = function (client) {
        var other = { type: null, isTaxFree: false };
        switch (client) {
          case 1:
            $scope.otherClient1.unshift(other);
            toCompare.income.client1PersonalIncome.other.unshift(other);
            break;
          case 2:
            $scope.otherClient2.unshift(other);
            toCompare.income.client2PersonalIncome.other.unshift(other);
        }
      };

      $scope.deleteOther = function (other, client) {
        switch (client) {
          case 1:
            $scope.otherClient1.splice($scope.otherClient1.indexOf(other), 1);
            toCompare.income.client1PersonalIncome.other.splice(
              toCompare.income.client1PersonalIncome.other.indexOf(other),
              1
            );
            break;
          case 2:
            $scope.otherClient2.splice($scope.otherClient2.indexOf(other), 1);
            toCompare.income.client2PersonalIncome.other.splice(
              toCompare.income.client2PersonalIncome.other.indexOf(other),
              1
            );
        }
      };

      $scope.deletePreTax = function (preTax, index) {
        preTax.splice(index, 1);
      };

      $scope.deleteOtherEmployment = function (other) {
        $scope.other.splice($scope.other.indexOf(other), 1);
      };

      $scope.addNewCreditHistory = function (client) {
        var creditIssue = {};

        switch (client) {
          case 1:
            $scope.client1CreditHistory.push(creditIssue);
            toCompare.income.client1PersonalIncome.creditHistory.push(
              creditIssue
            );
            break;
          case 2:
            $scope.client2CreditHistory.push(creditIssue);
            toCompare.income.client2PersonalIncome.creditHistory.push(
              creditIssue
            );
            break;
        }
      };

      // check if has at least 1
      $scope.hasCreditHistoryChange = function (clientNum) {
        const credit = {};
        if ($scope.suitabilityEnabled == true) {
          if (clientNum == 1) {
            if (
              $scope.hasCreditHistory.client1 &&
              $scope.client1CreditHistory.length < 1 &&
              toCompare.income.client1PersonalIncome.creditHistory.length < 1
            ) {
              $scope.client1CreditHistory.push(credit);
              toCompare.income.client1PersonalIncome.creditHistory.push(credit);
            }
          } else if (clientNum == 2) {
            if (
              $scope.hasCreditHistory.client2 &&
              $scope.client2CreditHistory.length < 1 &&
              toCompare.income.client2PersonalIncome.creditHistory.length < 1
            ) {
              $scope.client2CreditHistory.push(credit);
              toCompare.income.client2PersonalIncome.creditHistory.push(credit);
            }
          }
        }

        if (clientNum == 1) {
          toCompare.income.client1PersonalIncome.hasCreditHistory =
            $scope.hasCreditHistory.client1;
        } else if (clientNum == 2) {
          toCompare.income.client2PersonalIncome.hasCreditHistory =
            $scope.hasCreditHistory.client2;
        }
      };
      $scope.deleteCreditHistory = function (client, creditIssue) {
        switch (client) {
          case 1:
            $scope.client1CreditHistory.splice(
              $scope.client1CreditHistory.indexOf(creditIssue),
              1
            );
            toCompare.income.client1PersonalIncome.creditHistory.splice(
              toCompare.income.client1PersonalIncome.creditHistory.indexOf(
                creditIssue
              ),
              1
            );
            break;
          case 2:
            $scope.client2CreditHistory.splice(
              $scope.client2CreditHistory.indexOf(creditIssue),
              1
            );
            toCompare.income.client2PersonalIncome.creditHistory.splice(
              toCompare.income.client2PersonalIncome.creditHistory.indexOf(
                creditIssue
              ),
              1
            );
            break;
        }
      };

      $scope.changeBasis = function (employment, basis) {
        if (basis == "Sole trader") {
          if (employment.business) {
            if (employment.business.earnings) {
              if (employment.business.earnings[0]) {
                employment.business.earnings[0].profit = null;
              }
              if (employment.business.earnings[1]) {
                employment.business.earnings[1].profit = null;
              }
            }
          }
        }
      };

      $scope.updateEmploymentData = function (employment) {
        employment.startDate = null;
        employment.endDate = null;
        if (
          employment.employmentType == "Self employed" ||
          employment.employmentType == "Business" ||
          employment.employmentType == "Unemployed" ||
          employment.employmentType == "Retired"
        ) {
          employment.job = null;
          employment.business = {
            detailedBasis: "",
            title: "",
            businessName: "",
            abn: "",
            earnings: [],
            natureOfWork: "",
            natureOfBusiness: "",
            company: {
              tradingType: null,
              name: "",
              shareholders: [],
              directors: [],
              liabilities: "",
              directorship: "",
            },
            trust: {
              tradingType: null,
              name: "",
              trustType: null,
              trusteeName: "",
              directors: [],
              beneficiaries: [],
            },
          };
        } else {
          employment.business = null;
          if (employment.employmentType == "PAYG") {
            employment.job = {
              positionTitle: "",
              nature: "",
              weeklyHours: null,
              annualSalary: null,
              overtime: null,
              commission: null,
              bonus: null,
              monthsOfProbation: "",
              employer: {
                employer: "",
                contactNo: "",
                address: {
                  country: "",
                },
              },
            };
          }
        }
      };

      $scope.updateProbationPeriod = function (employment) {
        employment.job.monthsOfProbation = null;
      };

      $scope.FY = function () {
        $scope.newFY = new Date(0, 6, 1);
        $scope.date = new Date();
        $scope.newFY.setUTCFullYear($scope.date.getUTCFullYear());
        // if date is July 1 or past, last financial year is (prev)/(curr). else it is (prev2)/(prev)
        if ($scope.date > $scope.newFY) {
          var $str = $scope.date.getUTCFullYear().toString().substring(2, 4);
          var $str2 = (Number($str) - 1).toString();
          $scope.FYStrings.push($str2 + "/" + $str);
        } else {
          var $str = $scope.date.getUTCFullYear().toString().substring(2, 4);
          var $str2 = (Number($str) - 1).toString();
          $str = (Number($str2) - 1).toString();
          $scope.FYStrings.push($str + "/" + $str2);
        }
        while ($scope.FYStrings.length < 4) {
          var $x = $scope.FYStrings[$scope.FYStrings.length - 1].toString();
          var $x1 = Number($x.substring(0, 2)) - 1;
          var $x2 = Number($x.substring(3, 5)) - 1;
          $scope.FYStrings.push($x1.toString() + "/" + $x2.toString());
        }
      };

      // $scope.FY();

      $scope.emptyClient = function (x) {
        return Object.keys(x).every(function (y) {
          return (
            x[y] === "" || x[y] === null || x[y] === false || x[y].length === 0
          );
        });
      };

      $scope.setCHDate = function (x) {
        if (
          x.hasOwnProperty("resolutionMonth") &&
          x.hasOwnProperty("resolutionYear")
        ) {
          x.resolutionDate = moment.utc([
            x.resolutionYear,
            x.resolutionMonth,
            1,
            0,
            0,
            0,
          ]);
        }
      };

      $scope.setDate = function (x) {
        x.startDate = moment.utc(x.startDatePicker, ["DD/MM/YYYY"]);
      };

      $scope.setEndDate = function (x) {
        x.endDate = moment.utc(x.endDatePicker, ["DD/MM/YYYY"]);
      };
      $scope.goToLocation = function (location) {
        var proceed = $scope.empowerData.goToLocation(location, $scope);

        if (proceed) {
          $state.go(location, {
            clientId: $scope.clientId,
          });
        }
      };

      $scope.isMBAdmin = false;
      // check if user is admin
      DataM.getMe()
        .then(function (userData) {
          if (userData.role === "super_admin" || userData.role === "mb_admin") {
            $scope.isMBAdmin = true;
          }
        })
        .catch(function (err) {
          console.log("Retrieve current user error");
        });

      $scope.assignValues = function (data, isDiscard) {
        $scope.personalInfo = data.personalInfo;

        $scope.isPersonalInfoCompleted = data.isPersonalInfoCompleted;
        $scope.isIncomeCompleted = data.isIncomeCompleted;
        $scope.isPropertyCompleted = data.isPropertyCompleted;
        $scope.isNonPropertyCompleted = data.isNonPropertyCompleted;
        $scope.isBorrowingCompleted = data.isBorrowingCompleted;
        $scope.isBillPaymentsCompleted = data.isBillPaymentsCompleted;
        $scope.isSpendingCompleted = data.isSpendingCompleted;
        $scope.isFPlanHouseholdCompleted = data.isFPlanHouseholdCompleted;
        $scope.isFPlanIncomeCompleted = data.isFPlanIncomeCompleted;

        /*Set full names for ownership*/
        $scope.client1FullName =
          $scope.personalInfo.client1.fName +
          " " +
          $scope.personalInfo.client1.lName;
        if ($scope.client1FullName == " ") {
          $scope.client1FullName = "You";
        }

        if ($scope.personalInfo.client2) {
          $scope.client2FullName =
            $scope.personalInfo.client2.fName +
            " " +
            $scope.personalInfo.client2.lName;
          if ($scope.client2FullName == " ") {
            $scope.client2FullName = "Your Partner";
          }
        }
        if (data.income) {
          if (data.income.client1PersonalIncome) {
            var currentEmployments =
              data.income.client1PersonalIncome.currentEmployment;
            if (currentEmployments) {
              for (var $i = 0; $i < currentEmployments.length; $i++) {
                if (!_.isNil(currentEmployments[$i].startDate)) {
                  currentEmployments[$i].startDatePicker =
                    empowerDataService.generatedLocalDateString(
                      currentEmployments[$i].startDate
                    );
                  currentEmployments[$i].startDate =
                    empowerDataService.generateUTCDate(
                      currentEmployments[$i].startDate
                    );
                }

                if (!_.isNil(currentEmployments[$i].job)) {
                  if (_.isNil(currentEmployments[$i].job.preTaxes)) {
                    currentEmployments[$i].job.preTaxes = [];
                  }
                  if (!_.isNil(currentEmployments[$i].job.employer)) {
                    $scope.updateAddressField(
                      currentEmployments[$i].job.employer.address
                    );
                    EmpowerWealthHelper.initAddressField(
                      currentEmployments[$i].job.employer.address
                    );
                  }
                } else {
                  if (!_.isNil(currentEmployments[$i].business)) {
                    if (
                      !_.isNil(
                        currentEmployments[$i].business.registeredAddress
                      )
                    ) {
                      $scope.updateAddressField(
                        currentEmployments[$i].business.registeredAddress
                      );
                      EmpowerWealthHelper.initAddressField(
                        currentEmployments[$i].business.registeredAddress
                      );
                    }
                  }
                }

                $scope.client1Employment.push(currentEmployments[$i]);
                $scope.calcInitialEmploymentHistoryRequirement(1);
              }
              if (
                data.income.client1PersonalIncome &&
                data.income.client1PersonalIncome.currentEmployment.length < 1
              ) {
                $scope.client1Employment.push({});
              }
            }

            var pastEmployments =
              data.income.client1PersonalIncome.pastEmployment;
            if (pastEmployments) {
              for (var $i = 0; $i < pastEmployments.length; $i++) {
                if (!_.isNil(pastEmployments[$i].startDate)) {
                  pastEmployments[$i].startDatePicker =
                    empowerDataService.generatedLocalDateString(
                      pastEmployments[$i].startDate
                    );
                  pastEmployments[$i].startDate =
                    empowerDataService.generateUTCDate(
                      pastEmployments[$i].startDate
                    );
                }

                if (!_.isNil(pastEmployments[$i].endDate)) {
                  pastEmployments[$i].endDatePicker =
                    empowerDataService.generatedLocalDateString(
                      pastEmployments[$i].endDate
                    );
                  pastEmployments[$i].endDate =
                    empowerDataService.generateUTCDate(
                      pastEmployments[$i].endDate
                    );
                }

                if (!_.isNil(pastEmployments[$i].job)) {
                  if (_.isNil(pastEmployments[$i].job.preTaxes)) {
                    pastEmployments[$i].job.preTaxes = [];
                  }
                  if (!_.isNil(pastEmployments[$i].job.employer)) {
                    if (!_.isNil(pastEmployments[$i].job.employer.address)) {
                      $scope.updateAddressField(
                        pastEmployments[$i].job.employer.address
                      );
                      EmpowerWealthHelper.initAddressField(
                        pastEmployments[$i].job.employer.address
                      );
                    }
                  }
                } else {
                  if (!_.isNil(pastEmployments[$i].business)) {
                    if (
                      !_.isNil(pastEmployments[$i].business.registeredAddress)
                    ) {
                      $scope.updateAddressField(
                        pastEmployments[$i].business.registeredAddress
                      );
                      EmpowerWealthHelper.initAddressField(
                        pastEmployments[$i].business.registeredAddress
                      );
                    }
                  }
                }

                $scope.client1PreviousEmployment.push(pastEmployments[$i]);
              }
            }

            var otherIncomes = data.income.client1PersonalIncome.other;
            if (otherIncomes) {
              for (var $i = 0; $i < otherIncomes.length; $i++) {
                $scope.otherClient1.push(otherIncomes[$i]);
              }
            }

            var creditHistories =
              data.income.client1PersonalIncome.creditHistory;
            if (creditHistories) {
              for (var $i = 0; $i < creditHistories.length; $i++) {
                if (creditHistories[$i].hasOwnProperty("resolutionDate")) {
                  if (creditHistories[$i].resolutionDate) {
                    creditHistories[$i].resolutionDatePicker =
                      empowerDataService.generatedLocalDateString(
                        creditHistories[$i].resolutionDate
                      );
                    creditHistories[$i].resolutionDate =
                      empowerDataService.generateUTCDate(
                        creditHistories[$i].resolutionDate
                      );

                    creditHistories[$i].resolutionMonth = creditHistories[
                      $i
                    ].resolutionDate
                      .month()
                      .toString();
                    creditHistories[$i].resolutionYear =
                      creditHistories[$i].resolutionDate.format("YYYY");
                  }
                }
                $scope.client1CreditHistory.push(creditHistories[$i]);
              }
              $scope.hasCreditHistory.client1 =
                data.income.client1PersonalIncome.hasCreditHistory === null
                  ? false
                  : data.income.client1PersonalIncome.hasCreditHistory;
            }
          }

          if (data.income.client2PersonalIncome) {
            var currentEmployments =
              data.income.client2PersonalIncome.currentEmployment;
            if (currentEmployments) {
              for (var $i = 0; $i < currentEmployments.length; $i++) {
                if (!_.isNil(currentEmployments[$i].startDate)) {
                  currentEmployments[$i].startDatePicker =
                    empowerDataService.generatedLocalDateString(
                      currentEmployments[$i].startDate
                    );
                  currentEmployments[$i].startDate =
                    empowerDataService.generateUTCDate(
                      currentEmployments[$i].startDate
                    );
                }

                if (!_.isNil(currentEmployments[$i].job)) {
                  if (_.isNil(currentEmployments[$i].job.preTaxes)) {
                    currentEmployments[$i].job.preTaxes = [];
                  }
                  if (!_.isNil(currentEmployments[$i].job.employer)) {
                    $scope.updateAddressField(
                      currentEmployments[$i].job.employer.address
                    );
                    EmpowerWealthHelper.initAddressField(
                      currentEmployments[$i].job.employer.address
                    );
                  }
                } else {
                  if (!_.isNil(currentEmployments[$i].business)) {
                    if (
                      !_.isNil(
                        currentEmployments[$i].business.registeredAddress
                      )
                    ) {
                      $scope.updateAddressField(
                        currentEmployments[$i].business.registeredAddress
                      );
                      EmpowerWealthHelper.initAddressField(
                        currentEmployments[$i].business.registeredAddress
                      );
                    }
                  }
                }

                $scope.client2Employment.push(currentEmployments[$i]);
                $scope.calcInitialEmploymentHistoryRequirement(2);
              }
            }

            var pastEmployments =
              data.income.client2PersonalIncome.pastEmployment;
            if (pastEmployments) {
              for (var $i = 0; $i < pastEmployments.length; $i++) {
                if (!_.isNil(pastEmployments[$i].startDate)) {
                  pastEmployments[$i].startDatePicker =
                    empowerDataService.generatedLocalDateString(
                      pastEmployments[$i].startDate
                    );
                  pastEmployments[$i].startDate =
                    empowerDataService.generateUTCDate(
                      pastEmployments[$i].startDate
                    );
                }

                if (!_.isNil(pastEmployments[$i].endDate)) {
                  pastEmployments[$i].endDatePicker =
                    empowerDataService.generatedLocalDateString(
                      pastEmployments[$i].endDate
                    );
                  pastEmployments[$i].endDate =
                    empowerDataService.generateUTCDate(
                      pastEmployments[$i].endDate
                    );
                }

                if (!_.isNil(pastEmployments[$i].job)) {
                  if (_.isNil(pastEmployments[$i].job.preTaxes)) {
                    pastEmployments[$i].job.preTaxes = [];
                  }
                  if (!_.isNil(pastEmployments[$i].job.employer)) {
                    if (!_.isNil(pastEmployments[$i].job.employer.address)) {
                      $scope.updateAddressField(
                        pastEmployments[$i].job.employer.address
                      );
                      EmpowerWealthHelper.initAddressField(
                        pastEmployments[$i].job.employer.address
                      );
                    }
                  }
                } else {
                  if (!_.isNil(pastEmployments[$i].business)) {
                    if (
                      !_.isNil(pastEmployments[$i].business.registeredAddress)
                    ) {
                      $scope.updateAddressField(
                        pastEmployments[$i].business.registeredAddress
                      );
                      EmpowerWealthHelper.initAddressField(
                        pastEmployments[$i].business.registeredAddress
                      );
                    }
                  }
                }

                $scope.client2PreviousEmployment.push(pastEmployments[$i]);
              }
            }

            var otherIncomes = data.income.client2PersonalIncome.other;
            if (otherIncomes) {
              for (var $i = 0; $i < otherIncomes.length; $i++) {
                $scope.otherClient2.push(otherIncomes[$i]);
              }
            }

            var creditHistories =
              data.income.client2PersonalIncome.creditHistory;
            if (creditHistories) {
              for (var $i = 0; $i < creditHistories.length; $i++) {
                if (creditHistories[$i].hasOwnProperty("resolutionDate")) {
                  if (creditHistories[$i].resolutionDate) {
                    creditHistories[$i].resolutionDatePicker =
                      empowerDataService.generatedLocalDateString(
                        creditHistories[$i].resolutionDate
                      );
                    creditHistories[$i].resolutionDate =
                      empowerDataService.generateUTCDate(
                        creditHistories[$i].resolutionDate
                      );
                    creditHistories[$i].resolutionMonth = creditHistories[
                      $i
                    ].resolutionDate
                      .month()
                      .toString();
                    creditHistories[$i].resolutionYear =
                      creditHistories[$i].resolutionDate.format("YYYY");
                  }
                }
                $scope.client2CreditHistory.push(creditHistories[$i]);
              }
              $scope.hasCreditHistory.client2 =
                data.income.client2PersonalIncome.hasCreditHistory === null
                  ? false
                  : data.income.client2PersonalIncome.hasCreditHistory;
            }
          }

          if (data.income.thirdParties) {
            for (var x in data.income.thirdParties) {
              // O: Property Investment Advisor
              // 1: Mortgage Broker
              // 2: Buyers Agent
              // 3: Financial Planner
              // 4: Accountant
              // 5: Solicitor/Conveyancer
              if (data.income.thirdParties[x].id === 0) {
                $scope.thirdParties["Property Investment Advisor"] =
                  data.income.thirdParties[x];
                $scope.thirdParties[
                  "Property Investment Advisor"
                ].active = true;
              } else if (data.income.thirdParties[x].id === 1) {
                $scope.thirdParties["Mortgage Broker"] =
                  data.income.thirdParties[x];
                $scope.thirdParties["Mortgage Broker"].active = true;
              } else if (data.income.thirdParties[x].id === 2) {
                $scope.thirdParties["Buyers Agent"] =
                  data.income.thirdParties[x];
                $scope.thirdParties["Buyers Agent"].active = true;
              } else if (data.income.thirdParties[x].id === 3) {
                $scope.thirdParties["Financial Planner"] =
                  data.income.thirdParties[x];
                $scope.thirdParties["Financial Planner"].active = true;
              } else if (data.income.thirdParties[x].id === 4) {
                $scope.thirdParties["Accountant"] = data.income.thirdParties[x];
                $scope.thirdParties["Accountant"].active = true;
              } else if (data.income.thirdParties[x].id === 5) {
                $scope.thirdParties["Solicitor/Conveyancer"] =
                  data.income.thirdParties[x];
                $scope.thirdParties["Solicitor/Conveyancer"].active = true;
              }
            }
          }
        }

        $scope.calcPreviousEmploymentHistory(1);
        $scope.calcPreviousEmploymentHistory(2);
        if (
          (isDiscard && $scope.isUserNewlyCreated) ||
          (toCompare.income &&
            toCompare.income.client1PersonalIncome.currentEmployment.length ===
              0 &&
            $scope.client1Employment.length > 0)
        ) {
          $scope.client1Employment.splice(
            $scope.client1Employment.indexOf($scope.client1Employment[0]),
            1
          );
        }
      };

      $scope.setInputValues = function (isDiscard) {
        $scope.client1Employment = [];
        $scope.client2Employment = [];

        $scope.client1PreviousEmployment = [];
        $scope.client2PreviousEmployment = [];

        $scope.client1CreditHistory = [];
        $scope.client2CreditHistory = [];

        $scope.hasCreditHistory = {
          client1: false,
          client2: false,
        };

        $scope.threeYearsHistoryBoolClient1 = true;
        $scope.sufficientPreviousBoolClient1 = false;

        $scope.threeYearsHistoryBoolClient2 = true;
        $scope.sufficientPreviousBoolClient2 = false;

        $scope.otherClient1 = [];
        $scope.otherClient2 = [];

        if (!isDiscard) {
          DataM.getIncome()
            .then(function (data) {
              if (_.isNil(data.income)) {
                // if the user is newly created, this varible is undefined
                // in the database, so we need to add an empty data for it
                // so that it will not cause any inconsistency on comapring
                // initial scope variable to toCompare
                $scope.isUserNewlyCreated = true;
                data.income = {
                  thirdParties: [],
                  client1PersonalIncome: {
                    creditHistory: [],
                    currentEmployment: [],
                    deductions: [],
                    hasCreditHistory: false,
                    other: [],
                    pastEmployment: [],
                  },
                  client2PersonalIncome: {
                    creditHistory: [],
                    currentEmployment: [],
                    deductions: [],
                    hasCreditHistory: false,
                    other: [],
                    pastEmployment: [],
                  },
                };
              }
              toCompare = data;
              var promise = new Promise(function (resolve, reject) {
                $scope.assignValues(data, isDiscard);
                resolve("done");
              });

              promise.then(function (data) {
                if (data === "done") {
                  $scope.showClientSpinner = false;
                }
              });
            })
            .catch(function (err) {
              console.log("Retrieve error", err);
              $scope.showRetrieveError = true;
            });
        } else {
          var data = JSON.parse($scope.initialIncome);
          toCompare = data;
          $scope.assignValues(data, isDiscard);
        }
      };

      $scope.setInputValues(false);

      $scope.changeBottomButtonState = function () {
        if ($scope.initialIncome !== null && toCompare !== null) {
          var newToCompare = JSON.stringify(toCompare, function (key, value) {
            if (key === "$$hashKey") {
              return undefined;
            }
            if (key === "$promise") {
              return undefined;
            }
            if (key === "$resolved") {
              return undefined;
            }
            if (key === "startDatePicker") {
              return undefined;
            }
            if (key === "endDatePicker") {
              return undefined;
            }
            return value;
          });
          if ($scope.initialIncome !== newToCompare) {
            $scope.showBottomButtons = true;
          } else {
            $scope.showBottomButtons = false;
          }
        } else if ($scope.initialIncome === null && toCompare !== null) {
          $scope.initialIncome = JSON.stringify(
            toCompare,
            function (key, value) {
              if (key === "$$hashKey") {
                return undefined;
              }
              if (key === "$promise") {
                return undefined;
              }
              if (key === "$resolved") {
                return undefined;
              }
              if (key === "startDatePicker") {
                return undefined;
              }
              if (key === "endDatePicker") {
                return undefined;
              }
              return value;
            }
          );
        }
      };

      $scope.$watch(
        "[client1Employment, client2Employment, client1PreviousEmployment, client2PreviousEmployment, client1CreditHistory, client2CreditHistory, hasCreditHistory, otherClient1, otherClient2]",
        function () {
          $scope.changeBottomButtonState();
        },
        true
      );

      $scope.save = function (location) {
        if ($scope.showChangesAlert) {
          $scope.showChangesAlert = false;
        }
        $scope.showClientSpinner = true;
        $scope.loadingMessage = "Saving Client Information...";

        if ($scope.hasCreditHistory.client1 != true) {
          $scope.client1CreditHistory = [];
        }
        if ($scope.hasCreditHistory.client2 != true) {
          $scope.client2CreditHistory = [];
        }

        for (var i = 0; i < $scope.client1Employment.length; i++) {
          if (
            $scope.client1Employment[i].employmentType == "Self employed" ||
            $scope.client1Employment[i].employmentType == "Business"
          ) {
            $scope.client1Employment[i].job = null;
            if ($scope.client1Employment[i].employmentType == "Business") {
              $scope.client1Employment[i].business.natureOfWork = null;
            }
          } else {
            $scope.client1Employment[i].business = null;
          }
        }

        for (var i = 0; i < $scope.client1PreviousEmployment.length; i++) {
          if (
            $scope.client1PreviousEmployment[i].employmentType ==
              "Self employed" ||
            $scope.client1PreviousEmployment[i].employmentType == "Business"
          ) {
            $scope.client1PreviousEmployment[i].job = null;
            if (
              $scope.client1PreviousEmployment[i].employmentType == "Business"
            ) {
              $scope.client1PreviousEmployment[i].business.natureOfWork = null;
            }
          } else {
            $scope.client1PreviousEmployment[i].business = null;
          }
        }

        for (var i = 0; i < $scope.client2Employment.length; i++) {
          if (
            $scope.client2Employment[i].employmentType == "Self employed" ||
            $scope.client2Employment[i].employmentType == "Business"
          ) {
            $scope.client2Employment[i].job = null;
          } else {
            $scope.client2Employment[i].business = null;
          }
        }

        for (var i = 0; i < $scope.client2PreviousEmployment.length; i++) {
          if (
            $scope.client2PreviousEmployment[i].employmentType ==
              "Self employed" ||
            $scope.client2PreviousEmployment[i].employmentType == "Business"
          ) {
            $scope.client2PreviousEmployment[i].job = null;
          } else {
            $scope.client2PreviousEmployment[i].business = null;
          }
        }

        var professionalAdvisory = [];

        // Deal with third Parties:
        for (var x in $scope.thirdParties) {
          if ($scope.thirdParties[x].active === true) {
            professionalAdvisory.push($scope.thirdParties[x]);
          }
        }

        var client1PersonalIncome = {
          currentEmployment: $scope.client1Employment,
          pastEmployment: $scope.client1PreviousEmployment,
          other: $scope.otherClient1,
          creditHistory: $scope.client1CreditHistory,
          hasCreditHistory: $scope.hasCreditHistory.client1,
        };

        var client2PersonalIncome = {
          currentEmployment: $scope.client2Employment,
          pastEmployment: $scope.client2PreviousEmployment,
          other: $scope.otherClient2,
          creditHistory: $scope.client2CreditHistory,
          hasCreditHistory: $scope.hasCreditHistory.client2,
        };

        var formData = {
          income: {
            client1PersonalIncome: client1PersonalIncome,
            client2PersonalIncome: client2PersonalIncome,
            thirdParties: professionalAdvisory,
          },
        };

        //SAVE TO DB
        DataM.saveIncome(formData)
          .then(function (data) {
            var newData = toCompare;
            $scope.initialIncome = newData;

            if (data._message === "Client validation failed") {
              var errorMessage = "";
              const formattedMessage = DataHelper.formatMongoError(data);
              $scope.showClientSpinner = false;

              $scope.didNextSave = false;
              alert(formattedMessage);
            } else {
              if ($scope.isSaving && $scope.toStateName !== "") {
                $state.go($scope.toStateName, {
                  clientId: $scope.clientId,
                });
              } else {
                if ($location.$$url.includes("income")) {
                  $rootScope.setAlertValues(
                    true,
                    true,
                    "Successfully saved changes!",
                    false
                  );
                  $scope.initialIncome = null;
                  $scope.initialIncome = JSON.stringify(
                    toCompare,
                    function (key, value) {
                      if (key === "$$hashKey") {
                        return undefined;
                      }
                      if (key === "$promise") {
                        return undefined;
                      }
                      if (key === "$resolved") {
                        return undefined;
                      }
                      if (key === "startDatePicker") {
                        return undefined;
                      }
                      if (key === "endDatePicker") {
                        return undefined;
                      }
                      return value;
                    }
                  );
                  $scope.changeBottomButtonState();
                } else {
                  $state.go(location, {
                    clientId: $scope.clientId,
                  });
                }
              }
              $scope.showClientSpinner = false;
            }
            $rootScope.$broadcast("onSaved");
          })
          .catch(function (err) {
            console.log("test err", err);
            $rootScope.setAlertValues(
              true,
              false,
              "There is a problem accessing the server.",
              true
            );
            alert(
              "There is a problem accessing the server. Please make sure your internet connection is working. If it is working and this problem persist, please report this problem to Empower Wealth team."
            );
            $scope.showClientSpinner = false;
          });
      };

      $scope.next = function () {
        $scope.save("fact-find3");
      };
      $scope.back = function () {
        $state.go("fact-find0", {
          clientId: $scope.clientId,
        });
      };
      $scope.quit = function () {
        $state.go("dashboard");
      };

      $scope.deleteBusinessItem = function (businessList, item) {
        businessList.splice(businessList.indexOf(item), 1);
      };

      $scope.addShareholder = function (company) {
        if (!company.shareholders) {
          company.shareholders = [];
        }
        company.shareholders.push({});
      };

      $scope.addBeneficiary = function (trust) {
        if (!trust.beneficiaries) {
          trust.beneficiaries = [];
        }
        trust.beneficiaries.push({});
      };

      $scope.addDirector = function (business) {
        if (!business.directors) {
          business.directors = [];
        }
        business.directors.push({});
      };

      $scope.setSelfEmployedDetails = function (business) {
        if (!business.trust) {
          business.trust = {};
        }
        if (!business.company) {
          business.company = {};
        }
      };

      $scope.computePretaxDeduction = function (employment) {
        if (
          employment.hasOwnProperty("annualSalary") &&
          employment.hasOwnProperty("preTax")
        ) {
          employment.annualSalary = employment.annualSalary - employment.preTax;
        }
      };

      $scope.revertFromPreTaxDeduction = function (employment) {
        if (
          employment.hasOwnProperty("annualSalary") &&
          employment.hasOwnProperty("preTax")
        ) {
          employment.annualSalary = employment.annualSalary + employment.preTax;
        }
      };
    }
  )
  .directive("clickOutside", function ($document) {
    return {
      restrict: "A",
      scope: {
        clickOutside: "&",
      },
      link: function (scope, el, attr) {
        $document.on("click", function (e) {
          if (
            e.target.id === "searchIndustry" ||
            e.target.id === "industryOption"
          ) {
            return;
          } else {
            scope.$apply(function () {
              scope.$eval(scope.clickOutside);
            });
          }
        });
      },
    };
  })

  .controller(
    "factfind-3-controller",
    function (
      $scope,
      $rootScope,
      $state,
      $stateParams,
      $location,
      $window,
      DataM,
      Auth,
      empowerDataService,
      EmpowerWealthHelper,
      DataHelper,
      $timeout
    ) {
      "use strict";

      $scope.showNotification = false;
      $scope.isAddressEditMode = false;
      $scope.isAdmin = false;
      Auth.isAdmin().then((isAdmin) => ($scope.isAdmin = isAdmin));

      $scope.reqDataCR = {};
      $scope.reqDataMB = {};
      $scope.reqDataLoaded = false;
      $scope.MBChecker = false;

      // linking address field functions to services
      $scope.addressOnClickHandler = (id) => {
        EmpowerWealthHelper.addressFieldOnClickHandler(
          $scope.properties,
          id,
          "property"
        );
      };
      $scope.switchToManual = (id, value) => {
        EmpowerWealthHelper.addressSwitchToManual(
          $scope.properties,
          id,
          value,
          "property"
        );
      };
      $scope.updateAddressField = (address) => {
        EmpowerWealthHelper.updateAddressField(address);
      };

      $scope.$on("reqData", function (evt, reqData) {
        $scope.reqDataCR = reqData.cr;
        $scope.reqDataMB = reqData.mb;
        $scope.MBChecker = reqData.MBChecker;
        $scope.reqDataLoaded = true;
      });
      /*Testing a custom Angular Service (Kosta)*/
      $scope.empowerData = empowerDataService;
      $scope.forms = {};
      $scope.properties = [];
      $scope.updateToNull = function (val, index) {
        if (val === "") {
          $scope.properties[index].address.state = null;
        }
      };
      $scope.rentalContentValue = null;
      $scope.personalInfo = {};
      $scope.isRenting = false;
      $scope.propertyTypes = [
        "House",
        "Duplex/Semi-Detached House",
        "Apartment",
        "Unit/Flat",
        "Townhouse/Terrace",
        "Villa",
        "Studio",
        "Acreage/Semi-Rural",
        "Alpine",
        "Commercial Property",
        "Commercial Land",
        "Cropping",
        "Dairy",
        "Estate",
        "Farmlet",
        "Horticulture",
        "Livestock",
        "Mixed Farming",
        "Residential Land",
        "Retirement Living",
        "Serviced Apartment",
        "Unitblock",
        "Viticulture",
        "Warehouse",
        "Other",
      ];
      $scope.titleTypes = [
        "Torrens/Freehold",
        "Strata",
        "Company",
        "Leasehold",
        "Stratum",
        "Community",
        "Old System/General Law Title",
        "Qualified Torrens",
        "Limited Torrens",
        "Crown Lease",
      ];
      $scope.conditions = [
        "Brand New",
        "Excellent",
        "Very Good",
        "Good",
        "Average",
        "Poor",
        "Uninhabitable",
      ];
      $scope.rooms = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15];

      $scope.showClientSpinner = true;
      $scope.loadingMessage = "Retrieving Client Information...";
      $scope.showRetrieveError = false;

      $scope.isPersonalInfoCompleted = false;
      $scope.isIncomeCompleted = false;
      $scope.isPropertyCompleted = false;
      $scope.isNonPropertyCompleted = false;
      $scope.isBorrowingCompleted = false;
      $scope.isBillPaymentsCompleted = false;
      $scope.isSpendingCompleted = false;
      $scope.isFPlanHouseholdCompleted = false;
      $scope.isFPlanIncomeCompleted = false;
      $scope.didNextSave = false;

      // Annual Rental Income
      $scope.annualRentalIncome = {};
      $scope.totalAnnualRentalIncome = null;
      // flex check missing
      /*Check if lending needs is turned on*/
      $scope.suitabilityEnabled = false;

      $scope.showBottomButtons = false;
      let toCompare = null;
      $scope.initialProperties = null;
      $scope.showChangesAlert = false;
      $scope.toStateName = "";
      $scope.isSaving = false;

      $scope.closeChangesAlert = function () {
        $scope.newLocation = "";
        $scope.showChangesAlert = false;
        $scope.isSaving = false;
      };

      $scope.dashboardDiscard = async function () {
        $scope.showClientSpinner = true;
        $scope.loadingMessage = "Discarding Client Changes...";
        if ($scope.showChangesAlert) {
          $scope.showChangesAlert = false;
        }

        var promise = new Promise(function (resolve, reject) {
          $timeout(function () {
            $scope.setInputValues(true);
            resolve("done");
          }, 1);
        });

        let result = await promise;
        if (result === "done") {
          $scope.showClientSpinner = false;
          if ($scope.isSaving && $scope.toStateName !== "") {
            $state.go($scope.toStateName, {
              clientId: $scope.clientId,
            });
          } else {
            $scope.showBottomButtons = false;
            $rootScope.setAlertValues(
              true,
              true,
              "Successfully discarded changes!",
              true
            );
          }
        }
      };

      $scope.isInvalidForFlex = function (object) {
        return DataHelper.isObjectInvalid(object) && $scope.suitabilityEnabled;
      };
      $scope.isInvalidForFlexCheckAll = (address) => {
        const addressProps = ["street", "streetType", "suburb", "postcode"];
        return addressProps.some((addressProp) =>
          $scope.isInvalidForFlex(address[addressProp])
        );
      };

      $scope.isQualifiedForMB = function (object, value) {
        if ($scope.reqDataLoaded && $scope.MBChecker) {
          let className = "";
          if (object !== null && typeof object !== "undefined") {
            if (
              $scope.reqDataMB.properties.indexOf(object) > -1 &&
              (value === "" ||
                typeof value === "undefined" ||
                value === null ||
                value !== value ||
                (typeof value.length !== "undefined" && value.length === 0) ||
                (typeof value._isValid !== "undefined" && !value._isValid))
            ) {
              className = "highlight-blue";
            }
          }
          return className;
        }
      };

      function getSuitabilitySetting() {
        DataM.getSuitabilitySetting({ uid: $stateParams.clientId })
          .then(function (settingData) {
            $scope.suitabilityEnabled = false;
            if (settingData.suitabilityEnabled === true) {
              $scope.suitabilityEnabled = true;
            }
          })
          .catch(function (err) {
            console.log(err);
          });
      }

      $scope.annualRentalIncomeListener = function (property) {
        const { grossRentalIncome, purpose } = property;
        if (
          purpose &&
          purpose !== "Investment Property" &&
          purpose !== "Business Use" &&
          grossRentalIncome &&
          grossRentalIncome > 0
        ) {
          // remove gross rental income if property
          // changes to other purpose
          property.grossRentalIncome = null;
          // update bottom calculation
          $scope.rentalIncomeAdded();
        }
      };

      getSuitabilitySetting();

      $scope.clientId = $stateParams.clientId;
      $scope.state = $state.current;
      $scope.params = $stateParams;
      $scope.owners = [];
      $scope.addNewProperty = function () {
        var property = {
          address: {
            country: "",
          },
          landSizeUnit: "sqm",
          material: "Concrete",
          maintenanceCost: null,
          managementFees: null,
          insuranceCosts: null,
          councilRates: null,
          landTaxCosts: null,
          waterRates: null,
          bodyCorpCosts: null,
          contentsValue: null,
          landSize: null,
          internalLivingSpaceSize: null,
          purchasePrice: null,
          currentValue: null,
          datePurchased: null,
          purchaseDatePicker: "",
          bodyCorpCosts: null,
          addressLine: null,
          grossRentalIncome: null,
          managingAgent: "",
          managingAgentContactName: "",
          managingAgentContactNo: "",
          dateSold: null,
          salePrice: null,
          depreciationBuilding: null,
          depreciationFittings: null,
          depreciationYears: null,
          projectedCapitalGrowth: null,
          addlInfo: "",
        };
        property.owner = [];

        // set initial values
        $scope.properties.push(property);
        property.owner = $scope.owners[0];
        $scope.assignOwnership(property, $scope.owners[0]);
      };

      $scope.deleteProperty = function (property) {
        $scope.properties.splice($scope.properties.indexOf(property), 1);
      };

      /**
       * Sorts the properties by purpose
       * (Home occupied first)
       */
      $scope.sortProperties = function () {
        var sortedProperties = [];
        var purposeOrder = [
          "Own Home",
          "Investment",
          "Personal Use",
          "Business Use",
        ];

        sortedProperties = $scope.properties.sort(function (a, b) {
          if (purposeOrder.indexOf(a.purpose) === -1) {
            return 1;
          }
          if (purposeOrder.indexOf(b.purpose) === -1) {
            return -1;
          }
          return (
            purposeOrder.indexOf(a.purpose) - purposeOrder.indexOf(b.purpose)
          );
        });
        $scope.properties = sortedProperties;
      };
      $scope.setPurchaseDate = function (x) {
        var property = $scope.properties[$scope.properties.indexOf(x)];
        var newDate = moment.utc(property.purchaseDatePicker, ["DD/MM/YYYY"]);

        property.datePurchased = newDate;
      };

      $scope.goToLocation = function (location) {
        var proceed = $scope.empowerData.goToLocation(location, $scope);

        if (proceed) {
          $state.go(location, {
            clientId: $scope.clientId,
          });
        }
      };

      /**
       * compute annual rental income
       */
      $scope.rentalIncomeAdded = function () {
        // holder for client 1 or 2 rental income
        var income1 = 0;
        var income2 = 0;
        $scope.totalAnnualRentalIncome = 0;
        for (var i = 0; i < $scope.properties.length; i++) {
          var prop = $scope.properties[i];
          if (
            prop.purpose === "Business Use" ||
            prop.purpose === "Investment Property" ||
            prop.purpose === "Investment"
          ) {
            if (prop.grossRentalIncome > 0) {
              if (prop.ownership) {
                if (prop.ownership.owners.length > 0) {
                  for (var j = 0; j < prop.ownership.owners.length; j++) {
                    var percentage = 1;
                    if (prop.ownership.owners[j]) {
                      if (prop.ownership.owners[j].percentage) {
                        percentage = prop.ownership.owners[j].percentage / 100;
                      }
                      if (
                        prop.ownership.owners[j].owner ===
                        $scope.personalInfo.client1._id
                      ) {
                        income1 += prop.grossRentalIncome * percentage;
                      }
                      if (
                        $scope.personalInfo.client2 &&
                        prop.ownership.owners[j].owner ===
                          $scope.personalInfo.client2._id
                      ) {
                        income2 += prop.grossRentalIncome * percentage;
                      }
                    }
                  }
                }
              } else {
                income1 += prop.grossRentalIncome;
              }
              $scope.totalAnnualRentalIncome += prop.grossRentalIncome;
            }
          }
        }

        $scope.annualRentalIncome.client1GrossRentalIncome = income1;
        $scope.annualRentalIncome.client2GrossRentalIncome = income2;
      };

      $scope.isMBAdmin = false;
      // check if user is admin
      DataM.getMe()
        .then(function (userData) {
          if (userData.role === "super_admin" || userData.role === "mb_admin") {
            $scope.isMBAdmin = true;
          }
        })
        .catch(function (err) {
          console.log("Retrieve current user error");
        });

      $scope.assignValues = function (data) {
        if (data.properties) {
          $scope.properties = data.properties;
          $scope.sortProperties();
        }
        if (data.annualRentalIncome) {
          $scope.annualRentalIncome = data.annualRentalIncome;
          if ($scope.annualRentalIncome.client1GrossRentalIncome > 0) {
            $scope.totalAnnualRentalIncome +=
              $scope.annualRentalIncome.client1GrossRentalIncome;
          }
          if ($scope.annualRentalIncome.client2GrossRentalIncome > 0) {
            $scope.totalAnnualRentalIncome +=
              $scope.annualRentalIncome.client2GrossRentalIncome;
          }
        }
        $scope.personalInfo = data.personalInfo;
        $scope.rentalContentValue = data.rentalContentValue;

        $scope.isPersonalInfoCompleted = data.isPersonalInfoCompleted;
        $scope.isIncomeCompleted = data.isIncomeCompleted;
        $scope.isPropertyCompleted = data.isPropertyCompleted;
        $scope.isNonPropertyCompleted = data.isNonPropertyCompleted;
        $scope.isBorrowingCompleted = data.isBorrowingCompleted;
        $scope.isBillPaymentsCompleted = data.isBillPaymentsCompleted;
        $scope.isSpendingCompleted = data.isSpendingCompleted;
        $scope.isFPlanHouseholdCompleted = data.isFPlanHouseholdCompleted;
        $scope.isFPlanIncomeCompleted = data.isFPlanIncomeCompleted;

        $scope.owners = DataHelper.getOwnerList(data, false, true, true);

        $scope.isRenting = false;
        if ($scope.personalInfo.client1) {
          if ($scope.personalInfo.client1.tenure == "Renting") {
            $scope.isRenting = true;
          }
        }
        if ($scope.personalInfo.client2) {
          if ($scope.personalInfo.client2.tenure == "Renting") {
            $scope.isRenting = true;
          }
        }

        for (var $i = 0; $i < $scope.properties.length; $i++) {
          var property = $scope.properties[$i];
          if (property.hasOwnProperty("datePurchased")) {
            property.purchaseDatePicker =
              empowerDataService.generatedLocalDateString(
                property.datePurchased
              );
            property.datePurchased = empowerDataService.generateUTCDate(
              property.datePurchased
            );
          }

          if (property.ownership) {
            if (property.ownership.ownershipType == "Joint") {
              for (var key in $scope.owners) {
                if ($scope.owners[key].name == "Joint") {
                  property.owner = $scope.owners[key];
                }
              }

              property.percentage1 = property.ownership.owners[0].percentage;
              if (
                property.ownership.owners[1] &&
                property.ownership.owners[1].percentage
              ) {
                property.percentage2 = property.ownership.owners[1].percentage;
              } else {
                property.percentage2 = 0;
              }
            }

            // determine owner
            else if (property.ownership.ownershipType != "Joint") {
              var ownershipType = property.ownership.ownershipType;
              for (var $k = 0; $k < $scope.owners.length; $k++) {
                var ownerListOption = $scope.owners[$k];

                if (ownershipType == "Sole") {
                  if (
                    property.ownership.owners[0] &&
                    property.ownership.owners[0].owner == ownerListOption.id
                  ) {
                    property.percentage1 = 100;
                    property.owner = ownerListOption;
                  }
                } else if (ownershipType == "Tenants in Common") {
                  property.owner = $scope.owners[$scope.owners.length - 2];
                  property.percentage1 =
                    property.ownership.owners[0].percentage;
                  if (
                    data.personalInfo.client2 &&
                    property.ownership.owners[1]
                  ) {
                    property.percentage2 =
                      property.ownership.owners[1].percentage;
                  }
                } else if (ownershipType == "Other") {
                  property.ownerOther = property.ownership.ownershipDesc;
                  property.owner = ownerListOption;
                }
              }
            }
          } else {
            $scope.assignOwnership(property, $scope.owners[0]);
          }
          if (property.capitalGrowth) {
            $scope.calcCapitalGrowth(property);
          }
          if (property.address) {
            $scope.updateAddressField(property.address);
            EmpowerWealthHelper.initAddressField(property.address);
          }
        }
        if (
          $scope.totalAnnualRentalIncome === null ||
          $scope.totalAnnualRentalIncome < 1
        ) {
          $scope.rentalIncomeAdded();
        }
      };
      $scope.updateProperyFormatted = function (address) {
        address.formatted = [
          address.floor,
          address.unit,
          address.number,
          address.street,
          address.streetType,
          address.suburb,
          address.postcode,
          address.state,
        ].join(" ");
      };

      $scope.setInputValues = function (isDiscard) {
        $scope.properties = [];
        $scope.rentalContentValue = null;
        $scope.personalInfo = {};
        $scope.isRenting = false;
        $scope.annualRentalIncome = {};
        $scope.totalAnnualRentalIncome = null;

        if (!isDiscard) {
          DataM.getProperties()
            .then(function (data) {
              if (_.isNil(data.properties)) {
                // if the user is newly created, this varible is undefined
                // in the database, so we need to add an empty data for it
                // so that it will not cause any inconsistency on comapring
                // initial scope variable to toCompare
                data.properties = [];
              }
              toCompare = data;
              var promise = new Promise(function (resolve, reject) {
                $scope.assignValues(data);
                resolve("done");
              });

              promise.then(function (data) {
                if (data === "done") {
                  $scope.showClientSpinner = false;
                }
              });
            })
            .catch(function (err) {
              console.log("Retrieve error", err);
              $scope.showRetrieveError = true;
            });
        } else {
          var data = JSON.parse($scope.initialProperties);
          toCompare = data;
          $scope.assignValues(data);
        }
      };

      $scope.setInputValues(false);

      $scope.changeBottomButtonState = function () {
        if ($scope.initialProperties !== null && toCompare !== null) {
          var newToCompare = JSON.stringify(toCompare, function (key, value) {
            if (key === "$$hashKey") {
              return undefined;
            }
            if (key === "$promise") {
              return undefined;
            }
            if (key === "$resolved") {
              return undefined;
            }
            if (key === "capitalGrowth") {
              return undefined;
            }
            if (key === "capitalGrowthDisplay") {
              return undefined;
            }
            if (key === "purchaseDatePicker") {
              return undefined;
            }
            return value;
          });
          if ($scope.initialProperties !== newToCompare) {
            $scope.showBottomButtons = true;
          } else {
            $scope.showBottomButtons = false;
          }
        } else if ($scope.initialProperties === null && toCompare !== null) {
          $scope.initialProperties = JSON.stringify(
            toCompare,
            function (key, value) {
              if (key === "$$hashKey") {
                return undefined;
              }
              if (key === "$promise") {
                return undefined;
              }
              if (key === "$resolved") {
                return undefined;
              }
              if (key === "capitalGrowth") {
                return undefined;
              }
              if (key === "capitalGrowthDisplay") {
                return undefined;
              }
              if (key === "purchaseDatePicker") {
                return undefined;
              }
              return value;
            }
          );
        }
      };

      $scope.$watch(
        "[properties, rentalContentValue, isRenting, annualRentalIncome, totalAnnualRentalIncome]",
        function () {
          $scope.changeBottomButtonState();
        },
        true
      );

      $scope.save = function (location) {
        if ($scope.showChangesAlert) {
          $scope.showChangesAlert = false;
        }
        $scope.showClientSpinner = true;
        $scope.loadingMessage = "Saving Client Information...";

        for (var $i = 0; $i < $scope.properties.length; $i++) {
          if ($scope.properties[$i].hasOwnProperty("address")) {
            //empowerDataService.generateAddressFull($scope.householdClients[0].address);
            empowerDataService.generateAddressFull(
              $scope.properties[$i].address
            );
          }
        }

        $scope.properties.map((val, i) => {
          if (
            !_.isNil(val.ownership) &&
            !_.isNil(val.ownership.ownershipType)
          ) {
            if (
              val.ownership.ownershipType === "Sole" &&
              val.ownership.owners.length === 2
            ) {
              $scope.properties[i].ownership.owners.splice(1, 1);
            } else if (
              val.ownership.ownershipType === "Other" &&
              val.ownership.owners.length > 0
            ) {
              $scope.properties[i].ownership.owners = [];
            }
          }
        });

        var formData = {
          properties: $scope.properties,
          annualRentalIncome: $scope.annualRentalIncome,
          rentalContentValue: $scope.rentalContentValue,
        };
        //SAVE TO DB

        DataM.saveProperties(formData)
          .then(function (data) {
            var newData = toCompare;
            $scope.initialProperties = newData;

            if (data._message === "Client validation failed") {
              var errorMessage = "";
              const formattedMessage = DataHelper.formatMongoError(data);
              $scope.showClientSpinner = false;

              $scope.didNextSave = false;
              alert(formattedMessage);
            } else {
              if ($scope.isSaving && $scope.toStateName !== "") {
                $state.go($scope.toStateName, {
                  clientId: $scope.clientId,
                });
              } else {
                if ($location.$$url.includes("property-assets")) {
                  $rootScope.setAlertValues(
                    true,
                    true,
                    "Successfully saved changes!",
                    false
                  );
                  $scope.initialProperties = null;
                  $scope.initialProperties = JSON.stringify(
                    toCompare,
                    function (key, value) {
                      if (key === "$$hashKey") {
                        return undefined;
                      }
                      if (key === "$promise") {
                        return undefined;
                      }
                      if (key === "$resolved") {
                        return undefined;
                      }
                      if (key === "capitalGrowth") {
                        return undefined;
                      }
                      if (key === "capitalGrowthDisplay") {
                        return undefined;
                      }
                      if (key === "purchaseDatePicker") {
                        return undefined;
                      }
                      return value;
                    }
                  );
                  $scope.changeBottomButtonState();
                } else {
                  $state.go(location, {
                    clientId: $scope.clientId,
                  });
                }
              }
              $scope.showClientSpinner = false;
            }
            $rootScope.$broadcast("onSaved");
          })
          .catch(function (err) {
            console.log("test err", err);
            $rootScope.setAlertValues(
              true,
              false,
              "There is a problem accessing the server.",
              true
            );
            alert(
              "There is a problem accessing the server. Please make sure your internet connection is working. If it is working and this problem persist, please report this problem to Empower Wealth team."
            );
            $scope.showClientSpinner = false;
          });
      };

      $scope.assignOwnership = function (
        property,
        owner,
        initialOwnershipDesc
      ) {
        var p = $scope.properties[$scope.properties.indexOf(property)];

        if (owner.name == "Joint") {
          if ($scope.personalInfo.client2) {
            if (_.isNil(p.ownership)) {
              p.ownership = {
                ownershipType: "Joint",
                ownershipDesc: initialOwnershipDesc,
                owners: [
                  {
                    percentage: property.percentage1,
                    owner: $scope.personalInfo.client1._id,
                  },
                  {
                    percentage: property.percentage2,
                    owner: $scope.personalInfo.client2._id,
                  },
                ],
              };
            } else {
              p.ownership.ownershipType = "Joint";
              p.ownership.ownershipDesc = initialOwnershipDesc;
              if (!_.isNil(p.ownership.owners)) {
                if (p.ownership.owners.length === 1) {
                  p.ownership.owners[0].percentage = property.percentage1;
                  p.ownership.owners[0].owner = $scope.personalInfo.client1._id;
                  p.ownership.owners.push({
                    percentage: property.percentage2,
                    owner: $scope.personalInfo.client2._id,
                  });
                } else if (p.ownership.owners.length === 2) {
                  p.ownership.owners[0].percentage = property.percentage1;
                  p.ownership.owners[0].owner = $scope.personalInfo.client1._id;
                  (p.ownership.owners[1].percentage = property.percentage2),
                    (p.ownership.owners[1].owner =
                      $scope.personalInfo.client2._id);
                } else if (p.ownership.owners.length === 0) {
                  p.ownership.owners.push({
                    percentage: property.percentage1,
                    owner: $scope.personalInfo.client1._id,
                  });
                  p.ownership.owners.push({
                    percentage: property.percentage2,
                    owner: $scope.personalInfo.client2._id,
                  });
                }
              }
            }
          }
        } else if (owner.name == "Tenants in Common") {
          p.ownership = {
            ownershipType: "Tenants in Common",
            ownershipDesc: initialOwnershipDesc,
            owners: [
              {
                percentage: property.percentage1,
                name:
                  $scope.personalInfo.client1.fName +
                  " " +
                  $scope.personalInfo.client1.lName,
                owner: $scope.personalInfo.client1._id,
              },
            ],
          };
          if ($scope.personalInfo.client2) {
            var owner2 = {
              percentage: property.percentage2,
              name:
                $scope.personalInfo.client2.fName +
                " " +
                $scope.personalInfo.client2.lName,
              owner: $scope.personalInfo.client2._id,
            };
            p.ownership.owners.push(owner2);
          }
        } else if (owner.name == "Other") {
          if (_.isNil(p.ownership)) {
            p.ownership = {
              ownershipType: "Other",
              ownershipDesc: !_.isNil(p.ownerOther)
                ? p.ownerOther
                : initialOwnershipDesc,
            };
          } else {
            p.ownership.ownershipType = "Other";
            p.ownership.ownershipDesc = !_.isNil(p.ownerOther)
              ? p.ownerOther
              : initialOwnershipDesc;
            if (
              p.ownership.owners.length === 1 &&
              _.isNil(p.ownership.owners[0]._id)
            ) {
              p.ownership.owners = [];
            } else if (
              p.ownership.owners.length === 2 &&
              _.isNil(p.ownership.owners[0]._id) &&
              _.isNil(p.ownership.owners[1]._id)
            ) {
              p.ownership.owners = [];
            }
          }
        } else {
          if (_.isNil(p.ownership)) {
            p.ownership = {
              ownershipType: "Sole",
              ownershipDesc: initialOwnershipDesc,
              owners: [
                {
                  percentage: 100,
                  owner: owner.id,
                },
              ],
            };
          } else {
            p.ownership.ownershipType = "Sole";
            p.ownership.ownershipDesc = initialOwnershipDesc;
            if (!_.isNil(p.ownership.owners) && p.ownership.owners.length > 0) {
              p.ownership.owners[0].percentage = 100;
              p.ownership.owners[0].owner = owner.id;
            } else {
              p.ownership.owners.push({
                percentage: 100,
                owner: owner.id,
              });
            }
            if (
              p.ownership.owners.length === 2 &&
              _.isNil(p.ownership.owners[1]._id)
            ) {
              p.ownership.owners.splice(1, 1);
            }
          }
        }
      };

      $scope.calcCapitalGrowth = function (property) {
        var p = $scope.properties[$scope.properties.indexOf(property)];
        var purchaseDate = p.datePurchased.year();
        var currentYear = moment().year();
        var yearDifference = currentYear - purchaseDate;

        var valueRatio = p.currentValue / p.purchasePrice;
        var CAGR = valueRatio ** (1 / yearDifference) - 1;

        p.capitalGrowth = Number((CAGR * 100).toFixed(2)) / 100;
        p.capitalGrowthDisplay = `${(p.capitalGrowth * 100).toFixed(2)}%`;
        if (p.capitalGrowth < 0) {
          p.capitalGrowth = 0;
        }
      };

      $scope.next = function () {
        // check if form is valid
        $scope.save("fact-find4");
      };
      $scope.back = function () {
        // check if form is valid
        $state.go("fact-find2", {
          clientId: $scope.clientId,
        });
      };
      $scope.quit = function () {
        $state.go("dashboard");
      };

      $scope.savedText = "Save";

      $scope.showAlertValidationModal = function () {
        $scope.alertMessage = $scope.empowerData.saveValidationMessage;
        $scope.showAlertModal = true;
      };
      $scope.closeModal = function () {
        $scope.showAlertModal = false;

        if ($scope.elementToFocus) {
          $timeout($scope.elementToFocus.focus(), 1500);
        }
      };
      $scope.showConfirmSaveModal = function (page) {
        if (!$scope.didNextSave) {
          //$scope.toShowConfirmSaveModal = true;
          $scope.nextPageLocation = page;
        }
      };
      $scope.goNextAndNotSaveModal = function () {
        //$scope.toShowConfirmSaveModal = false;
        $state.go($scope.nextPageLocation, {
          clientId: $scope.clientId,
        });
      };
      $scope.goNextAndSaveModal = function () {
        //$scope.toShowConfirmSaveModal = false;
        $scope.didNextSave = true;
        $scope.save($scope.nextPageLocation);
      };
      $scope.closeConfirmModal = function () {
        //$scope.toShowConfirmSaveModal = false;
        $scope.savedText = "Save";
        $scope.isSaving = false;
      };
      $scope.$on(
        "$stateChangeStart",
        function (event, toState, toParams, fromState, fromParams) {
          if (_.isEqual(toState.name, "login")) {
          } else if (
            toState.url.indexOf("property-assets") == -1 &&
            !$scope.isSaving &&
            !$scope.didNextSave
          ) {
            $scope.isSaving = true;
            $scope.toStateName = toState.name;
            event.preventDefault();
            if ($scope.showBottomButtons) {
              $scope.showChangesAlert = true;
            } else {
              $state.go(toState.name, {
                clientId: $scope.clientId,
              });
              return;
            }
          }
        }
      );

      $scope.dashboardSave = function () {
        const setInitialAddressState = function () {
          $scope.properties.filter(Boolean).map((i) => {
            if (i.address) EmpowerWealthHelper.initAddressField(i.address);
            return i;
          });
        };
        var location = "property-assets";
        if ($scope.isSaving && $scope.toStateName !== "") {
          location = $scope.toStateName;
        }
        setInitialAddressState();
        $scope.save(location);
      };
    }
  )

  .controller(
    "factfind-4-controller",
    function (
      $scope,
      $rootScope,
      $state,
      $stateParams,
      $location,
      $window,
      DataM,
      Auth,
      empowerDataService,
      DataHelper,
      $timeout
    ) {
      "use strict";
      $scope.isAdmin = false;
      Auth.isAdmin().then((isAdmin) => ($scope.isAdmin = isAdmin));

      $scope.reqDataCR = {};
      $scope.reqDataMB = {};
      $scope.reqDataLoaded = false;
      $scope.MBChecker = false;

      $scope.$on("reqData", function (evt, reqData) {
        $scope.reqDataCR = reqData.cr;
        $scope.reqDataMB = reqData.mb;
        $scope.MBChecker = reqData.MBChecker;
        $scope.reqDataLoaded = true;
      });

      $scope.bankAccounts = [];
      $scope.vehicles = [];
      $scope.lifeInsurance = [];
      $scope.investments = [];
      $scope.superFunds = [];
      $scope.otherAssets = [];
      $scope.rentalContentValue = null;
      $scope.forms = {};
      /*Testing a custom Angular Service (Kosta)*/
      $scope.empowerData = empowerDataService;
      $scope.showClientSpinner = true;
      $scope.loadingMessage = "Retrieving Client Information...";
      $scope.showRetrieveError = false;
      $scope.isPersonalInfoCompleted = false;
      $scope.isIncomeCompleted = false;
      $scope.isPropertyCompleted = false;
      $scope.isNonPropertyCompleted = false;
      $scope.isBorrowingCompleted = false;
      $scope.isBillPaymentsCompleted = false;
      $scope.isSpendingCompleted = false;
      $scope.isFPlanHouseholdCompleted = false;
      $scope.isFPlanIncomeCompleted = false;
      $scope.didNextSave = false;

      $scope.personalInfo = {};
      //$scope.owners = ['Joint Tenants', 'Tenants in Common', 'Other'];
      $scope.owners = ["Joint", "Tenants in Common", "Other"];
      $scope.household = 1;

      $scope.clientId = $stateParams.clientId;
      $scope.state = $state.current;
      $scope.params = $stateParams;

      // flex check missing
      /*Check if lending needs is turned on*/
      $scope.suitabilityEnabled = false;

      $scope.showBottomButtons = false;
      let toCompare = null;
      $scope.initialOtherAssets = null;
      $scope.showChangesAlert = false;
      $scope.toStateName = "";
      $scope.isSaving = false;

      $scope.closeChangesAlert = function () {
        $scope.newLocation = "";
        $scope.showChangesAlert = false;
        $scope.isSaving = false;
      };

      $scope.dashboardDiscard = async function () {
        $scope.showClientSpinner = true;
        $scope.loadingMessage = "Discarding Client Changes...";
        if ($scope.showChangesAlert) {
          $scope.showChangesAlert = false;
        }

        var promise = new Promise(function (resolve, reject) {
          $timeout(function () {
            $scope.setInputValues(true);
            resolve("done");
          }, 1);
        });

        let result = await promise;
        if (result === "done") {
          $scope.showClientSpinner = false;
          if ($scope.isSaving && $scope.toStateName !== "") {
            $state.go($scope.toStateName, {
              clientId: $scope.clientId,
            });
          } else {
            $scope.showBottomButtons = false;
            $rootScope.setAlertValues(
              true,
              true,
              "Successfully discarded changes!",
              true
            );
          }
        }
      };

      $scope.investmentContributionListener = function (investment) {
        investment.contributionAmount = "";
        investment.contributionFrequency = "Yearly";
        investment.contributionEndDate = null;
        investment.contributionStartDate = null;
        investment.contributionStartDatePicker = null;
        investment.contributionEndDatePicker = null;
      };

      $scope.isInvalidForFlex = function (object) {
        return DataHelper.isObjectInvalid(object) && $scope.suitabilityEnabled;
      };

      $scope.isQualifiedForMB = function (object, value) {
        if ($scope.reqDataLoaded && $scope.MBChecker) {
          let className = "";
          if (object !== null && typeof object !== "undefined") {
            if (
              $scope.reqDataMB.assets.indexOf(object) > -1 &&
              (value === "" ||
                typeof value === "undefined" ||
                value === null ||
                value !== value ||
                (typeof value.length !== "undefined" && value.length === 0) ||
                (typeof value._isValid !== "undefined" && !value._isValid))
            ) {
              className = "highlight-blue";
            } else if (
              $scope.reqDataMB.assets.indexOf(object) > -1 &&
              typeof value.length !== "undefined" &&
              value.length > 0 &&
              object === "superFunds.ownership"
            ) {
              className = "highlight-blue";
            }
          }
          return className;
        }
      };

      function getSuitabilitySetting() {
        DataM.getSuitabilitySetting({ uid: $stateParams.clientId })
          .then(function (settingData) {
            $scope.suitabilityEnabled = false;
            if (settingData.suitabilityEnabled === true) {
              $scope.suitabilityEnabled = true;
            }
          })
          .catch(function (err) {
            console.log(err);
          });
      }
      getSuitabilitySetting();
      $scope.addNewBankAccount = function () {
        var account = {};
        account.owner = [];
        account.percentage1 = 0;
        account.percentage2 = 0;

        if ($scope.personalInfo) {
          var data = {
            personalInfo: $scope.personalInfo,
          };
          account.ownerList = DataHelper.getOwnerList(data, false, true);
          account.interestRate = null;
          account.balance = null;
          account.accountType = null;
        }
        $scope.bankAccounts.push(account);
      };

      $scope.deleteBankAccount = function (account) {
        $scope.bankAccounts.splice($scope.bankAccounts.indexOf(account), 1);
      };

      $scope.setPrimaryAccount = function (account) {
        account.isPrimary = true;
        for (var i = 0; i < $scope.bankAccounts.length; i++) {
          if (i != $scope.bankAccounts.indexOf(account)) {
            $scope.bankAccounts[i].isPrimary = false;
          }
        }
      };
      $scope.addNewShares = function () {
        var share = {};
        $scope.shares.unshift(share);
      };

      $scope.deleteShares = function (share) {
        $scope.shares.splice($scope.shares.indexOf(share), 1);
      };

      $scope.addNewVehicle = function () {
        var vehicle = {};
        vehicle.owner = [];

        if ($scope.personalInfo) {
          var data = {
            personalInfo: $scope.personalInfo,
          };
          vehicle.ownerList = DataHelper.getOwnerList(data, false, true);
          vehicle.value = null;
          vehicle.manufacturer = "";
        }

        $scope.vehicles.push(vehicle);
      };

      $scope.deleteVehicle = function (vehicle) {
        $scope.vehicles.splice($scope.vehicles.indexOf(vehicle), 1);
      };

      $scope.addNewLifeInsurance = function () {
        var insurance = {};
        insurance.owner = [];
        if ($scope.personalInfo) {
          var data = {
            personalInfo: $scope.personalInfo,
          };
          insurance.ownerList = DataHelper.getOwnerList(data, false, false);
          insurance.value = null;
          insurance.addlInfo = "";
        }
        $scope.lifeInsurance.push(insurance);
      };

      $scope.deleteLifeInsurance = function (insurance) {
        $scope.lifeInsurance.splice($scope.lifeInsurance.indexOf(insurance), 1);
      };

      $scope.addNewInvestment = function () {
        var investment = {};

        investment.owner = [];
        if ($scope.personalInfo) {
          var data = {
            personalInfo: $scope.personalInfo,
          };
          investment.ownerList = DataHelper.getOwnerList(data, false, true);
          investment.provider = "";
          investment.yearlyGrowth = null;
          investment.startDate = null;
          investment.initialValue = null;
          investment.currentValue = null;
          investment.projectedGrowth = null;
          investment.yearlyIncome = "";
          investment.addlInfo = "";
          investment.startDatePicker = "";
          investment.contributionAmount = "";
          investment.contributionFrequency = "Yearly";
          investment.contributionEndDate = null;
          investment.contributionStartDate = null;
        }
        $scope.investments.push(investment);
      };

      $scope.deleteInvestment = function (investment) {
        $scope.investments.splice($scope.investments.indexOf(investment), 1);
      };

      $scope.addNewSuperFund = function () {
        var superfund = {};

        superfund.owner = [];
        if ($scope.personalInfo) {
          var data = {
            personalInfo: $scope.personalInfo,
          };
          superfund.ownerList = DataHelper.getOwnerList(data, false, true);
          superfund.value = null;
          superfund.salarySacrificeClient1 = null;
          superfund.salarySacrificeClient2 = null;
          superfund.personalContributionsClient1 = null;
          superfund.personalContributionsClient2 = null;
          superfund.salarySacrificeClient2 = null;
          superfund.strategy = null;
          superfund.product = null;
        }
        $scope.superFunds.push(superfund);
      };

      $scope.deleteSuperFund = function (superfund) {
        $scope.superFunds.splice($scope.superFunds.indexOf(superfund), 1);
      };

      $scope.addNewOther = function () {
        var other = {};

        other.owner = [];

        if ($scope.personalInfo) {
          var data = {
            personalInfo: $scope.personalInfo,
          };
          other.ownerList = DataHelper.getOwnerList(data, false, true);
          other.value = null;
          other.desc = "";
        }
        $scope.otherAssets.push(other);
      };

      $scope.deleteOther = function (other) {
        $scope.otherAssets.splice($scope.otherAssets.indexOf(other), 1);
      };

      $scope.assignOwnership = function (
        asset,
        owner,
        assetType,
        initialOwnershipDesc
      ) {
        var asset;

        switch (assetType) {
          case "BankAccount":
            var asset = $scope.bankAccounts[$scope.bankAccounts.indexOf(asset)];
            break;
          case "Vehicle":
            var asset = $scope.vehicles[$scope.vehicles.indexOf(asset)];
            break;
          case "Investment":
            var asset = $scope.investments[$scope.investments.indexOf(asset)];
            break;
          case "Other":
            var asset = $scope.otherAssets[$scope.otherAssets.indexOf(asset)];
            break;
          case "SuperFund":
            var asset = $scope.superFunds[$scope.superFunds.indexOf(asset)];
            break;
          case "LifeInsurance":
            var asset =
              $scope.lifeInsurance[$scope.lifeInsurance.indexOf(asset)];
            break;
        }
        if (owner.name == "Joint") {
          if ($scope.personalInfo.client2) {
            if (_.isNil(asset.ownership)) {
              asset.ownership = {
                ownershipType: "Joint",
                ownershipDesc: initialOwnershipDesc,
                owners: [
                  {
                    percentage:
                      assetType == "BankAccount" ? 50 : asset.percentage1,
                    owner: $scope.personalInfo.client1._id,
                  },
                  {
                    percentage:
                      assetType == "BankAccount" ? 50 : asset.percentage2,
                    owner: $scope.personalInfo.client2._id,
                  },
                ],
              };
            } else {
              asset.ownership.ownershipType = "Joint";
              asset.ownership.ownershipDesc = initialOwnershipDesc;
              if (!_.isNil(asset.ownership.owners)) {
                if (asset.ownership.owners.length === 1) {
                  asset.ownership.owners[0].percentage =
                    assetType == "BankAccount" ? 50 : asset.percentage1;
                  asset.ownership.owners[0].owner =
                    $scope.personalInfo.client1._id;
                  asset.ownership.owners.push({
                    percentage:
                      assetType == "BankAccount" ? 50 : asset.percentage2,
                    owner: $scope.personalInfo.client2._id,
                  });
                } else if (asset.ownership.owners.length === 2) {
                  asset.ownership.owners[0].percentage =
                    assetType == "BankAccount" ? 50 : asset.percentage1;
                  asset.ownership.owners[0].owner =
                    $scope.personalInfo.client1._id;
                  (asset.ownership.owners[1].percentage =
                    assetType == "BankAccount" ? 50 : asset.percentage2),
                    (asset.ownership.owners[1].owner =
                      $scope.personalInfo.client2._id);
                } else if (asset.ownership.owners.length === 0) {
                  asset.ownership.owners.push({
                    percentage:
                      assetType == "BankAccount" ? 50 : asset.percentage1,
                    owner: $scope.personalInfo.client1._id,
                  });
                  asset.ownership.owners.push({
                    percentage:
                      assetType == "BankAccount" ? 50 : asset.percentage2,
                    owner: $scope.personalInfo.client2._id,
                  });
                }
              }
            }
          }
        } else if (owner.name == "Tenants in Common") {
          asset.ownership = {
            ownershipType: "Tenants in Common",
            ownershipDesc: initialOwnershipDesc,
            owners: [
              {
                percentage: asset.percentage1,
                name:
                  $scope.personalInfo.client1.fName +
                  " " +
                  $scope.personalInfo.client1.lName,
                owner: $scope.personalInfo.client1._id,
              },
            ],
          };
          if ($scope.personalInfo.client2) {
            var owner2 = {
              percentage: asset.percentage2,
              name:
                $scope.personalInfo.client2.fName +
                " " +
                $scope.personalInfo.client2.lName,
              owner: $scope.personalInfo.client2._id,
            };
            asset.ownership.owners.push(owner2);
          }
        } else if (owner.name == "Other") {
          if (_.isNil(asset.ownership)) {
            asset.ownership = {
              ownershipType: "Other",
              ownershipDesc: !_.isNil(asset.ownerOther)
                ? asset.ownerOther
                : initialOwnershipDesc,
            };
          } else {
            asset.ownership.ownershipType = "Other";
            asset.ownership.ownershipDesc = !_.isNil(asset.ownerOther)
              ? asset.ownerOther
              : initialOwnershipDesc;
            if (
              asset.ownership.owners.length === 1 &&
              _.isNil(asset.ownership.owners[0]._id)
            ) {
              asset.ownership.owners = [];
            } else if (
              asset.ownership.owners.length === 2 &&
              _.isNil(asset.ownership.owners[0]._id) &&
              _.isNil(asset.ownership.owners[1]._id)
            ) {
              asset.ownership.owners = [];
            }
          }
        } else {
          var percentage = 100;
          var ownershipType = "Sole";
          if (_.isNil(asset.ownership)) {
            asset.ownership = {
              ownershipType: ownershipType,
              ownershipDesc: initialOwnershipDesc,
              owners: [
                {
                  percentage: percentage,
                  owner: owner.id,
                },
              ],
            };
          } else {
            asset.ownership.ownershipType = ownershipType;
            asset.ownership.ownershipDesc = initialOwnershipDesc;
            if (
              !_.isNil(asset.ownership.owners) &&
              asset.ownership.owners.length > 0
            ) {
              asset.ownership.owners[0].percentage = percentage;
              asset.ownership.owners[0].owner = owner.id;
            } else {
              asset.ownership.owners.push({
                percentage: percentage,
                owner: owner.id,
              });
            }
            if (
              asset.ownership.owners.length === 2 &&
              _.isNil(asset.ownership.owners[1]._id)
            ) {
              asset.ownership.owners.splice(1, 1);
            }
          }
        }
      };

      $scope.setDate = function (investment, type = null) {
        if (type) {
          switch (type) {
            case "Start":
              investment.contributionStartDate = moment.utc(
                investment.contributionStartDatePicker,
                ["DD/MM/YYYY"]
              );
              break;
            case "End":
              investment.contributionEndDate = moment.utc(
                investment.contributionEndDatePicker,
                ["DD/MM/YYYY"]
              );
              break;
          }
        } else {
          investment.startDate = moment.utc(investment.startDatePicker, [
            "DD/MM/YYYY",
          ]);
        }
      };

      $scope.calcYearlyGrowthRate = function (investment) {
        var localInvestment =
          $scope.investments[$scope.investments.indexOf(investment)];
        var investmentDate = moment.utc(localInvestment.startDatePicker, [
          "DD/MM/YYYY",
        ]);

        var investmentDay = investmentDate.date();
        var investmentMonth = investmentDate.month();
        var investmentYear = investmentDate.year();

        var todayDate = moment.utc();
        var todayYear = todayDate.year();
        var todayMonth = todayDate.month();
        var todayDay = todayDate.date();
        var totalYears = todayYear - investmentYear;

        if (todayMonth < investmentMonth - 1) {
          totalYears--;
        }
        if (investmentMonth - 1 === todayMonth && todayDay < investmentDay) {
          totalYears--;
        }
        if (totalYears < 1) {
          totalYears = 0;

          var monthsGap = monthDiff(investmentDate, todayDate) + 1;

          totalYears = monthsGap / 12;
        }
        var initial = localInvestment.initialValue;
        var current = localInvestment.currentValue;

        var difference = current - initial;
        var amtPerYear = difference / totalYears;

        var tempYearlyGrowth = ((amtPerYear / initial) * 100).toFixed(2);

        if (
          tempYearlyGrowth === 0 ||
          tempYearlyGrowth === "Infinity" ||
          tempYearlyGrowth === "NaN"
        ) {
          localInvestment.yearlyGrowth = null;
        } else {
          localInvestment.yearlyGrowth = tempYearlyGrowth;
        }

        if (isNaN(localInvestment.yearlyGrowth)) {
          localInvestment.yearlyGrowth = 0;
        }

        if (todayYear == localInvestment.startYear) {
          localInvestment.yearlyGrowth = 0;
        }
        localInvestment.yearlyGrowthIsValid =
          !isNaN(localInvestment.yearlyGrowth) &&
          isFinite(localInvestment.yearlyGrowth);
      };

      function monthDiff(d1, d2) {
        var months;
        months = (d2.year() - d1.year()) * 12;
        months -= d1.month() + 1;
        months += d2.month();
        return months <= 0 ? 0 : months;
      }
      $scope.goToLocation = function (location) {
        var proceed = $scope.empowerData.goToLocation(location, $scope);

        if (proceed) {
          $state.go(location, {
            clientId: $scope.clientId,
          });
        }
      };
      $scope.isMBAdmin = false;
      // check if user is admin
      DataM.getMe()
        .then(function (userData) {
          if (userData.role === "super_admin" || userData.role === "mb_admin") {
            $scope.isMBAdmin = true;
          }
        })
        .catch(function (err) {
          console.log("Retrieve current user error");
        });

      $scope.assignValues = function (data) {
        if (data.otherAssets && data.otherAssets.bankAccounts) {
          $scope.bankAccounts = DataHelper.formatOwnership(
            data.otherAssets.bankAccounts,
            data.personalInfo,
            DataHelper.getOwnerList(data, false, true, true)
          );
        }
        if (data.otherAssets && data.otherAssets.vehicles) {
          $scope.vehicles = DataHelper.formatOwnership(
            data.otherAssets.vehicles,
            data.personalInfo,
            DataHelper.getOwnerList(data, false, true)
          );
        }
        if (data.otherAssets && data.otherAssets.lifeInsurance) {
          $scope.lifeInsurance = DataHelper.formatOwnership(
            data.otherAssets.lifeInsurance,
            data.personalInfo,
            DataHelper.getOwnerList(data, false, false)
          );
        }
        if (data.otherAssets && data.otherAssets.superFunds) {
          $scope.superFunds = DataHelper.formatOwnership(
            data.otherAssets.superFunds,
            data.personalInfo,
            DataHelper.getOwnerList(data, false, true)
          );
        }
        if (data.otherAssets && data.otherAssets.investments) {
          $scope.investments = DataHelper.formatOwnership(
            data.otherAssets.investments,
            data.personalInfo,
            DataHelper.getOwnerList(data, false, true)
          );
        }
        if (data.otherAssets && data.otherAssets.otherAssets) {
          $scope.otherAssets = DataHelper.formatOwnership(
            data.otherAssets.otherAssets,
            data.personalInfo,
            DataHelper.getOwnerList(data, false, true)
          );
        }
        $scope.personalInfo = data.personalInfo;

        $scope.isPersonalInfoCompleted = data.isPersonalInfoCompleted;
        $scope.isIncomeCompleted = data.isIncomeCompleted;
        $scope.isPropertyCompleted = data.isPropertyCompleted;
        $scope.isNonPropertyCompleted = data.isNonPropertyCompleted;
        $scope.isBorrowingCompleted = data.isBorrowingCompleted;
        $scope.isBillPaymentsCompleted = data.isBillPaymentsCompleted;
        $scope.isSpendingCompleted = data.isSpendingCompleted;
        $scope.isFPlanHouseholdCompleted = data.isFPlanHouseholdCompleted;
        $scope.isFPlanIncomeCompleted = data.isFPlanIncomeCompleted;

        /*Retrieve Date Object for Investment Start Date, and separate.*/
        for (var $i = 0; $i < $scope.investments.length; $i++) {
          var newDate = empowerDataService.generateUTCDate(
            $scope.investments[$i].startDate
          );
          $scope.investments[$i].startDatePicker =
            empowerDataService.generatedLocalDateString(
              $scope.investments[$i].startDate
            );
          $scope.investments[$i].startDate = newDate;

          var contStartDate = empowerDataService.generateUTCDate(
            $scope.investments[$i].contributionStartDate
          );
          $scope.investments[$i].contributionStartDatePicker =
            empowerDataService.generatedLocalDateString(
              $scope.investments[$i].contributionStartDate
            );
          $scope.investments[$i].contributionStartDate = contStartDate;

          var contEndDate = empowerDataService.generateUTCDate(
            $scope.investments[$i].contributionEndDate
          );
          $scope.investments[$i].contributionEndDatePicker =
            empowerDataService.generatedLocalDateString(
              $scope.investments[$i].contributionEndDate
            );
          $scope.investments[$i].contributionEndDate = contEndDate;

          $scope.investments[$i].yearlyGrowthIsValid =
            !isNaN($scope.investments[$i].yearlyGrowth) &&
            isFinite($scope.investments[$i].yearlyGrowth);
        }

        /** This filter is for hiding the joint option */
        $scope.shouldHideJoint = function (ownerListOption) {
          return ownerListOption.name !== "Joint";
        };

        DataM.getProperties()
          .then(function (propertiesData) {
            $scope.showClientSpinner = false;

            if (propertiesData.properties) {
              $scope.properties = propertiesData.properties;
              $scope.rentalContentValue = propertiesData.rentalContentValue;
            }
          })
          .catch(function (err) {
            console.log("Retrieve error", err);
            $scope.showRetrieveError = true;
          });
      };

      $scope.setInputValues = function (isDiscard) {
        $scope.bankAccounts = [];
        $scope.vehicles = [];
        $scope.lifeInsurance = [];
        $scope.investments = [];
        $scope.superFunds = [];
        $scope.otherAssets = [];
        $scope.personalInfo = {};

        if (!isDiscard) {
          DataM.getOtherAssets()
            .then(function (data) {
              if (_.isNil(data.otherAssets)) {
                // if the user is newly created, this varible is undefined
                // in the database, so we need to add an empty data for it
                // so that it will not cause any inconsistency on comapring
                // initial scope variable to toCompare
                data.otherAssets = {
                  bankAccounts: [],
                  investments: [],
                  lifeInsurance: [],
                  otherAssets: [],
                  properties: [],
                  superFunds: [],
                  vehicles: [],
                };
              }
              toCompare = data;
              var promise = new Promise(function (resolve, reject) {
                $scope.assignValues(data);
                resolve("done");
              });

              promise.then(function (data) {
                if (data === "done") {
                  $scope.showClientSpinner = false;
                  $scope.changeBottomButtonState();
                }
              });
            })
            .catch(function (err) {
              console.log("Retrieve error", err);
              $scope.showRetrieveError = true;
            });
        } else {
          var data = JSON.parse($scope.initialOtherAssets);
          toCompare = data;
          $scope.assignValues(data);
        }
      };

      $scope.setInputValues(false);

      $scope.changeBottomButtonState = function () {
        if ($scope.initialOtherAssets !== null && toCompare !== null) {
          var newToCompare = JSON.stringify(toCompare, function (key, value) {
            if (key === "$$hashKey") {
              return undefined;
            }
            if (key === "$promise") {
              return undefined;
            }
            if (key === "$resolved") {
              return undefined;
            }
            if (key === "yearlyGrowthIsValid") {
              return undefined;
            }
            if (key === "contributionStartDatePicker") {
              return undefined;
            }
            if (key === "contributionEndDatePicker") {
              return undefined;
            }
            return value;
          });
          if ($scope.initialOtherAssets !== newToCompare) {
            $scope.showBottomButtons = true;
          } else {
            $scope.showBottomButtons = false;
          }
        } else if ($scope.initialOtherAssets === null && toCompare !== null) {
          $scope.initialOtherAssets = JSON.stringify(
            toCompare,
            function (key, value) {
              if (key === "$$hashKey") {
                return undefined;
              }
              if (key === "$promise") {
                return undefined;
              }
              if (key === "$resolved") {
                return undefined;
              }
              if (key === "yearlyGrowthIsValid") {
                return undefined;
              }
              if (key === "contributionStartDatePicker") {
                return undefined;
              }
              if (key === "contributionEndDatePicker") {
                return undefined;
              }
              return value;
            }
          );
        }
      };

      $scope.$watch(
        "[bankAccounts, rentalContentValue, vehicles, lifeInsurance, investments, superFunds, otherAssets]",
        function () {
          $scope.changeBottomButtonState();
        },
        true
      );

      // Get Property
      $scope.save = function (location) {
        if ($scope.showChangesAlert) {
          $scope.showChangesAlert = false;
        }
        $scope.showClientSpinner = true;
        $scope.loadingMessage = "Saving Client Information...";

        for (var $i = 0; $i < $scope.superFunds.length; $i++) {
          if (!$scope.superFunds[$i].salarySacrificeBool) {
            $scope.superFunds[$i].salarySacrificeClient1 = null;
            $scope.superFunds[$i].salarySacrificeClient2 = null;
          } else {
            if (
              $scope.superFunds[$i].owner.id ==
              $scope.superFunds[$i].ownerList[0].id
            ) {
              $scope.superFunds[$i].salarySacrificeClient2 = null;
            }
            if (
              $scope.superFunds[$i].ownerList[1] &&
              $scope.superFunds[$i].owner.id ==
                $scope.superFunds[$i].ownerList[1].id
            ) {
              $scope.superFunds[$i].salarySacrificeClient1 = null;
            }
          }
          if (!$scope.superFunds[$i].personalContributionsBool) {
            $scope.superFunds[$i].personalContributionsClient1 = null;
            $scope.superFunds[$i].personalContributionsClient2 = null;
          } else {
            if (
              $scope.superFunds[$i].ownership &&
              $scope.superFunds[$i].ownership.ownershipType == "Sole"
            ) {
              // remove wrong salary sacrifice
              if (
                $scope.superFunds[$i].ownership.owners[0].owner ==
                $scope.personalInfo.client1._id
              ) {
                $scope.superFunds[$i].personalContributionsClient2 = null;
              } else if (
                $scope.superFunds[$i].ownership.owners[0].owner ==
                $scope.personalInfo.client2._id
              ) {
                $scope.superFunds[$i].personalContributionsClient1 = null;
              }
            }
          }
        }
        $scope.removeExcessOwners($scope.bankAccounts);
        $scope.removeExcessOwners($scope.vehicles);
        $scope.removeExcessOwners($scope.lifeInsurance);
        $scope.removeExcessOwners($scope.superFunds);
        $scope.removeExcessOwners($scope.investments);
        $scope.removeExcessOwners($scope.vehicles);
        $scope.removeExcessOwners($scope.otherAssets);
        var formData = {
          assets: {
            bankAccounts: $scope.bankAccounts,
            vehicles: $scope.vehicles,
            lifeInsurance: $scope.lifeInsurance,
            superFunds: $scope.superFunds,
            investments: $scope.investments,
            otherAssets: $scope.otherAssets,
            isReviewed: $scope.isReviewed,
            addlAdvisorInfo: $scope.addlAdvisorInfo,
            properties: $scope.properties,
            rentalContentValue: $scope.rentalContentValue,
          },
        };
        //SAVE TO DB
        DataM.saveOtherAssets(formData)
          .then(function (data) {
            var newData = toCompare;
            $scope.initialOtherAssets = newData;

            if (data._message === "Client validation failed") {
              var errorMessage = "";
              const formattedMessage = DataHelper.formatMongoError(data);
              $scope.showClientSpinner = false;

              $scope.didNextSave = false;
              alert(formattedMessage);
            } else {
              if ($scope.isSaving && $scope.toStateName !== "") {
                $state.go($scope.toStateName, {
                  clientId: $scope.clientId,
                });
              } else {
                if ($location.$$url.includes("other-assets")) {
                  $rootScope.setAlertValues(
                    true,
                    true,
                    "Successfully saved changes!",
                    false
                  );
                  $scope.initialOtherAssets = null;
                  $scope.initialOtherAssets = JSON.stringify(
                    toCompare,
                    function (key, value) {
                      if (key === "$$hashKey") {
                        return undefined;
                      }
                      if (key === "$promise") {
                        return undefined;
                      }
                      if (key === "$resolved") {
                        return undefined;
                      }
                      if (key === "yearlyGrowthIsValid") {
                        return undefined;
                      }
                      if (key === "contributionStartDatePicker") {
                        return undefined;
                      }
                      if (key === "contributionEndDatePicker") {
                        return undefined;
                      }
                      return value;
                    }
                  );
                  $scope.changeBottomButtonState();
                } else {
                  $state.go(location, {
                    clientId: $scope.clientId,
                  });
                }
              }
              $scope.showClientSpinner = false;
            }
            $rootScope.$broadcast("onSaved");
          })
          .catch(function (err) {
            console.log("test err", err);
            $rootScope.setAlertValues(
              true,
              false,
              "There is a problem accessing the server.",
              true
            );
            alert(
              "There is a problem accessing the server. Please make sure your internet connection is working. If it is working and this problem persist, please report this problem to Empower Wealth team."
            );
            $scope.showClientSpinner = false;
          });
      };

      $scope.removeExcessOwners = function (data) {
        data.map((val, i) => {
          if (
            !_.isNil(val.ownership) &&
            !_.isNil(val.ownership.ownershipType)
          ) {
            if (
              val.ownership.ownershipType === "Sole" &&
              val.ownership.owners.length === 2
            ) {
              data[i].ownership.owners.splice(1, 1);
            } else if (
              val.ownership.ownershipType === "Other" &&
              val.ownership.owners.length > 0
            ) {
              data[i].ownership.owners = [];
            }
          }
        });
      };

      $scope.next = function () {
        $scope.save("fact-find5");
      };

      $scope.back = function () {
        // check if form is valid
        $state.go("fact-find3", {
          clientId: $scope.clientId,
        });
      };

      $scope.quit = function () {
        $state.go("dashboard");
      };

      $scope.savedText = "Save";

      $scope.showAlertValidationModal = function () {
        $scope.alertMessage = $scope.empowerData.saveValidationMessage;
        $scope.showAlertModal = true;
      };
      $scope.closeModal = function () {
        $scope.showRetrieveError = false;

        if ($scope.showAlertModal == true) {
          $scope.showAlertModal = false;
          if ($scope.elementToFocus) {
            $timeout($scope.elementToFocus.focus(), 1500);
          }
        }
      };
      $scope.showConfirmSaveModal = function (page) {
        if (!$scope.didNextSave) {
          //$scope.toShowConfirmSaveModal = true;
          $scope.nextPageLocation = page;
        }
      };
      $scope.goNextAndNotSaveModal = function () {
        //$scope.toShowConfirmSaveModal = false;
        $state.go($scope.nextPageLocation, {
          clientId: $scope.clientId,
        });
      };
      $scope.goNextAndSaveModal = function () {
        //$scope.toShowConfirmSaveModal = false;
        $scope.didNextSave = true;
        $scope.save($scope.nextPageLocation);
      };
      $scope.closeConfirmModal = function () {
        //$scope.toShowConfirmSaveModal = false;
        $scope.savedText = "Save";
        $scope.isSaving = false;
      };
      $scope.$on(
        "$stateChangeStart",
        function (event, toState, toParams, fromState, fromParams) {
          if (_.isEqual(toState.name, "login")) {
          } else if (
            toState.url.indexOf("other-assets") == -1 &&
            !$scope.isSaving &&
            !$scope.didNextSave
          ) {
            $scope.isSaving = true;
            $scope.toStateName = toState.name;
            event.preventDefault();
            if ($scope.showBottomButtons) {
              $scope.showChangesAlert = true;
            } else {
              $state.go(toState.name, {
                clientId: $scope.clientId,
              });
              return;
            }
          }
        }
      );
      $scope.dashboardSave = function () {
        var location = "other-assets";
        if ($scope.isSaving && $scope.toStateName !== "") {
          location = $scope.toStateName;
        }
        $scope.save(location);
      };
    }
  )

  .controller(
    "factfind-5-controller",
    function (
      $scope,
      $rootScope,
      $state,
      $stateParams,
      DataM,
      $window,
      $interval,
      Auth,
      empowerDataService,
      DataHelper,
      $timeout,
      $location,
      $anchorScroll
    ) {
      "use strict";
      $scope.isAdmin = false;
      Auth.isAdmin().then((isAdmin) => ($scope.isAdmin = isAdmin));

      $scope._ = _;

      $scope.reqDataCR = {};
      $scope.reqDataMB = {};
      $scope.reqDataLoaded = false;
      $scope.MBChecker = false;

      $scope.$on("reqData", function (evt, reqData) {
        $scope.reqDataCR = reqData.cr;
        $scope.reqDataMB = reqData.mb;
        $scope.MBChecker = reqData.MBChecker;
        $scope.reqDataLoaded = true;
      });

      $scope.loans = [];
      $scope.owners = [];
      $scope.forms = {};
      $scope.offsetAccounts = [];
      $scope.bankAccounts = [];
      $scope.isPersonalInfoCompleted = false;
      $scope.isIncomeCompleted = false;
      $scope.isPropertyCompleted = false;
      $scope.isNonPropertyCompleted = false;
      $scope.isBorrowingCompleted = false;
      $scope.isBillPaymentsCompleted = false;
      $scope.isSpendingCompleted = false;
      $scope.isFPlanHouseholdCompleted = false;
      $scope.isFPlanIncomeCompleted = false;
      $scope.didNextSave = false;
      $scope.selectedScopeIndex = "";
      $scope.availableOffsets = [];
      $scope.showClientSpinner = true;
      $scope.loadingMessage = "Retrieving Client Information...";
      $scope.showRetrieveError = false;
      $scope.showWarningModal = false;
      $scope.warningMessage =
        "This offset is already attached to another loan. Please select another offset account.";
      /*Testing a custom Angular Service (Kosta)*/
      $scope.empowerData = empowerDataService;
      $scope.loanTerms = [
        1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
        21, 22, 23, 24, 25, 26, 27, 28, 29, 30,
      ];
      $scope.futureYears = empowerDataService.futureYears;
      $scope.providers = empowerDataService.financeProviders;
      $scope.hasEmptySecuredAgainst = false;

      /**['Australian Military Bank', 'Adelaide Bank', 'Advantedge','AFG Home Loans EDGE','Australian First Mortgage',
      'AIMS','Allianz','AMEX','Ample Capital Australia','AMP','AMS','Anchorage','ANZ','ANZ Asset Finance','API Home Loans','Aussie Home Loans',
      'Australian Financial','Australian Lending Services','Australian Wholesale Lending','Auswide Bank','AXA','Bank Australia','Bank of Melbourne',
      'Bank of Queensland','BankSA','Bank of Sydney','Banksia','Bankwest','Bendigo And Adelaide Bank','Beyond Bank','Berrima District Credit Union',
      'Better Mortgage Management','Bluebay Home Loans','Bluegum Home Loans','Bluestone','Bluestone Equity','BMC','BMCAdvantedge','Illawarra Credit Union',
      'CBA','Challenge Bank','Choice Lend','Citibank','Collins Securities','Community CPS Australia','Community Mutual Ltd','Connective Home Loans Essentials',
      'Credit Union Home Loans','Credit Union Australia','Credit Union SA','Def Credit','Direct Mortgage Solutions','Director Of Housing VIC','Domain',
      'Economy Home Loans','eMoney','Ezy Mortgage','Fifty Group','Finance Express Home Loans','FirstFolio','FirstMac','Fox Symes Home Loans','Gateway Credit Union',
      'Great Southern Loans','Greater Building Society','Heritage Bank','RESIMAC Financial Services','Homeloans Ltd','Homeloan Centre Australia','Home Path',
      'NAB Broker','HomeStart Finance','HSBC','Iden Group','ING Direct','Keystart','La Trobe','LJ Hooker Home Loans Connect','Loan Ave','Loan Market Go',
      'Loan Plan','Liberty Financial','LJ Hooker','Macquarie Bank','MCCA','ME Bank','Mortgage Ezy','Mortgage House','Macquarie Securitisation Ltd',
      'MyState','National Australia Bank','Napiers','Nationalcorp','NCF Financial Services Pty Ltd','Newcastle Permanent','Folio Mortgage & Finance',
      'NMC','NMMC','OFG','Paladin','People\'s Choice Credit Union','Pepper Money','P&N Bank','Power Home Loans','Qantas Staff Credit Union',
      'Queensland Country Credit Union','Qudos Bank','QBANK','Queenslanders Credit Union','RAMS','Resi','Resimac','RHG','Sam Loans','Sandhurst Trustees',
      'Smartline','SGE Credit Union','St. George','Statewide Home Loans','Suncorp Bank','Sydney Home Loans','Teachers Mutual Bank','The Rock','Think Tank',
      'Victoria Teachers Mutual Bank','Virgin Money','Wide Bay Australia','Westpac','Westpac Business','Wizard Home Loans','World Home Loans',
      'Yellow Brick Road','Yes Home Loans','Other...'];*/

      $scope.loanTypes = [
        "Home Loan - Basic Variable",
        "Home Loan - Standard Variable",
        "Home Loan - Fixed Rate",
        "Home Loan - Line of Credit (Personal Use)",
        "Investment Loan - Basic Variable",
        "Investment Loan - Standard Variable",
        "Investment Loan - Fixed Rate",
        "Investment Loan - Line of Credit (Investment Use)",
        "Credit Card",
        "Car Loan",
        "Personal Loan",
        "ATO or Centrelink Debt",
        "HECS/HELP Debt",
        "Hire Purchase",
        "Lease",
        "Overdraft",
        "Store Card",
        "Commercial Bill",
        "Charge Card",
        "Court Ruled Debt",
        "Outstanding Taxation",
        "Maintenance",
        "Loan As Guarantor",
        "Contingent Liability",
        "Other",
      ];

      /*Test Array Will Be Loaded From Data*/
      $scope.clientProperties = [
        "876 High Street, East Kew 3102",
        "853 Glenferrie Road, Hawthorn 3122",
        "582 Queensberry St, North Melbourne 3051",
        "35 Collins Street, Melbourne 3000",
      ];

      $scope.clientVehicles = [];

      $scope.primaryPurposes = [
        "Buy Real Estate",
        "Refinance Real Estate",
        "Debt Consolidation",
        "General Spending",
        "Other Purchases/Items",
      ];

      $scope.realEstatePurposes = [
        "Construction of dwellings - Owner occupier/Personal use",
        "Construction of dwellings - Investment use",
        "Maintenance and Repairs - Owner occupier/Personal use",
        " Maintenance and Repairs - Investment use",
        "Purchase of newly erected dwellings - Owner occupier/Personal use",
        "Purchase of newly erected dwellings - Investment use",
        "Purchase of established dwelling - Owner occupier/Personal use",
        "Purchase of established dwelling - Investment use",
        "Purchase of off the plan dwelling - Owner occupier/Personal use",
        "Purchase of off the plan dwelling - Investment use",
        "Purchase of vacant land - Owner occupier/Personal use",
        "Purchase of vacant land - Investment use",
        "Purchase of land and buildings (Commercial/Rural) - Personal use",
        "Purchase of land and buildings (Commercial/Rural) - Investment use",
        "Structural alterations and additions - Owner occupier/Personal use",
        "Structural alterations and additions - Investment use",
      ];

      $scope.refinancingPurposes = [
        "Re-finance of existing of home loans - Owner occupier/Personal use",
        "Re-finance of existing of home loans - Investment use",
        "Debt consolidation (excluding refinance for existing loans)",
        "Refinancing - Personal Finance",
      ];

      $scope.generalSpendingPurposes = [
        "Purchase of household and personal goods",
        "Travel and holidays",
        "Other",
      ];

      $scope.otherPurposes = [
        "Boats, caravans and trailers",
        "Education/Learning (eg HECS/HELP)",
        "Fund a business",
        "Motor cars and station wagons - new",
        "Motor cars and station wagons - used",
        "Motor cycles, scooters",
        "Other motor vehicles",
        "Personal Investment excluding property ie shares, bonds and managed funds",
        "Tax/Government Debt",
        "Other",
      ];

      $scope.clientId = $stateParams.clientId;
      $scope.state = $state.current;
      $scope.params = $stateParams;
      $scope.isReadOnlyBase = true;
      $scope.showChangesAlert = false;
      let toCompare = null;
      $scope.initialLoans = null;
      $scope.showBottomButtons = false;
      $scope.toStateName = "";
      $scope.isSaving = false;

      $scope.closeChangesAlert = function () {
        $scope.showChangesAlert = false;
        $scope.isSaving = false;
      };

      $scope.dashboardDiscard = async function () {
        $scope.showClientSpinner = true;
        $scope.loadingMessage = "Discarding Client Changes...";
        if ($scope.showChangesAlert) {
          $scope.showChangesAlert = false;
        }
        var promise = new Promise(function (resolve, reject) {
          $timeout(function () {
            $scope.setInputValues(true);
            resolve("done");
          }, 1);
        });

        let result = await promise;
        if (result === "done") {
          $scope.showClientSpinner = false;
          if ($scope.isSaving && $scope.toStateName !== "") {
            $state.go($scope.toStateName, {
              clientId: $scope.clientId,
            });
          } else {
            $scope.showBottomButtons = false;
            $rootScope.setAlertValues(
              true,
              true,
              "Successfully discarded changes!",
              true
            );
          }
        }
      };

      /**
       * Sorts the loans according to loan type
       * same as the order of $scope.loanTypes
       *
       */
      $scope.changeToClose = function (index, loan) {
        if (
          loan.isClosed &&
          moment(loan.closedDate, ["DD/MM/YYYY"]).isValid()
        ) {
          $scope.showClosed = true;
          loan.closedDate = loan.closedDate.format("DD/MM/YYYY");
          setTimeout(function () {
            $("html,body").animate(
              { scrollTop: $("#closed-liabilities").offset().top + 500 },
              "slow"
            );
          }, 500);
        } else if (
          _.has(loan, "isClosed") &&
          !loan.isClosed &&
          moment(loan.closedDate, ["DD/MM/YYYY"]).isValid()
        ) {
          setTimeout(function () {
            $("html,body").animate(
              { scrollTop: $("#closed-liabilities").offset().top - 100 },
              "slow"
            );
          }, 500);
        } else {
          setTimeout(function () {
            if (!moment(loan.closedDate, ["DD/MM/YYYY"]).isValid()) {
              $(
                $($scope.forms.factfindform.$$element[0]).find(
                  ".available-loan-container"
                )[index]
              )
                .find(".liability-closed-date")[0]
                .focus()
                .focus();
            }
          }, 200);
        }
      };

      $scope.$watch(
        "loans",
        function () {
          $scope.loans.forEach(function (loan) {
            if (
              loan.isClosed &&
              _.isNil(loan.closedDate) &&
              loan.closedDate !== null
            ) {
              $scope.showClosed = true;
              loan.closedDate = moment.utc().format("DD/MM/YYYY");
              setTimeout(function () {
                $("html,body").animate(
                  { scrollTop: $("#closed-liabilities").offset().top - 150 },
                  "slow"
                );
              }, 500);
            }
          });
        },
        true
      );

      $scope.sortLoans = function () {
        var sortedLoans = [];

        sortedLoans = $scope.loans.sort(function (a, b) {
          if ($scope.loanTypes.indexOf(a.type) === -1) {
            return 1;
          }
          if ($scope.loanTypes.indexOf(b.type) === -1) {
            return -1;
          }
          return (
            $scope.loanTypes.indexOf(a.type) - $scope.loanTypes.indexOf(b.type)
          );
        });
        $scope.loans = sortedLoans;
      };
      // flex check missing
      /*Check if lending needs is turned on*/
      $scope.suitabilityEnabled = false;

      $scope.isInvalidForFlex = function (object) {
        return DataHelper.isObjectInvalid(object) && $scope.suitabilityEnabled;
      };

      $scope.isQualifiedForMB = function (object, value) {
        if ($scope.reqDataLoaded && $scope.MBChecker) {
          let className = "";
          if (object !== null && typeof object !== "undefined") {
            if (
              $scope.reqDataMB.loans.indexOf(object) > -1 &&
              (value === "" ||
                typeof value === "undefined" ||
                value === null ||
                value !== value ||
                (typeof value.length !== "undefined" && value.length === 0) ||
                (typeof value._isValid !== "undefined" && !value._isValid))
            ) {
              className = "highlight-blue";
            }
          }
          return className;
        }
      };

      function getSuitabilitySetting() {
        DataM.getSuitabilitySetting({ uid: $stateParams.clientId })
          .then(function (settingData) {
            $scope.suitabilityEnabled = false;
            if (settingData.suitabilityEnabled === true) {
              $scope.suitabilityEnabled = true;
            }
          })
          .catch(function (err) {
            console.log(err);
          });
      }

      getSuitabilitySetting();
      $scope.calcLoanEndDate = function (loan) {
        var l = $scope.loans[$scope.loans.indexOf(loan)];
        var loanDate = moment.utc(l.startDate, ["DD/MM/YYYY"]);
        l.loanExpiryDay = loanDate.date();
        l.loanExpiryMonth = loanDate.month() + 1;

        var finishYear = parseInt(loanDate.year(), 10) + parseInt(l.term, 10);
        l.loanExpiryYear = finishYear.toString();

        l.expiryDate = moment.utc(
          [l.loanExpiryYear, l.loanExpiryMonth, l.loanExpiryDay],
          ["YYYY/MM/DD"]
        );
        var expDate = empowerDataService.generatedLocalDateString(l.expiryDate);

        if (expDate !== "Invalid date") {
          l.loanExpiryDatePicker = expDate;
        }
      };
      $scope.changeDetailedPurpose = function (loan, purpose) {
        if (purpose == "Real Estate") {
          if (loan.detailedPurpose) {
            if (
              $scope.realEstatePurposes.indexOf(loan.detailedPurpose) === -1
            ) {
              loan.detailedPurpose = null;
            }
          }
        }
        if (purpose == "Refinancing/Debt Consolidation") {
          if (loan.detailedPurpose) {
            if (
              $scope.refinancingPurposes.indexOf(loan.detailedPurpose) === -1
            ) {
              loan.detailedPurpose = null;
            }
          }
        }
        if (purpose == "General Spending") {
          if (loan.detailedPurpose) {
            if (
              $scope.generalSpendingPurposes.indexOf(
                loan.detailedPurpose === -1
              )
            ) {
              loan.detailedPurpose = null;
            }
          }
        }
        if (purpose == "Other Purchases/Items") {
          if (loan.detailedPurpose) {
            if ($scope.otherPurposes.indexOf(loan.detailedPurpose) === -1) {
              loan.detailedPurpose = null;
            }
          }
        }
      };

      $scope.addLoan = function () {
        var loan = {};
        loan.owner = [];
        loan.borrowerType = "";
        loan.percentage1 = 0;
        loan.percentage2 = 0;
        $scope.loans.push(loan);
      };

      $scope.removeLoan = function (loan) {
        $scope.loans.splice($scope.loans.indexOf(loan), 1);
      };

      $scope.removeOffset = function (offset, offsetArray) {
        offsetArray.splice(offsetArray.indexOf(offset), 1);
      };
      $scope.isOptionsRequired = function (loan) {
        if (loan.properties) {
          if (loan.properties.length > 0) {
            return false;
          }
        }
        return true;
      };
      $scope.someSelected = function (object) {
        return Object.keys(object).some(function (key) {
          return object[key];
        });
      };
      $scope.toggleProperty = function (propertyId, loan) {
        // check if loan exists in loans array
        if ($scope.loans.indexOf(loan) > -1) {
          if (!loan.properties) {
            loan.properties = [];
          }

          var idx = loan.properties.indexOf(propertyId);

          if (idx > -1) {
            loan.properties.splice(idx, 1);
          } else {
            loan.properties.push(propertyId);
          }
        }
        $scope.someSelected(loan.properties);
      };

      $scope.toggleSecuredAgainst = function (propertyId, loan) {
        // check if loan exists in loans array
        if ($scope.loans.indexOf(loan) > -1) {
          if (!loan.securedAgainst) {
            loan.securedAgainst = [];
          }

          var idx = loan.securedAgainst.indexOf(propertyId);

          if (idx > -1) {
            loan.securedAgainst.splice(idx, 1);
          } else {
            loan.securedAgainst.push(propertyId);
          }
        }
      };

      $scope.toggleVehicle = function (vehicleId, loan) {
        if ($scope.loans.indexOf(loan) > -1) {
          if (!loan.vehicles) {
            loan.vehicles = [];
          }

          var idx = loan.vehicles.indexOf(vehicleId);

          if (idx > -1) {
            loan.vehicles.splice(idx, 1);
          } else {
            loan.vehicles.push(vehicleId);
          }
        }
      };

      $scope.addOffset = function (loan) {
        if (!loan.offsets) {
          loan.offsets = [{}];
        } else {
          loan.offsets.unshift({});
        }
      };

      $scope.isOffsetAvailable = function (offset, loan, itemIndex) {
        // check available offsets
        // loop all loans and check if offset is present
        // remove offset if found
        for (var i = 0; i < $scope.loans.length; i++) {
          var loanItem = $scope.loans[i];
          if (loanItem.offsets) {
            for (var j = 0; j < loanItem.offsets.length; j++) {
              var offsetItem = loanItem.offsets[j];

              if (loanItem != loan) {
                if (offsetItem.offset === offset) {
                  loan.offsets[itemIndex].offset = null;
                  $scope.showWarningModal = true;
                  $scope.warningMessage =
                    "This offset is already attached to another loan. Please select another offset account.";
                  return false;
                }
              } else {
                if (j != itemIndex && offsetItem.offset === offset) {
                  loan.offsets[itemIndex].offset = null;
                  $scope.showWarningModal = true;
                  $scope.warningMessage =
                    "This offset is already attached to another loan. Please select another offset account.";
                  return false;
                }
              }
            }
          }
        }
        return true;
      };

      // compute base, discount and end rate
      $scope.computeEndRate = function (loan, type) {
        if (type == "End") {
          loan.endRateChanged = true;
          loan.interestRate = Number.parseFloat(
            Number.parseFloat(loan.interestRate).toFixed(2)
          );
        } else if (type == "Base") {
          if (
            typeof loan.discountRate == "number" &&
            typeof loan.baseRate == "number"
          ) {
            loan.interestRate = loan.baseRate - loan.discountRate;
            loan.interestRate = Number.parseFloat(
              Number.parseFloat(loan.interestRate).toFixed(2)
            );
          }
        } else if (type == "Discount") {
          if (
            typeof loan.discountRate == "number" &&
            typeof loan.baseRate == "number"
          ) {
            loan.interestRate = loan.baseRate - loan.discountRate;
            loan.interestRate = Number.parseFloat(
              Number.parseFloat(loan.interestRate).toFixed(2)
            );
          }
        }
      };

      $scope.assignOwnership = function (loan, owner) {
        var loanObject = $scope.loans[$scope.loans.indexOf(loan)];
        if (owner.name == "Joint") {
          if ($scope.personalInfo.client2) {
            loanObject.borrower = {
              ownershipType: "Joint",
              ownershipDesc: "",
              owners: [
                {
                  percentage: loanObject.percentage1,
                  name:
                    $scope.personalInfo.client1.fName +
                    " " +
                    $scope.personalInfo.client1.lName,
                  owner: $scope.personalInfo.client1._id,
                },
                {
                  percentage: loanObject.percentage2,
                  name:
                    $scope.personalInfo.client2.fName +
                    " " +
                    $scope.personalInfo.client2.lName,
                  owner: $scope.personalInfo.client2._id,
                },
              ],
            };
          }
        } else if (owner.name == "Tenants in Common") {
          loanObject.borrower = {
            ownershipType: "Tenants in Common",
            ownershipDesc: "",
            owners: [
              {
                percentage: loanObject.percentage1,
                name:
                  $scope.personalInfo.client1.fName +
                  " " +
                  $scope.personalInfo.client1.lName,
                owner: $scope.personalInfo.client1._id,
              },
            ],
          };
          if ($scope.personalInfo.client2) {
            var owner2 = {
              percentage: loanObject.percentage2,
              name:
                $scope.personalInfo.client2.fName +
                " " +
                $scope.personalInfo.client2.lName,
              owner: $scope.personalInfo.client2._id,
            };
            loanObject.borrower.owners.push(owner2);
          }
        } else if (owner.name == "Other") {
          loanObject.borrower = {
            ownershipType: "Other",
            ownershipDesc: loanObject.ownerOther,
            owners: [
              {
                percentage: loanObject.percentage1,
                name:
                  $scope.personalInfo.client1.fName +
                  " " +
                  $scope.personalInfo.client1.lName,
                owner: $scope.personalInfo.client1._id,
              },
            ],
          };
        } else {
          loanObject.otherBorrowerPercentage = null;
          loanObject.otherBorrowerAddlInfo = null;
          loanObject.borrower = {
            ownershipType: "Sole",
            ownershipDesc: "",
            owners: [
              {
                percentage: 100,
                name: owner.name,
                owner: owner.id,
              },
            ],
          };
        }
      };

      $scope.setStartDate = function (x) {
        var loan = $scope.loans[$scope.loans.indexOf(x)];
        loan.startDate = moment.utc(loan.loanStartDatePicker, ["DD/MM/YYYY"]);
      };

      $scope.setInterestEndDate = function (x) {
        var loan = $scope.loans[$scope.loans.indexOf(x)];

        loan.interestOnlyEndDate = moment.utc(loan.interestOnlyEndDatePicker, [
          "DD/MM/YYYY",
        ]);
      };

      // $scope.setFixedTermSpecificDate = function (x) {
      //   var loan = $scope.loans[$scope.loans.indexOf(x)];

      //   loan.fixedTermSpecificDate = moment.utc(loan.fixedTermSpecificDatePicker, ["DD/MM/YYYY"]);
      // };

      $scope.setFixedEndDate = function (x) {
        var loan = $scope.loans[$scope.loans.indexOf(x)];

        loan.fixedRateEndDate = moment.utc(loan.fixedRateEndDatePicker, [
          "DD/MM/YYYY",
        ]);
      };
      $scope.setIOEndDate = function (x) {
        var loan = $scope.loans[$scope.loans.indexOf(x)];
        loan.interestOnlyExpiryDate = moment.utc(loan.IOEndDatePicker, [
          "DD/MM/YYYY",
        ]);
      };
      $scope.goToLocation = function (location) {
        var proceed = $scope.empowerData.goToLocation(location, $scope);

        if (proceed) {
          $state.go(location, {
            clientId: $scope.clientId,
          });
        }
      };

      $scope.assignValues = function (data, isDiscard) {
        $scope.borrowerList = DataHelper.getOwnerList(data, false, true, true);
        if (data.borrowings) {
          $scope.loans = DataHelper.formatBorrower(
            data.borrowings.borrowing,
            data.personalInfo,
            $scope.borrowerList
          );
          $scope.sortLoans();
        }

        $scope.personalInfo = data.personalInfo;

        $scope.isPersonalInfoCompleted = data.isPersonalInfoCompleted;
        $scope.isIncomeCompleted = data.isIncomeCompleted;
        $scope.isPropertyCompleted = data.isPropertyCompleted;
        $scope.isNonPropertyCompleted = data.isNonPropertyCompleted;
        $scope.isBorrowingCompleted = data.isBorrowingCompleted;
        $scope.isBillPaymentsCompleted = data.isBillPaymentsCompleted;
        $scope.isSpendingCompleted = data.isSpendingCompleted;
        $scope.isFPlanHouseholdCompleted = data.isFPlanHouseholdCompleted;
        $scope.isFPlanIncomeCompleted = data.isFPlanIncomeCompleted;

        if (data.assets && data.assets.properties) {
          $scope.clientProperties = data.assets.properties;
        }

        $scope.loans.forEach(function (x) {
          if (x.provider) {
            if ($scope.providers.indexOf(x.provider) === -1) {
              x.other = x.provider;
              x.provider = $scope.providers[$scope.providers.length - 1];
            }
          }
          if (
            $scope.loanTypes.indexOf(x.type) === -1 &&
            typeof x.type !== "undefined"
          ) {
            x.otherLoanType = x.type;
            x.type = $scope.loanTypes[$scope.loanTypes.length - 1];
          }

          if (x.primaryPurpose == "Real Estate") {
            x.primaryPurpose = "Buy Real Estate";
          }

          if (x.properties) {
            x.properties.forEach(function (loanPropId) {
              var isFound = false;
              $scope.clientProperties.forEach(function (property) {
                if (property._id === loanPropId) {
                  isFound = true;
                }
              });

              if (isFound == false) {
                x.properties.splice(loanPropId, 1);
              }
            });
          }

          if (x.securedAgainst) {
            x.securedAgainst.forEach(function (loanPropId) {
              var isFound = false;
              $scope.clientProperties.forEach(function (property) {
                if (property._id === loanPropId) {
                  isFound = true;
                }
              });

              if (isFound == false) {
                x.securedAgainst.splice(loanPropId, 1);
              }
            });
          }
          if ((!x.offsets || x.offsets.length < 1) && x.offset) {
            x.offsets = [{ offset: x.offset }];
          }

          x.isClosed = x.isClosed === undefined ? false : x.isClosed;
          x.closedDate = x.closedDate
            ? moment.utc(x.closedDate, ["DD/MM/YYYY"]).format("DD/MM/YYYY")
            : null;
        });

        // getting 'owners' from personalInfo
        /*var owner1;
      (data.personalInfo.client1.preferred === null) ? owner1 = data.personalInfo.client1.fName
        : owner1 = data.personalInfo.client1.preferred;
      $scope.owners.unshift(owner1);
      if(data.personalInfo.client2.fName !== null) {
        var owner2;
        (data.personalInfo.client2.preferred === null) ? owner2 = data.personalInfo.client2.fName
          : owner2 = data.personalInfo.client2.preferred;
        $scope.owners.unshift(owner2);
      }*/

        /*Retrieve Date Object for Investment Start Date, and separate.*/
        for (var $i = 0; $i < $scope.loans.length; $i++) {
          var newDate = empowerDataService.generateUTCDate(
            $scope.loans[$i].startDate
          );
          $scope.loans[$i].loanStartDatePicker =
            empowerDataService.generatedLocalDateString(
              $scope.loans[$i].startDate
            );
          $scope.loans[$i].startDate = newDate;

          var newDate = empowerDataService.generateUTCDate(
            $scope.loans[$i].expiryDate
          );
          $scope.loans[$i].loanExpiryDatePicker =
            empowerDataService.generatedLocalDateString(
              $scope.loans[$i].expiryDate
            );
          $scope.loans[$i].expiryDate = newDate;

          if ($scope.loans[$i].interestOnlyEndDate) {
            var newDate = empowerDataService.generateUTCDate(
              $scope.loans[$i].interestOnlyEndDate
            );
            $scope.loans[$i].interestOnlyEndDatePicker =
              empowerDataService.generatedLocalDateString(
                $scope.loans[$i].interestOnlyEndDate
              );
            $scope.loans[$i].interestOnlyEndDate = newDate;
          }
          // if ($scope.loans[$i].fixedTermSpecificDate) {
          //   var newDate = empowerDataService.generateUTCDate($scope.loans[$i].fixedTermSpecificDate);
          //   $scope.loans[$i].fixedTermSpecificDatePicker = empowerDataService.generatedLocalDateString($scope.loans[$i].fixedTermSpecificDate);
          //   $scope.loans[$i].fixedTermSpecificDate = newDate;
          // }

          if ($scope.loans[$i].interestOnlyExpiryDate) {
            var newDate = empowerDataService.generateUTCDate(
              $scope.loans[$i].interestOnlyExpiryDate
            );
            $scope.loans[$i].IOEndDatePicker =
              empowerDataService.generatedLocalDateString(
                $scope.loans[$i].interestOnlyExpiryDate
              );
            $scope.loans[$i].interestOnlyExpiryDate = newDate;
          }
          if ($scope.loans[$i].fixedRateEndDate) {
            var newDate = empowerDataService.generateUTCDate(
              $scope.loans[$i].fixedRateEndDate
            );
            $scope.loans[$i].fixedRateEndDatePicker =
              empowerDataService.generatedLocalDateString(
                $scope.loans[$i].fixedRateEndDate
              );
            $scope.loans[$i].fixedRateEndDate = newDate;
          }
        }

        if (data.assets && data.assets.vehicles) {
          for (var $i = 0; $i < data.assets.vehicles.length; $i++) {
            $scope.clientVehicles.push(data.assets.vehicles[$i]);
          }
        }

        // getting offset accounts
        if (data.assets && data.assets.bankAccounts) {
          for (var $i = 0; $i < data.assets.bankAccounts.length; $i++) {
            if (data.assets.bankAccounts[$i].accountType === "Offset") {
              var $str =
                data.assets.bankAccounts[$i].institution +
                " " +
                data.assets.bankAccounts[$i].last4;
              var offsetAccount = {
                v: data.assets.bankAccounts[$i]._id,
                n: $str,
              };
              $scope.offsetAccounts.unshift(offsetAccount);
              var bankAccount = {
                v: data.assets.bankAccounts[$i]._id,
                n:
                  data.assets.bankAccounts[$i].institution +
                  " " +
                  data.assets.bankAccounts[$i].accountType +
                  " (" +
                  data.assets.bankAccounts[$i].last4 +
                  ")",
              };
              $scope.bankAccounts.unshift(bankAccount);
            } else {
              var name =
                data.assets.bankAccounts[$i].institution +
                " " +
                data.assets.bankAccounts[$i].accountType;
              if (data.assets.bankAccounts[$i].accountType == "Other") {
                name =
                  data.assets.bankAccounts[$i].institution +
                  " " +
                  data.assets.bankAccounts[$i].otherType;
              }
              var bankAccount = {
                v: data.assets.bankAccounts[$i]._id,
                n: name,
              };
              $scope.bankAccounts.unshift(bankAccount);
            }
          }
        }

        $scope.isMBAdmin = false;
        // check if user is admin
        DataM.getMe()
          .then(function (userData) {
            if (userData.role !== "user" && userData.role !== "guest") {
              $scope.isReadOnlyBase = false;
              if (
                userData.role === "super_admin" ||
                userData.role === "mb_admin"
              ) {
                $scope.isMBAdmin = true;
              }
            } else {
              $scope.isReadOnlyBase = true;
            }
          })
          .catch(function (err) {
            console.log("Retrieve current user error");
          });
      };

      $scope.setInputValues = function (isDiscard) {
        $scope.loans = [];
        $scope.owners = [];
        $scope.offsetAccounts = [];
        $scope.bankAccounts = [];

        if (!isDiscard) {
          DataM.getPropertyLoans()
            .then(function (data) {
              if (_.isNil(data.borrowings)) {
                // if the user is newly created, this varible is undefined
                // in the database, so we need to add an empty data for it
                // so that it will not cause any inconsistency on comapring
                // initial scope variable to toCompare
                data.borrowings = {
                  borrowing: [],
                };
              } else {
                // added default data to repaymentFreq loanFeesFrequency repaymentType
                // this fix is to prevent the initialLoans scope to be different on
                // toCompare on first load
                data.borrowings.borrowing.map((loan, i) => {
                  if (_.isNil(loan.repaymentFreq)) {
                    data.borrowings.borrowing[i].repaymentFreq = null;
                  }
                  if (_.isNil(loan.loanFeesFrequency)) {
                    data.borrowings.borrowing[i].loanFeesFrequency = null;
                  }
                  if (_.isNil(loan.repaymentType)) {
                    data.borrowings.borrowing[i].repaymentType = null;
                  }
                });
              }
              toCompare = data;

              var promise = new Promise(function (resolve, reject) {
                $scope.assignValues(data, isDiscard);
                resolve("done");
              });

              promise.then(function (data) {
                if (data === "done") {
                  $scope.showClientSpinner = false;
                }
              });
            })
            .catch(function (err) {
              console.log("Retrieve error", err);
              $scope.showRetrieveError = true;
            });
        } else {
          var data = JSON.parse($scope.initialLoans);
          toCompare = data;
          $scope.assignValues(data, isDiscard);
        }
      };

      $scope.setInputValues(false);

      $scope.changeBottomButtonState = function () {
        if ($scope.initialLoans !== null && toCompare !== null) {
          var newToCompare = JSON.stringify(toCompare, function (key, value) {
            if (key === "$$hashKey") {
              return undefined;
            }
            if (key === "$promise") {
              return undefined;
            }
            if (key === "$resolved") {
              return undefined;
            }
            return value;
          });
          if ($scope.initialLoans !== newToCompare) {
            $scope.showBottomButtons = true;
          } else {
            $scope.showBottomButtons = false;
          }
        } else if ($scope.initialLoans === null && toCompare !== null) {
          $scope.initialLoans = JSON.stringify(
            toCompare,
            function (key, value) {
              if (key === "$$hashKey") {
                return undefined;
              }
              if (key === "$promise") {
                return undefined;
              }
              if (key === "$resolved") {
                return undefined;
              }
              return value;
            }
          );
        }
      };

      $scope.$watch(
        "[loans, owners, forms, offsetAccounts, bankAccounts]",
        function () {
          $scope.changeBottomButtonState();
        },
        true
      );

      $scope.save = function (location) {
        if ($scope.showChangesAlert) {
          $scope.showChangesAlert = false;
        }
        $scope.showClientSpinner = true;
        $scope.loadingMessage = "Saving Client Information...";

        let loans = $scope.loans.map(function (_x) {
          let x = Object.assign({}, _x);
          // if (x.provider === $scope.providers[$scope.providers.length - 1]) {
          //   x.provider = x.other;
          // }
          if (x.type === $scope.loanTypes[$scope.loanTypes.length - 1]) {
            x.type = x.otherLoanType;
          }

          // remove "Other" or extended fields if not chosen
          if (x.detailedPurpose != "Other") {
            x.otherDetailedPurpose = null;
          }
          if (x.provider != $scope.providers[$scope.providers.length - 1]) {
            x.otherProvider = null;
          }
          if (x.otherBorrowerPercentage < 1 || !x.otherBorrowerPercentage) {
            x.otherBorrowerAddlInfo = null;
          }
          if (x.isOffset == false) {
            x.offset = null;
            x.offsets = [];
          }
          if (x.LMIBool == false) {
            x.LMI = null;
          }

          // check if type is not Car Loan AND Lease AND Hire Purchase
          // set vehicles to empty
          if (x.type) {
            if (
              x.type.indexOf("Car Loan") == -1 &&
              x.type.indexOf("Lease") == -1 &&
              x.type.indexOf("Hire Purchase") == -1
            ) {
              x.vehicles = null;
            }
          }

          if (x.primaryPurpose && x.type) {
            if (
              x.primaryPurpose.indexOf("Real Estate") == -1 &&
              x.type.indexOf("Lease") == -1 &&
              x.type.indexOf("Hire Purchase")
            ) {
              x.properties = null;
            }
          }
          if (x.isClosed) {
            if (x.closedDate === moment.utc().format("DD/MM/YYYY")) {
              x.closedDate = moment.utc().startOf("day").format("DD/MM/YYYY");
            } else {
              x.closedDate = moment
                .utc(x.closedDate, ["DD/MM/YYYY"])
                .format("DD/MM/YYYY");
            }
          } else {
            x.closedDate = null;
          }
          return x;
        });
        var formData = {
          borrowings: {
            borrowing: loans,
          },
        };
        //SAVE TO DB
        DataM.savePropertyLoans(formData)
          .then(function (data) {
            var newData = toCompare;
            $scope.initialLoans = newData;

            if (data._message === "Client validation failed") {
              var errorMessage = "";
              const formattedMessage = DataHelper.formatMongoError(data);
              $scope.showClientSpinner = false;

              $scope.didNextSave = false;
              alert(formattedMessage);
            } else {
              if ($scope.isSaving && $scope.toStateName !== "") {
                $state.go($scope.toStateName, {
                  clientId: $scope.clientId,
                });
              } else {
                if ($location.$$url.includes("borrowings")) {
                  $rootScope.setAlertValues(
                    true,
                    true,
                    "Successfully saved changes!",
                    false
                  );
                  $scope.initialLoans = null;
                  $scope.initialLoans = JSON.stringify(
                    toCompare,
                    function (key, value) {
                      if (key === "$$hashKey") {
                        return undefined;
                      }
                      if (key === "$promise") {
                        return undefined;
                      }
                      if (key === "$resolved") {
                        return undefined;
                      }
                      return value;
                    }
                  );
                  $scope.changeBottomButtonState();
                } else {
                  $state.go(location, {
                    clientId: $scope.clientId,
                  });
                }
              }
              $scope.showClientSpinner = false;
            }
            $rootScope.$broadcast("onSaved");
          })
          .catch(function (err) {
            console.log("test err", err);
            $rootScope.setAlertValues(
              true,
              false,
              "There is a problem accessing the server.",
              true
            );
            alert(
              "There is a problem accessing the server. Please make sure your internet connection is working. If it is working and this problem persist, please report this problem to Empower Wealth team."
            );
            $scope.showClientSpinner = false;
          });
      };

      $scope.next = function () {
        $scope.save("fact-find6");
      };
      $scope.back = function () {
        $state.go("fact-find4", {
          clientId: $scope.clientId,
        });
      };
      $scope.quit = function () {
        $state.go("dashboard");
      };

      $scope.savedText = "Save";

      $scope.showAlertValidationModal = function () {
        $scope.alertMessage = $scope.empowerData.saveValidationMessage;
        $scope.showAlertModal = true;
      };
      $scope.closeModal = function () {
        $scope.showAlertModal = false;
        $scope.showWarningModal = false;
        if ($scope.elementToFocus) {
          $timeout($scope.elementToFocus.focus(), 1500);
        }
      };

      $scope.showConfirmSaveModal = function (page) {
        if (!$scope.didNextSave) {
          //$scope.toShowConfirmSaveModal = true;
          $scope.nextPageLocation = page;
        }
      };
      $scope.goNextAndNotSaveModal = function () {
        //$scope.toShowConfirmSaveModal = false;

        $state.go($scope.nextPageLocation, {
          clientId: $scope.clientId,
        });
      };
      $scope.goNextAndSaveModal = function () {
        //$scope.toShowConfirmSaveModal = false;
        $scope.didNextSave = true;
        $scope.save($scope.nextPageLocation);
      };
      $scope.closeConfirmModal = function () {
        //$scope.toShowConfirmSaveModal = false;
        $scope.savedText = "Save";
        $scope.isSaving = false;
      };
      $scope.$on(
        "$stateChangeStart",
        function (event, toState, toParams, fromState, fromParams) {
          if (_.isEqual(toState.name, "login")) {
          } else if (
            toState.url.indexOf("borrowings") == -1 &&
            !$scope.isSaving &&
            !$scope.didNextSave
          ) {
            $scope.isSaving = true;
            $scope.toStateName = toState.name;
            event.preventDefault();
            if ($scope.showBottomButtons) {
              $scope.showChangesAlert = true;
            } else {
              $state.go(toState.name, {
                clientId: $scope.clientId,
              });
              return;
            }
          }
        }
      );
      $scope.dashboardSave = function () {
        var location = "borrowings";
        if ($scope.isSaving && $scope.toStateName !== "") {
          location = $scope.toStateName;
        }
        $scope.save(location);
      };
    }
  )

  .controller(
    "factfind-6-controller",
    function (
      $scope,
      $state,
      $stateParams,
      $rootScope,
      $location,
      DataM,
      Auth,
      empowerDataService,
      $timeout,
      $filter,
      DataHelper,
      EmpowerWealthHelper
    ) {
      "use strict";
      $scope.isAdmin = false;
      Auth.isAdmin().then((isAdmin) => ($scope.isAdmin = isAdmin));
      $scope.fetchFlexSummaryData = function () {
        $rootScope.$broadcast("OnExpensesShowFlexSummary");
      };
      $scope.customBills = [];
      $scope.taxDeductible = [];
      $scope.openFlexSummary = false;
      // attributes as defined in client.model.js
      $scope.expenses = [];
      $scope.isReviewed = false;
      $scope.addlAdvisorInfo = "";
      $scope.owners = [];
      $scope.otherExpenses = [];

      $scope.showClientSpinner = true;
      $scope.loadingMessage = "Retrieving Client Information...";
      $scope.showRetrieveError = false;
      $scope.hasExpenseChanges = false;
      /*Empower Data Service - For common datasets*/
      $scope.empowerData = empowerDataService;
      $scope.isPersonalInfoCompleted = false;
      $scope.isIncomeCompleted = false;
      $scope.isPropertyCompleted = false;
      $scope.isNonPropertyCompleted = false;
      $scope.isBorrowingCompleted = false;
      $scope.isBillPaymentsCompleted = false;
      $scope.isSpendingCompleted = false;
      $scope.isFPlanHouseholdCompleted = false;
      $scope.isFPlanIncomeCompleted = false;
      $scope.didNextSave = false;
      $scope.isBasicExpensesHidden = true;
      const { formUniqueArrayByValue, stringifyJSON, holdingCostsTotal } =
        EmpowerWealthHelper;
      const defaultKeysToRemove = ["$$hashKey", "$promise", "$resolved"];

      $scope.clientId = $stateParams.clientId;
      $scope.state = $state.current;
      $scope.params = $stateParams;
      $scope.forms = {};

      $scope.isUserAdmin = false;

      $scope.annualBills = {};
      $scope.initialBillBasic = 0;
      $scope.annualSpendings = {};
      $scope.annualBillsAmount = "";
      $scope.annualBillsDiscAmount = "";
      $scope.annualBillsBasicAmount = "";

      $scope.billsPageActive = true;

      $scope.flexExpenses = [];
      $scope.flexExpensesMonthlyTotal = 0;
      $scope.isFlexSummaryShown = false;
      $scope.properties = [];
      $scope.investmentProperties = [];
      $scope.personalProperties = [];
      $scope.billPayments = {
        rent: {
          tier1: "Home Costs",
          tier2: "Rent Payments",
          tier3: "Rent payments",
          tier4: "Regular Payments",
          desc: "Rent",
          amount: 0,
          discretionary: 0,
          frequency: "",
          owner: "Joint",
          category: "7 Days Float",
          basic: 0,
        },
        rates: {
          tier1: "Home Costs",
          tier2: "Council Rates",
          tier3: "Local government rates",
          tier4: "Bills",
          desc: "Local Government Rates",
          amount: null,
          discretionary: null,
          frequency: "",
          owner: "Joint",
          category: "Paid by Credit",
          basic: null,
        },
        waterRates: {
          tier1: "Home Costs",
          tier2: "Council Rates",
          tier3: "Water and sewerage rates",
          tier4: "Bills",
          desc: "Water and Sewerage Rates and Charges",
          amount: null,
          discretionary: null,
          frequency: "",
          owner: "Joint",
          category: "Paid by Credit",
          basic: null,
        },
        insurance: {
          tier1: "Home Costs",
          tier2: "Home and Contents Insurance",
          tier3: "",
          tier4: "Bills",
          desc: "House and Contents Insurance",
          amount: null,
          discretionary: null,
          frequency: "",
          owner: "Joint",
          category: "Paid by Credit",
          basic: null,
        },
        electricity: {
          tier1: "Home Power & Fuel",
          tier2: "Utilities",
          tier3: "Electricity",
          tier4: "Bills",
          desc: "Electricity",
          amount: null,
          discretionary: null,
          frequency: "",
          owner: "Joint",
          category: "Paid by Credit",
          basic: null,
        },
        gas: {
          tier1: "Home Power & Fuel",
          tier2: "Utilities",
          tier3: "Mains gas",
          tier4: "Bills",
          desc: "Gas",
          amount: null,
          discretionary: null,
          frequency: "",
          owner: "Joint",
          category: "Paid by Credit",
          basic: null,
        },
        telephone: {
          tier1: "Home Services & Operations",
          tier2: "Communications",
          tier3: "",
          tier4: "Bills",
          desc: "Telephone - Fixed and Mobile",
          amount: null,
          discretionary: null,
          frequency: "",
          owner: "Joint",
          category: "Paid by Credit",
          basic: null,
        },
        internet: {
          tier1: "Home Services & Operations",
          tier2: "Communications",
          tier3: "Internet charges",
          tier4: "Bills",
          desc: "Internet",
          amount: null,
          discretionary: null,
          frequency: "",
          owner: "Joint",
          category: "Paid by Credit",
          basic: null,
        },
        payTV: {
          tier1: "Lifestyle (Recreational)",
          tier2: "Audio & Visual",
          tier3: "",
          tier4: "Living and Lifestyle",
          desc: "Pay Television",
          amount: null,
          discretionary: null,
          frequency: "",
          owner: "Joint",
          category: "7 Days Float",
          basic: null,
        },
        healthInsurance: {
          tier1: "Personal Health Insurances",
          tier2: "Accident & Health Insurance",
          tier3: "",
          tier4: "Bills",
          desc: "Health Insurance",
          amount: null,
          discretionary: null,
          frequency: "",
          owner: "Joint",
          category: "Paid by Credit",
          basic: null,
        },
        lifeInsurance: {
          tier1: "Personal / Professional",
          tier2: "Personal Protection Insurances",
          tier3: "Life Insurance",
          tier4: "Bills",
          desc: "Life Insurance",
          amount: null,
          discretionary: null,
          frequency: "",
          owner: "Joint",
          category: "Paid by Credit",
          basic: null,
        },
        carRego: {
          tier1: "Transport",
          tier2: "Car License & Registration",
          tier3: "Compulsory registration",
          tier4: "Bills",
          desc: "Car Registration",
          amount: null,
          discretionary: null,
          frequency: "",
          owner: "Joint",
          category: "Paid by Credit",
          basic: null,
        },
        carInsurance: {
          tier1: "Transport",
          tier2: "Car Insurance",
          tier3: "",
          tier4: "Bills",
          desc: "Car Insurance",
          amount: null,
          discretionary: null,
          frequency: "",
          owner: "Joint",
          category: "Paid by Credit",
          basic: null,
        },
        motoOrgs: {
          tier1: "Transport",
          tier2: "Motoring Organisation Fees",
          tier3: "Subscription to motor organisations",
          tier4: "Bills",
          desc: "Motoring Organisations",
          amount: null,
          discretionary: null,
          frequency: "",
          owner: "Joint",
          category: "Paid by Credit",
          basic: null,
        },
        education: {
          tier1: "Education",
          tier2: "",
          tier3: "",
          tier4: "Bills",
          desc: "Education - School Fees",
          amount: null,
          discretionary: null,
          frequency: "",
          owner: "Joint",
          category: "Paid by Credit",
          basic: null,
        },
        childCare: {
          tier1: "Education",
          tier2: "Pre School Care Services",
          tier3: "",
          tier4: "Bills",
          desc: "Child Care Fees",
          amount: null,
          discretionary: null,
          frequency: "",
          owner: "Joint",
          category: "Paid by Credit",
          basic: null,
        },
        bankFees: {
          tier1: "Bank Charges",
          tier2: "Banking Fees",
          tier3: "",
          tier4: "Regular Payment",
          desc: "Bank Fees",
          amount: null,
          discretionary: null,
          frequency: "",
          owner: "Joint",
          category: "7 Days Float",
          basic: null,
        },
      };

      $scope.showBottomButtons = false;
      let toCompare = null;
      let toCompare2 = null;
      $scope.initialBills = null;
      $scope.initialProperties = null;
      $scope.showChangesAlert = false;
      $scope.toStateName = "";
      $scope.isSaving = false;

      $scope.closeChangesAlert = function () {
        $scope.newLocation = "";
        $scope.showChangesAlert = false;
        $scope.isSaving = false;
      };

      $scope.dashboardDiscard = async function () {
        $scope.showClientSpinner = true;
        $scope.loadingMessage = "Discarding Client Changes...";
        if ($scope.showChangesAlert) {
          $scope.showChangesAlert = false;
        }

        var promise = new Promise(function (resolve, reject) {
          $timeout(function () {
            $scope.setInputValues(true);
            resolve("done");
          }, 1);
        });

        let result = await promise;
        if (result === "done") {
          $scope.showClientSpinner = false;
          if ($scope.isSaving && $scope.toStateName !== "") {
            $state.go($scope.toStateName, {
              clientId: $scope.clientId,
            });
          } else {
            $scope.showBottomButtons = false;
            $rootScope.setAlertValues(
              true,
              true,
              "Successfully discarded changes!",
              true
            );
          }
        }
      };

      $scope.addNewCustomBill = function () {
        var bill = {
          tier4: "Bills",
          customExpense: true,
          taxDeductible: false,
          frequency: "Monthly",
          basic: null,
          discretionary: null,
          amount: null,
          totalString: "",
          monthly: "",
          amountStr: "",
          desc: "",
        };
        $scope.customBills.push(bill);
        toCompare.expenses.expenses.push(bill);
      };

      $scope.deleteCustomBill = function (bill) {
        $scope.customBills.splice($scope.customBills.indexOf(bill), 1);
        toCompare.expenses.expenses.splice(
          toCompare.expenses.expenses.indexOf(bill),
          1
        );
        computeBreakDown();
      };

      $scope.addNewCustomTaxDeductBill = function () {
        var bill = {
          tier4: "Bills",
          taxDeductible: true,
          customExpense: true,
          frequency: "Monthly",
          category: "Paid by Credit",
          basic: null,
          discretionary: null,
          amount: null,
          totalString: "",
          monthly: "",
          amountStr: "",
          desc: "",
        };
        $scope.taxDeductible.push(bill);
        toCompare.expenses.expenses.push(bill);
      };

      $scope.deleteCustomTaxDeductBill = function (bill) {
        $scope.taxDeductible.splice($scope.taxDeductible.indexOf(bill), 1);
        toCompare.expenses.expenses.splice(
          toCompare.expenses.expenses.indexOf(bill),
          1
        );
        computeBreakDown();
      };

      $scope.addNewCustomHoldingCost = function (property) {
        var holdingCost = {
          name: "",
          value: "",
          category: "Direct from Primary",
        };
        property.customHoldingCosts.push(holdingCost);
      };

      $scope.addHoldingExpense = function (property) {
        let total = 0;
        total += !_.isNil(property.bodyCorpCosts) ? property.bodyCorpCosts : 0;
        total += !_.isNil(property.waterRates) ? property.waterRates : 0;
        total += !_.isNil(property.landTaxCosts) ? property.landTaxCosts : 0;
        total += !_.isNil(property.councilRates) ? property.councilRates : 0;
        total += !_.isNil(property.insuranceCosts)
          ? property.insuranceCosts
          : 0;
        total += !_.isNil(property.managementFees)
          ? property.managementFees
          : 0;
        total += !_.isNil(property.maintenanceCost)
          ? property.maintenanceCost
          : 0;
        property.customHoldingCosts.map((val) => {
          total += !_.isNil(val.value) ? val.value : 0;
        });
        property.holdingCost = total;
      };

      $scope.$watch(
        "properties",
        function () {
          var totalHoldingCost = 0;
          var totalHoldingCostPersonalUse = 0;
          for (var $i = 0; $i < $scope.properties.length; $i++) {
            var property = $scope.properties[$i];
            if (
              property.purpose === "Investment" ||
              property.purpose === "Business Use" ||
              property.purpose === "Personal Use"
            ) {
              var holdingCost = 0;
              var isCustomIncluded = true;
              holdingCost = holdingCostsTotal(property, isCustomIncluded);
              property.holdingCost = holdingCost;
              if (
                property.purpose === "Investment" ||
                property.purpose === "Business Use"
              ) {
                totalHoldingCost += property.holdingCost / 12;
              } else if (property.purpose === "Personal Use") {
                totalHoldingCostPersonalUse += property.holdingCost / 12;
              }
            }
          }
          $scope.properties.totalHoldingCost = totalHoldingCost;
          $scope.properties.totalHoldingCostPersonalUse =
            totalHoldingCostPersonalUse;
        },
        true
      );

      $scope.goToLocation = function (location) {
        var proceed = $scope.empowerData.goToLocation(location, $scope);

        if (proceed) {
          $state.go(location, {
            clientId: $scope.clientId,
          });
        }
      };

      $scope.computeTotal = function (expense) {
        if (expense.basic && expense.discretionary) {
          expense.amount = expense.basic + expense.discretionary;
        }
      };

      $scope.bills = [];
      $scope.getMonthly = function (amount, frequency) {
        var annual = 0;
        if (amount > 0) {
          switch (frequency) {
            case "Weekly":
              annual = amount * 52;
              break;
            case "Fortnightly":
              annual = amount * 26;
              break;
            case "Monthly":
              annual = amount * 12;
              break;
            case "Every 2 months":
              annual = amount * 6;
              break;
            case "Every 3 months":
              annual = amount * 4;
              break;
            case "Every 6 months":
              annual = amount * 2;
              break;
            case "Yearly":
              annual = amount;
              break;
          }
        }
        var monthly = annual / 12;

        return monthly;
      };

      $scope.computeMonthly = function (expense) {
        DataHelper.processExpenseMonthly(expense);
        computeBreakDown();
      };

      function computeBreakDown() {
        var allBills = $scope.customBills.concat($scope.bills);
        allBills = allBills.concat($scope.taxDeductible);
        var allExpenses = allBills.concat($scope.otherExpenses);

        $scope.computeMonthlyBreakdown(allBills, "BILL");
        $scope.computeMonthlyBreakdown($scope.otherExpenses, "SPENDING");
        $scope.computeMonthlyBreakdown(allExpenses, "ALL");
      }
      $scope.totalBillsAmount = "";
      $scope.totalBillsDiscAmount = "";
      $scope.totalBillsBasicAmount = "";

      $scope.totalSpendingAmount = "";
      $scope.totalSpendingDiscAmount = "";
      $scope.totalSpendingBasicAmount = "";

      $scope.totalAmount = "";
      $scope.totalDiscAmount = "";
      $scope.totalBasicAmount = "";
      $scope.rentMonthly = {
        amount: 0,
        basic: 0,
        discretionary: 0,
      };
      /**
       * Sets initial annual expense
       */
      function setInitialAmount(initial, current) {
        if (current === 0 && $scope.hasExpenseChanges === false) {
          if (initial > 0) {
            return initial;
          }
        }
        return current;
      }
      $scope.checkIfBillsHasInput = function () {
        if ($scope.hasExpenseChanges) {
          return true;
        }
        if ($scope.bills.length > 0 || $scope.customBills.length > 0) {
          for (var i = 0; i < $scope.bills.length; i++) {
            var expense = $scope.bills[i];
            if (expense.amount && expense.amount > 0) {
              return true;
            }
          }
          for (var i = 0; i < $scope.customBills.length; i++) {
            var expense = $scope.customBills[i];
            if (expense.amount && expense.amount > 0) {
              return true;
            }
          }
          return false;
        } else {
          return false;
        }
      };

      $scope.computeMonthlyBreakdown = function (expenseArray, expenseType) {
        var total = 0;
        var discTotal = 0;
        var basicTotal = 0;

        for (var i = 0; i < expenseArray.length; i++) {
          var expenseItem = expenseArray[i];

          if (expenseItem.frequency) {
            if (typeof expenseItem.discretionary === "number") {
              discTotal += $scope.getMonthly(
                expenseItem.discretionary,
                expenseItem.frequency,
                "number"
              );
            }
            if (typeof expenseItem.basic === "number") {
              basicTotal += $scope.getMonthly(
                expenseItem.basic,
                expenseItem.frequency,
                "number"
              );
            }
          }
        }

        total = basicTotal + discTotal;

        if (expenseType === "BILL") {
          // get annual

          $scope.annualBills.basic =
            12 * basicTotal > 0 ? 12 * basicTotal : null;
          $scope.annualBills.discretionary =
            12 * discTotal > 0 ? 12 * discTotal : null;
          $scope.annualBills.amount = 12 * total > 0 ? 12 * total : null;

          if (basicTotal === 0) {
            $scope.annualBills.basic =
              $scope.initialBillBasic === 0 ? null : $scope.initialBillBasic;
            $scope.annualBills.discretionary = null;
            $scope.annualBills.amount =
              $scope.initialBillBasic === 0 ? null : $scope.initialBillBasic;
          }

          let initialMonthlyTotal = $scope.annualBills.amount / 12;
          let initialMonthlyBasic = $scope.annualBills.basic / 12;
          let initialMonthlyDisc = $scope.annualBills.discretionary / 12;

          total = setInitialAmount(initialMonthlyTotal, total);
          basicTotal = setInitialAmount(initialMonthlyBasic, basicTotal);
          discTotal = setInitialAmount(initialMonthlyDisc, discTotal);

          if (
            $scope.billPayments["rent"].basic ||
            $scope.billPayments["rent"].basic === 0
          ) {
            $scope.rentMonthly.basic = $scope.getMonthly(
              $scope.billPayments["rent"].basic,
              $scope.billPayments["rent"].frequency,
              "number"
            );
            basicTotal = basicTotal - $scope.rentMonthly.basic;
          }
          if (
            $scope.billPayments["rent"].discretionary ||
            $scope.billPayments["rent"].discretionary === 0
          ) {
            $scope.rentMonthly.discretionary = $scope.getMonthly(
              $scope.billPayments["rent"].discretionary,
              $scope.billPayments["rent"].frequency,
              "number"
            );
            discTotal = discTotal - $scope.rentMonthly.discretionary;
          }
          if (
            $scope.billPayments["rent"].amount ||
            $scope.billPayments["rent"].amount === 0
          ) {
            $scope.rentMonthly.amount = $scope.getMonthly(
              $scope.billPayments["rent"].amount,
              $scope.billPayments["rent"].frequency,
              "number"
            );
            total = total - $scope.rentMonthly.amount;
          }

          $scope.totalBillsAmount = $filter("number")(total, 2);
          $scope.totalBillsDiscAmount = $filter("number")(discTotal, 2);
          $scope.totalBillsBasicAmount = $filter("number")(basicTotal, 2);

          $scope.annualBillsAmount = $filter("number")(
            $scope.annualBills.amount,
            2
          );
          $scope.annualBillsDiscAmount = $filter("number")(
            $scope.annualBills.discretionary,
            2
          );
          $scope.annualBillsBasicAmount = $filter("number")(
            $scope.annualBills.basic,
            2
          );
        } else if (expenseType === "SPENDING") {
          let initialMonthlyTotal = $scope.annualSpendings.amount / 12;
          let initialMonthlyBasic = $scope.annualSpendings.basic / 12;
          let initialMonthlyDisc = $scope.annualSpendings.discretionary / 12;

          total = setInitialAmount(initialMonthlyTotal, total);
          basicTotal = setInitialAmount(initialMonthlyBasic, basicTotal);
          discTotal = setInitialAmount(initialMonthlyDisc, discTotal);

          $scope.totalSpendingAmount = $filter("number")(total, 2);
          $scope.totalSpendingDiscAmount = $filter("number")(discTotal, 2);
          $scope.totalSpendingBasicAmount = $filter("number")(basicTotal, 2);
        } else if (expenseType === "ALL") {
          let initialMonthlyTotal =
            ($scope.annualBills.amount + $scope.annualSpendings.amount) / 12;
          let initialMonthlyBasic =
            ($scope.annualBills.basic + $scope.annualSpendings.basic) / 12;
          let initialMonthlyDisc =
            ($scope.annualBills.discretionary +
              $scope.annualSpendings.discretionary) /
            12;

          total = setInitialAmount(initialMonthlyTotal, total);
          basicTotal = setInitialAmount(initialMonthlyBasic, basicTotal);
          discTotal = setInitialAmount(initialMonthlyDisc, discTotal);

          var totalHoldingCost = $scope.properties.totalHoldingCost
            ? $scope.properties.totalHoldingCost
            : 0;
          $scope.totalAmount = $filter("number")(total + totalHoldingCost, 2);
          $scope.totalDiscAmount = $filter("number")(discTotal, 2);
          $scope.totalBasicAmount = $filter("number")(
            basicTotal + totalHoldingCost,
            2
          );
        }
      };
      $scope.isMBAdmin = false;
      // check if user is admin
      DataM.getMe()
        .then(function (userData) {
          if (userData.role !== "user" && userData.role !== "guest") {
            $scope.isUserAdmin = true;
            if (
              userData.role === "super_admin" ||
              userData.role === "mb_admin"
            ) {
              $scope.isMBAdmin = true;
            }
          } else {
            $scope.isUserAdmin = false;
          }
        })
        .catch(function (err) {
          console.log("Retrieve current user error");
        });

      $scope.assignValues = function (data, properties) {
        if (properties.properties) {
          $scope.properties = properties.properties;
        }

        $scope.investmentProperties = $scope.properties.filter(
          (i) => i.purpose === "Investment" || i.purpose === "Business Use"
        );
        $scope.personalProperties = $scope.properties.filter(
          (i) => i.purpose === "Personal Use"
        );

        $scope.investmentProperties.map((res, i) => {
          $scope.investmentProperties[i].isOpen = false;
        });

        $scope.personalProperties.map((res, i) => {
          $scope.personalProperties[i].isOpen = false;
        });

        let basicTotal = 0;

        for (var i = 0; i < data.expenses.expenses.length; i++) {
          var expenseItem = data.expenses.expenses[i];
          if (expenseItem.tier4 === "Bills" || expenseItem.desc === "Rent") {
            if (expenseItem.frequency) {
              if (typeof expenseItem.basic === "number") {
                basicTotal += $scope.getMonthly(
                  expenseItem.basic,
                  expenseItem.frequency,
                  "number"
                );
              }
            }
          }
        }

        $scope.showClientSpinner = false;
        if (data.expenses) {
          $scope.expenses = data.expenses.expenses;

          if (data.expenses.annualBills) {
            if (data.expenses.annualBills) {
              $scope.annualBills = data.expenses.annualBills;
              if (basicTotal === 0 && $scope.annualBills.basic > 0) {
                $scope.initialBillBasic = $scope.annualBills.basic;
              }
              $scope.annualBillsAmount = $filter("number")(
                $scope.annualBills.amount,
                2
              );
              $scope.annualBillsDiscAmount = $filter("number")(
                $scope.annualBills.discretionary,
                2
              );
              $scope.annualBillsBasicAmount = $filter("number")(
                $scope.annualBills.basic,
                2
              );
            }
            if (data.expenses.annualSpendings) {
              $scope.annualSpendings = data.expenses.annualSpendings;
            }
          }
        }
        //$scope.isReviewed = data.expenses.isReviewed;
        //$scope.addlAdvisorInfo = data.expenses.addlAdvisorInfo;
        $scope.personalInfo = data.personalInfo;

        $scope.isPersonalInfoCompleted = data.isPersonalInfoCompleted;
        $scope.isIncomeCompleted = data.isIncomeCompleted;
        $scope.isPropertyCompleted = data.isPropertyCompleted;
        $scope.isNonPropertyCompleted = data.isNonPropertyCompleted;
        $scope.isBorrowingCompleted = data.isBorrowingCompleted;
        $scope.isBillPaymentsCompleted = data.isBillPaymentsCompleted;
        $scope.isSpendingCompleted = data.isSpendingCompleted;
        $scope.isFPlanHouseholdCompleted = data.isFPlanHouseholdCompleted;
        $scope.isFPlanIncomeCompleted = data.isFPlanIncomeCompleted;

        // check if user is admin
        DataM.getMe()
          .then(function (userData) {
            if (userData.role !== "user" && userData.role !== "guest") {
              $scope.isBasicExpensesHidden = true;
              if (
                userData.role === "super_admin" ||
                userData.role === "mb_admin"
              ) {
                $scope.isMBAdmin = true;
              }
            } else {
              if (data.expenses && data.expenses.basicExpenses) {
                $scope.isBasicExpensesHidden = true; //data.expenses.basicExpenses.isHidden;
              } else {
                $scope.isBasicExpensesHidden = true;
              }
            }
          })
          .catch(function (err) {
            console.log("Retrieve current user error");
          });

        if (data.personalInfo.client1.fName != null) {
          var owner1 = {
            name:
              data.personalInfo.client1.fName +
              " " +
              data.personalInfo.client1.lName,
            id: data.personalInfo.client1._id,
          };
          $scope.owners.unshift(owner1);
        }
        if (data.personalInfo.client2) {
          if (data.personalInfo.client2.fName) {
            var owner2 = {
              name:
                data.personalInfo.client2.fName +
                " " +
                data.personalInfo.client2.lName,
              id: data.personalInfo.client2._id,
            };

            var joint = {
              name: "Joint",
              id: "",
            };

            $scope.owners.unshift(owner2);
            $scope.owners.push(joint);
          }
        }
        if ($scope.expenses) {
          $scope.bills = [];
          for (var $i = 0; $i < $scope.expenses.length; $i++) {
            // map bills
            if ($scope.expenses[$i].desc === "Rent") {
              if (
                $scope.expenses[$i].frequency === "" ||
                $scope.expenses[$i].frequency === null
              ) {
                $scope.expenses[$i].frequency = "Monthly";
              }
              $scope.billPayments["rent"] = $scope.expenses[$i];
              $scope.computeMonthly($scope.billPayments["rent"]);
              if (_.isNil($scope.expenses[$i].basic)) {
                $scope.expenses[$i].basic = null;
              }
              $scope.bills.push($scope.expenses[$i]);
            } else if ($scope.expenses[$i].desc === "Local Government Rates") {
              if (
                $scope.expenses[$i].frequency === "" ||
                $scope.expenses[$i].frequency === null
              ) {
                $scope.expenses[$i].frequency = "Monthly";
              }
              $scope.billPayments["rates"] = $scope.expenses[$i];
              $scope.computeMonthly($scope.billPayments["rates"]);
              if (_.isNil($scope.expenses[$i].basic)) {
                $scope.expenses[$i].basic = null;
              }
              $scope.bills.push($scope.expenses[$i]);
            } else if (
              $scope.expenses[$i].desc ===
              "Water and Sewerage Rates and Charges"
            ) {
              if (
                $scope.expenses[$i].frequency === "" ||
                $scope.expenses[$i].frequency === null
              ) {
                $scope.expenses[$i].frequency = "Monthly";
              }
              $scope.billPayments["waterRates"] = $scope.expenses[$i];
              $scope.computeMonthly($scope.billPayments["waterRates"]);
              if (_.isNil($scope.expenses[$i].basic)) {
                $scope.expenses[$i].basic = null;
              }
              $scope.bills.push($scope.expenses[$i]);
            } else if (
              $scope.expenses[$i].desc === "House and Contents Insurance"
            ) {
              if (
                $scope.expenses[$i].frequency === "" ||
                $scope.expenses[$i].frequency === null
              ) {
                $scope.expenses[$i].frequency = "Monthly";
              }
              $scope.billPayments["insurance"] = $scope.expenses[$i];
              $scope.computeMonthly($scope.billPayments["insurance"]);
              if (_.isNil($scope.expenses[$i].basic)) {
                $scope.expenses[$i].basic = null;
              }
              $scope.bills.push($scope.expenses[$i]);
            } else if ($scope.expenses[$i].desc === "Electricity") {
              if (
                $scope.expenses[$i].frequency === "" ||
                $scope.expenses[$i].frequency === null
              ) {
                $scope.expenses[$i].frequency = "Monthly";
              }
              $scope.billPayments["electricity"] = $scope.expenses[$i];
              $scope.computeMonthly($scope.billPayments["electricity"]);
              if (_.isNil($scope.expenses[$i].basic)) {
                $scope.expenses[$i].basic = null;
              }
              $scope.bills.push($scope.expenses[$i]);
            } else if ($scope.expenses[$i].desc === "Gas") {
              if (
                $scope.expenses[$i].frequency === "" ||
                $scope.expenses[$i].frequency === null
              ) {
                $scope.expenses[$i].frequency = "Monthly";
              }
              $scope.billPayments["gas"] = $scope.expenses[$i];
              $scope.computeMonthly($scope.billPayments["gas"]);
              if (_.isNil($scope.expenses[$i].basic)) {
                $scope.expenses[$i].basic = null;
              }
              $scope.bills.push($scope.expenses[$i]);
            } else if (
              $scope.expenses[$i].desc === "Telephone - Fixed and Mobile"
            ) {
              if (
                $scope.expenses[$i].frequency === "" ||
                $scope.expenses[$i].frequency === null
              ) {
                $scope.expenses[$i].frequency = "Monthly";
              }
              $scope.billPayments["telephone"] = $scope.expenses[$i];
              $scope.computeMonthly($scope.billPayments["telephone"]);
              if (_.isNil($scope.expenses[$i].basic)) {
                $scope.expenses[$i].basic = null;
              }
              $scope.bills.push($scope.expenses[$i]);
            } else if ($scope.expenses[$i].desc === "Internet") {
              if (
                $scope.expenses[$i].frequency === "" ||
                $scope.expenses[$i].frequency === null
              ) {
                $scope.expenses[$i].frequency = "Monthly";
              }
              $scope.billPayments["internet"] = $scope.expenses[$i];
              $scope.computeMonthly($scope.billPayments["internet"]);
              if (_.isNil($scope.expenses[$i].basic)) {
                $scope.expenses[$i].basic = null;
              }
              $scope.bills.push($scope.expenses[$i]);
            } else if ($scope.expenses[$i].desc === "Pay Television") {
              if (
                $scope.expenses[$i].frequency === "" ||
                $scope.expenses[$i].frequency === null
              ) {
                $scope.expenses[$i].frequency = "Monthly";
              }
              $scope.billPayments["payTV"] = $scope.expenses[$i];
              $scope.computeMonthly($scope.billPayments["payTV"]);
              if (_.isNil($scope.expenses[$i].basic)) {
                $scope.expenses[$i].basic = null;
              }
              $scope.bills.push($scope.expenses[$i]);
            } else if ($scope.expenses[$i].desc === "Health Insurance") {
              if (
                $scope.expenses[$i].frequency === "" ||
                $scope.expenses[$i].frequency === null
              ) {
                $scope.expenses[$i].frequency = "Monthly";
              }
              $scope.billPayments["healthInsurance"] = $scope.expenses[$i];
              $scope.computeMonthly($scope.billPayments["healthInsurance"]);
              if (_.isNil($scope.expenses[$i].basic)) {
                $scope.expenses[$i].basic = null;
              }
              $scope.bills.push($scope.expenses[$i]);
            } else if ($scope.expenses[$i].desc === "Life Insurance") {
              if (
                $scope.expenses[$i].frequency === "" ||
                $scope.expenses[$i].frequency === null
              ) {
                $scope.expenses[$i].frequency = "Monthly";
              }
              $scope.billPayments["lifeInsurance"] = $scope.expenses[$i];
              $scope.computeMonthly($scope.billPayments["lifeInsurance"]);
              if (_.isNil($scope.expenses[$i].basic)) {
                $scope.expenses[$i].basic = null;
              }
              $scope.bills.push($scope.expenses[$i]);
            } else if ($scope.expenses[$i].desc === "Car Registration") {
              if (
                $scope.expenses[$i].frequency === "" ||
                $scope.expenses[$i].frequency === null
              ) {
                $scope.expenses[$i].frequency = "Monthly";
              }
              $scope.billPayments["carRego"] = $scope.expenses[$i];
              $scope.computeMonthly($scope.billPayments["carRego"]);
              if (_.isNil($scope.expenses[$i].basic)) {
                $scope.expenses[$i].basic = null;
              }
              $scope.bills.push($scope.expenses[$i]);
            } else if ($scope.expenses[$i].desc === "Car Insurance") {
              if (
                $scope.expenses[$i].frequency === "" ||
                $scope.expenses[$i].frequency === null
              ) {
                $scope.expenses[$i].frequency = "Monthly";
              }
              $scope.billPayments["carInsurance"] = $scope.expenses[$i];
              $scope.computeMonthly($scope.billPayments["carInsurance"]);
              if (_.isNil($scope.expenses[$i].basic)) {
                $scope.expenses[$i].basic = null;
              }
              $scope.bills.push($scope.expenses[$i]);
            } else if ($scope.expenses[$i].desc === "Motoring Organisations") {
              if (
                $scope.expenses[$i].frequency === "" ||
                $scope.expenses[$i].frequency === null
              ) {
                $scope.expenses[$i].frequency = "Monthly";
              }
              $scope.billPayments["motoOrgs"] = $scope.expenses[$i];
              $scope.computeMonthly($scope.billPayments["motoOrgs"]);
              if (_.isNil($scope.expenses[$i].basic)) {
                $scope.expenses[$i].basic = null;
              }
              $scope.bills.push($scope.expenses[$i]);
            } else if ($scope.expenses[$i].desc === "Education - School Fees") {
              if (
                $scope.expenses[$i].frequency === "" ||
                $scope.expenses[$i].frequency === null
              ) {
                $scope.expenses[$i].frequency = "Monthly";
              }
              $scope.billPayments["education"] = $scope.expenses[$i];
              $scope.computeMonthly($scope.billPayments["education"]);
              if (_.isNil($scope.expenses[$i].basic)) {
                $scope.expenses[$i].basic = null;
              }
              $scope.bills.push($scope.expenses[$i]);
            } else if ($scope.expenses[$i].desc === "Child Care Fees") {
              if (
                $scope.expenses[$i].frequency === "" ||
                $scope.expenses[$i].frequency === null
              ) {
                $scope.expenses[$i].frequency = "Monthly";
              }
              $scope.billPayments["childCare"] = $scope.expenses[$i];
              $scope.computeMonthly($scope.billPayments["childCare"]);
              if (_.isNil($scope.expenses[$i].basic)) {
                $scope.expenses[$i].basic = null;
              }
              $scope.bills.push($scope.expenses[$i]);
            } else if ($scope.expenses[$i].desc === "Bank Fees") {
              if (
                $scope.expenses[$i].frequency === "" ||
                $scope.expenses[$i].frequency === null
              ) {
                $scope.expenses[$i].frequency = "Monthly";
              }
              $scope.billPayments["bankFees"] = $scope.expenses[$i];
              if (_.isNil($scope.expenses[$i].basic)) {
                $scope.expenses[$i].basic = null;
              }
              $scope.bills.push($scope.expenses[$i]);
              $scope.computeMonthly($scope.billPayments["bankFees"]);
            } else if (
              $scope.expenses[$i].tier4 === "Bills" &&
              $scope.expenses[$i].taxDeductible === false &&
              $scope.expenses[$i].customExpense === true
            ) {
              if (
                $scope.expenses[$i].frequency === "" ||
                $scope.expenses[$i].frequency === null
              ) {
                $scope.expenses[$i].frequency = "Monthly";
              }
              if (_.isNil($scope.expenses[$i].basic)) {
                $scope.expenses[$i].basic = null;
              }
              if (_.isNil($scope.expenses[$i].discretionary)) {
                $scope.expenses[$i].discretionary = null;
              }
              $scope.customBills.push($scope.expenses[$i]);
              $scope.computeMonthly($scope.expenses[$i]);
            } else if ($scope.expenses[$i].taxDeductible === true) {
              if (
                $scope.expenses[$i].frequency === "" ||
                $scope.expenses[$i].frequency === null
              ) {
                $scope.expenses[$i].frequency = "Monthly";
              }
              if (
                $scope.expenses[$i].category === "" ||
                $scope.expenses[$i].category === null ||
                typeof $scope.expenses[$i].category === "undefined"
              ) {
                $scope.expenses[$i].category = "Paid by Credit";
              }
              if (_.isNil($scope.expenses[$i].basic)) {
                $scope.expenses[$i].basic = null;
              }
              if (_.isNil($scope.expenses[$i].discretionary)) {
                $scope.expenses[$i].discretionary = null;
              }
              $scope.taxDeductible.push($scope.expenses[$i]);
              $scope.computeMonthly($scope.expenses[$i]);
            } else {
              if (
                $scope.expenses[$i].frequency === "" ||
                $scope.expenses[$i].frequency === null
              ) {
                $scope.expenses[$i].frequency = "Monthly";
              }
              if (_.isNil($scope.expenses[$i].basic)) {
                $scope.expenses[$i].basic = null;
              }
              if (_.isNil($scope.expenses[$i].discretionary)) {
                $scope.expenses[$i].discretionary = null;
              }
              $scope.otherExpenses.push($scope.expenses[$i]);
              $scope.computeMonthly($scope.expenses[$i]);
            }
          }
        } else {
          $scope.expenses = [];
        }

        $scope.hasExpenseChanges = $scope.checkIfBillsHasInput();
        // if there isn't an income protection Insurance taxDeductible for each client, we need to add empty ones:
        var $j = 0;
        var found = false;

        while ($j < $scope.taxDeductible.length && found == false) {
          if (
            $scope.taxDeductible[$j].desc === "Income Protection Insurance" ||
            $scope.taxDeductible[$j].desc === "Income Protection"
          ) {
            found = true;
          }
          $j++;
        }

        /*No expense for Income Protection Insurance add field for it for both clients.*/
        if (found == false) {
          for (var $i = 0; $i < $scope.owners.length - 1; $i++) {
            var taxDExpense = {
              tier4: "Bills",
              desc: "Income Protection Insurance",
              owner: $scope.owners[$i],
              taxDeductible: true,
              basic: null,
              discretionary: null,
              amount: null,
              frequency: null,
              totalString: "",
              monthly: "",
              amountStr: "",
            };
            $scope.taxDeductible.push(taxDExpense);
            $scope.assignOwnership(taxDExpense, $scope.owners[$i]);
            toCompare.expenses.expenses.push(taxDExpense);
          }
        }

        var $j = 0;
        var found = false;
        while ($j < $scope.taxDeductible.length && found == false) {
          if ($scope.taxDeductible[$j].desc === "Accountant / Tax agent fees") {
            found = true;
          }
          $j++;
        }

        /*No expense for Accountant / Tax agent fees add field for it.*/
        if (found == false) {
          var taxDExpense = {
            tier4: "Bills",
            desc: "Accountant / Tax agent fees",
            owner: {},
            taxDeductible: true,
            basic: null,
            discretionary: null,
            amount: null,
            frequency: null,
            totalString: "",
            monthly: "",
            amountStr: "",
          };
          $scope.taxDeductible.push(taxDExpense);
          toCompare.expenses.expenses.push(taxDExpense);
        }

        // check selected tax deductible owner
        for (var $i = 0; $i < $scope.taxDeductible.length; $i++) {
          var taxDeductibleExpense = $scope.taxDeductible[$i];

          // check if joint type
          if (taxDeductibleExpense.ownership) {
            if (taxDeductibleExpense.ownership.ownershipType == "Joint") {
              taxDeductibleExpense.owner =
                $scope.owners[$scope.owners.length - 1];
            }

            // determine owner
            else if (taxDeductibleExpense.ownership.ownershipType == "Sole") {
              for (var $k = 0; $k < $scope.owners.length; $k++) {
                var ownerListOption = $scope.owners[$k];

                if (
                  taxDeductibleExpense.ownership.owners[0].owner ==
                  ownerListOption.id
                ) {
                  taxDeductibleExpense.owner = ownerListOption;
                }
              }
            }
          }
        }
      };

      $scope.setInputValues = function (isDiscard) {
        $scope.customBills = [];
        $scope.taxDeductible = [];
        $scope.expenses = [];
        $scope.owners = [];
        $scope.otherExpenses = [];
        $scope.annualBills = {};
        $scope.annualSpendings = {};
        $scope.flexExpenses = [];
        $scope.properties = [];

        if (!isDiscard) {
          DataM.getProperties()
            .then(function (properties) {
              DataM.getBillPayments()
                .then(function (data) {
                  toCompare = data;
                  toCompare2 = properties;
                  var promise = new Promise(function (resolve, reject) {
                    $scope.assignValues(data, properties);
                    resolve("done");
                  });

                  promise.then(function (data) {
                    if (data === "done") {
                      $scope.showClientSpinner = false;
                    }
                  });
                })
                .catch(function (err) {
                  console.log("Retrieve error", err);
                  $scope.showRetrieveError = true;
                });
            })
            .catch(function (err) {
              console.log("Retrieve error", err);
              $scope.showRetrieveError = true;
            });
        } else {
          var data1 = JSON.parse($scope.initialBills);
          var data2 = JSON.parse($scope.initialProperties);
          toCompare = data1;
          toCompare2 = data2;
          $scope.assignValues(data1, data2);
        }
      };

      $scope.setInputValues(false);

      $scope.changeBottomButtonState = function () {
        if (
          $scope.initialBills !== null &&
          $scope.initialProperties !== null &&
          toCompare !== null &&
          toCompare2 !== null
        ) {
          const newToCompare = stringifyJSON(toCompare, defaultKeysToRemove);
          const newToCompare2 = stringifyJSON(toCompare2, [
            ...defaultKeysToRemove,
            "isOpen",
          ]);
          if (
            $scope.initialBills !== newToCompare ||
            $scope.initialProperties !== newToCompare2
          ) {
            $scope.showBottomButtons = true;
          } else {
            $scope.showBottomButtons = false;
          }
        } else if (
          $scope.initialBills === null &&
          $scope.initialProperties === null &&
          toCompare !== null &&
          toCompare2 !== null
        ) {
          $scope.initialBills = stringifyJSON(toCompare, defaultKeysToRemove);
          $scope.initialProperties = stringifyJSON(toCompare2, [
            ...defaultKeysToRemove,
            "isOpen",
          ]);
        }
      };

      $scope.$watch(
        "[expenses, owners, properties, flexExpenses, annualBills, annualSpendings, otherExpenses, customBills, taxDeductible]",
        function () {
          $scope.changeBottomButtonState();
        },
        true
      );

      $scope.save = function (location) {
        if ($scope.showChangesAlert) {
          $scope.showChangesAlert = false;
        }
        $scope.showClientSpinner = true;
        $scope.loadingMessage = "Saving Client Information...";

        var $expenses = [];

        for (var x in $scope.billPayments) {
          if (typeof $scope.billPayments[x] !== "function") {
            $expenses.push($scope.billPayments[x]);
          }
        }

        for (x in $scope.customBills) {
          $scope.customBills[x].customExpense = true;
          $expenses.push($scope.customBills[x]);
        }

        for (x in $scope.taxDeductible) {
          $scope.taxDeductible[x].taxDeductible = true;
          $scope.taxDeductible[x].tier4 = "Bills";
          $expenses.push($scope.taxDeductible[x]);
        }

        for (x in $scope.otherExpenses) {
          $expenses.push($scope.otherExpenses[x]);
        }
        for (var $i = 0; $i < $expenses.length; $i++) {
          // check if frequency and amount exists
          // add empty frequency/amount property
          if (
            !$expenses[$i].hasOwnProperty("frequency") ||
            !$expenses[$i].frequency
          ) {
            $expenses[$i].frequency = null;
          }
          if (!$expenses[$i].hasOwnProperty("amount")) {
            $expenses[$i].amount = null;
          }
        }
        /** Inserted Code 101 */
        var propertiesFormData = {
          properties: $scope.properties,
        };

        const defaultExpenses = $expenses.filter(
          (def) => def.customExpense === false && def.taxDeductible === false
        );
        const customBills = formUniqueArrayByValue(
          $expenses.filter(
            (cus) => cus.customExpense === true && cus.tier4 === "Bills"
          ),
          "desc"
        );
        const mergeDefCus = [...defaultExpenses, ...customBills];
        const uniqueDefCus = formUniqueArrayByValue(mergeDefCus, "desc");

        if (mergeDefCus.length > uniqueDefCus.length) {
          $rootScope.setAlertValues(
            true,
            false,
            `You cannot add custom bill
        that has the same description as the default bills and spendings.
        Please change the custom one in order for you to save your changes.`,
            true
          );
          $scope.showClientSpinner = false;
        } else {
          DataM.saveProperties(propertiesFormData)
            .then(function (data) {
              if (data._message === "Client validation failed") {
                var errorMessage = "";
                const formattedMessage = DataHelper.formatMongoError(data);
                $scope.showClientSpinner = false;

                $scope.didNextSave = false;
                alert(formattedMessage);
              }
            })
            .catch(function (err) {
              $scope.didNextSave = false;
              $scope.showClientSpinner = false;
            });

          var formData = {
            expenses: {
              annualSpendings: $scope.annualSpendings,
              annualBills: $scope.annualBills,
              expenses: $expenses,
            },
          };
          //SAVE TO DB
          DataM.saveBillPayments(formData)
            .then(function (data) {
              if (data._message === "Client validation failed") {
                var errorMessage = "";
                const formattedMessage = DataHelper.formatMongoError(data);
                $scope.showClientSpinner = false;

                $scope.didNextSave = false;
                alert(formattedMessage);
              } else {
                if ($scope.isSaving && $scope.toStateName !== "") {
                  $state.go($scope.toStateName, {
                    clientId: $scope.clientId,
                  });
                } else {
                  if ($location.$$url.includes("bills")) {
                    $rootScope.setAlertValues(
                      true,
                      true,
                      "Successfully saved changes!",
                      false
                    );
                    $scope.initialBills = null;
                    $scope.initialProperties = null;
                    $scope.initialBills = stringifyJSON(
                      toCompare,
                      defaultKeysToRemove
                    );
                    $scope.initialProperties = stringifyJSON(toCompare2, [
                      ...defaultKeysToRemove,
                      "isOpen",
                    ]);
                    $scope.changeBottomButtonState();
                  } else {
                    $state.go(location, {
                      clientId: $scope.clientId,
                    });
                  }
                }
                $scope.fetchFlexSummaryData();
                $scope.showClientSpinner = false;
              }
            })
            .catch(function (err) {
              $rootScope.setAlertValues(
                true,
                false,
                "There is a problem accessing the server.",
                true
              );
              alert(
                "There is a problem accessing the server. Please make sure your internet connection is working. If it is working and this problem persist, please report this problem to Empower Wealth team."
              );
              $scope.showClientSpinner = false;
            });
        }
      };

      $scope.next = function () {
        // check if form is valid
        $scope.save("fact-find7");
      };
      $scope.back = function () {
        $state.go("fact-find5", {
          clientId: $scope.clientId,
        });
      };
      $scope.quit = function () {
        $state.go("dashboard");
      };

      $scope.savedText = "Save";

      $scope.showAlertValidationModal = function () {
        $scope.alertMessage = $scope.empowerData.saveValidationMessage;
        $scope.showAlertModal = true;
      };
      $scope.closeModal = function () {
        $scope.showAlertModal = false;
        if ($scope.elementToFocus) {
          $timeout($scope.elementToFocus.focus(), 1500);
        }
      };
      $scope.showConfirmSaveModal = function (page) {
        if (!$scope.didNextSave) {
          // $scope.toShowConfirmSaveModal = true;
          $scope.nextPageLocation = page;
        }
      };
      $scope.goNextAndNotSaveModal = function () {
        // $scope.toShowConfirmSaveModal = false;
        $state.go($scope.nextPageLocation, {
          clientId: $scope.clientId,
        });
      };
      $scope.goNextAndSaveModal = function () {
        // $scope.toShowConfirmSaveModal = false;
        $scope.didNextSave = true;
        $scope.save($scope.nextPageLocation);
      };
      $scope.closeConfirmModal = function () {
        // $scope.toShowConfirmSaveModal = false;
        $scope.savedText = "Save";
        $scope.isSaving = false;
      };
      function closeModalWhenURLChanges() {
        //Removing modal when url is changed
        $("body").removeClass("modal-open");
        $(".modal-backdrop").remove();
      }
      $scope.toggleFlexSummaryModal = function () {
        $scope.openFlexSummary = $scope.openFlexSummary ? false : true;
      };
      $scope.$on(
        "$stateChangeStart",
        function (event, toState, toParams, fromState, fromParams) {
          closeModalWhenURLChanges();

          if (_.isEqual(toState.name, "login")) {
          } else if (
            toState.url.indexOf("bills") == -1 &&
            !$scope.isSaving &&
            !$scope.didNextSave
          ) {
            $scope.isSaving = true;
            $scope.toStateName = toState.name;
            event.preventDefault();
            if ($scope.showBottomButtons) {
              $scope.showChangesAlert = true;
            } else {
              $state.go(toState.name, {
                clientId: $scope.clientId,
              });
              return;
            }
          }
        }
      );
      $scope.dashboardSave = function () {
        Auth.isAdmin().then((isAdmin) => {
          if (!isAdmin) {
            if ($scope.forms.factfindform.$valid) {
              $scope.save("bills");

              $scope.savedText = "Saved";
            }
          } else {
            $scope.save("bills");

            $scope.savedText = "Saved";
          }
        });
      };

      $scope.getFlexExpenses = function () {
        // DataHelper.getMappedFlexExpenses deprecated by DataM.getFlexSummary
        $scope.flexExpenses = DataHelper.getMappedFlexExpenses($scope.expenses);
        var propertyINVCost = {
          flexType: "Utilities & Rates - INV",
          monthlyAmount: 0,
          items: [],
        };
        // Contains property expenses
        // DataHelper.mapFlexPropertyExpenses deprecated by DataM.getFlexSummary
        propertyINVCost = DataHelper.mapFlexPropertyExpenses(
          $scope.properties,
          propertyINVCost
        );

        $scope.flexExpenses.push(propertyINVCost);
        $scope.flexExpenses = DataHelper.arrangeFlexExpenseOrder(
          $scope.flexExpenses
        );

        $scope.flexExpensesMonthlyTotal = DataHelper.getFlexMonthlyTotal(
          $scope.flexExpenses
        );
      };

      $scope.assignOwnership = function (taxDeductibleExpense, owner) {
        var p =
          $scope.taxDeductible[
            $scope.taxDeductible.indexOf(taxDeductibleExpense)
          ];

        if (owner.name == "Joint") {
          if ($scope.owners.length > 2) {
            p.ownership = {
              ownershipType: "Joint",
              owners: [
                {
                  percentage: 50,
                  name:
                    $scope.personalInfo.client1.fName +
                    " " +
                    $scope.personalInfo.client1.lName,
                  owner: $scope.personalInfo.client1._id,
                },
                {
                  percentage: 50,
                  name:
                    $scope.personalInfo.client2.fName +
                    " " +
                    $scope.personalInfo.client2.lName,
                  owner: $scope.personalInfo.client2._id,
                },
              ],
            };
          }
        } else if (owner.name == "Tenants in Common") {
          p.ownership = {
            ownershipType: "Joint",
            owners: [
              {
                percentage: 0,
                name:
                  $scope.personalInfo.client1.fName +
                  " " +
                  $scope.personalInfo.client1.lName,
                owner: $scope.personalInfo.client1._id,
              },
            ],
          };
          if ($scope.owners.length > 2) {
            var owner2 = {
              percentage: 0,
              name:
                $scope.personalInfo.client2.fName +
                " " +
                $scope.personalInfo.client2.lName,
              owner: $scope.personalInfo.client2._id,
            };
            p.ownership.owners.push(owner2);
          }
        } else {
          p.ownership = {
            ownershipType: "Sole",
            owners: [
              {
                percentage: 100,
                name: owner.name,
                owner: owner.id,
              },
            ],
          };
        }
      };
    }
  )

  .controller(
    "factfind-7-controller",
    function (
      $scope,
      $state,
      $stateParams,
      $location,
      $rootScope,
      DataM,
      Auth,
      empowerDataService,
      $timeout,
      DataHelper,
      $filter,
      EmpowerWealthHelper
    ) {
      "use strict";
      $scope.isAdmin = false;
      Auth.isAdmin().then((isAdmin) => ($scope.isAdmin = isAdmin));
      $scope.fetchFlexSummaryData = function () {
        $rootScope.$broadcast("OnExpensesShowFlexSummary");
      };
      $scope.spending = {};
      $scope.openFlexSummary = false;
      $scope.customSpending = [];
      $scope.openFlexSummary = false;
      // attributes as defined in client.model.js
      $scope.expenses = [];
      $scope.isReviewed = false;
      $scope.addlAdvisorInfo = "";

      $scope.showClientSpinner = true;
      $scope.loadingMessage = "Retrieving Client Information...";
      $scope.showRetrieveError = false;

      $scope.clientId = $stateParams.clientId;
      $scope.state = $state.current;
      $scope.params = $stateParams;
      $scope.forms = {};
      $scope.isPersonalInfoCompleted = false;
      $scope.isIncomeCompleted = false;
      $scope.isPropertyCompleted = false;
      $scope.isNonPropertyCompleted = false;
      $scope.isBorrowingCompleted = false;
      $scope.isBillPaymentsCompleted = false;
      $scope.isSpendingCompleted = false;
      $scope.isFPlanHouseholdCompleted = false;
      $scope.isFPlanIncomeCompleted = false;
      $scope.didNextSave = false;
      $scope.isBasicExpensesHidden = true;
      $scope.isSaving = false;
      const { formUniqueArrayByValue, stringifyJSON } = EmpowerWealthHelper;
      const defaultKeysToRemove = ["$$hashKey", "$promise", "$resolved"];

      $scope.isUserAdmin = false;
      $scope.isMBAdmin = false;
      $scope.spendingPageActive = true;

      $scope.flexExpenses = [];
      $scope.flexExpensesMonthlyTotal = 0;
      $scope.properties = [];

      $scope.hasExpenseChanges = false;
      $scope.rent = {
        amount: 0,
        discretionary: 0,
        basic: 0,
      };
      // check if user is admin
      DataM.getMe()
        .then(function (userData) {
          if (userData.role !== "user" && userData.role !== "guest") {
            $scope.isUserAdmin = true;
            if (
              userData.role === "super_admin" ||
              userData.role === "mb_admin"
            ) {
              $scope.isMBAdmin = true;
            }
          } else {
            $scope.isUserAdmin = false;
          }
        })
        .catch(function (err) {
          console.log("Retrieve current user error");
        });
      $scope.spendings = [];
      $scope.computeMonthly = function (expense) {
        DataHelper.processExpenseMonthly(expense);

        computeBreakDown();
      };

      function computeBreakDown() {
        var spendings = $scope.spendings;
        spendings = spendings.concat($scope.customSpending);

        var allExpenses = $scope.bills.concat(spendings);
        $scope.computeMonthlyBreakdown($scope.bills, "BILL");
        $scope.computeMonthlyBreakdown(spendings, "SPENDING");
        $scope.computeMonthlyBreakdown(allExpenses, "ALL");
      }
      /*Empower Data Service - For common datasets*/
      $scope.empowerData = empowerDataService;

      $scope.getMonthly = function (amount, frequency, dataType) {
        var annual = 0;

        switch (frequency) {
          case "Weekly":
            annual = amount * 52;
            break;
          case "Fortnightly":
            annual = amount * 26;
            break;
          case "Monthly":
            annual = amount * 12;
            break;
          case "Every 2 months":
            annual = amount * 6;
            break;
          case "Every 3 months":
            annual = amount * 4;
            break;
          case "Every 6 months":
            annual = amount * 2;
            break;
          case "Yearly":
            annual = amount;
            break;
        }
        if (dataType === "number") {
          return annual / 12;
        } else {
          var monthly = $filter("number")(annual / 12, 2);

          return monthly;
        }
      };

      $scope.totalBillsAmount = "";
      $scope.totalBillsDiscAmount = "";
      $scope.totalBillsBasicAmount = "";

      $scope.totalSpendingAmount = "";
      $scope.totalSpendingDiscAmount = "";
      $scope.totalSpendingBasicAmount = "";

      $scope.totalAmount = "";
      $scope.totalDiscAmount = "";
      $scope.totalBasicAmount = "";
      $scope.annualBills = {};
      $scope.initialSpendingBasic = 0;
      $scope.annualSpendings = {};

      $scope.annualSpendingsAmount = "";
      $scope.annualSpendingsDiscAmount = "";
      $scope.annualSpendingsBasicAmount = "";

      $scope.rentMonthly = {
        amount: 0,
        basic: 0,
        discretionary: 0,
      };

      $scope.bills = [];

      $scope.showChangesAlert = false;
      let toCompare = null;
      $scope.initialSpending = null;
      $scope.showBottomButtons = false;
      $scope.toStateName = "";

      $scope.closeChangesAlert = function () {
        $scope.showChangesAlert = false;
        $scope.isSaving = false;
      };

      $scope.dashboardDiscard = async function () {
        $scope.showClientSpinner = true;
        $scope.loadingMessage = "Discarding Client Changes...";
        if ($scope.showChangesAlert) {
          $scope.showChangesAlert = false;
        }
        var promise = new Promise(function (resolve, reject) {
          $timeout(function () {
            $scope.setInputValues(true);
            resolve("done");
          }, 1);
        });

        let result = await promise;
        if (result === "done") {
          $scope.showClientSpinner = false;
          if ($scope.isSaving && $scope.toStateName !== "") {
            $state.go($scope.toStateName, {
              clientId: $scope.clientId,
            });
          } else {
            $scope.showBottomButtons = false;
            $rootScope.setAlertValues(
              true,
              true,
              "Successfully discarded changes!",
              true
            );
          }
        }
      };

      /**
       * Sets initial annual expense
       */
      function setInitialAmount(initial, current) {
        if (current === 0 && $scope.hasExpenseChanges === false) {
          if (initial > 0) {
            return initial;
          }
        }
        return current;
      }

      function checkIfSpendingHasInput() {
        if ($scope.hasExpenseChanges) {
          return true;
        }
        if ($scope.spendings.length > 0 || $scope.customSpending.length > 0) {
          for (var i = 0; i < $scope.spendings.length; i++) {
            const expense = $scope.spendings[i];
            if (expense.amount > 0) {
              return true;
            }
          }
          for (var i = 0; i < $scope.customSpending.length; i++) {
            const expense = $scope.customSpending[i];
            if (expense.amount > 0) {
              return true;
            }
          }
          return false;
        } else {
          return false;
        }
      }

      $scope.computeMonthlyBreakdown = function (expenseArray, expenseType) {
        var total = 0;
        var discTotal = 0;
        var basicTotal = 0;

        for (var i = 0; i < expenseArray.length; i++) {
          var expenseItem = expenseArray[i];

          if (expenseItem.frequency) {
            if (typeof expenseItem.discretionary === "number") {
              discTotal += $scope.getMonthly(
                expenseItem.discretionary,
                expenseItem.frequency,
                "number"
              );
            }
            if (typeof expenseItem.basic === "number") {
              basicTotal += $scope.getMonthly(
                expenseItem.basic,
                expenseItem.frequency,
                "number"
              );
            }
          }
        }

        total = basicTotal + discTotal;

        if (expenseType === "BILL") {
          let initialMonthlyTotal = $scope.annualBills.amount / 12;
          let initialMonthlyBasic = $scope.annualBills.basic / 12;
          let initialMonthlyDisc = $scope.annualBills.discretionary / 12;

          total = setInitialAmount(initialMonthlyTotal, total);
          basicTotal = setInitialAmount(initialMonthlyBasic, basicTotal);
          discTotal = setInitialAmount(initialMonthlyDisc, discTotal);

          if ($scope.rent.basic || $scope.rent.basic === 0) {
            $scope.rentMonthly.basic = $scope.getMonthly(
              $scope.rent.basic,
              $scope.rent.frequency,
              "number"
            );
            basicTotal = basicTotal - $scope.rentMonthly.basic;
          }
          if ($scope.rent.discretionary || $scope.rent.discretionary === 0) {
            $scope.rentMonthly.discretionary = $scope.getMonthly(
              $scope.rent.discretionary,
              $scope.rent.frequency,
              "number"
            );
            discTotal = discTotal - $scope.rentMonthly.discretionary;
          }
          if ($scope.rent.amount || $scope.rent.amount === 0) {
            $scope.rentMonthly.amount = $scope.getMonthly(
              $scope.rent.amount,
              $scope.rent.frequency,
              "number"
            );
            total = total - $scope.rentMonthly.amount;
          }

          $scope.totalBillsAmount = $filter("number")(total, 2);
          $scope.totalBillsDiscAmount = $filter("number")(discTotal, 2);
          $scope.totalBillsBasicAmount = $filter("number")(basicTotal, 2);
        } else if (expenseType === "SPENDING") {
          // get annual
          $scope.annualSpendings.basic =
            12 * basicTotal > 0 ? 12 * basicTotal : null;
          $scope.annualSpendings.discretionary =
            12 * discTotal > 0 ? 12 * discTotal : null;
          $scope.annualSpendings.amount = 12 * total > 0 ? 12 * total : null;

          if (basicTotal === 0) {
            $scope.annualSpendings.basic =
              $scope.initialSpendingBasic === 0
                ? null
                : $scope.initialSpendingBasic;
            $scope.annualSpendings.discretionary = null;
            $scope.annualSpendings.amount =
              $scope.initialSpendingBasic === 0
                ? null
                : $scope.initialSpendingBasic;
          }

          let initialMonthlyTotal = $scope.annualSpendings.amount / 12;
          let initialMonthlyBasic = $scope.annualSpendings.basic / 12;
          let initialMonthlyDisc = $scope.annualSpendings.discretionary / 12;

          total = setInitialAmount(initialMonthlyTotal, total);
          basicTotal = setInitialAmount(initialMonthlyBasic, basicTotal);
          discTotal = setInitialAmount(initialMonthlyDisc, discTotal);

          $scope.totalSpendingAmount = $filter("number")(total, 2);
          $scope.totalSpendingDiscAmount = $filter("number")(discTotal, 2);
          $scope.totalSpendingBasicAmount = $filter("number")(basicTotal, 2);

          $scope.annualSpendingsAmount = $filter("number")(
            $scope.annualSpendings.amount,
            2
          );
          $scope.annualSpendingsDiscAmount = $filter("number")(
            $scope.annualSpendings.discretionary,
            2
          );
          $scope.annualSpendingsBasicAmount = $filter("number")(
            $scope.annualSpendings.basic,
            2
          );
        } else if (expenseType === "ALL") {
          let initialMonthlyTotal =
            ($scope.annualBills.amount + $scope.annualSpendings.amount) / 12;
          let initialMonthlyBasic =
            ($scope.annualBills.basic + $scope.annualSpendings.basic) / 12;
          let initialMonthlyDisc =
            ($scope.annualBills.discretionary +
              $scope.annualSpendings.discretionary) /
            12;
          total = setInitialAmount(initialMonthlyTotal, total);
          basicTotal = setInitialAmount(initialMonthlyBasic, basicTotal);
          discTotal = setInitialAmount(initialMonthlyDisc, discTotal);

          $scope.totalAmount = $filter("number")(total, 2);
          $scope.totalDiscAmount = $filter("number")(discTotal, 2);
          $scope.totalBasicAmount = $filter("number")(basicTotal, 2);
        }
      };

      $scope.processExpenseTotalMonthly = function (expense, spendingCat) {
        if (
          $scope.spending[spendingCat + "Discretionary"] ||
          $scope.spending[spendingCat + "Discretionary"] === 0 ||
          $scope.spending[spendingCat + "Discretionary"] === null
        ) {
          expense.discretionary =
            $scope.spending[spendingCat + "Discretionary"];
          expense.amount = expense.discretionary;

          $scope.spending[spendingCat + "Total"] = $filter("number")(
            expense.amount,
            2
          );

          if (expense.frequency) {
            $scope.spending[spendingCat + "MonthlyDisc"] = $scope.getMonthly(
              expense.discretionary,
              expense.frequency
            );
            $scope.spending[spendingCat + "Monthly"] = $scope.getMonthly(
              expense.amount,
              expense.frequency
            );
          }
        }
        if (expense.basic || expense.basic === 0 || expense.basic === null) {
          expense.amount = expense.basic;
          if (expense.discretionary) {
            expense.amount = expense.discretionary + expense.basic;
          }
          $scope.spending[spendingCat + "Total"] = $filter("number")(
            expense.amount,
            2
          );

          if (expense.frequency) {
            $scope.spending[spendingCat + "Monthly"] = $scope.getMonthly(
              expense.amount,
              expense.frequency
            );
            $scope.spending[spendingCat + "MonthlyBasic"] = $scope.getMonthly(
              expense.basic,
              expense.frequency
            );
          }
        }
        if ($scope.spending[spendingCat + "Category"]) {
          expense.category = $scope.spending[spendingCat + "Category"];
        }

        if (expense.basic === null && expense.discretionary === null) {
          expense.amount = null;
          $scope.spending[spendingCat + "Total"] = "";
          $scope.spending[spendingCat + "Monthly"] = "";
        }

        $scope.computeMonthly(expense);
      };

      $scope.addNewCustomSpending = function () {
        var spend = {
          tier4: "Spending",
          customExpense: true,
          frequency: "Monthly",
          basic: null,
          discretionary: null,
          amount: null,
          totalString: "",
          monthly: "",
          amountStr: "",
          desc: "",
        };
        $scope.customSpending.push(spend);
        toCompare.expenses.expenses.push(spend);
      };

      $scope.deleteCustomSpending = function (spend) {
        $scope.customSpending.splice($scope.customSpending.indexOf(spend), 1);
        toCompare.expenses.expenses.splice(
          toCompare.expenses.expenses.indexOf(spend),
          1
        );
        computeBreakDown();
      };

      $scope.setGroceries = function () {
        var expense = {};
        if ($scope.spending.hasOwnProperty("groceries")) {
          var found = false;

          for (var $i = 0; $i < $scope.expenses.length; $i++) {
            if (
              $scope.expenses[$i].desc ===
              "Groceries and Non-alcoholic Beverages"
            ) {
              found = true;
              expense = $scope.expenses[$i];
              $scope.expenses[$i].basic = $scope.spending.groceries;
            }
          }
          if (!found) {
            expense = {
              tier4: "Living Lifestyle",
              desc: "Groceries and Non-alcoholic Beverages",
              basic: $scope.spending.groceries,
            };
            $scope.expenses.push(expense);
          }

          $scope.processExpenseTotalMonthly(expense, "groceries");
        }
      };
      $scope.setGroceriesFreq = function () {
        var expense = {};
        if ($scope.spending.hasOwnProperty("groceriesFreq")) {
          var found = false;
          for (var $i = 0; $i < $scope.expenses.length; $i++) {
            if (
              $scope.expenses[$i].desc ===
              "Groceries and Non-alcoholic Beverages"
            ) {
              found = true;
              expense = $scope.expenses[$i];
              $scope.expenses[$i].frequency = $scope.spending.groceriesFreq;
              expense = $scope.expenses[$i];
            }
          }
          if (!found) {
            expense = {
              tier4: "Living Lifestyle",
              desc: "Groceries and Non-alcoholic Beverages",
              frequency: $scope.spending.groceriesFreq,
            };
            $scope.expenses.push(expense);
          }
          $scope.processExpenseTotalMonthly(expense, "groceries");
        }
      };

      $scope.setClothing = function () {
        var expense = {};
        if ($scope.spending.hasOwnProperty("clothing")) {
          var found = false;
          for (var $i = 0; $i < $scope.expenses.length; $i++) {
            if (
              $scope.expenses[$i].desc ===
              "Clothing, Footwear, Dry cleaning, Repairs"
            ) {
              found = true;
              expense = $scope.expenses[$i];
              $scope.expenses[$i].basic = $scope.spending.clothing;
            }
          }
          if (!found) {
            expense = {
              tier4: "Living Lifestyle",
              desc: "Clothing, Footwear, Dry cleaning, Repairs",
              amount: $scope.spending.clothing,
            };
            $scope.expenses.push(expense);
          }
          $scope.processExpenseTotalMonthly(expense, "clothing");
        }
      };
      $scope.setClothingFreq = function () {
        var expense = {};
        if ($scope.spending.hasOwnProperty("clothingFreq")) {
          var found = false;
          for (var $i = 0; $i < $scope.expenses.length; $i++) {
            if (
              $scope.expenses[$i].desc ===
              "Clothing, Footwear, Dry cleaning, Repairs"
            ) {
              found = true;
              expense = $scope.expenses[$i];
              $scope.expenses[$i].frequency = $scope.spending.clothingFreq;
            }
          }
          if (!found) {
            expense = {
              tier4: "Living Lifestyle",
              desc: "Clothing, Footwear, Dry cleaning, Repairs",
              frequency: $scope.spending.clothingFreq,
            };
            $scope.expenses.push(expense);
          }

          $scope.processExpenseTotalMonthly(expense, "clothing");
        }
      };

      $scope.setEducation = function () {
        var expense = {};
        if ($scope.spending.hasOwnProperty("education")) {
          var found = false;
          for (var $i = 0; $i < $scope.expenses.length; $i++) {
            if (
              $scope.expenses[$i].desc === "Education - Out-of-pocket expenses"
            ) {
              found = true;
              expense = $scope.expenses[$i];
              $scope.expenses[$i].basic = $scope.spending.education;
            }
          }
          if (!found) {
            expense = {
              tier4: "Bills",
              desc: "Education - Out-of-pocket expenses",
              amount: $scope.spending.education,
            };
            $scope.expenses.push(expense);
          }
          $scope.processExpenseTotalMonthly(expense, "education");
        }
      };
      $scope.setEducationFreq = function () {
        var expense = {};
        if ($scope.spending.hasOwnProperty("educationFreq")) {
          var found = false;
          for (var $i = 0; $i < $scope.expenses.length; $i++) {
            if (
              $scope.expenses[$i].desc === "Education - Out-of-pocket expenses"
            ) {
              found = true;
              expense = $scope.expenses[$i];
              $scope.expenses[$i].frequency = $scope.spending.educationFreq;
            }
          }
          if (!found) {
            expense = {
              tier4: "Bills",
              desc: "Education - Out-of-pocket expenses",
              frequency: $scope.spending.educationFreq,
            };
            $scope.expenses.push(expense);
          }

          $scope.processExpenseTotalMonthly(expense, "education");
        }
      };

      $scope.setHealthCare = function () {
        var expense = {};
        if ($scope.spending.hasOwnProperty("healthCare")) {
          var found = false;
          for (var $i = 0; $i < $scope.expenses.length; $i++) {
            if (
              $scope.expenses[$i].desc === "Medical and Health Care expenses"
            ) {
              found = true;
              expense = $scope.expenses[$i];
              $scope.expenses[$i].basic = $scope.spending.healthCare;
            }
          }
          if (!found) {
            expense = {
              tier4: "Bills",
              desc: "Medical and Health Care expenses",
              amount: $scope.spending.healthCare,
            };
            $scope.expenses.push(expense);
          }
          $scope.processExpenseTotalMonthly(expense, "healthCare");
        }
      };
      $scope.setHealthCareFreq = function () {
        var expense = {};
        if ($scope.spending.hasOwnProperty("healthCareFreq")) {
          var found = false;
          for (var $i = 0; $i < $scope.expenses.length; $i++) {
            if (
              $scope.expenses[$i].desc === "Medical and Health Care expenses"
            ) {
              found = true;
              expense = $scope.expenses[$i];
              $scope.expenses[$i].frequency = $scope.spending.healthCareFreq;
            }
          }
          if (!found) {
            expense = {
              tier4: "Bills",
              desc: "Medical and Health Care expenses",
              frequency: $scope.spending.healthCareFreq,
            };
            $scope.expenses.push(expense);
          }
          $scope.processExpenseTotalMonthly(expense, "healthCare");
        }
      };

      $scope.setPersonalCare = function () {
        var expense = {};
        if ($scope.spending.hasOwnProperty("personalCare")) {
          var found = false;
          for (var $i = 0; $i < $scope.expenses.length; $i++) {
            if ($scope.expenses[$i].desc === "Personal Care") {
              found = true;
              expense = $scope.expenses[$i];
              $scope.expenses[$i].basic = $scope.spending.personalCare;
            }
          }
          if (!found) {
            expense = {
              tier4: "Living Lifestyle",
              desc: "Personal Care",
              amount: $scope.spending.personalCare,
            };
            $scope.expenses.push(expense);
          }
          $scope.processExpenseTotalMonthly(expense, "personalCare");
        }
      };
      $scope.setPersonalCareFreq = function () {
        var expense = {};
        if ($scope.spending.hasOwnProperty("personalCareFreq")) {
          var found = false;
          for (var $i = 0; $i < $scope.expenses.length; $i++) {
            if ($scope.expenses[$i].desc === "Personal Care") {
              found = true;
              expense = $scope.expenses[$i];
              $scope.expenses[$i].frequency = $scope.spending.personalCareFreq;
            }
          }
          if (!found) {
            expense = {
              tier4: "Living Lifestyle",
              desc: "Personal Care",
              frequency: $scope.spending.personalCareFreq,
            };
            $scope.expenses.push(expense);
          }
          $scope.processExpenseTotalMonthly(expense, "personalCare");
        }
      };

      $scope.setPets = function () {
        var expense = {};
        if ($scope.spending.hasOwnProperty("pets")) {
          var found = false;
          for (var $i = 0; $i < $scope.expenses.length; $i++) {
            if ($scope.expenses[$i].desc === "Pets and Animals") {
              found = true;
              expense = $scope.expenses[$i];
              $scope.expenses[$i].basic = $scope.spending.pets;
            }
          }
          if (!found) {
            expense = {
              tier4: "Living Lifestyle",
              desc: "Pets and Animals",
              amount: $scope.spending.pets,
            };
            $scope.expenses.push(expense);
          }
          $scope.processExpenseTotalMonthly(expense, "pets");
        }
      };
      $scope.setPetsFreq = function () {
        var expense = {};
        if ($scope.spending.hasOwnProperty("petsFreq")) {
          var found = false;
          for (var $i = 0; $i < $scope.expenses.length; $i++) {
            if ($scope.expenses[$i].desc === "Pets and Animals") {
              found = true;
              expense = $scope.expenses[$i];
              $scope.expenses[$i].frequency = $scope.spending.petsFreq;
            }
          }
          if (!found) {
            expense = {
              tier4: "Living Lifestyle",
              desc: "Pets and Animals",
              frequency: $scope.spending.petsFreq,
            };
            $scope.expenses.push(expense);
          }
          $scope.processExpenseTotalMonthly(expense, "pets");
        }
      };

      $scope.setAppliances = function () {
        var expense = {};
        if ($scope.spending.hasOwnProperty("appliances")) {
          var found = false;
          for (var $i = 0; $i < $scope.expenses.length; $i++) {
            if ($scope.expenses[$i].desc === "Household Appliances and Tools") {
              found = true;
              expense = $scope.expenses[$i];
              $scope.expenses[$i].basic = $scope.spending.appliances;
            }
          }
          if (!found) {
            expense = {
              tier4: "Provisioning",
              desc: "Household Appliances and Tools",
              amount: $scope.spending.appliances,
            };
            $scope.expenses.push(expense);
          }
          $scope.processExpenseTotalMonthly(expense, "appliances");
        }
      };
      $scope.setAppliancesFreq = function () {
        var expense = {};
        if ($scope.spending.hasOwnProperty("appliancesFreq")) {
          var found = false;
          for (var $i = 0; $i < $scope.expenses.length; $i++) {
            if ($scope.expenses[$i].desc === "Household Appliances and Tools") {
              found = true;
              expense = $scope.expenses[$i];
              $scope.expenses[$i].frequency = $scope.spending.appliancesFreq;
            }
          }
          if (!found) {
            expense = {
              tier4: "Provisioning",
              desc: "Household Appliances and Tools",
              frequency: $scope.spending.appliancesFreq,
            };
            $scope.expenses.push(expense);
          }
          $scope.processExpenseTotalMonthly(expense, "appliances");
        }
      };

      $scope.setFurniture = function () {
        var expense = {};
        if ($scope.spending.hasOwnProperty("furniture")) {
          var found = false;
          for (var $i = 0; $i < $scope.expenses.length; $i++) {
            if ($scope.expenses[$i].desc === "Furniture") {
              found = true;
              expense = $scope.expenses[$i];
              $scope.expenses[$i].basic = $scope.spending.furniture;
            }
          }
          if (!found) {
            expense = {
              tier4: "Provisioning",
              desc: "Furniture",
              amount: $scope.spending.furniture,
            };
            $scope.expenses.push(expense);
          }
          $scope.processExpenseTotalMonthly(expense, "furniture");
        }
      };
      $scope.setFurnitureFreq = function () {
        var expense = {};
        if ($scope.spending.hasOwnProperty("furnitureFreq")) {
          var found = false;
          for (var $i = 0; $i < $scope.expenses.length; $i++) {
            if ($scope.expenses[$i].desc === "Furniture") {
              found = true;
              expense = $scope.expenses[$i];
              $scope.expenses[$i].frequency = $scope.spending.furnitureFreq;
            }
          }
          if (!found) {
            expense = {
              tier4: "Provisioning",
              desc: "Furniture",
              frequency: $scope.spending.furnitureFreq,
            };
            $scope.expenses.push(expense);
          }
          $scope.processExpenseTotalMonthly(expense, "furniture");
        }
      };

      $scope.setFurnishings = function () {
        var expense = {};
        if ($scope.spending.hasOwnProperty("furnishings")) {
          var found = false;
          for (var $i = 0; $i < $scope.expenses.length; $i++) {
            if ($scope.expenses[$i].desc === "Household Furnishings") {
              found = true;
              expense = $scope.expenses[$i];
              $scope.expenses[$i].basic = $scope.spending.furnishings;
            }
          }
          if (!found) {
            expense = {
              tier4: "Living Lifestyle",
              desc: "Household Furnishings",
              amount: $scope.spending.furnishings,
            };
            $scope.expenses.push(expense);
          }
          $scope.processExpenseTotalMonthly(expense, "furnishings");
        }
      };
      $scope.setFurnishingsFreq = function () {
        var expense = {};
        if ($scope.spending.hasOwnProperty("furnishingsFreq")) {
          var found = false;
          for (var $i = 0; $i < $scope.expenses.length; $i++) {
            if ($scope.expenses[$i].desc === "Household Furnishings") {
              found = true;
              expense = $scope.expenses[$i];
              $scope.expenses[$i].frequency = $scope.spending.furnishingsFreq;
            }
          }
          if (!found) {
            expense = {
              tier4: "Living Lifestyle",
              desc: "Household Furnishings",
              frequency: $scope.spending.furnishingsFreq,
            };
            $scope.expenses.push(expense);
          }
          $scope.processExpenseTotalMonthly(expense, "furnishings");
        }
      };

      $scope.setHomeMaintenance = function () {
        var expense = {};
        if ($scope.spending.hasOwnProperty("homeMaintenance")) {
          var found = false;
          for (var $i = 0; $i < $scope.expenses.length; $i++) {
            if ($scope.expenses[$i].desc === "House and Garden maintenance") {
              found = true;
              expense = $scope.expenses[$i];
              $scope.expenses[$i].basic = $scope.spending.homeMaintenance;
            }
          }
          if (!found) {
            expense = {
              tier4: "Household",
              desc: "House and Garden maintenance",
              amount: $scope.spending.homeMaintenance,
            };
            $scope.expenses.push(expense);
          }
          $scope.processExpenseTotalMonthly(expense, "homeMaintenance");
        }
      };
      $scope.setHomeMaintenanceFreq = function () {
        var expense = {};
        if ($scope.spending.hasOwnProperty("homeMaintenanceFreq")) {
          var found = false;
          for (var $i = 0; $i < $scope.expenses.length; $i++) {
            if ($scope.expenses[$i].desc === "House and Garden maintenance") {
              found = true;
              expense = $scope.expenses[$i];
              $scope.expenses[$i].frequency =
                $scope.spending.homeMaintenanceFreq;
            }
          }
          if (!found) {
            expense = {
              tier4: "Household",
              desc: "House and Garden maintenance",
              frequency: $scope.spending.homeMaintenanceFreq,
            };
            $scope.expenses.push(expense);
          }
          $scope.processExpenseTotalMonthly(expense, "homeMaintenance");
        }
      };

      $scope.setFuel = function () {
        var expense = {};
        if ($scope.spending.hasOwnProperty("fuel")) {
          var found = false;
          for (var $i = 0; $i < $scope.expenses.length; $i++) {
            if ($scope.expenses[$i].desc === "Fuel") {
              found = true;
              expense = $scope.expenses[$i];
              $scope.expenses[$i].basic = $scope.spending.fuel;
            }
          }
          if (!found) {
            expense = {
              tier4: "Bills",
              desc: "Fuel",
              amount: $scope.spending.fuel,
            };
            $scope.expenses.push(expense);
          }
          $scope.processExpenseTotalMonthly(expense, "fuel");
        }
      };
      $scope.setFuelFreq = function () {
        var expense = {};
        if ($scope.spending.hasOwnProperty("fuelFreq")) {
          var found = false;
          for (var $i = 0; $i < $scope.expenses.length; $i++) {
            if ($scope.expenses[$i].desc === "Fuel") {
              found = true;
              expense = $scope.expenses[$i];
              $scope.expenses[$i].frequency = $scope.spending.fuelFreq;
            }
          }
          if (!found) {
            expense = {
              tier4: "Bills",
              desc: "Fuel",
              frequency: $scope.spending.fuelFreq,
            };
            $scope.expenses.push(expense);
          }
          $scope.processExpenseTotalMonthly(expense, "fuel");
        }
      };

      $scope.setCarMaintenance = function () {
        var expense = {};
        if ($scope.spending.hasOwnProperty("carMaintenance")) {
          var found = false;
          for (var $i = 0; $i < $scope.expenses.length; $i++) {
            if ($scope.expenses[$i].desc === "Car maintenance") {
              found = true;
              expense = $scope.expenses[$i];
              $scope.expenses[$i].basic = $scope.spending.carMaintenance;
            }
          }
          if (!found) {
            expense = {
              tier4: "Bills",
              desc: "Car maintenance",
              amount: $scope.spending.carMaintenance,
            };
            $scope.expenses.push(expense);
          }
          $scope.processExpenseTotalMonthly(expense, "carMaintenance");
        }
      };
      $scope.setCarMaintenanceFreq = function () {
        var expense = {};
        if ($scope.spending.hasOwnProperty("carMaintenanceFreq")) {
          var found = false;
          for (var $i = 0; $i < $scope.expenses.length; $i++) {
            if ($scope.expenses[$i].desc === "Car maintenance") {
              found = true;
              expense = $scope.expenses[$i];
              $scope.expenses[$i].frequency =
                $scope.spending.carMaintenanceFreq;
            }
          }
          if (!found) {
            expense = {
              tier4: "Bills",
              desc: "Car maintenance",
              frequency: $scope.spending.carMaintenanceFreq,
            };
            $scope.expenses.push(expense);
          }
          $scope.processExpenseTotalMonthly(expense, "carMaintenance");
        }
      };

      $scope.setParking = function () {
        var expense = {};
        if ($scope.spending.hasOwnProperty("parking")) {
          var found = false;
          for (var $i = 0; $i < $scope.expenses.length; $i++) {
            if ($scope.expenses[$i].desc === "Parking and Tolls") {
              found = true;
              expense = $scope.expenses[$i];
              $scope.expenses[$i].basic = $scope.spending.parking;
            }
          }
          if (!found) {
            expense = {
              tier4: "Bills",
              desc: "Parking and Tolls",
              amount: $scope.spending.parking,
            };
            $scope.expenses.push(expense);
          }
          $scope.processExpenseTotalMonthly(expense, "parking");
        }
      };
      $scope.setParkingFreq = function () {
        var expense = {};
        if ($scope.spending.hasOwnProperty("parkingFreq")) {
          var found = false;
          for (var $i = 0; $i < $scope.expenses.length; $i++) {
            if ($scope.expenses[$i].desc === "Parking and Tolls") {
              found = true;
              expense = $scope.expenses[$i];
              $scope.expenses[$i].frequency = $scope.spending.parkingFreq;
            }
          }
          if (!found) {
            expense = {
              tier4: "Bills",
              desc: "Parking and Tolls",
              frequency: $scope.spending.parkingFreq,
            };
            $scope.expenses.push(expense);
          }
          $scope.processExpenseTotalMonthly(expense, "parking");
        }
      };

      $scope.setFares = function () {
        var expense = {};
        if ($scope.spending.hasOwnProperty("fares")) {
          var found = false;
          for (var $i = 0; $i < $scope.expenses.length; $i++) {
            if ($scope.expenses[$i].desc === "Fares") {
              found = true;
              expense = $scope.expenses[$i];
              $scope.expenses[$i].basic = $scope.spending.fares;
            }
          }
          if (!found) {
            expense = {
              tier4: "Bills",
              desc: "Fares",
              amount: $scope.spending.fares,
            };
            $scope.expenses.push(expense);
          }
          $scope.processExpenseTotalMonthly(expense, "fares");
        }
      };
      $scope.setFaresFreq = function () {
        var expense = {};
        if ($scope.spending.hasOwnProperty("faresFreq")) {
          var found = false;
          for (var $i = 0; $i < $scope.expenses.length; $i++) {
            if ($scope.expenses[$i].desc === "Fares") {
              found = true;
              expense = $scope.expenses[$i];
              $scope.expenses[$i].frequency = $scope.spending.faresFreq;
            }
          }
          if (!found) {
            expense = {
              tier4: "Bills",
              desc: "Fares",
              frequency: $scope.spending.faresFreq,
            };
            $scope.expenses.push(expense);
          }
          $scope.processExpenseTotalMonthly(expense, "fares");
        }
      };

      $scope.setGifts = function () {
        var expense = {};
        if ($scope.spending.hasOwnProperty("gifts")) {
          var found = false;
          for (var $i = 0; $i < $scope.expenses.length; $i++) {
            if ($scope.expenses[$i].desc === "Presents and Gifts") {
              found = true;
              expense = $scope.expenses[$i];
              $scope.expenses[$i].basic = $scope.spending.gifts;
            }
          }
          if (!found) {
            expense = {
              tier4: "Living Lifestyle",
              desc: "Presents and Gifts",
              amount: $scope.spending.gifts,
            };
            $scope.expenses.push(expense);
          }
          $scope.processExpenseTotalMonthly(expense, "gifts");
        }
      };
      $scope.setGiftsFreq = function () {
        var expense = {};
        if ($scope.spending.hasOwnProperty("giftsFreq")) {
          var found = false;
          for (var $i = 0; $i < $scope.expenses.length; $i++) {
            if ($scope.expenses[$i].desc === "Presents and Gifts") {
              found = true;
              expense = $scope.expenses[$i];
              $scope.expenses[$i].frequency = $scope.spending.giftsFreq;
            }
          }
          if (!found) {
            expense = {
              tier4: "Living Lifestyle",
              desc: "Presents and Gifts",
              frequency: $scope.spending.giftsFreq,
            };
            $scope.expenses.push(expense);
          }
          $scope.processExpenseTotalMonthly(expense, "gifts");
        }
      };

      $scope.setDonations = function () {
        var expense = {};
        if ($scope.spending.hasOwnProperty("donations")) {
          var found = false;
          for (var $i = 0; $i < $scope.expenses.length; $i++) {
            if ($scope.expenses[$i].desc === "Donations") {
              found = true;
              expense = $scope.expenses[$i];
              $scope.expenses[$i].basic = $scope.spending.donations;
            }
          }
          if (!found) {
            expense = {
              tier4: "Living Lifestyle",
              desc: "Donations",
              amount: $scope.spending.donations,
            };
            $scope.expenses.push(expense);
          }
          $scope.processExpenseTotalMonthly(expense, "donations");
        }
      };
      $scope.setDonationsFreq = function () {
        var expense = {};
        if ($scope.spending.hasOwnProperty("donationsFreq")) {
          var found = false;
          for (var $i = 0; $i < $scope.expenses.length; $i++) {
            if ($scope.expenses[$i].desc === "Donations") {
              found = true;
              expense = $scope.expenses[$i];
              $scope.expenses[$i].frequency = $scope.spending.donationsFreq;
            }
          }
          if (!found) {
            expense = {
              tier4: "Living Lifestyle",
              desc: "Donations",
              frequency: $scope.spending.donationsFreq,
            };
            $scope.expenses.push(expense);
          }
          $scope.processExpenseTotalMonthly(expense, "donations");
        }
      };

      $scope.setHolidays = function () {
        var expense = {};
        if ($scope.spending.hasOwnProperty("holidays")) {
          var found = false;
          for (var $i = 0; $i < $scope.expenses.length; $i++) {
            if ($scope.expenses[$i].desc === "Holidays") {
              found = true;
              expense = $scope.expenses[$i];
              $scope.expenses[$i].basic = $scope.spending.holidays;
            }
          }
          if (!found) {
            expense = {
              tier4: "Provisioning",
              desc: "Holidays",
              amount: $scope.spending.holidays,
            };
            $scope.expenses.push(expense);
          }
          $scope.processExpenseTotalMonthly(expense, "holidays");
        }
      };
      $scope.setHolidaysFreq = function () {
        var expense = {};
        if ($scope.spending.hasOwnProperty("holidaysFreq")) {
          var found = false;
          for (var $i = 0; $i < $scope.expenses.length; $i++) {
            if ($scope.expenses[$i].desc === "Holidays") {
              found = true;
              expense = $scope.expenses[$i];
              $scope.expenses[$i].frequency = $scope.spending.holidaysFreq;
            }
          }
          if (!found) {
            expense = {
              tier4: "Provisioning",
              desc: "Holidays",
              frequency: $scope.spending.holidaysFreq,
            };
            $scope.expenses.push(expense);
          }
          $scope.processExpenseTotalMonthly(expense, "holidays");
        }
      };

      $scope.setTakeaway = function () {
        var expense = {};
        if ($scope.spending.hasOwnProperty("takeaway")) {
          var found = false;
          for (var $i = 0; $i < $scope.expenses.length; $i++) {
            if ($scope.expenses[$i].desc === "Dining out / Takeaway food") {
              found = true;
              expense = $scope.expenses[$i];
              $scope.expenses[$i].basic = $scope.spending.takeaway;
            }
          }
          if (!found) {
            expense = {
              tier4: "Living Lifestyle",
              desc: "Dining out / Takeaway food",
              amount: $scope.spending.takeaway,
            };
            $scope.expenses.push(expense);
          }
          $scope.processExpenseTotalMonthly(expense, "takeaway");
        }
      };
      $scope.setTakeawayFreq = function () {
        var expense = {};
        if ($scope.spending.hasOwnProperty("takeawayFreq")) {
          var found = false;
          for (var $i = 0; $i < $scope.expenses.length; $i++) {
            if ($scope.expenses[$i].desc === "Dining out / Takeaway food") {
              found = true;
              expense = $scope.expenses[$i];
              $scope.expenses[$i].frequency = $scope.spending.takeawayFreq;
            }
          }
          if (!found) {
            expense = {
              tier4: "Living Lifestyle",
              desc: "Dining out / Takeaway food",
              frequency: $scope.spending.takeawayFreq,
            };
            $scope.expenses.push(expense);
          }
          $scope.processExpenseTotalMonthly(expense, "takeaway");
        }
      };

      $scope.setEntertainment = function () {
        var expense = {};
        if ($scope.spending.hasOwnProperty("entertainment")) {
          var found = false;
          for (var $i = 0; $i < $scope.expenses.length; $i++) {
            if ($scope.expenses[$i].desc === "Entertainment") {
              found = true;
              expense = $scope.expenses[$i];
              $scope.expenses[$i].basic = $scope.spending.entertainment;
            }
          }
          if (!found) {
            expense = {
              tier4: "Living Lifestyle",
              desc: "Entertainment",
              amount: $scope.spending.entertainment,
            };
            $scope.expenses.push(expense);
          }
          $scope.processExpenseTotalMonthly(expense, "entertainment");
        }
      };
      $scope.setEntertainmentFreq = function () {
        var expense = {};
        if ($scope.spending.hasOwnProperty("entertainmentFreq")) {
          var found = false;
          for (var $i = 0; $i < $scope.expenses.length; $i++) {
            if ($scope.expenses[$i].desc === "Entertainment") {
              found = true;
              expense = $scope.expenses[$i];
              $scope.expenses[$i].frequency = $scope.spending.entertainmentFreq;
            }
          }
          if (!found) {
            expense = {
              tier4: "Living Lifestyle",
              desc: "Entertainment",
              frequency: $scope.spending.entertainmentFreq,
            };
            $scope.expenses.push(expense);
          }
          $scope.processExpenseTotalMonthly(expense, "entertainment");
        }
      };

      $scope.setRecreation = function () {
        var expense = {};
        if ($scope.spending.hasOwnProperty("recreation")) {
          var found = false;
          for (var $i = 0; $i < $scope.expenses.length; $i++) {
            if ($scope.expenses[$i].desc === "Sport, recreation and hobbies") {
              found = true;
              expense = $scope.expenses[$i];
              $scope.expenses[$i].basic = $scope.spending.recreation;
            }
          }
          if (!found) {
            expense = {
              tier4: "Living Lifestyle",
              desc: "Sport, recreation and hobbies",
              amount: $scope.spending.recreation,
            };
            $scope.expenses.push(expense);
          }
          $scope.processExpenseTotalMonthly(expense, "recreation");
        }
      };
      $scope.setRecreationFreq = function () {
        var expense = {};
        if ($scope.spending.hasOwnProperty("recreationFreq")) {
          var found = false;
          for (var $i = 0; $i < $scope.expenses.length; $i++) {
            if ($scope.expenses[$i].desc === "Sport, recreation and hobbies") {
              found = true;
              expense = $scope.expenses[$i];
              $scope.expenses[$i].frequency = $scope.spending.recreationFreq;
            }
          }
          if (!found) {
            expense = {
              tier4: "Living Lifestyle",
              desc: "Sport, recreation and hobbies",
              frequency: $scope.spending.recreationFreq,
            };
            $scope.expenses.push(expense);
          }
          $scope.processExpenseTotalMonthly(expense, "recreation");
        }
      };

      $scope.setReading = function () {
        var expense = {};
        if ($scope.spending.hasOwnProperty("reading")) {
          var found = false;
          for (var $i = 0; $i < $scope.expenses.length; $i++) {
            if (
              $scope.expenses[$i].desc === "Books, Magazines and Newspapers"
            ) {
              found = true;
              expense = $scope.expenses[$i];
              $scope.expenses[$i].basic = $scope.spending.reading;
            }
          }
          if (!found) {
            expense = {
              tier4: "Living Lifestyle",
              desc: "Books, Magazines and Newspapers",
              amount: $scope.spending.reading,
            };
            $scope.expenses.push(expense);
          }
          $scope.processExpenseTotalMonthly(expense, "reading");
        }
      };
      $scope.setReadingFreq = function () {
        var expense = {};
        if ($scope.spending.hasOwnProperty("readingFreq")) {
          var found = false;
          for (var $i = 0; $i < $scope.expenses.length; $i++) {
            if (
              $scope.expenses[$i].desc === "Books, Magazines and Newspapers"
            ) {
              found = true;
              expense = $scope.expenses[$i];
              $scope.expenses[$i].frequency = $scope.spending.readingFreq;
            }
          }
          if (!found) {
            expense = {
              tier4: "Living Lifestyle",
              desc: "Books, Magazines and Newspapers",
              frequency: $scope.spending.readingFreq,
            };
            $scope.expenses.push(expense);
          }
          $scope.processExpenseTotalMonthly(expense, "reading");
        }
      };

      $scope.setVideo = function () {
        var expense = {};
        if ($scope.spending.hasOwnProperty("video")) {
          var found = false;
          for (var $i = 0; $i < $scope.expenses.length; $i++) {
            if ($scope.expenses[$i].desc === "Video / DVD purchase and hire") {
              found = true;
              expense = $scope.expenses[$i];
              $scope.expenses[$i].basic = $scope.spending.video;
            }
          }
          if (!found) {
            expense = {
              tier4: "Living Lifestyle",
              desc: "Video / DVD purchase and hire",
              amount: $scope.spending.video,
            };
            $scope.expenses.push(expense);
          }
          $scope.processExpenseTotalMonthly(expense, "video");
        }
      };
      $scope.setVideoFreq = function () {
        var expense = {};
        if ($scope.spending.hasOwnProperty("videoFreq")) {
          var found = false;
          for (var $i = 0; $i < $scope.expenses.length; $i++) {
            if ($scope.expenses[$i].desc === "Video / DVD purchase and hire") {
              found = true;
              expense = $scope.expenses[$i];
              $scope.expenses[$i].frequency = $scope.spending.videoFreq;
            }
          }
          if (!found) {
            expense = {
              tier4: "Living Lifestyle",
              desc: "Video / DVD purchase and hire",
              frequency: $scope.spending.videoFreq,
            };
            $scope.expenses.push(expense);
          }
          $scope.processExpenseTotalMonthly(expense, "video");
        }
      };

      $scope.setSubstances = function () {
        var expense = {};
        if ($scope.spending.hasOwnProperty("substances")) {
          var found = false;
          for (var $i = 0; $i < $scope.expenses.length; $i++) {
            if ($scope.expenses[$i].desc === "Alcohol and Tobacco") {
              found = true;
              expense = $scope.expenses[$i];
              $scope.expenses[$i].basic = $scope.spending.substances;
            }
          }
          if (!found) {
            expense = {
              tier4: "Living Lifestyle",
              desc: "Alcohol and Tobacco",
              amount: $scope.spending.substances,
            };
            $scope.expenses.push(expense);
          }
          $scope.processExpenseTotalMonthly(expense, "substances");
        }
      };
      $scope.setSubstancesFreq = function () {
        var expense = {};
        if ($scope.spending.hasOwnProperty("substancesFreq")) {
          var found = false;
          for (var $i = 0; $i < $scope.expenses.length; $i++) {
            if ($scope.expenses[$i].desc === "Alcohol and Tobacco") {
              found = true;
              expense = $scope.expenses[$i];
              $scope.expenses[$i].frequency = $scope.spending.substancesFreq;
            }
          }
          if (!found) {
            expense = {
              tier4: "Living Lifestyle",
              desc: "Alcohol and Tobacco",
              frequency: $scope.spending.substancesFreq,
            };
            $scope.expenses.push(expense);
          }
          $scope.processExpenseTotalMonthly(expense, "substances");
        }
      };

      $scope.setGambling = function () {
        var expense = {};
        if ($scope.spending.hasOwnProperty("gambling")) {
          var found = false;
          for (var $i = 0; $i < $scope.expenses.length; $i++) {
            if ($scope.expenses[$i].desc === "Gambling and Lotto") {
              found = true;
              expense = $scope.expenses[$i];
              $scope.expenses[$i].basic = $scope.spending.gambling;
            }
          }
          if (!found) {
            expense = {
              tier4: "Living Lifestyle",
              desc: "Gambling and Lotto",
              amount: $scope.spending.gambling,
            };
            $scope.expenses.push(expense);
          }
          $scope.processExpenseTotalMonthly(expense, "gambling");
        }
      };
      $scope.setGamblingFreq = function () {
        var expense = {};
        if ($scope.spending.hasOwnProperty("gamblingFreq")) {
          var found = false;
          for (var $i = 0; $i < $scope.expenses.length; $i++) {
            if ($scope.expenses[$i].desc === "Gambling and Lotto") {
              found = true;
              expense = $scope.expenses[$i];
              $scope.expenses[$i].frequency = $scope.spending.gamblingFreq;
            }
          }
          if (!found) {
            expense = {
              tier4: "Living Lifestyle",
              desc: "Gambling and Lotto",
              frequency:
                $scope.spending.gamblingFreq === null
                  ? "Monthly"
                  : $scope.spending.gamblingFreq,
            };
            $scope.expenses.push(expense);
          }
          $scope.processExpenseTotalMonthly(expense, "gambling");
        }
      };

      $scope.goToLocation = function (location) {
        var proceed = $scope.empowerData.goToLocation(location, $scope);

        if (proceed) {
          $state.go(location, {
            clientId: $scope.clientId,
          });
        }
      };

      $scope.assignValues = function (data, properties, isDiscard) {
        if (properties.properties) {
          $scope.properties = properties.properties;
        }

        let basicTotal = 0;

        for (var i = 0; i < data.expenses.expenses.length; i++) {
          var expenseItem = data.expenses.expenses[i];
          if (
            expenseItem.tier4 !== "Bills" &&
            expenseItem.desc !== "Rent" &&
            expenseItem.tier4 === "Spending"
          ) {
            if (expenseItem.frequency) {
              if (typeof expenseItem.basic === "number") {
                basicTotal += $scope.getMonthly(
                  expenseItem.basic,
                  expenseItem.frequency,
                  "number"
                );
              }
            }
          }
        }

        if (data.expenses) {
          if (data.expenses.expenses) {
            $scope.expenses = data.expenses.expenses;
            if (data.expenses.annualSpendings) {
              $scope.annualSpendings = data.expenses.annualSpendings;
              if (basicTotal === 0 && $scope.annualSpendings.basic > 0) {
                $scope.initialSpendingBasic = $scope.annualSpendings.basic;
              }
              $scope.annualSpendingsAmount = $filter("number")(
                $scope.annualSpendings.amount,
                2
              );
              $scope.annualSpendingsDiscAmount = $filter("number")(
                $scope.annualSpendings.discretionary,
                2
              );
              $scope.annualSpendingsBasicAmount = $filter("number")(
                $scope.annualSpendings.basic,
                2
              );
            }
            if (data.expenses.annualBills) {
              $scope.annualBills = data.expenses.annualBills;
            }
          }
        }

        $scope.isPersonalInfoCompleted = data.isPersonalInfoCompleted;
        $scope.isIncomeCompleted = data.isIncomeCompleted;
        $scope.isPropertyCompleted = data.isPropertyCompleted;
        $scope.isNonPropertyCompleted = data.isNonPropertyCompleted;
        $scope.isBorrowingCompleted = data.isBorrowingCompleted;
        $scope.isBillPaymentsCompleted = data.isBillPaymentsCompleted;
        $scope.isSpendingCompleted = data.isSpendingCompleted;
        $scope.isFPlanHouseholdCompleted = data.isFPlanHouseholdCompleted;
        $scope.isFPlanIncomeCompleted = data.isFPlanIncomeCompleted;
        // check if user is admin
        DataM.getMe()
          .then(function (userData) {
            if (userData.role !== "user" && userData.role !== "guest") {
              $scope.isBasicExpensesHidden = true;
              if (
                userData.role === "super_admin" ||
                userData.role === "mb_admin"
              ) {
                $scope.isMBAdmin = true;
              }
            } else {
              if (data.expenses && data.expenses.basicExpenses) {
                $scope.isBasicExpensesHidden = true; //data.expenses.basicExpenses.isHidden;
              } else {
                $scope.isBasicExpensesHidden = true;
              }
            }
          })
          .catch(function (err) {
            console.log("Retrieve current user error");
          });

        if ($scope.expenses) {
          $scope.spendings = [];

          for (var $i = 0; $i < $scope.expenses.length; $i++) {
            if (
              $scope.expenses[$i].desc ===
              "Groceries and Non-alcoholic Beverages"
            ) {
              $scope.spendings.push($scope.expenses[$i]);
              $scope.spending.groceries = $scope.expenses[$i].basic;
              $scope.spending.groceriesFreq =
                typeof $scope.expenses[$i].frequency !== "undefined"
                  ? $scope.expenses[$i].frequency !== null
                    ? $scope.expenses[$i].frequency
                    : "Monthly"
                  : "Monthly";
              $scope.spending.groceriesDiscretionary =
                $scope.expenses[$i].discretionary;
              $scope.expenses[$i].frequency = $scope.spending.groceriesFreq;
              $scope.spending.groceriesCategory = $scope.expenses[$i].category;
              if (_.isNil($scope.expenses[$i].basic)) {
                $scope.expenses[$i].basic = null;
              }
              if (_.isNil($scope.expenses[$i].discretionary)) {
                $scope.expenses[$i].discretionary = null;
              }
              $scope.processExpenseTotalMonthly(
                $scope.expenses[$i],
                "groceries"
              );
            } else if (
              $scope.expenses[$i].desc ===
              "Clothing, Footwear, Dry cleaning, Repairs"
            ) {
              $scope.spendings.push($scope.expenses[$i]);
              $scope.spending.clothing = $scope.expenses[$i].basic;
              $scope.spending.clothingDiscretionary =
                $scope.expenses[$i].discretionary;
              $scope.spending.clothingFreq =
                typeof $scope.expenses[$i].frequency !== "undefined"
                  ? $scope.expenses[$i].frequency !== null
                    ? $scope.expenses[$i].frequency
                    : "Monthly"
                  : "Monthly";
              $scope.expenses[$i].frequency = $scope.spending.clothingFreq;
              $scope.spending.clothingCategory = $scope.expenses[$i].category;
              if (_.isNil($scope.expenses[$i].basic)) {
                $scope.expenses[$i].basic = null;
              }
              if (_.isNil($scope.expenses[$i].discretionary)) {
                $scope.expenses[$i].discretionary = null;
              }
              $scope.processExpenseTotalMonthly(
                $scope.expenses[$i],
                "clothing"
              );
            } else if (
              $scope.expenses[$i].desc === "Education - Out-of-pocket expenses"
            ) {
              $scope.spendings.push($scope.expenses[$i]);
              $scope.spending.education = $scope.expenses[$i].basic;
              $scope.spending.educationDiscretionary =
                $scope.expenses[$i].discretionary;
              $scope.spending.educationFreq =
                typeof $scope.expenses[$i].frequency !== "undefined"
                  ? $scope.expenses[$i].frequency !== null
                    ? $scope.expenses[$i].frequency
                    : "Monthly"
                  : "Monthly";
              $scope.expenses[$i].frequency = $scope.spending.educationFreq;
              $scope.spending.educationCategory = $scope.expenses[$i].category;
              if (_.isNil($scope.expenses[$i].basic)) {
                $scope.expenses[$i].basic = null;
              }
              if (_.isNil($scope.expenses[$i].discretionary)) {
                $scope.expenses[$i].discretionary = null;
              }
              $scope.processExpenseTotalMonthly(
                $scope.expenses[$i],
                "education"
              );
            } else if (
              $scope.expenses[$i].desc === "Medical and Health Care expenses"
            ) {
              $scope.spendings.push($scope.expenses[$i]);
              $scope.spending.healthCare = $scope.expenses[$i].basic;
              $scope.spending.healthCareDiscretionary =
                $scope.expenses[$i].discretionary;
              $scope.spending.healthCareFreq =
                typeof $scope.expenses[$i].frequency !== "undefined"
                  ? $scope.expenses[$i].frequency !== null
                    ? $scope.expenses[$i].frequency
                    : "Monthly"
                  : "Monthly";
              $scope.expenses[$i].frequency = $scope.spending.healthCareFreq;
              $scope.spending.healthCareCategory = $scope.expenses[$i].category;
              if (_.isNil($scope.expenses[$i].basic)) {
                $scope.expenses[$i].basic = null;
              }
              if (_.isNil($scope.expenses[$i].discretionary)) {
                $scope.expenses[$i].discretionary = null;
              }
              $scope.processExpenseTotalMonthly(
                $scope.expenses[$i],
                "healthCare"
              );
            } else if ($scope.expenses[$i].desc === "Personal Care") {
              $scope.spendings.push($scope.expenses[$i]);
              $scope.spending.personalCare = $scope.expenses[$i].basic;
              $scope.spending.personalCareDiscretionary =
                $scope.expenses[$i].discretionary;
              $scope.spending.personalCareFreq =
                typeof $scope.expenses[$i].frequency !== "undefined"
                  ? $scope.expenses[$i].frequency !== null
                    ? $scope.expenses[$i].frequency
                    : "Monthly"
                  : "Monthly";
              $scope.expenses[$i].frequency = $scope.spending.personalCareFreq;
              $scope.spending.personalCareCategory =
                $scope.expenses[$i].category;
              if (_.isNil($scope.expenses[$i].basic)) {
                $scope.expenses[$i].basic = null;
              }
              if (_.isNil($scope.expenses[$i].discretionary)) {
                $scope.expenses[$i].discretionary = null;
              }
              $scope.processExpenseTotalMonthly(
                $scope.expenses[$i],
                "personalCare"
              );
            } else if ($scope.expenses[$i].desc === "Pets and Animals") {
              $scope.spendings.push($scope.expenses[$i]);
              $scope.spending.pets = $scope.expenses[$i].basic;
              $scope.spending.petsDiscretionary =
                $scope.expenses[$i].discretionary;
              $scope.spending.petsFreq =
                typeof $scope.expenses[$i].frequency !== "undefined"
                  ? $scope.expenses[$i].frequency !== null
                    ? $scope.expenses[$i].frequency
                    : "Monthly"
                  : "Monthly";
              $scope.expenses[$i].frequency = $scope.spending.petsFreq;
              $scope.spending.petsCategory = $scope.expenses[$i].category;
              if (_.isNil($scope.expenses[$i].basic)) {
                $scope.expenses[$i].basic = null;
              }
              if (_.isNil($scope.expenses[$i].discretionary)) {
                $scope.expenses[$i].discretionary = null;
              }
              $scope.processExpenseTotalMonthly($scope.expenses[$i], "pets");
            } else if (
              $scope.expenses[$i].desc === "Household Appliances and Tools"
            ) {
              $scope.spendings.push($scope.expenses[$i]);
              $scope.spending.appliances = $scope.expenses[$i].basic;
              $scope.spending.appliancesDiscretionary =
                $scope.expenses[$i].discretionary;
              $scope.spending.appliancesFreq =
                typeof $scope.expenses[$i].frequency !== "undefined"
                  ? $scope.expenses[$i].frequency !== null
                    ? $scope.expenses[$i].frequency
                    : "Monthly"
                  : "Monthly";
              $scope.expenses[$i].frequency = $scope.spending.appliancesFreq;
              $scope.spending.appliancesCategory = $scope.expenses[$i].category;
              if (_.isNil($scope.expenses[$i].basic)) {
                $scope.expenses[$i].basic = null;
              }
              if (_.isNil($scope.expenses[$i].discretionary)) {
                $scope.expenses[$i].discretionary = null;
              }
              $scope.processExpenseTotalMonthly(
                $scope.expenses[$i],
                "appliances"
              );
            } else if ($scope.expenses[$i].desc === "Furniture") {
              $scope.spendings.push($scope.expenses[$i]);
              $scope.spending.furniture = $scope.expenses[$i].basic;
              $scope.spending.furnitureDiscretionary =
                $scope.expenses[$i].discretionary;
              $scope.spending.furnitureFreq =
                typeof $scope.expenses[$i].frequency !== "undefined"
                  ? $scope.expenses[$i].frequency !== null
                    ? $scope.expenses[$i].frequency
                    : "Monthly"
                  : "Monthly";
              $scope.expenses[$i].frequency = $scope.spending.furnitureFreq;
              $scope.spending.furnitureCategory = $scope.expenses[$i].category;
              if (_.isNil($scope.expenses[$i].basic)) {
                $scope.expenses[$i].basic = null;
              }
              if (_.isNil($scope.expenses[$i].discretionary)) {
                $scope.expenses[$i].discretionary = null;
              }
              $scope.processExpenseTotalMonthly(
                $scope.expenses[$i],
                "furniture"
              );
            } else if ($scope.expenses[$i].desc === "Household Furnishings") {
              $scope.spendings.push($scope.expenses[$i]);
              $scope.spending.furnishings = $scope.expenses[$i].basic;
              $scope.spending.furnishingsDiscretionary =
                $scope.expenses[$i].discretionary;
              $scope.spending.furnishingsFreq =
                typeof $scope.expenses[$i].frequency !== "undefined"
                  ? $scope.expenses[$i].frequency !== null
                    ? $scope.expenses[$i].frequency
                    : "Monthly"
                  : "Monthly";
              $scope.expenses[$i].frequency = $scope.spending.furnishingsFreq;
              $scope.spending.furnishingsCategory =
                $scope.expenses[$i].category;
              if (_.isNil($scope.expenses[$i].basic)) {
                $scope.expenses[$i].basic = null;
              }
              if (_.isNil($scope.expenses[$i].discretionary)) {
                $scope.expenses[$i].discretionary = null;
              }
              $scope.processExpenseTotalMonthly(
                $scope.expenses[$i],
                "furnishings"
              );
            } else if (
              $scope.expenses[$i].desc === "House and Garden maintenance"
            ) {
              $scope.spendings.push($scope.expenses[$i]);
              $scope.spending.homeMaintenance = $scope.expenses[$i].basic;
              $scope.spending.homeMaintenanceDiscretionary =
                $scope.expenses[$i].discretionary;
              $scope.spending.homeMaintenanceFreq =
                typeof $scope.expenses[$i].frequency !== "undefined"
                  ? $scope.expenses[$i].frequency !== null
                    ? $scope.expenses[$i].frequency
                    : "Monthly"
                  : "Monthly";
              $scope.expenses[$i].frequency =
                $scope.spending.homeMaintenanceFreq;
              $scope.spending.homeMaintenanceCategory =
                $scope.expenses[$i].category;
              if (_.isNil($scope.expenses[$i].basic)) {
                $scope.expenses[$i].basic = null;
              }
              if (_.isNil($scope.expenses[$i].discretionary)) {
                $scope.expenses[$i].discretionary = null;
              }
              $scope.processExpenseTotalMonthly(
                $scope.expenses[$i],
                "homeMaintenance"
              );
            } else if ($scope.expenses[$i].desc === "Fuel") {
              $scope.spendings.push($scope.expenses[$i]);
              $scope.spending.fuel = $scope.expenses[$i].basic;
              $scope.spending.fuelDiscretionary =
                $scope.expenses[$i].discretionary;
              $scope.spending.fuelFreq =
                typeof $scope.expenses[$i].frequency !== "undefined"
                  ? $scope.expenses[$i].frequency !== null
                    ? $scope.expenses[$i].frequency
                    : "Monthly"
                  : "Monthly";
              $scope.expenses[$i].frequency = $scope.spending.fuelFreq;
              $scope.spending.fuelCategory = $scope.expenses[$i].category;
              if (_.isNil($scope.expenses[$i].basic)) {
                $scope.expenses[$i].basic = null;
              }
              if (_.isNil($scope.expenses[$i].discretionary)) {
                $scope.expenses[$i].discretionary = null;
              }
              $scope.processExpenseTotalMonthly($scope.expenses[$i], "fuel");
            } else if ($scope.expenses[$i].desc === "Car maintenance") {
              $scope.spendings.push($scope.expenses[$i]);
              $scope.spending.carMaintenance = $scope.expenses[$i].basic;
              $scope.spending.carMaintenanceDiscretionary =
                $scope.expenses[$i].discretionary;
              $scope.spending.carMaintenanceFreq =
                typeof $scope.expenses[$i].frequency !== "undefined"
                  ? $scope.expenses[$i].frequency !== null
                    ? $scope.expenses[$i].frequency
                    : "Monthly"
                  : "Monthly";
              $scope.expenses[$i].frequency =
                $scope.spending.carMaintenanceFreq;
              $scope.spending.carMaintenanceCategory =
                $scope.expenses[$i].category;
              if (_.isNil($scope.expenses[$i].basic)) {
                $scope.expenses[$i].basic = null;
              }
              if (_.isNil($scope.expenses[$i].discretionary)) {
                $scope.expenses[$i].discretionary = null;
              }
              $scope.processExpenseTotalMonthly(
                $scope.expenses[$i],
                "carMaintenance"
              );
            } else if ($scope.expenses[$i].desc === "Parking and Tolls") {
              $scope.spendings.push($scope.expenses[$i]);
              $scope.spending.parking = $scope.expenses[$i].basic;
              $scope.spending.parkingDiscretionary =
                $scope.expenses[$i].discretionary;
              $scope.spending.parkingFreq =
                typeof $scope.expenses[$i].frequency !== "undefined"
                  ? $scope.expenses[$i].frequency !== null
                    ? $scope.expenses[$i].frequency
                    : "Monthly"
                  : "Monthly";
              $scope.expenses[$i].frequency = $scope.spending.parkingFreq;
              $scope.spending.parkingCategory = $scope.expenses[$i].category;
              if (_.isNil($scope.expenses[$i].basic)) {
                $scope.expenses[$i].basic = null;
              }
              if (_.isNil($scope.expenses[$i].discretionary)) {
                $scope.expenses[$i].discretionary = null;
              }
              $scope.processExpenseTotalMonthly($scope.expenses[$i], "parking");
            } else if ($scope.expenses[$i].desc === "Fares") {
              $scope.spendings.push($scope.expenses[$i]);
              $scope.spending.fares = $scope.expenses[$i].basic;
              $scope.spending.faresDiscretionary =
                $scope.expenses[$i].discretionary;
              $scope.spending.faresFreq =
                typeof $scope.expenses[$i].frequency !== "undefined"
                  ? $scope.expenses[$i].frequency !== null
                    ? $scope.expenses[$i].frequency
                    : "Monthly"
                  : "Monthly";
              $scope.expenses[$i].frequency = $scope.spending.faresFreq;
              $scope.spending.faresCategory = $scope.expenses[$i].category;
              if (_.isNil($scope.expenses[$i].basic)) {
                $scope.expenses[$i].basic = null;
              }
              if (_.isNil($scope.expenses[$i].discretionary)) {
                $scope.expenses[$i].discretionary = null;
              }
              $scope.processExpenseTotalMonthly($scope.expenses[$i], "fares");
            } else if ($scope.expenses[$i].desc === "Presents and Gifts") {
              $scope.spendings.push($scope.expenses[$i]);
              $scope.spending.gifts = $scope.expenses[$i].basic;
              $scope.spending.giftsDiscretionary =
                $scope.expenses[$i].discretionary;
              $scope.spending.giftsFreq =
                typeof $scope.expenses[$i].frequency !== "undefined"
                  ? $scope.expenses[$i].frequency !== null
                    ? $scope.expenses[$i].frequency
                    : "Monthly"
                  : "Monthly";
              $scope.expenses[$i].frequency = $scope.spending.giftsFreq;
              $scope.spending.giftsCategory = $scope.expenses[$i].category;
              if (_.isNil($scope.expenses[$i].basic)) {
                $scope.expenses[$i].basic = null;
              }
              if (_.isNil($scope.expenses[$i].discretionary)) {
                $scope.expenses[$i].discretionary = null;
              }
              $scope.processExpenseTotalMonthly($scope.expenses[$i], "gifts");
            } else if ($scope.expenses[$i].desc === "Donations") {
              $scope.spendings.push($scope.expenses[$i]);
              $scope.spending.donations = $scope.expenses[$i].basic;
              $scope.spending.donationsDiscretionary =
                $scope.expenses[$i].discretionary;
              $scope.spending.donationsFreq =
                typeof $scope.expenses[$i].frequency !== "undefined"
                  ? $scope.expenses[$i].frequency !== null
                    ? $scope.expenses[$i].frequency
                    : "Monthly"
                  : "Monthly";
              $scope.expenses[$i].frequency = $scope.spending.donationsFreq;
              $scope.spending.donationsCategory = $scope.expenses[$i].category;
              if (_.isNil($scope.expenses[$i].basic)) {
                $scope.expenses[$i].basic = null;
              }
              if (_.isNil($scope.expenses[$i].discretionary)) {
                $scope.expenses[$i].discretionary = null;
              }
              $scope.processExpenseTotalMonthly(
                $scope.expenses[$i],
                "donations"
              );
            } else if ($scope.expenses[$i].desc === "Holidays") {
              $scope.spendings.push($scope.expenses[$i]);
              $scope.spending.holidays = $scope.expenses[$i].basic;
              $scope.spending.holidaysDiscretionary =
                $scope.expenses[$i].discretionary;
              $scope.spending.holidaysFreq =
                typeof $scope.expenses[$i].frequency !== "undefined"
                  ? $scope.expenses[$i].frequency !== null
                    ? $scope.expenses[$i].frequency
                    : "Monthly"
                  : "Monthly";
              $scope.expenses[$i].frequency = $scope.spending.holidaysFreq;
              $scope.spending.holidaysCategory = $scope.expenses[$i].category;
              if (_.isNil($scope.expenses[$i].basic)) {
                $scope.expenses[$i].basic = null;
              }
              if (_.isNil($scope.expenses[$i].discretionary)) {
                $scope.expenses[$i].discretionary = null;
              }
              $scope.processExpenseTotalMonthly(
                $scope.expenses[$i],
                "holidays"
              );
            } else if (
              $scope.expenses[$i].desc === "Dining out / Takeaway food"
            ) {
              $scope.spendings.push($scope.expenses[$i]);
              $scope.spending.takeaway = $scope.expenses[$i].basic;
              $scope.spending.takeawayDiscretionary =
                $scope.expenses[$i].discretionary;
              $scope.spending.takeawayFreq =
                typeof $scope.expenses[$i].frequency !== "undefined"
                  ? $scope.expenses[$i].frequency !== null
                    ? $scope.expenses[$i].frequency
                    : "Monthly"
                  : "Monthly";
              $scope.expenses[$i].frequency = $scope.spending.takeawayFreq;
              $scope.spending.takeawayCategory = $scope.expenses[$i].category;
              if (_.isNil($scope.expenses[$i].basic)) {
                $scope.expenses[$i].basic = null;
              }
              if (_.isNil($scope.expenses[$i].discretionary)) {
                $scope.expenses[$i].discretionary = null;
              }
              $scope.processExpenseTotalMonthly(
                $scope.expenses[$i],
                "takeaway"
              );
            } else if ($scope.expenses[$i].desc === "Entertainment") {
              $scope.spendings.push($scope.expenses[$i]);
              $scope.spending.entertainment = $scope.expenses[$i].basic;
              $scope.spending.entertainmentDiscretionary =
                $scope.expenses[$i].discretionary;
              $scope.spending.entertainmentFreq =
                typeof $scope.expenses[$i].frequency !== "undefined"
                  ? $scope.expenses[$i].frequency !== null
                    ? $scope.expenses[$i].frequency
                    : "Monthly"
                  : "Monthly";
              $scope.expenses[$i].frequency = $scope.spending.entertainmentFreq;
              $scope.spending.entertainmentCategory =
                $scope.expenses[$i].category;
              if (_.isNil($scope.expenses[$i].basic)) {
                $scope.expenses[$i].basic = null;
              }
              if (_.isNil($scope.expenses[$i].discretionary)) {
                $scope.expenses[$i].discretionary = null;
              }
              $scope.processExpenseTotalMonthly(
                $scope.expenses[$i],
                "entertainment"
              );
            } else if (
              $scope.expenses[$i].desc === "Sport, recreation and hobbies"
            ) {
              $scope.spendings.push($scope.expenses[$i]);
              $scope.spending.recreation = $scope.expenses[$i].basic;
              $scope.spending.recreationDiscretionary =
                $scope.expenses[$i].discretionary;
              $scope.spending.recreationFreq =
                typeof $scope.expenses[$i].frequency !== "undefined"
                  ? $scope.expenses[$i].frequency !== null
                    ? $scope.expenses[$i].frequency
                    : "Monthly"
                  : "Monthly";
              $scope.expenses[$i].frequency = $scope.spending.recreationFreq;
              $scope.spending.recreationCategory = $scope.expenses[$i].category;
              if (_.isNil($scope.expenses[$i].basic)) {
                $scope.expenses[$i].basic = null;
              }
              if (_.isNil($scope.expenses[$i].discretionary)) {
                $scope.expenses[$i].discretionary = null;
              }
              $scope.processExpenseTotalMonthly(
                $scope.expenses[$i],
                "recreation"
              );
            } else if (
              $scope.expenses[$i].desc === "Books, Magazines and Newspapers"
            ) {
              $scope.spendings.push($scope.expenses[$i]);
              $scope.spending.reading = $scope.expenses[$i].basic;
              $scope.spending.readingDiscretionary =
                $scope.expenses[$i].discretionary;
              $scope.spending.readingFreq =
                typeof $scope.expenses[$i].frequency !== "undefined"
                  ? $scope.expenses[$i].frequency !== null
                    ? $scope.expenses[$i].frequency
                    : "Monthly"
                  : "Monthly";
              $scope.expenses[$i].frequency = $scope.spending.readingFreq;
              $scope.spending.readingCategory = $scope.expenses[$i].category;
              if (_.isNil($scope.expenses[$i].basic)) {
                $scope.expenses[$i].basic = null;
              }
              if (_.isNil($scope.expenses[$i].discretionary)) {
                $scope.expenses[$i].discretionary = null;
              }
              $scope.processExpenseTotalMonthly($scope.expenses[$i], "reading");
            } else if (
              $scope.expenses[$i].desc === "Video / DVD purchase and hire"
            ) {
              $scope.spendings.push($scope.expenses[$i]);
              $scope.spending.video = $scope.expenses[$i].basic;
              $scope.spending.videoDiscretionary =
                $scope.expenses[$i].discretionary;
              $scope.spending.videoFreq =
                typeof $scope.expenses[$i].frequency !== "undefined"
                  ? $scope.expenses[$i].frequency !== null
                    ? $scope.expenses[$i].frequency
                    : "Monthly"
                  : "Monthly";
              $scope.expenses[$i].frequency = $scope.spending.videoFreq;
              $scope.spending.videoCategory = $scope.expenses[$i].category;
              if (_.isNil($scope.expenses[$i].basic)) {
                $scope.expenses[$i].basic = null;
              }
              if (_.isNil($scope.expenses[$i].discretionary)) {
                $scope.expenses[$i].discretionary = null;
              }
              $scope.processExpenseTotalMonthly($scope.expenses[$i], "video");
            } else if ($scope.expenses[$i].desc === "Alcohol and Tobacco") {
              $scope.spendings.push($scope.expenses[$i]);
              $scope.spending.substances = $scope.expenses[$i].basic;
              $scope.spending.substancesDiscretionary =
                $scope.expenses[$i].discretionary;
              $scope.spending.substancesFreq =
                typeof $scope.expenses[$i].frequency !== "undefined"
                  ? $scope.expenses[$i].frequency !== null
                    ? $scope.expenses[$i].frequency
                    : "Monthly"
                  : "Monthly";
              $scope.expenses[$i].frequency = $scope.spending.substancesFreq;
              $scope.spending.substancesCategory = $scope.expenses[$i].category;
              if (_.isNil($scope.expenses[$i].basic)) {
                $scope.expenses[$i].basic = null;
              }
              if (_.isNil($scope.expenses[$i].discretionary)) {
                $scope.expenses[$i].discretionary = null;
              }
              $scope.processExpenseTotalMonthly(
                $scope.expenses[$i],
                "substances"
              );
            } else if ($scope.expenses[$i].desc === "Gambling and Lotto") {
              $scope.spendings.push($scope.expenses[$i]);
              $scope.spending.gambling = $scope.expenses[$i].basic;
              $scope.spending.gamblingDiscretionary =
                $scope.expenses[$i].discretionary;
              $scope.spending.gamblingFreq =
                typeof $scope.expenses[$i].frequency !== "undefined"
                  ? $scope.expenses[$i].frequency !== null
                    ? $scope.expenses[$i].frequency
                    : "Monthly"
                  : "Monthly";
              $scope.expenses[$i].frequency = $scope.spending.gamblingFreq;
              $scope.spending.gamblingCategory = $scope.expenses[$i].category;
              if (_.isNil($scope.expenses[$i].basic)) {
                $scope.expenses[$i].basic = null;
              }
              if (_.isNil($scope.expenses[$i].discretionary)) {
                $scope.expenses[$i].discretionary = null;
              }
              $scope.processExpenseTotalMonthly(
                $scope.expenses[$i],
                "gambling"
              );
            } else if (
              $scope.expenses[$i].tier4 == "Spending" &&
              $scope.expenses[$i].customExpense == true
            ) {
              $scope.customSpending.push($scope.expenses[$i]);
              $scope.expenses[$i].frequency = !_.isNil(
                $scope.expenses[$i].frequency
              )
                ? $scope.expenses[$i].frequency
                : "Monthly";
              $scope.computeMonthly($scope.expenses[$i]);
              if (_.isNil($scope.expenses[$i].basic)) {
                $scope.expenses[$i].basic = null;
              }
              if (_.isNil($scope.expenses[$i].discretionary)) {
                $scope.expenses[$i].discretionary = null;
              }
            } else {
              if ($scope.expenses[$i].desc === "Rent") {
                $scope.rent = $scope.expenses[$i];
              }
              if (
                $scope.expenses[$i].frequency === "" ||
                $scope.expenses[$i].frequency === null
              ) {
                $scope.expenses[$i].frequency = "Monthly";
              }
              $scope.bills.push($scope.expenses[$i]);
            }
            $scope.computeMonthly($scope.expenses[$i]);
          }
        } else {
          $scope.expenses = [];
        }

        $scope.hasExpenseChanges = checkIfSpendingHasInput();

        /*Now Remove all Custom Expenses from Expenses Scope Array*/
        // for (var $i = $scope.expenses.length - 1; $i >= 0; $i--) {
        //   if ($scope.expenses[$i].tier4 == 'Spending' && $scope.expenses[$i].customExpense == true) {
        //     $scope.expenses.splice($scope.expenses.indexOf($scope.expenses[$i]), 1);
        //   }
        // }

        $scope.isReviewed = data.isReviewed;
        $scope.addlAdvisorInfo = data.addlAdvisorInfo;
      };

      $scope.setInputValues = function (isDiscard) {
        $scope.spending = {};
        $scope.customSpending = [];
        $scope.expenses = [];
        $scope.flexExpenses = [];
        $scope.flexExpensesMonthlyTotal = 0;
        $scope.properties = [];
        $scope.rent = {
          amount: 0,
          discretionary: 0,
          basic: 0,
        };

        $scope.totalBillsAmount = "";
        $scope.totalBillsDiscAmount = "";
        $scope.totalBillsBasicAmount = "";

        $scope.totalSpendingAmount = "";
        $scope.totalSpendingDiscAmount = "";
        $scope.totalSpendingBasicAmount = "";

        $scope.totalAmount = 0;
        $scope.totalDiscAmount = 0;
        $scope.totalBasicAmount = 0;

        $scope.annualBills = {};
        $scope.annualSpendings = {};

        $scope.annualSpendingsAmount = "";
        $scope.annualSpendingsDiscAmount = "";
        $scope.annualSpendingsBasicAmount = "";

        $scope.bills = [];

        if (!isDiscard) {
          DataM.getProperties()
            .then(function (properties) {
              DataM.getExpenses()
                .then(function (data) {
                  toCompare = data;
                  var promise = new Promise(function (resolve, reject) {
                    $scope.assignValues(data, properties, isDiscard);
                    resolve("done");
                  });

                  promise.then(function (data) {
                    if (data === "done") {
                      $scope.showClientSpinner = false;
                    }
                  });
                })
                .catch(function (err) {
                  console.log("Retrieve error", err);
                  $scope.showRetrieveError = true;
                });
            })
            .catch(function (err) {
              console.log("Retrieve error", err);
              $scope.showRetrieveError = true;
            });
        } else {
          var data1 = JSON.parse($scope.initialSpending);
          var data2 = $scope.properties;
          toCompare = data1;
          $scope.assignValues(data1, data2, isDiscard);
        }
      };

      $scope.setInputValues(false);

      $scope.changeBottomButtonState = function () {
        if ($scope.initialSpending !== null && toCompare !== null) {
          var newToCompare = stringifyJSON(toCompare, defaultKeysToRemove);
          if ($scope.initialSpending !== newToCompare) {
            $scope.showBottomButtons = true;
          } else {
            $scope.showBottomButtons = false;
          }
        } else if ($scope.initialSpending === null && toCompare !== null) {
          $scope.initialSpending = stringifyJSON(
            toCompare,
            defaultKeysToRemove
          );
        }
      };

      $scope.$watch(
        "[spending, customSpending, expenses, properties, flexExpenses]",
        function () {
          $scope.changeBottomButtonState();
        },
        true
      );

      $scope.save = function (location) {
        if ($scope.showChangesAlert) {
          $scope.showChangesAlert = false;
        }
        $scope.showClientSpinner = true;
        $scope.loadingMessage = "Saving Client Information...";

        var spending = [];

        const defaultExpenses = $scope.expenses.filter(
          (def) => def.customExpense === false && def.taxDeductible === false
        );
        const customSpending = formUniqueArrayByValue(
          $scope.expenses.filter(
            (cus) => cus.customExpense === true && cus.tier4 === "Spending"
          ),
          "desc"
        );
        const mergeDefCus = [...defaultExpenses, ...customSpending];
        const uniqueDefCus = formUniqueArrayByValue(mergeDefCus, "desc");
        if (mergeDefCus.length > uniqueDefCus.length) {
          $rootScope.setAlertValues(
            true,
            false,
            `You cannot add custom spending that
        has the same description as the default bills and spendings. Please change
        the custom one in order for you to save your changes.`,
            true
          );
          $scope.showClientSpinner = false;
        } else {
          for (var $i = 0; $i < $scope.expenses.length; $i++) {
            spending.push($scope.expenses[$i]);
          }

          var formData = {
            expenses: {
              annualSpendings: $scope.annualSpendings,
              annualBills: $scope.annualBills,
              expenses: spending,
              isReviewed: $scope.isReviewed,
              addlAdvisorInfo: $scope.addlAdvisorInfo,
            },
          };

          //SAVE TO DB
          DataM.saveExpenses(formData)
            .then(function (data) {
              if (data._message === "Client validation failed") {
                var errorMessage = "";
                const formattedMessage = DataHelper.formatMongoError(data);
                $scope.showClientSpinner = false;

                $scope.didNextSave = false;
                alert(formattedMessage);
              } else {
                if ($scope.isSaving && $scope.toStateName !== "") {
                  $state.go($scope.toStateName, {
                    clientId: $scope.clientId,
                  });
                } else {
                  if ($location.$$url.includes("spending")) {
                    $rootScope.setAlertValues(
                      true,
                      true,
                      "Successfully saved changes!",
                      false
                    );
                    $scope.initialSpending = null;
                    $scope.initialSpending = stringifyJSON(
                      toCompare,
                      defaultKeysToRemove
                    );
                    $scope.changeBottomButtonState();
                  } else {
                    $state.go(location, {
                      clientId: $scope.clientId,
                    });
                  }
                }
                $scope.showClientSpinner = false;
                $scope.fetchFlexSummaryData();
              }
            })
            .catch(function (err) {
              $rootScope.setAlertValues(
                true,
                false,
                "There is a problem accessing the server.",
                true
              );
              alert(
                "There is a problem accessing the server. Please make sure your internet connection is working. If it is working and this problem persist, please report this problem to Empower Wealth team."
              );
              $scope.showClientSpinner = false;
            });
        }
      };

      $scope.next = function () {
        // check if form is valid
        $scope.save("fact-find8");
      };
      $scope.back = function () {
        // check if form is valid
        $state.go("fact-find6", {
          clientId: $scope.clientId,
        });
      };
      $scope.quit = function () {
        $state.go("dashboard");
      };

      $scope.savedText = "Save";

      $scope.showAlertValidationModal = function () {
        $scope.alertMessage = $scope.empowerData.saveValidationMessage;
        $scope.showAlertModal = true;
      };
      $scope.closeModal = function () {
        $scope.showAlertModal = false;

        if ($scope.elementToFocus) {
          $timeout($scope.elementToFocus.focus(), 1500);
        }
      };
      $scope.showConfirmSaveModal = function (page) {
        if (!$scope.didNextSave) {
          //$scope.toShowConfirmSaveModal = true;
          $scope.nextPageLocation = page;
        }
      };
      $scope.goNextAndNotSaveModal = function () {
        //$scope.toShowConfirmSaveModal = false;
        $state.go($scope.nextPageLocation, {
          clientId: $scope.clientId,
        });
      };
      $scope.goNextAndSaveModal = function () {
        //$scope.toShowConfirmSaveModal = false;
        $scope.didNextSave = true;
        $scope.save($scope.nextPageLocation);
      };
      $scope.closeConfirmModal = function () {
        //$scope.toShowConfirmSaveModal = false;
        $scope.savedText = "Save";
        $scope.isSaving = false;
      };
      $scope.getFlexExpenses = function () {
        // DataHelper.getMappedFlexExpenses deprecated by DataM.getFlexSummary
        $scope.flexExpenses = DataHelper.getMappedFlexExpenses($scope.expenses);
        var propertyINVCost = {
          flexType: "Utilities & Rates - INV",
          monthlyAmount: 0,
          items: [],
        };
        // Contains property expenses
        // DataHelper.mapFlexPropertyExpenses deprecated by DataM.getFlexSummary
        propertyINVCost = DataHelper.mapFlexPropertyExpenses(
          $scope.properties,
          propertyINVCost
        );

        $scope.flexExpenses.push(propertyINVCost);
        $scope.flexExpenses = DataHelper.arrangeFlexExpenseOrder(
          $scope.flexExpenses
        );

        $scope.flexExpensesMonthlyTotal = DataHelper.getFlexMonthlyTotal(
          $scope.flexExpenses
        );
      };
      function closeModalWhenURLChanges() {
        //Removing modal when url is changed
        $("body").removeClass("modal-open");
        $(".modal-backdrop").remove();
      }
      $scope.toggleFlexSummaryModal = function () {
        $scope.openFlexSummary = $scope.openFlexSummary ? false : true;
      };
      $scope.$on(
        "$stateChangeStart",
        function (event, toState, toParams, fromState, fromParams) {
          closeModalWhenURLChanges();
          if (_.isEqual(toState.name, "login")) {
          } else if (
            toState.url.indexOf("spending") == -1 &&
            !$scope.isSaving &&
            !$scope.didNextSave
          ) {
            $scope.isSaving = true;
            $scope.toStateName = toState.name;
            event.preventDefault();
            if ($scope.showBottomButtons) {
              $scope.showChangesAlert = true;
            } else {
              $state.go(toState.name, {
                clientId: $scope.clientId,
              });
              return;
            }
          }
        }
      );
      $scope.dashboardSave = function () {
        Auth.isAdmin().then((isAdmin) => {
          if (!isAdmin) {
            if ($scope.forms.factfindform.$valid) {
              $scope.save("spending");

              $scope.savedText = "Saved";
            }
          } else {
            $scope.save("spending");

            $scope.savedText = "Saved";
          }
        });
      };
    }
  )

  .controller(
    "factfind-8-controller",
    function (
      $scope,
      $state,
      $stateParams,
      DataM,
      Auth,
      empowerDataService,
      $timeout,
      DataHelper
    ) {
      "use strict";
      $scope.isAdmin = false;
      Auth.isAdmin().then((isAdmin) => ($scope.isAdmin = isAdmin));

      $scope.reqDataCR = {};
      $scope.reqDataMB = {};
      $scope.reqDataLoaded = false;

      $scope.$on("reqData", function (evt, reqData) {
        $scope.reqDataCR = reqData.cr;
        $scope.reqDataMB = reqData.mb;
        $scope.reqDataLoaded = true;
      });

      //Form
      $scope.forms = {};
      $scope.empowerData = empowerDataService;
      // Future plans income and expense
      $scope.financialIndependence = null;
      $scope.carChangeover = null;
      $scope.plannedExpense = null;
      $scope.incomeChanges = null;
      $scope.addlAdvisorInfo = null;
      $scope.isFutureInvestmentsHidden = true;

      $scope.showClientSpinner = true;
      $scope.loadingMessage = "Retrieving Client Information...";
      $scope.showRetrieveError = false;
      $scope.futureHouseholdPageActive = true;
      /*Current/Existing Information Arrays*/
      $scope.currentDependants = [];

      $scope.underThreeBool = false;
      $scope.sixAndUnderCurrentSituation = [];
      $scope.sevenAndOverCurrentSituation = [];
      $scope.existingDependentFutureCosts = [];
      $scope.childCare = {};
      $scope.currentMaternityLeave = {
        matLeaveDetails: {},
      };

      $scope.isPersonalInfoCompleted = false;
      $scope.isIncomeCompleted = false;
      $scope.isPropertyCompleted = false;
      $scope.isNonPropertyCompleted = false;
      $scope.isBorrowingCompleted = false;
      $scope.isBillPaymentsCompleted = false;
      $scope.isSpendingCompleted = false;
      $scope.isFPlanHouseholdCompleted = false;
      $scope.isFPlanIncomeCompleted = false;
      $scope.didNextSave = false;

      // family plans
      $scope.familyPlanBool = false;
      $scope.moreKids = [];
      $scope.familyPlans = {};

      // home improvement
      $scope.isPlanningHomeImprovement = false;
      $scope.plannedRenovations = [];

      // moving homes
      $scope.isMovingHome = false;
      $scope.plannedMoves = [];

      // options
      $scope.daysPerWeek = [
        {
          v: 1,
          n: "1 Day",
        },
      ];
      $scope.futureYears = empowerDataService.futureYears;
      $scope.yearOfSchoolingOptions = [
        {
          v: null,
          n: "Not in School",
        },
        {
          v: 0,
          n: "Prep",
        },
        {
          v: 1,
          n: "Year 1",
        },
      ];
      $scope.secondarySchoolDurationOption = [
        {
          v: 1,
          n: 1,
        },
      ];
      $scope.booleanOptions = [
        {
          n: "Yes",
          v: true,
        },
        {
          n: "No",
          v: false,
        },
      ];
      $scope.monthsLeaveOption = [
        {
          v: 1,
          n: "1 Month",
        },
      ];
      $scope.beforeMatLeaveMonthsOptions = [
        {
          v: 0,
          n: "0 Month",
        },
        {
          v: 1,
          n: "1 Month",
        },
      ];
      $scope.paidWeeksOptions = [
        {
          v: 1,
          n: "1 Week",
        },
      ];
      $scope.childCareYearOptions = [];
      $scope.monthsOfYear = [
        {
          v: 0,
          n: "January",
        },
        {
          v: 1,
          n: "February",
        },
        {
          v: 2,
          n: "March",
        },
        {
          v: 3,
          n: "April",
        },
        {
          v: 4,
          n: "May",
        },
        {
          v: 5,
          n: "June",
        },
        {
          v: 6,
          n: "July",
        },
        {
          v: 7,
          n: "August",
        },
        {
          v: 8,
          n: "September",
        },
        {
          v: 9,
          n: "October",
        },
        {
          v: 10,
          n: "November",
        },
        {
          v: 11,
          n: "December",
        },
      ];
      for (var i = 2; i < 61; i++) {
        var month = {
          v: i,
          n: i + " Months",
        };
        $scope.monthsLeaveOption.push(month);

        if (i < 51) {
          $scope.beforeMatLeaveMonthsOptions.push(month);
        }

        if (i < 8) {
          var day = {
            v: i,
            n: i + " Days",
          };
          $scope.daysPerWeek.push(day);
        }

        if (i < 13) {
          var year = {
            v: i,
            n: "Year " + i,
          };
          $scope.yearOfSchoolingOptions.push(year);
        }

        if (i < 7) {
          var secondaryYear = {
            v: i,
            n: i,
          };
          $scope.secondarySchoolDurationOption.push(secondaryYear);
        }

        if (i < 53) {
          var week = {
            v: i,
            n: i + " Weeks",
          };
          $scope.paidWeeksOptions.push(week);
        }
      }
      var curDate = moment.utc();

      for (var i = curDate.year() - 5; i <= curDate.year() + 50; i++) {
        var year = {
          v: i,
          n: i,
        };
        $scope.childCareYearOptions.push(year);
      }
      /*Future/Planned Information Arrays*/
      $scope.plannedChildren = [];

      $scope.future = {
        homeImprovementBool: "No",
        childrenBool: "No",
        moveBool: "No",
        paidPaternalLeaveBool: "No",
        maternityLeaveBool: "No",
        maternityReturnBool: "No",
        childcareServicesBool: "No",
        childcareRebateBool: "No",
      };

      // attributes as defined in client.model.js
      $scope.ownWords = "";

      $scope.setExistingFutureCostDate = function (dependant) {
        if (
          dependant.hasOwnProperty("endYear") &&
          dependant.hasOwnProperty("endMonth")
        ) {
          //x.resolutionDate =  moment.utc([x.resolutionYear, x.resolutionMonth, 1, 0, 0, 0]);
          var newDate = moment.utc([dependant.endYear, dependant.endMonth, 1]);
          dependant.expDate = newDate;
        }
      };

      $scope.setPlannedChildMatLeaveExpReturnDate = function (
        plannedChildMatLeave
      ) {
        if (
          plannedChildMatLeave.hasOwnProperty("expReturnFullTimeYear") &&
          plannedChildMatLeave.hasOwnProperty("expReturnFullTimeMonth")
        ) {
          var newDate = moment.utc([
            plannedChildMatLeave.expReturnFullTimeYear,
            plannedChildMatLeave.expReturnFullTimeMonth,
            1,
          ]);

          plannedChildMatLeave.expReturnFullTime = newDate;
        }
      };

      $scope.setExpDate = function (home) {
        if (
          home.hasOwnProperty("startYear") &&
          home.hasOwnProperty("startMonth")
        ) {
          var newDate = moment.utc([home.startYear, home.startMonth, 1]);

          home.expDate = newDate;
        }
      };

      $scope.setExpBDate = function (plannedChild) {
        if (
          plannedChild.hasOwnProperty("dobYear") &&
          plannedChild.hasOwnProperty("dobMonth")
        ) {
          var newDate = moment.utc([
            plannedChild.dobYear,
            plannedChild.dobMonth,
            1,
          ]);

          plannedChild.expBirth = newDate;
        }
      };

      $scope.updateCurrentHomePlan = function (ind) {
        $scope.plannedMoves[ind].expSalePrice = null;
        $scope.plannedMoves[ind].expAnnualRent = null;
      };

      $scope.updateMovingTo = function (ind) {
        $scope.plannedMoves[ind].annualRent = null;
        $scope.plannedMoves[ind].purchasePrice = null;
        $scope.plannedMoves[ind].address = null;
      };

      $scope.homeImprovement = {};
      $scope.movingHome = {};
      $scope.incomeChanges = {};
      $scope.plannedExpense = {};
      $scope.carChangeover = {};

      $scope.dependants = [];
      $scope.homeImprovement = [];
      $scope.movingHome = [];
      $scope.isReviewed = false;
      $scope.addlAdvisorInfo = "";

      $scope.allYears = [];
      $scope.futureYears = [];
      $scope.pastYears = [];
      $scope.pastFinancialYears = [];
      $scope.daysOfMonth = [];

      $scope.clientId = $stateParams.clientId;
      $scope.state = $state.current;
      $scope.params = $stateParams;
      $scope.showFutureInvestments = false;

      $scope.calcAge = function (birthDay, birthMonth, birthYear) {
        var todayDate = new Date();
        var todayYear = todayDate.getUTCFullYear();
        var todayMonth = todayDate.getUTCMonth();
        var todayDay = todayDate.getUTCDate();
        var age = todayYear - birthYear;
        if (todayMonth < birthMonth - 1) {
          age--;
        }
        if (birthMonth - 1 === todayMonth && todayDay < birthDay) {
          age--;
        }
        return age;
      };

      $scope.currentChildrenAges = function () {
        for (var index in $scope.currentDependants) {
          var child = $scope.currentDependants[index];
          var childAge = $scope.calcAge(child.day, child.month, child.year);
          if (childAge < 3) {
            $scope.underThreeBool = true;
            $scope.sixAndUnderCurrentSituation.push(child);
          } else if (childAge <= 6) {
            $scope.sixAndUnderCurrentSituation.push(child);
          } else if (childAge >= 7) {
            $scope.sevenAndOverCurrentSituation.push(child);
          }
        }
      };

      $scope.addNewPlannedChild = function () {
        var child = {};
        child.plannedToTakeMatLeave = false;
        child.childCareService = false;
        child.maternityLeave = {};
        child.childCare = {};

        $scope.plannedChildren.push(child);
      };

      $scope.deletePlannedChild = function (child) {
        $scope.plannedChildren.splice($scope.plannedChildren.indexOf(child), 1);
      };

      $scope.addNewPlannedRenovation = function () {
        var home = {};
        $scope.plannedRenovations.push(home);
      };

      $scope.deletePlannedRenovation = function (home) {
        $scope.plannedRenovations.splice(
          $scope.plannedRenovations.indexOf(home),
          1
        );
      };

      // map currentDependant with future household plans
      var mapDetailsPerChild = function (
        currentDependants,
        detailsPerChild,
        otherSchooling
      ) {
        var detailsPerChild = detailsPerChild ? detailsPerChild : [];
        var otherSchooling = otherSchooling ? otherSchooling : [];
        for (var i = 0; i < currentDependants.length; i++) {
          var currentDependant = currentDependants[i];

          for (var j = 0; j < detailsPerChild.length; j++) {
            var detailPerChild = detailsPerChild[j];
            if (currentDependant.dependantId == detailPerChild.dependantId) {
              currentDependant.isAttendingChildcare =
                detailPerChild.isAttendingChildcare;
              currentDependant.daysPerWeek = detailPerChild.daysPerWeek;
              currentDependant.costPerDay = detailPerChild.costPerDay;
              currentDependant.childcareRebate = detailPerChild.childcareRebate;
              currentDependant.startYearPrimSchool =
                detailPerChild.startYearPrimSchool;
              break;
            }
          }

          for (var k = 0; k < otherSchooling.length; k++) {
            var otherSchool = otherSchooling[k];

            if (currentDependant.dependantId == otherSchool.dependantId) {
              currentDependant.yearOfSchooling = otherSchool.yearOfSchooling;
              currentDependant.yearlyPrimarySchoolCost =
                otherSchool.yearlyPrimarySchoolCost;
              currentDependant.yearlySecondarySchoolCost =
                otherSchool.yearlySecondarySchoolCost;
              currentDependant.secondarySchoolDuration =
                otherSchool.secondarySchoolDuration;
              break;
            }
          }
        }
      };

      $scope.addNewPlannedMove = function () {
        var move = {};
        $scope.plannedMoves.push(move);
      };

      $scope.deletePlannedMove = function (move) {
        $scope.plannedMoves.splice($scope.plannedMoves.indexOf(move), 1);
      };

      $scope.generateYears = function () {
        var year = new Date().getFullYear();
        $scope.pastYears.push(year);
        $scope.futureYears.push(year);

        for (var i = 1; i < 50; i++) {
          $scope.pastYears.push(year - i);
          $scope.futureYears.push(year + i);
        }
        for (var i = 1; i <= 50; i++) {
          $scope.allYears.push(year + 20 - i);
        }

        var FY;
        FY = year - 1 + "/" + year;
        $scope.pastFinancialYears.push(FY);
        for (var i = 1; i <= 4; i++) {
          FY = year - i - 1 + "/" + (year - i);
          $scope.pastFinancialYears.push(FY);
        }
        for (var i = 1; i <= 31; i++) {
          $scope.daysOfMonth.push(i);
        }
      };

      //$scope.currentChildrenAges();
      //$scope.generateYears();
      $scope.goToLocation = function (location) {
        var proceed = $scope.empowerData.goToLocation(location, $scope);

        if (proceed) {
          $state.go(location, {
            clientId: $scope.clientId,
          });
        }
      };

      DataM.checkPermission({ name: "viewFutureInvestments" })
        .then(function (data) {
          $scope.showFutureInvestments = data.hasPermission;
        })
        .catch(function (err) {
          console.log(err);
        });

      DataM.getFutureHouseholdPlans()
        .then(function (data) {
          $scope.showClientSpinner = false;
          $scope.dependants = data.dependants;
          $scope.currentDependants = data.dependants;
          $scope.clientProperties = data.properties;

          $scope.homeImprovement = data.homeImprovement;
          $scope.movingHome = data.movingHome;
          $scope.isReviewed = data.isReviewed;
          $scope.addlAdvisorInfo = data.addlAdvisorInfo;

          $scope.isPersonalInfoCompleted = data.isPersonalInfoCompleted;
          $scope.isIncomeCompleted = data.isIncomeCompleted;
          $scope.isPropertyCompleted = data.isPropertyCompleted;
          $scope.isNonPropertyCompleted = data.isNonPropertyCompleted;
          $scope.isBorrowingCompleted = data.isBorrowingCompleted;
          $scope.isBillPaymentsCompleted = data.isBillPaymentsCompleted;
          $scope.isSpendingCompleted = data.isSpendingCompleted;
          $scope.isFPlanHouseholdCompleted = data.isFPlanHouseholdCompleted;
          $scope.isFPlanIncomeCompleted = data.isFPlanIncomeCompleted;

          // check if user is admin
          DataM.getMe()
            .then(function (userData) {
              if (userData.role !== "user" && userData.role !== "guest") {
                $scope.isFutureInvestmentsHidden = false;
              } else {
                $scope.isFutureInvestmentsHidden =
                  data.isPortfolioPlanningHidden;
              }
            })
            .catch(function (err) {
              console.log("Retrieve current user error");
            });

          for (var i = 0; i < $scope.currentDependants.length; i++) {
            const defaultYear = [1980, 0, 1, 0, 0, 0];
            var birthdate = $scope.currentDependants[i].dob
              ? $scope.currentDependants[i].dob
              : moment.utc(defaultYear);

            var newDate = empowerDataService.generateUTCDate(birthdate);
            $scope.currentDependants[i].dob = newDate;
            if (newDate) {
              $scope.currentDependants[i].year = newDate.year();
              $scope.currentDependants[i].month = newDate.month();
              $scope.currentDependants[i].day = newDate.date();
            }
            $scope.currentDependants[i].dependantId =
              $scope.currentDependants[i]._id;
          }

          if (data.futureHouseholdPlans) {
            $scope.ownWords = data.futureHouseholdPlans.ownWords;

            if (data.futureHouseholdPlans.financialIndependence) {
              $scope.financialIndependence =
                data.futureHouseholdPlans.financialIndependence;
            }
            if (data.futureHouseholdPlans.carChangeover) {
              $scope.carChangeover = data.futureHouseholdPlans.carChangeover;
            }
            if (data.futureHouseholdPlans.plannedExpense) {
              $scope.plannedExpense = data.futureHouseholdPlans.plannedExpense;
            }
            if (data.futureHouseholdPlans.incomeChanges) {
              $scope.incomeChanges = data.futureHouseholdPlans.incomeChanges;
            }
            if (data.futureHouseholdPlans.addlAdvisorInfo) {
              $scope.addlAdvisorInfo =
                data.futureHouseholdPlans.addlAdvisorInfo;
            }

            /** MAP DATA RETRIEVED */
            /** Existing Family Dependent */
            if (data.futureHouseholdPlans.existingFamilyDep) {
              $scope.currentMaternityLeave =
                data.futureHouseholdPlans.existingFamilyDep.maternityLeave;

              if (
                data.futureHouseholdPlans.existingFamilyDep.currentSchooling
              ) {
                $scope.addtionalSchoolInfo =
                  data.futureHouseholdPlans.existingFamilyDep.currentSchooling.addlInfo;

                var detailsPerChild = [];
                var otherSchooling =
                  data.futureHouseholdPlans.existingFamilyDep.currentSchooling
                    .otherSchooling;

                if (
                  data.futureHouseholdPlans.existingFamilyDep.currentSchooling
                    .childcare
                ) {
                  detailsPerChild =
                    data.futureHouseholdPlans.existingFamilyDep.currentSchooling
                      .childcare.detailsPerChild;
                  $scope.childCare.yearlyPrimarySchoolCost =
                    data.futureHouseholdPlans.existingFamilyDep.currentSchooling.childcare.yearlyPrimarySchoolCost;
                  $scope.childCare.yearlySecondarySchoolCost =
                    data.futureHouseholdPlans.existingFamilyDep.currentSchooling.childcare.yearlySecondarySchoolCost;
                  $scope.childCare.secondarySchoolDuration =
                    data.futureHouseholdPlans.existingFamilyDep.currentSchooling.childcare.secondarySchoolDuration;
                }

                mapDetailsPerChild(
                  $scope.currentDependants,
                  detailsPerChild,
                  otherSchooling
                );
              }

              if (
                data.futureHouseholdPlans.existingFamilyDep.compositionChange
              ) {
                var compositionChange =
                  data.futureHouseholdPlans.existingFamilyDep.compositionChange;

                for (var i = 0; i < $scope.currentDependants.length; i++) {
                  var currentDependant = $scope.currentDependants[i];
                  for (var j = 0; j < compositionChange.length; j++) {
                    var composition = compositionChange[j];
                    if (
                      composition.dependantId == currentDependant.dependantId
                    ) {
                      currentDependant.expDate = composition.expDate;
                      currentDependant.extraSavings = composition.extraSavings;

                      if (composition.expDate) {
                        var newDate = empowerDataService.generateUTCDate(
                          composition.expDate
                        );
                        composition.expDate = newDate;
                        if (newDate) {
                          currentDependant.expDate = composition.expDate;
                          currentDependant.endYear = newDate.year();
                          currentDependant.endMonth = newDate.month();
                        }
                      }
                    }
                  }
                }
              }
            }

            /** FAMILY PLANS */
            if (data.futureHouseholdPlans.familyPlans) {
              $scope.plannedChildren =
                data.futureHouseholdPlans.familyPlans.moreKids;
              $scope.familyPlans.futurePrimSchoolcost =
                data.futureHouseholdPlans.familyPlans.futurePrimarySchoolCost;
              $scope.familyPlans.futureSecSchoolcost =
                data.futureHouseholdPlans.familyPlans.futureSecondarySchoolCost;
              $scope.familyPlans.oneOffCost =
                data.futureHouseholdPlans.familyPlans.oneOffCost;
              $scope.familyPlans.onGoingCost =
                data.futureHouseholdPlans.familyPlans.onGoingCost;
              $scope.familyPlans.yearsDependent =
                data.futureHouseholdPlans.familyPlans.yearsDependent;
              $scope.familyPlans.addlInfo =
                data.futureHouseholdPlans.familyPlans.addlInfo;

              for (
                var i = 0;
                $scope.plannedChildren && i < $scope.plannedChildren.length;
                i++
              ) {
                var plannedChild = $scope.plannedChildren[i];
                var newDate = empowerDataService.generateUTCDate(
                  plannedChild.expBirth
                );
                plannedChild.expBirth = newDate;
                if (newDate) {
                  plannedChild.dobYear = newDate.year();
                  plannedChild.dobMonth = newDate.month();
                }
                if ($scope.plannedChildren[0].onGoingCost) {
                  $scope.familyPlans.onGoingCost =
                    $scope.plannedChildren[0].onGoingCost;
                }
                if ($scope.plannedChildren[0].yearsDependent) {
                  $scope.familyPlans.yearsDependent =
                    $scope.plannedChildren[0].yearsDependent;
                }
                if ($scope.plannedChildren[0].oneOffCost) {
                  $scope.familyPlans.oneOffCost =
                    $scope.plannedChildren[0].oneOffCost;
                }
                if (plannedChild.maternityLeave) {
                  if (plannedChild.maternityLeave.expReturnFullTime) {
                    var newDate = empowerDataService.generateUTCDate(
                      plannedChild.maternityLeave.expReturnFullTime
                    );
                    plannedChild.maternityLeave.expReturnFullTime = newDate;
                    if (newDate) {
                      plannedChild.maternityLeave.expReturnFullTimeYear =
                        newDate.year();
                      plannedChild.maternityLeave.expReturnFullTimeMonth =
                        newDate.month();
                    }
                  }
                }
              }

              if (
                $scope.moreKids.length > 0 ||
                $scope.familyPlans.futurePrimSchoolcost ||
                $scope.familyPlans.futureSecSchoolcost ||
                $scope.familyPlans.addlInfo ||
                $scope.familyPlans.yearsDependent ||
                $scope.familyPlans.oneOffCost ||
                $scope.familyPlans.onGoingCost
              ) {
                $scope.familyPlanBool = true;
              } else {
                $scope.plannedChildren = [];

                $scope.moreKids = [];
              }
            }

            /** HOME IMPROVEMENTS */
            if (data.futureHouseholdPlans.homeImprovement) {
              $scope.plannedRenovations =
                data.futureHouseholdPlans.homeImprovement;

              if ($scope.plannedRenovations.length > 0) {
                $scope.isPlanningHomeImprovement = true;
              }
              for (var i = 0; i < $scope.plannedRenovations.length; i++) {
                var plannedRenovation = $scope.plannedRenovations[i];
                var newDate = empowerDataService.generateUTCDate(
                  plannedRenovation.expDate
                );
                plannedRenovation.expDate = newDate;
                if (newDate) {
                  plannedRenovation.startMonth = newDate.month();
                  plannedRenovation.startYear = newDate.year();
                }
              }
            }

            if (data.futureHouseholdPlans.movingHome) {
              $scope.plannedMoves = data.futureHouseholdPlans.movingHome;

              if ($scope.plannedMoves.length > 0) {
                $scope.isMovingHome = true;
              }
              for (var i = 0; i < $scope.plannedMoves.length; i++) {
                var plannedMove = $scope.plannedMoves[i];
                var newDate = empowerDataService.generateUTCDate(
                  plannedMove.expDate
                );

                plannedMove.expDate = newDate;
                if (newDate) {
                  plannedMove.startMonth = newDate.month();
                  plannedMove.startYear = newDate.year();
                }
              }
            }
          }

          $scope.currentChildrenAges();
          $scope.generateYears();
          /*Initalise Future Dependents Costs to contain same number of elements as currentDependants Array*/
          for (var i = 0; i < $scope.currentDependants.length; i++) {
            var futureCost = $scope.currentDependants[i];
            $scope.existingDependentFutureCosts.push(futureCost);
          }

          $scope.currentChildrenFutureHouseholdCosts = $scope.currentDependants;
        })
        .catch(function (err) {
          console.log("Retrieve error", err);
          $scope.showRetrieveError = true;
        });

      $scope.save = function (location) {
        $scope.showClientSpinner = true;
        $scope.loadingMessage = "Saving Client Information...";

        // remove maternity leave details if false
        if (!$scope.currentMaternityLeave.currentlyOnMatLeave) {
          $scope.currentMaternityLeave.currentlyOnMatLeave = false;
          $scope.currentMaternityLeave.matLeaveDetails = null;
        }

        if ($scope.familyPlanBool == false) {
          $scope.plannedChildren = [];

          $scope.moreKids = [];
          $scope.familyPlans.futurePrimSchoolcost = null;
          $scope.familyPlans.futureSecSchoolcost = null;
          $scope.familyPlans.addlInfo = null;

          $scope.familyPlans.yearsDependent = null;
          $scope.familyPlans.oneOffCost = null;
          $scope.familyPlans.onGoingCost = null;
        }
        // if($scope.plannedChildren) {
        //    for(var i = 0; i< $scope.plannedChildren.length; i++){
        //      if($scope.familyPlans.onGoingCost){
        //       $scope.plannedChildren[i].onGoingCost = $scope.familyPlans.onGoingCost;
        //      }
        //      if($scope.familyPlans.yearsDependent){
        //       $scope.plannedChildren[i].yearsDependent = $scope.familyPlans.yearsDependent;
        //      }
        //      if($scope.familyPlans.oneOffCost){
        //       $scope.plannedChildren[i].oneOffCost = $scope.familyPlans.oneOffCost;
        //       console.log( $scope.plannedChildren[i]);
        //      }
        //    }
        // }

        var formData = {
          futurePlans: {
            ownWords: $scope.ownWords,
            existingFamilyDep: {
              maternityLeave: $scope.currentMaternityLeave,
              currentSchooling: {
                childcare: {
                  detailsPerChild: $scope.sixAndUnderCurrentSituation,
                  yearlyPrimarySchoolCost:
                    $scope.childCare.yearlyPrimarySchoolCost,
                  yearlySecondarySchoolCost:
                    $scope.childCare.yearlySecondarySchoolCost,
                  secondarySchoolDuration:
                    $scope.childCare.secondarySchoolDuration,
                },
                otherSchooling: $scope.sevenAndOverCurrentSituation,
                addlInfo: $scope.addtionalSchoolInfo,
              },
              compositionChange: $scope.existingDependentFutureCosts,
            },
            familyPlans: {
              moreKids: $scope.plannedChildren,
              futurePrimarySchoolCost: $scope.familyPlans.futurePrimSchoolcost,
              futureSecondarySchoolCost: $scope.familyPlans.futureSecSchoolcost,
              addlInfo: $scope.familyPlans.addlInfo,
              oneOffCost: $scope.familyPlans.oneOffCost,
              onGoingCost: $scope.familyPlans.onGoingCost,
              yearsDependent: $scope.familyPlans.yearsDependent,
            },
            homeImprovement: $scope.plannedRenovations,
            movingHome: $scope.plannedMoves,
            dependants: $scope.dependants,
            isReviewed: $scope.isReviewed,
            addlAdvisorInfo: $scope.addlAdvisorInfo,
            // add future plans expenses
            financialIndependence: $scope.financialIndependence,
            carChangeover: $scope.carChangeover,
            plannedExpense: $scope.plannedExpense,
            incomeChanges: $scope.incomeChanges,
            addlAdvisorInfo: $scope.addlAdvisorInfo,
          },
        };

        //SAVE TO DB
        DataM.saveFutureHouseholdPlans(formData)
          .then(function (data) {
            if (data._message === "Client validation failed") {
              var errorMessage = "";
              const formattedMessage = DataHelper.formatMongoError(data);
              $scope.showClientSpinner = false;

              $scope.didNextSave = false;
              alert(formattedMessage);
            } else {
              $state.go(location, {
                clientId: $scope.clientId,
              });
              $scope.showClientSpinner = false;
            }
          })
          .catch(function (err) {
            console.log("Save error");
            $scope.didNextSave = false;
            $scope.showClientSpinner = false;
          });
      };

      $scope.next = function () {
        $scope.save("fact-find9");
      };
      $scope.back = function () {
        $state.go("fact-find7", {
          clientId: $scope.clientId,
        });
      };
      $scope.quit = function () {
        $state.go("dashboard");
      };

      $scope.savedText = "Save";

      $scope.showAlertValidationModal = function () {
        $scope.alertMessage = $scope.empowerData.saveValidationMessage;
        $scope.showAlertModal = true;
      };
      $scope.closeModal = function () {
        $scope.showAlertModal = false;

        if ($scope.elementToFocus) {
          $timeout($scope.elementToFocus.focus(), 1500);
        }
      };
      $scope.showConfirmSaveModal = function (page) {
        if (!$scope.didNextSave) {
          //$scope.toShowConfirmSaveModal = true;
          $scope.nextPageLocation = page;
        }
      };
      $scope.goNextAndNotSaveModal = function () {
        //$scope.toShowConfirmSaveModal = false;
        $state.go($scope.nextPageLocation, {
          clientId: $scope.clientId,
        });
      };
      $scope.goNextAndSaveModal = function () {
        //$scope.toShowConfirmSaveModal = false;
        $scope.didNextSave = true;
        $scope.save($scope.nextPageLocation);
      };
      $scope.closeConfirmModal = function () {
        //$scope.toShowConfirmSaveModal = false;
        $scope.savedText = "Save";
        $scope.isSaving = false;
      };
      $scope.$on(
        "$stateChangeStart",
        function (event, toState, toParams, fromState, fromParams) {
          if (
            toState.url.indexOf("dashboard") !== -1 ||
            _.isEqual(toState.name, "login")
          ) {
          } else if (
            toState.url.indexOf("future-household") == -1 &&
            !$scope.isSaving &&
            !$scope.didNextSave
          ) {
            $scope.isSaving = true;
            event.preventDefault();
            // check if form is valid
            if (
              $scope.forms.factfindform.$valid &&
              $scope.forms.factfindform.$dirty
            ) {
              $scope.showConfirmSaveModal(toState.name);
            } else {
              if (!$scope.forms.factfindform.$dirty) {
                $state.go(toState.name, {
                  clientId: $scope.clientId,
                });
                return;
              }
            }
          }
        }
      );
      $scope.dashboardSave = function () {
        Auth.isAdmin().then((isAdmin) => {
          if (!isAdmin) {
            if ($scope.forms.factfindform.$valid) {
              $scope.save("future-household");

              $scope.savedText = "Saved";
            }
          } else {
            $scope.save("future-household");

            $scope.savedText = "Saved";
          }
        });
      };
    }
  )

  .controller(
    "factfind-9-controller",
    function (
      $scope,
      $state,
      $stateParams,
      $window,
      DataM,
      Auth,
      empowerDataService,
      DataHelper,
      $timeout
    ) {
      "use strict";
      $scope.isAdmin = false;
      Auth.isAdmin().then((isAdmin) => ($scope.isAdmin = isAdmin));

      $scope.reqDataCR = {};
      $scope.reqDataMB = {};
      $scope.reqDataLoaded = false;

      $scope.$on("reqData", function (evt, reqData) {
        $scope.reqDataCR = reqData.cr;
        $scope.reqDataMB = reqData.mb;
        $scope.reqDataLoaded = true;
      });

      // form
      $scope.forms = {};

      // future plans household
      $scope.ownWords = null;
      $scope.existingFamilyDep = null;
      $scope.familyPlans = null;
      $scope.homeImprovement = null;
      $scope.movingHome = null;

      $scope.showClientSpinner = true;
      $scope.loadingMessage = "Retrieving Client Information...";
      $scope.showRetrieveError = false;
      $scope.futureIncomeExpensePageActive = true;
      $scope.retirement = {};
      $scope.vehicles = [
        {
          name: "Holden Commodore SSV Redline",
        },
      ];
      $scope.future = {
        expensesBool: false,
        incomeBool: false,
        carChangeoverBool: false,
      };
      $scope.owners = [];
      $scope.clientVehicles = [];
      // attributes as they appear in client.model.js
      $scope.financialIndependence = {
        client1FinInd: {},
      };
      $scope.carChangeover = [];
      $scope.plannedExpenses = [];
      $scope.incomeChanges = [];
      $scope.isReviewed = false;
      $scope.addlAdvisorInfo = "";
      $scope.personalInfo = {};
      $scope.years = [
        0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19,
        20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37,
        38, 39, 40,
      ];

      $scope.isPersonalInfoCompleted = false;
      $scope.isIncomeCompleted = false;
      $scope.isPropertyCompleted = false;
      $scope.isNonPropertyCompleted = false;
      $scope.isBorrowingCompleted = false;
      $scope.isBillPaymentsCompleted = false;
      $scope.isSpendingCompleted = false;
      $scope.isFPlanHouseholdCompleted = false;
      $scope.isFPlanIncomeCompleted = false;
      $scope.didNextSave = false;
      $scope.isFutureInvestmentsHidden = true;
      $scope.showFutureInvestments = false;
      $scope.expYearDurationOptions = [
        {
          v: 0,
          n: "0 Years",
        },
        {
          v: 1,
          n: "1 Year",
        },
      ];
      $scope.expMonthDurationOptions = [
        {
          v: 0,
          n: "0 Months",
        },
        {
          v: 1,
          n: "1 Month",
        },
      ];

      $scope.lastPage = false;

      for (var i = 2; i < 51; i++) {
        if (i < 13) {
          var month = {
            v: i,
            n: i + " Months",
          };
          $scope.expMonthDurationOptions.push(month);
        }
        var year = {
          v: i,
          n: i + " Years",
        };
        $scope.expYearDurationOptions.push(year);
      }

      $scope.clientId = $stateParams.clientId;
      $scope.state = $state.current;
      $scope.params = $stateParams;

      $scope.monthsOfYear = [
        {
          v: 0,
          n: "January",
        },
        {
          v: 1,
          n: "February",
        },
        {
          v: 2,
          n: "March",
        },
        {
          v: 3,
          n: "April",
        },
        {
          v: 4,
          n: "May",
        },
        {
          v: 5,
          n: "June",
        },
        {
          v: 6,
          n: "July",
        },
        {
          v: 7,
          n: "August",
        },
        {
          v: 8,
          n: "September",
        },
        {
          v: 9,
          n: "October",
        },
        {
          v: 10,
          n: "November",
        },
        {
          v: 11,
          n: "December",
        },
      ];

      $scope.expYearsOptions = [];
      var curDate = moment.utc();

      for (var i = curDate.year(); i <= curDate.year() + 50; i++) {
        var year = {
          v: i,
          n: i,
        };
        $scope.expYearsOptions.push(year);
      }
      $scope.empowerData = empowerDataService;

      $scope.addNewPlannedExpense = function () {
        var expense = {};
        $scope.plannedExpenses.push(expense);
      };

      $scope.deletePlannedExpense = function (expense) {
        $scope.plannedExpenses.splice(
          $scope.plannedExpenses.indexOf(expense),
          1
        );
      };

      $scope.addNewPlannedIncome = function () {
        $scope.incomeChanges.push({});
      };

      $scope.deletePlannedIncome = function (income) {
        $scope.incomeChanges.splice($scope.incomeChanges.indexOf(income), 1);
      };

      $scope.addNewCarChangeover = function () {
        var change = {};
        $scope.carChangeover.push(change);
      };

      $scope.deleteCarChangeover = function (change) {
        $scope.carChangeover.splice($scope.carChangeover.indexOf(change), 1);
      };

      $scope.changeDuration = function (x) {
        if (isNaN(x.durationMonths)) {
          x.durationMonths = 0;
        }
        if (isNaN(x.durationYears)) {
          x.durationYears = 0;
        }

        x.expDuration =
          parseInt(x.durationYears * 12) + parseInt(x.durationMonths);
      };

      $scope.addDate = function (changeType, x) {
        var futureChange;

        switch (changeType) {
          case "PlannedIncome":
            futureChange =
              $scope.incomeChanges[$scope.incomeChanges.indexOf(x)];
            break;
          case "PlannedExpense":
            futureChange =
              $scope.plannedExpenses[$scope.plannedExpenses.indexOf(x)];
            break;
        }
        if (
          futureChange.hasOwnProperty("year") &&
          futureChange.hasOwnProperty("month")
        ) {
          futureChange.expDate = moment.utc([
            futureChange.year,
            futureChange.month,
            1,
          ]);
        }
      };

      $scope.addRetireDate = function (client1) {
        if (
          client1.hasOwnProperty("expYear") &&
          client1.hasOwnProperty("expMonth")
        ) {
          var newDate = moment.utc([client1.expYear, client1.expMonth, 1]);
          client1.expRetireDate = newDate;
        }
      };

      $scope.assignOwnership = function (income, owner) {
        var incomeChange =
          $scope.incomeChanges[$scope.incomeChanges.indexOf(income)];

        if (owner.name == "Joint") {
          if ($scope.personalInfo.client2) {
            income.owner = {
              ownershipType: "Joint",
              ownershipDesc: "",
              owners: [
                {
                  percentage: 50,
                  name:
                    $scope.personalInfo.client1.fName +
                    " " +
                    $scope.personalInfo.client1.lName,
                  owner: $scope.personalInfo.client1._id,
                },
                {
                  percentage: 50,
                  name:
                    $scope.personalInfo.client2.fName +
                    " " +
                    $scope.personalInfo.client2.lName,
                  owner: $scope.personalInfo.client2._id,
                },
              ],
            };
          }
        } else {
          income.owner = {
            ownershipType: "Sole",
            ownershipDesc: "",
            owners: [
              {
                percentage: 100,
                name: owner.name,
                owner: owner.id,
              },
            ],
          };
        }
      };

      $scope.goToLocation = function (location) {
        var proceed = $scope.empowerData.goToLocation(location, $scope);

        if (proceed) {
          $state.go(location, {
            clientId: $scope.clientId,
          });
        }
      };

      DataM.checkPermission({ name: "viewFutureInvestments" })
        .then(function (data) {
          $scope.showFutureInvestments = data.hasPermission;
        })
        .catch(function (err) {
          console.log(err);
        });

      DataM.getFutureIncomeChanges()
        .then(function (data) {
          $scope.showClientSpinner = false;
          $scope.isPersonalInfoCompleted = data.isPersonalInfoCompleted;
          $scope.isIncomeCompleted = data.isIncomeCompleted;
          $scope.isPropertyCompleted = data.isPropertyCompleted;
          $scope.isNonPropertyCompleted = data.isNonPropertyCompleted;
          $scope.isBorrowingCompleted = data.isBorrowingCompleted;
          $scope.isBillPaymentsCompleted = data.isBillPaymentsCompleted;
          $scope.isSpendingCompleted = data.isSpendingCompleted;
          $scope.isFPlanHouseholdCompleted = data.isFPlanHouseholdCompleted;
          $scope.isFPlanIncomeCompleted = data.isFPlanIncomeCompleted;

          // check if user is admin
          DataM.getMe()
            .then(function (userData) {
              if (userData.role !== "user" && userData.role !== "guest") {
                $scope.isFutureInvestmentsHidden = false;
              } else {
                $scope.isFutureInvestmentsHidden =
                  data.isPortfolioPlanningHidden;
              }
            })
            .catch(function (err) {
              console.log("Retrieve current user error");
            });

          if (data.futurePlans && data.futurePlans.incomeChanges) {
            $scope.incomeChanges = data.futurePlans.incomeChanges;
          }
          if (data.futurePlans && data.futurePlans.plannedExpense) {
            $scope.plannedExpenses = data.futurePlans.plannedExpense;
          }
          if (data.futurePlans && data.futurePlans.carChangeover) {
            $scope.carChangeover = data.futurePlans.carChangeover;
          }
          if (data.futurePlans && data.futurePlans.financialIndependence) {
            $scope.financialIndependence =
              data.futurePlans.financialIndependence;
          }
          if (data.vehicles) {
            $scope.clientVehicles = data.vehicles;
          }
          if (data.futurePlans) {
            $scope.isReviewed = data.futurePlans.isReviewed;
            $scope.addlAdvisorInfo = data.futurePlans.addlAdvisorInfo;
          }
          $scope.personalInfo = data.personalInfo;

          $scope.owners = DataHelper.getOwnerList(data, false, true, false);

          if ($scope.incomeChanges.length > 0) {
            $scope.future.incomeBool = true;

            $scope.incomeChanges = DataHelper.formatOwnership(
              $scope.incomeChanges,
              data.personalInfo,
              $scope.owners
            );
          }

          if ($scope.carChangeover.length > 0) {
            $scope.future.carChangeoverBool = true;
          }

          if ($scope.incomeChanges.length > 0) {
            $scope.future.incomeChangesBool = true;
          }

          if ($scope.plannedExpenses.length > 0) {
            $scope.future.expensesBool = true;
          }

          for (var $i = 0; $i < $scope.incomeChanges.length; $i++) {
            var newDate = empowerDataService.generateUTCDate(
              $scope.incomeChanges[$i].expDate
            );
            if (newDate) {
              $scope.incomeChanges[$i].expDate = newDate;
              if (newDate) {
                $scope.incomeChanges[$i].month = newDate.month();
                $scope.incomeChanges[$i].year = newDate.year();
              }
            }

            if ($scope.incomeChanges[$i].expDuration) {
              $scope.incomeChanges[$i].durationMonths =
                $scope.incomeChanges[$i].expDuration % 12;
              $scope.incomeChanges[$i].durationYears = parseInt(
                $scope.incomeChanges[$i].expDuration / 12
              );
            } else {
              $scope.incomeChanges[$i].durationMonths = 0;
              $scope.incomeChanges[$i].durationYears = 0;
            }
          }

          for (var $i = 0; $i < $scope.plannedExpenses.length; $i++) {
            var newDate = empowerDataService.generateUTCDate(
              $scope.plannedExpenses[$i].expDate
            );
            if (newDate) {
              $scope.plannedExpenses[$i].expDate = newDate;
              $scope.plannedExpenses[$i].month = newDate.month();
              $scope.plannedExpenses[$i].year = newDate.year();
            }

            if ($scope.plannedExpenses[$i].expDuration) {
              $scope.plannedExpenses[$i].durationMonths =
                $scope.plannedExpenses[$i].expDuration % 12;
              $scope.plannedExpenses[$i].durationYears = parseInt(
                $scope.plannedExpenses[$i].expDuration / 12
              );
            } else {
              $scope.plannedExpenses[$i].durationMonths = 0;
              $scope.plannedExpenses[$i].durationYears = 0;
            }
          }

          if ($scope.financialIndependence.client1FinInd) {
            if ($scope.financialIndependence.client1FinInd.expRetireDate) {
              var newDate2 = empowerDataService.generateUTCDate(
                $scope.financialIndependence.client1FinInd.expRetireDate
              );
              $scope.financialIndependence.client1FinInd.expRetireDate =
                newDate2;
              if (newDate2) {
                $scope.financialIndependence.client1FinInd.expMonth =
                  newDate2.month();
                $scope.financialIndependence.client1FinInd.expYear =
                  newDate2.year();
              }
            }
          }

          if ($scope.financialIndependence.client2FinInd) {
            if ($scope.financialIndependence.client2FinInd.expRetireDate) {
              var newDate2 = empowerDataService.generateUTCDate(
                $scope.financialIndependence.client2FinInd.expRetireDate
              );
              $scope.financialIndependence.client2FinInd.expRetireDate =
                newDate2;
              if (newDate2) {
                $scope.financialIndependence.client2FinInd.expMonth =
                  newDate2.month();
                $scope.financialIndependence.client2FinInd.expYear =
                  newDate2.year();
              }
            }
          }

          // future plans house hold
          if (data.futurePlans && data.futurePlans.ownWords) {
            $scope.ownWords = data.futurePlans.ownWords;
          }
          if (data.futurePlans && data.futurePlans.existingFamilyDep) {
            $scope.existingFamilyDep = data.futurePlans.existingFamilyDep;
          }
          if (data.futurePlans && data.futurePlans.homeImprovement) {
            $scope.homeImprovement = data.futurePlans.homeImprovement;
          }
          if (data.futurePlans && data.futurePlans.movingHome) {
            $scope.movingHome = data.futurePlans.movingHome;
          }
          if (data.futurePlans && data.futurePlans.familyPlans) {
            $scope.familyPlans = data.futurePlans.familyPlans;
          }
        })
        .catch(function (err) {
          console.log("Retrieve error", err);
          $scope.showRetrieveError = true;
        });

      $scope.save = function (location) {
        $scope.showClientSpinner = true;
        $scope.loadingMessage = "Saving Client Information...";
        var formData = {
          futurePlans: {
            financialIndependence: $scope.financialIndependence,
            carChangeover: $scope.carChangeover,
            plannedExpense: $scope.plannedExpenses,
            incomeChanges: $scope.incomeChanges,
            isReviewed: $scope.isReviewed,
            addlAdvisorInfo: $scope.addlAdvisorInfo,
            ownWords: $scope.ownWords,
            existingFamilyDep: $scope.existingFamilyDep,
            familyPlans: $scope.familyPlans,
            homeImprovement: $scope.homeImprovement,
            movingHome: $scope.movingHome,
          },
        };
        //SAVE TO DB
        DataM.saveFutureIncomeChanges(formData)
          .then(function (data) {
            if (data._message === "Client validation failed") {
              var errorMessage = "";
              const formattedMessage = DataHelper.formatMongoError(data);
              $scope.showClientSpinner = false;

              $scope.didNextSave = false;
              alert(formattedMessage);
            } else {
              $state.go(location, {
                clientId: $scope.clientId,
              });
              $scope.showClientSpinner = false;
            }
          })
          .catch(function (err) {
            console.log("Save error");
            $scope.didNextSave = false;
            $scope.showClientSpinner = false;
          });
      };

      $scope.next = function () {
        $scope.save("client-summary");
      };
      $scope.back = function () {
        // check if form is valid
        $state.go("fact-find8", {
          clientId: $scope.clientId,
        });
      };
      $scope.quit = function () {
        $state.go("dashboard");
      };

      $scope.savedText = "Save";

      $scope.showAlertValidationModal = function () {
        $scope.alertMessage = $scope.empowerData.saveValidationMessage;
        $scope.showAlertModal = true;
      };
      $scope.closeModal = function () {
        $scope.showAlertModal = false;
        if ($scope.elementToFocus) {
          $timeout($scope.elementToFocus.focus(), 1500);
        }
      };
      $scope.showConfirmSaveModal = function (page) {
        if (!$scope.didNextSave) {
          //$scope.toShowConfirmSaveModal = true;
          $scope.nextPageLocation = page;
        }
      };
      $scope.goNextAndNotSaveModal = function () {
        //$scope.toShowConfirmSaveModal = false;
        $state.go($scope.nextPageLocation, {
          clientId: $scope.clientId,
        });
      };
      $scope.goNextAndSaveModal = function () {
        //$scope.toShowConfirmSaveModal = false;
        $scope.didNextSave = true;
        $scope.save($scope.nextPageLocation);
      };
      $scope.closeConfirmModal = function () {
        //$scope.toShowConfirmSaveModal = false;
        $scope.savedText = "Save";
        $scope.isSaving = false;
      };
      $scope.$on(
        "$stateChangeStart",
        function (event, toState, toParams, fromState, fromParams) {
          if (
            toState.url.indexOf("dashboard") !== -1 ||
            _.isEqual(toState.name, "login")
          ) {
          } else if (
            toState.url.indexOf("future-income-expense") == -1 &&
            !$scope.isSaving &&
            !$scope.didNextSave
          ) {
            $scope.isSaving = true;
            event.preventDefault();
            // check if form is valid
            if (
              $scope.forms.factfindform.$valid &&
              $scope.forms.factfindform.$dirty
            ) {
              $scope.showConfirmSaveModal(toState.name);
            } else {
              if (!$scope.forms.factfindform.$dirty) {
                $state.go(toState.name, {
                  clientId: $scope.clientId,
                });
                return;
              }
            }
          }
        }
      );
      $scope.dashboardSave = function () {
        Auth.isAdmin().then((isAdmin) => {
          if (!isAdmin) {
            if ($scope.forms.factfindform.$valid) {
              $scope.save("future-income-expenses");

              $scope.savedText = "Saved";
            }
          } else {
            $scope.save("future-income-expenses");

            $scope.savedText = "Saved";
          }
        });
      };
    }
  )

  .controller(
    "factfind-10-controller",
    function (
      $scope,
      $state,
      $stateParams,
      DataM,
      $window,
      Auth,
      empowerDataService,
      $timeout,
      DataHelper
    ) {
      $scope.isAdmin = false;
      Auth.isAdmin().then((isAdmin) => ($scope.isAdmin = isAdmin));

      $scope.reqDataCR = {};
      $scope.reqDataMB = {};
      $scope.reqDataLoaded = false;

      $scope.$on("reqData", function (evt, reqData) {
        $scope.reqDataCR = reqData.cr;
        $scope.reqDataMB = reqData.mb;
        $scope.reqDataLoaded = true;
      });

      $scope.showClientSpinner = true;
      $scope.isPersonalInfoCompleted = false;
      $scope.isIncomeCompleted = false;
      $scope.isPropertyCompleted = false;
      $scope.isNonPropertyCompleted = false;
      $scope.isBorrowingCompleted = false;
      $scope.isBillPaymentsCompleted = false;
      $scope.isSpendingCompleted = false;
      $scope.isFPlanHouseholdCompleted = false;
      $scope.isFPlanIncomeCompleted = false;
      $scope.didNextSave = false;
      $scope.isReadOnly = true;
      $scope.showTab = false;
      $scope.isFutureInvestmentsHidden = false;
      $scope.isPortfolioPlanningHidden = true;
      $scope.futurePortfolioPageActive = true;
      $scope.loadingMessage = "Retrieving Client Information...";
      $scope.showRetrieveError = false;
      $scope.clientId = $stateParams.clientId;
      $scope.personalInfo = {};
      $scope.bankAccounts = [];
      $scope.owners = [];
      $scope.forms = {};
      $scope.borrowerList = [];
      $scope.plannedInvestmentProperties = [];
      $scope.plannedInvestments = [];
      $scope.plannedLoans = [];
      $scope.plannedCashTransfers = [];
      $scope.plannedInvestmentRenovations = [];
      $scope.properties = [];
      $scope.empowerData = empowerDataService;
      $scope.loanTerms = [
        1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
        21, 22, 23, 24, 25, 26, 27, 28, 29, 30,
      ];
      $scope.interestOnlyPeriodYears = [
        1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15,
      ];
      $scope.providers = empowerDataService.financeProviders;
      var genShortId = empowerDataService.generateShortId;
      $scope.futureYears = [];

      $scope.showFutureInvestments = true;
      var curDate = moment.utc();
      $scope.booleanOptions = [
        {
          n: "Yes",
          v: true,
        },
        {
          n: "No",
          v: false,
        },
      ];

      for (var i = curDate.year(); i <= curDate.year() + 50; i++) {
        var year = i;
        $scope.futureYears.push(year);
      }

      $scope.loanTypes = [
        "Home Loan - Basic Variable",
        "Home Loan - Standard Variable",
        "Home Loan - Fixed Rate",
        "Home Loan - Line of Credit (Personal Use)",
        "Investment Loan - Basic Variable",
        "Investment Loan - Standard Variable",
        "Investment Loan - Fixed Rate",
        "Investment Loan - Line of Credit (Investment Use)",
        "Credit Card",
        "Car Loan",
        "Personal Loan",
        "ATO or Centrelink Debt",
        "HECS/HELP Debt",
        "Hire Purchase",
        "Lease",
        "Overdraft",
        "Store Card",
        "Commercial Bill",
        "Charge Card",
        "Other",
      ];
      $scope.primaryPurposes = [
        "Buy Real Estate",
        "Refinance Real Estate",
        "Debt Consolidation",
        "General Spending",
        "Other Purchases/Items",
      ];

      $scope.refinancingPurposes = [
        "Re-finance of existing of home loans - Owner occupier/Personal use",
        "Re-finance of existing of home loans - Investment use",
        "Debt consolidation (excluding refinance for existing loans)",
        "Refinancing - Personal Finance",
      ];

      $scope.generalSpendingPurposes = [
        "Purchase of household and personal goods",
        "Travel and holidays",
        "Other",
      ];

      $scope.otherPurposes = [
        "Boats, caravans and trailers",
        "Education/Learning (eg HECS/HELP)",
        "Fund a business",
        "Motor cars and station wagons - new",
        "Motor cars and station wagons - used",
        "Motor cycles, scooters",
        "Other motor vehicles",
        "Personal Investment excluding property ie shares, bonds and managed funds",
        "Tax/Government Debt",
        "Other",
      ];

      $scope.realEstatePurposes = [
        "Construction of dwellings - Owner occupier/Personal use",
        "Construction of dwellings - Investment use",
        "Maintenance and Repairs - Owner occupier/Personal use",
        " Maintenance and Repairs - Investment use",
        "Purchase of newly erected dwellings - Owner occupier/Personal use",
        "Purchase of newly erected dwellings - Investment use",
        "Purchase of established dwelling - Owner occupier/Personal use",
        "Purchase of established dwelling - Investment use",
        "Purchase of off the plan dwelling - Owner occupier/Personal use",
        "Purchase of off the plan dwelling - Investment use",
        "Purchase of vacant land - Owner occupier/Personal use",
        "Purchase of vacant land - Investment use",
        "Purchase of land and buildings (Commercial/Rural) - Personal use",
        "Purchase of land and buildings (Commercial/Rural) - Investment use",
        "Structural alterations and additions - Owner occupier/Personal use",
        "Structural alterations and additions - Investment use",
      ];
      $scope.setPlannedInvestmentDate = function (x, type) {
        var dateFormat = ["DD/MM/YYYY"];
        switch (type) {
          case "Target":
            x.targetDate = moment.utc(x.targetDatePicker, dateFormat);
            break;

          case "Sale":
            x.saleDate = moment.utc(x.saleDatePicker, dateFormat);
            break;

          case "Transfer":
            x.date = moment.utc(x.transferDatePicker, dateFormat);
            break;

          case "Start":
            x.startDate = moment.utc(x.startDatePicker, dateFormat);
            break;

          case "Loan Start":
            x.startDate = moment.utc(x.loanStartDatePicker, dateFormat);
            break;

          case "Expected":
            x.expectedDate = moment.utc(x.expectedDatePicker, dateFormat);
            break;
        }
      };

      $scope.setIsFutureInvestmentsHidden = function () {
        $scope.isFutureInvestmentsHidden = !$scope.isFutureInvestmentsHidden;
        $scope.isPortfolioPlanningHidden = !$scope.isPortfolioPlanningHidden;
      };

      $scope.addPlannedLoans = function () {
        // add planned investment loan
        var plannedLoan = {};

        plannedLoan.borrowers = [];

        $scope.plannedLoans.push(plannedLoan);
      };

      $scope.addPlannedCashTransfer = function (cashTransfer) {
        $scope.plannedCashTransfers.push({});
      };

      $scope.addPlannedInvestmentRenovation = function (plannedRenovation) {
        $scope.plannedInvestmentRenovations.push({});
      };

      $scope.addPlannedInvestmentProperty = function () {
        var plannedInvestmentProperty = {};

        if ($scope.personalInfo) {
          var data = {
            personalInfo: $scope.personalInfo,
          };
          plannedInvestmentProperty.ownerList = DataHelper.getOwnerList(
            data,
            false,
            true
          );
        }
        // Generate random string for prop id;
        // set id since it doesnt have _id from mongodb
        var shortId = genShortId();
        plannedInvestmentProperty._id = Date.now().toString() + shortId;
        plannedInvestmentProperty.name =
          "Planned Property " + ($scope.plannedInvestmentProperties.length + 1);
        $scope.plannedInvestmentProperties.push(plannedInvestmentProperty);
      };
      $scope.addPlannedInvestment = function () {
        var plannedInvestment = {};

        if ($scope.personalInfo) {
          var data = {
            personalInfo: $scope.personalInfo,
          };
          plannedInvestment.ownerList = DataHelper.getOwnerList(
            data,
            false,
            true
          );
        }
        $scope.plannedInvestments.push(plannedInvestment);
      };

      $scope.deletePlannedInvestmentProperty = function (investmentProperty) {
        $scope.plannedInvestmentProperties.splice(
          $scope.plannedInvestmentProperties.indexOf(investmentProperty),
          1
        );
      };

      $scope.deletePlannedInvestment = function (plannedInvestment) {
        $scope.plannedInvestments.splice(
          $scope.plannedInvestments.indexOf(plannedInvestment),
          1
        );
      };
      $scope.deletePlannedInvestmentRenovation = function (
        plannedInvestmentRenovation
      ) {
        $scope.plannedInvestmentRenovations.splice(
          $scope.plannedInvestmentRenovations.indexOf(
            plannedInvestmentRenovation
          ),
          1
        );
      };

      $scope.deletePlannedLoan = function (plannedLoan) {
        $scope.plannedLoans.splice($scope.plannedLoans.indexOf(plannedLoan), 1);
      };

      $scope.deletePlannedCashTransfer = function (plannedCashTransfer) {
        $scope.plannedCashTransfers.splice(
          $scope.plannedCashTransfers.indexOf(plannedCashTransfer),
          1
        );
      };

      $scope.assignOwnership = function (plannedItem, owner, plannedItemType) {
        var ownership = {};
        if (owner.name == "Joint") {
          if ($scope.personalInfo.client2) {
            ownership = {
              ownershipType: "Joint",
              ownershipDesc: "",
              owners: [
                {
                  percentage: plannedItem.percentage1
                    ? plannedItem.percentage1
                    : 50,
                  name:
                    $scope.personalInfo.client1.fName +
                    " " +
                    $scope.personalInfo.client1.lName,
                  owner: $scope.personalInfo.client1._id,
                },
                {
                  percentage: plannedItem.percentage2
                    ? plannedItem.percentage2
                    : 50,
                  name:
                    $scope.personalInfo.client2.fName +
                    " " +
                    $scope.personalInfo.client2.lName,
                  owner: $scope.personalInfo.client2._id,
                },
              ],
            };
          }
        } else {
          ownership = {
            ownershipType: "Sole",
            ownershipDesc: "",
            owners: [
              {
                percentage: 100,
                name: owner.name,
                owner: owner.id,
              },
            ],
          };
        }

        if (plannedItemType !== "Planned Loans") {
          plannedItem.ownership = ownership;
        } else {
          plannedItem.borrower = ownership;
        }
      };
      // Planned loans
      $scope.someSelected = function (object) {
        return Object.keys(object).some(function (key) {
          return object[key];
        });
      };
      $scope.toggleProperty = function (propertyId, loan) {
        // check if loan exists in loans array
        if ($scope.plannedLoans.indexOf(loan) > -1) {
          if (!loan.properties) {
            loan.properties = [];
          }

          var idx = loan.properties.indexOf(propertyId);

          if (idx > -1) {
            loan.properties.splice(idx, 1);
          } else {
            loan.properties.push(propertyId);
          }
        }
        // $scope.someSelected(loan.properties);
      };

      $scope.toggleSecuredAgainst = function (propertyId, loan) {
        // check if loan exists in loans array
        if ($scope.plannedLoans.indexOf(loan) > -1) {
          if (!loan.securedAgainst) {
            loan.securedAgainst = [];
          }

          var idx = loan.securedAgainst.indexOf(propertyId);

          if (idx > -1) {
            loan.securedAgainst.splice(idx, 1);
          } else {
            loan.securedAgainst.push(propertyId);
          }
        }
      };

      $scope.toggleVehicle = function (vehicleId, loan) {
        if ($scope.plannedLoans.indexOf(loan) > -1) {
          if (!loan.vehicles) {
            loan.vehicles = [];
          }

          var idx = loan.vehicles.indexOf(vehicleId);

          if (idx > -1) {
            loan.vehicles.splice(idx, 1);
          } else {
            loan.vehicles.push(vehicleId);
          }
        }
      };

      $scope.calcLoanEndDate = function (loan) {
        var l = $scope.plannedLoans[$scope.plannedLoans.indexOf(loan)];
        var loanDate = moment.utc(l.startDate, ["DD/MM/YYYY"]);

        l.loanExpiryDay = loanDate.date();
        l.loanExpiryMonth = loanDate.month();

        var finishYear = parseInt(loanDate.year(), 10) + parseInt(l.term, 10);
        l.loanExpiryYear = finishYear.toString();

        l.expiryDate = moment.utc(
          [l.loanExpiryYear, l.loanExpiryMonth, l.loanExpiryDay],
          ["YYYY/MM/DD"]
        );
        l.loanExpiryDatePicker = empowerDataService.generatedLocalDateString(
          l.expiryDate
        );
      };

      $scope.changeDetailedPurpose = function (loan, purpose) {
        if (purpose == "Real Estate") {
          if (loan.detailedPurpose) {
            if (
              $scope.realEstatePurposes.indexOf(loan.detailedPurpose) === -1
            ) {
              loan.detailedPurpose = null;
            }
          }
        }
        if (purpose == "Refinancing/Debt Consolidation") {
          if (loan.detailedPurpose) {
            if (
              $scope.refinancingPurposes.indexOf(loan.detailedPurpose) === -1
            ) {
              loan.detailedPurpose = null;
            }
          }
        }
        if (purpose == "General Spending") {
          if (loan.detailedPurpose) {
            if (
              $scope.generalSpendingPurposes.indexOf(
                loan.detailedPurpose === -1
              )
            ) {
              loan.detailedPurpose = null;
            }
          }
        }
        if (purpose == "Other Purchases/Items") {
          if (loan.detailedPurpose) {
            if ($scope.otherPurposes.indexOf(loan.detailedPurpose) === -1) {
              loan.detailedPurpose = null;
            }
          }
        }
      };

      $scope.removeDateValue = function (x, type) {
        if (type === "Target") {
          x.targetDate = null;
          x.targetDay = "";
          x.targetMonth = "";
          x.targetYear = "";
        } else if (type === "Sale") {
          x.saleDate = null;
          x.saleYear = "";
          x.saleMonth = "";
          x.saleDay = "";
        } else if (type === "Transfer") {
          x.date = null;
          x.transferYear = "";
          x.transferMonth = "";
          x.transferDay = "";
        } else if (type === "Start") {
          x.startDate = null;
          x.startYear = "";
          x.startMonth = "";
          x.startDay = "";
        } else if (type === "Loan Start") {
          x.startDate = null;
          x.loanStartYear = "";
          x.loanStartMonth = "";
          x.loanStartDay = "";
        } else if (type === "Loan Expiry") {
          x.expiryDate = null;
          x.loanExpiryYear = "";
          x.loanExpiryMonth = "";
          x.loanExpiryDay = "";
        } else if (type === "Expected") {
          x.expectedDate = null;
          x.expectedYear = "";
          x.expectedMonth = "";
          x.expectedDay = "";
        }
      };

      DataM.getPortfolioPlanning().then(function (data) {
        $scope.personalInfo = data.personalInfo;
        $scope.bankAccounts = data.bankAccounts;
        $scope.owners = DataHelper.getOwnerList(data, false, true, true);
        $scope.borrowerList = DataHelper.getOwnerList(data, false, true, true);
        $scope.properties = data.properties;

        $scope.isPersonalInfoCompleted = data.isPersonalInfoCompleted;
        $scope.isIncomeCompleted = data.isIncomeCompleted;
        $scope.isPropertyCompleted = data.isPropertyCompleted;
        $scope.isNonPropertyCompleted = data.isNonPropertyCompleted;
        $scope.isBorrowingCompleted = data.isBorrowingCompleted;
        $scope.isBillPaymentsCompleted = data.isBillPaymentsCompleted;
        $scope.isSpendingCompleted = data.isSpendingCompleted;
        $scope.isFPlanHouseholdCompleted = data.isFPlanHouseholdCompleted;
        $scope.isFPlanIncomeCompleted = data.isFPlanIncomeCompleted;

        if (data.portfolioPlanning) {
          // check if user is admin
          DataM.getMe()
            .then(function (userData) {
              if (userData.role !== "user" && userData.role !== "guest") {
                $scope.isPortfolioPlanningHidden =
                  data.portfolioPlanning.isHidden;
                $scope.isAdmin = true;
              } else {
                $scope.isPortfolioPlanningHidden =
                  data.portfolioPlanning.isHidden;
                $scope.isAdmin = false;
              }
            })
            .catch(function (err) {
              console.log("Retrieve current user error");
            });

          if (data.portfolioPlanning.plannedCashTransfers) {
            $scope.plannedCashTransfers =
              data.portfolioPlanning.plannedCashTransfers;
          }

          if (
            data.portfolioPlanning.plannedInvestments &&
            data.portfolioPlanning.plannedInvestments.plannedPropertyInvestments
          ) {
            $scope.plannedInvestmentProperties =
              data.portfolioPlanning.plannedInvestments.plannedPropertyInvestments;
            $scope.plannedInvestmentProperties = DataHelper.formatOwnership(
              data.portfolioPlanning.plannedInvestments
                .plannedPropertyInvestments,
              data.personalInfo,
              DataHelper.getOwnerList(data, false, true, true)
            );
          }
          if (
            data.portfolioPlanning.plannedInvestments &&
            data.portfolioPlanning.plannedInvestments.plannedOtherInvestments
          ) {
            $scope.plannedInvestments =
              data.portfolioPlanning.plannedInvestments.plannedOtherInvestments;
            $scope.plannedInvestments = DataHelper.formatOwnership(
              data.portfolioPlanning.plannedInvestments.plannedOtherInvestments,
              data.personalInfo,
              DataHelper.getOwnerList(data, false, true, true)
            );
          }
          if (data.portfolioPlanning.plannedLoans) {
            $scope.plannedLoans = data.portfolioPlanning.plannedLoans;
            $scope.plannedLoans = DataHelper.formatBorrower(
              data.portfolioPlanning.plannedLoans,
              data.personalInfo,
              $scope.borrowerList
            );
          }
          if (data.portfolioPlanning.plannedInvestmentRenovations) {
            $scope.plannedInvestmentRenovations =
              data.portfolioPlanning.plannedInvestmentRenovations;
          }
        }

        for (var $i = 0; $i < $scope.plannedCashTransfers.length; $i++) {
          if (
            $scope.plannedCashTransfers[$i].hasOwnProperty("date") &&
            $scope.plannedCashTransfers[$i].date !== null
          ) {
            $scope.plannedCashTransfers[$i].transferDatePicker =
              empowerDataService.generatedLocalDateString(
                $scope.plannedCashTransfers[$i].date
              );
            $scope.plannedCashTransfers[$i].date =
              empowerDataService.generateUTCDate(
                $scope.plannedCashTransfers[$i].date
              );
          }
        }

        for (var $i = 0; $i < $scope.plannedInvestmentProperties.length; $i++) {
          if (
            $scope.plannedInvestmentProperties[$i].hasOwnProperty(
              "targetDate"
            ) &&
            $scope.plannedInvestmentProperties[$i].targetDate !== null
          ) {
            $scope.plannedInvestmentProperties[$i].targetDatePicker =
              empowerDataService.generatedLocalDateString(
                $scope.plannedInvestmentProperties[$i].targetDate
              );
            $scope.plannedInvestmentProperties[$i].targetDate =
              empowerDataService.generateUTCDate(
                $scope.plannedInvestmentProperties[$i].targetDate
              );
          }

          if (
            $scope.plannedInvestmentProperties[$i].hasOwnProperty("saleDate") &&
            $scope.plannedInvestmentProperties[$i].saleDate !== null
          ) {
            $scope.plannedInvestmentProperties[$i].saleDatePicker =
              empowerDataService.generatedLocalDateString(
                $scope.plannedInvestmentProperties[$i].saleDate
              );
            $scope.plannedInvestmentProperties[$i].saleDate =
              empowerDataService.generateUTCDate(
                $scope.plannedInvestmentProperties[$i].saleDate
              );
          }
        }
        for (var $i = 0; $i < $scope.plannedInvestments.length; $i++) {
          if (
            $scope.plannedInvestments[$i].hasOwnProperty("startDate") &&
            $scope.plannedInvestments[$i].startDate !== null
          ) {
            $scope.plannedInvestments[$i].startDatePicker =
              empowerDataService.generatedLocalDateString(
                $scope.plannedInvestments[$i].startDate
              );
            $scope.plannedInvestments[$i].startDate =
              empowerDataService.generateUTCDate(
                $scope.plannedInvestments[$i].startDate
              );
          }
        }

        for (var $i = 0; $i < $scope.plannedLoans.length; $i++) {
          if (
            $scope.plannedLoans[$i].hasOwnProperty("expectedDate") &&
            $scope.plannedLoans[$i].expectedDate !== null
          ) {
            $scope.plannedLoans[$i].expectedDate =
              empowerDataService.generateUTCDate(
                $scope.plannedLoans[$i].expectedDate
              );
          }
        }
        for (
          var $i = 0;
          $i < $scope.plannedInvestmentRenovations.length;
          $i++
        ) {
          if (
            $scope.plannedInvestmentRenovations[$i].hasOwnProperty(
              "expectedDate"
            ) &&
            $scope.plannedInvestmentRenovations[$i].expectedDate !== null
          ) {
            $scope.plannedInvestmentRenovations[$i].expectedDatePicker =
              empowerDataService.generatedLocalDateString(
                $scope.plannedInvestmentRenovations[$i].expectedDate
              );
            $scope.plannedInvestmentRenovations[$i].expectedDate =
              empowerDataService.generateUTCDate(
                $scope.plannedInvestmentRenovations[$i].expectedDate
              );
          }
        }

        for (var $i = 0; $i < $scope.plannedLoans.length; $i++) {
          if (
            $scope.plannedLoans[$i].hasOwnProperty("startDate") &&
            $scope.plannedLoans[$i].startDate !== null
          ) {
            $scope.plannedLoans[$i].loanStartDatePicker =
              empowerDataService.generatedLocalDateString(
                $scope.plannedLoans[$i].startDate
              );
            $scope.plannedLoans[$i].startDate =
              empowerDataService.generateUTCDate(
                $scope.plannedLoans[$i].startDate
              );
          }

          if (
            $scope.plannedLoans[$i].hasOwnProperty("expiryDate") &&
            $scope.plannedLoans[$i].expiryDate !== null
          ) {
            $scope.plannedLoans[$i].loanExpiryDatePicker =
              empowerDataService.generatedLocalDateString(
                $scope.plannedLoans[$i].expiryDate
              );
            $scope.plannedLoans[$i].expiryDate =
              empowerDataService.generateUTCDate(
                $scope.plannedLoans[$i].expiryDate
              );
          }
        }

        $scope.showClientSpinner = false;
      });

      // Save
      $scope.save = function (location) {
        $scope.showClientSpinner = true;
        $scope.loadingMessage = "Saving Client Information...";

        //SAVE TO DB
        var formData = {
          portfolioPlanning: {
            plannedInvestments: {
              plannedOtherInvestments: $scope.plannedInvestments,
              plannedPropertyInvestments: $scope.plannedInvestmentProperties,
            },
            plannedInvestmentRenovations: $scope.plannedInvestmentRenovations,
            plannedLoans: $scope.plannedLoans,
            plannedCashTransfers: $scope.plannedCashTransfers,
            isHidden: $scope.isPortfolioPlanningHidden,
          },
        };

        DataM.savePortfolioPlanning(formData)
          .then(function (data) {
            if (data._message === "Client validation failed") {
              var errorMessage = "";
              const formattedMessage = DataHelper.formatMongoError(data);
              $scope.showClientSpinner = false;

              $scope.didNextSave = false;
              alert(formattedMessage);
            } else {
              $state.go(location, {
                clientId: $scope.clientId,
              });
              $scope.showClientSpinner = false;
            }
          })
          .catch(function (err) {
            console.log("Save error");
            $scope.didNextSave = false;
            $scope.showClientSpinner = false;
          });
      };
      $scope.savedText = "Save";

      $scope.dashboardSave = function () {
        $scope.save("portfolio-planning");

        $scope.savedText = "Saved";
      };

      $scope.next = function () {
        // check if form is valid
        $scope.save("client-summary");
      };
      $scope.back = function () {
        // check if form is valid
        $state.go("fact-find9", {
          clientId: $scope.clientId,
        });
      };
      $scope.quit = function () {
        $state.go("dashboard");
      };

      $scope.savedText = "Save";

      $scope.showAlertValidationModal = function () {
        $scope.alertMessage = $scope.empowerData.saveValidationMessage;
        $scope.showAlertModal = true;
      };
      $scope.closeModal = function () {
        $scope.showAlertModal = false;
        if ($scope.elementToFocus) {
          $timeout($scope.elementToFocus.focus(), 1500);
        }
      };
      $scope.showConfirmSaveModal = function (page) {
        if (!$scope.didNextSave) {
          //$scope.toShowConfirmSaveModal = true;
          $scope.nextPageLocation = page;
        }
      };
      $scope.goNextAndNotSaveModal = function () {
        //$scope.toShowConfirmSaveModal = false;
        $state.go($scope.nextPageLocation, {
          clientId: $scope.clientId,
        });
      };
      $scope.goNextAndSaveModal = function () {
        //$scope.toShowConfirmSaveModal = false;
        $scope.didNextSave = true;
        $scope.save($scope.nextPageLocation);
      };
      $scope.closeConfirmModal = function () {
        //$scope.toShowConfirmSaveModal = false;
        $scope.savedText = "Save";
        $scope.isSaving = false;
      };

      $scope.goToLocation = function (location) {
        var proceed = $scope.empowerData.goToLocation(location, $scope);

        if (proceed) {
          $state.go(location, {
            clientId: $scope.clientId,
          });
        }
      };
    }
  )

  .controller(
    "client-summary-controller",
    function (
      $scope,
      $state,
      $stateParams,
      DataM,
      User,
      Auth,
      EmpowerWealthHelper,
      $timeout
    ) {
      "use strict";
      $scope.isAdmin = false;
      Auth.isAdmin().then((isAdmin) => ($scope.isAdmin = isAdmin));

      var id = User._id;
      //$scope.clientSchema = {test:true};
      $scope.userID = {
        ID: id,
      };
      // grab the whole JSON file to summarize
      $scope.clientSchema = {};
      $scope.lastPage = true;

      // Hold client 1 and 2 ids
      $scope.client1Id = "";
      $scope.client2Id = "";

      $scope.showClientSpinner = true;
      $scope.loadingMessage = "Retrieving Client Information...";
      $scope.showRetrieveError = false;

      /*Property Portfolio*/
      //Need an array of properties.
      $scope.loansPropertyList = [];
      $scope.nonLoanPropertyList = [];
      //Need an array of Loans.
      $scope.totalPropertiesValue = 0;

      /*Property Loans*/
      $scope.loansTotalLimit = 0;
      $scope.loansTotalBalance = 0;
      $scope.loansTotalRepayment = 0;
      $scope.loansBalance = 0;
      $scope.currentLvr = 0;
      $scope.avlEquityAtNinety = 0;
      $scope.repayments = 0;

      $scope.isPersonalInfoCompleted = true;
      $scope.isIncomeCompleted = true;
      $scope.isPropertyCompleted = true;
      $scope.isNonPropertyCompleted = true;
      $scope.isBorrowingCompleted = true;
      $scope.isBillPaymentsCompleted = true;
      $scope.isSpendingCompleted = true;
      $scope.isFPlanHouseholdCompleted = true;
      $scope.isFPlanIncomeCompleted = true;

      /*Summary of Income*/
      /*[0] - Client 1, [1] - Client 2*/
      $scope.grossPaygIncome = [0, 0];
      $scope.superannuationSalarySacrifice = [0, 0];
      $scope.grossBusinessIncome = [0, 0];
      $scope.grossSoleTraderIncome = [0, 0];
      $scope.grossOtherIncome = [0, 0];
      $scope.grossRentalIncome = [0, 0];
      $scope.grossInvestmentIncome = [0, 0];

      $scope.totalIncome = [0, 0];

      $scope.investmentPropertyDeductions = [0, 0];
      $scope.otherTaxDeductions = [0, 0];
      $scope.taxableIncome = [0, 0];
      $scope.estimatedTaxPaid = [0, 0];
      $scope.nonTaxableIncome = [0, 0];
      $scope.totalNetIncome = [0, 0];

      /*Summary of Expenditure*/
      /*[0] - Monthly, [1] - Yearly*/
      $scope.totalBillPayments = [0, 0];
      $scope.totalSpending = [0, 0];
      $scope.totalInvestmentPropertyCosts = [0, 0];
      $scope.totalLoanPayments = [0, 0];
      $scope.afterTaxSuperContributions = [0, 0];

      $scope.totalExpenditure = [0, 0];

      /*[0] - Monthly, [1] - Yearly*/
      $scope.incomeOverall = [0, 0];
      $scope.expenditureOverall = [0, 0];
      $scope.surplus = [
        $scope.incomeOverall[0] - $scope.expenditureOverall[0],
        $scope.incomeOverall[1] - $scope.expenditureOverall[1],
      ];

      /*Summary of Assets and Liabilities*/
      $scope.totalInvestmentAssets = 0;
      $scope.totalNonInvestmentAssets = 0;
      $scope.totalLiabilities = 0;
      $scope.totalOutstandingCreditCardDebit = 0;

      $scope.clientId = $stateParams.clientId;
      $scope.state = $state.current;
      $scope.params = $stateParams;

      $scope.goToLocation = function (location) {
        $state.go(location, {
          clientId: $scope.clientId,
        });
      };
      $scope.calculateAllInvestmentAssets = function () {
        /*Calculate Total Super Annuation*/
        for (var $i = 0; $i < $scope.allSuper.length; $i++) {
          $scope.totalInvestmentAssets += $scope.allSuper[$i].value;

          if ($scope.allSuper[$i].personalContributionsBool == true) {
            $scope.afterTaxSuperContributions[1] +=
              $scope.allSuper[$i].personalContributionsClient1 +
              $scope.allSuper[$i].personalContributionsClient2;
          }
          /*Super Salary Sacrifice & Post Tax Contributions*/
          if (
            $scope.allSuper[$i].ownership &&
            $scope.allSuper[$i].ownership.owners.length == 1 &&
            $scope.allSuper[$i].salarySacrificeClient1 != null
          ) {
            if ($scope.allSuper[$i].ownership.ownershipType == "Sole") {
              switch ($scope.allSuper[$i].ownership.owners[0].owner) {
                case $scope.client1Id:
                  $scope.superannuationSalarySacrifice[0] +=
                    $scope.allSuper[$i].salarySacrificeClient1;
                  break;

                case $scope.client2Id:
                  $scope.superannuationSalarySacrifice[1] +=
                    $scope.allSuper[$i].salarySacrificeClient2;
                  break;
              }
            }
          } else {
            if ($scope.allSuper[$i].salarySacrificeClient1 != null) {
              $scope.superannuationSalarySacrifice[0] +=
                $scope.allSuper[$i].salarySacrificeClient1;
            }

            if ($scope.allSuper[$i].salarySacrificeClient2 != null) {
              $scope.superannuationSalarySacrifice[1] +=
                $scope.allSuper[$i].salarySacrificeClient2;
            }
          }
        }

        $scope.superannuationSalarySacrifice[0] *= -1;
        $scope.superannuationSalarySacrifice[1] *= -1;

        $scope.afterTaxSuperContributions[0] =
          $scope.afterTaxSuperContributions[1] / 12;

        /*Calculate Total Bank Accounts*/
        for ($i = 0; $i < $scope.bankAccounts.length; $i++) {
          $scope.totalInvestmentAssets += $scope.bankAccounts[$i].balance;
        }

        /*Calculate Total Investments Value*/
        for ($i = 0; $i < $scope.investments.length; $i++) {
          $scope.totalInvestmentAssets += $scope.investments[$i].currentValue;
        }

        /*Calculate Total Investment Property Value*/
        for (var $i = 0; $i < $scope.properties.length; $i++) {
          switch ($scope.properties[$i].purpose) {
            case "Business Use":
              $scope.totalInvestmentAssets +=
                $scope.properties[$i].currentValue;
              break;

            case "Investment Property":
              $scope.totalInvestmentAssets +=
                $scope.properties[$i].currentValue;
              break;
          }
        }
      };

      $scope.calculatePropertyInvestmentCost = function () {
        for (var i = 0; i < $scope.properties.length; i++) {
          var property = $scope.properties[i];
          if (property.purpose === "Investment") {
            // calculate property cost
            if (property.bodyCorpCosts) {
              $scope.totalInvestmentPropertyCosts[1] += property.bodyCorpCosts;
            }
            if (property.insuranceCosts) {
              $scope.totalInvestmentPropertyCosts[1] += property.insuranceCosts;
            }
            if (property.landTaxCosts) {
              $scope.totalInvestmentPropertyCosts[1] += property.landTaxCosts;
            }
            if (property.maintenanceCost) {
              $scope.totalInvestmentPropertyCosts[1] +=
                property.maintenanceCost;
            }
            if (property.managementFees) {
              $scope.totalInvestmentPropertyCosts[1] += property.managementFees;
            }
            if (property.waterRates) {
              $scope.totalInvestmentPropertyCosts[1] += property.waterRates;
            }
            if (property.councilRates) {
              $scope.totalInvestmentPropertyCosts[1] += property.councilRates;
            }
          }
        }
        $scope.totalInvestmentPropertyCosts[0] =
          $scope.totalInvestmentPropertyCosts[1] / 12;
      };

      $scope.calculateAllNonInvestmentAssets = function () {
        for (var i = 0; i < $scope.properties.length; i++) {
          var property = $scope.properties[i];
          switch (property.purpose) {
            case "Personal Use":
              $scope.totalNonInvestmentAssets +=
                $scope.properties[i].currentValue;
              break;

            case "Own Home":
              $scope.totalNonInvestmentAssets +=
                $scope.properties[i].currentValue;
              break;
          }
        }
      };

      $scope.calculateIncome = function () {
        var allIncome = [];
        $scope.otherIncome = [];

        if ($scope.clientSchema.income) {
          // Client 1
          if ($scope.clientSchema.income.client1PersonalIncome) {
            allIncome =
              $scope.clientSchema.income.client1PersonalIncome
                .currentEmployment;
            $scope.otherIncome =
              $scope.clientSchema.income.client1PersonalIncome.other;

            for (var i = 0; i < allIncome.length; i++) {
              var income = allIncome[i];
              income.owner = $scope.client1FullName;
            }

            for (var i = 0; i < $scope.otherIncome.length; i++) {
              var income = $scope.otherIncome[i];
              income.owner = $scope.client1FullName;
            }
          }
          // Client 2
          if ($scope.clientSchema.income.client2PersonalIncome) {
            for (
              var i = 0;
              i <
              $scope.clientSchema.income.client2PersonalIncome.currentEmployment
                .length;
              i++
            ) {
              var income =
                $scope.clientSchema.income.client2PersonalIncome
                  .currentEmployment[i];
              income.owner = $scope.client2FullName;
            }

            for (
              var i = 0;
              i < $scope.clientSchema.income.client2PersonalIncome.other.length;
              i++
            ) {
              var income =
                $scope.clientSchema.income.client2PersonalIncome.other[i];
              income.owner = $scope.client2FullName;
            }

            allIncome = allIncome.concat(
              $scope.clientSchema.income.client2PersonalIncome.currentEmployment
            );

            $scope.otherIncome = $scope.otherIncome.concat(
              $scope.clientSchema.income.client2PersonalIncome.other
            );
          }
        }

        $scope.allBusinessIncome = [];
        $scope.allSoleTraderIncome = [];
        $scope.allInvestments = $scope.clientSchema.assets.investments;

        /*Total Value of PAYG Income*/
        for (var $i = 0; $i < allIncome.length; $i++) {
          switch (allIncome[$i].employmentType) {
            case "PAYG":
              switch (allIncome[$i].owner) {
                case $scope.client1FullName:
                  $scope.grossPaygIncome[0] += allIncome[$i].job.annualSalary;
                  $scope.grossOtherIncome[0] += allIncome[$i].job.overtime;
                  $scope.grossOtherIncome[0] += allIncome[$i].job.commission;
                  $scope.grossOtherIncome[0] += allIncome[$i].job.bonus;
                  break;

                case $scope.client2FullName:
                  $scope.grossPaygIncome[1] += allIncome[$i].job.annualSalary;
                  $scope.grossOtherIncome[1] += allIncome[$i].job.overtime;
                  $scope.grossOtherIncome[1] += allIncome[$i].job.commission;
                  $scope.grossOtherIncome[1] += allIncome[$i].job.bonus;
                  break;
              }
            default:
              if (allIncome[$i].business) {
                if (allIncome[$i].business.basis != "Sole trader") {
                  $scope.allBusinessIncome.push(allIncome[$i]);
                } else {
                  $scope.allSoleTraderIncome.push(allIncome[$i]);
                }
              }
              break;
          }
        }
        /*Total Value of Business Income*/
        for (
          var $i = 0;
          $i < $scope.allBusinessIncome.length &&
          $scope.allBusinessIncome[$i].business.earnings[0];
          $i++
        ) {
          switch ($scope.allBusinessIncome[$i].owner) {
            case $scope.client1FullName:
              $scope.grossBusinessIncome[0] += $scope.allBusinessIncome[$i]
                .business.earnings[0].salary
                ? $scope.allBusinessIncome[$i].business.earnings[0].salary
                : 0;

              break;

            case $scope.client2FullName:
              $scope.grossBusinessIncome[1] += $scope.allBusinessIncome[$i]
                .business.earnings[0].salary
                ? $scope.allBusinessIncome[$i].business.earnings[0].salary
                : 0;

              break;
          }
        }

        /*Total Value of Sole Trader Income*/
        for (
          var $i = 0;
          $i < $scope.allSoleTraderIncome.length &&
          $scope.allSoleTraderIncome[$i].business.earnings[0];
          $i++
        ) {
          switch ($scope.allSoleTraderIncome[$i].owner) {
            case $scope.client1FullName:
              $scope.grossSoleTraderIncome[0] += $scope.allSoleTraderIncome[$i]
                .business.earnings[0].salary
                ? $scope.allSoleTraderIncome[$i].business.earnings[0].salary
                : 0;

              break;

            case $scope.client2FullName:
              $scope.grossSoleTraderIncome[1] += $scope.allSoleTraderIncome[$i]
                .business.earnings[0].salary
                ? $scope.allSoleTraderIncome[$i].business.earnings[0].salary
                : 0;

              break;
          }
        }

        /*Calculating Other Income Total Value*/
        for (var $i = 0; $i < $scope.otherIncome.length; $i++) {
          if ($scope.otherIncome[$i].isTaxFree == false) {
            switch ($scope.otherIncome[$i].owner) {
              case $scope.client1FullName:
                $scope.grossOtherIncome[0] += $scope.otherIncome[$i].amount;
                break;

              case $scope.client2FullName:
                $scope.grossOtherIncome[1] += $scope.otherIncome[$i].amount;
                break;
            }
          } else {
            switch ($scope.otherIncome[$i].owner) {
              case $scope.client1FullName:
                $scope.nonTaxableIncome[0] += $scope.otherIncome[$i].amount;
                break;

              case $scope.client2FullName:
                $scope.nonTaxableIncome[1] += $scope.otherIncome[$i].amount;
                break;
            }
          }
        }

        /*Calculating Rental Income Total Value*/
        for (var $i = 0; $i < $scope.properties.length; $i++) {
          if (
            $scope.properties[$i].purpose == "Investment Property" ||
            $scope.properties[$i].purpose == "Investment" ||
            $scope.properties[$i].purpose == "Business Use"
          ) {
            if ($scope.properties[$i].ownership.ownershipType == "Sole") {
              if ($scope.properties[$i].ownership.owners.length == 1) {
                switch ($scope.properties[$i].ownership.owners[0].owner) {
                  case $scope.client1Id:
                    $scope.grossRentalIncome[0] +=
                      $scope.properties[$i].grossRentalIncome *
                      ($scope.properties[$i].ownership.owners[0].percentage /
                        100);

                    break;

                  case $scope.client2Id:
                    $scope.grossRentalIncome[1] +=
                      $scope.properties[$i].grossRentalIncome *
                      ($scope.properties[$i].ownership.owners[0].percentage /
                        100);
                    break;
                }
              }
            } else if (
              $scope.properties[$i].ownership.ownershipType == "Joint"
            ) {
              $scope.grossRentalIncome[0] +=
                $scope.properties[$i].grossRentalIncome *
                ($scope.properties[$i].ownership.owners[0].percentage / 100);
              if (
                $scope.properties[$i].ownership.owners[1] &&
                $scope.properties[$i].ownership.owners[1].percentage
              ) {
                $scope.grossRentalIncome[1] +=
                  $scope.properties[$i].grossRentalIncome *
                  ($scope.properties[$i].ownership.owners[1].percentage / 100);
              }
            }
          }
        }

        /*Calculating Investments Total Value*/
        for (var $i = 0; $i < $scope.allInvestments.length; $i++) {
          if ($scope.allInvestments[$i].ownership.ownershipType == "Sole") {
            switch ($scope.allInvestments[$i].ownership.owners[0].owner) {
              case $scope.client1Id:
                $scope.grossInvestmentIncome[0] +=
                  $scope.allInvestments[$i].yearlyIncome *
                  ($scope.allInvestments[$i].ownership.owners[0].percentage /
                    100);
                break;

              case $scope.client2Id:
                $scope.grossInvestmentIncome[1] +=
                  $scope.allInvestments[$i].yearlyIncome *
                  ($scope.allInvestments[$i].ownership.owners[0].percentage /
                    100);
                break;
            }
          }
          if ($scope.allInvestments[$i].ownership.ownershipType == "Joint") {
            if ($scope.allInvestments[$i].ownership.owners.length > 1) {
              $scope.grossInvestmentIncome[0] +=
                $scope.allInvestments[$i].yearlyIncome *
                ($scope.allInvestments[$i].ownership.owners[0].percentage /
                  100);
              if (
                $scope.allInvestments[$i].ownership.owners[1] &&
                $scope.allInvestments[$i].ownership.owners[1].percentage
              ) {
                $scope.grossInvestmentIncome[1] +=
                  $scope.allInvestments[$i].yearlyIncome *
                  ($scope.allInvestments[$i].ownership.owners[1].percentage /
                    100);
              }
            }
          }
        }
      };

      $scope.calculateAllExpenses = function () {
        /*All Expenses*/
        var bills = 0;
        var expenses = 0;

        if ($scope.clientSchema.expenses) {
          $scope.allExpenses = $scope.clientSchema.expenses.expenses;
        } else {
          $scope.allExpenses = [];
        }
        /*Iterate through all expenses sorting into tier 4 category*/
        for (var i = 0; i < $scope.allExpenses.length; i++) {
          //Bills
          if (
            $scope.allExpenses[i].tier1 ||
            ($scope.allExpenses[i].customExpense == true &&
              $scope.allExpenses[i].tier4 == "Bills") ||
            ($scope.allExpenses[i].ownership &&
              $scope.allExpenses[i].tier4 == "Bills")
          ) {
            {
              switch ($scope.allExpenses[i].frequency) {
                case "Weekly":
                  bills += $scope.allExpenses[i].amount * 4;
                  break;
                case "Fortnightly":
                  bills += $scope.allExpenses[i].amount * 2;
                  break;
                case "Monthly":
                  bills += $scope.allExpenses[i].amount;
                  break;
                case "Every 2 months":
                  bills += $scope.allExpenses[i].amount / 2;
                  break;
                case "Every 3 months":
                  bills += $scope.allExpenses[i].amount / 3;
                  break;
                case "Every 6 months":
                  bills += $scope.allExpenses[i].amount / 6;
                  break;
                case "Yearly":
                  bills += $scope.allExpenses[i].amount / 12;
                  break;
              }
            }

            /*Calculate All Tax Deductable Expenses*/
            if ($scope.allExpenses[i].taxDeductible == true) {
              var ownerIndex = 0;
              if (
                $scope.allExpenses[i].ownership.ownershipType == "Sole" ||
                !$scope.allExpenses[i].ownership ||
                ($scope.allExpenses[i].ownership.ownershipType == "Joint" &&
                  $scope.client2FullName == "Your Partner")
              ) {
                //Determine Owner of Expense
                switch ($scope.allExpenses[i].ownership.owners[0].owner) {
                  case $scope.client1Id:
                    ownerIndex = 0;
                    break;
                  case $scope.client2Id:
                    ownerIndex = 1;
                    break;
                  default:
                    ownerIndex = 0;
                    break;
                }
                if ($scope.client2FullName == "Your Partner") {
                  ownerIndex = 0;
                }
                switch ($scope.allExpenses[i].frequency) {
                  case "Weekly":
                    $scope.otherTaxDeductions[ownerIndex] +=
                      $scope.allExpenses[i].amount * 4;
                    break;
                  case "Fortnightly":
                    $scope.otherTaxDeductions[ownerIndex] +=
                      $scope.allExpenses[i].amount * 2;
                    break;
                  case "Monthly":
                    $scope.otherTaxDeductions[ownerIndex] +=
                      $scope.allExpenses[i].amount;
                    break;
                  case "Every 2 months":
                    $scope.otherTaxDeductions[ownerIndex] +=
                      $scope.allExpenses[i].amount / 2;
                    break;
                  case "Every 3 months":
                    $scope.otherTaxDeductions[ownerIndex] +=
                      $scope.allExpenses[i].amount / 3;
                    break;
                  case "Every 6 months":
                    $scope.otherTaxDeductions[ownerIndex] +=
                      $scope.allExpenses[i].amount / 6;
                    break;
                  case "Yearly":
                    $scope.otherTaxDeductions[ownerIndex] +=
                      $scope.allExpenses[i].amount / 12;
                    break;
                }
              } else if (
                $scope.allExpenses[i].ownership.ownershipType == "Joint"
              ) {
                switch ($scope.allExpenses[i].frequency) {
                  case "Weekly":
                    $scope.otherTaxDeductions[0] +=
                      $scope.allExpenses[i].amount * 4 * 0.5;
                    $scope.otherTaxDeductions[1] +=
                      $scope.allExpenses[i].amount * 4 * 0.5;
                    break;
                  case "Fortnightly":
                    $scope.otherTaxDeductions[0] +=
                      $scope.allExpenses[i].amount * 2 * 0.5;
                    $scope.otherTaxDeductions[1] +=
                      $scope.allExpenses[i].amount * 2 * 0.5;
                    break;
                  case "Monthly":
                    $scope.otherTaxDeductions[0] +=
                      $scope.allExpenses[i].amount * 0.5;
                    $scope.otherTaxDeductions[1] +=
                      $scope.allExpenses[i].amount * 0.5;
                    break;
                  case "Every 2 months":
                    $scope.otherTaxDeductions[0] +=
                      ($scope.allExpenses[i].amount / 2) * 0.5;
                    $scope.otherTaxDeductions[1] +=
                      ($scope.allExpenses[i].amount / 2) * 0.5;
                    break;
                  case "Every 3 months":
                    $scope.otherTaxDeductions[0] +=
                      ($scope.allExpenses[i].amount / 3) * 0.5;
                    $scope.otherTaxDeductions[1] +=
                      ($scope.allExpenses[i].amount / 3) * 0.5;
                    break;
                  case "Every 6 months":
                    $scope.otherTaxDeductions[0] +=
                      ($scope.allExpenses[i].amount / 6) * 0.5;
                    $scope.otherTaxDeductions[1] +=
                      ($scope.allExpenses[i].amount / 6) * 0.5;
                    break;
                  case "Yearly":
                    $scope.otherTaxDeductions[0] +=
                      ($scope.allExpenses[i].amount / 12) * 0.5;
                    $scope.otherTaxDeductions[1] +=
                      ($scope.allExpenses[i].amount / 12) * 0.5;
                    break;
                }
              }
            }
          }

          //Expenses
          else {
            switch ($scope.allExpenses[i].frequency) {
              case "Weekly":
                expenses += $scope.allExpenses[i].amount * 4;
                break;
              case "Fortnightly":
                expenses += $scope.allExpenses[i].amount * 2;
                break;
              case "Monthly":
                expenses += $scope.allExpenses[i].amount;
                break;
              case "Every 2 months":
                expenses += $scope.allExpenses[i].amount / 2;
                break;
              case "Every 3 months":
                expenses += $scope.allExpenses[i].amount / 3;
                break;
              case "Every 6 months":
                expenses += $scope.allExpenses[i].amount / 6;
                break;
              case "Yearly":
                expenses += $scope.allExpenses[i].amount / 12;
                break;
            }
          }
        }

        bills = Math.round(bills * 100) / 100;
        expenses = Math.round(expenses * 100) / 100;

        $scope.totalBillPayments[0] = bills;
        $scope.totalBillPayments[1] = bills * 12;

        $scope.totalSpending[0] = expenses;
        $scope.totalSpending[1] = expenses * 12;

        $scope.otherTaxDeductions[0] *= 12;
        $scope.otherTaxDeductions[1] *= 12;
      };

      $scope.listLoanProperty = function (loan) {
        var loanProperties = [];

        if (loan.properties) {
          for (var index = 0; index < loan.properties.length; index++) {
            var propertyId = loan.properties[index];

            $scope.properties.forEach(function (property) {
              if (property._id === propertyId) {
                loanProperties.push(property);
              }
            }, this);
          }

          if (loanProperties.length > 0) {
            var contains = false;

            $scope.loansPropertyList.forEach(function (propertyList) {
              if (
                _.isEqual(
                  _.map(_.sortBy(propertyList, "_id"), _.values),
                  _.map(_.sortBy(loanProperties, "_id"), _.values)
                )
              ) {
                contains = true;
              }
            }, this);

            if (contains === false) {
              $scope.loansPropertyList.push(loanProperties);
            }
          }
        }
      };
      $scope.filterPropertyLoans = function (propertyList) {
        return function (loan) {
          var propertyListIds = [];
          // loop properties to get propertyListValue
          propertyList.forEach(function (property) {
            // form propertylist ids
            propertyListIds.push(property._id);
          }, this);

          if (loan && loan.type) {
            if (
              loan.type.indexOf("Investment Loan") != -1 ||
              loan.type.indexOf("Home Loan") != -1
            ) {
              if (loan.properties) {
                if (_.isEqual(loan.properties.sort(), propertyListIds.sort())) {
                  return true;
                }
              }
            }
          }

          return false;
        };
      };

      $scope.calculateAllLiabilities = function () {
        var loanRepayments = 0;

        $scope.allLoans = [];
        if (
          $scope.clientSchema.borrowings &&
          $scope.clientSchema.borrowings.borrowing
        ) {
          $scope.allLoans = $scope.clientSchema.borrowings.borrowing.filter(
            (_borrowing) => !_borrowing.isClosed
          );
        }

        for (var i = 0; i < $scope.allLoans.length; i++) {
          $scope.totalLiabilities += $scope.allLoans[i].outstanding;

          switch ($scope.allLoans[i].type) {
            case "Credit Card":
              $scope.totalOutstandingCreditCardDebit +=
                $scope.allLoans[i].outstanding;
              break;
          }

          /*Calculate Repayments*/
          switch ($scope.allLoans[i].repaymentFreq) {
            case "Weekly":
              loanRepayments += parseInt($scope.allLoans[i].repayment, 10) * 4;
              break;
            case "Fortnightly":
              loanRepayments += parseInt($scope.allLoans[i].repayment, 10) * 2;
              break;
            case "Monthly":
              loanRepayments += parseInt($scope.allLoans[i].repayment, 10);
              break;
            case "Bi-Monthly":
              loanRepayments += parseInt($scope.allLoans[i].repayment, 10) / 2;
              break;
            case "Quarterly":
              loanRepayments += parseInt($scope.allLoans[i].repayment, 10) / 4;
              break;
            case "Yearly":
              loanRepayments += parseInt($scope.allLoans[i].repayment, 10) / 12;
              break;
          }
        }

        $scope.totalLoanPayments[0] = loanRepayments;
        $scope.totalLoanPayments[1] = loanRepayments * 12;
      };

      $scope.totalPropertyValue = function () {
        for (var i = 0; i < $scope.properties.length; i++) {
          $scope.totalPropertiesValue += $scope.properties[i].currentValue;
        }
      };

      $scope.calculatePortfolioValue = function () {
        $scope.propertyLVR = [];
        $scope.propertyAVL90 = [];
        $scope.propertyCurrentIO = [];
        $scope.propertyTotalRepayment = [];
        $scope.propertyTotalBalance = [];
        $scope.totalCurrentIO = 0;

        var totalLoanOutstanding = 0;
        var totalRepayments = 0;

        for (var index in $scope.loans) {
          $scope.listLoanProperty($scope.loans[index]);
        }

        // get properties without loan
        for (var i in $scope.properties) {
          let hasLoan = false;

          for (var j in $scope.loansPropertyList) {
            for (var k in $scope.loansPropertyList[j]) {
              if (
                $scope.properties[i]._id == $scope.loansPropertyList[j][k]._id
              ) {
                hasLoan = true;
                break;
              }
            }
          }
          if (!hasLoan) {
            $scope.nonLoanPropertyList.push($scope.properties[i]);
          }
        }

        for (var index in $scope.loansPropertyList) {
          computePropertyListLoanValues(index, $scope.loansPropertyList[index]);
        }
        for (var index in $scope.loans) {
          if ($scope.loans[index].type) {
            if (
              $scope.loans[index].type.indexOf("Investment Loan") != -1 ||
              $scope.loans[index].type.indexOf("Home Loan") != -1
            ) {
              totalLoanOutstanding += $scope.loans[index].outstanding;
              $scope.loansTotalLimit += $scope.loans[index].limit;
              totalRepayments += $scope.loans[index].repayment;
            }
          }
        }

        $scope.loansBalance = totalLoanOutstanding;
        $scope.loansTotalBalance = totalLoanOutstanding;
        $scope.loansTotalRepayment = totalRepayments;
        $scope.avlEquityAtNinety =
          $scope.totalPropertiesValue * 0.9 - totalLoanOutstanding;
        $scope.currentLvr = Math.round(
          (totalLoanOutstanding / $scope.totalPropertiesValue) * 100
        );
      };

      var computePropertyListLoanValues = function (index, propertyList) {
        var propertyListValue = 0;
        var loanOutstanding = 0;
        var loanRepayment = 0;
        let loanTotalIO = 0;
        var propertyListIds = [];

        // loop properties to get propertyListValue
        propertyList.forEach(function (property) {
          // form propertylist ids
          propertyListIds.push(property._id);
          propertyListValue += property.currentValue;
        }, this);

        // loop all loans
        $scope.loans.forEach(function (loan) {
          if (loan.properties) {
            // check if loan is related to property list
            if (_.isEqual(loan.properties.sort(), propertyListIds.sort())) {
              loanOutstanding += loan.outstanding;
              loanRepayment += loan.repayment;
              loanTotalIO +=
                (loan.outstanding * (loan.interestRate / 100)) / 12;
            }
          }
        }, this);

        // compute property's lvr, io and avl 90
        $scope.propertyLVR[index] = (loanOutstanding / propertyListValue) * 100;
        $scope.propertyCurrentIO[index] = Math.round(loanTotalIO);
        $scope.totalCurrentIO += Math.round(loanTotalIO);
        $scope.propertyAVL90[index] = propertyListValue * 0.9 - loanOutstanding;
        $scope.propertyTotalRepayment[index] = loanRepayment;
        $scope.propertyTotalBalance[index] = loanOutstanding;
      };
      var computerPropertyTotalDeduction = function (property) {
        var totalDeduction = 0;

        // calculate property cost
        if (property.bodyCorpCosts) {
          totalDeduction += property.bodyCorpCosts;
        }
        if (property.insuranceCosts) {
          totalDeduction += property.insuranceCosts;
        }
        if (property.landTaxCosts) {
          totalDeduction += property.landTaxCosts;
        }
        if (property.maintenanceCost) {
          totalDeduction += property.maintenanceCost;
        }
        if (property.managementFees) {
          totalDeduction += property.managementFees;
        }
        if (property.waterRates) {
          totalDeduction += property.waterRates;
        }
        if (property.councilRates) {
          totalDeduction += property.councilRates;
        }

        return totalDeduction;
      };

      $scope.computePropertyDeduction = function () {
        /*Calculating Property Deduction Value*/
        for (
          var $i = 0;
          $i < $scope.properties.length && $scope.properties[$i].ownership;
          $i++
        ) {
          if ($scope.properties[$i].ownership.ownershipType == "Sole") {
            if ($scope.properties[$i].ownership.owners.length == 1) {
              switch ($scope.properties[$i].ownership.owners[0].owner) {
                case $scope.client1Id:
                  $scope.investmentPropertyDeductions[0] +=
                    computerPropertyTotalDeduction($scope.properties[$i]) *
                    ($scope.properties[$i].ownership.owners[0].percentage /
                      100);

                  break;

                case $scope.client2Id:
                  $scope.investmentPropertyDeductions[1] +=
                    computerPropertyTotalDeduction($scope.properties[$i]) *
                    ($scope.properties[$i].ownership.owners[0].percentage /
                      100);
                  break;
              }
            }
          } else if ($scope.properties[$i].ownership.ownershipType == "Joint") {
            $scope.investmentPropertyDeductions[0] +=
              computerPropertyTotalDeduction($scope.properties[$i]) *
              ($scope.properties[$i].ownership.owners[0].percentage / 100);
            if (
              $scope.properties[$i].ownership.owners[1] &&
              $scope.properties[$i].ownership.owners[1].percentage
            ) {
              $scope.investmentPropertyDeductions[1] +=
                computerPropertyTotalDeduction($scope.properties[$i]) *
                ($scope.properties[$i].ownership.owners[1].percentage / 100);
            }
          } else {
            if ($scope.properties[$i].ownership.owners.length == 1) {
              if ($scope.properties[$i].ownership.owners[0].percentage) {
                switch ($scope.properties[$i].ownership.owners[0].owner) {
                  case $scope.client1Id:
                    $scope.investmentPropertyDeductions[0] +=
                      computerPropertyTotalDeduction($scope.properties[$i]) *
                      ($scope.properties[$i].ownership.owners[0].percentage /
                        100);
                    break;
                  case $scope.client2Id:
                    $scope.investmentPropertyDeductions[1] +=
                      computerPropertyTotalDeduction($scope.properties[$i]) *
                      ($scope.properties[$i].ownership.owners[0].percentage /
                        100);
                    break;
                }
              }
            } else if ($scope.properties[$i].ownership.owners.length == 2) {
              if ($scope.properties[$i].ownership.owners[0].percentage) {
                switch ($scope.properties[$i].ownership.owners[0].owner) {
                  case $scope.client1Id:
                    $scope.investmentPropertyDeductions[0] +=
                      computerPropertyTotalDeduction($scope.properties[$i]) *
                      ($scope.properties[$i].ownership.owners[0].percentage /
                        100);
                    break;
                  case $scope.client2Id:
                    $scope.investmentPropertyDeductions[1] +=
                      computerPropertyTotalDeduction($scope.properties[$i]) *
                      ($scope.properties[$i].ownership.owners[0].percentage /
                        100);
                    break;
                }
              }
              if ($scope.properties[$i].ownership.owners[1].percentage) {
                switch ($scope.properties[$i].ownership.owners[1].owner) {
                  case $scope.client1Id:
                    $scope.investmentPropertyDeductions[0] +=
                      computerPropertyTotalDeduction($scope.properties[$i]) *
                      ($scope.properties[$i].ownership.owners[1].percentage /
                        100);
                    break;
                  case $scope.client2Id:
                    $scope.investmentPropertyDeductions[1] +=
                      computerPropertyTotalDeduction($scope.properties[$i]) *
                      ($scope.properties[$i].ownership.owners[1].percentage /
                        100);
                    break;
                }
              }
            }
          }
        }

        if (hasLoanPropertyDeduction()) {
          $scope.computeLoanPropertyDeduction();
        }
      };

      var hasLoanPropertyDeduction = function () {
        return (
          $scope.grossInvestmentIncome[0] > 0 ||
          $scope.grossInvestmentIncome[1] > 0 ||
          $scope.grossRentalIncome[0] > 0 ||
          $scope.grossRentalIncome[1] > 0
        );
      };
      $scope.computeLoanPropertyDeduction = function () {
        /*Calculating Property Deduction Value*/
        for (var $i = 0; $i < $scope.allLoans.length; $i++) {
          if ($scope.allLoans[$i].type) {
            if (
              $scope.allLoans[$i].type.indexOf("Investment Loan") != -1 &&
              $scope.allLoans[$i].primaryPurpose.indexOf("Real Estate") != -1
            ) {
              if ($scope.allLoans[$i].borrower) {
                if ($scope.allLoans[$i].borrower.ownershipType == "Sole") {
                  if ($scope.allLoans[$i].borrower.owners.length == 1) {
                    switch ($scope.allLoans[$i].borrower.owners[0].owner) {
                      case $scope.client1Id:
                        $scope.investmentPropertyDeductions[0] +=
                          computerLoanPropertyDeduction($scope.allLoans[$i]) *
                          ($scope.allLoans[$i].borrower.owners[0].percentage /
                            100);
                        break;

                      case $scope.client2Id:
                        $scope.investmentPropertyDeductions[1] +=
                          computerLoanPropertyDeduction($scope.allLoans[$i]) *
                          ($scope.allLoans[$i].borrower.owners[0].percentage /
                            100);
                        break;
                    }
                  }
                } else if (
                  $scope.allLoans[$i].borrower.ownershipType == "Joint"
                ) {
                  $scope.investmentPropertyDeductions[0] +=
                    computerLoanPropertyDeduction($scope.allLoans[$i]) *
                    ($scope.allLoans[$i].borrower.owners[0].percentage / 100);

                  $scope.investmentPropertyDeductions[1] +=
                    computerLoanPropertyDeduction($scope.allLoans[$i]) *
                    ($scope.allLoans[$i].borrower.owners[1].percentage / 100);
                } else {
                  if ($scope.allLoans[$i].borrower.owners.length == 1) {
                    if ($scope.allLoans[$i].borrower.owners[0].percentage) {
                      switch ($scope.allLoans[$i].borrower.owners[0].owner) {
                        case $scope.client1Id:
                          $scope.investmentPropertyDeductions[0] +=
                            computerLoanPropertyDeduction($scope.allLoans[$i]) *
                            ($scope.allLoans[$i].borrower.owners[0].percentage /
                              100);
                          break;
                        case $scope.client2Id:
                          $scope.investmentPropertyDeductions[1] +=
                            computerLoanPropertyDeduction($scope.allLoans[$i]) *
                            ($scope.allLoans[$i].borrower.owners[0].percentage /
                              100);
                          break;
                      }
                    }
                  } else if ($scope.allLoans[$i].borrower.owners.length == 2) {
                    if ($scope.allLoans[$i].borrower.owners[0].percentage) {
                      switch ($scope.allLoans[$i].borrower.owners[0].owner) {
                        case $scope.client1Id:
                          $scope.investmentPropertyDeductions[0] +=
                            computerLoanPropertyDeduction($scope.allLoans[$i]) *
                            ($scope.allLoans[$i].borrower.owners[0].percentage /
                              100);
                          break;
                        case $scope.client2Id:
                          $scope.investmentPropertyDeductions[1] +=
                            computerLoanPropertyDeduction($scope.allLoans[$i]) *
                            ($scope.allLoans[$i].borrower.owners[0].percentage /
                              100);
                          break;
                      }
                    }
                    if ($scope.allLoans[$i].borrower.owners[1].percentage) {
                      switch ($scope.allLoans[$i].borrower.owners[1].owner) {
                        case $scope.client1Id:
                          $scope.investmentPropertyDeductions[0] +=
                            computerLoanPropertyDeduction($scope.allLoans[$i]) *
                            ($scope.allLoans[$i].borrower.owners[1].percentage /
                              100);
                          break;
                        case $scope.client2Id:
                          $scope.investmentPropertyDeductions[1] +=
                            computerLoanPropertyDeduction($scope.allLoans[$i]) *
                            ($scope.allLoans[$i].borrower.owners[1].percentage /
                              100);
                          break;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      };
      var computerLoanPropertyDeduction = function (loan) {
        if (loan) {
          if (loan.interestRate) {
            if (loan.outstanding) {
              return (loan.interestRate / 100) * loan.outstanding;
            }
          }
        }
        return 0;
      };

      $scope.initCalculation = function () {
        $scope.assets = $scope.clientSchema.assets;
        if ($scope.assets) {
          $scope.properties = $scope.clientSchema.assets.properties;
          $scope.allSuper = $scope.assets.superFunds;
          $scope.bankAccounts = $scope.assets.bankAccounts;
          $scope.investments = $scope.assets.investments;
        } else {
          $scope.properties = [];
          $scope.allSuper = [];
          $scope.bankAccounts = [];
          $scope.investments = [];
        }
        $scope.clients = $scope.clientSchema.personalInfo;

        /*Set full names for ownership*/
        if ($scope.clients) {
          if ($scope.clients.client1) {
            $scope.client1FullName =
              $scope.clients.client1.fName + " " + $scope.clients.client1.lName;
            $scope.client1Id = $scope.clients.client1._id;
          }

          if ($scope.client1FullName == " ") {
            $scope.client1FullName = "You";
          }

          if ($scope.clients.client2) {
            $scope.client2FullName =
              $scope.clients.client2.fName + " " + $scope.clients.client2.lName;
            if ($scope.client2FullName == " ") {
              $scope.client2FullName = "Your Partner";
            }
            $scope.client2Id = $scope.clients.client2._id;
          }
        }

        $scope.totalPropertyValue();
        $scope.calculatePortfolioValue();
        $scope.calculateIncome();
        $scope.calculateAllInvestmentAssets();
        $scope.calculateAllExpenses();
        $scope.calculateAllLiabilities();
        $scope.calculateAllNonInvestmentAssets();
        $scope.calculatePropertyInvestmentCost();
        $scope.computePropertyDeduction();

        /*Total Income Summation*/
        $scope.totalIncome[0] =
          $scope.grossPaygIncome[0] +
          $scope.superannuationSalarySacrifice[0] +
          $scope.grossBusinessIncome[0] +
          $scope.grossSoleTraderIncome[0] +
          $scope.grossOtherIncome[0] +
          $scope.grossRentalIncome[0] +
          $scope.grossInvestmentIncome[0];
        $scope.totalIncome[1] =
          $scope.grossPaygIncome[1] +
          $scope.superannuationSalarySacrifice[1] +
          $scope.grossBusinessIncome[1] +
          $scope.grossSoleTraderIncome[1] +
          $scope.grossOtherIncome[1] +
          $scope.grossRentalIncome[1] +
          $scope.grossInvestmentIncome[1];

        $scope.totalIncomeVal = Math.round(
          ($scope.totalIncome[0] + $scope.totalIncome[1]) / 12
        );
        $scope.data = [
          Math.round(
            ($scope.grossPaygIncome[0] +
              $scope.superannuationSalarySacrifice[0] +
              $scope.grossPaygIncome[1] +
              $scope.superannuationSalarySacrifice[1]) /
              12
          ),
          Math.round(
            ($scope.grossOtherIncome[0] + $scope.grossOtherIncome[1]) / 12
          ),
          Math.round(
            ($scope.grossBusinessIncome[0] + $scope.grossBusinessIncome[1]) / 12
          ),
          Math.round(
            ($scope.grossSoleTraderIncome[0] +
              $scope.grossSoleTraderIncome[1]) /
              12
          ),
          Math.round(
            ($scope.grossRentalIncome[0] + $scope.grossRentalIncome[1]) / 12
          ),
          Math.round(
            ($scope.grossInvestmentIncome[0] +
              $scope.grossInvestmentIncome[1]) /
              12
          ),
        ];
        $scope.labels = [
          "Gross PAYG Income",
          "Gross Other Income",
          "Gross Business Income",
          "Gross Self Employed Income",
          "Gross Rental Income",
          "Gross Investment Income",
        ];
        $scope.colors = [
          "#A3D12D",
          "#8CC230",
          "#75B334",
          "#5DA437",
          "#46953B",
          "#2F863E",
        ];

        /*Total Expenses Summation*/
        $scope.totalExpenditure[0] =
          $scope.totalBillPayments[0] +
          $scope.totalSpending[0] +
          $scope.totalInvestmentPropertyCosts[0] +
          $scope.totalLoanPayments[0] +
          $scope.afterTaxSuperContributions[0];
        $scope.totalExpenditure[1] =
          $scope.totalBillPayments[1] +
          $scope.totalSpending[1] +
          $scope.totalInvestmentPropertyCosts[1] +
          $scope.totalLoanPayments[1] +
          $scope.afterTaxSuperContributions[1];
        $scope.totalExpenses = $scope.totalExpenditure[0];

        /*Expenses Chart*/
        $scope.data2 = [
          $scope.totalBillPayments[0],
          $scope.totalSpending[0],
          $scope.totalInvestmentPropertyCosts[0],
          $scope.totalLoanPayments[0],
          $scope.afterTaxSuperContributions[0],
        ];
        $scope.labels2 = [
          "Bills",
          "Spending",
          "Investment Property Costs",
          "Loan Payments",
          "After Tax Super Contributions",
        ];
        $scope.colors2 = [
          "#f22525",
          "#E02323",
          "#CD2121",
          "#b21e1e",
          "#b21e1f",
        ];

        /* Taxable Income*/
        $scope.taxableIncome[0] =
          $scope.totalIncome[0] -
          $scope.otherTaxDeductions[0] -
          $scope.investmentPropertyDeductions[0];
        $scope.taxableIncome[1] =
          $scope.totalIncome[1] -
          $scope.otherTaxDeductions[1] -
          $scope.investmentPropertyDeductions[1];

        /* Estimated Tax Paid */
        $scope.estimatedTaxPaid[0] =
          EmpowerWealthHelper.computeEstimatedTaxPaid($scope.taxableIncome[0]);
        $scope.estimatedTaxPaid[1] =
          EmpowerWealthHelper.computeEstimatedTaxPaid($scope.taxableIncome[1]);

        /* Total Net */
        $scope.totalNetIncome[0] =
          $scope.totalIncome[0] +
          $scope.nonTaxableIncome[0] -
          $scope.estimatedTaxPaid[0];
        $scope.totalNetIncome[1] =
          $scope.totalIncome[1] +
          $scope.nonTaxableIncome[1] -
          $scope.estimatedTaxPaid[1];

        /*Income overall*/
        $scope.incomeOverall[1] =
          $scope.totalNetIncome[0] + $scope.totalNetIncome[1];
        $scope.incomeOverall[0] = parseInt(
          ($scope.totalNetIncome[0] + $scope.totalNetIncome[1]) / 12
        );

        /* Expenditure overall */
        $scope.expenditureOverall[1] = $scope.totalExpenditure[1];
        $scope.expenditureOverall[0] = parseInt(
          $scope.expenditureOverall[1] / 12
        );
        $scope.surplus = [
          $scope.incomeOverall[0] - $scope.expenditureOverall[0],
          $scope.incomeOverall[1] - $scope.expenditureOverall[1],
        ];

        //dashboard tiles
        $scope.householdIncome = $scope.totalIncome[0] + $scope.totalIncome[1];
        $scope.taxPayable =
          $scope.estimatedTaxPaid[0] + $scope.estimatedTaxPaid[1];
        $scope.surplusCashflow = $scope.surplus[0] + $scope.surplus[1];
        $scope.totalCash = $scope.bankAccountsValue;
        $scope.availableEquity =
          $scope.totalPropertiesValue - $scope.totalPropertyLiabilities;
        //$scope.investmentNestegg = $scope.superFundsValue;
      };
      DataM.getSummary()
        .then(function (data) {
          $scope.showClientSpinner = false;
          $scope.clientSchema = data.clientSummary;
          $scope.loans = [];
          if (data.clientSummary.borrowings) {
            $scope.loans = data.clientSummary.borrowings.borrowing.filter(
              (_borrowing) => !_borrowing.isClosed
            );
          }
          $scope.clients = $scope.clientSchema.personalInfo;

          /*Set full names for ownership*/
          $scope.client1FullName =
            $scope.clients.client1.fName + " " + $scope.clients.client1.lName;
          $scope.client1Id = $scope.clients.client1._id;

          if ($scope.client1FullName == " ") {
            $scope.client1FullName = "You";
          }

          if ($scope.clients.client2) {
            $scope.client2FullName =
              $scope.clients.client2.fName + " " + $scope.clients.client2.lName;
            if ($scope.client2FullName == " ") {
              $scope.client2FullName = "Your Partner";
            }
            $scope.client2Id = $scope.clients.client2._id;
          }
          $scope.initCalculation();
        })
        .catch(function (err) {
          console.log("Retrieve error", err);
          $scope.showRetrieveError = true;
        });

      $scope.save = function (location) {
        $scope.showClientSpinner = true;
        $scope.loadingMessage = "Saving Client Information...";
        $scope.clientSchema.surveyComplete = true;
        var formData = {
          clientSchema: $scope.clientSchema,
        };
        //SAVE TO DB
        DataM.saveSummary(formData)
          .then(function (data) {
            if (data._message === "Client validation failed") {
              var errorMessage = "";
              const formattedMessage = DataHelper.formatMongoError(data);
              $scope.showClientSpinner = false;

              $scope.didNextSave = false;
              alert(formattedMessage);
            } else {
              $state.go(location, {
                clientId: $scope.clientId,
              });
            }
          })
          .catch(function (err) {
            console.log("Save error");
            $scope.didNextSave = false;
            $scope.showClientSpinner = false;
          });
      };

      $scope.next = function () {
        $scope.save("dashboard");
      };
      $scope.back = function () {
        $state.go("fact-find9");
      };
      $scope.quit = function () {
        $state.go("dashboard");
      };
    }
  )

  /* Datepicker directive used in the portal */

  .directive("datepicker", function () {
    return {
      restrict: "A",
      require: "ngModel",
      link: function (scope, elem, attrs, ngModelCtrl) {
        var updateModel = function (dateText) {
          scope.$apply(function () {
            ngModelCtrl.$setViewValue(moment.utc(dateText, ["DD/MM/YYYY"]));
          });
        };
        var options = {
          dateFormat: "dd/mm/yy",
          onSelect: function (dateText) {
            updateModel(dateText);
          },
        };
        $(elem)
          .bind("blur", function () {
            if (
              this.value !== "" &&
              !moment.utc(this.value, ["DD/MM/YYYY"]).isValid()
            ) {
              alert("Please fix invalid date");
              this.value = "";
              this.focus();
            }
          })
          .datepicker(options);
      },
    };
  })
  .directive("addressBlock", [
    "$compile",
    function ($compile) {
      return {
        restrict: "E",
        scope: {
          address: "=address",
          addressType: "=addressType",
          empowerData: "=empowerData",
          allData: "=allData",
          sourceId: "=sourceId",
          validationData: "=validationData",
          isMBAdmin: "=isMBAdmin",
          keydownHandler: "&",
          switchtomanualHandler: "&",
          fieldonchangeHandler: "&",
          isInvalidForFlexHandler: "&",
          isInvalidForFlexCheckAllHandler: "&",
        },
        templateUrl: "app/factFind/common/address.html",
        link: function (scope, elm, attrs) {
          scope.callKeyDownHandler = function () {
            scope.keydownHandler()(
              scope.sourceId || scope.allData.$$hashKey,
              scope
            );
          };
          scope.callSwitchToManualHandler = function (isManual) {
            scope.switchtomanualHandler()(scope.sourceId, isManual, scope);
          };
          scope.callupdateAddressField = function (address) {
            scope.fieldonchangeHandler()(address, scope.addressType);
          };
          scope.callIsInvalidForFlexHandler = function (str) {
            return scope.isInvalidForFlexHandler()(str);
          };
          scope.callIsInvalidForFlexCheckAllHandler = function (arr) {
            return scope.isInvalidForFlexCheckAllHandler()(arr);
          };
        },
      };
    },
  ])
  .directive("billsPropertiesBlock", [
    "$compile",
    function ($compile) {
      return {
        restrict: "E",
        scope: {
          title: "@",
          datas: "=",
          empowerData: "@",
        },
        controller: "factfind-6-controller",
        templateUrl: "app/factFind/common/bills-properties.html",
      };
    },
  ])
  .directive("trustDetails", [
    "$compile",
    function ($compile) {
      return {
        restrict: "E",
        scope: {
          trust: "=trust",
          addDirector: "=addDirector",
          addBeneficiary: "=addBeneficiary",
          deleteBusinessItem: "=deleteBusinessItem",
        },
        templateUrl: "app/factFind/income/trust-details.html",
      };
    },
  ])
  .directive("companyDetails", [
    "$compile",
    function ($compile) {
      return {
        restrict: "E",
        scope: {
          company: "=company",
          addDirector: "=addDirector",
          addShareholder: "=addShareholder",
          deleteBusinessItem: "=deleteBusinessItem",
        },
        templateUrl: "app/factFind/income/company-details.html",
      };
    },
  ])

  /*Angular Service that contains all commonly used data. Can be accessed from all controllers*/
  .service("empowerDataService", function () {
    /*Source: Australia Post*/
    this.topStreetTypes = [
      "Avenue",
      "Court",
      "Crescent",
      "Drive",
      "Parade",
      "Road",
      "Street",
    ];
    this.streetTypes = [
      "Access",
      "Alley",
      "Alleyway",
      "Amble",
      "Anchorage",
      "Approach",
      "Arcade",
      "Artery",
      "Avenue",
      "Basin",
      "Beach",
      "Bend",
      "Block",
      "Boulevard",
      "Brace",
      "Brae",
      "Break",
      "Bridge",
      "Broadway",
      "Brow",
      "Bypass",
      "Byway",
      "Causeway",
      "Centre",
      "Centreway",
      "Chase",
      "Circle",
      "Circlet",
      "Circuit",
      "Circus",
      "Close",
      "Colonnade",
      "Common",
      "Concourse",
      "Copse",
      "Corner",
      "Corso",
      "Court",
      "Courtyard",
      "Cove",
      "Crescent",
      "Crest",
      "Cross",
      "Crossing",
      "Crossroad",
      "Crossway",
      "Cruiseway",
      "Cul-De-Sac",
      "Cutting",
      "Dale",
      "Dell",
      "Deviation",
      "Dip",
      "Distributor",
      "Drive",
      "Driveway",
      "Edge",
      "Elbow",
      "End",
      "Entrance",
      "Esplanade",
      "Estate",
      "Expressway",
      "Extension",
      "Fairway",
      "Fire Track",
      "Firetrail",
      "Flat",
      "Follow",
      "Footway",
      "Foreshore",
      "Formation",
      "Freeway",
      "Front",
      "Frontage",
      "Gap",
      "Garden",
      "Gate",
      "Gardens",
      "Gates",
      "Glade",
      "Glen",
      "Grange",
      "Green",
      "Ground",
      "Grove",
      "Gully",
      "Heights",
      "Highroad",
      "Highway",
      "Hill",
      "Interchange",
      "Intersection",
      "Junction",
      "Key",
      "Landing",
      "Lane",
      "Laneway",
      "Lees",
      "Line",
      "Link",
      "Little",
      "Lookout",
      "Loop",
      "Lower",
      "Mall",
      "Meander",
      "Mew",
      "Mews",
      "Motorway",
      "Mount",
      "Nook",
      "Outlook",
      "Parade",
      "Park",
      "Parklands",
      "Parkway",
      "Part",
      "Pass",
      "Passage",
      "Path",
      "Pathway",
      "Piazza",
      "Place",
      "Plateau",
      "Plaza",
      "Pocket",
      "Point",
      "Port",
      "Promenade",
      "Quad",
      "Quadrangle",
      "Quadrant",
      "Quay",
      "Quays",
      "Ramble",
      "Ramp",
      "Range",
      "Reach",
      "Reserve",
      "Rest",
      "Retreat",
      "Ride",
      "Ridge",
      "Ridgeway",
      "Right of way",
      "Ring",
      "Rise",
      "River",
      "Riverway",
      "Riviera",
      "Road",
      "Roads",
      "Roadside",
      "Roadway",
      "Ronde",
      "Rosebowl",
      "Rotary",
      "Round",
      "Route",
      "Row",
      "Rue",
      "Run",
      "Service way",
      "Siding",
      "Slope",
      "Sound",
      "Spur",
      "Square",
      "Stairs",
      "State highway",
      "Steps",
      "Strand",
      "Street",
      "Strip",
      "Subway",
      "Tarn",
      "Terrace",
      "Thoroughfare",
      "Tollway",
      "Top",
      "Tor",
      "Towers",
      "Track",
      "Trail",
      "Trailer",
      "Triangle",
      "Trunkway",
      "Turn",
      "Underpass",
      "Upper",
      "Vale",
      "Viaduct",
      "View",
      "Villas",
      "Vista",
      "Wade",
      "Walk",
      "Walkway",
      "Way",
      "Wharf",
      "Wynd",
      "Yard",
    ];

    this.allCountries = [
      "Australia",
      "Afghanistan",
      "Albania",
      "Algeria",
      "American Samoa",
      "Andorra",
      "Angola",
      "Anguilla",
      "Antarctica",
      "Antigua And Barbuda",
      "Argentina",
      "Armenia",
      "Aruba",
      "Austria",
      "Azerbaijan",
      "Bahamas",
      "Bahrain",
      "Bangladesh",
      "Barbados",
      "Belarus",
      "Belgium",
      "Belize",
      "Benin",
      "Bermuda",
      "Bhutan",
      "Bolivia",
      "Bosnia And Herzegovina",
      "Botswana",
      "Bouvet Island",
      "Brazil",
      "British Indian Ocean Territory",
      "Brunei Darussalam",
      "Bulgaria",
      "Burkina Faso",
      "Burundi",
      "Cambodia",
      "Cameroon",
      "Canada",
      "Cape Verde",
      "Cayman Islands",
      "Central African Republic",
      "Chad",
      "Chile",
      "China",
      "Christmas Island",
      "Cocos (keeling) Islands",
      "Colombia",
      "Comoros",
      "Congo",
      "Congo, The Democratic Republic Of The",
      "Cook Islands",
      "Costa Rica",
      "Cote D'ivoire",
      "Croatia",
      "Cuba",
      "Cyprus",
      "Czech Republic",
      "Denmark",
      "Djibouti",
      "Dominica",
      "Dominican Republic",
      "East Timor",
      "Ecuador",
      "Egypt",
      "El Salvador",
      "Equatorial Guinea",
      "Eritrea",
      "Estonia",
      "Ethiopia",
      "Falkland Islands (malvinas)",
      "Faroe Islands",
      "Fiji",
      "Finland",
      "France",
      "French Guiana",
      "French Polynesia",
      "French Southern Territories",
      "Gabon",
      "Gambia",
      "Georgia",
      "Germany",
      "Ghana",
      "Gibraltar",
      "Greece",
      "Greenland",
      "Grenada",
      "Guadeloupe",
      "Guam",
      "Guatemala",
      "Guinea",
      "Guinea-bissau",
      "Guyana",
      "Haiti",
      "Heard Island And Mcdonald Islands",
      "Holy See (vatican City State)",
      "Honduras",
      "Hong Kong",
      "Hungary",
      "Iceland",
      "India",
      "Indonesia",
      "Iran",
      "Iraq",
      "Ireland",
      "Israel",
      "Italy",
      "Jamaica",
      "Japan",
      "Jordan",
      "Kazakstan",
      "Kenya",
      "Kiribati",
      "Korea, Democratic People's Republic Of",
      "Korea, Republic Of",
      "Kosovo",
      "Kuwait",
      "Kyrgyzstan",
      "Lao People's Democratic Republic",
      "Latvia",
      "Lebanon",
      "Lesotho",
      "Liberia",
      "Libyan Arab Jamahiriya",
      "Liechtenstein",
      "Lithuania",
      "Luxembourg",
      "Macau",
      "Macedonia, The Former Yugoslav Republic Of",
      "Madagascar",
      "Malawi",
      "Malaysia",
      "Maldives",
      "Mali",
      "Malta",
      "Marshall Islands",
      "Martinique",
      "Mauritania",
      "Mauritius",
      "Mayotte",
      "Mexico",
      "Micronesia, Federated States Of",
      "Moldova, Republic Of",
      "Monaco",
      "Mongolia",
      "Montserrat",
      "Montenegro",
      "Morocco",
      "Mozambique",
      "Myanmar",
      "Namibia",
      "Nauru",
      "Nepal",
      "Netherlands",
      "Netherlands Antilles",
      "New Caledonia",
      "New Zealand",
      "Nicaragua",
      "Niger",
      "Nigeria",
      "Niue",
      "Norfolk Island",
      "Northern Mariana Islands",
      "Norway",
      "Oman",
      "Pakistan",
      "Palau",
      "Palestinian Territory, Occupied",
      "Panama",
      "Papua New Guinea",
      "Paraguay",
      "Peru",
      "Philippines",
      "Pitcairn",
      "Poland",
      "Portugal",
      "Puerto Rico",
      "Qatar",
      "Reunion",
      "Romania",
      "Russian Federation",
      "Rwanda",
      "Saint Helena",
      "Saint Kitts And Nevis",
      "Saint Lucia",
      "Saint Pierre And Miquelon",
      "Saint Vincent And The Grenadines",
      "Samoa",
      "San Marino",
      "Sao Tome And Principe",
      "Saudi Arabia",
      "Senegal",
      "Serbia",
      "Seychelles",
      "Sierra Leone",
      "Singapore",
      "Slovakia",
      "Slovenia",
      "Solomon Islands",
      "Somalia",
      "South Africa",
      "South Georgia And The South Sandwich Islands",
      "Spain",
      "Sri Lanka",
      "Sudan",
      "Suriname",
      "Svalbard And Jan Mayen",
      "Swaziland",
      "Sweden",
      "Switzerland",
      "Syrian Arab Republic",
      "Taiwan, Province Of China",
      "Tajikistan",
      "Tanzania, United Republic Of",
      "Thailand",
      "Togo",
      "Tokelau",
      "Tonga",
      "Trinidad And Tobago",
      "Tunisia",
      "Turkey",
      "Turkmenistan",
      "Turks And Caicos Islands",
      "Tuvalu",
      "Uganda",
      "Ukraine",
      "United Arab Emirates",
      "United Kingdom",
      "United States",
      "United States Minor Outlying Islands",
      "Uruguay",
      "Uzbekistan",
      "Vanuatu",
      "Venezuela",
      "Vietnam",
      "Virgin Islands, British",
      "Virgin Islands, U.s.",
      "Wallis And Futuna",
      "Western Sahara",
      "Yemen",
      "Zambia",
      "Zimbabwe",
    ];

    /*Source: Ben*/
    this.financeProviders = [
      "Australian Military Bank",
      "Adelaide Bank",
      "Advantedge",
      "AFG Home Loans EDGE",
      "Australian First Mortgage",
      "Australian Government",
      "AIMS",
      "Allianz",
      "AMEX",
      "Ample Capital Australia",
      "AMP",
      "AMS",
      "Anchorage",
      "ANZ Asset Finance",
      "API Home Loans",
      "Aussie Home Loans",
      "Australian Financial",
      "Australian Lending Services",
      "Australian Wholesale Lending",
      "Auswide Bank",
      "AXA",
      "AFG Commercial",
      "AFG Home Loans - Alpha",
      "AFG Home Loans - Icon",
      "AFG Home Loans - Link",
      "AFG Home Loans - Options",
      "AFG Home Loans - Retro",
      "AFG-Retro Go",
      "Firefighters Mutual Bank",
      "Health Professionals Bank",
      "IMB Ltd",
      "MKM Capital Pty Ltd",
      "UniBank",
      "86 400",
      "Bank Australia",
      "Bank of Melbourne",
      "Bank of Queensland",
      "BankSA",
      "Bank of Sydney",
      "Banksia",
      "Bankwest",
      "Bendigo And Adelaide Bank",
      "Beyond Bank",
      "Berrima District Credit Union",
      "Better Mortgage Management",
      "Bluebay Home Loans",
      "Bluegum Home Loans",
      "Bluestone",
      "Bluestone Equity",
      "BMC",
      "BMCAdvantedge",
      "Illawarra Credit Union",
      "Challenge Bank",
      "Choice Lend",
      "Citibank",
      "Collins Securities",
      "Community CPS Australia",
      "Community Mutual Ltd",
      "Connective Home Loans Essentials",
      "Credit Union Home Loans",
      "Credit Union Australia",
      "Credit Union SA",
      "Def Credit",
      "Diners Card",
      "Direct Mortgage Solutions",
      "Director Of Housing VIC",
      "Domain",
      "Economy Home Loans",
      "eMoney",
      "Ezy Mortgage",
      "Fifty Group",
      "Finance Express Home Loans",
      "FirstFolio",
      "FirstMac",
      "Fox Symes Home Loans",
      "Gateway Credit Union",
      "Great Southern Loans",
      "Greater Building Society",
      "Heritage Bank",
      "RESIMAC Financial Services",
      "Homeloans Ltd",
      "Homeloan Centre Australia",
      "Home Path",
      "NAB Broker",
      "HomeStart Finance",
      "HSBC",
      "Iden Group",
      "ING Direct",
      "Keystart",
      "La Trobe",
      "LJ Hooker Home Loans Connect",
      "Loan Ave",
      "Loan Market Go",
      "Loan Plan",
      "Liberty Financial",
      "LJ Hooker",
      "Macquarie Bank",
      "MCCA",
      "ME Bank",
      "Mortgage Ezy",
      "Mortgage House",
      "Macquarie Securitisation Ltd",
      "MyState",
      "National Australia Bank",
      "Napiers",
      "Nationalcorp",
      "NCF Financial Services Pty Ltd",
      "Newcastle Permanent",
      "Folio Mortgage & Finance",
      "NMC",
      "NMMC",
      "OFG",
      "Paladin",
      "People's Choice Credit Union",
      "Pepper Money",
      "P&N Bank",
      "Power Home Loans",
      "Qantas Staff Credit Union",
      "Queensland Country Credit Union",
      "Qudos Bank",
      "QBANK",
      "Queenslanders Credit Union",
      "RAMS",
      "Resi",
      "Resimac",
      "RHG",
      "Sam Loans",
      "Sandhurst Trustees",
      "Smartline",
      "SGE Credit Union",
      "St. George",
      "Statewide Home Loans",
      "Suncorp Bank",
      "Sydney Home Loans",
      "Teachers Mutual Bank",
      "The Rock",
      "Think Tank",
      "Victoria Teachers Mutual Bank",
      "Virgin Money",
      "Wide Bay Australia",
      "Westpac Business",
      "Wizard Home Loans",
      "World Home Loans",
      "Yellow Brick Road",
      "Yes Home Loans",
    ];

    this.financeProviders.sort(function (a, b) {
      return a.toLowerCase().localeCompare(b.toLowerCase());
    });
    this.financeProviders.unshift("ANZ", "CBA", "NAB", "Westpac");
    this.financeProviders.push("Other");

    /*Regular Expressions for user validation*/
    this.validEmail =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    this.validNumber = "\\d+";
    this.fourDigits = "\\d{4}";
    this.elevenDigits = "\\d{11}";

    var deltaYears = 118;
    this.allYears = [];
    this.futureYears = [];
    this.pastYears = [];
    this.pastFinancialYears = [];
    this.daysOfMonth = [
      1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
      22, 23, 24, 25, 26, 27, 28, 29, 30, 31,
    ];
    this.allMonths = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    this.generateYears = function () {
      var curDate = moment.utc();
      var year = curDate.year();
      var creationYear = 2017;
      deltaYears += year - creationYear;
      this.pastYears.push(year);
      this.futureYears.push(year);

      for (var i = 1; i < deltaYears; i++) {
        this.pastYears.push(year - i);
        this.futureYears.push(year + i);
      }

      for (var i = deltaYears; i > 0; i--) {
        this.allYears.push(year + i);
      }

      this.allYears.push(year);

      for (var i = 1; i < deltaYears; i++) {
        this.allYears.push(year - i);
      }

      var FY;
      FY = year - 1 + "/" + year;
      this.pastFinancialYears.push(FY);

      for (var i = 1; i <= 4; i++) {
        FY = year - i - 1 + "/" + (year - i);
        this.pastFinancialYears.push(FY);
      }
    };
    this.generateYears();

    /* this function takes an address block and tries to create as full an address as it can from the components */
    /* Floor [floor], U[unit]/[lot] [street] [street type], [suburb] [state] [postcode] [COUNTRY]*/
    this.generateAddressFull = function (address) {
      var $str = "";

      if (address) {
        if (address.hasOwnProperty("floor")) {
          if (address.floor) {
            $str += "Floor " + address.floor + ", ";
          }
        }
        if (address.hasOwnProperty("unit")) {
          if (address.unit) {
            $str += address.unit + "/";
          }
        }
        if (address.hasOwnProperty("number")) {
          if (address.number) {
            $str += address.number + " ";
          }
        }
        if (address.hasOwnProperty("street")) {
          if (address.street) {
            $str += address.street + " ";
          }
        }
        if (address.hasOwnProperty("streetType")) {
          if (address.streetType) {
            $str += address.streetType + ", ";
          }
        }
        if (address.hasOwnProperty("suburb")) {
          if (address.suburb) {
            $str += address.suburb + " ";
          }
        }
        if (address.hasOwnProperty("state")) {
          if (address.state) {
            $str += address.state + " ";
          }
        }
        if (address.hasOwnProperty("postcode")) {
          if (address.postcode) {
            $str += address.postcode + " ";
          }
        }
        if (address.hasOwnProperty("country")) {
          if (address.country) {
            $str += address.country;
          }
        }
        //address.addressFull = $str;

        $str = $str.replace(new RegExp("null", "g"), "");
        $str = $str.trim();

        // check if address is almost empty or not
        if ($str.length < 4) {
          $str = "Invalid Address";
        }
      }
      return $str;
    };
    this.generateAddressShort = function (address) {
      var $str = "";
      if (address) {
        if (address.hasOwnProperty("number")) {
          if (address.number) {
            $str += address.number + " ";
          }
        }
        if (address.hasOwnProperty("street")) {
          if (address.street) {
            $str += address.street + " ";
          }
        }
      }
      $str = $str.replace(new RegExp("null", "g"), "");
      $str = $str.trim();

      // check if address is almost empty or not
      if ($str.length < 4) {
        $str = "Invalid Address";
      }

      return $str;
    };

    /*Factfind 6&7 - Data Arrays*/
    this.expenseFrequencies = [
      "Weekly",
      "Fortnightly",
      "Monthly",
      "Every 2 months",
      "Every 3 months",
      "Every 6 months",
      "Yearly",
    ];
    this.expenseCategories = [
      { n: "Primary Direct", v: "Direct from Primary" },
      { n: "via Credit Card", v: "Paid by Credit" },
      { n: "via Living & Lifestyle", v: "7 Days Float" },
      { n: "Provision for", v: "Provisioning" },
    ];

    /*Factfind 4 - Data Arrays*/
    this.vehicleTypes = [
      "Luxury",
      "4WD",
      "Large",
      "Medium",
      "Small",
      "Caravan",
      "Bike",
      "Boat",
    ];

    this.saveValidationMessage =
      "Before we can save your changes, there is still some information outstanding. Please fill in the required information as highlighted in orange or fields that have red asterisk.";
    this.superProviders = [
      "Not In List",
      "Access eWrap Super",
      "Acquire Retirement Service Superannuation",
      "Acquire Retirement Service Superannuation (Related Discount)",
      "Acuity Portfolio Service Super Wrap Personal Super",
      "ADF Super",
      "Advance Pooled Superanuation Trust",
      "Advance Retirement Suite",
      "adviceLink SuperWrap Super",
      "AET Small APRA fund",
      "Agentia Super Service",
      "Alcoa Retirement Plan Super",
      "AMG Southern Cross Super Plan",
      "AMG Super (Corporate (Family Discount�",
      "AMG Super (Corporate (Standard))",
      "AMG Super (Personal)",
      "AMP Custom Super (Plan size 1.5 - 2 million) - Plan A",
      "AMP Custom Super (Plan size 1.5 - 2 million) - Plan B",
      "AMP Custom Super (Plan size 10 - 12.5 million) - Plan A",
      "AMP Custom Super (Plan size 10 - 12.5 million) - Plan B",
      "AMP Custom Super (Plan size under 1.5 million)- Plan B",
      "AMP Eligible Rollover Fund",
      "AMP EndowmentSuper",
      "AMP Flexible Lifetime Super",
      "AMP Flexible Lifetime Super Easy (Flexible Protection)",
      "AMP Flexible Super Choice (Essential Protection) - Employer",
      "AMP Flexible Super Choice (Essential Protection) - Personal",
      "AMP Flexible Super Choice (Flexible Protection) - Employer",
      "AMP Flexible Super Choice (Flexible Protection) - Personal",
      "AMP Flexible Super Choice (Super Protection) - Personal",
      "AMP Flexible Super Core (Essential Protection) - Employer",
      "AMP Flexible Super Core (Essential Protection) - Personal",
      "AMP Flexible Super Core (Flexible Protection) - Employer",
      "AMP Flexible Super Core (Flexible Protection) - Personal",
      "AMP Flexible Super Core (Super Protection) - Personal",
      "AMP Flexible Super Select (Essential Protection) - Employer",
      "AMP Flexible Super Select (Essential Protection) - Personal",
      "AMP Flexible Super Select (Flexible Protection) - Employer",
      "AMP Flexible Super Select (Flexible Protection) - Personal",
      "AMP Generations Personal Super (AMP)",
      "AMP My North Retirement Fund",
      "AMP MyNorth Personal Super (Core Only)",
      "AMP MyNorth Personal Super (Standard)",
      "AMP MyNorth Personal Super (Standard) - First Quest Insurance Protection",
      "AMP North Personal Super",
      "AMP North Personal Super- New Members 19/01/2015 ($1,500,000 plus) (Excludes Guarantee)",
      "AMP North Personal Super - New Members 19/01/2015 (<$1,500,000) (Excludes Guarantee)",
      "AMP North Personal Super (Protection Guarantee - Pre 29 July 2013)",
      "AMP Retirement Savings Account",
      "AMP Retirement Security Plan",
      "AMP Signature Super - Employer",
      "AMP Signature Super - Select (Personal)",
      "AMP Signature Super-Woolworths Group Superannuation Plan",
      "AMP Signature Super (Alliance Sub Plan) - Personal",
      "AMP Signature Super (Large Plan Discount) - Personal",
      "AMP Signature Super (Post August 1st 2010 (Macquarie)) - Employer",
      "AMP Simple Super",
      "AMP Super Directions for Business",
      "AMP Super Directions for Business Rollover Section (Post May 2011)",
      "AMP Super Directions Personal Super (Exit Fee)",
      "AMP Super Directions Personal Super (Nil Entry/Nil Exit)",
      "AMP SuperLeader Plan",
      "AMP Tailored Super",
      "AMP WealthView eWRAP Super",
      "AMP Whole of Life Personal Plan",
      "Annex IOOF Pursuit Focus (Personal Super)",
      "Annex IOOF Pursuit Select (Personal Super)",
      "ANZ OneAnswer Personal Super (Entry Fee)",
      "ANZ OneAnswer Personal Super (Entry Fee) - ANZ Planners",
      "ANZ OneAnswer Personal Super (Nil Entry Fee)",
      "ANZ OneAnswer Personal Super (Nil Entry Fee) - ANZ Planners",
      "ANZ Personal Superannuation Bond (Fee Plan B)",
      "ANZ Smart Choice Employer Super (ex One Path)",
      "ANZ Smart Choice Super",
      "ANZ Staff Super Scheme",
      "ANZ Staff Super Schemejwsd (Derived)",
      "ANZ Super Advantage Entry Fee Option",
      "ANZ Superannuation Savings Account",
      "Aon Eligible Rollover Fund",
      "Aon Master Trust Corporate (Super Essentials)",
      "Aon MasterTrust Corporate (Super)",
      "Aon Master Trust Personal (Super Essentials)",
      "Aon Master Trust Personal Super",
      "Apex Super Plan",
      "Asgard Employee Super (Plan $1,000,001- $5,000,000)",
      "Asgard Employee Super (Plan $10,000,001 - $20,000,000)",
      "Asgard Employee Super (Plan $5,000,001 - $10,000,000)",
      "Asgard Employee Super (Plan $500,001- $1,000,000)",
      "Asgard Employee Super (Plan over $20,000,000)",
      "Asgard Employee Super (Plan up to $500,000)",
      "Asgard eWRAP (SMSF)",
      "Asgard eWrap Super",
      "Asgard Infinity eWrap SMSF (Core)",
      "Asgard Infinity eWrap SMSF (Full)",
      "Asgard Infinity eWrap SMSF (Select)",
      "Asgard Infinity eWrap Super (Core)",
      "Asgard Infinity eWrap Super (Full)",
      "Asgard Rollover Service",
      "Asgard Superannuation Account (Elements)",
      "Asgard Superannuation Account (Managed)",
      "Asgard Superannuation Account (SMA Funds)",
      "Aspire Superannuation MasterTrust Super Division (Family Discount)",
      "Aspire Superannuation MasterTrust Super Division (Standard)",
      "Assemble SMSF (Related Discount)",
      "Assemble SMSF (Standard)",
      "Assemble SuperWrap Super (Related Discount)",
      "Assemble SuperWrap Super (Standard)",
      "AssetChoice SuperWrap Super (Related Discount)",
      "AssetChoice SuperWrap Super (Standard)",
      "AssetLink SuperWrap Super",
      "Asteron Connelly Temple Employer Super Plan (Lifestage investment options)",
      "Asteron Connelly Temple Employer Super Plan (Non-Lifestage Investment options)",
      "Asteron Connelly Temple Employer Super Plan (Personal Member)",
      "Asteron Connelly Temple Super Savings Plan",
      "Asteron Optimum Corporate Super (Plan commenced on or after 11/12/06)",
      "Asteron Optimum Corporate Super for Standard Pacific (March 2007 onwards)",
      "Asteron Optimum Personal Super",
      "Asteron Optimum Professional Super Plan Super Savings",
      "Asteron Partnership Superannuation Fund",
      "Asteron Workforce Super",
      "Atwood Oceanics Australia Super Fund (sub-plan ofThe Executive Super Fund)",
      "AusBev Super Retained Benefits",
      "AUSFUND Eligible Rollover Fund",
      "AustChoice Superannuation",
      "Australia Post Super (Superannuation Scheme)- Defined Benefit",
      "Australia Post Super (Superannuation Scheme)- Member Savings",
      "Australian Catholic Super and Retirement Fund Employer Sponsored",
      "Australian Conference Association SuperTrust",
      "Australian Eligible Rollover Fund (Part A)",
      "Australian Eligible Rollover Fund (Part B)",
      "Australian Eligible Rollover Fund (Part M)",
      "Australian Eligible Rollover Fund (REST)",
      "Australian Ethical Retail Super (Large Account Balance discount)",
      "Australian Ethical Retail Super (Large Employer plus Large Account Balance discount)",
      "Australian Meat Industry Super Trust (Employer Sponsored)",
      "Australian Meat Industry Super Trust (Personal)",
      "AustralianSuper Corporate (Komatsu Super Plan}",
      "AustralianSuper Divisions (AustQ)",
      "AustralianSuper Divisions (Finsuper)",
      "AustralianSuper Divisions (Personal Plan)",
      "AustralianSuper Divisions (Public Sector)",
      "AustralianSuper Divisions (Standard Product)",
      "AustralianSuper Divisions (Westscheme)",
      "AustSafe Super (Industry Fund)",
      "AustSafe Super (Personal)",
      "AvSuper (Corporate Members)",
      "AvSuper (Public Offer Fund)",
      "AvWrap Retirement Service Super",
      "Axa Provider Personal Retirement Plan",
      "Axa Provider Special Purpose Plan",
      "Axa ProviderTop Up Retirement Plan",
      "BCU Retirement Saving Account",
      "Beacon Super Service",
      "Beacon Superannuation Managed Profiles",
      "Beacon Superannuation SMA",
      "Bendigo SmartOptions Super (new members)",
      "Bendigo SmartStart Super",
      "Bendigo Superannuation Plan",
      "BHP Billiton No.2 Superannuation Plan",
      "BHP Billiton Superannuation Fund (Defined Contribution Division)",
      "BHP Billiton Superannuation Fund (QNI Division)",
      "Blueprint Retirement Fund Super (Protectionfirst insurance)",
      "Bluescope Steel Super (Defined Contribution Div Part 17)",
      "Bluescope Steel Super (Retained Benefits}",
      "BOC Super",
      "brightdav Complete Super",
      "BT Business Super",
      "BT Compact SuperWrap Super",
      "BT Foundation Portfolio Superwrap",
      "BT Lifetime Personal Super",
      "BT Lifetime Super Employer Plan (Employer)",
      "BT Personal Portfolio Service (Superannuation)",
      "BT Portfolio SuperWrap+ (Related Discount)",
      "BT Portfolio SuperWrap+ (Standard)",
      "BT Retirement Selection Personal Super Plan",
      "BT Select Administrator SuperWrap Essentials Super",
      "BT Select Administrator SuperWrap Super",
      "BT Select Portfolio SuperWrap Essentials Super",
      "BT Select Portfolio SuperWrap Super",
      "BT Super for Life (Savings)",
      "BT Super for Life (Westpac Group Plan)",
      "BT Superannuation Investment Fund",
      "BT SuperWrap Essentials Super",
      "BT SuperWrap Super (Related Discount)",
      "BT SuperWrap Super (Standard)",
      "BT Wrap (Related Discount)",
      "BT Wrap (Standard)",
      "BUSSQ MySuper",
      "BUSSQ Premium Choice Plan",
      "Care Super Employer Plan",
      "Care Super Personal Plan",
      "CAREportfolio Personal Super",
      "Catholic Super",
      "CBUS Superannuation (Electech (formerly Connect))",
      "CBUS Superannuation (Industry)",
      "CBUS Superannuation (Personal)",
      "CBUS Superannuation (Sole Trader)",
      "Centric Capital Wholesale Private Equity Fund",
      "Challenger Guaranteed Personal Super",
      "Child Care Super",
      "Christian Super",
      "ClearView Superannuation and Rollovers",
      "ClearView WealthFoundations Super",
      "ClearView WealthSolutions Superannuation",
      "Client Portfolio Service SuperWrap Super",
      "Clough Super Superannuation Fund",
      "Club Plus Superannuation (Industry)",
      "Club Plus Superannuation (Personal)",
      "Club Super",
      "Colonial First State FirstChoice Employer Super",
      "Colonial First State FirstChoice Personal Super",
      "Colonial First State FirstChoice Wholesale Personal Super",
      "Colonial First State FirstWrap Plus Super",
      "Colonial First State FirstWrap Super",
      "Colonial First State Super and Rollover Fund",
      "Colonial First State Super Retirement Fund Umbrella Financial Plan",
      "Colonial First State Super Retirement Fund Wealth Portfolio Super",
      "Colonial First State Superwise Series 5 Fund",
      "Colonial Select Personal Superannuation",
      "Combined Fund Super",
      "Commonwealth Bank Approved Deposit Fund",
      "Commonwealth Bank Group SuperAccumulate Plus",
      "Commonwealth Bank Superannuation Savings Acc.",
      "Commonwealth Essential Super",
      "Commonwealth Personal Superannuation and Rollover Plan",
      "Commonwealth Super Select",
      "Compass HUB24 Super",
      "Compass Superannuation MasterTrust Super and Rollover",
      "Concept One The Industry Superannuation Fund",
      "Crescent Wealth Superannuation Fund",
      "Cruelty Free Super",
      "CSS Commonwealth Super Scheme",
      "C-Wrap SuperWrap Personal Super (Related Discount)",
      "C-Wrap SuperWrap Personal Super (Standard)",
      "Defence Bank (SuperAssured Retirement Savings Account)",
      "Defence Force Retirement and Death Benefits Scheme",
      "Deseret Super Fund",
      "Dominion Employer Super",
      "Dominion Portfolio Service (Family Discount) - SMSF",
      "Dominion Portfolio Service (Standard) - SMSF",
      "Dominion Superannuation MasterTrust Super (Family Discount)",
      "Dominion Superannuation Master Trust Super (Standard)",
      "Dow Australia Superannuation Fund",
      "DPM Retirement Service Pension ($1,000,000 to $1,999,999) - Group Investment Discount",
      "DPM Retirement Service Pension ($2,000,000 PLUS) - Group Investment Discount",
      "DPM Retirement Service Pension ($400,000 to $999,999) - Group Investment Discount",
      "DPM Retirement Service Superannuation ($1,000,000 to $1,999,999)",
      "DPM Retirement Service Superannuation ($1,000,000 to $1999.999) - Group Investment Discount",
      "DPM Retirement Service Superannuation (up to $999,999)",
      "Dulux Employees Superannuation Fund",
      "EISS (Super)",
      "Electricity Industry Super Scheme - Defined Benefit (Lump)",
      "Electricity Industry Super Scheme (Accumulation Scheme (Division 5))",
      "Emerald SuperWrap Super",
      "Emergency Services Super (Accumulation Plan)",
      "Emergency Services Super Beneficiary Account",
      "EmPIus Super (Employer Division)",
      "EmPIus Super (Personal Super Members)",
      "encircle Superannuation Service",
      "EndeavourSuper",
      "Energy Industries (Retirement Scheme - Contributor)",
      "Energy Super Superannuation Plan",
      "Enterprise Super (NSW Sugar Milling Co-Operative)",
      "Enterprise Super Employer Sponsored",
      "Enterprise Super Personal",
      "Equip MyFuture",
      "Executive Choice Master Fund",
      "FC ONE Retirement Builder Super (Series 2)",
      "FC ONE Retirement Builder Superannuation Service ($1,000,000 and over) - Group Investment Discount",
      "FC ONE Retirement Builder Superannuation Service ($1,000,000 and over)- Standard",
      "FC ONE Retirement Builder Superannuation Service ($400,000 to $999,999) - Group Investment Discount",
      "FC ONE Retirement Builder Superannuation Service (up to $999,999) - Standard",
      "Federation Super",
      "Fiducian Superannuation Service Personal Super",
      "FinHQ Wrap Administration Service Super",
      "FinHQ Wrap I Super",
      "FinHQ Wrap Portfolio Service Personal Super",
      "Fire and Emergency Services Super",
      "First State Super (Ambulance Officers)",
      "First State Super (Employer Division)",
      "First State Super (Personal Division)",
      "First State Super (Police Blue Ribbon)",
      "FIRST Super",
      "FlexibleSuper Plan",
      "Fortnum Advice SuperWrap Personal Super Plan",
      "Freedom of Choice Employer Superannuation Service",
      "Freedom of Choice Personal Superannuation Service",
      "FSP Super Fund (Standard)",
      "FutureSuper Super Plan",
      "Garnet Superannuation Fund",
      "GESB Gold State Super",
      "GESB Super",
      "GESB West State Super",
      "Go SuperWrap Personal Super Plan",
      "Goldman Sachs 8. JBWere Superannuation Fund (Accumulation)",
      "Grosvenor Pirie Master Super Fund",
      "Grow Wrap Super Service (Essentials)",
      "Grow Wrap Super Service (Essentials) - Family Discount",
      "Grow Wrap Super Service (Essentials, ASX Share Trading & Extended Managed Investment Menu)",
      "Grow Wrap Super Service (Essentials, ASX Share Trading & Extended Managed Investment Menu) - Family Discount",
      "Grow Wrap Super Service (Essentials, ASX Share Trading)",
      "Grow Wrap Super Service (Essentials, ASX Share Trading) - Family Discount",
      "Grow Wrap Super Service (Essentials, Extended Managed Investment Menu )- Family Discount",
      "Grow Wrap Super Service (Essentials, Extended Managed Investment Menu)",
      "Guild Retirement Fund Super Plan",
      "HESTA Super (Industry Super Plan)",
      "HESTA Super (Personal Plan)",
      "Holden Employees Superannuation Fund Retained Benefits",
      "HOSTPLUS Super (Executive)",
      "HOSTPLUS Super (Industry)",
      "HOSTPLUS Super (Personal)",
      "HUB24 Super (e-Clipse super)",
      "HUB24 Super (Related Discount)",
      "HUB24 Super (Standard)",
      "IAG and NRMA Superannuation Plan",
      "Imagine Wealth Superannuation (Employer)",
      "Imagine Wealth Superannuation (MasterTrust)",
      "Imagine Wealth Superannuation (Personal)",
      "Independent Superannuation Preservation Fund",
      "Infocus Super Hub",
      "ING DIRECT Living Super",
      "Intrust Core Super",
      "Intrust Executive Super",
      "Intrust Select Super",
      "Investment Exchange Retirement Service Super",
      "IOOF Employer Super - Bendigo and Adelaide Bank Staff Superannuation Plan",
      "IOOF Employer Super (Employer Division)",
      "IOOF Employer Super (Personal Division)",
      "IOOF Lifetrack Superannuation (Personal)",
      "IOOF Lifetrack Superannuation Corporate (Flight Centre Limited)",
      "IOOF Lifetrack Superannuation Corporate (Optus)",
      "IOOF Portfolio Essentials SuperWrap (Personal Super (EXISTING XPLAN SCENARIOS ONLY))",
      "IOOF Portfolio Essentials SuperWrap (Personal Super)",
      "IOOF Portfolio Service Superannuation Personal (Deferred Entry Fee)",
      "IOOF Portfolio Service Superannuation Personal (Standard Entry Fee)",
      "IOOF Pursuit Core Personal Super (Adviser Service Fee)",
      "IOOF Pursuit Core Personal Super (Ongoing Commission)",
      "IOOF Pursuit Focus for Infocus (Personal Superannuation)",
      "IOOF Pursuit Focus Personal Superannuation",
      "IOOF Pursuit Selectfor Infocus (Personal Superannuation)",
      "IOOF Pursuit Select Personal Super",
      "IOOF Pursuit Select Personal Super (Matrix)",
      "IOOF Pursuit Select Personal Super (Plan B)",
      "Ipac iAccess Personal Superannuation",
      "Ipac iAccess Strategic Service Personal Superannuation",
      "IPE Super",
      "IRESS AGEST Super Employer (Derived)",
      "JR Superannuation Fund",
      "KickStart Super Plan (Custom)",
      "Kinetic (Super)",
      "LAS Defined Benefit Plan",
      "Law Employees Super Fund",
      "Legal and General Personal Super Fund (Umbrella Plan)",
      "LegalSuper",
      "LGIA Super (QLD) Super (Accumulation Benefits Fund incl. Brisbane City Council)",
      "LGIA Super (QLD) Super (Retained Benefits)",
      "LifeFocus eWrap Super",
      "Lindfield Superannuation",
      "Local Government SuperAccumulation Scheme",
      "Local Government Super Retirement Scheme",
      "LUCRF Super (Industry)",
      "LUCRF Super (Personal)",
      "Lutheran Super",
      "M SuperWrap Essentials Super",
      "M SuperWrap Super (Related Discount)",
      "M SuperWrap Super (Standard)",
      "Macmahon (Employees Superannuation Fund)",
      "Macquarie ADF Superannuation Fund",
      "Macquarie Private Wealth Premium Portfolio Service Super",
      "Macquarie Super Consolidator (Related Discount)",
      "Macquarie Super Consolidator (Standard)",
      "Macquarie SuperOptions Super Plan",
      "Macquarie Wrap - Super Manager FYG (Derived)",
      "Macquarie Wrap Investment Consolidator (Related Discount)",
      "Macquarie Wrap Investment Consolidator (Standard)",
      "Macquarie Wrap Investment Manager",
      "Macquarie Wrap SuperAccumulator",
      "Macquarie Wrap Super Manager",
      "Makeachoice Superannuation MasterTrust",
      "MAP Pooled Superannuation Trust ($10,000 or more)",
      "MAP Pooled Superannuation Trust (Under $10,000)",
      "MAP Superannuation Plan",
      "Maritime Seafarers Division Super (AMOU Staff)",
      "Maritime Seafarers Division Super (Contributory Accumulation)",
      "Maritime Seafarers Division Super (Inco Sub-fund)",
      "Maritime Seafarers Division Super (SVITZER Accumulation Members)",
      "Maritime Stevedores Division Super (Accumulation Basic)",
      "Maritime Stevedores Division Super (Accumulation Plus)",
      "Maritime Super Division (Accumulation Advantage)",
      "Maritime Super Division (Teekay Accumulation)",
      "Maritime Super Division (Trident Accumulation)",
      "Matrix Superannuation MasterTrust Super (Family Discount- Pre Feb 2014)",
      "Matrix Superannuation Master Trust Super (Family Discount)",
      "Matrix Superannuation Master Trust Super (Standard - Pre Feb 2014)",
      "Matrix Superannuation Master Trust Super (Standard)",
      "Matrix Superannuation Plan (Employer)",
      "Matrix Superannuation Plan (Personal)",
      "Max Super",
      "Meat Industry Employees Super",
      "Media Super (Employer Sponsored)",
      "Media Super (Personal Super)",
      "Medical and Associated Professions Superannuation Plan",
      "Mentor Superannuation Master Trust (Family Discount)",
      "Mentor Superannuation Master Trust (Standard)",
      "Mercer Portfolio Service (Super)",
      "Mercer SuperTrust (Corporate Super Division)- (Woodside Superannuation Plan)",
      "Mercer SuperTrust (Corporate Super Division (Ericsson Australia Super Plan))",
      "Mercer Super Trust (Corporate Super Division (Ernst & Young Partners - Accumulation))",
      "Mercer Super Trust (Corporate Super Division (SmartSuper - Individual))",
      "Mercer SuperTrust (Corporate Super Division (Wesfarmers - Accumulation))",
      "Mercer Super Trust (Corporate Superannuation Division)",
      "Mercer Super Trust (Personal Superannuation Division)",
      "Mercy Super",
      "MilitarySuper",
      "Mine Wealth and Wellbeing Super",
      "MLC MasterKey Business Super (Business Super Member's)",
      "MLC MasterKey Personal Super (Ex-Business Super members)",
      "MLC MasterKey Super ($100,000 to $199,999)",
      "MLC MasterKey Super ($200,000 to $399,999)",
      "MLC MasterKey Super ($400,000 Plus)",
      "MLC MasterKey Super ($50,000 to $99,999)",
      "MLC Masterkey Super (Family Linked Accounts)",
      "MLC MasterKey Super (Up to $49,999)",
      "MLC MasterKey Super Fundamentals",
      "MLC MasterKey Superannuation Gold Star ($100,000 - $199,999)",
      "MLC MasterKey Superannuation Gold Star ($200,000 - $399,999)",
      "MLC MasterKey Superannuation Gold Star ($400,000 plus)",
      "MLC MasterKey Superannuation Gold Star (Up to $99,999)",
      "MLC Navigator Access Super",
      "MLC Navigator Pers. Retirement Super Service ($1,000,000 - $1,999,999)",
      "MLC Navigator Pers. Retirement Super Service ($2,000,000 PLUS)",
      "MLC Navigator Pers. Retirement Super Service (Up to $999,999)",
      "MLC Navigator Pers. Retirement Super Service 2010 ($1,000,000 - $1,999,999)",
      "MLC Navigator Pers. Retirement Super Service 2010 ($2,000,000 PLUS)",
      "MLC Navigator Pers. Retirement Super Service 2010 (Up to $999,999)",
      "MLC Navigator Retirement Plan Super (Series 2)",
      "MLC The Employee Retirement Plan",
      "MLC Wrap SelfManaged Super (Related Discount)",
      "MLC Wrap SelfManaged Super (Standard)",
      "MLC Wrap Super ($400,000 and over- Group Investment Discount)",
      "MLC Wrap Super (Related Discount)",
      "MLC Wrap Super (Standard)",
      "MLC Wrap Super Series 2",
      "Money Management SuperWrap Personal Super Plan",
      "MTAA Superannuation Fund",
      "Mutual Super Personal Division",
      "MX HUBZ4 Super",
      "MX SuperWrap Personal Super Plan (Standard)",
      "MyState Wealth Management Superannuation",
      "National Australia Bank Group Super Fund A (Employee Members)",
      "National Australia Bank Group Super Fund A (Retained Benefits)",
      "Nationwide Superannuation Fund (Employer Division)",
      "Nationwide Superannuation Fund (Personal Division)",
      "NESS Super",
      "Nestle Super Insured Accumulation",
      "netwealth Accelerator (Core Employer Sponsored Super)",
      "netwealth Accelerator (Core Personal Super)",
      "netwealth Accelerator (Plus Employer Sponsored Super)",
      "netwealth Accelerator (Plus Personal Super)",
      "netwealth SuperWrap (Personal Super)",
      "netwealth SuperWrap Employer Sponsored Super (Over $10,000,000)",
      "netwealth SuperWrap Employer Sponsored Super (Plan $1,000,001 - $2,500,000)",
      "netwealth SuperWrap Employer Sponsored Super (Plan $2,500,001 - $5,000,000)",
      "netwealth SuperWrap Employer Sponsored Super (Plan $5,000,001 - $10,000,000)",
      "netwealth SuperWrap Employer Sponsored Super (Plan $500,001 - $1,000,000)",
      "netwealth SuperWrap Employer Sponsored Super (Plan up to $500,000)",
      "netwealth SuperWrap Private Wealth (Personal Super)",
      "Newcastle Permanent Superannuation Plan",
      "NGS Super",
      "NTGPASS Retained Benefit",
      "Omniport Superannuation Service",
      "OnePath Deferred Annuity",
      "OnePath lntegra Super (Employee Member)",
      "OnePath lntegra Super (Personal)",
      "OnePath OneAnswer (Personal Select)",
      "OnePath OneAnswer Frontier (Personal Super)",
      "OnePath OneAnswer Personal Super (Entry Fee version)",
      "OnePath OneAnswer Personal Super (Nil Entry Fee Version)",
      "OnePath Optimix Super",
      "Oracle Superannuation Plan",
      "PATRON Super Hub",
      "Perpetual Pooled Superannuation Trust",
      "Perpetual Private Super Wrap",
      "Perpetual Select Superannuation Plan (MvSuper)",
      "Perpetual Select Superannuation Plan (Personal)",
      "Perpetual Select Superannuation Plan (Plan $1,000,001 - $2,500,000)",
      "Perpetual Select Superannuation Plan (Plan $10,000,001 - $20,000,000)",
      "Perpetual Select Superannuation Plan (Plan $2,500,001 - $5,000,000)",
      "Perpetual Select Superannuation Plan (Plan $20,000,001 - $50,000,000)",
      "Perpetual Select Superannuation Plan (Plan $5,000,001 - $10,000,000)",
      "Perpetual Select Superannuation Plan (Plan $500,001 - $1,000,000)",
      "Perpetual Select Superannuation Plan (Plan over $50,000,000)",
      "Perpetual Select Superannuation Plan (Plan up to $500,000)",
      "Perpetual WealthFocus (Super Plan)",
      "Perpetual WealthFocus (Wholesale Super)",
      "Personal Choice eWrap Super",
      "Personal Choice Private eWrap Super",
      "Personal Financial Services Wrap Super",
      "Pitcher Retirement Plan",
      "Plum Boral Super",
      "Plum Glencore Super (Coal Management)",
      "Plum GPT Group Superannuation Plan",
      "Plum HP Super",
      "Plum JP Morgan Superannuation Plan",
      "Plum KCA Super Plan",
      "Plum Lend Lease Superannuation Plan",
      "Plum O'Brien Superannuation",
      "Plum Personal Plan (Plum Personal Division)",
      "Portfolio Administrator SuperWrap Personal Super Plan",
      "Portfolio Plus SuperWrap Super",
      "PortfolioCare Super (Elements) - Elements I eWrap",
      "PortfolioCare Super (eWRAP) - Elements I eWrap",
      "PortfolioCare Super Service (Plan $1,000,001 to $5,000,000)",
      "PortfolioCare Super Service (Plan $10,000,001 to $20,000,000)",
      "PortfolioCare Super Service (Plan $20,000,001 to $30,000,000)",
      "PortfolioCare Super Service (Plan $5,000,001 to $10,000,000)",
      "PortfolioCare Super Service (Plan $500,001 to $1,000,000)",
      "PortfolioCare Super Service (Plan up to $500,000)",
      "Portfoliofocus Essentials Super",
      "Portfoliofocus Premium Retirement Service Super ($1 ,000,000 - $1,999,999) - Group Investment Discount",
      "Portfoliofocus Premium Retirement Service Super ($1,000,000 - $1,999,999) - Standard",
      "Portfoliofocus Premium Retirement Service Super ($2,000,000 Plus) - Group lnvestrnent Discount",
      "Portfoliofocus Premium Retirement Service Super ($2,000,000 Plus) - Standard",
      "Portfoliofocus Premium Retirement Service Super ($499,999 - $999,999) - Group Investment Discount",
      "Portfoliofocus Premium Retirement Service Super (Series 2)",
      "Portfoliofocus Premium Retirement Service Super (Up to $999,999) - Standard",
      "PortfolioOne Super (Contribution Fee)",
      "PortfolioOne Super (Net Operator Fee)",
      "Powerwrap Superannuation Account",
      "PPM Super",
      "Praemium NEO SuperSMA (Super)",
      "Praemium SuperSMA (Super)",
      "PreAssemble SuperWrap Personal Super",
      "Premium Choice Retirement Service Super ($1,000,000 - $1,999,999) - Group Investment Discount",
      "Premium Choice Retirement Service Super ($1,000,000 - $1,999,999) - Standard",
      "Premium Choice Retirement Service Super ($2,000,000 PLUS) - Group Investment Discount",
      "Premium Choice Retirement Service Super ($2.000,000 PLUS) - Standard",
      "Prime Super (Prime Division)",
      "Privilege Superannuation Solutions ($140,000 plus)",
      "Privilege Superannuation Solutions (Under $140,000)",
      "PSS (Accumulation Plan)",
      "PSS Defined Benefit (Superannuation Scheme)",
      "QANTAS Gateway Super",
      "QANTAS Retirement Savings Account",
      "QANTAS Super Plan (Division 10)",
      "QANTAS Super Plan (Division 3)",
      "QANTAS Super Plan (Division 5)",
      "QANTAS Super Plan (Division 6)",
      "QANTAS Super Plan (Division 7)",
      "QANTAS Super Plan (Division 8)",
      "QIEC Super",
      "QSuper (Defined Benefit)",
      "QSuper (Non Queensland-Government employees)",
      "QSuper (Queensland Government and related entity employees)",
      "REI Super (Super)",
      "REST Acumen - Super Retail Group",
      "REST Employee Super",
      "REST Personal Super",
      "Retirement Benefits Fund Tasmanian Accumulation Scheme",
      "RetireSelect Super",
      "Rio Tinto Super (Employee Members)",
      "Russell iQ Super",
      "Russell Super Solution Employer Division (Division Three Holcim Employees)",
      "Russell Super Solutions Employer Division (Division Two Holcim Employees)",
      "Russell SuperSolution (D.E Coffee 8. Tea Australia Pty Ltd Category 2)",
      "Russell SuperSolution (General Division)",
      "SA Metropolitan Fire Service Super Scheme",
      "Savings and Loans Members Super Fund",
      "SCM Optimal Choice Funds",
      "SimpleWRAP Personal Superannuation (Related Discount)",
      "SimpleWRAP Personal Superannuation (Standard)",
      "Smartsave Super (Master Plan Employer)",
      "Smartsave Super (Master Plan Personal)",
      "SMARTwrap Super Account",
      "SMF Master Pooled Superannuation Trust",
      "Solar SuperWrap Essentials Super Plan",
      "Solar SuperWrap Super (Related Discount)",
      "Solar SuperWrap Super (Standard)",
      "South Australian Ambulance Service Super",
      "Star Portfolio Superannuation Fund Super (Fee Aggregation)",
      "Star Portfolio Superannuation Fund Super (No Fee Aggregation)",
      "State Authorities Super Scheme",
      "State Super Personal Retirement Plan",
      "State Super Tailored Super Fund",
      "StatewideSuper ChoicePlus Employee",
      "StatewideSuper ChoicePlus Personal",
      "StatewideSuper Defined Benefit (Salarylink)",
      "StatewideSuper Employer Sponsored",
      "StatewideSuper Personal",
      "Sterling Accumulation (Super)",
      "Sterling Super (SRA)",
      "Strategy Super Plan (Employer)",
      "Strategy Super Plan (Personal)",
      "Strategy Super Plan Retirement Fund Super (Family Discount)",
      "Strategy Super Plan Retirement Fund Super (Standard)",
      "Summit Multiport selfmanaged super",
      "Summit Personal Super (AMP)",
      "Suncorp Brighter Super (Business)",
      "Suncorp Brighter Super (Personal Super)",
      "Suncorp Employee Superannuation Plan",
      "Suncorp Everyday Super- Super",
      "Suncorp Wealthsmart Business Super",
      "Suncorp Wealthsmart Personal Super",
      "Sunsuper Corporate Defined Benefit",
      "Sunsuperfor life Super Savings account",
      "Super ERF",
      "Super Money ERF",
      "Super SA (Flexible Rollover)",
      "Super SA (Lump Sum)",
      "Super SA (Select)",
      "Super SA (Triple 8)",
      "Super Safeguard Eligible Rollover Fund",
      "SuperChoice Personal Super",
      "SuperTrace Eligible Rollover Fund",
      "SWMS SuperWrap Super - BT version",
      "SWMS SuperWrap Super - Private Client Super (Oasis version)",
      "SWMS SuperWrap Super (Related Discount) - Oasis Version",
      "SWMS SuperWrap Super (Standard) - Oasis version",
      "Symetry Foundation IDPS",
      "Symetry Foundation Super",
      "Symetry Lifetime Super",
      "TAL ARC Classic Super (Series 1)",
      "TAL ARC Classic Super (Series 2)",
      "TAL ARC Classic Super (Series 3)",
      "TAL ARC MasterTrust Corporate Superannuation",
      "TAL ARC Master Trust Personal Superannuation",
      "TAL Blue Ribbon Executive Super",
      "TAL Personal Super (ex FAI)",
      "TAL Prestige Superannuation Bond",
      "Tasplan Launceston City Council Defined Benefits Fund (Accumulation section)",
      "Tasplan Super (Employer Sponsor)",
      "Tasplan Super (TasPersonaI - Public Offer)",
      "Taxi Industry Superannuation Fund",
      "Telstra Super Corporate Plus",
      "Telstra Super Personal Plus",
      "The Avenue Superannuation Service",
      "The Executive Superannuation Fund (Employer Sponsored)",
      "The Executive Superannuation Fund (Personal)",
      "The Executive Superannuation Fund Progress Plan",
      "Top Quartile Superannuation Trust",
      "TotalView SuperWrap Personal Super",
      "Toyota Australia Superannuation Plan",
      "Toyota Australia Superannuation Trust",
      "Transport Industry Super",
      "TWUSuper",
      "TWUSuperTransPersonal",
      "TWUSuperTransuper",
      "Ultimate Super Service",
      "UMA Super",
      "UniSuper (Accumulation 1)",
      "UniSuper (Accumulation 2)",
      "United Technologies Retirement Plan",
      "Vauraus Core SupenNrap",
      "Ventura Managed Account Portfolios (Superannuation)",
      "VicSuper FutureSaver",
      "Victorian Independent Schools Superannuation Fund",
      "Virgin Superannuation",
      "Vision Super (ASU Plan)",
      "Vision Super (City of Melbourne)",
      "Vision Super (Local Authorities Superannuation Defined Benefit Plan)",
      "Vision Super (Personal Plan)",
      "Vision Super (Super Saver)",
      "Voyage Superannuation MasterTrust Super (Family Discount)",
      "Voyage Superannuation Master Trust Super (Standard)",
      "WA Super- Super Solutions",
      "Water Corporation Super Plan",
      "Wealth Manager SuperWrap Personal Super",
      "Wealth-e-account SuperWrap Essentials Super",
      "Wealthtrac Superannuation Master Trust Super (Family Discount)",
      "Wealthtrac Superannuation Master Trust Super (Standard)",
      "Wealthtrac Superannuation Plan (Employer)",
      "Wealthtrac Superannuation Plan (Personal)",
      "Westpac Lifetime Super Service (Contribution Fee)",
      "Westpac Lifetime Super Service (Nil Contribution Fee}",
      "Westpac Personal Superannuation Fund",
      "Westpac Staff Super Accumulation",
      "YellowBrickRoad Super",
      "YellowBrickRoad Super Plus",
      "YourChoice Super",
      "Zurich Superannuation Plan (Entry Fee}",
      "Zurich Superannuation Trustee Investment Plan (Entry Fee)",
      "Zurich Superannuation Trustee Investment Plan (Nil Entry Fee)",
    ];

    this.generateUTCDate = function (date) {
      if (date) {
        var dateObj = moment.utc(date);
        var utcDate = moment.utc([
          dateObj.year(),
          dateObj.month(),
          dateObj.date(),
          0,
          0,
          0,
        ]);

        return utcDate;
      }
      return null;
    };

    this.generatedLocalDateString = function (date) {
      if (date) {
        var dateObj = moment.utc(date, ["MM/DD/YYYY", "YYYY/MM/DD"]);
        var date = dateObj.format("DD/MM/YYYY");
        return date;
      }
    };

    this.goToLocation = function (location, scope) {
      var proceed = false;
      switch (location) {
        case "fact-find2":
          if (scope.isPersonalInfoCompleted) {
            proceed = true;
          }
          break;

        case "fact-find3":
          if (scope.isPersonalInfoCompleted && scope.isIncomeCompleted) {
            proceed = true;
          }
          break;

        case "fact-find4":
          if (scope.isPersonalInfoCompleted && scope.isIncomeCompleted) {
            proceed = true;
          }
          break;

        case "fact-find5":
          if (
            scope.isPersonalInfoCompleted &&
            scope.isIncomeCompleted &&
            scope.isPropertyCompleted
          ) {
            proceed = true;
          }
          break;

        case "fact-find6":
          if (
            scope.isPersonalInfoCompleted &&
            scope.isIncomeCompleted &&
            scope.isPropertyCompleted &&
            scope.isBorrowingCompleted
          ) {
            proceed = true;
          }
          break;

        case "fact-find7":
          if (
            scope.isPersonalInfoCompleted &&
            scope.isIncomeCompleted &&
            scope.isPropertyCompleted &&
            scope.isBorrowingCompleted
          ) {
            proceed = true;
          }
          break;

        case "fact-find8":
          if (
            scope.isPersonalInfoCompleted &&
            scope.isIncomeCompleted &&
            scope.isPropertyCompleted &&
            scope.isBorrowingCompleted &&
            scope.isSpendingCompleted
          ) {
            proceed = true;
          }
          break;

        case "fact-find9":
          if (
            scope.isPersonalInfoCompleted &&
            scope.isIncomeCompleted &&
            scope.isPropertyCompleted &&
            scope.isBorrowingCompleted &&
            scope.isSpendingCompleted
          ) {
            proceed = true;
          }
          break;

        case "fact-find10":
          if (
            scope.isPersonalInfoCompleted &&
            scope.isIncomeCompleted &&
            scope.isPropertyCompleted &&
            scope.isBorrowingCompleted &&
            scope.isSpendingCompleted
          ) {
            proceed = false;
          }
          break;

        case "client-summary":
          if (
            scope.isPersonalInfoCompleted &&
            scope.isIncomeCompleted &&
            scope.isPropertyCompleted &&
            scope.isBorrowingCompleted &&
            scope.isSpendingCompleted &&
            scope.isFPlanHouseholdCompleted
          ) {
            proceed = true;
          }
          break;
      }

      return proceed;
    };

    this.getFrequencyNum = function (freq) {
      switch (freq) {
        case "Weekly":
          return 52;
        case "Fortnightly":
          return 26;
        case "Monthly":
          return 12;
        case "Every 2 months":
          return 6;
        case "Every 3 months":
          return 4;
        case "Every 6 months":
          return 6;
        case "Yearly":
          return 1;
        default:
          return 1;
      }
    };

    this.generateShortId = function () {
      var text = "";
      var possible =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

      for (var i = 0; i < 5; i++)
        text += possible.charAt(Math.floor(Math.random() * possible.length));

      return text;
    };

    this.removeSpace = function (strWithSpace) {
      if (strWithSpace) {
        var strNoSpace = strWithSpace.replace(/\s+/g, "").trim();
        return strNoSpace;
      } else {
        return strNoSpace;
      }
    };
    this.defaultExpensesBills = [
      {
        tier1: "Home Costs",
        tier2: "Rent Payments",
        tier3: "Rent payments",
        tier4: "Regular Payments",
        desc: "Rent",
        amount: null,
        discretionary: null,
        frequency: "Monthly",
        owner: "Joint",
        category: "7 Days Float",
      },
      {
        tier1: "Home Costs",
        tier2: "Council Rates",
        tier3: "Local government rates",
        tier4: "Bills",
        desc: "Local Government Rates",
        amount: null,
        discretionary: null,
        frequency: "Monthly",
        owner: "Joint",
        category: "Paid by Credit",
      },
      {
        tier1: "Home Costs",
        tier2: "Council Rates",
        tier3: "Water and sewerage rates",
        tier4: "Bills",
        desc: "Water and Sewerage Rates and Charges",
        amount: null,
        discretionary: null,
        frequency: "Monthly",
        owner: "Joint",
        category: "Paid by Credit",
      },
      {
        tier1: "Home Costs",
        tier2: "Home and Contents Insurance",
        tier3: "",
        tier4: "Bills",
        desc: "House and Contents Insurance",
        amount: null,
        discretionary: null,
        frequency: "Monthly",
        owner: "Joint",
        category: "Paid by Credit",
      },
      {
        tier1: "Home Power & Fuel",
        tier2: "Utilities",
        tier3: "Electricity",
        tier4: "Bills",
        desc: "Electricity",
        amount: null,
        discretionary: null,
        frequency: "Monthly",
        owner: "Joint",
        category: "Paid by Credit",
      },
      {
        tier1: "Home Power & Fuel",
        tier2: "Utilities",
        tier3: "Mains gas",
        tier4: "Bills",
        desc: "Gas",
        amount: null,
        discretionary: null,
        frequency: "Monthly",
        owner: "Joint",
        category: "Paid by Credit",
      },
      {
        tier1: "Home Services & Operations",
        tier2: "Communications",
        tier3: "",
        tier4: "Bills",
        desc: "Telephone - Fixed and Mobile",
        amount: null,
        discretionary: null,
        frequency: "Monthly",
        owner: "Joint",
        category: "Paid by Credit",
      },
      {
        tier1: "Home Services & Operations",
        tier2: "Communications",
        tier3: "Internet charges",
        tier4: "Bills",
        desc: "Internet",
        amount: null,
        discretionary: null,
        frequency: "Monthly",
        owner: "Joint",
        category: "Paid by Credit",
      },
      {
        tier1: "Lifestyle (Recreational)",
        tier2: "Audio & Visual",
        tier3: "",
        tier4: "Living and Lifestyle",
        desc: "Pay Television",
        amount: null,
        discretionary: null,
        frequency: "Monthly",
        owner: "Joint",
        category: "7 Days Float",
      },
      {
        tier1: "Personal Health Insurances",
        tier2: "Accident & Health Insurance",
        tier3: "",
        tier4: "Bills",
        desc: "Health Insurance",
        amount: null,
        discretionary: null,
        frequency: "Monthly",
        owner: "Joint",
        category: "Paid by Credit",
      },
      {
        tier1: "Personal / Professional",
        tier2: "Personal Protection Insurances",
        tier3: "Life Insurance",
        tier4: "Bills",
        desc: "Life Insurance",
        amount: null,
        discretionary: null,
        frequency: "Monthly",
        owner: "Joint",
        category: "Paid by Credit",
      },
      {
        tier1: "Transport",
        tier2: "Car License & Registration",
        tier3: "Compulsory registration",
        tier4: "Bills",
        desc: "Car Registration",
        amount: null,
        discretionary: null,
        frequency: "Monthly",
        owner: "Joint",
        category: "Paid by Credit",
      },
      {
        tier1: "Transport",
        tier2: "Car Insurance",
        tier3: "",
        tier4: "Bills",
        desc: "Car Insurance",
        amount: null,
        discretionary: null,
        frequency: "Monthly",
        owner: "Joint",
        category: "Paid by Credit",
      },
      {
        tier1: "Transport",
        tier2: "Motoring Organisation Fees",
        tier3: "Subscription to motor organisations",
        tier4: "Bills",
        desc: "Motoring Organisations",
        amount: null,
        discretionary: null,
        frequency: "Monthly",
        owner: "Joint",
        category: "Paid by Credit",
      },
      {
        tier1: "Education",
        tier2: "",
        tier3: "",
        tier4: "Bills",
        desc: "Education - School Fees",
        amount: null,
        discretionary: null,
        frequency: "Monthly",
        owner: "Joint",
        category: "Paid by Credit",
      },
      {
        tier1: "Education",
        tier2: "Pre School Care Services",
        tier3: "",
        tier4: "Bills",
        desc: "Child Care Fees",
        amount: null,
        discretionary: null,
        frequency: "Monthly",
        owner: "Joint",
        category: "Paid by Credit",
      },
      {
        tier1: "Bank Charges",
        tier2: "Banking Fees",
        tier3: "",
        tier4: "Regular Payment",
        desc: "Bank Fees",
        amount: null,
        discretionary: null,
        frequency: "Monthly",
        owner: "Joint",
        category: "7 Days Float",
      },
    ];

    this.defaultExpensesSpending = [
      {
        desc: "Groceries and Non-alcoholic Beverages",
        tier4: "Living Lifestyle",
        amount: null,
        category: "7 Days Float",
        frequency: "Monthly",
      },
      {
        desc: "Clothing, Footwear, Dry cleaning, Repairs",
        tier4: "Living Lifestyle",
        amount: null,
        category: "7 Days Float",
        frequency: "Monthly",
      },
      {
        desc: "Education - Out-of-pocket expenses",
        tier4: "Bills",
        amount: null,
        category: "Paid by Credit",
        frequency: "Monthly",
      },
      {
        desc: "Medical and Health Care expenses",
        tier4: "Bills",
        amount: null,
        category: "Paid by Credit",
        frequency: "Monthly",
      },
      {
        desc: "Personal Care",
        tier4: "Living Lifestyle",
        amount: null,
        category: "7 Days Float",
        frequency: "Monthly",
      },
      {
        desc: "Pets and Animals",
        tier4: "Living Lifestyle",
        amount: null,
        category: "7 Days Float",
        frequency: "Monthly",
      },
      {
        desc: "Household Appliances and Tools",
        tier4: "Provisioning",
        amount: null,
        category: "Provisioning",
        frequency: "Monthly",
      },
      {
        desc: "Furniture",
        tier4: "Provisioning",
        amount: null,
        category: "Provisioning",
        frequency: "Monthly",
      },
      {
        desc: "Household Furnishings",
        tier4: "Living Lifestyle",
        amount: 0,
        category: "7 Days Float",
        frequency: "Monthly",
      },
      {
        desc: "House and Garden maintenance",
        tier4: "Household",
        amount: null,
        category: "7 Days Float",
        frequency: "Monthly",
      },
      {
        desc: "Fuel",
        tier4: "Bills",
        amount: null,
        category: "Paid by Credit",
        frequency: "Monthly",
      },
      {
        desc: "Car maintenance",
        tier4: "Bills",
        amount: null,
        category: "Paid by Credit",
        frequency: "Monthly",
      },
      {
        desc: "Parking and Tolls",
        tier4: "Bills",
        amount: null,
        category: "Paid by Credit",
        frequency: "Monthly",
      },
      {
        desc: "Fares",
        tier4: "Bills",
        amount: null,
        category: "Paid by Credit",
        frequency: "Monthly",
      },
      {
        desc: "Presents and Gifts",
        tier4: "Living Lifestyle",
        amount: null,
        category: "7 Days Float",
        frequency: "Monthly",
      },
      {
        desc: "Donations",
        tier4: "Living Lifestyle",
        amount: null,
        category: "7 Days Float",
        frequency: "Monthly",
      },
      {
        desc: "Holidays",
        tier4: "Provisioning",
        amount: null,
        category: "Provisioning",
        frequency: "Monthly",
      },
      {
        desc: "Dining out / Takeaway food",
        tier4: "Living Lifestyle",
        amount: null,
        category: "7 Days Float",
        frequency: "Monthly",
      },
      {
        desc: "Entertainment",
        tier4: "Living Lifestyle",
        amount: null,
        category: "7 Days Float",
        frequency: "Monthly",
      },
      {
        desc: "Sport, recreation and hobbies",
        tier4: "Living Lifestyle",
        amount: null,
        category: "7 Days Float",
        frequency: "Monthly",
      },
      {
        desc: "Books, Magazines and Newspapers",
        tier4: "Living Lifestyle",
        amount: null,
        category: "7 Days Float",
        frequency: "Monthly",
      },
      {
        desc: "Video / DVD purchase and hire",
        tier4: "Living Lifestyle",
        amount: null,
        category: "7 Days Float",
        frequency: "Monthly",
      },
      {
        desc: "Alcohol and Tobacco",
        tier4: "Living Lifestyle",
        amount: null,
        category: "7 Days Float",
        frequency: "Monthly",
      },
      {
        desc: "Gambling and Lotto",
        tier4: "Living Lifestyle",
        amount: null,
        category: "7 Days Float",
        frequency: "Monthly",
      },
    ];

    this.newOtherExpense = function () {
      return {
        tier4: "Spending",
        customExpense: true,
        amount: null,
        frequency: "Monthly",
      };
    };
    this.newTaxDeductibleBill = function () {
      return {
        tier4: "Bills",
        taxDeductible: true,
        amount: null,
        frequency: "Monthly",
      };
    };
  });
